import * as ActionTypes from "../../CMS/ActionTypes";
import {DAS_DOCTOR_REPORT_REFRESH_IS_CHECK} from "../../CMS/ActionTypes";

const initialState = {
    isLoading: false,
    showDetailModal: false,
    reports:[],
    report: {},
    check:false,
};

const DeviceDoctorReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DAS_DOCTOR_REPORT_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DAS_DOCTOR_REPORT_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.DAS_DOCTOR_REPORT_REFRESH_REPORTS:
            return Object.assign({}, state, {
                reports: action.reports
            });
        case ActionTypes.DAS_DOCTOR_REPORT_REFRESH_REPORT:
            return Object.assign({}, state, {
                report: action.report
            });
        case ActionTypes.DAS_DOCTOR_REPORT_REFRESH_IS_CHECK:
            return Object.assign({}, state, {
                check: action.check
            });
        default:
            return state
    }
};

export default DeviceDoctorReducer;