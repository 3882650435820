import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Tabs, Button, Card} from 'antd';

import PageTitle from "../../../../../components/PageTitle";
import ContentBasicForm from "../../../components/ContentBasicForm";
import ContentFileList from "../../../components/ContentFileList";
import ContentSummaryEditor from "../../../components/ContentSummaryEditor";
import ContentBodyEditor from "../../../components/ContentBodyEditor";
import {getContent, initContent, updateContent} from "../../../actions/ContentAction";
import {refreshFiles} from "../../../actions/ContentFileAction";
import {RoutePath} from "../../../../../constants/RoutePath";
import {getChannelTree} from "../../../actions/ChannelAction";
import {CACHE_SITE_ID} from "../../../../../constants/Profile";
import HttpUtils from "../../../../../utils/HttpUtils";

let keys = 0;
let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            key: 1,
            height:"100%"
        }
    }

    componentWillMount() {
        this.props.dispatch(getContent(this.props.params.contentId, this.state.formatMessage));
        const key = this.props.location.query.key;
        const contentId = HttpUtils.getQueryString("contentId");
        console.log("this.props.location.query.key", key, this.props.params.contentId, contentId)
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
        // console.log("this.props.router.state.key", this.props.router.state.key)
        if (key) {
            keys = key
            this.setState({"key": key})
        }
    }

    componentWillUnmount() {
        console.log("this.props:", this.props)
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(initContent());
        this.props.dispatch(refreshFiles([]));
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    _validate(content) {
        let messageMap = {};
        if (!content.channelId || content.channelId === "") {
            messageMap["channelId"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CHANNEL_REQUIRED'});
        }
        if (!content.title || content.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_TITLE_REQUIRED'});
        }
        if (!content.code || content.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CODE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleSubmit(isDraft) {
        const content = Object.assign({}, this.props.content);
        content["files"] = this.props.files;
        content["draftFlag"] = isDraft;
        if (this._validate(content)) {
            this.props.dispatch(updateContent(content, this.state.formatMessage));
        }
    }



    _handleEnclosure(name, data) {
        keys = data;
        if (data == 0) {
            this.props.router.push(RoutePath.CMS_SOFTWARE_VERSION_MANAGEMENT.path)
        } else {
            this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", this.props.params.contentId) + "?key=" + data);
        }

    }


    _reload(data) {
        window.location.reload();
        // this.props.dispatch(getContent(this.props.params.contentId, this.state.formatMessage));
        // this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", record.id) + "?key=" + keys);
    }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <div className="page-content position-relative" style={{maxHeight:height}}>
                <PageTitle title="CMS_CONTENT_ROUTE_CONTENT_UPDATE"/>
                <Card>
                    <Tabs AutoPostBack={true}
                          ref="tabs"
                          onChange={(data) => {
                              this._handleEnclosure("version", data)
                          }}
                          defaultActiveKey={this.state.key}
                          tabBarExtraContent={
                              <div>
                                  <Button onClick={(data) => this._reload()} className="spacing-h">
                                      <FormattedMessage id='COMMON_BUTTON_REFRESH'/>
                                  </Button>
                                  <Button type="primary" icon={"save"} className="spacing-h"
                                          onClick={() => this._handleSubmit(0)}>
                                      <FormattedMessage id='COMMON_BUTTON_SAVE'/>
                                  </Button>
                                  <Button icon={"save"}
                                          onClick={() => this._handleSubmit(1)}>
                                      <FormattedMessage id='COMMON_BUTTON_SAVE_DRAFT'/>
                                  </Button>
                              </div>
                          }>

                        <Tabs.TabPane tab={formatMessage({id: "CMS_SOFTWARE_ROUTE_VERSION_MANAGEMENT"})} key="0"/>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_BASIC_INFO"})} key="1">
                            <ContentBasicForm messageMap={this.state.messageMap} {...this.props}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_FILE_LIST"})} key="2">
                            <ContentFileList/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_SUMMARY_EDITOR"})} key="3">
                            <ContentSummaryEditor/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_BODY_EDITOR"})} key="4">
                            <ContentBodyEditor/>
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </div>
        );
    }
}


function mapStateToProps(store) {
    return {
        content: store.ContentReducer.content,
        files: store.ContentFileReducer.files,
        searchCondition: store.ContentReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));