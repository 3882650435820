import React, {Component} from 'react';
import {Drawer, Tabs} from 'antd';
import "./DeviceDetailDrawer.css";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import DeviceDetailBasic from "../../Device/Monitor/DeviceDetailBasic";
import DeviceDetailSystem from "../../Device/Monitor/DeviceDetailSystem";
import DeviceDetailUsage from "../../Device/Monitor/DeviceDetailUsage";
import {getDeviceById} from "../../../actions/DeviceAction";

let self

class DeviceDetailDrawer extends Component {

    constructor(props) {
        super(props);
        self = this
        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {

    }

    componentWillUnmount() {
    }


    render() {
        const onClose = () => {
            console.log("onClose", this, self);
            this.props.setVis(false)
        };
        const {formatMessage} = this.state;
        return (
            <Drawer
                onClose={onClose}
                className="device-detail-drawer"
                placement="right"
                closable={true}
                visible={this.props.visible}
                destroyOnClose={true}
                mask={false}
                width={400}>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_BASIC'})} key="1">
                        <DeviceDetailBasic device={this.props.device}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_SYSTEM'})} key="2">
                        <DeviceDetailSystem device={this.props.device}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_USAGE'})} key="3">
                        <DeviceDetailUsage device={this.props.device} deviceHistoryData={this.props.deviceHistoryData}/>
                    </Tabs.TabPane>
                </Tabs>
            </Drawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        device: store.EcgDeviceReducer.device,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceDetailDrawer));