/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import moment from "moment"
import "./Index.css"

import {Calendar, Row, Col, Badge} from 'antd';
import {FORMAT_DATE_SIMPLE} from "../../../../../../constants/DateTimeFormats";

export default class DateIndexCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previousDate: moment(moment(new Date()).subtract(1, 'month')),
            currentDate: moment(moment(new Date()))
        }
    }

    dateCellRender = (value) => {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[value.format(FORMAT_DATE_SIMPLE)];
        if (!dateIndexRecord) {
            return;
        }

        return (
            <h3 className="text-center">
                {
                    // 优先显示事件数
                    dateIndexRecord.eventNum >= dateIndexRecord.timeNum ?
                        <Badge count={dateIndexRecord.eventNum} overflowCount={9999}/> :
                        <Badge count={dateIndexRecord.timeNum} overflowCount={9999}
                               style={{backgroundColor: '#52c41a'}}/>
                }
            </h3>
        );
    };

    /** 返回日历日期的禁止点击 */
    _handleDisabled = (currentDate) => {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[currentDate.format(FORMAT_DATE_SIMPLE)];
        // 时间索引文件编号，0表示没采集数据
        return dateIndexRecord === undefined || dateIndexRecord === null || dateIndexRecord.eventFileIndex <= 0;
    };

    /** 日历日期点击事件 */
    _handleSelectDate = (date) => {
        this.props.onSelect && this.props.onSelect.call(this, date);
    };

    render() {
        return (
            <Row className="double-calendar">
                <Col className="double-calendar-divider double-calendar-left" span={12}>
                    <Calendar value={this.state.previousDate}
                              disabledDate={currentDate => this._handleDisabled(currentDate)}
                              dateCellRender={(value) => this.dateCellRender(value)}
                              onSelect={(date) => this._handleSelectDate(date)}/>
                </Col>
                <Col className="double-calendar-right" span={12}>
                    <Calendar value={this.state.currentDate}
                              disabledDate={currentDate => this._handleDisabled(currentDate)}
                              dateCellRender={(value) => this.dateCellRender(value)}
                              onSelect={(date) => this._handleSelectDate(date)}/>
                </Col>
            </Row>
        );
    }
}
