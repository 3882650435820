/**
 * Created by Ethan on 16/12/23.
 * 根Reducer，把所有的Reducer合并返回
 */

import { combineReducers } from 'redux';

import AppReducer from './AppReducer';

// 字典项
import DictTypeReducer from "../modules/Dict/reducers/DictTypeReducer";
import DictClassReducer from "../modules/Dict/reducers/DictClassReducer";
import DictItemReducer from "../modules/Dict/reducers/DictItemReducer";

import AccountRoleReducer from '../modules/Account/reducers/RoleReducer';
import AccountUserReducer from '../modules/Account/reducers/UserReducer';

import OrgOrganizationReducer from "../modules/ORG/reduers/OrganizationReducer";
import OrgEmployeeReducer from "../modules/ORG/reduers/EmployeeReducer";

import SiteReducer from '../modules/CMS/reducers/SiteReducer';
import ChannelReducer from '../modules/CMS/reducers/ChannelReducer';
import ContentReducer from '../modules/CMS/reducers/ContentReducer';
import ContentFileReducer from '../modules/CMS/reducers/ContentFileReducer';

import EcgDeviceReducer from '../modules/DAS/reducers/DeviceReducer';
import EcgDeviceCoordinateReducer from "../modules/DAS/reducers/DeviceCoordinateReducer";
import BinderReducer from '../modules/DAS/reducers/BinderReducer';
import BinderDataReducer from "../modules/DAS/reducers/BinderDataReducer";
import GuardianReducer from "../modules/DAS/reducers/GuardianReducer";
import UserReducer from "../modules/DAS/reducers/UserReducer";
import DeviceFileDownloadReducer from "../modules/DAS/reducers/DeviceFileDownloadReducer";

import DASECGReducer from "../modules/DAS/reducers/ECGReducer";
import DASNcovReducer from "../modules/DAS/reducers/NcovReducer";
import EcgLogDataServerReducer from "../modules/DAS/reducers/LogDataServerReducer";

import DistrictCountryReducer from "../modules/District/reducers/CountryReducer";
import DistrictProvincialReducer from "../modules/District/reducers/ProvincialReducer";
import DistrictCityReducer from "../modules/District/reducers/CityReducer";
import DistrictCountyReducer from "../modules/District/reducers/CountyReducer";
import DistrictTownReducer from "../modules/District/reducers/TownReducer";
import DistrictVillageReducer from "../modules/District/reducers/VillageReducer";
import EcgHistoryReducer from "../modules/DAS/reducers/HistoryReducer";
import EcgSystemReducer from "../modules/DAS/reducers/EcgSystemReducer";
import EcgDeviceFileAnnotationReducer from "../modules/DAS/reducers/DeviceFileAnnotationReducer";
import EcgCaseReducer from "../modules/DAS/reducers/EcgCaseReducer";
import EcgApplicationReducer from "../modules/DAS/reducers/EcgApplicationReducer";
import EcgDeviceBindHistoryReducer from "../modules/DAS/reducers/DeviceBindHistoryReducer";
import EcgGuarderBindHistoryReducer from "../modules/DAS/reducers/GuarderBindHistoryReducer";

import DeviceDoctorReducer from "../modules/DAS/reducers/DeviceDoctorReducer"

const RootReducer = combineReducers({
    AppReducer,

    // 字典项
    DictTypeReducer,
    DictClassReducer,
    DictItemReducer,

    AccountRoleReducer,
    AccountUserReducer,

    OrgOrganizationReducer,
    OrgEmployeeReducer,

    SiteReducer,
    ChannelReducer,
    ContentReducer,
    ContentFileReducer,

    EcgDeviceReducer,
    EcgDeviceCoordinateReducer,
    BinderReducer,
    GuardianReducer,
    BinderDataReducer,
    UserReducer,
    DeviceFileDownloadReducer,

    DASECGReducer,
    DASNcovReducer,
    EcgLogDataServerReducer,
    EcgHistoryReducer,
    EcgSystemReducer,
    EcgApplicationReducer,

    DistrictCountryReducer,
    DistrictProvincialReducer,
    DistrictCityReducer,
    DistrictCountyReducer,
    DistrictTownReducer,
    DistrictVillageReducer,
    EcgDeviceFileAnnotationReducer,
    EcgCaseReducer,
    EcgDeviceBindHistoryReducer,
    EcgGuarderBindHistoryReducer,

    DeviceDoctorReducer
});

export default RootReducer;
