/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    counties: [],
    county: {},
    countyMap: {}, // key: countyId, value: county
    showDetailModal: false
};

const CountyReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_COUNTY_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_COUNTY_SEARCH:
            return Object.assign({}, state, {
                counties: action.counties
            });
        case ActionTypes.DISTRICT_COUNTY_REFRESH_COUNTY:
            return Object.assign({}, state, {
                county: action.county
            });
        case ActionTypes.DISTRICT_COUNTY_REFRESH_COUNTY_MAP:
            return Object.assign({}, state, {
                countyMap: action.countyMap
            });
        case ActionTypes.DISTRICT_COUNTY_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default CountyReducer;