/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";

export function refreshCounty(county) {
    return {
        type: ActionTypes.DISTRICT_COUNTY_REFRESH_COUNTY,
        county: county
    }
}

export function refreshCountyMap(countyMap) {
    return {
        type: ActionTypes.DISTRICT_COUNTY_REFRESH_COUNTY_MAP,
        countyMap: countyMap
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_COUNTY_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getCountiesByCityId(cityId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: true});

        const requestURL = WebService.getCountiesByCityId.replace("{id}", cityId);
        HttpUtils.get(requestURL, (response) => {

            dispatch({
                type: ActionTypes.DISTRICT_COUNTY_SEARCH,
                counties: response.data
            });

            dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getCounty(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: true});
        const requestURL = WebService.getCountyById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshCounty(response.data));
            dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createCounty(county, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: true});

        HttpUtils.post(WebService.createCounty, county, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCounty({}));
            dispatch(getCountiesByCityId(response.data.cityId, formatMessage));
            dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateCounty(county, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: true});

        const requestURL = WebService.updateCountyById.replace("{id}", county.id);
        HttpUtils.put(requestURL, county, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCounty({}));
            dispatch(getCountiesByCityId(response.data.cityId, formatMessage));

            dispatch({type: ActionTypes.DISTRICT_COUNTY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteCounty(county, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByCountyId.replace("{id}", county.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getCountiesByCityId(response.data.cityId, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



