/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Collapse} from 'antd';

import {Avatar, Card, Divider, Icon, Menu, Popconfirm, Table, Button} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import SiderLayout from "../../../../../layout/SiderLayout";
import {Pagination} from "antd";

import {
    deleteBinder,
    getBinderDevices,
    getBinderGuarders,
    refreshBinder,
    searchBinders,
    toggleDeviceListModal,
    toggleGuarderListModal
} from '../../../actions/BinderAction';

import {UserSex} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

import mqtt from "mqtt";
import {RoutePath} from "../../../../../constants/RoutePath";
import {Link} from "react-router";
import {CACHE_SESSION_USER} from "../../../../../constants/Profile";

// const mqttId = [];
let mqttA = {};
let deviceIds = {};
let client;
class SearchResult extends Component {

    constructor(props) {


        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            // Key: record.id, value: mqtt value
            IP: {},
            mobileCount: {},
            formatMessage: this.props.intl['formatMessage'],
            showDetail: false,
            columns: [
                {
                    key: 'ip',
                    dataIndex: 'ip',
                    title: formatMessage({id: 'DAS_TERMINAL_IP'}),

                },
                {
                    key: 'process',
                    dataIndex: 'process',
                    title: formatMessage({id: 'DAS_PROCESS_LIST'}),
                    render: (text, record, index) =>
                        this._renderMqttId(text, record, index)
                },
            ],
            mqttDatas: null
        }
    }

    componentWillMount() {
        client = mqtt.connect('ws://140.206.121.162:7768/mqtt');
        const self = this;
        let messageIdMap = {};
        //一个IP下有多少数据
        let mobileMap = {};
        //mqtt内容的列表
        let mqttIdMap = [];
        let mqttIpMap = [];
        let mqttData = [];
        let mqttDataFlag = true;
        // let mobileCount = 0;
        client.on('connect', function () {
            client.subscribe('D9K_MQTT/#', function (err) {
                console.log(err);
                if (!err) {
                    console.log("subscribe successed");
                    window.mobileData = [];
                }
            })

        })


        client.on('message', function (topic, message) {
            // message is Buffer
            let mqttIdMap2 = [];
            var obj = JSON.parse(message);

            if (obj.ip) {
                console.log(topic + ":" + message.toString())
                var messageId = topic.toString().substr(9, 12);
                var deviceId = topic.toString().substr(22, 8);
                //每个进程的唯一标识
                var mqttId = topic.toString().substr(31, 36);
                console.log("deviceId:", deviceId)
                for (var i = 0; i < mqttIdMap.length; i++) {
                    if (mqttIdMap[i] === mqttId) {
                        return;
                    }
                }
                // if (mqttId == mqttIdMap[messageId]) {
                //     return
                // }
                // if (mqttId != self.state.mqttId[messageId]) {
                //     mobileCount++
                // }
                mqttA[mqttId] = obj;
                deviceIds[mqttId] = deviceId
                // mqttIdMap[messageId] = mqttId;

                // mqttData.push(mqttA)
                mqttIdMap.push(mqttId);
                console.log("obj:", obj, "mqttA:", mqttA, "mqttIdMap:", mqttIdMap)
                if (messageIdMap[obj.ip]) {
                    messageIdMap[obj.ip].push(mqttId);
                } else {
                    mqttIdMap2.push(mqttId);
                    messageIdMap[obj.ip] = mqttIdMap2;
                }
                for (var i = 0; i < mqttIpMap.length; i++) {
                    if (mqttIpMap[i] === obj.ip) {
                        mqttDataFlag = false
                    }
                }
                if (mqttDataFlag) {
                    mqttData.push(obj)
                }
                mqttIpMap.push(obj.ip)
                console.log("messageIdMap.length:", messageIdMap[obj.ip].length, "mobileCount:", mobileMap, "messageIdMap", messageIdMap, "mqrrDatas:", mqttIdMap);
                mobileMap[obj.ip] = messageIdMap[obj.ip].length;
                self.setState({"mobileCount": mobileMap, "mqttId": messageIdMap});
                self.setState({"mqttDatas": mqttData});

                mqttDataFlag = true;
                // self.state.mqttId[messageId] = mqttId;
                // console.log(self.state.mqttId);
                // console.log(mqttA);
                // console.log(client);
            }


        })

        this.discardData = setInterval(() => {
                mqttIdMap = [];
                mqttA = [];
                mobileMap = {};
                messageIdMap = {};
                mqttData = [];
                mqttIpMap = [];
                self.setState({"mqttId": messageIdMap, "mobileCount": mobileMap, "mqttDatas": mqttData});
            },
            60000)
    }

    componentWillUnmount() {
        this._handleStopSearch();
    }

    _handleStopSearch() {
        clearInterval(this.discardData)
        client.end();
    }

    _render(record, i) {
        let text = "";
        let phoneNumber = ""
        let guardMode = ""
        let appVersion = ""
        console.log("record.userId", record.userId)
        if (this.state.mqttId[record.userId]) {
            if (mqttA[this.state.mqttId[record.userId][i]]) {
                console.log(mqttA[this.state.mqttId[record.userId][i]])
                text = (mqttA[this.state.mqttId[record.userId][i]].phoneBrand ? mqttA[this.state.mqttId[record.userId][i]].phoneBrand : "") + " "
                    + (mqttA[this.state.mqttId[record.userId][i]].phoneModel ? mqttA[this.state.mqttId[record.userId][i]].phoneModel : "")
                phoneNumber = "手机号码：" + (mqttA[this.state.mqttId[record.userId][i]].phoneNumber ? mqttA[this.state.mqttId[record.userId][i]].phoneNumber : "未知")
                guardMode = "监护模式：  " + (mqttA[this.state.mqttId[record.userId][i]].guardMode ? mqttA[this.state.mqttId[record.userId][i]].guardMode : "未知");
                appVersion = "app版本：  " + (mqttA[this.state.mqttId[record.userId][i]].appVersion ? mqttA[this.state.mqttId[record.userId][i]].appVersion : "未知");
            }
        }
        return (
            <div>
                <div>
                    {text}
                </div>
                <div>
                    {phoneNumber}
                </div>
                <div>
                    {guardMode}
                </div>
                <div>
                    {appVersion}
                </div>
            </div>

        )
    }

    _renderMqttId(text, record, index) {

        const {Panel} = Collapse;
        let res = [];
        let resV = [];
        let length = 0;
        let keys = Object.keys(mqttA)
        let value = Object.values(record)
        console.log("keys:", keys)
        console.log("text:", text, "record:", record, "index:", index)
        // console.log("ip:", record[keys[0]].ip)
        for (let i = 0; i < this.state.mqttId[record.ip].length; i++) {
            //     if (record[keys[index]].ip != record[keys[i]].ip) {
            //         continue
            //     }
            res.push(<Link
                to={RoutePath.DAS_MQTT_LIST.path + "?userId=" + this.state.mqttId[record.ip][i] + "&deviceId=" + deviceIds[this.state.mqttId[record.ip][i]]}
                URIEncoding="utf-8">
                {/*{this._render(record, i)}*/}
                {this.state.mqttId[record.ip][i]+"("+deviceIds[this.state.mqttId[record.ip][i]]+")"}
                <br/>
            </Link>)
        }
        console.log("res:", res)
        if (res.length > 0) {
            console.log("进行输出")
            resV.push(<Collapse title={this.state.mqttId[record.userId]} placement="bottom">
                <Panel key={1} header={this.state.mobileCount[record.userId]}>
                    {res}
                </Panel>
            </Collapse>)
        }
        //输出出来是一个数组但是里面是没有内容的
        console.log(res)
        return (
            <div>
                {resV}
            </div>
        )
    }

    //获取ip
    _renderMqttIp(text, record, index) {
        let res;
        let length = 0;
        let keys = Object.keys(record)
        for (var i = 0; i <= index; i++) {
            if (record[keys[index]].ip == record[keys[i]].ip) {
                length++
            }
        }
        if (length <= 1) {
            res = record[keys[index]].ip
        }
        return (
            <div>
                {record.ip}
            </div>
        )
    }

// <Collapse title={this.state.mqttId[record.userId]} placement="bottom">
// <Panel key={1} header={this.state.mobileCount[record.userId]}>
//     {res}
// </Panel>
// </Collapse>

    _handleGetDevices(record) {
        this.props.dispatch(refreshBinder(record));
        this.props.dispatch(getBinderDevices(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleDeviceListModal(true));
    }

    _handleGetGuarders(record) {
        this.props.dispatch(refreshBinder(record));
        this.props.dispatch(getBinderGuarders(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleGuarderListModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteBinder(record, searchCondition, formatMessage));
    }

    render() {
        return (
            <Card className="spacing-v page-width">
                {/*<PaginationBar pagination={this.props.pagination}*/}
                {/*               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>*/}
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.state.mqttDatas}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        binders: store.BinderReducer.binders,
        searchCondition: store.BinderReducer.searchCondition,
        pagination: store.BinderReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));