import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Tree} from 'antd';

import {
    getCountryTree,
    refreshCountryTreeBySelectCity, refreshCountryTreeBySelectCounty,
    refreshCountryTreeBySelectProvincial, refreshCountryTreeBySelectTown,
    refreshCountryTreeBySelectTreeNode, refreshCountryTreeExpandKeys
} from '../actions/CountryAction';

class DistrictTree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        this.props.dispatch(getCountryTree(this.state.formatMessage));
    }

    _handleSelect(selectedKeys, info) {
        if (selectedKeys.length > 0) {
            const countryTree = Object.assign([], this.props.countryTree);
            const expandKeys = Object.assign([], this.props.countryTreeExpandKeys);
            const provincialMap = Object.assign({}, this.props.provincialMap);
            const cityMap = Object.assign({}, this.props.cityMap);
            const countyMap = Object.assign({}, this.props.countyMap);
            const townMap = Object.assign({}, this.props.townMap);
            const villageMap = Object.assign({}, this.props.villageMap);
            if (info.node.props.pos.split("-").length === 2) {
                // 选择了国家节点
                this.props.dispatch(getCountryTree(this.state.formatMessage));
            } else if (info.node.props.pos.split("-").length === 3) {
                // 选择了省份节点
                this.props.dispatch(refreshCountryTreeBySelectProvincial(countryTree, info.node.props.eventKey, expandKeys, cityMap, this.state.formatMessage));
            } else if (info.node.props.pos.split("-").length === 4) {
                // 选择了城市节点
                const city = cityMap[info.node.props.eventKey];
                this.props.dispatch(refreshCountryTreeBySelectCity(countryTree, city.provincialId, info.node.props.eventKey, expandKeys, countyMap, this.state.formatMessage));
            } else if (info.node.props.pos.split("-").length === 5) {
                // 选择了区县节点
                const county = countyMap[info.node.props.eventKey];
                console.log(county);
                const city = cityMap[county.cityId];
                console.log(city);
                this.props.dispatch(refreshCountryTreeBySelectCounty(countryTree, city.provincialId, city.id, info.node.props.eventKey, expandKeys, townMap, this.state.formatMessage));
            } else if (info.node.props.pos.split("-").length === 6) {
                // 选择了镇节点
                const town = townMap[info.node.props.eventKey];
                const county = countyMap[town.countyId];
                const city = cityMap[county.cityId];
                this.props.dispatch(refreshCountryTreeBySelectTown(countryTree, city.provincialId, city.id, county.id, info.node.props.eventKey, expandKeys, villageMap, this.state.formatMessage));
            }
            const selectedKey = selectedKeys[0];
            this.props.onSelect && this.props.onSelect.call(this, selectedKey, info);
        } else {
            // const expandKeys = Object.assign([], this.props.countryTreeExpandKeys);
            // const index = expandKeys.indexOf(info.node.props.eventKey);
            // expandKeys.splice(index, 1);
            // this.props.dispatch(refreshCountryTreeExpandKeys(expandKeys));
        }
    }

    _handleExpand(expandedKeys, info) {
        // console.log(expandedKeys);
        // console.log(info);
        // this.props.dispatch(refreshExpandedKeys(expandedKeys));
        // const expandKeys = Object.assign([], this.props.countryTreeExpandKeys);
        // if (expandedKeys.length > 0){
        //     expandKeys.push(expandedKeys[0]);
        // } else {
        //     const index = expandKeys.indexOf(info.node.props.eventKey);
        //     expandKeys.splice(index, 1);
        // }
        this.props.dispatch(refreshCountryTreeExpandKeys(expandedKeys));
    }

    render() {
        return (
            <div>
                <Tree
                    showLine
                    expandedKeys={this.props.countryTreeExpandKeys}
                    // selectedKeys={[this.props.selectTreeKey]}
                    onSelect={(selectedKeys, info) => this._handleSelect(selectedKeys, info)}
                    onExpand={(expandedKeys, info) => this._handleExpand(expandedKeys, info)}>
                    {
                        this.props.countryTree.map((item, i) => {
                            return (
                                <Tree.TreeNode title={item.name} key={item.id}>
                                    {this._renderTreeNode(item)}
                                </Tree.TreeNode>
                            )
                        })
                    }
                </Tree>
            </div>
        );
    }

    _renderTreeNode(item) {
        if (item.children && item.children.length > 0) {
            return (
                item.children.map((item, i) => {
                    return (
                        <Tree.TreeNode title={item.name} key={item.id}>
                            {this._renderTreeNode(item)}
                        </Tree.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        countryTree: store.DistrictCountryReducer.countryTree,
        countryTreeExpandKeys: store.DistrictCountryReducer.countryTreeExpandKeys,
        provincialMap: store.DistrictProvincialReducer.provincialMap,
        cityMap: store.DistrictCityReducer.cityMap,
        countyMap: store.DistrictCountyReducer.countyMap,
        townMap: store.DistrictTownReducer.townMap,
        villageMap: store.DistrictVillageReducer.villageMap
    }
}

export default connect(mapStateToProps)(injectIntl(DistrictTree));