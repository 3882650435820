/**
 * Created by Ethan on 2017/11/7.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from "react-intl";
import {Col, Row, Collapse, Icon} from "antd";
import ReactEcharts from 'echarts-for-react';
// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';

import {FORMAT_DATE_SIMPLE, FORMAT_MONTY_SLASH} from "../../../../constants/DateTimeFormats";

import moment from "moment";
import HttpUtils from "../../../../utils/HttpUtils";
import mqtt from "mqtt";

let client;

class ProvinceChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeKey: "上海市",
            mobileData: []
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
        clearInterval(this.viewR)
    }


    componentDidMount() {
        const activeTaskCountData = [];
        const corePoolSize = [];
        const datatiem = [];
        const memory = [];

        let activeTaskMax = 0;
        let corePoolSizeMax = 0;
        let memoryMax = 0;

        for (var i = 0; i < 200; i++) {
            console.log("填充")
            activeTaskCountData.push(0);
            corePoolSize.push(0);
            memory.push(0);
            datatiem.push(0)
        }

        const userId = HttpUtils.getQueryString("userId");
        // const userName = HttpUtils.getQueryString("userName");

        client = mqtt.connect('ws://140.206.121.164:7768/mqtt');
        client.on('connect', function () {
            client.subscribe('D9K_MQTT/' + userId + '/#', function (err) {
                console.log(err);
                if (!err) {
                    console.log("subscribe successed");
                }
            })
        })

        client.on('message', function (topic, message) {
            // message is Buffer
            console.log(topic + ":" + message.toString())
            var obj = JSON.parse(message);

            var url = window.location.href; //获取当前页面的url
            url = decodeURI(url);//转码
            var len = url.length; //获取url的长度值
            var a = url.indexOf("?"); //获取第一次出现？的位置下标
            var b = url.substr(a + 1, len); //截取问号之后的内容
            var c = b.split("&"); //从指定的地方将字符串分割成字符串数组
            var arr = new Array(); //新建一个数组
            for (var i = 0; i < c.length; i++) {
                var d = c[i].split("=")[1]; //从=处将字符串分割成字符串数组,并选择第2个元素
                arr.push(d); //将获取的元素存入到数组中
            }
            if (obj.activeTaskCount != null || obj.activeTaskCount != undefined || obj.activeTaskCount != "") {
                activeTaskMax = activeTaskMax > obj.activeTaskCount ? activeTaskMax : obj.activeTaskCount;
                activeTaskCountData.splice(0, 1);
                activeTaskCountData.push(obj.activeTaskCount);
            } else {
                activeTaskMax = activeTaskMax > obj.activeThreadCount ? activeTaskMax : obj.activeThreadCount;
                activeTaskCountData.splice(0, 1);
                activeTaskCountData.push(obj.activeThreadCount);
            }
            corePoolSizeMax = corePoolSizeMax > obj.corePoolSize ? corePoolSizeMax : obj.corePoolSize;
            corePoolSize.splice(0, 1);
            corePoolSize.push(obj.corePoolSize);
            datatiem.splice(0, 1);
            datatiem.push(obj.datetime);
            memoryMax = memoryMax > obj.memory ? memoryMax : obj.memory;
            memory.splice(0, 1);
            memory.push(obj.memory);


            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('main'));
            if (myChart === "null" || myChart === undefined) {
                return
            }
            // 绘制图表
            myChart.setOption({
                title: {text: '用戶:' + arr[1]},
                yAxisIndex: 1,
                tooltip: {},
                legend: {
                    data: ['activeTaskCount', 'corePoolSize', 'memory']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    data: datatiem
                },
                yAxis: [
                    {
                        type: 'value',
                        name: 'activeTaskCount',
                        min: 0,
                        max: activeTaskMax,
                        position: 'left',
                        axisLabel: {
                            formatter: '{value}', // 61A0A8
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#C23531',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: 'corePoolSize',
                        min: 0,
                        max: corePoolSizeMax,
                        position: 'right',
                        axisLabel: {
                            formatter: '{value}',
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#334B5C',
                            },
                        },
                    },
                    {
                        type: 'value',
                        name: 'memory',
                        min: 0,
                        max: memoryMax,
                        offset: 70, // 偏移
                        position: 'right',
                        axisLabel: {
                            formatter: '{value}',
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#61A0A8',
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: 'activeTaskCount',
                        type: 'line',
                        yAxisIndex: 0,
                        data: activeTaskCountData,
                        smooth: true
                    },
                    {
                        name: 'corePoolSize',
                        type: 'line',
                        yAxisIndex: 1,
                        data: corePoolSize,
                        smooth: true
                    },
                    {
                        name: 'memory',
                        type: 'line',
                        yAxisIndex: 2,
                        data: memory,
                        smooth: true
                    },
                ]
            });
        })


    }

    componentWillUnmount() {
        this._handleStopSearch();
    }

    _handleStopSearch() {
        client.end();
    }

    render() {
        return (
            <div id="main" style={{height: '500px', width: '100%'}}>

            </div>
        );
    }

}

function mapStateToProps(store) {
    return {
        provinceStatistic: store.DASNcovReducer.provinceStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(ProvinceChart));