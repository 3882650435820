class EcgFileTime {
    constructor(dataArray, startPos) {
        let index = startPos;

        this.hour = dataArray[index];
        if (this.hour < 10) {
            this.hour = "0" + this.hour.toString();
        }
        index++;

        this.minute = dataArray[index];
        if (this.minute < 10) {
            this.minute = "0" + this.minute.toString();
        }
        index++;

        this.second = dataArray[index];
        if (this.second < 10) {
            this.second = "0" + this.second.toString();
        }
        index++;

        this.millisecond = dataArray[index];
        index++;

        this.length = index - startPos;
    }

    getTime() {
        return this.hour.toString() + ":" + this.minute.toString() + ":" + this.second.toString();
    }
}

export default EcgFileTime;