/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    guardians: [],
    guarderBinders: [],
    guarder: {},
    binder: {},
    binderGuarders: [],
    pagination: {},
    isLoading: false,
    showBinderListModal: false,
    showGuarderListModal: false,
    showGuarderBindModal:false
};

const UserReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_GUARDER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL:
            return Object.assign({}, state, {
                showBinderListModal: action.showBinderListModal
            });
        case ActionTypes.ECG_GUARDER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_GUARDER_REFRESH_GUARDER:
            return Object.assign({}, state, {
                guarder: action.guarder
            });
        case ActionTypes.ECG_GUARDER_SEARCH:
            return Object.assign({}, state, {
                guardians: action.guardians,
                pagination: action.pagination
            });
        case ActionTypes.ECG_GUARDER_REFRESH_BINDERS:
            return Object.assign({}, state, {
                guarderBinders: action.guarderBinders
            });
        case ActionTypes.ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL:
            return Object.assign({}, state, {
                showGuarderBindModal: action.showGuarderBindModal
            });
        case ActionTypes.ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL:
            return Object.assign({}, state, {
                showGuarderListModal: action.showGuarderListModal
            });
        case ActionTypes.ECG_BINDER_REFRESH_GUARDERS:
            return Object.assign({}, state, {
                binderGuarders: action.binderGuarders
            });
        case ActionTypes.ECG_BINDER_REFRESH_BINDER:
            return Object.assign({}, state, {
                binder: action.binder
            });
        default:
            return state
    }
};

export default UserReducer;