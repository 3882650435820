/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';
import ReactMarkdown from 'react-markdown';

import {
    Modal,
    Button,
    Tabs,
    Popconfirm,
    Form,
    Row,
    Col,
    DatePicker,
    Table,
    Input,
    message,
    Icon,
    Descriptions,
    Tag,
    Popover,
    Switch,
    Alert,
    Radio
} from "antd";

import CircularProgress from '@material-ui/core/CircularProgress';

import {
    refreshSearchCondition,
    searchFileBound,
    downloadDeviceFile,
    refreshDownloadFlag,
    downloadFileBytes,
    getFileReadStatus
} from "../../../actions/DeviceFileDownloadAction";
import {
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";

import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {
    AnnotationFileSourceType,
    DataFileSourceType,
    DeviceFileType,
    DeviceFileTypeTemp,
    OnlineState
} from "../../../Enums";
import DateRangeDivider from "../../../../../components/DateRangeDivider";
import mqtt from "mqtt";
import TextArea from "antd/es/input/TextArea";
import Progress from "./Progress";
import * as ActionTypes from "../../../ActionTypes";
import {unbindGuarder} from "../../../actions/BinderAction";
import {ECG_LOG_DEVICE_DOWNLOAD_CONFIRM} from "../../../ActionTypes";
import {setReadOnly} from "echarts/lib/util/clazz";
import {Label_12_Wrapper_12, Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import EcgTransferStatus from "../../../components/EcgTransferStatus";
import {writeAnnotationFile} from "../../../actions/HistoryAction";
import Label from "react-bootstrap/lib/Label";
import EnumItemRadio from "../../../../../components/EnumItemRadio";
import {getPastDynastiesVersion} from "../../../actions/DeviceAction";

let client;
let clientSend;
let receiveLog = " ";
let endMsg = "";
let percentage = 0;
let allCount = 0;
let per = 0;
let successCnt = 0;
let failCnt = 0;
let isDownload = false;
let isShowMsg = false;
let clientIdTemp = "";
let isCancel = false;
let dtStart;
let dtSuccess;
let secondsFail = 0;
let lastFileIdx;
let isFail = false;
let isCancelDownload = false;
let lstData = [];
let generationId = "";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            progressValue: 0, // 进度条背景宽度
            percentage: 0, // 进度条百分比
            log:"",
            appSettingSocketStatus: false,
            fileDownloadPath:"",
            downloadFrom:0,
        }
    }

    componentWillMount() {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        clientIdTemp = this._guid();
        searchCondition.clientId = clientIdTemp;
        searchCondition.deviceStatus = "0";
        searchCondition.appStatus = "0";
        searchCondition.fileDownloadPath = "";
        searchCondition.source="10";
        this.props.dispatch(refreshSearchCondition(searchCondition));

        this._mqttConnect();

        this.timer = setInterval(() => {
            const {searchCondition} = this.props
            if (searchCondition.deviceCode !== undefined && searchCondition.deviceCode !== null  && searchCondition.deviceCode !== ""  && searchCondition.deviceCode.length === 8
                && searchCondition.fileType !== undefined && searchCondition.fileType !== null && searchCondition.fileType !== "") {

                if (!this.props.isLoading) {
                    this.props.dispatch(searchFileBound(searchCondition));
                    this.props.dispatch(getFileReadStatus(searchCondition.deviceCode,searchCondition.fileType,searchCondition.source,this.state.formatMessage));
                    this.props.dispatch(refreshSearchCondition(searchCondition));
                }

            }
        }, 30000);
    }

    componentWillUnmount() {
        window.clearInterval(this.timer);
        let searchCondition = Object.assign({}, this.props.searchCondition);
        clientSend.publish('D9K_MQTT_DOWNLOAD/' + searchCondition.clientId + "receive","cancel", function (err) {
            console.log(err);
        });
        console.log("clsoe");

        searchCondition.deviceCode = null;
        searchCondition.fileType = null;
        searchCondition.codeFrom = null;
        searchCondition.codeTo = null;
        searchCondition.fileCount = null;
        searchCondition.fileBoundFromDevice = null;
        searchCondition.fileLowerBound = null;
        searchCondition.fileDownloadPath = null;
        searchCondition.deviceStatus = null;
        searchCondition.appStatus = null;
        this.props.dispatch(refreshSearchCondition(searchCondition));
        client.end();
        clientSend.end();
    }

    componentDidMount() {

    }

    _mqttConnect(){
        let searchCondition = Object.assign({}, this.props.searchCondition);
        let self = this;
        percentage = 0;
        per = 0;

        // mqtt 连接"receive"
        clientSend = mqtt.connect('ws://140.206.121.162:7768/mqtt');
        console.log("clientId:" + clientIdTemp);
        clientSend.on('connect', function () {
            clientSend.subscribe('D9K_MQTT_DOWNLOAD/' + clientIdTemp + "receive", function (err) {
                console.log(err);
                if (!err) {
                    console.log("subscribe successed");
                } else {
                    console.log("subscribe failed");
                }
            })
        })

        // mqtt 连接
        client = mqtt.connect('ws://140.206.121.162:7768/mqtt');
        client.on('connect', function () {
            client.subscribe('D9K_MQTT_DOWNLOAD/' + clientIdTemp, function (err) {
                console.log(err);
                if (!err) {
                    console.log("subscribe successed");
                } else {
                    console.log("subscribe failed");
                }
            })
        })

        //mqtt 接收信息
        client.on('message', function (topic, message) {
            // message is Buffer
            let obj = JSON.parse(message.toString());
            console.log("message:" + message);
            console.log("obj:" + obj);

            let d = new Date();
            if (obj.searchType !== undefined || obj.fileIndex !== undefined || obj.message !== undefined) {
                let strTemp = "";
                let strMsg = "";
                strTemp = strTemp + "<font color='blue'>" + d.toLocaleTimeString() + "</font>";
                if (obj.message === "fail") {
                    strTemp = strTemp + "<b style={{color: \"red\",overflowWrap: \"break-word\"}}> <font color='red'>";
                    strMsg = "失败";
                    let dt = new Date();
                    secondsFail = secondsFail + parseInt(dt - dtSuccess) / 1000;
                    dtSuccess = new Date();
                    isFail = true;
                } else if (obj.message === "wait") {
                    strTemp = strTemp + "<b style={{color: \"black\",overflowWrap: \"break-word\"}}> <font color='black'>";
                    strMsg = "所需时间较长，请稍等";
                } else {
                    strTemp = strTemp + "<b style={{color: \"black\",overflowWrap: \"break-word\"}}> <font color='black'>";
                    strMsg = "成功";
                    dtSuccess = new Date();
                    lastFileIdx = obj.fileIndex;
                }
                if (obj.searchType === "server") {
                    strTemp = strTemp + "  从服务器中获取" + obj.fileIndex + "文件"
                } else if (obj.searchType === "client") {
                    strTemp = strTemp + "  从设备中获取" + obj.fileIndex + "文件"
                } else if (obj.searchType === "clienting") {
                    strTemp = strTemp + "  正在从设备中获取" + obj.fileIndex + "文件"
                }
                // strTemp = strTemp + "  " + obj.searchType + "中获取" + obj.fileIndex + "文件" + obj.message;

                strTemp = strTemp + strMsg + "</font> </b>";
                receiveLog = strTemp + " <br> " + receiveLog;
            }
            endMsg = obj.endMsg;

            if (endMsg !== "yes") {
                if (obj.isCurrent === "true") {
                    percentage = percentage + 1;
                    if (obj.message === "success") {
                        successCnt = successCnt + 1;
                    } else if (obj.message === "fail") {
                        failCnt = failCnt + 1;
                    }
                }
            } else {
                percentage = allCount;
                if (failCnt <  allCount) {
                    self._setReceiveLog("black","正在下载中，请稍等。");
                }
            }
            per = Math.ceil(percentage / allCount * 100);
        });

    }

    _handleCloseMsg() {
        let formatMessage = this.state.formatMessage;
        let self = this;
        Modal.confirm({
            title: formatMessage({id: "ECG_HISTORY_DATA_FILE_DOWNLOAD"}),
            content: "是否取消下载？",
            okType: 'danger',
            okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
            cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
            onOk() {
                self._handleCloseDownload();
            }
        })
    }

    //取消下载
    _handleCloseDownload(){
        let searchCondition = Object.assign({}, this.props.searchCondition);
        let self = this;
        console.log("searchCondition.clientId:" + searchCondition.clientId);
        clientSend.publish('D9K_MQTT_DOWNLOAD/' + searchCondition.clientId + "receive","close", function (err) {
            console.log(err);
            if (!err) {
                // console.log("send successed");
                isCancelDownload = true;
                let interval = setInterval(() => {
                    if (!isDownload) {
                        // message.warning('已取消下载！');
                        let strTemp = "";
                        let d = new Date();
                        strTemp = "<font color='blue'>" + d.toLocaleTimeString() + "</font>";
                        strTemp = strTemp + "<b style={{color: \"red\",overflowWrap: \"break-word\"}}> <font color='red'>";
                        strTemp = strTemp + "已取消下载" + "</font> </b>";
                        receiveLog = strTemp + " <br> " + receiveLog;
                        isCancelDownload = false;
                        clearInterval(interval);
                    } else {
                        console.log("setInterval");
                    }
                }, 100);

            } else {
                console.log("send failed");
            }
        });

    }

    _setReceiveLog(strColor,strMsg) {
        let strTemp = "";
        let d = new Date();
        strTemp = "<font color='blue'>" + d.toLocaleTimeString() + "</font>";
        strTemp = strTemp + "<b style={{color: \"red\",overflowWrap: \"break-word\"}}> <font color='" + strColor + "'>";
        strTemp = strTemp + strMsg + "</font> </b>";
        receiveLog = strTemp + " <br> " + receiveLog;
    }

    _Bytes2Str(arr)

    {

        let str = "";

        for(let i=0; i<arr.length; i++)

        {

            let tmp = arr[i].toString(16);

            if(tmp.length == 1)

            {

                tmp = "0" + tmp;

            }

            str += tmp;

        }

        return str;

    }

    _handleDownloadFiles() {
        let searchCondition = Object.assign({}, this.props.searchCondition);

        if (isDownload) {
            message.warning('正在下载中，请勿重复下载！');
            return;
        }
        isCancelDownload = false;
        if (this._handleCheck(searchCondition)){
            dtStart = new Date();
            secondsFail = 0;
            dtSuccess = new Date();
            isFail = false;

            let name;
            let tempFileEnd;
            receiveLog = "";
            this._setReceiveLog("black","准备下载。");
            successCnt = 0;
            failCnt = 0;
            percentage = 0;
            per = 0;
            endMsg = "no";
            this.setState({ "log": " " });
            //设置下载文件名
            if (searchCondition.codeTo !== undefined && searchCondition.codeTo !== "") {
                // if (searchCondition.fileType === "5" || searchCondition.fileType === "6" || searchCondition.fileType === "7" || searchCondition.fileType === "8" || searchCondition.fileType === "9") {
                //     let strIdx;
                //     let myDate = new Date();
                //     let arrB = new Array() ;
                //     let num = parseInt(searchCondition.codeTo);
                //     arrB.push(num & 0xFF);
                //     arrB.push((num >> 8) & 0xFF);
                //     arrB.push((num >> 16) & 0xFF);
                //     arrB.push((num >> 24) & 0xFF);
                //
                //     strIdx = this._Bytes2Str(arrB);
                //     // console.log(strIdx);
                //
                //     // console.log(myDate.toLocaleString());
                //     let strDate = myDate.toLocaleString().replaceAll("/","0").replaceAll(":","").replaceAll(" ","");
                //     console.log(strDate);
                //     //72070000_error_20220401222251_01000000.log
                //     // name = "";
                //     if (searchCondition.fileType === "5") {
                //         name = searchCondition.deviceCode + "_loginfo_" + strDate + "_" + strIdx + ".log";
                //     } else if (searchCondition.fileType === "6") {
                //         name = searchCondition.deviceCode + "_work_" + strDate + "_" + strIdx + ".log";
                //     } else if (searchCondition.fileType === "7") {
                //         name = searchCondition.deviceCode + "_error_" + strDate + "_" + strIdx + ".log";
                //     } else if (searchCondition.fileType === "8") {
                //         name = searchCondition.deviceCode + "_warning_" + strDate + "_" + strIdx + ".log";
                //     } else if (searchCondition.fileType === "9") {
                //         name = searchCondition.deviceCode + "_powerup_" + strDate + "_" + strIdx + ".log";
                //     } else {
                //         name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + searchCondition.codeFrom + ".log";
                //     }
                //
                // } else {
                //     name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + searchCondition.codeTo + ".zip";
                // }
                name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + searchCondition.codeTo;
            } else {
                if (searchCondition.fileType === "0") {
                    //历史文件
                    tempFileEnd = parseInt(searchCondition.codeFrom) + parseInt(searchCondition.fileCount) - 1;
                    name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + tempFileEnd;
                } else if (searchCondition.fileType === "1") {
                    //日期文件
                    name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + searchCondition.codeFrom;
                } else if (searchCondition.fileType === "2" || searchCondition.fileType === "3") {
                    //时间文件
                    let strS = searchCondition.codeFrom.slice(0,4) + "/" + searchCondition.codeFrom.slice(4,6) + "/" +searchCondition.fileStart.slice(6);
                    let dateS = new Date(strS);
                    let strE;
                    dateS.setDate(dateS.getDate() + parseInt(searchCondition.fileCount));
                    strE = dateS.getFullYear() +  (parseInt(dateS.getMonth()) + 1) + dateS.getDate();
                    name = searchCondition.deviceCode + "_" + searchCondition.codeFrom + "-" + strE;
                } else {
                    name = searchCondition.deviceCode;
                }
            }
            name = name + ".zip";
            //计算下载文件数
            if (searchCondition.fileCount !== undefined && searchCondition.fileCount !== "") {
                allCount = parseInt(searchCondition.fileCount);
            } else {
                if (searchCondition.fileType === "0") {
                    //历史文件
                    allCount = parseInt(searchCondition.codeTo) - parseInt(searchCondition.codeFrom) + 1;
                } else if (searchCondition.fileType === "1") {
                    //日期文件
                    allCount = 1;
                } else if (searchCondition.fileType === "2" || searchCondition.fileType === "3") {
                    //时间文件
                    let strS = searchCondition.codeFrom.slice(0,4) + "/" + searchCondition.codeFrom.slice(4,6) + "/" +searchCondition.codeFrom.slice(6);
                    let strE = searchCondition.codeTo.slice(0,4) + "/" + searchCondition.codeTo.slice(4,6) + "/" +searchCondition.codeTo.slice(6);
                    let dateS = new Date(strS);
                    let dateE = new Date(strE);
                    let days = (dateE - dateS)/(1*24*60*60*1000) + 1;

                    allCount = days;
                } else {
                    allCount = 0;
                }
            }
            //设置clientId
            // searchCondition["clientId"] = this._guid();
            //连接mqtt
            //this._mqttConnect();

            if (this.state.appSettingSocketStatus) {
                const annotationFilePath = this.state.fileDownloadPath;
                this.props.dispatch(downloadFileBytes(searchCondition,annotationFilePath, this.state.formatMessage, () => {
                    message.success("文件下载成功，路径：" + annotationFilePath);
                    console.log("文件下载成功，路径：" + annotationFilePath);
                }));
            } else {
                this.props.dispatch(downloadDeviceFile(searchCondition, name,generationId, this.state.formatMessage));
            }

            let self = this;
            //从mqtt获取log
            let interval = setInterval(() => {
                if (endMsg === "yes" ) {
                    console.log("clearInterval");
                    this.props.dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
                    if (failCnt <  allCount) {
                        // self._setReceiveLog("black","下载已完成。");
                    }
                    clearInterval(interval);
                    isDownload = false;
                } else {
                    console.log("setInterval");
                    this.setState({ "log": receiveLog });
                }
                this.setState({ "percentage": Math.ceil(percentage / allCount * 100) });
            }, 500);

            isDownload = true;
            this.props.dispatch(refreshSearchCondition(searchCondition));
        }
    }

    // _getEcgFileFolder(historyFileIndex) {
    //     let historyFileFolder = this.state.d9kEcgDatabaseRoot + "\\" + this.state.deviceCode + "\\0000";
    //     // 历史文件按5000个一组进行分文件夹存储
    //     const historyFileGroupIndex = historyFileIndex / 5000;
    //     historyFileFolder = historyFileFolder + "\\" + (Math.ceil(historyFileGroupIndex) * 5000) + "\\";
    //     return historyFileFolder;
    // }

    _guid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }

    _handleCheck(condition){
        if (condition.deviceCode === undefined || condition.deviceCode === "") {
            message.warning('设备编号不能为空！');
            return false;
        }

        if (condition.fileType === undefined || condition.fileType === "") {
            message.warning('文件类型不能为空！');
            return false;
        }

        if (condition.codeFrom === undefined || condition.codeFrom === "") {
            message.warning('下载文件起始编号不能为空！');
            return false;
        }

        if (condition.codeTo === undefined || condition.codeTo === "") {
            message.warning('下载文件末尾编号不能为空！');
            return false;
        }

        if (condition.deviceCode.length !== 8) {
            message.warning('设备编号的长度应为8！');
            return false;
        }

        if (parseInt(condition.codeFrom) < 1) {
            message.warning('下载文件起始编号不能小于1！');
            return false;
        }

        if (parseInt(condition.codeTo) < 1) {
            message.warning('下载文件末尾编号不能小于1！');
            return false;
        }

        if ((parseInt(condition.codeFrom) > parseInt(condition.codeTo))) {
            message.warning('下载文件起始编号不能大于末尾编号！');
            return false;
        }

        // if (condition.fileType === "2" || condition.fileType === "3") {
        //     if (condition.codeFrom.length < 8) {
        //         message.warning('请输入正确的下载文件起始编号（20220101）！');
        //         return false;
        //     }
        //
        //     if (condition.codeTo !== undefined && condition.codeTo.length < 8) {
        //         message.warning('请输入正确的下载文件末尾编号（20220101）！');
        //         return false;
        //     }
        // }

        if (this.state.appSettingSocketStatus) {
            if (condition.fileType === "5" || condition.fileType === "6" || condition.fileType === "7" || condition.fileType === "8" || condition.fileType === "9") {
                message.warning('暂不支持下载日志文件');
                return false;
            }
        }

        if (!isShowMsg) {
            this._showMsg();
            if (!isCancel) {
                return false;
            } else {
                isCancel = false;
            }
        } else {
            isShowMsg = false;
        }

        return true;
    }

    _showMsg() {
        let cnt = this._checkValue();
        let condition = Object.assign({}, this.props.searchCondition);

        if (cnt > 0) {
            const formatMessage = this.state.formatMessage;
            const self = this;
            const strId = condition.deviceCode;
            Modal.confirm({
                title: formatMessage({id: "ECG_HISTORY_DATA_FILE_DOWNLOAD"}),
                content: strId + "中有" + cnt + "个文件因在服务器上不存在，需从设备上获取，预计多耗时" + cnt * 10 + "秒，是否继续下载？",
                okType: 'danger',
                okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
                cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
                onCancel() {
                    // this.props.dispatch(refreshDownloadFlag(false));
                    isCancel = false;
                    isShowMsg = false;
                },
                onOk() {
                    // this.props.dispatch(refreshDownloadFlag(true));
                    isCancel = true;
                    self._handleDownloadFiles();
                    isCancel = false;
                }
            })
            isShowMsg = true;
        } else {
            isCancel = true;
        }
    }
    //判断数值是否在范围中
    _checkValue() {
        let condition = Object.assign({}, this.props.searchCondition);
        let cnt = 0;

        if (condition.fileType === "1" || condition.fileType === "5" || condition.fileType === "6" || condition.fileType === "75" || condition.fileType === "8" || condition.fileType === "9") {
            return 0;
        }

        if (condition.fileLowerBound !== undefined && condition.fileLowerBound !== "") {
            let str = condition.fileLowerBound.toString();
            str = str.substring(11).replace("\r\n",",");
            let strS = str.split(",");
            for (let i = parseInt(condition.codeFrom); i <= parseInt(condition.codeTo); i++) {

                let bolExist = false;
                for (let j in strS) {
                    let strT = strS[j].split("  --  ")
                    if (strT.length > 1) {
                        if (i >= parseInt(strT[0]) && i <= parseInt(strT[1]) ) {
                            bolExist = true;
                            break;
                        }
                    }
                }

                if (!bolExist) {
                    cnt = cnt + 1;
                }
            }

        }
        return cnt;
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        let targetValue = value.target ? value.target.value : value;
        if ((value || value === 0) && targetValue !== ""){
            searchCondition[name] = value.target ? value.target.value : value;
        } else {
            delete searchCondition[name];
        }

        if(name === "version"){
            lstData.map((item, index) => {
                if(item.value === value){
                    generationId = item.label.replaceAll("(当世)","");
                }
            });
            console.log("generationId:" + generationId);
        }

        if (name === "fileType") {
            if (searchCondition.deviceCode === undefined || searchCondition.deviceCode === "") {
                message.warning('设备编号不能为空！');
                return;
            }

            if (searchCondition.fileType === undefined || searchCondition.fileType === "") {
                message.warning('文件类型不能为空！');
                return;
            }

            if (value === "1" || value === "4" || value === "5" || value === "6" || value === "7" || value === "8" || value === "9") {
                searchCondition["codeFrom"] = 1;
                searchCondition["codeTo"]= 1;
                searchCondition.fileCount = 1;
            }

            if (value === "5" || value === "6" || value === "7" || value === "8" || value === "9") {
                this.setState({downloadFrom: 2});
            } else {
                this.setState({downloadFrom: 1});
            }
        }

        if(name === "deviceCode"){

            if (searchCondition.deviceCode !== undefined && searchCondition.deviceCode !== null && searchCondition.deviceCode !== "" && searchCondition.deviceCode.length === 8){

                //获取设备的所有世代信息
                this.props.dispatch(getPastDynastiesVersion(searchCondition.deviceCode, this.state.formatMessage));

            }
        }

        if (name === "deviceCode" || name === "fileType" || name === "source"  || name === "version" ){

            if (searchCondition.deviceCode !== undefined && searchCondition.deviceCode !== null && searchCondition.deviceCode !== "" && searchCondition.deviceCode.length === 8
                && searchCondition.fileType !== undefined && searchCondition.fileType !== null && searchCondition.fileType !== "") {

                if (!this.props.isLoading) {
                    searchCondition.fileBoundFromDevice = "";
                    searchCondition.fileLowerBound = "";
                    this.props.dispatch(searchFileBound(searchCondition));
                    this.props.dispatch(getFileReadStatus(searchCondition.deviceCode,searchCondition.fileType,searchCondition.source,this.state.formatMessage));
                    console.log(searchCondition);

                }

            }

        }

            //计算末尾编号或文件数量
        if (searchCondition.codeFrom !== undefined && searchCondition.codeFrom !== "") {

            if (name === "codeFrom") {
                if (searchCondition.codeTo !== undefined && searchCondition.codeTo !== "") {
                    searchCondition.fileCount = parseInt(searchCondition.codeTo) - parseInt(searchCondition.codeFrom) + 1;
                } else if (searchCondition.fileCount !== undefined && searchCondition.fileCount !== "") {
                    searchCondition.codeTo = parseInt(searchCondition.codeFrom) + parseInt(searchCondition[name]) - 1;
                }
            } else if (name === "codeTo") {
                if (searchCondition[name] === undefined || searchCondition[name] === "" ) {
                    delete searchCondition["fileCount"];
                } else {
                    searchCondition["fileCount"] = parseInt(searchCondition.codeTo) - parseInt(searchCondition.codeFrom) + 1;
                }
            } else if (name === "fileCount") {
                if (searchCondition[name] === undefined || searchCondition[name] === "" ) {
                    delete searchCondition["codeTo"];
                } else {
                    searchCondition["codeTo"] = parseInt(searchCondition.codeFrom) + parseInt(searchCondition[name]) - 1;
                }
            }
        }

        this.props.dispatch(refreshSearchCondition(searchCondition));
        console.log(searchCondition);
    }

    _handleChangeDownload(searchCondition){
        if (this.state.appSettingSocketStatus) {
            this.setState({appSettingSocketStatus: false});
        } else {
            searchCondition["fileDownloadPath"] = "D:\\d9k";
            console.log(searchCondition["fileDownloadPath"]);
            this.setState({fileDownloadPath: "D:\\d9k"});
            this.setState({appSettingSocketStatus: true});
            this.props.dispatch(refreshSearchCondition(searchCondition));
        }

    }

    _handleSetPath(value) {
        this.setState({fileDownloadPath: value.target ? value.target.value : value});
    }

    _getFileStatus(){
        const {fileReadStatus} = this.props;
        const {searchCondition} = this.props;
        let text = "";

        if(searchCondition.fileType === "0"){
            text = "历史文件已读状态：" + "\n ";
        } else if(searchCondition.fileType === "2"){
            text = "时间索引已读状态：" + "\n ";
        } else if(searchCondition.fileType === "3"){
            text = "事件索引已读状态：" + "\n ";
        }
        console.log("fileReadStatus:" + fileReadStatus);
        if (fileReadStatus.length > 0) {
            for (var i = 0; i < fileReadStatus.length; i++) {
                text += fileReadStatus[i].beginIdx + "--" + fileReadStatus[i].endIdx + "\n "
            }
        }

        return text;
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;
        const {content} = this.props;
        let fileLowerBound = this._getFileStatus();
        console.log("fileLowerBound:" + fileLowerBound);
        if(this.props.isFinish){
            this._setReceiveLog("black","下载已完成。");
            let dt = new Date();
            let minute=  Math.floor(parseInt(dt - dtStart) / (1000 * 60));
            let seconds=  Math.round(Math.floor(parseInt(dt - dtStart) % (1000 * 60)) / 1000);
            this._setReceiveLog("black","下载用时：" + minute + "分"+ seconds + "秒。");

            let speed;
            let second = Math.round(parseInt(dt - dtStart) / 1000) - Math.round(secondsFail);
            if (second > 0) {
                speed = Math.round(this.props.fileSize / 1000 / second);
                if (isNaN(speed)) {
                    speed = 0;
                }
            } else {
                speed = 0;
            }

            if(isFail) {
                this._setReceiveLog("black","每个文件平均用时：" + (second / successCnt).toFixed(2)+ "s。");
                this._setReceiveLog("black","最后一个成功下载的文件：" + lastFileIdx );
            } else {
                this._setReceiveLog("black","下载速度：" + speed + "kb/s。");
            }

            console.log("失败时间：" + Math.round(secondsFail));
            if(isCancelDownload){
                this._setReceiveLog("black","正在取消下载...");
                isCancelDownload = false;
            }
        }

        let mapVersion =this.props.mapVersion;

        if(mapVersion !== undefined && mapVersion !== null ){

            lstData = [];
            mapVersion.sort((curr, next) => {
                let a = curr;
                let b = next;

                if (a < b) return 1;
                if (a > b) return -1;
                return 0;
            });

            mapVersion.map((item, index) => {
                if(index === 0){
                    lstData.push({value: '00', label: item + "(当世)"});
                } else{
                    lstData.push({value: item.substring(2,4), label: item});
                }
            })

        }

        return (

            <Form style={{backgroundColor: "#FFF",paddingLeft: "21px"}} className="page-width">
                {/*<CheckUpdateVersion/>*/}
                <Row>
                    <Col span={13}>
                        <Row>
                            <Col span={8} style={{background:"gold"}}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_TERMINAL_SOCKET'})}
                                    style={{marginLeft:10}}>
                                    <Switch style={{width: 80}}
                                            checked={this.state.appSettingSocketStatus}
                                            onChange={() => this._handleChangeDownload(searchCondition)}/>

                                </Form.Item>
                            </Col>
                            <Col span={8} style={{background:"gold"}}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_ROOT_PATH'})}>
                                    {this.state.appSettingSocketStatus ?
                                        <Input
                                            value={this.state.fileDownloadPath}
                                            style={{width: 200}}
                                            onChange={(value) => {
                                                this._handleSetPath(value)
                                            }}/> :
                                        <Input
                                            value={this.state.fileDownloadPath}
                                            style={{width: 200}}
                                            disabled={"disabled"}
                                            onChange={(value) => {
                                                this._handleSetPath(value)
                                            }}/>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={6} style={{background:"gold"}}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_TERMINAL_SOCKET_DOWNLOAD'})}>
                                    <a href={"http://140.206.121.162:8766/upload/ecg/contents/202202/TerminalSocket_v1.0.0.0.zip"}>Terminal Socket <FormattedMessage id={"COMMON_BUTTON_DOWNLOAD"}/></a>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}>
                                    {this.props.isLoading ?
                                        <Input
                                            defaultValue={searchCondition.deviceCode}
                                            style={{width: 200}}
                                            disabled={"disabled"}
                                            onChange={(value) => {
                                                this._handleChange("deviceCode", value)
                                            }}/> :
                                        <Input
                                            defaultValue={searchCondition.deviceCode}
                                            style={{width: 200}}
                                            onChange={(value) => {
                                                this._handleChange("deviceCode", value)
                                            }}/>
                                    }

                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Row>
                                    <Col span={6}>
                                        <Form.Item
                                            label={formatMessage({id: 'DAS_DEVICE_FILE_TYPE'})}>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        {this.state.downloadFrom === 1 ?
                                            <label style={{paddingTop: 8,color: "red"}}>{"（从服务器下载）"}</label> :
                                            this.state.downloadFrom === 2 ?
                                                <label style={{paddingTop: 8,color: "red"}}>{"（从设备下载）"}</label> :
                                                null
                                        }

                                    </Col>
                                </Row>

                                {this.state.appSettingSocketStatus ?
                                    this.props.isLoading ?
                                        <EnumItemSelect data={DeviceFileTypeTemp.List}
                                                        value={searchCondition.fileType}
                                                        style={{width: 200}}
                                                        disabled={"disabled"}
                                                        onChange={(value) => {
                                                            this._handleChange("fileType", value)
                                                        }}/> :
                                        <EnumItemSelect data={DeviceFileTypeTemp.List}
                                                        value={searchCondition.fileType}
                                                        style={{width: 200}}
                                                        onChange={(value) => {
                                                            this._handleChange("fileType", value)
                                                        }}/>
                                    :
                                    this.props.isLoading ?
                                        <EnumItemSelect data={DeviceFileType.List}
                                                        value={searchCondition.fileType}
                                                        style={{width: 200}}
                                                        disabled={"disabled"}
                                                        onChange={(value) => {
                                                            this._handleChange("fileType", value)
                                                        }}/> :
                                        <EnumItemSelect data={DeviceFileType.List}
                                                        value={searchCondition.fileType}
                                                        style={{width: 200}}
                                                        onChange={(value) => {
                                                            this._handleChange("fileType", value)
                                                        }}/>

                                }
                            </Col>
                            <Col span={8}>
                                <Row>
                                    {
                                        this.props.isLoading ?
                                            <Col>
                                                <CircularProgress className={this.props.progress}
                                                                  style={{marginTop: 20,width:30,height:30}}/>
                                            </Col>
                                            : null
                                    }
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_SOURCE'})}>
                                    <EnumItemRadio data={DataFileSourceType.List}
                                                   value={searchCondition.source}
                                                   onChange={(value) => {
                                                       this._handleChange("source", value)
                                                   }}/>
                                </Form.Item>

                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_VERSION'})}>
                                    <EnumItemSelect data={lstData}
                                                    value={searchCondition.version}
                                                    style={{width: 240}}
                                                    onChange={(value) => {
                                                        this._handleChange("version",value);
                                                    }}/>
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_CODE_START'})}>
                                    <Input
                                        value={searchCondition.codeFrom}
                                        style={{width: 200}}
                                        onChange={(value) => {
                                            this._handleChange("codeFrom", value)
                                        }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_CODE_END'})}>
                                    <Input
                                        value={searchCondition.codeTo}
                                        style={{width: 200}}
                                        onChange={(value) => {
                                            this._handleChange("codeTo", value)
                                        }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'DAS_DEVICE_FILE_COUNT'})}>
                                    <Input
                                        value={searchCondition.fileCount}
                                        style={{width: 200}}
                                        onChange={(value) => {
                                            this._handleChange("fileCount", value)
                                        }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item>
                                    {this.props.isLoading ?
                                        <Button icon="download" style={{marginTop: 15}} disabled={"disabled"}
                                                onClick={() => this._handleDownloadFiles(searchCondition)}>
                                            <FormattedMessage id='COMMON_BUTTON_DOWNLOAD'/>
                                        </Button> :
                                        <Button icon="download" style={{marginTop: 15}}
                                                onClick={() => this._handleDownloadFiles(searchCondition)}>
                                            <FormattedMessage id='COMMON_BUTTON_DOWNLOAD'/>
                                        </Button>
                                    }
                                    <Button icon="cancel" style={{marginTop: 15,marginLeft:10}}
                                            onClick={() => this._handleCloseMsg()}>
                                        <FormattedMessage id='COMMON_BUTTON_CANCEL'/>
                                    </Button>
                                    <Progress allNum={100} percentageNum={per}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={"文件下载成功数："}
                                    style={{marginTop: 15}}>
                                    <label style={{width: 200}}>{successCnt}</label>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={"文件下载失败数："}
                                    style={{marginTop: 15}}>
                                    <label style={{width: 200}}>{failCnt}</label>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={21}>
                                <div id={"scrollDiv"} style={{height: '500px', overflowY:'scroll'}} dangerouslySetInnerHTML={{__html: receiveLog}}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label={"设备在线状态："}>
                                    {searchCondition.generationInvalid === 1 ?
                                        <Tag color="blue">未重置</Tag> :
                                        <EcgTransferStatus status={parseInt(searchCondition.deviceStatus)}/>
                                    }
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={"APP在线状态："}>
                                    {searchCondition.generationInvalid === 1 ?
                                        <Tag color="blue">未重置</Tag> :
                                        <EcgTransferStatus status={parseInt(searchCondition.appStatus)}/>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FILE_BOUND_LAST'})}>
                                <TextArea
                                    value={searchCondition.fileBoundFromDevice}
                                    style={{width: 300,height: 120}}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FILE_BOUND'})}
                                style={{marginTop: 15}}>
                                <TextArea
                                    value={fileLowerBound}
                                    style={{width: 300,height: 400}}
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>


        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.DeviceFileDownloadReducer.isLoading,
        showDetailModal: store.DeviceFileDownloadReducer.showDetailModal,
        searchCondition: store.DeviceFileDownloadReducer.searchCondition,
        isFinish: store.DeviceFileDownloadReducer.isFinish,
        fileSize: store.DeviceFileDownloadReducer.fileSize,
        fileReadStatus:store.DeviceFileDownloadReducer.fileReadStatus,
        mapVersion: store.EcgDeviceReducer.mapVersion,
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));