import React, {Component} from "react";
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import ChartPoint from "../components/ChartPoint";
import {Col, Row} from 'antd';
import {ybounds} from "plotly.js/src/traces/pointcloud/attributes";

/*******************************************************************************
 * 常量
 ******************************************************************************/

const NAMESPACE = "http://schemas.android.com/apk/res-auto";
/**
 * 中上位置
 */
const CENTER_TOP = 0;
/**
 * 中中位置
 */
const CENTER_CENTER = 1;
/**
 * 中下位置
 */
const CENTER_BOTTOM = 2;
/**
 * 标题文字颜色
 */
// const COLOR_TITLE = Color.parseColor("#000000");
/**
 * 曲线颜色
 * （有数据）
 */
// const COLOR_POINT = Color.parseColor("#0000FF");

/*******************************************************************************
 * 属性变量
 ******************************************************************************/
//画布
let mCanvas;
let mCanvas2;
let mCanvas3;
let mCanvas4;
//绘图对象
let ctx;
let ctx2;
let ctx3;
let ctx4;
/**
 * 标题文字
 */
let title = "";
/**
 * 标题文字颜色
 */
let titleColor = "rgb(0,0,0)";
/**
 * 标题位置
 */
let titleAlign = CENTER_TOP;
/**
 * 数据最大值，默认-1~1之间
 */
let maxXValue = 1;
/**
 * 数据最小值，默认最大值的负数
 */
let minXValue = -1;
/**
 * 数据最大值，默认-1~1之间
 */
let maxYValue = 1;
/**
 * 数据最小值，默认最大值的负数
 */
let minYValue = -1;
/**
 * 数据最大值，默认-1~1之间
 * （原始值）
 */
let originalMaxXValue = 1;
/**
 * 数据最小值，默认最大值的负数
 * （原始值）
 */
let originalMinXValue = -1;
/**
 * 数据最大值，默认-1~1之间
 * （原始值）
 */
let originalMaxYValue = 1;
/**
 * 数据最小值，默认最大值的负数
 * （原始值）
 */
let originalMinYValue = -1;
/**
 * 是否可以超出最大最小值，否则按最大最小值来画
 */
let drawOutOfRange = false;
/**
 * 数据点的数量
 * （默认要为0，若为0则依赖点间隔计算出来，
 *   不为0，则计算出点间隔）
 */
let pointNumber = 100;
/**
 * 点的大小
 */
let pointSize = 1;
/**
 * 点颜色
 */
let pointColor = "rgb(0,0,255)";

/*******************************************************************************
 * 类内用临时变量
 ******************************************************************************/
/**
 * 宽
 */
let measuredWidth = 0;
/**
 * 高
 */
let measuredHeight = 0;
/**
 * 边框宽
 */
let frameWidth = 1;
/**
 * 绘制的点
 */
let points = [];
/**
 * 辅助线
 */
let lines = new Array();
/**
 * 刷新曲线
 * （因为某些参数改变）
 */
let isRefresh = true;
/**
 * 单位数值的X轴高度
 */
let unitWidth;
/**
 * 单位数值的Y轴高度
 */
let unitHeight;
/**
 * 最小值的X坐标
 */
let xOfMinValue = 0;
/**
 * 最小值的Y坐标
 */
let yOfMinValue = 0;
/**
 * 最大值的X坐标
 */
let xOfMaxValue = 0;
/**
 * 最大值的Y坐标
 */
let yOfMaxValue = 0;
/**
 * 标题高度
 */
let titleHeight;
/*******************************************************************************
 * 事件触发相关临时变量
 ******************************************************************************/
let eventTimeStamp;
let moveDownFlag = false;
let translationX; // 移动X
let translationY; // 移动Y
let scale = 1; // 伸缩比例
let actionX;
let actionY;
let spacing;
let moveType; // 0=未选择，1=拖动，2=缩放
/**
 * 双击间400ms延时
 */
let doubleClickTimeOut = 400;
/**
 * 记录连续点击次数
 */
let clickCount = 0;
/**
 * 缩放节奏控制
 */
let zoomStep = 0;

//水平标尺与canvas的距离
var HORIZONTAL_AXIS_MARGIN = 50;
//竖直标尺与canvas的距离
var VERTICAL_AXIS_MARGIN = 50;
var AXIS_ORIGIN;

class ScatterChart extends Component {

    constructor(props) {
        console.log("ScatterChart",props);
        super(props);

        this.state = {
            refresh: false,
        }
    }

    componentWillMount() {
    }

    /**
     * 绘图回调
     */
    initCanvas(ctx) {

        switch (this.props.type) {
            case "rr":
                this.setRrScatterChart(this.props.x, this.props.y, this.props.density);
                break
            case "drr":
                this.setDrrScatterChart(this.props.x, this.props.y, this.props.density);
                break
            case "drrAngle":
                this.setDrrRadiusScatterChart(this.props.x, this.props.y, this.props.density);
                break
            default:
                this.setSampleScatterChart(this.props.x, this.props.y)
                break
        }
        //鼠标事件
        this.mouse(mCanvas);
        //单位数值的X轴宽度
        unitWidth = (xOfMaxValue - xOfMinValue) / (maxXValue - minXValue);
        //单位数值的Y轴高度
        unitHeight = (yOfMaxValue - yOfMinValue) / (maxYValue - minYValue);

        this.onDraw(ctx)
    }

    onDraw(ctx) {
        var w = mCanvas.width
        var h = mCanvas.height
        mCanvas.width = w;
        mCanvas.height = h;
        //绘制点
        this.drawPoints(ctx);
        //绘制标题
        // this.drawTitle(ctx);
        //绘制框框
        this.drawFrame(ctx);
        //绘制轴线
        this.drawAxis(ctx);
        //绘制辅助线
        this.drawLines(ctx);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("改变：", nextProps, nextContext, this, mCanvas, ctx);
        this.init(mCanvas);
        this.initCanvas(ctx, 1);
    }

    /**
     * RR间期散点图 显示
     */
    setRrScatterChart(
        xArray,
        yArray,
        density) {
        console.log("RR间期散点图");
        //最大显示范围
        let maxX = this.max(xArray);
        let maxY = this.max(yArray);
        var maxDensity = this.max(density);
        var minDensity = this.min(density);
        var deltaDensity = maxDensity - minDensity;
        //最大密度
        if (maxDensity == 0) {
            maxDensity = 1;
        }

        //设定曲线图参数
        this.setMinX(0);
        this.setMaxX(maxX);
        this.setMinY(0);
        this.setMaxY(maxY);
        this.setPointNumber(xArray.length);


        //点
        for (let i = 0; i < xArray.length; i++) {

            points[i].x = xArray[i];
            points[i].y = yArray[i];
            points[i].color = this.HSBToRGB((density[i] - minDensity) / deltaDensity * 360);
        }
    }

    /**
     * RR间期差值散点图
     * @param sc
     * @param charLayout
     * @param type
     * @param xArray
     * @param yArray
     * @param XlimD
     */
    setDrrScatterChart(
        xArray,
        yArray,
        drrDensity) {

        //----------------------------------
        //辅助线数据
        //----------------------------------
        //最大显示范围
        let minX = this.min(xArray);
        let minY = this.min(yArray);
        let maxX = this.max(xArray);
        let maxY = this.max(yArray);
        let absMaxXy = this.max([Math.abs(minX),
            Math.abs(minY),
            Math.abs(maxX),
            Math.abs(maxY)]
        );

        let range = this.min(
            [absMaxXy,
                Math.abs(Math.sqrt(1000 * 1000 * 2) / 2)]);
        //最大密度
        let maxDensity = this.max(drrDensity);
        if (maxDensity == 0) {
            maxDensity = 1;
        }


        //设定曲线图参数
        this.setMinX(-range);
        this.setMaxX(range);
        this.setMinY(-range);
        this.setMaxY(range);
        this.setPointNumber(xArray.length);

        console.log("absMaxXy:", range, absMaxXy)
        //点
        let count = 0;
        for (let i = 0; i < xArray.length; i++) {
            points[i].x = xArray[i];
            points[i].y = yArray[i];
            points[i].color = this.HSBToRGB(drrDensity[i] / maxDensity * 360);
        }

        //标题
        // title = type.getDescr() + " (点数：" + count + ")";

    }


    /**
     * RR间期差值角度半径散点图
     * @param sc
     * @param charLayout
     * @param type
     * @param xArray
     * @param yArray
     */
    setDrrRadiusScatterChart(
        xArray,
        yArray,
        drrDensity) {

        //----------------------------------
        //辅助线数据
        //----------------------------------
        //最大显示范围
        let minX = this.min(xArray);
        let minY = this.min(yArray);
        let maxX = this.max(xArray);
        let maxY = this.max(yArray);

        //设定曲线图参数
        this.setMinX(minX);
        this.setMaxX(maxX);
        this.setMinY(minY);
        this.setMaxY(maxY);
        //最大密度
        let maxDensity = this.max(drrDensity);
        if (maxDensity == 0) {
            maxDensity = 1;
        }

        //点
        this.setPointNumber(xArray.length);
        let count = 0;
        for (let i = 0; i < xArray.length; i++) {
            points[i].x = xArray[i];
            points[i].y = yArray[i];
            points[i].color = this.HSBToRGB(drrDensity[i] / maxDensity * 360);
        }

    }


    /**
     * RR间期差值角度半径散点图
     * @param sc
     * @param charLayout
     * @param type
     * @param xArray
     * @param yArray
     */
    setSampleScatterChart(
        xArray,
        yArray) {

        //----------------------------------
        //辅助线数据
        //----------------------------------
        //最大显示范围
        let minX = this.min(xArray);
        let minY = this.min(yArray);
        let maxX = this.max(xArray);
        let maxY = this.max(yArray);

        //设定曲线图参数
        this.setMinX(minX);
        this.setMaxX(maxX);
        this.setMinY(minY);
        this.setMaxY(maxY);
        //最大密度
        this.setPointNumber(xArray.length);
        let count = 0;
        for (let i = 0; i < xArray.length; i++) {
            points[i].x = xArray[i];
            points[i].y = yArray[i];
        }

    }


    max(arr) {
        if (!arr) {
            return 0
        }
        //求数组arr的最大值
        var max = arr[0];
        for (var i = 1; i < arr.length; i++) {
            if (arr[i] > max) {
                max = arr[i];
            }
        }
        return max;
    }


    min(arr) {
        if (!arr) {
            return 0
        }
        //求数组arr的最小值
        var min = arr[0];
        for (var i = 1; i < arr.length; i++) {
            if (min > arr[i]) {
                min = arr[i];
            }
        }
        return min;
    }

    /**
     * 绘制辅助线
     */
    drawLines() {

        if (lines == null) {
            return;
        }

    }

    /**
     * 绘制框
     *
     * @param canvas
     */
    drawFrame(ctx) {
        //画网格线
        ctx.beginPath()
        ctx.fillRect(frameWidth / 2, measuredWidth - frameWidth / 2, frameWidth / 2, measuredHeight - frameWidth / 2);
        ctx.closePath()
        ctx.fill()
    }

    /**
     * 绘制标题
     */
    drawTitle(ctx) {

        // Paint.FontMetrics
        ctx.beginPath()
        switch (titleAlign) {
            case CENTER_BOTTOM:
                ctx.fillText(this.props.title, measuredWidth / 2, measuredHeight - 10 - 800);
                break;
            case CENTER_CENTER:
                ctx.fillText(this.props.title, measuredWidth / 2, 800 / 2 - 800);
                break;
            case CENTER_TOP:
                ctx.fillText(this.props.title, measuredWidth / 2, 10);
                break;
            default:
                break;
        }
        ctx.closePath()
    }

    /**
     * 事件
     */
    mouse(canvas) {
        var self = this;
        if (canvas.addEventListener) {
            canvas.addEventListener('mousemove', mouseMove, false);
            canvas.addEventListener('mousedown', mouseDown, false);
            canvas.addEventListener('mouseup', mouseUp, false);
            // IE9, Chrome, Safari, Opera
            canvas.addEventListener("mousewheel", scaleCanvas, false);
            // Firefox
            canvas.addEventListener("DOMMouseScroll", mouseMove, false);
        } else {
            // IE 6/7/8
            canvas.addEventListener("onmousewheel", mouseMove);
        }

        function mouseMove(event) {
            event.preventDefault();
            if (!moveDownFlag) {
                return
            }
            //移动距离
            translationX = event.offsetX - actionX;
            translationY = event.offsetY - actionY;
            //控件移动
            actionX = event.offsetX;
            actionY = event.offsetY;
            //改变显示区域
            minXValue -= translationX / unitWidth;
            minYValue += translationY / unitHeight;
            maxXValue -= translationX / unitWidth;
            maxYValue += translationY / unitHeight;
            self.refreshParam();
            self.onDraw(ctx);
        }

        function mouseDown(event) {
            event.preventDefault();
            if (eventTimeStamp == event.timeStamp) {
                return
            }
            clickCount++;
            eventTimeStamp = event.timeStamp;
            moveDownFlag = true;
            actionX = event.offsetX;
            actionY = event.offsetY;
            if (clickCount >= 2){
                scale = 1;
                minXValue = originalMinXValue;
                minYValue = originalMinYValue;
                maxXValue = originalMaxXValue;
                maxYValue = originalMaxYValue;
                self.refreshParam();
                self.onDraw(ctx);
                clickCount = 0;
            }
        }

        function mouseUp(event) {
            event.preventDefault();
            if (eventTimeStamp == event.timeStamp) {
                return
            }
            eventTimeStamp = event.timeStamp;
            moveDownFlag = false;
        }


        function scaleCanvas(event) {
            event.preventDefault();
            if (eventTimeStamp == event.timeStamp) {
                return
            }
            eventTimeStamp = event.timeStamp;
            if (event.deltaY > 0) {
                self.scaleCanvas(2);
            } else {
                self.scaleCanvas(1);
            }
        }
    }

    /**
     * 缩放
     */
    scaleCanvas(model) {

        //model 1 放大 2 缩小
        switch (model) {
            case 1:
                scale = 1 + 0.05
                break
            case 2:
                scale = 1 - 0.05
                break
        }
        var minXValueTmp = (maxXValue + minXValue) / 2 - (maxXValue - minXValue) / 2 / scale;
        var minYValueTmp = (maxYValue + minYValue) / 2 - (maxYValue - minYValue) / 2 / scale;
        var maxXValueTmp = (maxXValue + minXValue) / 2 + (maxXValue - minXValue) / 2 / scale;
        var maxYValueTmp = (maxYValue + minYValue) / 2 + (maxYValue - minYValue) / 2 / scale;
        maxXValue = maxXValueTmp
        minXValue = minXValueTmp
        maxYValue = maxYValueTmp
        minYValue = minYValueTmp
        this.refreshParam()
        this.onDraw(ctx);
    }


    /**
     * 绘制轴
     */
    drawAxis(ctx) {

        ctx.beginPath();
        //绘制横线
        ctx.moveTo(this.xV2P(minXValue), this.yV2P(0));
        ctx.lineTo(this.xV2P(maxXValue), this.yV2P(0));

        //绘制纵线
        ctx.moveTo(this.xV2P(0), this.yV2P(minYValue));
        ctx.lineTo(this.xV2P(0), this.yV2P(maxYValue));
        //画网格线
        // ctx.drawPath(axisLinePath, axisPaint);
        ctx.stroke();
    }

    /**
     * 常规模式绘制折线
     */
    drawPoints(ctx) {
        for (let i = 0; i < pointNumber; i++) {
            this.drawPoint(
                points[i].x,
                points[i].y,
                points[i].color, ctx);
        }
    }

    /**
     * 设定点的数据及色彩密度
     * @param i
     * @param x
     * @param y
     * @param density 密度:0-1的范围，可用于颜色
     */
    setPoint(i, x, y, density) {
        if (points == null || points.length == 0 || pointNumber == 0 ||
            (!drawOutOfRange &&
                (x > maxXValue || y > maxYValue || x < minXValue || y < minYValue))) {
            return;
        }
        //点坐标

        let color = this.HSBToRGB(density);
        //点颜色
        points[i].color = color;
    }

    HSBToRGB(H) {

        var R, G, B;

        var S = 1;
        var V = 1;
        var C = V * S;
        var X = C * (1 - (Math.abs((H / 60) % 2 - 1)));
        var m = V - C;
        if (H >= 0 && H < 60) {
            R = C;
            G = X;
            B = 0;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        if (H >= 60 && H < 120) {
            R = X;
            G = C;
            B = 0;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        if (H >= 120 && H < 180) {
            R = 0;
            G = C;
            B = X;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        if (H >= 180 && H < 240) {
            R = 0;
            G = X;
            B = C;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        if (H >= 240 && H < 300) {
            R = X;
            G = 0;
            B = C;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        if (H >= 300 && H < 360) {
            R = C;
            G = 0;
            B = X;

            R = (R + m) * 255;
            G = (G + m) * 255;
            B = (B + m) * 255;
        }
        return "rgb(" + R + "," + G + "," + B + ")"
    }

    /**
     * 设定点的数据及色彩密度
     * @param i
     * @param x
     * @param y
     */
    setPoint(i, x, y) {

        if (points == null || points.length == 0 || pointNumber == 0 ||
            (!drawOutOfRange &&
                (x > maxXValue || y > maxYValue || x < minXValue || y < minYValue))) {
            return;
        }

        //点坐标
        points[i].x = x;
        points[i].y = y;
        //点颜色
        points[i].color = pointColor;
    }

    /**
     * 画点
     */
    drawPoint(x, y, color, ctx) {
        if (points.length == 0) {
            return;
        }
        ctx.fillStyle = color;
        ctx.beginPath()
        ctx.fillRect(this.xV2P(x), this.yV2P(y), 1, 1)
        ctx.closePath()
        ctx.fill()
    }

    /**
     * 刷新绘点参数
     */
    refreshParam() {

        //最小值的X坐标
        xOfMinValue = frameWidth;
        //最小值的Y坐标
        if (!title) {
            //无标题
            yOfMinValue = frameWidth;
        } else {
            //2倍标题高，为了给标题留出一些余白
            yOfMinValue = titleHeight * 2 + frameWidth;
        }
        //最大值的X坐标
        xOfMaxValue = measuredWidth - frameWidth;
        //最大值的Y坐标
        yOfMaxValue = measuredHeight - frameWidth;
        //单位数值的X轴宽度
        unitWidth = (xOfMaxValue - xOfMinValue) / (maxXValue - minXValue);
        //单位数值的Y轴高度
        unitHeight = (yOfMaxValue - yOfMinValue) / (maxYValue - minYValue);
        this.setState({refresh: true});
    }

    /**
     * 初始化点值数组
     * @param newPointNumber
     */
    initArray(newPointNumber) {

        //若点值数组为空 or 一屏点数有变
        if (points == null || pointNumber != newPointNumber) {
            //初始化
            points = [];
            for (let i = 0; i < newPointNumber; i++) {
                points[i] = new ChartPoint();
            }
        }
    }

    /**
     * 资源回收
     */
    recycle() {

        if (points != null) {
            for (let i = 0; i < pointNumber; i++) {
                points[i] = null;
            }
        }
        points = null;
        lines = null;
    }

    /**
     * 设定X值最大值
     * @param max_value
     * @return
     */
    setMaxX(max_value) {
        isRefresh = true;
        maxXValue = max_value;
        originalMaxXValue = max_value;
        this.refreshParam();
        // return this;
    }

    /**
     * 设定X值最小值
     * @param min_value
     * @return
     */
    setMinX(min_value) {

        isRefresh = true;
        minXValue = min_value;
        originalMinXValue = min_value;
        this.refreshParam();
        // return this;
    }

    /**
     * 设定Y值最大值
     * @param max_value
     * @return
     */
    setMaxY(max_value) {

        isRefresh = true;
        maxYValue = max_value;
        originalMaxYValue = max_value;
        this.refreshParam();
        // return this;
    }

    /**
     * 设定Y值最小值
     * @param min_value
     * @return
     */
    setMinY(min_value) {

        isRefresh = true;
        minYValue = min_value;
        originalMinYValue = min_value;
        this.refreshParam();
        // return this;
    }

    /**
     * 设定一屏内点数
     * 同时设定点间距
     * @param pointNumber
     * @return
     */
    setPointNumber(newPointNumber) {

        //初始化点值数组
        this.initArray(newPointNumber);

        //保存值
        pointNumber = newPointNumber;
        // return this;
    }

    xV2P(x) {
        return xOfMinValue + (x - minXValue) * unitWidth;
    }

    yV2P(y) {
        return yOfMaxValue - (y - minYValue) * unitHeight;
    }

    /**
     * 设定曲线粗细
     * @param width
     * @return
     */
    setPointSize(width) {

        isRefresh = true;
        pointSize = width;
        return this;
    }

    /**
     * 设定曲线色
     * @param colorString
     * @return
     */
    setPointColor(colorString) {
        isRefresh = true;
        pointColor = colorString;
        return this;
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    componentDidMount() {
        mCanvas = document.getElementById(this.props.id);
        ctx = mCanvas.getContext("2d");
        this.init(mCanvas);
        this.initCanvas(ctx, 1);
        this.timer = setInterval(() => {
            clickCount = 0;
        }, 1000);
    }

    init(mCanvas) {
        //获取控件的实际高度，与屏幕无关。
        //统将View的参数根据父容器的规则转换而成的，之后根据它来测量出View的宽和高。
        measuredWidth = mCanvas.clientWidth;
        measuredHeight = mCanvas.clientHeight;
        this.refreshParam();
    }

    render() {
        return (
            <div>
                {/*<Row style={{width: '100%', height: '100%', backgroundColor: "#FFF"}}>*/}
                {/*    <Col span={3} style={{*/}
                {/*        width: '33%', height: '100%', backgroundColor: "#FFF",*/}
                {/*    }}>*/}
                {/*        <canvas id={"main"} width="380" height="380"></canvas>*/}
                {/*    </Col>*/}
                {/*    <Col span={3} style={{*/}
                {/*        width: '33%', height: '100%', backgroundColor: "#FFF",*/}
                {/*    }}>*/}
                {/*        <canvas id={"main2"} width="380" height="380"></canvas>*/}
                {/*    </Col>*/}
                {/*    <Col span={3} style={{*/}
                {/*        width: '33%', height: '100%', backgroundColor: "#FFF",*/}
                {/*    }}>*/}
                {/*        <canvas id={"main3"} width="380" height="380"></canvas>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<canvas id={"main4"} width="380" height="380"></canvas>*/}
                <canvas id={this.props.id} width={this.props.width} height={this.props.height}/>
            </div>
        )
    }

}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(ScatterChart));