import EcgFileDate from "./EcgFileDate";
import EcgFileTime from "./EcgFileTime";

/**
 * 名称    字节数    范围    说明
 * EventTime    4B        当天内的时间，8B时间的低4字节
 * 在波形诊断时，时间精确到了ms级，但是存储，我们以实时时钟的秒为精度足矣。
 * HistoryFileIndex    4B        发生事件的历史数据文件编号
 * TimeRecordIndex    4B        历史数据对应的时间索引记录条编号
 * 以便于迅速从事件映射到时间
 * EventType    1B        0x01    诊断紧急事件类型
 * 0x02    跌倒紧急事件类型
 * 0x03    导联脱落事件类型
 * 0x04    诊断结果事件类型
 * 0x05 设备状态事件
 * Diagnosis    1B        诊断结果编码：
 * 0x01：正常
 * 0x10：左束枝…
 * …..
 * Reserved    1B        保留
 * ACKRecord    1B        ACK相应（暂时不用）
 */
class EcgFileEventIndexRecord {
    constructor(dataArray, startPos) {
        let index = startPos;

        const ecgFileTime = new EcgFileTime(dataArray, index);
        this.eventTime = ecgFileTime.getTime();
        index = index + ecgFileTime.length;

        const historyFileIndexArray = dataArray.slice(index, index + 4);
        this.historyFileIndex = new Int32Array(historyFileIndexArray.buffer.slice(-4))[0];
        index = index + 4;

        const timeRecordIndexArray = dataArray.slice(index, index + 4);
        this.timeRecordIndex = new Int32Array(timeRecordIndexArray.buffer.slice(-4))[0];
        index = index + 4;

        this.eventType = dataArray[index];
        index++;

        this.diagnosis = dataArray[index];
        index++;

        this.reserved = dataArray[index];
        index++;

        this.ackRecord = dataArray[index];
        index++;

        this.length = index - startPos;
    }
}

export default EcgFileEventIndexRecord;