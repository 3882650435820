const DecompressUtils = {

    f: (a, power) => {
        let i, j, N = 1 << power,
            L = 1,
            nStage, M, J, K, odv = power & 0x1;
        let b = new Array();

        if (odv) {
            for (i = 0; i < N; i += 2) {
                b[i] = a[i] + a[i + 1];
                b[i + 1] = a[i] - a[i + 1];
            }
        } else {
            for (i = 0; i < N; i += 2) {
                a[i] = a[i] + a[i + 1];
                a[i + 1] = a[i] - 2 * a[i + 1];
            }
            let tmp = a;
            a = b;
            b = tmp;
        }
        for (nStage = 1; nStage < power; nStage++) {
            M = 1 << L;
            J = 0;
            K = 0;
            while (K < N) {
                for (j = J; j < J + M - 1; j += 2) {
                    a[K] = b[j] + b[j + M];
                    a[K + 1] = b[j] - b[j + M];
                    a[K + 2] = b[j + 1] - b[j + M + 1];
                    a[K + 3] = b[j + 1] + b[j + M + 1];
                    K += 4;
                }
                J = J + (M << 1);
            }
            let tmp = a;
            a = b;
            b = tmp;
            L++;
        }
        return b;
    },

    d9k_dec: (data) => {
        let decData = new Array();
        while (true) {
            if (data.byteLength == 0) break;
            let dv = new DataView(data);
            let v = dv.getUint16(0, true);
            let n0 = dv.getInt32(2, true);
            let n1 = dv.getInt32(6, true);
            let p = dv.getInt16(10, true);
            let tb = dv.getInt16(12, true);
            let ix = dv.getFloat32(14, true);
            let dm = dv.getFloat32(18, true);
            let wd = dv.getFloat32(22, true);
            let ot = dv.getFloat32(26, true);
            let sl = dv.getFloat32(30, true);
            let N = 1 << p;
            if (data.byteLength < (34 + n1)) break;

            let data0 = new Uint8Array(data.slice(34, 34 + n1));
            let a = new Array();
            let tm = (1 << (tb)) - 1;
            a[0] = wd / N;
            let i = 1;
            sl = sl / tm / N;
            ot = ot / N;
            for (; i < data0.length; i++) {
                a[i] = data0[i] * sl + ot;
            }
            for (; i < N; i++) a[i] = 0.0;
            let b = DecompressUtils.f(a, p);
            // let s = b[0] + dm + ix;
            let s = ix;
            for (i = 0; i < n0; i++) {
                s += (b[i] + dm);
                a[i] = s;
            }
            decData.push(a);
            data = data.slice(34 + n1);
        }
        return decData;
    }
};

export default DecompressUtils;