import React, {Component} from 'react';
import {connect} from 'react-redux';


import {addWarnCount, getDefaultLanguage} from './actions/AppAction';
import './App.css';

import mqtt from "mqtt";
import messageBox from "../src/components/toast";

// 从'react-intl'中引入addLocaleData,IntlProvider两个格式化组件；
import {addLocaleData, IntlProvider} from 'react-intl';

import HttpUtils from "./utils/HttpUtils";
import {
    CACHE_ACCESS_TOKEN,
    CACHE_SESSION_USER,
    CACHE_SITE_CODE,
    CACHE_SITE_ID,
    MQTT_HOST,
    WARN_RECORD
} from "./constants/Profile";

/*
 *引入与navigator.languages[0]所对应的语言；
 *如果没有引入对应的语言，会使用默认的“en”；
 *导致FormattedMessage的映射不会成功；
 */
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import ja from 'react-intl/locale-data/ja';
/*
 *引入自定义的映射表；
 *通过与FormattedMessage的id值来当索引映射返回值；
 */
import zh_CN from './locale/zh_CN';
import en_US from './locale/en_US';
import ja_JP from './locale/ja_JP';
import {getSessionUser} from "./modules/Account/actions/UserAction";
import * as WebService from "./modules/Account/WebService";
import MsgWarn from "./images/bmap/ic_warn.png";
import {Modal} from 'antd';
import {
    getFileReadStatus,
    refreshSearchCondition,
    searchFileBound
} from "./modules/DAS/actions/DeviceFileDownloadAction";
/*
 *messages是render()时IntlProvider组件所传的props，属性名固定：messages；
 *messages返回值为映射表，比如：'react-intl/locale-data/zh'&&'./locale/zh_CN'；
 */
let messages = {};
messages["en-US"] = en_US;
messages["zh-CN"] = zh_CN;
messages["ja-JP"] = ja_JP;
/*
 *获取浏览器设置的语言；
 *按我demo中的设置，返回["zh-CN", "zh", "en-US", "en"]，排序与语言设置顺序一直；
 */
const languages = navigator.languages;
const currentLang = "zh-CN";
//console.log("languages: ", languages);
//console.log("language: ", currentLang);
// 载入语言数据；
//载入一个addLocaleData(zh);
//载入多个；
addLocaleData([...zh, ...en, ...ja]);

let client;

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalVisible:false,
            secs:"2s",
        };
    }

    componentWillUnmount() {
        // localStorage.removeItem(WARN_RECORD);
    }

    componentWillMount() {
        this.props.dispatch(getDefaultLanguage());

        // Execute a function when the user releases a key on the keyboard
        window.addEventListener('keyup', function (event) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Number 13 is the "Enter" key on the keyboard
            if (event.code === "Enter") {
                // Trigger the button element with a click
                let btnEnter = document.getElementById("btnEnter");
                if (btnEnter) {
                    btnEnter.click();
                }
            }
        });

        const token = HttpUtils.getQueryString("token");
        if (token !== undefined && token !== "") {
            localStorage.setItem(CACHE_ACCESS_TOKEN, decodeURIComponent(token));
        }

        localStorage.setItem(CACHE_SITE_ID, 'ecg');
        localStorage.setItem(CACHE_SITE_CODE, 'ecg');

        this.props.dispatch(addWarnCount(-1));

    }

    render() {
        const language = this.props.currentLanguage ? this.props.currentLanguage : currentLang;
        return (
            <IntlProvider key={language} locale={language} messages={messages[language]}>
                <div>
                    {this.props.children}

                </div>
            </IntlProvider>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage
    }
}

export default connect(mapStateToProps)(App);