import React, {Component} from 'react';
import ReactEcharts from 'echarts-for-react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Radio} from "antd";

// require('echarts/map/js/china.js');

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classify: "累计确诊"
        };
    }

    // timeTicket = null;

    componentDidMount() {
        // if (this.timeTicket) {
        //     clearInterval(this.timeTicket);
        // }
        // this.timeTicket = setInterval(() => {
        //     const option = this.state.option;
        //     const r = new Date().getSeconds();
        //     // option.title.text = 'iphone销量' + r;
        //     // option.series[0].name = 'iphone销量' + r;
        //     // option.legend.data[0] = 'iphone销量' + r;
        //     this.setState({option: option});
        // }, 1000);
    };

    componentWillUnmount() {
        // if (this.timeTicket) {
        //     clearInterval(this.timeTicket);
        // }
    };

    randomData() {
        return Math.round(Math.random() * 1000);
    };

    getProvinceData(province) {
        const provinceDataMap = this.props.provinceStatistic[province];
        if (provinceDataMap) {
            const provinceDataMapKeys = Object.keys(provinceDataMap);
            const lastItem = provinceDataMap[provinceDataMapKeys[provinceDataMapKeys.length - 1]];
            if (this.state.classify === "累计确诊") {
                return lastItem.confirmed;
            } else {
                return lastItem.confirmed - lastItem.cure - lastItem.dead
            }
        }
    }

    getOption = () => {
        return {
            title: {
                text: '',
                subtext: '',
                left: 'center'
            },
            tooltip: {
                trigger: 'item'
            },
            // legend: {
            //   orient: 'vertical',
            //   left: 'left',
            //   data:['iphone3','iphone4','iphone5']
            // },
            visualMap: {
                min: 0,
                max: 3000,
                left: 'left',
                top: 'bottom',
                text: ['高', '低'],       // 文本，默认为数值文本
                calculable: true,
            },
            // toolbox: {
            //   show: true,
            //   orient: 'vertical',
            //   left: 'right',
            //   top: 'center',
            //   feature: {
            //     dataView: {readOnly: false},
            //     restore: {},
            //     saveAsImage: {}
            //   }
            // },
            series: [
                {
                    name: this.state.classify,
                    type: 'map',
                    mapType: 'china',
                    roam: false,
                    label: {
                        normal: {
                            show: true,
                            textStyle: {
                                fontSize: 8
                            }
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: 8
                            }
                        },
                    },
                    data: [
                        {
                            name: '北京', value: this.getProvinceData('北京市'), 'children': [
                                {'name': '北京', 'log': '116.46', 'lat': '39.92'},
                                {'name': '平谷', 'log': '117.1', 'lat': '40.13'},
                                {'name': '密云', 'log': '116.85', 'lat': '40.37'},
                                {'name': '顺义', 'log': '116.65', 'lat': '40.13'},
                                {'name': '通县', 'log': '116.67', 'lat': '39.92'},
                                {'name': '怀柔', 'log': '116.62', 'lat': '40.32'},
                                {'name': '大兴', 'log': '116.33', 'lat': '39.73'},
                                {'name': '房山', 'log': '115.98', 'lat': '39.72'},
                                {'name': '延庆', 'log': '115.97', 'lat': '40.47'},
                                {'name': '昌平', 'log': '116.2', 'lat': '40.22'}
                            ]
                        },
                        {name: '天津', value: this.getProvinceData('天津市')},
                        {name: '上海', value: this.getProvinceData('上海市')},
                        {name: '重庆', value: this.getProvinceData('重庆市')},
                        {name: '河北', value: this.getProvinceData('河北省')},
                        {name: '河南', value: this.getProvinceData('河南省')},
                        {name: '云南', value: this.getProvinceData('云南省')},
                        {name: '辽宁', value: this.getProvinceData('辽宁省')},
                        {name: '黑龙江', value: this.getProvinceData('黑龙江省')},
                        {name: '湖南', value: this.getProvinceData('湖南省')},
                        {name: '安徽', value: this.getProvinceData('安徽省')},
                        {name: '山东', value: this.getProvinceData('山东省')},
                        {name: '新疆', value: this.getProvinceData('新疆维吾尔自治区')},
                        {name: '江苏', value: this.getProvinceData('江苏省')},
                        {name: '浙江', value: this.getProvinceData('浙江省')},
                        {name: '江西', value: this.getProvinceData('江西省')},
                        {name: '湖北', value: this.getProvinceData('湖北省')},
                        {name: '广西', value: this.getProvinceData('广西壮族自治区')},
                        {name: '甘肃', value: this.getProvinceData('甘肃省')},
                        {name: '山西', value: this.getProvinceData('山西省')},
                        {name: '内蒙古', value: this.getProvinceData('内蒙古自治区')},
                        {name: '陕西', value: this.getProvinceData('陕西省')},
                        {name: '吉林', value: this.getProvinceData('吉林省')},
                        {name: '福建', value: this.getProvinceData('福建省')},
                        {name: '贵州', value: this.getProvinceData('贵州省')},
                        {name: '广东', value: this.getProvinceData('广东省')},
                        {name: '青海', value: this.getProvinceData('青海省')},
                        {name: '西藏', value: this.getProvinceData('西藏自治区')},
                        {name: '四川', value: this.getProvinceData('四川省')},
                        {name: '宁夏', value: this.getProvinceData('宁夏回族自治区')},
                        {name: '海南', value: this.getProvinceData('海南省')},
                        {name: '台湾', value: this.getProvinceData('台湾')},
                        {name: '香港', value: this.getProvinceData('香港')},
                        {name: '澳门', value: this.getProvinceData('澳门')}
                    ]
                }
            ]
        };
    };

    render() {
        const option = this.getOption();
        // const code = "require('echarts/map/js/china.js'); \n" +
        //     "<ReactEcharts \n" +
        //     "  option={this.state.option || {}} \n" +
        //     "  style={{height: '350px', width: '100%'}}  \n" +
        //     "  className='react_for_echarts' />";
        return (
            <div className='examples'>
                <div className='parent'>
                    <ReactEcharts
                        option={option}
                        style={{height: '500px', width: '100%'}}
                        className='react_for_echarts'/>
                    <div className="text-center">
                        <Radio.Group value={this.state.classify}
                                     onChange={(value) => {
                                         this.setState({classify: value.target.value})
                                     }}>
                            <Radio.Button value="累计确诊">累计确诊</Radio.Button>
                            <Radio.Button value="现有确诊">现有确诊</Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    };
}

function mapStateToProps(store) {
    return {
        provinceStatistic: store.DASNcovReducer.provinceStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(Map));