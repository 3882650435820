/**
 * Created by Ethan on 2022/7/15.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import PageTitle from "../../../../../components/PageTitle";
import DetailModal from "./DetailModal";
import {Radio, Select,Popover, Descriptions, Tag} from "antd";
import {
    getAllDevices,
    getDeviceById,
    getDeviceHistoryData,
    getPastDynastiesVersion,
    getUserMemoList,
    refreshChannelType, refreshEcgEventRecordMap,
    refreshSearchDeviceCode, refreshSelectDeviceId,
    refreshVersionIndex,
    searchDeviceList,
    searchDevicesSum,
    searchEcgRecordSum
} from "../../../actions/DeviceAction";
import HttpUtils from "../../../../../utils/HttpUtils";
import {initDeviceLogs} from "../../../actions/LogDataServerAction";
import moment from "moment";
import {FORMAT_DATE_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import WebSocketUtils from "../../../utils/WebSocketUtils";
import {EcgFileType} from "../../../Enums";
import {
    getDateIndexFile,
    refreshCalendarEcgDateIndexRecord,
    refreshEcgDateIndexRecordMap, refreshVersionChange
} from "../../../actions/HistoryAction";
import {RoutePath} from "../../../../../constants/RoutePath";

import "./Index.css";
import CheckUpdateVersion from "../../../../CMS/components/CheckUpdateVersion";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {CHANNEL_TYPE, VERSION, WARN_RECORD} from "../../../../../constants/Profile";

// let deviceCode = [];
let deviceId = [];

let deivcies = [];
let height;

let xuanzeValue

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            device: "",
            height: "100%",
            channelType:"20",
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllDevices(this.state.formatMessage));
        deivcies = this.props.allDevices
        xuanzeValue = this.props.mapVersion.length
        //数据来源默认为手机
        this.props.dispatch(refreshChannelType(this.state.channelType));
        // if (localStorage.getItem(CHANNEL_TYPE)){
        //     this.props.dispatch(refreshChannelType(localStorage.getItem(CHANNEL_TYPE)));
        // }

        this.intervalRefresh = setInterval(() => {
            let id;
            for (var item of deivcies) {
                if (item.code === this.state.deviceCode) {
                    id = item.id;
                }
            }
            this.props.dispatch(getDeviceById(id, this.state.formatMessage));
            // console.log("this.props.device:" + JSON.stringify(this.props.device));
            if(this.props.device !== undefined){
                this.setState({device: this.props.device})
            }

            console.log("id:" + id);
        }, 2000);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (deivcies != nextProps.allDevices) {
            deivcies = nextProps.allDevices
            this._handleChange("code", this.state.deviceCode)

            // const getUserMemoListParem = {
            //     "deviceCode": this.state.deviceCode,//zhi
            //     "generationId": nextProps.mapVersion[0],
            // }
            // console.log("getUserMemoListParem2:", getUserMemoListParem, nextProps.mapVersion, nextProps.deviceCode
            //     , nextProps)
            // this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))
            //
            // const parem = {
            //     "deviceCode": this.state.deviceCode,
            //     "generationId": nextProps.mapVersion[0],
            //     "fromApp": nextProps.channelType == "10" ? "0" : "1"
            // }
            // this.props.dispatch(refreshEcgEventRecordMap(""));
            // this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
            // this.props.dispatch(getDateIndexFile(this.props.deviceCode, this.props.channelType, this.props.version,"detailIndex"));
        }

    }


    componentDidMount() {
        const deviceCode = HttpUtils.getQueryString("deviceCode");
        if (deviceCode !== undefined && deviceCode !== "") {
            this.setState({deviceCode: deviceCode});
        }
        this.props.dispatch(refreshSearchDeviceCode(deviceCode));
        this._handleChange("code", deviceCode)
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    _handleChange(name, value, index) {
        let id = "";

        for (var item of deivcies) {
            if (item.code == value) {//value.target ? value.target.value :
                id = item.id;
                this.setState({device: item})
            }
        }
        const startTime = moment().format(FORMAT_DATE_SIMPLE + "000000");
        const endTime = moment().format(FORMAT_DATE_SIMPLE + "235959");
        if (id) {
            this.props.dispatch(searchDeviceCoordinates(id, startTime, endTime, this.state.formatMessage));
            this.props.dispatch(getDeviceById(id, this.state.formatMessage));
            this.props.dispatch(refreshSelectDeviceId(id));
        }
        this.props.dispatch(refreshSearchDeviceCode(value.target ? value.target.value : value));
        this.props.dispatch(initDeviceLogs(value.target ? value.target.value : value));
        this.props.dispatch(getDeviceHistoryData(value.target ? value.target.value : value, "dev", "03", this.state.formatMessage));
        this.props.dispatch(getDeviceHistoryData(value.target ? value.target.value : value, "app", "03", this.state.formatMessage));
        this.setState({deviceCode: value.target ? value.target.value : value});
        // this.props.dispatch(getDateIndexFile(this.props.deviceCode, this.props.channelType, this.props.version,"detailIndex"));
        //获取设备的所有世代信息
        this.props.dispatch(getPastDynastiesVersion(value.target ? value.target.value : value, this.state.formatMessage));
        console.log("_handleChange:", value, index, name, this.props.deviceCode)
        if (index == 2) {
            const tabIndex = HttpUtils.getQueryString("tabIndex");
            console.log("_handleChange")
            this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + value + "&tabIndex=" + tabIndex);
            window.location.reload();
        }

    }

    _handleOn() {

    }

//className="page-width"
    render() {
        const formatMessage = this.state.formatMessage;
        let record = this.props.device;

        //设置世代数据
        let lstData = [];
        let mapVersion = this.props.mapVersion;
        mapVersion.sort((curr, next) => {
            let a = curr;
            let b = next;

            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        });

        mapVersion.map((item, index) => {
            if (index === 0) {
                lstData.push({value: '00', label: item + "(当世)"});
            } else {
                lstData.push({value: item.substring(2, 4), label: item});
            }
        })
        return (
            <div className="position-relative" style={{height: '100%'}}>
                <div style={{marginLeft: "20px", marginRight: "20px", marginTop: "20px"}} className="spacing-v">
                    <b>设备编号：</b>
                    <Select style={{width: '150px'}}
                            optionFilterProp="children"
                            showSearch
                            placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}
                            value={this.state.deviceCode}
                            onChange={(value) => {
                                this._handleChange("code", value, 2)
                            }}>
                        {
                            this.props.allDevices.map((item, index) => {
                                return <Select.Option key={index}
                                                      value={item.code}>{item.code}</Select.Option>
                                //device={this.state.deviceCode}
                            })
                        }
                    </Select>
                    <b style={{paddingLeft: "20px"}}>数据来源：</b>
                    <Radio.Group
                        onChange={(event) => {
                            this._handleChannelTypeChange(event.target.value);
                        }} value={this.state.channelType}>
                        <Radio value={'10'}>设备</Radio>
                        <Radio value={'20'}>手机</Radio>
                    </Radio.Group>

                    <b style={{paddingLeft: "20px"}}>世代编号：</b>
                    <EnumItemSelect data={lstData}
                                    value={this.props.version}
                                    style={{width: 240}}
                                    onChange={(value) => {
                                        this._handleVersionChange(value);
                                    }}/>

                    <b style={{paddingLeft: "20px"}}>电极状态：</b>
                    <Popover content={
                        <Descriptions column={3} size={"small"} bordered={true}>
                            <Descriptions.Item span={2} label="正面">
                                <div style={{src: require("../../../../../images/front.png")}}>
                                    <img style={{width: 150, height: 150}}
                                         src={require("../../../../../images/front.png")} alt=""/>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 80,
                                        left: 165,
                                    }}
                                         src={require((record.leadDState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 90,
                                        left: 155,
                                    }}>V1</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 80,
                                        left: 200,
                                    }}
                                         src={require((record.leadEState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 90,
                                        left: 205,
                                    }}>V2</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 130,
                                        left: 165,
                                    }}
                                         src={require((record.leadGState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 140,
                                        left: 155,
                                    }}>V4</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 130,
                                        left: 200,
                                    }}
                                         src={require((record.leadHState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 140,
                                        left: 205,
                                    }}>V5</p>

                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 95,
                                        left: 130,
                                    }}
                                         src={require((record.leadAState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 85,
                                        left: 110,
                                    }}>RA</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 95,
                                        left: 235,
                                    }}
                                         src={require((record.leadBState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 85,
                                        left: 245,
                                    }}>LA</p>
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label="背面">
                                <img style={{width: 150, height: 150}}
                                     src={require("../../../../../images/back_front.png")}
                                     alt="">
                                </img>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 80,
                                    left: 520,
                                }}
                                     src={require((record.leadFState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 90,
                                    left: 525,
                                }}>V3</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 480,
                                }}
                                     src={require((record.leadCState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 485,
                                }}>LL</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 520,
                                }}
                                     src={require(((!record.leadBState && !record.leadCState &&
                                         !record.leadDState && !record.leadEState &&
                                         !record.leadFState && !record.leadGState &&
                                         !record.leadHState) ? "../../../../../images/red.png" : "../../../../../images/blue.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 525,
                                }}>RL</p>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label="RA电极">{record.leadAState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V2电极">{record.leadEState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V3电极">{record.leadFState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LA电极">{record.leadBState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V4电极">{record.leadGState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LL电极">{record.leadCState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>

                            <Descriptions.Item
                                label="V1电极">{record.leadDState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V5电极">{record.leadHState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="RL电极">{(!record.leadBState && !record.leadCState &&
                                !record.leadDState && !record.leadEState &&
                                !record.leadFState && !record.leadGState &&
                                !record.leadHState) ? <Tag color="red">脱落 </Tag> :
                                <Tag color="green">正常 </Tag>}</Descriptions.Item>
                        </Descriptions>
                    } title="详细">
                        {(record.leadAState && record.leadBState && record.leadCState && record.leadDState && record.leadEState && record.leadFState && record.leadGState && record.leadHState) ?
                            <Tag color="green">正常</Tag> :
                            (record.leadAState || record.leadBState || record.leadCState || record.leadDState || record.leadEState || record.leadFState || record.leadGState || record.leadHState) ?
                                <Tag color="blue">部分脱落</Tag> : <Tag color="red">脱落</Tag>}
                    </Popover>

                </div>
                <CheckUpdateVersion/>
                <DetailModal {...this.props} deviceCode={this.state.deviceCode} deviceValue={this.state.device}/>
            </div>
        );
    }

    _handleChannelTypeChange(value) {
        this.props.dispatch(refreshChannelType(value));
        this.props.dispatch(getDateIndexFile(this.state.deviceCode, value, this.props.version));
        localStorage.setItem(CHANNEL_TYPE, value);
        this.setState({channelType: value});

    }

    _handleVersionChange(value) {
        this.props.dispatch(refreshVersionIndex(value));
        this.props.dispatch(getDateIndexFile(this.state.deviceCode, value, this.props.version));
        if (value == "00") {
            xuanzeValue = this.props.mapVersion.length;
        } else {
            xuanzeValue = value;
        }
    }
}

function mapStateToProps(store) {
    return {
        allDevices: store.EcgDeviceReducer.allDevices,
        allAllDevices: store.EcgDeviceReducer.allAllDevices,
        deviceCode: store.EcgDeviceReducer.deviceCode,
        channelType: store.EcgDeviceReducer.channelType,
        mapVersion: store.EcgDeviceReducer.mapVersion,
        version: store.EcgDeviceReducer.version,
        device:store.EcgDeviceReducer.device,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));