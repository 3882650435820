import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Tree} from 'antd';

class ChannelTree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedKeys: [],
            expandedKeys: []
        }
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.channelTree) {
        //     // 计算所有的展开节点
        //     let expandedKeys = [];
        //     for (let i = 0; i < nextProps.channelTree.length; i++) {
        //         const item = nextProps.channelTree[i];
        //         expandedKeys.push(item.id);
        //         this._pushChildrenExpandedKeys(expandedKeys, item.children);
        //     }
        //     this.setState({expandedKeys:expandedKeys});
        // }
    }

    // _pushChildrenExpandedKeys(expandedKeys, children) {
    //     if (children !== null && children.length > 0) {
    //         for (let i = 0; i < children.length; i++) {
    //             const item = children[i];
    //             expandedKeys.push(item.id);
    //             this._pushChildrenExpandedKeys(expandedKeys, item.children);
    //         }
    //     }
    // }

    componentWillMount() {

    }

    _handleSelect(selectedKeys) {
        this.setState({selectedKeys: selectedKeys});
        const selectedKey = selectedKeys[0];
        this.props.onSelect && this.props.onSelect.call(this, selectedKey);
    }

    render() {
        return (
            <div>
                <Tree
                    showLine
                    // expandedKeys={this.state.expandedKeys}
                    // selectedKeys={this.state.selectedKeys}
                    onSelect={(selectedKeys) => this._handleSelect(selectedKeys)}>
                    {
                        this.props.channelTree.map((item, i) => {
                            return (
                                <Tree.TreeNode title={item.title} key={item.id}>
                                    {this._renderChannelTreeNode(item)}
                                </Tree.TreeNode>
                            )
                        })
                    }
                </Tree>
            </div>
        );
    }

    _renderChannelTreeNode(channel) {
        if (channel.children && channel.children.length > 0) {
            return (
                channel.children.map((item, i) => {
                    return (
                        <Tree.TreeNode title={item.title} key={item.id}>
                            {this._renderChannelTreeNode(item)}
                        </Tree.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        // channelTree: store.ChannelReducer.channelTree,
        // selectTreeKey: store.ChannelReducer.selectTreeKey,
        // expandedKeys: store.ChannelReducer.expandedKeys,
    }
}

export default connect(mapStateToProps)(injectIntl(ChannelTree));