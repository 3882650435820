/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Icon, Row, Col, Form, Drawer} from 'antd';

import {
    toggleResultModal

} from '../../../actions/DeviceAction';
import './index.css';

let dataCondition= [];
class SearchResult extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }



    componentWillMount() {
        // this.props.dispatch(getCountryTree(this.state.formatMessage));
    }

    _closeSearchResult(){
        this.props.dispatch(toggleResultModal(false));
    }

    _returnRunningNum(allAllDevices){
        const resultArr = allAllDevices && allAllDevices.filter(e =>
            e.appHisOnlineStatus === 1 ||
            e.appLiveOnlineStatus === 1 ||
            e.devHisOnlineStatus === 1 ||
            e.devLiveOnlineStatus === 1
        );
        return resultArr && resultArr.length;
    }

    _returnUnusedActiveNum(allAllDevices){
        const resultArr = allAllDevices && allAllDevices.filter(e =>
            e.appHisOnlineStatus === 0 &&
            e.appLiveOnlineStatus === 0 &&
            e.devHisOnlineStatus === 0 &&
            e.devLiveOnlineStatus === 0 &&
            e.activeFlag &&
            Number(e.activeFlag) === 1
        );
        return resultArr && resultArr.length;
    }

    _selectDevices(){
        const allAllDevices = Object.assign([],this.props.devices);
        const monitorSearchCondition = Object.assign({}, this.props.monitorSearchCondition);
        dataCondition= [];

        for(let item of allAllDevices){

            //传输状态
            if(monitorSearchCondition.deviceOnlineStatesTemp !== undefined &&
                monitorSearchCondition.deviceOnlineStatesTemp.length > 0){
                if (!monitorSearchCondition.deviceOnlineStatesTemp.includes(item.devLiveOnlineStatus)){
                    continue;
                }
            }

            //导联状态
            if(monitorSearchCondition.deviceLeadStatesTemp !== undefined){
                // debugger;
                if(monitorSearchCondition.deviceLeadStatesTemp[0] === "0"){
                    if(item.leadAState === 0 || item.leadBState === 0 || item.leadCState === 0 || item.leadDState === 0 ||
                        item.leadEState === 0 || item.leadFState === 0 || item.leadGState === 0 || item.leadHState === 0){
                        dataCondition.push(JSON.parse(JSON.stringify(item)));
                    }
                } else {
                    if(item.leadAState === 1 && item.leadBState === 1 && item.leadCState === 1 && item.leadDState === 1 &&
                        item.leadEState === 1 && item.leadFState === 1 && item.leadGState === 1 && item.leadHState === 1){
                        dataCondition.push(JSON.parse(JSON.stringify(item)));
                    }
                }

                continue;
            }

            dataCondition.push(JSON.parse(JSON.stringify(item)));
        }
        console.log("dataCondition:" + dataCondition.length);
    }

    render() {
        const allAllDevices = dataCondition;
        const {formatMessage} = this.state;
        this._selectDevices();
        return (
            <Drawer
                className="monitorSearchCondition"
                title={false}
                placement="bottom"
                closable={false}
                visible={this.props.showResultModal}
                // visible={true}
                getContainer={false}
                mask={false}
                height={"auto"}
                style={{ position: 'absolute' }}
            >
                <div style={{position:"absolute",top:10,right:10,cursor:"pointer"}}
                     onClick={() => this._closeSearchResult()}>
                    <Icon type="close" />
                </div>
                <Form layout="inline" style={{backgroundColor: "#FFF", paddingLeft: "5px"}}>
                    <Row>
                        <Col>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_DEVICE_NUMBER'})}>
                                {allAllDevices.length}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_DEVICE_RUNNING'})}>
                                {this._returnRunningNum(allAllDevices)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_DEVICE_UNUSED_ACTIVE'})}>
                                {this._returnUnusedActiveNum(allAllDevices)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        devices: store.EcgDeviceReducer.devices,
        showResultModal: store.EcgDeviceReducer.showResultModal,
        monitorSearchCondition: store.EcgDeviceReducer.monitorSearchCondition,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));
