class EcgRuler {
    constructor(id) {
        this.id = id;
        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;
        this.distance = 0;
        this.svg = '';
    }

    calculateDistance_W(pointSpace, sampRate) {
        this.distance = (Math.abs((this.endX - this.startX) / pointSpace) / sampRate);
    }

    calculateDistance_H(cellWidth) {
        this.distance = (Math.abs((this.endY - this.startY) / cellWidth) * 0.5);
    }

    setSvg() {
        if (this.startX === this.endX) {
            this.svg = this.toSVG_H();
        } else {
            this.svg = this.toSVG_W();
        }
    }

    toSVG_W() {
        const svgStart = "<g id='" + this.id + "' class='ecg_view_ruler_group'>";
        const svgLine1 = "<line x1='" + this.startX + "' y1='" + (this.startY - 20) + "' x2='" + this.startX + "' y2='" + (this.startY + 20) + "' stroke='blue' stroke-width='4'/>";
        const svgLine2 = "<line x1='" + this.startX + "' y1='" + this.startY + "' x2='" + this.endX + "' y2='" + this.startY + "' stroke='blue' stroke-width='2'/>";
        const svgLine3 = "<line x1='" + this.endX + "' y1='" + (this.startY - 20) + "' x2='" + this.endX + "' y2='" + (this.startY + 20) + "' stroke='blue' stroke-width='4'/>";
        const svgLineText = "<text x='" + (this.startX + (this.endX - this.startX) / 2 - 10) + "' y='" + (this.startY - 10) + "'>" + this.distance + "</text>";
        const svgEnd = "</g>";
        return svgStart + svgLine1 + svgLine2 + svgLine3 + svgLineText + svgEnd;
    }

    toSVG_H() {
        const svgStart = "<g id='" + this.id + "' class='ecg_view_ruler_group'>";
        const svgLine1 = "<line x1='" + (this.startX - 10) + "' y1='" + this.startY + "' x2='" + (this.startX + 10) + "' y2='" + this.startY + "' stroke='blue' stroke-width='4'/>";
        const svgLine2 = "<line x1='" + this.startX + "' y1='" + this.startY + "' x2='" + this.startX + "' y2='" + this.endY + "' stroke='blue' stroke-width='2'/>";
        const svgLine3 = "<line x1='" + (this.startX - 10) + "' y1='" + this.endY + "' x2='" + (this.startX + 10) + "' y2='" + this.endY + "' stroke='blue' stroke-width='4'/>";
        const svgLineText = "<text x='" + (this.startX - 20) + "' y='" + (this.startY - 10) + "'>" + this.distance + "</text>";
        const svgEnd = "</g>";
        return svgStart + svgLine1 + svgLine2 + svgLine3 + svgLineText + svgEnd;
    }
}

export default EcgRuler;