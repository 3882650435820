/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    ecgCases: [],
    ecgCase: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false
};

const EcgCaseReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_CASE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_CASE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ECG_CASE_REFRESH_CASES:
            return Object.assign({}, state, {
                ecgCases: action.ecgCases
            });
        case ActionTypes.ECG_CASE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_CASE_SEARCH:
            return Object.assign({}, state, {
                ecgCases: action.ecgCases,
                pagination: action.pagination
            });
        case ActionTypes.ECG_CASE_REFRESH_CASE:
            return Object.assign({}, state, {
                ecgCase: action.ecgCase
            });
        default:
            return state
    }
};

export default EcgCaseReducer;