/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Table} from "antd";
import {
    toggleBinderListModal, unbindBinder,
} from "../../../actions/GuarderAction";

class BinderListModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            columns: [
                {
                    key: 'userName',
                    dataIndex: 'userName',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_NAME'})
                },
                {
                    key: 'userMobile',
                    dataIndex: 'userMobile',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_MOBILE'})
                },
                {
                    key: 'userEmail',
                    dataIndex: 'userEmail',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_EMAIL'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleUnbind.bind(this, record)}>
                    <FormattedMessage id="ECG_GUARDER_BUTTON_UNBIND"/>
                </a>
            </div>
        )
    }

    _handleUnbind(record) {
        const {dispatch, guarder} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "ECG_GUARDER_TITLE_UNBIND"}),
            content: formatMessage({id: "ECG_GUARDER_MSG_UNBIND_CONFIRM"}),
            okType: 'danger',
            okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
            cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
            onOk() {
                dispatch(unbindBinder(guarder.userId, record.id, formatMessage));
            }
        })
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.props.dispatch(toggleBinderListModal(false));
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                title={formatMessage({id: "ECG_GUARDER_TITLE_BINDERS"})}
                maskClosable={false}
                visible={this.props.showBinderListModal}
                onCancel={() => this._handleCancel()}
                footer={null}>
                <Table rowKey="id" size="small"
                       columns={this.state.columns} dataSource={this.props.guarderBinders}/>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        guarder: store.GuardianReducer.guarder,
        guarderBinders: store.GuardianReducer.guarderBinders,
        showBinderListModal: store.GuardianReducer.showBinderListModal
    }
}

export default connect(mapStateToProps)(injectIntl(BinderListModal));