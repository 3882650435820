/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input, DatePicker, Table} from 'antd';

import {Label_3_Wrapper_21} from "../../../../../../constants/FormItemLayout";
import {
    FORMAT_DATE_SIMPLE, FORMAT_DATE_TIME_SIMPLE,
} from "../../../../../../constants/DateTimeFormats";

import moment from 'moment';
import DateRangeDivider from "../../../../../../components/DateRangeDivider";
import {
    searchDeviceLogList,
    refreshSearchCondition,
    downloadDeviceLog
} from "../../../../actions/LogDataServerAction";

class DeviceModalLogTab extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: formatMessage({id: 'ECG_SERVER_LOG_FIELD_NAME'})
                },
                {
                    key: 'size',
                    dataIndex: 'size',
                    title: formatMessage({id: 'ECG_SERVER_LOG_FIELD_SIZE'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ], selectedRows: []
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleDownload.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DOWNLOAD"/>
                </a>
            </div>
        )
    }

    _handleDownload(record) {
        this.props.dispatch(downloadDeviceLog(record.path, record.name, this.state.formatMessage));
    }

    _handleDownloadFiles(records) {
        const filePaths = records.map((item, index) => item.path);
        console.log(filePaths);
        this.props.dispatch(downloadDeviceLog(filePaths, moment().format(FORMAT_DATE_TIME_SIMPLE) + ".zip", this.state.formatMessage));
    }

    componentWillMount() {
    }

    _handleSearch(searchCondition) {
        searchCondition.deviceCode = this.props.deviceCode;
        this.props.dispatch(searchDeviceLogList(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    render() {
        const {searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;

        return (
            <div onScroll={{x: 900}}>
                <Form>
                    <Row gutter={16}>
                        <Col span={10}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_SERVER_LOG_FIELD_DATE'})} {...Label_3_Wrapper_21}>
                                <DatePicker
                                    value={searchCondition.startDate ? moment(searchCondition.startDate, FORMAT_DATE_SIMPLE) : null}
                                    onChange={(value) => this._handleChange("startDate", value ? value.format(FORMAT_DATE_SIMPLE) : "")}/>
                                <DateRangeDivider/>
                                <DatePicker
                                    value={searchCondition.endDate ? moment(searchCondition.endDate, FORMAT_DATE_SIMPLE) : null}
                                    onChange={(value) => this._handleChange("endDate", value ? value.format(FORMAT_DATE_SIMPLE) : "")}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="download"
                                        onClick={() => this._handleDownloadFiles(this.state.selectedRows)}>
                                    <FormattedMessage id='COMMON_BUTTON_DOWNLOAD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div>
                    <Table style={{marginLeft: "20px", marginRight: "20px"}}
                           scroll={{x: 900, y: 300}}
                           rowKey="id" size="small" rowSelection={this.rowSelection} columns={this.state.columns}
                           dataSource={this.props.deviceLogs}/>
                </div>
            </div>
        );
    }

    // rowSelection object indicates the need for row selection
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.setState({selectedRows: selectedRows});
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };
}

function mapStateToProps(store) {
    return {
        device: store.EcgDeviceReducer.device,
        isLoading: store.EcgLogDataServerReducer.isLoading,
        searchCondition: store.EcgLogDataServerReducer.searchCondition,
        deviceLogs: store.EcgLogDataServerReducer.deviceLogs
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceModalLogTab));