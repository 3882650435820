/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";

export function refreshTown(town) {
    return {
        type: ActionTypes.DISTRICT_TOWN_REFRESH_TOWN,
        town: town
    }
}

export function refreshTownMap(townMap) {
    return {
        type: ActionTypes.DISTRICT_TOWN_REFRESH_TOWN_MAP,
        townMap: townMap
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_TOWN_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getTownsByCountyId(countyId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: true});

        const requestURL = WebService.getTownsByCountyId.replace("{id}", countyId);
        HttpUtils.get(requestURL, (response) => {

            dispatch({
                type: ActionTypes.DISTRICT_TOWN_SEARCH,
                towns: response.data
            });

            dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getTown(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: true});
        const requestURL = WebService.getTownById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshTown(response.data));
            dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createTown(town, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: true});

        HttpUtils.post(WebService.createTown, town, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshTown({}));
            dispatch(getTownsByCountyId(response.data.countyId, formatMessage));
            dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateTown(town, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: true});

        const requestURL = WebService.updateTownById.replace("{id}", town.id);
        HttpUtils.put(requestURL, town, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshTown({}));
            dispatch(getTownsByCountyId(response.data.countyId, formatMessage));

            dispatch({type: ActionTypes.DISTRICT_TOWN_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteTown(town, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByTownId.replace("{id}", town.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getTownsByCountyId(response.data.countyId, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



