/**
 * Created by Ethan on 2017/11/16.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import "./PageTitle.css";

class PageTitle extends Component {

    render() {
        return (
            <p className="page-title">
                <FormattedMessage id={this.props.title}/>
            </p>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(PageTitle);