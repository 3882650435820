/**
 * EventTime    4B        当天内的时间，8B时间的低4字节
 * 在波形诊断时，时间精确到了ms级，但是存储，我们以实时时钟的秒为精度足矣。
 * EventType    1B        0x01    诊断紧急事件类型
 * 0x02    跌倒紧急事件类型
 * 0x03    导联脱落事件类型
 * 0x04    诊断结果事件类型
 * 0x05 设备状态事件
 * Diagnosis    1B        诊断结果编码：
 * 0x01：正常
 * 0x10：左束枝…
 * …..
 */

class EcgFileMixDataEventRecord {
    constructor(dataArray, startPos) {

        let index = startPos;

        // 当天内的时间，8B时间的低4字节
        // 在波形诊断时，时间精确到了ms级，但是存储，我们以实时时钟的秒为精度足矣。
        const eventTimeArray = dataArray.slice(index, index + 4);
        this.eventTime = new Int32Array(eventTimeArray.buffer.slice(-4))[0];
        this.h = eventTimeArray[0] > 9 ? eventTimeArray[0] : "0" + eventTimeArray[0]
        this.m = eventTimeArray[1] > 9 ? eventTimeArray[1] : "0" + eventTimeArray[1]
        this.s = eventTimeArray[2] > 9 ? eventTimeArray[2] : "0" + eventTimeArray[2]
        this.ss = (eventTimeArray[3] > 9 ? eventTimeArray[3] : "0" + eventTimeArray[3]) + "0"
        this.time = this.h.toString() + this.m + this.s + this.ss
        index += 4;
        console.log("eventTimeArray:", eventTimeArray, this.eventTime, this.time)

        // 0x01	诊断紧急事件类型
        // 0x02	跌倒紧急事件类型
        // 0x03	导联脱落事件类型
        // 0x04	诊断结果事件类型
        // 0x05 设备状态事件
        switch (dataArray[index]) {
            case 0x01:
                this.eventType = "诊断紧急事件"
                break
            case 0x02:
                this.eventType = "跌倒紧急事件"
                break
            case 0x03:
                this.eventType = "导联脱落事件"
                break
            case 0x04:
                this.eventType = "诊断结果事件"
                break
            case 0x05:
                this.eventType = "设备状态事件"
                break
        }
        index++;

        // this.diagnosis = dataArray[index];
        switch (dataArray[index] >= 0 ? dataArray[index] : dataArray[index] + 256) {
            case 0xF0:
                this.diagnosis = "心肌梗塞"
                break
            case 0xF1:
                this.diagnosis = "心动极缓"
                break
            case 0xF2:
                this.diagnosis = "心动急速"
                break
            case 0xe0:
                this.diagnosis = "跌倒"
                break
            case 0xe1:
                this.diagnosis = "撞击"
                break
            case 0xe2:
                this.diagnosis = "平躺"
                break
            case 0xd0:
                this.diagnosis = "电极轻微脱落<=2个电极"
                break
            case 0xd1:
                this.diagnosis = "电极严重脱落>=3个电极"
                break
            case 0xd2:
                this.diagnosis = "RLD电极脱落 未使用"
                break
            case 0x0:
                this.diagnosis = "正常"
                break
            case 0x01:
                this.diagnosis = "心动过缓"
                break
            case 0x02:
                this.diagnosis = "心动过速"
                break
            case 0x03:
                this.diagnosis = "左束枝传导组织"
                break
            default:
                this.diagnosis = "未知";
                break
        }
        index++;

        this.length = index - startPos;
    }
}

export default EcgFileMixDataEventRecord;