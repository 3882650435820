import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'

import message from "../../../components/toast";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_CASE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshCase(ecgCase) {
    return {
        type: ActionTypes.ECG_CASE_REFRESH_CASE,
        ecgCase: ecgCase
    }
}

export function refreshCases(ecgCases) {
    return {
        type: ActionTypes.ECG_CASE_REFRESH_CASES,
        ecgCases: ecgCases
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.ECG_CASE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function searchCases(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchCases, searchCondition, (response) => {
            dispatch({
                type: ActionTypes.ECG_CASE_SEARCH,
                ecgCases: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getCase(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getCase, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshCase(response.data));
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createCase(ecgCase, searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createCase, ecgCase, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCase({}));
            dispatch(searchCases(searchCondition, formatMessage));
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateCase(ecgCase, searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateCase, ecgCase, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCase({}));
            dispatch(searchCases(searchCondition, formatMessage));
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteCase(ecgCase, searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: true});
        const requestUrl = HttpUtils.addQueryString(WebService.deleteCase, "id", ecgCase.id);
        HttpUtils.post(requestUrl, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(toggleDetailModal(false));
            dispatch(refreshCase({}));
            dispatch(searchCases(searchCondition, formatMessage));
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_CASE_LOADING, isLoading: false});
        }, formatMessage)
    }
}