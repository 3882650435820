/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    signal: {},
    ecgFiles: [],
    ecgFileCount: 0,
    searchCondition: {},
    pagination: {},
    selectedDate: {}
};

const ECGReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_HISTORY_REFRESH_DATE_INDEX_EFFECTIVE_RECORDS:
            return Object.assign({}, state, {
                selectedDate: action.selectedDate
            });
        case ActionTypes.DAS_ECG_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DAS_ECG_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.DAS_ECG_INIT_SIGNAL:
            return Object.assign({}, state, {
                signal: initialState.signal
            });
        case ActionTypes.DAS_ECG_FILES_COUNT:
            return Object.assign({}, state, {
                ecgFileCount: action.ecgFileCount
            });
        case ActionTypes.DAS_ECG_SIGNAL_SEARCH:
            return Object.assign({}, state, {
                signal: action.signal
            });
        case ActionTypes.DAS_ECG_FILES_SEARCH:
            return Object.assign({}, state, {
                ecgFiles: action.ecgFiles,
                pagination: action.pagination
            });
        default:
            return state
    }
};

export default ECGReducer;
