import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";

export function getApplicationByCode(code, formatMessage, successCallback) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_APPLICATION_LOADING, isLoading: true});

        const requestURL = WebService.getApplicationLatestVersion + code;
        HttpUtils.get(requestURL, (response) => {
            dispatch({type: ActionTypes.ECG_APPLICATION_LOADING, isLoading: false});
            dispatch({
                type: ActionTypes.ECG_APPLICATION_REFRESH_APPLICATION,
                application: response.data
            });
            successCallback && successCallback.call(this, response.data);
        }, (response) => {
            dispatch({type: ActionTypes.ECG_APPLICATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}