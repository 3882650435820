import * as ActionTypes from "../../CMS/ActionTypes";
import HttpUtils from "../../../utils/HttpUtils";
import message from "../../../components/toast";
import * as WebService from "../WebService";

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DAS_DOCTOR_REPORT_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshReports(reports) {
    return {
        type: ActionTypes.DAS_DOCTOR_REPORT_REFRESH_REPORTS,
        reports: reports
    }
}

export function refreshReport(report) {
    return {
        type: ActionTypes.DAS_DOCTOR_REPORT_REFRESH_REPORT,
        report: report
    }
}

export function refreshIsCheck(check) {
    return {
        type: ActionTypes.DAS_DOCTOR_REPORT_REFRESH_IS_CHECK,
        check: check
    }
}

export function getDiagnosticReports(deviceCode, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: true});

        const requestUrl = WebService.getDiagnosticReports.replace("{deviceCode}", deviceCode);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshReports(response.data));
            // console.log("getDiagnosticReports:" + JSON.stringify(response.data));
            dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function getDiagnosticReportsDetail(deviceId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: true});

        const requestUrl = WebService.getDiagnosticReportsDetail.replace("{deviceId}", deviceId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshReports(response.data));
            // console.log("getDiagnosticReportsDetail:" + JSON.stringify(response.data));
            dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function createDiagnosticReport(report,deviceId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: true});

        const requestURL = WebService.createDiagnosticReport;
        HttpUtils.post(requestURL, report, (response) => {
            // dispatch(toggleDetailModal(false));
            dispatch(getDiagnosticReportsDetail(deviceId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function updateDiagnosticReport(report, deviceId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: true});

        const requestURL = WebService.updateDiagnosticReport;
        HttpUtils.post(requestURL, report, (response) => {
            // dispatch(toggleDetailModal(false));
            dispatch(getDiagnosticReportsDetail(deviceId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DAS_DOCTOR_REPORT_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}
