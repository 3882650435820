import * as ActionTypes from "../ActionTypes";
import HttpUtils from "../../../utils/HttpUtils";
import * as WebService from "../WebService";
import {downloadHistoryFile} from "../WebService";
import {refreshDeviceLogs} from "./LogDataServerAction";
import {
    ECG_DEVICE_SEARCH_FILE_BOUND,
    ECG_LOG_DEVICE_DOWNLOAD_FINISH,
    ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS, ECG_LOG_DEVICE_DOWNLOAD_VERSION
} from "../ActionTypes";
import {FILE_IO_SOCKET_SERVER_HOST} from "../../../constants/Profile";
import fs from "fs";
// import path from  "path";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshDownloadFlag(isShow) {
    return {
        type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_CONFIRM,
        isDownload: isShow
    }
}

export function downloadDeviceFile(condition, name,generationId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH, isFinish: false});

        let conditions=new Array(condition.deviceCode,condition.fileType,condition.codeFrom,condition.codeTo,condition.fileCount,condition.clientId,condition.source,generationId);
        const requestUrl = HttpUtils.addQueryString(WebService.downloadHistoryFile, "condition", conditions);
        HttpUtils.getFile(requestUrl, name, (response) => {
            console.log("response:" + response);
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH, isFinish: true, fileSize:response});
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH, isFinish: false, fileSize:response});
        }, (response) => {
            // dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchFileBound(searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchFileBound, searchCondition, (response) => {

            dispatch(refreshSearchCondition(response.data));
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function downloadFileBytes(condition,filePath, formatMessage, successCallback) {
    return dispatch => {
        let conditions=new Array(condition.deviceCode,condition.fileType,condition.codeFrom,condition.codeTo,condition.fileCount,condition.clientId,filePath,condition.source,condition.version);
        let fileSize = 0;

        HttpUtils.post(WebService.downloadFileBytes, conditions, (response) => {
            const map = new Map(Object.entries(response.data));
            let isClose = true;

            for (let item of map) {
                // while (!isClose) {}
                // isClose = false;

                const webSocket = new WebSocket(FILE_IO_SOCKET_SERVER_HOST);
                webSocket.binaryType = 'arraybuffer';
                webSocket.onopen = (e) => {
                    // 打开一个连接
                    console.log("链接服务器成功：" + e.target.url);
                    console.log("文件路径：" + item[0]);
                    webSocket.send(JSON.stringify({command: 'writeC', filePath: item[0], fileContents: item[1]}));
                };
                webSocket.onmessage = (e) => {

                    const dataArray = new Int8Array(e.data);
                    // message.success("文件下载成功，路径：" + item[0]);
                    fileSize = fileSize + item[1].length;
                    webSocket.close();
                    successCallback && successCallback.call(this, dataArray);
                };
                webSocket.onerror = (e) => {
                    console.log("链接服务器异常：" + e.target.url);
                };
                webSocket.onclose = (evnt) => {
                    console.log("与服务器断开了链接：" + evnt.target.url);
                    let arr = Array.from(map.keys());
                    // console.log("arr：" + arr);
                    // console.log("index：" + arr.indexOf(item));
                    if(arr.indexOf(item[0]) === arr.length - 1){
                        console.log("文件大小：" + fileSize);
                        dispatch({type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH, isFinish: true, fileSize:fileSize});
                        dispatch({type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH, isFinish: false, fileSize:fileSize});
                    }

                    isClose = true
                };
                console.log("websocket状态：" + webSocket.readyState);
                // let start = (new Date()).getTime();

            }

        }, (response) => {

        }, formatMessage)
    }
}

export function getFileReadStatus(deviceId,fileType,dataSource, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: true});
        let requestUrl = WebService.getDeviceHistoryData.replace("{deviceId}", deviceId);

        if(fileType === "0"){
            requestUrl = requestUrl.replace("{fileType}", 0X03);
        }else if(fileType === "1"){
            requestUrl = requestUrl.replace("{fileType}", 0X04);
        }else if(fileType === "2"){
            requestUrl = requestUrl.replace("{fileType}", 0X05);
        }else{
            dispatch({
                type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS,
                fileReadStatus: []
            });

            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }

        if(dataSource === "10"){
            requestUrl = requestUrl.replace("{dataSource}","dev");
        }else {
            requestUrl = requestUrl.replace("{dataSource}","app");
        }

        HttpUtils.get(requestUrl, (response) => {

            dispatch({
                type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS,
                fileReadStatus: response.data
            });
            console.log("response:", response)

            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getPastDynastiesVersion(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: true});
        let requestUrl = WebService.getGenerationIDS.replace("{id}", id);
        HttpUtils.get(requestUrl, (response) => {
            console.log("mapVersion:" + response.data);
            dispatch({
                type: ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_VERSION,
                mapVersion: response.data
            });
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}



