import EcgDrawUtils from "../utils/EcgDrawUtils";
import {EcgLightWaveType, leadName} from "../Enums";

class EcgLightWave {
    constructor(nodeElementId, type) {

        // DOM的id，后续的dom对象管理，需要使用该Id
        this.nodeElementId = nodeElementId;

        this.type = type;

        // SVG 线的背景颜色
        this.lineColors = ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E'];

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById(nodeElementId);
        if (!ecgViewWrapNode) {
            return;
        }

        const clientWidth = ecgViewWrapNode.clientWidth;

        // 做一个定标的1mm等于多少像素的换算
        /**
         *<div id="unit_width" style={{
         *           width: '1mm',
         *           height: '1mm',
         *           position: 'absolute',
         *           zIndex: -1
         *       }}>
         *</div>
         */
        const unitWidthDiv = document.createElement("div");
        ecgViewWrapNode.appendChild(unitWidthDiv);
        unitWidthDiv.id = "unit_width";
        unitWidthDiv.style.width = "1mm";
        unitWidthDiv.style.height = "1mm";
        unitWidthDiv.style.position = "absolute";
        unitWidthDiv.style.zIndex = "-1";
        const unitClientWidth = unitWidthDiv.clientWidth;

        // 走纸速度, 标准值：25mm/s
        // 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
        this.chartSpeed = 25;

        // 格子尺度【GridScale】
        // 5个小格构成一个大格
        // 标准值：1mm/小格
        // 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
        // 例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
        // 此处默认以大格子作为显示单位
        this.gridScale = 5;

        // 采样率
        // 标准值：500samples/s
        // 可选值：250samples/s，1000samples/s
        this.sampRate = 500;

        // 下采样率，默认值1，从设备中获取后改变
        this.downRatio = 1;

        // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
        // 一个格子是5mm，所以一个格子代表0.5mv
        this.voltDiv = 10;

        this.gc = 20132.66;

        // 8个导联的数据
        this.numLeads = 8;

        // 绘图窗口的X,Y轴Margin
        this.winMarginX = 70;
        this.winMarginY = 0;
        // 通过采样率和下采样率的关系，算出每秒实际多少个点
        this.dataRate = this.sampRate / this.downRatio;

        // 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
        this.gridPointNum = this.dataRate / (this.chartSpeed / this.gridScale);

        // 算出每个格子的宽度
        this.cellWidth = this.gridScale * unitClientWidth;

        // 横向格子数，根据屏幕尺寸计算
        this.xCellCount = Math.floor(clientWidth / this.cellWidth);

        // 每个导联占据纵向格子数
        this.yCellCount = 6;

        // 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
        this.winW = clientWidth / unitClientWidth;
        // 一个导联的svg图高度
        this.winH = 0;
        if (this.type === EcgLightWaveType.Enum.Combined) {
            // 合并在一张画板上，则需要按导联数计算高度
            this.winH = this.yCellCount * this.numLeads * this.cellWidth;
        } else if (this.type === EcgLightWaveType.Enum.Overlap) {
            this.yCellCount = this.yCellCount * this.numLeads;
            this.winH = this.yCellCount * this.cellWidth;
        } else {
            this.winH = this.yCellCount * this.cellWidth + 10;
        }

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        this.pointSpace = this.cellWidth / this.gridPointNum;

        // 算出0点，以下是负值，以上是正直，需要变换数据
        this.zeroPointY = (this.yCellCount - this.yCellCount / 2) * this.cellWidth + this.winMarginY;

        // 计算出一屏的点总数
        this.screenPointSum = this.gridPointNum * this.xCellCount;
    }

    changeGridScale(newGridScale) {
        if (newGridScale !== this.gridScale) {
            this.gridScale = newGridScale;
            // 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
            this.gridPointNum = this.dataRate / (this.chartSpeed / this.gridScale);
            // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
            // 计算得出点之间的x间距
            this.pointSpace = this.cellWidth / this.gridPointNum;
            // 计算出一屏的点总数
            this.screenPointSum = this.gridPointNum * this.xCellCount;
        }
    }

    changeVoltDiv(newVoltDiv) {
        if (newVoltDiv !== this.voltDiv) {
            this.voltDiv = newVoltDiv;
        }
    }

    _renderEcgChainCombined(i, ecgData, annotationMap, ecgViewContent) {
        let result = '';
        const offsetY = i * this.yCellCount * this.cellWidth;

        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='" + this.nodeElementId + "_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

        const ecgViewText = "<text x=0 y=" + ((this.yCellCount * this.cellWidth) / 2) + ">CH-" + (i + 1) + ":" + leadName.List[i].label + "</text>"; //EcgDrawUtils._getECGViewText(i, this.winH);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='" + this.nodeElementId + "_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='" + this.nodeElementId + "_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(ecgData[i], i, annotationMap) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        result = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;

        return result;
    }

    _renderEcgChain(i, ecgData, annotationMap, ecgViewWrapNode) {
        const ecgViewContainerStart = "<div id='" + this.nodeElementId + "_container_" + i + "' class='ecg_view_container'>";
        const ecgSvgZoomMark = "<div id='" + this.nodeElementId + "_zoom_mark_" + i + "' class='ecg_svg_zoom_mark'></div>";

        // 创建SVG容器标签
        const ecgViewStart = "<svg id='" + this.nodeElementId + "_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + this.winH + "' preserveAspectRatio='xMidYMid meet'>";
        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='" + this.nodeElementId + "_group_" + i + "' >";

        const ecgViewText = "<text x=0 y=" + (this.winH / 2) + ">CH-" + (i + 1) + ":" + leadName.List[i].label + "</text>"; //EcgDrawUtils._getECGViewText(i, this.winH);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='" + this.nodeElementId + "_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='" + this.nodeElementId + "_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(ecgData[i], i, annotationMap) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        const ecgViewEnd = "</svg>";

        const ecgViewContainerEnd = "</div>";
        ecgViewWrapNode.innerHTML += ecgViewContainerStart + ecgSvgZoomMark + ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd + ecgViewContainerEnd;
    }

    render(ecgData, annotationMap) {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById(this.nodeElementId);
        ecgViewWrapNode.innerHTML = "";

        if (this.type === EcgLightWaveType.Enum.Combined) {
            // 创建SVG容器标签
            const ecgViewStart = "<svg id='" + this.nodeElementId + "_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + this.winH + "' preserveAspectRatio='xMidYMid meet'>";

            let ecgViewContent = "";
            for (let i = 1; i < this.numLeads; i++) {

                // const offsetY = i * this.yCellCount * this.cellWidth;
                //
                // // 创建SVG的Group标签
                // const ecgViewGroupStart = "<g id='" + this.nodeElementId + "_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";
                //
                // const ecgViewText = "<text x=0 y=" + ((this.yCellCount * this.cellWidth) / 2) + ">CH-" + (i + 1) + ":" + leadName.List[i].label + "</text>"; //EcgDrawUtils._getECGViewText(i, this.winH);
                //
                // // 创建心电图背景图
                // const ecgViewBackgroundStart = "<g id='" + this.nodeElementId + "_background_group_" + i + "'>";
                // const ecgViewBackgroundEnd = "</g>";
                // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
                //
                // // 创建心电图波形
                // const ecgViewLightWaveStart = "<g id='" + this.nodeElementId + "_lightwave_group_" + i + "'>";
                // const ecgViewLightWaveEnd = "</g>";
                // // 创建心电图波形
                // const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(ecgData[i], i, annotationMap) + ecgViewLightWaveEnd;
                //
                // const ecgViewGroupEnd = "</g>";
                // ecgViewContent = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;

                ecgViewContent = this._renderEcgChainCombined(i, ecgData, annotationMap, ecgViewContent);
            }

            ecgViewContent = this._renderEcgChainCombined(0, ecgData, annotationMap, ecgViewContent);

            const ecgViewEnd = "</svg>";
            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
        } else if (this.type === EcgLightWaveType.Enum.Overlap) {
            // 画一个合并的图，可以展示比较各心电图的层叠效果
            const ecgViewContainerStart = "<div id='" + this.nodeElementId + "_container_0' class='ecg_view_container'>";
            const ecgSvgZoomMark = "<div id='" + this.nodeElementId + "_zoom_mark_0' class='ecg_svg_zoom_mark'></div>";

            // 创建SVG容器标签
            const ecgViewStart = "<svg id='" + this.nodeElementId + "_0" + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + this.winH + "' preserveAspectRatio='xMidYMid meet'>";
            // 创建SVG的Group标签
            const ecgViewGroupStart = "<g id='" + this.nodeElementId + "_group_0" + "' >";

            // 创建心电图背景图
            const ecgViewBackgroundStart = "<g id='" + this.nodeElementId + "_background_group_0" + "'>";
            const ecgViewBackgroundEnd = "</g>";
            const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

            // ecgViewWrapNode.innerHTML += ecgViewContainerStart + ecgSvgZoomMark + ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground;

            let ecgViewLightWaves = "";
            // 创建心电图波形
            const ecgViewLightWaveStart = "<g id='" + this.nodeElementId + "_lightwave_group_0" + "'>";
            for (let i = 1; i < this.numLeads; i++) {
                // 创建心电图波形
                const ecgViewLightWave = this._getECGViewLightWave(ecgData[i], i, {});
                ecgViewLightWaves += ecgViewLightWave;
            }
            const ecgViewLightWave = this._getECGViewLightWave(ecgData[0], 0, {});
            ecgViewLightWaves += ecgViewLightWave;

            const ecgViewLightWaveEnd = "</g>";

            const ecgViewGroupEnd = "</g>";
            const ecgViewEnd = "</svg>";
            const ecgViewContainerEnd = "</div>";
            ecgViewWrapNode.innerHTML += ecgViewContainerStart + ecgSvgZoomMark + ecgViewStart + ecgViewGroupStart + ecgViewBackground + ecgViewLightWaveStart + ecgViewLightWaves + ecgViewLightWaveEnd + ecgViewGroupEnd + ecgViewEnd + ecgViewContainerEnd;
        } else {
            for (let i = 1; i < this.numLeads; i++) {
                this._renderEcgChain(i, ecgData, annotationMap, ecgViewWrapNode);
                // const ecgViewContainerStart = "<div id='" + this.nodeElementId + "_container_" + i + "' class='ecg_view_container'>";
                // const ecgSvgZoomMark = "<div id='" + this.nodeElementId + "_zoom_mark_" + i + "' class='ecg_svg_zoom_mark'></div>";
                //
                // // 创建SVG容器标签
                // const ecgViewStart = "<svg id='" + this.nodeElementId + "_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + this.winH + "' preserveAspectRatio='xMidYMid meet'>";
                // // 创建SVG的Group标签
                // const ecgViewGroupStart = "<g id='" + this.nodeElementId + "_group_" + i + "' >";
                //
                // const ecgViewText = "<text x=0 y=" + (this.winH / 2) + ">CH-" + (i + 1) + ":" + leadName.List[i].label + "</text>"; //EcgDrawUtils._getECGViewText(i, this.winH);
                //
                // // 创建心电图背景图
                // const ecgViewBackgroundStart = "<g id='" + this.nodeElementId + "_background_group_" + i + "'>";
                // const ecgViewBackgroundEnd = "</g>";
                // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
                //
                // // 创建心电图波形
                // const ecgViewLightWaveStart = "<g id='" + this.nodeElementId + "_lightwave_group_" + i + "'>";
                // const ecgViewLightWaveEnd = "</g>";
                // // 创建心电图波形
                // const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(ecgData[i], i, annotationMap) + ecgViewLightWaveEnd;
                //
                // const ecgViewGroupEnd = "</g>";
                // const ecgViewEnd = "</svg>";
                //
                // const ecgViewContainerEnd = "</div>";
                // ecgViewWrapNode.innerHTML += ecgViewContainerStart + ecgSvgZoomMark + ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd + ecgViewContainerEnd;
            }
            this._renderEcgChain(0, ecgData, annotationMap, ecgViewWrapNode);
        }
    }

    _refreshLightWave(ecgData, annotationPointMap, ecgRulerPointMap) {
        if (this.type === EcgLightWaveType.Enum.Overlap) {
            const ecgViewLightwaveGroup = document.getElementById(this.nodeElementId + "_lightwave_group_0");
            ecgViewLightwaveGroup.innerHTML = "";
            for (let i = 1; i < this.numLeads; i++) {
                // 创建心电图波形
                const ecgViewLightWave = this._getECGViewLightWave(ecgData[i], i, {});
                ecgViewLightwaveGroup.innerHTML += ecgViewLightWave;
            }

            // 呼吸导联放最后描画
            const ecgViewLightWave = this._getECGViewLightWave(ecgData[0], 0, {});
            ecgViewLightwaveGroup.innerHTML += ecgViewLightWave;
        } else {
            for (let i = 1; i < this.numLeads; i++) {

                const ecgViewLightwaveGroup = document.getElementById(this.nodeElementId + "_lightwave_group_" + i);

                // 创建心电图波形
                const ecgViewLightWave = this._getECGViewLightWave(ecgData[i], i, annotationPointMap, ecgRulerPointMap);
                ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
            }

            // 呼吸导联放最后描画
            const ecgViewLightwaveGroup = document.getElementById(this.nodeElementId + "_lightwave_group_" + 0);

            // 创建心电图波形
            const ecgViewLightWave = this._getECGViewLightWave(ecgData[0], 0, annotationPointMap, ecgRulerPointMap);
            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }
    }

    /** 画心电图的背景 */
    _getECGViewBackground() {
        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + this.winMarginX + "," + this.winMarginY;

        // 画竖线
        const y = this.yCellCount * this.cellWidth;
        for (let i = 0; i <= this.xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + this.cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + this.winMarginX + "," + this.winMarginY;
        const x = this.xCellCount * this.cellWidth;
        for (let i = 0; i <= this.yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + this.cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        // 画原点
        const zeroRect = "<rect name='原点' x='" + (this.winMarginX - 2) + "' y='" + (this.zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    }

    /** 画心电图的折线 */
    _getECGViewLightWave(ecgPoints, index, annotationMap, ecgRulerMap) {

        let result = "";
        if (!ecgPoints || ecgPoints.length <= 0) {
            return result;
        }
        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";

        let x = this.winMarginX;
        for (let i = 0; i < this.screenPointSum; i++) {

            // 计算前一个点
            let preY = 0;
            if (i !== 0) {
                if (ecgPoints[i - 1].newPoint !== null) {
                    preY = ecgPoints[i - 1].newPoint;
                } else {
                    preY = ecgPoints[i - 1].point;
                    if (preY !== 0) {
                        preY = preY / this.gc;// * 0.000049;
                        preY = preY / (1 / (this.voltDiv / this.gridScale));
                        preY = preY * this.cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = this.zeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + this.zeroPointY;
                    }
                }
            }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (ecgPoints[i] === null || ecgPoints[i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (ecgPoints[i].newPoint !== null) {
                y = ecgPoints[i].newPoint;
            } else {
                y = ecgPoints[i].point;
                y = y / this.gc;// * 0.000049;
                y = y / (1 / (this.voltDiv / this.gridScale));
                y = y * this.cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = this.zeroPointY - y;
                } else {
                    y = Math.abs(y) + this.zeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                ecgPoints[i].newPoint = y;
            }

            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + this.winMarginX + "," + y;
            } else {
                path = path + " l" + this.pointSpace + "," + (y - preY)
            }

            x = x + this.pointSpace;

            result = result + this._getECGViewLightWaveAnnotationLine(annotationMap, x, i);

            if (ecgRulerMap && ecgRulerMap[i]) {
                // 只有当前导联的标尺，并且不是删除状态的，才需要描画删除标识的标注不描画
                if (ecgRulerMap[i].index === index.toString() && ecgRulerMap[i].deleteFlag !== 1) {
                    result = result + ecgRulerMap[i].svg;
                }
            }
        }

        // console.log(result);

        result = result + "<path stroke='" + this.lineColors[index] + "' fill='none' strokeWidth='1' d='" + path + "'></path>";
        return result;
    }

    _getECGViewLightWaveAnnotationLine(annotationMap, x, index) {
        // 标注线整体偏右，所以调整两个像素
        const adjustX = x - 1;

        let result = "";
        // 绘制心电图标注
        // 判断标注Map里是否包含当前历史文件索引
        if (annotationMap && annotationMap[index]) {
            // 取出标注对象
            const annotation = annotationMap[index];
            // 删除标识的标注不描画
            if (annotation.deleteFlag !== 1) {
                const y = this.yCellCount * this.cellWidth;
                const auxLine = "<line id='" + annotation.id + "_" + index + "' class='ecg-device-file-annotation' x1='" + adjustX + "' y1='" + this.winMarginY + "' x2='" + adjustX + "' y2='" + y + "' stroke-dasharray='5 2' stroke='grey' stroke-width='5'/>";
                const auxLineText = "<text y='" + (y + 10) + "' x='" + (x - 5) + "'>" + annotation.annotation + "</text>";
                result = result + auxLine + auxLineText;
            }
        }
        return result;
    }
}

export default EcgLightWave;