/**
 * Created by Ethan on 2017/11/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Form, DatePicker, Button, Select} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import DateRangeDivider from "../../../../../components/DateRangeDivider";
import moment from "moment";
import {searchDeviceCoordinates, refreshSearchCondition} from "../../../actions/DeviceCoordinateAction";

let locationMap;
const {Option} = Select;

class DeviceMapTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            zoom: 17
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        const lng = this.props.device && this.props.device.longitude;
        const lat = this.props.device && this.props.device.latitude;
        locationMap = new window.BMap.Map("locationMap");    // 创建Map实例
        locationMap.centerAndZoom(new window.BMap.Point(lng, lat), this.state.zoom);  // 初始化地图,设置中心点坐标和地图级别
        locationMap.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        this.forceUpdate();
    }

    render() {

        const {formatMessage} = this.state;
        const {searchCondition} = this.props;
        console.log(searchCondition);
        // const data = [];
        // let lng = this.props.device.longitude;
        // // if (this.props.longitude) {
        // //     lng = (parseInt(this.props.longitude / 100)) + (this.props.longitude % 100) / 60;
        // // }
        //
        // let lat = this.props.device.latitude;
        // // if (this.props.latitude) {
        // //     lat = (parseInt(this.props.latitude / 100)) + (this.props.latitude % 100) / 60;
        // // }
        //
        // console.log(lng);
        // console.log(lat);
        // data.push({
        //     name: this.props.device.code,
        //     color: 'red',
        //     isShowNumber: false,
        //     point: {
        //         lng: lng,
        //         lat: lat
        //     }
        // });
        // console.log(this.props.device);

        if (locationMap && this.props.device && this.props.coordinates.length) {
            locationMap.clearOverlays();
            this._renderMapMarker(this.props.device)
        }

        return (
            <div style={{height: 500, position: 'relative'}}>
                <Form layout="inline" style={{position: 'absolute', zIndex: 9999}}>
                    <Form.Item>
                        <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                    format={FORMAT_DATE_TIME_HYPHEN}
                                    value={searchCondition.startTime ? moment(searchCondition.startTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                    placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                    onChange={(value) => {
                                        this._handleChange("startTime", value.format(FORMAT_DATE_TIME_SIMPLE))
                                    }}/>
                        <DateRangeDivider/>
                        <DatePicker className="spacing-h"
                                    showTime={{format: FORMAT_TIME_COLON}}
                                    format={FORMAT_DATE_TIME_HYPHEN}
                                    value={searchCondition.endTime ? moment(searchCondition.endTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                    placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                    onChange={(value) => {
                                        this._handleChange("endTime", value.format(FORMAT_DATE_TIME_SIMPLE))
                                    }}/>
                        {/*
                        <Select defaultValue="0" style={{ width: 140 }}>
                            <Option value="0">GPS+网络定位</Option>
                            <Option value="1">仅GPS</Option>
                            <Option value="2">仅网络定位</Option>
                        </Select>
                        */}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" icon="search" loading={this.props.isLoading}
                                onClick={() => this._handleSearch(this.props.searchCondition)}>
                            <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                        </Button>
                    </Form.Item>
                </Form>
                <div id="locationMap" style={{height: '100%'}}></div>
            </div>
        );
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleSearch() {
        const {device, searchCondition} = this.props;
        const {formatMessage} = this.state;
        this.props.dispatch(searchDeviceCoordinates(device.id, searchCondition.startTime, searchCondition.endTime, formatMessage));
    }

    _renderMapMarker(item) {
        const point = new window.BMap.Point(item.longitude, item.latitude);
        // const label = new window.BMap.Label;
        // label.setContent(item.code);
        //Label的中心定位，个位数的时候和2位数的时候分开处理。
        //标注在图片上显示的偏移量
        // label.setOffset(new window.BMap.Size(11, 5));
        // label.setStyle({
        //     width: "26px", height: "28px",
        //     fontSize: "12px", backgroundColor: "transparent",
        //     border: "none", fontWeight: "900"
        // });
        const blueUrl = require("../../../../../images/bmap/location-blue.svg");
        //蓝色默认
        let blueIcon = new window.BMap.Icon(blueUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });

        const marker = new window.BMap.Marker(point, {icon: blueIcon});
        // marker.setLabel(label);
        let sMarker;
        let eMarker;
        const viewPointList = [];
        for (let i = 0; i < this.props.coordinates.length; i++) {
            const coordinate = this.props.coordinates[i];
            if (i === 0) {
                const sPoint = new window.BMap.Point(coordinate.longitude, coordinate.latitude);
                sMarker = new window.BMap.Marker(sPoint);
                const sLabel = new window.BMap.Label;
                sLabel.setContent("起");
                //Label的中心定位，个位数的时候和2位数的时候分开处理。
                //标注在图片上显示的偏移量
                sLabel.setOffset(new window.BMap.Size(3, 3));
                sLabel.setStyle({
                    width: "26px", height: "28px",
                    fontSize: "10px", backgroundColor: "transparent",
                    color: "#FFF",
                    border: "none", fontWeight: "900"
                });
                sMarker.setLabel(sLabel);
            } else if (i === this.props.coordinates.length - 1) {
                const ePoint = new window.BMap.Point(coordinate.longitude, coordinate.latitude);
                eMarker = new window.BMap.Marker(ePoint);
                const eLabel = new window.BMap.Label;
                eLabel.setContent("终");
                //Label的中心定位，个位数的时候和2位数的时候分开处理。
                //标注在图片上显示的偏移量
                eLabel.setOffset(new window.BMap.Size(3, 3));
                eLabel.setStyle({
                    width: "26px", height: "28px",
                    fontSize: "10px", backgroundColor: "transparent",
                    color: "#FFF",
                    border: "none", fontWeight: "900"
                });
                eMarker.setLabel(eLabel);
            }
            viewPointList.push(new window.BMap.Point(coordinate.longitude, coordinate.latitude));
        }

        console.log("centerAndZoom");
        locationMap.centerAndZoom(new window.BMap.Point(item.longitude, item.latitude), this.state.zoom);  // 初始化地图,设置中心点坐标和地图级别

        const polyline = new window.BMap.Polyline(viewPointList
            , {
                strokeColor: 'blue',
                strokeWeight: 2,
                strokeOpacity: 0.5
            });

        if (locationMap) {
            locationMap.addOverlay(marker);
            locationMap.addOverlay(sMarker);
            locationMap.addOverlay(eMarker);
            locationMap.addOverlay(polyline);
            // setTimeout(()=>{
            //     const trackAni = new window.BMapLib.TrackAnimation(locationMap, polyline, {
            //         overallView: true,
            //         tilt: 30,
            //         duration: 20000,
            //         delay: 300
            //     });
            //     trackAni.start();
            // }, 3000);
        }
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceCoordinateReducer.isLoading,
        device: store.EcgDeviceReducer.device,
        coordinates: store.EcgDeviceCoordinateReducer.coordinates,
        searchCondition: store.EcgDeviceCoordinateReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceMapTab));
