/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import PageTitle from "../../../../../components/PageTitle";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import CreateDeviceModal from "./CreateDeviceModal";
import DetailModal from "../Monitor/DetailModal";
import {Layout} from "antd";
import "./Index.css";
import FilterBMap from "./FilterBMap";

const {Header, Footer, Sider, Content} = Layout;
let bmap;
class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        return (
            <div className="position-relative page-content" style={{height: document.documentElement.clientHeight - 64 - 50}}>
                <CreateDeviceModal/>
                <DetailModal/>
                <SearchCondition/>
                <Layout style={{height: document.documentElement.clientHeight - 64 - 50}}>
                    <Sider width={420}
                           style={{
                               background: '#fff',
                               height: document.documentElement.clientHeight - 64 - 50,
                               overflowY: 'scroll',
                           }}>
                       <SearchResult bmap={bmap} {...this.props}/>
                    </Sider>
                    <Content style={{height: document.documentElement.clientHeight - 64 - 50}}>
                        <FilterBMap {...this.props}/>
                    </Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));