/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Icon} from "antd";

import {searchDevicesActiveSum} from "../../../actions/DeviceAction";
import {RoutePath} from "../../../../../constants/RoutePath";

class DevicesActiveSum extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(searchDevicesActiveSum(this.state.formatMessage));
    }

    _handleShowDetailType(showDetailType) {
        console.log(showDetailType);
        // this.props.dispatch(changeShowDetailType(showDetailType));
    }

    render() {
        return (
            <div className="small-box green">
                <div className="inner">
                    <h3>{this.props.devicesActiveSum}</h3>
                    <p><FormattedMessage id="DAS_DEVICE_FIELD_DEVICES_ACTIVE_SUM"/></p>
                </div>
                <div className="icon">
                    <Icon type="video-camera" theme="outlined" />
                </div>
                <Link to={RoutePath.DAS_DEVICE_LIST.path} className="small-box-footer" onClick={() => this._handleShowDetailType()}>
                    <FormattedMessage id="COMMON_BUTTON_DETAIL"/> <Icon type="right-circle" />
                </Link></div>
        );
    }
}

function mapStateToProps(store) {
    return {
        devicesActiveSum: store.EcgDeviceReducer.devicesActiveSum
    }
}

export default connect(mapStateToProps)(injectIntl(DevicesActiveSum));