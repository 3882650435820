/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';
import {Link} from 'react-router';
import {
    Modal,Table,Switch,Button,Icon,Row,Col,Form,DatePicker,Input
} from "antd";

import {
    refreshShowWarnRecordModal,
    refreshWarnFlag,
    refreshSearchCondition,
    searchWarnRecord
} from "../actions/AppAction";
import {toggleCacheModal} from "../modules/DAS/actions/DeviceAction";
import {RoutePath} from "../constants/RoutePath";
import {WARN_RECORD} from "../constants/Profile";
import {FORMAT_DATE_TIME_HYPHEN, FORMAT_DATE_TIME_SIMPLE, FORMAT_TIME_COLON} from "../constants/DateTimeFormats";
import {guardModel, userModel} from "../modules/DAS/Enums";
import EnumItemSelect from "../components/EnumItemSelect";
import moment from "moment";


class WarnRecordModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            showSearch:false,
            isSearch:false,
            columns: [
                {
                    key: 'createTime',
                    dataIndex: 'createTime',
                    title: "报警时间",
                },
                {
                    key: "userName",
                    dataIndex: "userName",
                    title: "用户名",
                },
                {
                    key: "deviceCode",
                    dataIndex: "deviceCode",
                    title: "设备编号",
                    render: (text, record, index) => this._renderDeviceCode(text, record, index)
                },
                {
                    key: "phoneNumber",
                    dataIndex: "phoneNumber",
                    title: "手机号",
                },
                {
                    key: "guardMode",
                    dataIndex: "guardMode",
                    title: "监护模式",
                    render: (text, record, index) => this._renderGuardModel(record)
                },
                {
                    key: "appVersion",
                    dataIndex: "appVersion",
                    title: "APP版本",
                },
                {
                    key: "phoneBrand",
                    dataIndex: "phoneBrand",
                    title: "手机品牌",
                },
                {
                    key: "phoneModel",
                    dataIndex: "phoneModel",
                    title: "手机型号",
                },
                {
                    key: "map",
                    dataIndex: "map",
                    title: "地图定位",
                    render: (text, record, index) => this._renderMap(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        let {searchCondition} = this.props;
        searchCondition.deviceCode = "";
        searchCondition.guardMode = "";
        searchCondition.userName = "";
        searchCondition.phoneNumber = "";
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    componentDidMount() {
    }

    _renderDeviceCode(text, record, index){

        return (
            <div>
                <Link
                    // to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.deviceCode + "&tabIndex=4" + "&start='1'"}
                    onClick={() => this._handleLink(record,"4")}>
                    {record.deviceCode}
                </Link>
            </div>
        )
    }

    _renderMap(text, record, index) {
        return (
            <div>
                <Link
                    // to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.deviceCode + "&tabIndex=3" + "&lng=" + record.longitude + "&lat=" + record.latitude}
                    onClick={() => this._handleLink(record,"3")}>
                    <FormattedMessage id="COMMON_WARN_MAP"/>
                </Link>
            </div>
        )
    }

    _handleLink(record,index){
        this._handleCancel();

        const {location: {pathname}} = this.props;

        if(index === "4"){
            this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.deviceCode + "&tabIndex=4" + "&start='1'");
        }else{
            this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.deviceCode + "&tabIndex=3" + "&lng=" + record.longitude + "&lat=" + record.latitude);
        }

        if(pathname === RoutePath.DAS_DEVICE_DETAIL.path){
            console.log("_handleLink");
            // this.props.setDefaultActiveKey(index);

            window.location.reload();
        }

    }

    _renderGuardModel(record){
        console.log("guardMode:" + record.guardMode);
        let text = "未知";
        if(record.guardMode === "1"){
            text = "监护他人";
        }else if(record.guardMode === "2"){
            text = "自我监护";
        }else if(record.guardMode === "3"){
            text = "监护自己和他人";
        }

        return(
            <div>
                {text}
            </div>
        );
    }

    _handleChange(isWarn){
        this.props.dispatch(refreshWarnFlag(isWarn));
    }

    _handleCancel() {
        this.props.dispatch(refreshShowWarnRecordModal(false));
    }

    _handleShowSearch(){

        this.setState({showSearch:!this.state.showSearch,isSearch:!this.state.showSearch});

    }

    _handleChangeCondition(name,value){
        let searchCondition = Object.assign({}, this.props.searchCondition);
        let targetValue = value.target ? value.target.value : value;
        if ((value || value === 0) && targetValue !== ""){
            searchCondition[name] = value.target ? value.target.value : value;
        } else {
            delete searchCondition[name];
        }

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleSearch(searchCondition){
        this.setState({isSearch:true});
        this.props.dispatch(searchWarnRecord(searchCondition));
    }

    _handleEmpty() {
        this.props.dispatch(refreshSearchCondition({}));
    }

    render() {
        const {formatMessage} = this.state;
        const {searchCondition} = this.props;
        let record = this.props.record;

        if(this.state.isSearch && record !== null){
            for(let i=0; i<record.length; i++){
                let dt = record[i].createTime;
                if(dt.length === 17){
                    record[i].createTime = dt.substring(0,4) + "/" + dt.substring(4,6) + "/" + dt.substring(6,8) + " "
                        + dt.substring(8,10) + ":" + dt.substring(10,12) + ":" + dt.substring(12,14);
                }

            }
        }

        return (
            <Modal
                title={"报警记录"}
                maskClosable={false}
                width={1200}
                centered={true}
                visible={this.props.showWarnRecordModal}
                onCancel={() => this._handleCancel()}
                footer={null}>

                <div style={{marginTop: -50}}>
                    <div style={{position:"absolute",marginLeft:100}}>
                        <Button type="primary"
                                onClick={() => this._handleShowSearch()}>
                            <FormattedMessage id="ECG_WARN_BUTTON_SEARCH"/>
                            <Icon type={this.state.showSearch ? 'up' : 'down'} />
                        </Button>
                    </div>

                    <div style={{position:"absolute",marginLeft:1000,marginTop:3}}>
                        报警提示
                        <Switch defaultChecked
                                checkedChildren="开启" unCheckedChildren="关闭"
                                onChange={(value) => {
                                    this._handleChange(value)
                                }}
                                style={{position:"absolute",marginLeft:10}}/>
                    </div>

                </div>

                {this.state.showSearch ?
                    <Form layout="inline" style={{backgroundColor: "#ffffcc", paddingLeft: "5px",paddingTop: "5px",marginTop: 50}}>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    style={{paddingLeft: 10}}
                                    label={formatMessage({id: 'ECG_WARN_SEARCH_USAGE_TIME'})}>
                                    <Form.Item>
                                        <DatePicker
                                                    showTime={{format: FORMAT_TIME_COLON}}
                                                    format={FORMAT_DATE_TIME_HYPHEN}
                                                    allowClear={true}
                                                    value={
                                                        searchCondition.sosTimeFrom ?
                                                            moment(searchCondition.sosTimeFrom, FORMAT_DATE_TIME_SIMPLE)
                                                            :
                                                            null
                                                    }
                                                    placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                                    onChange={(value) => {
                                                        this._handleChangeCondition("sosTimeFrom", value ? value.format(FORMAT_DATE_TIME_SIMPLE) : "")
                                                    }}/>
                                    </Form.Item>
                                    <Form.Item>--</Form.Item>
                                    <Form.Item>
                                        <DatePicker
                                            showTime={{format: FORMAT_TIME_COLON}}
                                            format={FORMAT_DATE_TIME_HYPHEN}
                                            allowClear={true}
                                            value={
                                                searchCondition.sosTimeTo ?
                                                    moment(searchCondition.sosTimeTo, FORMAT_DATE_TIME_SIMPLE)
                                                    :
                                                    null
                                            }
                                            placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                            onChange={(value) => {
                                                this._handleChangeCondition("sosTimeTo", value ? value.format(FORMAT_DATE_TIME_SIMPLE) : "")
                                            }}/>
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={formatMessage({id: 'ECG_WARN_SEARCH_GUARDER_MODEL'})}>
                                    <EnumItemSelect data={userModel.List}
                                                    value={searchCondition.guardMode}
                                                    style={{width: 150}}
                                                    onChange={(value) => {
                                                        this._handleChangeCondition("guardMode",value);
                                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>
                                <Form.Item
                                    style={{paddingLeft: 51}}
                                    label={formatMessage({id: 'ECG_WARN_SEARCH_USER'})}>
                                    <Input
                                        value={searchCondition.userName}
                                        style={{width: 150}}
                                        onChange={(value) => {
                                            this._handleChangeCondition("userName", value)
                                        }}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    style={{paddingLeft: 25}}
                                    label={formatMessage({id: 'ECG_WARN_SEARCH_DEVICE_CODE'})}>
                                    <Input
                                        value={searchCondition.deviceCode}
                                        style={{width: 150}}
                                        onChange={(value) => {
                                            this._handleChangeCondition("deviceCode", value)
                                        }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    style={{paddingLeft: 15}}
                                    label={formatMessage({id: 'ECG_WARN_SEARCH_PHONE_NUMBER'})}>
                                    <Input
                                        value={searchCondition.phoneNumber}
                                        style={{width: 150}}
                                        onChange={(value) => {
                                            this._handleChangeCondition("phoneNumber", value)
                                        }}/>
                                    <Button type="primary" icon="delete" className="spacing-h"
                                            style={{marginLeft: 150}}
                                            onClick={() => this._handleEmpty()}>
                                        <FormattedMessage id='COMMON_BUTTON_EMPTY'/>
                                    </Button>
                                    <Button type="primary" icon="search" loading={this.props.isWarnLoading}
                                            style={{marginLeft: 20}}
                                            onClick={() => this._handleSearch(this.props.searchCondition)}>
                                        <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    : null
                }

                <Table rowKey="keyId"
                       size="small"
                       className="spacing-v"
                       pagination={true}
                       columns={this.state.columns}
                       // scroll={{y: 638}}
                       // dataSource={this.props.warnRecord}
                       dataSource = {this.state.isSearch ? this.props.record : JSON.parse(localStorage.getItem(WARN_RECORD))}
                       style={this.state.showSearch ? {marginTop: 10} : {marginTop: 50}}
                />

            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showWarnRecordModal: store.AppReducer.showWarnRecordModal,
        warnRecord: store.AppReducer.warnRecord,
        searchCondition:store.AppReducer.searchCondition,
        isWarnLoading:store.AppReducer.isWarnLoading,
        record:store.AppReducer.record,
    }
}

export default connect(mapStateToProps)(injectIntl(WarnRecordModal));