/**
 * 心电文件的公共头文件
 * 名称    字节数    数据类型    数值范围    说明
 * FileVersion    8B    字符串    “FV1.00”    文件格式的版本号
 * DeviceID    20B    字符串    “HC21MRHH00007201”    设备编号，初始时为0
 * UserID    20B    字符串    “US0021XX00006601”    绑定的用户ID，初始时为0
 * DeviceVersion    8B    字符串    “HC1.0”    设备型号
 * HwVersion    8B    字符串    “HV4.10”    硬件版本号
 * DSPFirmwareVersion    8B    字符串    “DV4.05”    DSP固件版本号
 * ESPFirmwareVersion    8B    字符串    “EV4.71”    ESP固件版本号
 * FileTime    8B            文件产生的时间（7B格式统一为8B格式）
 * DataCompress    4B    UInt32    0-232-1    文件内容的压缩方式【本项其实冗余，因为也可以通过文件版本号来区分】
 * 这里主要是采用无损压缩算法
 * 0: 无压缩 1: 压缩函数版本1 2: 压缩函数版本2
 */
import EcgFileDateTime from "./EcgFileDateTime";
import {FORMAT_DATE_TIME_SIMPLE} from "../../../constants/DateTimeFormats";

class EcgFileCommonHeader {
    constructor(dataArray, startPos) {
        let index = startPos;
        const fileVersionArray = dataArray.slice(index, index + 8);
        this.fileVersion = fileVersionArray;
        index = index + 8;

        const deviceIDArray = dataArray.slice(index, index + 20);
        this.deviceID = "";
        for (let i = 12; i < deviceIDArray.length; i++) {
            if (deviceIDArray[i] === 0) {
                this.deviceID = this.deviceID + "0";
            } else {
                this.deviceID = this.deviceID + String.fromCharCode(deviceIDArray[i]);
            }
        }
        index = index + 20;

        const userIDArray = dataArray.slice(index, index + 20);
        this.userID = userIDArray;
        index = index + 20;

        const deviceVersionArray = dataArray.slice(index, index + 8);
        this.deviceVersion = deviceVersionArray;
        index = index + 8;

        const hwVersionArray = dataArray.slice(index, index + 8);
        this.hwVersion = hwVersionArray;
        index = index + 8;

        const dspFirmwareVersionArray = dataArray.slice(index, index + 8);
        this.dspFirmwareVersion = dspFirmwareVersionArray;
        index = index + 8;

        const espFirmwareVersionArray = dataArray.slice(index, index + 8);
        this.espFirmwareVersion = espFirmwareVersionArray;
        index = index + 8;

        const ecgFileDateTime = new EcgFileDateTime(dataArray, index);
        this.fileTime = ecgFileDateTime.getDateTime(FORMAT_DATE_TIME_SIMPLE);
        index = index + ecgFileDateTime.length;

        const dataCompressArray = dataArray.slice(index, index + 4);
        this.dataCompress = new Int32Array(dataCompressArray.buffer.slice(-4))[0];
        index = index + 4;

        this.length = index - startPos;
    }
}

export default EcgFileCommonHeader;