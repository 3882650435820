import React, {Component} from "react";
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';
import {Col, Row, Slider, Popover, Button, Radio} from "antd";
import message from "../../../../../../components/toast";
import {ECG_SOCKET_SERVER_HOST} from "../../../../../../constants/Profile";
import WebSocketUtils from "../../../../utils/WebSocketUtils";
import ProtocolCommonHeader from "../../../../entities/ProtocolCommonHeader";
import {EcgFileType, ProtocolPackageType, Year} from "../../../../Enums";
import * as echarts from 'echarts';
import ProtocalHistoryData from "../../../../entities/ProtocalHistoryData";
import {getDeviceById, getDeviceHistoryData, getReadFailFiles} from "../../../../actions/DeviceAction";
import {height} from "plotly.js/src/plots/layout_attributes";
import ScatterChart from "../../../../../../utils/ScatterChart";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";
import EcgFileEventIndex from "../../../../entities/EcgFileEventIndex";
import EcgFileTimeIndex from "../../../../entities/EcgFileTimeIndex";
import {chiSquaredGoodnessOfFit} from "simple-statistics";

let webSocket;
let isShow = true;

let myChart;
let main;
let option;

let manualStopSearch = false;
let historyData
let fileType = "03"
let r5Flag = false;
let appHistoryDataNumber;
let appHistoryDataIndex
let devHistoryDataNumber;
let devHistoryDataIndex

let historyFileIndex
let historyFileNumber

let devEcgSliderNodes;
let appEcgSliderNodes;
let ecgSliderTrackNodes;

let devMark = {};
let appMark = {};
let devMark2 = {};
let appMark2 = {};

let deviceHistoryData;
let appHistoryData;

let recordIndex = [0, 0];

let radioValue = [1, 1]
let failFilesMapDev = []
let failFilesMapApp = []


let color = ["rgb(1, 131, 226)", "rgb(221, 131, 226)", "rgb(221, 131, 1)", "rgb(221, 1, 1)", "rgb(221, 221, 1)", "rgb(1, 131, 226)", "rgb(221, 131, 226)", "rgb(221, 131, 1)", "rgb(221, 1, 1)", "rgb(221, 221, 1)", "rgb(1, 131, 226)", "rgb(221, 131, 226)", "rgb(221, 131, 1)", "rgb(221, 1, 1)", "rgb(221, 221, 1)"]

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            historyData: {},
            devMarks: {},
            appMarks: {},
            r5: true
        }
    }

    componentWillMount() {
        this.props.dispatch(getReadFailFiles(this.props.deviceCode, this.props.mapVersion[0]
            , 0, fileType, this.state.formatMessage))
        this.props.dispatch(getReadFailFiles(this.props.deviceCode, this.props.mapVersion[0]
            , 1, fileType, this.state.formatMessage))
    }


    componentWillUnmount() {
        clearInterval(this.intervalGetDeviceById)
    }

    componentDidMount() {
        this._handleSearch();
        this.props.historyRef(this)
        this.intervalGetDeviceById = setInterval(() => {
            // this.setState({devMarks: this.state.devMarks})
            if (this.props.isShow) {
                this._r5();
            }

        }, 60000);
    }

    _handleSearch = () => {
        let self = this;
        webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {

            // 打开一个连接
            // message.success("链接服务器成功：" + e.target.url);
            WebSocketUtils._sendHello(webSocket, this.props.deviceCode);
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            let startPos = 0;


            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            // console.log(protocolCommonHeader);
            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                // message.success("连接成功，开始发送获取文件长度请求");
                WebSocketUtils._sendGetDeviceDataStatic(webSocket, this.props.deviceCode, fileType, 0, this.props.channelType)
                return;
            }

            // console.log("dataArray", dataArray, protocolCommonHeader, protocolCommonHeader.packetType)
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.CommonReply) {
                // 0x0000 通用成功
                // 0xAAEF 超时
                if (dataArray[14] !== 0 && dataArray[15] !== 0) {
                    message.error("服务器中没有数据");
                    return;
                }
            }

            // 服务器向客户端发送对应的文件。【上行】
            // PacketType	1B	0x31：文件请求响应	包类型
            // 其他类型的包，不需要处理
            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.Statis) {
                return;
            }

            // 解析历史文件里的data数据
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            historyData = new ProtocalHistoryData(dataArray, startPos);
            //
            startPos = startPos + historyData.length;
            // const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            //
            //
            // startPos = startPos + ecgFileCommonHeader.length;


            // if (protocolCommonHeader.packetType == ProtocolPackageType.EnumInt.Statis) {
            switch (historyData.fileType) {
                case EcgFileType.EnumInt.MIXDATA:
                    message.success("历史文件获取成功")
                    break
                case EcgFileType.EnumInt.EVNTIDX:
                    message.success("事件文件获取成功")
                    break
                case EcgFileType.EnumInt.TIMEIDX:
                    message.success("时间文件获取成功")
                    break
            }
            devMark = {}
            appMark = {}
            devMark2 = {}
            appMark2 = {}
            if (historyData) {
                devMark2[1] = 1
                devMark[historyData.fileIndex] = historyData.fileIndex
                devMark[historyData.fileIndex] = {
                    style: {color: "#f50"},
                    value: historyData.fileIndex,
                    label: "UB:" + historyData.fileIndex
                }
                devMark[historyData.fileNumber] = historyData.fileNumber
                devMark[historyData.fileNumber] = {
                    style: {color: "#f50"},
                    value: historyData.fileNumber,
                    label: "LB:" + historyData.fileNumber
                }
                appMark2[1] = 1
                appMark[historyData.fileIndex] = historyData.fileIndex
                appMark[historyData.fileIndex] = {
                    style: {color: "#f50"},
                    value: historyData.fileIndex,
                    label: "UB:" + historyData.fileIndex
                }
                appMark[historyData.fileNumber] = historyData.fileNumber
                appMark[historyData.fileNumber] = {
                    style: {color: "#f50"},
                    value: historyData.fileNumber,
                    label: "LB:" + historyData.fileNumber
                }
            } else {
                devMark[1] = {
                    style: {color: "#f50"},
                    value: 1,
                    label: "LB:" + 1
                }
                devMark2[1] = 1
                appMark2[1] = 1
                devMark2[100] = 100
                devMark[100] = {
                    style: {color: "#f50"},
                    value: 100,
                    label: "UB:" + 100
                }
                appMark[1] = {
                    style: {color: "#f50"},
                    value: 1,
                    label: "LB:" + 1
                }
                appMark2[100] = 100
                appMark[100] = {
                    style: {color: "#f50"},
                    value: 100,
                    label: "UB:" + 100
                }
            }
            this.setState({historyData: historyData})
            this.setState({devMarks: devMark})
            this.setState({appMarks: appMark})
            // }
            // else {

            // switch (historyDataV.fileType) {
            //     case EcgFileType.EnumInt.MIXDATA:
            //         message.success("历史文件日期获取成功")
            //         historyFileNumber = historyDataV
            //         console.log("historyFileNumber:", historyDataV)
            //         break
            //     case EcgFileType.EnumInt.EVNTIDX:
            //         message.success("事件文件获取成功")
            //         historyFileNumber = new EcgFileEventIndex(dataArray, startPos);
            //         console.log("historyFileNumber:", historyFileNumber)
            //         break
            //     case EcgFileType.EnumInt.TIMEIDX:
            //         message.success("时间文件获取成功")
            //         historyFileNumber = new EcgFileTimeIndex(dataArray, startPos);
            //         console.log("historyFileNumber:", historyFileNumber)
            //         break
            // }
            // }

        };
        webSocket.onerror = (e) => {
            // message.error("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            message.warn("与服务器断开了链接：" + evnt.target.url);

            if (!manualStopSearch) {
                message.info("尝试重新连接：" + evnt.target.url);
                this._handleSearch(false);
            }
        };
    };

    _clear() {
        appMark = {};
        devMark = {};
        webSocket && webSocket.close();
        manualStopSearch = true
        appHistoryDataNumber = null
        appHistoryDataIndex = null
        devHistoryDataNumber = null
        devHistoryDataIndex = null

        isShow = false

        appHistoryData = null
        deviceHistoryData = null

        while (ecgSliderTrackNodes && ecgSliderTrackNodes.length > 0) {
            for (let i = 0; i < ecgSliderTrackNodes.length; i++) {
                ecgSliderTrackNodes[i].parentNode.removeChild(ecgSliderTrackNodes[i]);
            }
        }
        this.setState({historyData: historyData})
    }

    _r5() {
        appMark = {};
        devMark = {};


        appHistoryDataNumber = null
        appHistoryDataIndex = null
        devHistoryDataNumber = null
        devHistoryDataIndex = null

        appHistoryData = null
        deviceHistoryData = null

        while (ecgSliderTrackNodes && ecgSliderTrackNodes.length > 0) {
            for (let i = 0; i < ecgSliderTrackNodes.length; i++) {
                ecgSliderTrackNodes[i].parentNode.removeChild(ecgSliderTrackNodes[i]);
            }
        }

        if (webSocket) {
            message.warn("获取数据")
            WebSocketUtils._sendGetDeviceDataStatic(webSocket, this.props.deviceCode, fileType, 0, this.props.channelType)
            this.props.dispatch(getDeviceHistoryData(this.props.deviceCode, "dev", fileType, this.state.formatMessage));
            this.props.dispatch(getDeviceHistoryData(this.props.deviceCode, "app", fileType, this.state.formatMessage));
            this.props.dispatch(getReadFailFiles(this.props.deviceCode, this.props.mapVersion[0]
                , 0, fileType, this.state.formatMessage))
            this.props.dispatch(getReadFailFiles(this.props.deviceCode, this.props.mapVersion[0]
                , 1, fileType, this.state.formatMessage))
        }
    }

    _initHostCharts(value, failFilesMap, dataSouce) {
        if (myChart) {
            myChart.clear()
        }
        let hang = 20
        let lie = 50
        //
        let huang = 100
        let lan = 200
        let hong = 300

        main = document.getElementById("hostMain");
        myChart = echarts.init(main);
        // let noise = getNoiseHelper();
        let xData = [];
        let yData = [];

        let data = [];

        for (let j = value + 1000; j >= value; j -= lie) {
            yData.push(j)
        }
        // console.log("_initHostCharts1:",failFilesMap)
        for (let i = 0; i < lie; i++) {
            for (let j = hang; j >= 0; j--) {
                // console.log("_initHostCharts2:",failFilesMap[value - 500 + j * 100 + i])
                if (failFilesMap[value + j * lie + i]) {
                    data.push([i, Math.abs(j - 20), value + j * lie + i, failFilesMap[value + j * lie + i]]);
                } else {
                    let datas
                    datas = [i, Math.abs(j - 20), value + j * lie + i, 0]
                    if (historyData.fileNumber <= (value + j * lie + i)
                        && (value + j * lie + i) <= historyData.fileIndex) {
                        datas = ([i, Math.abs(j - 20), value + j * lie + i, huang]);
                    }
                    if (dataSouce) {
                        let is = false
                        for (let x = 0; x < dataSouce.length && !is; x++) {
                            if ((value + j * lie + i) >= dataSouce[x].beginIdx
                                && (value + j * lie + i) <= dataSouce[x].endIdx
                                && (value + j * lie + i) <= historyData.fileIndex) {
                                datas = ([i, Math.abs(j - 20), value + j * lie + i, lan]);
                                is = true;
                            } else if ((value + j * lie + i) >= dataSouce[x].beginIdx
                                && (value + j * lie + i) <= dataSouce[x].endIdx
                                && (value + j * lie + i) >= historyData.fileIndex) {
                                datas = ([i, Math.abs(j - 20), value + j * lie + i, hong]);
                                is = true;
                            }
                        }
                    }
                    data.push(datas);
                }
            }
        }

        option = {
            tooltip: {
                show: true,
                formatter: function (params) {
                    let value = params.value[3] > 10 ? 0 : params.value[3]
                    return "文件编号：" + params.value[2] + " <br/>失败次数：" + value
                },
            },
            grid: {
                left: 350,
                top: 0
            },
            xAxis: {
                type: 'category',
                data: xData
            },
            yAxis: {
                type: 'category',
                data: yData
            },
            visualMap: {
                type: 'piecewise',
                min: 0,
                max: 10,
                left: 'right',
                top: 'center',
                show: false,
                calculable: true,
                realtime: false,
                // splitNumber: 10,
                pieces: [
                    {value: 0, color: 'rgba(217, 217, 217, 1)'},
                    {value: 1, color: "#46c7e4"},
                    {min: 2, max: 4, color: '#1feef9'},
                    {min: 5, max: 10, color: '#f798b7'},
                    {value: 100, color: "#f8d703"},
                    {value: 200, color: "rgb(1,131,221)"},
                    {value: 300, color: "#c6156a"},
                ],
            },
            series: [
                {
                    name: '失败次数',
                    type: 'heatmap',
                    label: {
                        show: true,
                        formatter: function (params) {
                            return params.value[3] == 0 || params.value[3] > 10 ? "" : params.value[3];
                        },
                    },
                    data: data,
                    itemStyle: {
                        borderColor: '#e4e4e4',
                        borderWidth: 1
                    },
                    emphasis: {
                        itemStyle: {
                            borderColor: '#333',
                            borderWidth: 1
                        }
                    },
                    progressive: 1000,
                    animation: false
                }
            ]
        };
        console.log("option:", option);
        option && myChart.setOption(option);
    }

    render() {
        devEcgSliderNodes = document.getElementsByClassName("dev-ecg-slider");
        appEcgSliderNodes = document.getElementsByClassName("app-ecg-slider");
        ecgSliderTrackNodes = document.getElementsByClassName("ant-slider-track");
        let devWindImgBg;
        let devLest;
        let devNext;
        let appLest;
        let appNext;
        let appWindImgBg;

        if (historyData) {
            switch (historyData.fileType) {
                case EcgFileType.EnumInt.MIXDATA:
                    if (this.props.deviceHistoryData && this.props.deviceHistoryData.length > 0) {
                        deviceHistoryData = this.props.deviceHistoryData ? this.props.deviceHistoryData : "";
                        devHistoryDataNumber = this.props.deviceHistoryData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.deviceHistoryData[0].beginIdx;
                        devHistoryDataIndex = this.props.deviceHistoryData[this.props.deviceHistoryData.length - 1].endIdx >
                        historyData.fileIndex ? this.props.deviceHistoryData[this.props.deviceHistoryData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    if (this.props.appHistoryData && this.props.appHistoryData.length > 0) {
                        appHistoryData = this.props.appHistoryData ? this.props.appHistoryData : "";
                        appHistoryDataNumber = this.props.appHistoryData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.appHistoryData[0].beginIdx;
                        appHistoryDataIndex = this.props.appHistoryData[this.props.appHistoryData.length - 1].endIdx
                        > historyData.fileIndex ? this.props.appHistoryData[this.props.appHistoryData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    devHistoryDataIndex = devHistoryDataIndex > appHistoryDataIndex ? devHistoryDataIndex : appHistoryDataIndex
                    appHistoryDataIndex = appHistoryDataIndex > devHistoryDataIndex ? appHistoryDataIndex : devHistoryDataIndex;
                    break
                case EcgFileType.EnumInt.EVNTIDX:
                    if (this.props.deviceEventData && this.props.deviceEventData.length > 0) {
                        deviceHistoryData = this.props.deviceEventData ? this.props.deviceEventData : "";
                        devHistoryDataNumber = this.props.deviceEventData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.deviceEventData[0].beginIdx;
                        devHistoryDataIndex = this.props.deviceEventData[this.props.deviceEventData.length - 1].endIdx >
                        historyData.fileIndex ? this.props.deviceEventData[this.props.deviceEventData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    if (this.props.appEventData && this.props.appEventData.length > 0) {
                        appHistoryData = this.props.appEventData ? this.props.appEventData : "";
                        appHistoryDataNumber = this.props.appEventData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.appEventData[0].beginIdx;
                        appHistoryDataIndex = this.props.appEventData[this.props.appEventData.length - 1].endIdx
                        > historyData.fileIndex ? this.props.appEventData[this.props.appEventData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    devHistoryDataIndex = devHistoryDataIndex > appHistoryDataIndex ? devHistoryDataIndex : appHistoryDataIndex
                    appHistoryDataIndex = appHistoryDataIndex > devHistoryDataIndex ? appHistoryDataIndex : devHistoryDataIndex;
                    break
                case EcgFileType.EnumInt.TIMEIDX:
                    if (this.props.deviceTimeData && this.props.deviceTimeData.length > 0) {
                        deviceHistoryData = this.props.deviceTimeData ? this.props.deviceTimeData : "";
                        devHistoryDataNumber = this.props.deviceTimeData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.deviceTimeData[0].beginIdx;
                        devHistoryDataIndex = this.props.deviceTimeData[this.props.deviceTimeData.length - 1].endIdx >
                        historyData.fileIndex ? this.props.deviceTimeData[this.props.deviceTimeData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    if (this.props.appTimeData && this.props.appTimeData.length > 0) {
                        appHistoryData = this.props.appTimeData ? this.props.appTimeData : "";
                        appHistoryDataNumber = this.props.appTimeData[0].beginIdx > historyData.fileNumber ?
                            historyData.fileNumber : this.props.appTimeData[0].beginIdx;
                        appHistoryDataIndex = this.props.appTimeData[this.props.appTimeData.length - 1].endIdx
                        > historyData.fileIndex ? this.props.appTimeData[this.props.appTimeData.length - 1].endIdx
                            : historyData.fileIndex;
                    }
                    devHistoryDataIndex = devHistoryDataIndex > appHistoryDataIndex ? devHistoryDataIndex : appHistoryDataIndex
                    appHistoryDataIndex = appHistoryDataIndex > devHistoryDataIndex ? appHistoryDataIndex : devHistoryDataIndex;
                    break
            }
            // if (devHistoryDataIndex > historyData.fileIndex) {
            devMark2[devHistoryDataIndex] = devHistoryDataIndex;
            // }
            // if (appHistoryDataIndex > historyData.fileIndex) {
            appMark2[appHistoryDataIndex] = appHistoryDataIndex;
            // }
        }

        if (deviceHistoryData && historyData && deviceHistoryData.length > 0) {

            if (historyData.fileNumber > 1) {
                devLest = ((historyData.fileNumber / devHistoryDataIndex) * 100).toString() + "%";
            }
            if (devHistoryDataIndex
                > historyData.fileIndex) {
                devNext = (((historyData.fileIndex - 1) / devHistoryDataIndex) * 100).toString() + "%";
            }
            if (devLest && devNext) {
                devWindImgBg = "linear-gradient(90deg,rgba(217,217,217,1) 0%,rgba(217, 217, 217, 1) " + devLest +
                    ",rgba(248,215,3,1) " + devLest + ",rgba(248,215,3,1) " + devNext +
                    ",rgba(217,217,217,1) " + devNext + ",rgba(217, 217, 217, 1) 100%)"
            } else if (devLest) {
                devWindImgBg = "linear-gradient(90deg,rgba(217,217,217,1) 0%,rgba(217, 217, 217, 1) " + devLest +
                    ",rgba(248,215,3,1) " + devLest + ",rgba(248,215,3,1) 100%)"
            } else if (devNext) {
                devWindImgBg = "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) " + devNext +
                    ",rgba(217,217,217,1) " + devNext + ",rgba(217, 217, 217, 1) 100%)"
            }
            this._initSlider(devEcgSliderNodes, ecgSliderTrackNodes,
                deviceHistoryData, devHistoryDataIndex, devNext, this.props.failFilesMapDev, 0, radioValue[0]);
            // this._initSlider(devEcgSliderNodesExh, devEcgSliderTrackNodesExh,
            //     this.props.deviceHistoryData, devHistoryDataIndex, 2);
        } else {
            devHistoryDataNumber = historyData ? historyData.fileNumber : 1;
            devHistoryDataIndex = historyData ? historyData.fileIndex : 100;
        }

        if (appHistoryData && historyData && appHistoryData.length > 0) {

            if (historyData.fileNumber > 1) {
                appLest = ((historyData.fileNumber / appHistoryDataIndex) * 100).toString() + "%";
                // appLest = "50%"
            }
            if (appHistoryDataIndex
                > historyData.fileIndex) {
                appNext = (((historyData.fileIndex - 1) / appHistoryDataIndex) * 100).toString() + "%";
            }
            if (appLest && appNext) {
                appWindImgBg = "linear-gradient(90deg,rgba(217,217,217,1) 0%,rgba(217, 217, 217, 1) " + appLest +
                    ",rgba(248,215,3,1) " + appLest + ",rgba(248,215,3,1) " + appNext +
                    ",rgba(217,217,217,1) " + appNext + ",rgba(217, 217, 217, 1) 100%)"
            } else if (appLest) {
                appWindImgBg = "linear-gradient(90deg,rgba(217,217,217,1) 0%,rgba(217, 217, 217, 1) " + appLest +
                    ",rgba(248,215,3,1) " + appLest + ",rgba(248,215,3,1) 100%)"
            } else if (appNext) {
                appWindImgBg = "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) " + appNext +
                    ",rgba(217,217,217,1) " + appNext + ",rgba(217, 217, 217, 1) 100%)"
            }
            this._initSlider(appEcgSliderNodes, ecgSliderTrackNodes
                , appHistoryData, appHistoryDataIndex, appNext, this.props.failFilesMapApp, 1, radioValue[1]);
            // this._initSlider(appEcgSliderNodesExh, appEcgSliderTrackNodesExh
            //     , this.props.appHistoryData, appHistoryDataIndex, 2);
        } else {
            appHistoryDataNumber = historyData ? historyData.fileNumber : 1;
            appHistoryDataIndex = historyData ? historyData.fileIndex : 100;
        }

        for (let i = 0; i < this.props.failFilesMapDev.length; i++) {
            failFilesMapDev[this.props.failFilesMapDev[i].index] = this.props.failFilesMapDev[i].times
        }

        for (let i = 0; i < this.props.failFilesMapApp.length; i++) {
            failFilesMapApp[this.props.failFilesMapApp[i].index] = this.props.failFilesMapApp[i].times
        }
        console.log("this.props.failFilesMapApp:", this.props.failFilesMapApp)
        return (

            <div>
                {this.props.isShow ? <div style={{paddingLeft: "20px"}}>
                    <EnumItemSelect
                        style={{width: '100px', marginRight: '10px'}} allowClear={false}
                        data={EcgFileType.ListValue} value={fileType}
                        onChange={(value) => {
                            this._handleChangeFile(value)
                        }}/>
                    <p align="right" style={{paddingRight: "10px"}}><Button onClick={(value) => {
                        this._r5()
                    }}>刷新</Button></p>
                    来自设备的文件上传情况:
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            已读率：
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            读取异常文件数：
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Radio.Group value={radioValue[0]}>
                                <Radio value={1} onChange={(value) => {
                                    this._handleView(value, 0)
                                }}>有效范围：</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Popover content={
                                this._renderHistory(deviceHistoryData, 2)
                            } title="已读文件编号">
                                <Button type="link" style={{color: "red"}}
                                        size="small">{this._appHistoryDataPercentage(deviceHistoryData, 2, 0)}%</Button>

                            </Popover>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', width: '200px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Button type="link" style={{color: "red"}}
                                    size="small">{this._renderfailFilesMap(this.props.failFilesMapDev)}</Button>
                            {/*(超UB件数 <Button type="link" style={{color: "red"}}*/}
                            {/*               size="small">{recordIndex[0]}</Button>)*/}
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Radio.Group value={radioValue[0]}>
                                <Radio value={0} onChange={(value) => {
                                    this._handleView(value, 0)
                                }}>全范围：</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Popover content={
                                this._renderHistory(deviceHistoryData, 1)
                            } title="已读文件编号">
                                <Button type="link" style={{color: "red"}}
                                        size="small">{this._appHistoryDataPercentage(deviceHistoryData, 1)}%</Button>
                            </Popover>

                        </Col>
                        <Col span={3} style={{
                            height: '20px', width: '200px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Button type="link" style={{color: "red"}}
                                    size="small">{this.props.failFilesMapDev.length}</Button>
                            (超UB件数 <Button type="link" style={{color: "red"}}
                                           size="small">{recordIndex[0]}</Button>)
                        </Col>
                    </Row>
                    <div style={{paddingLeft: "10px"}}>
                        <Slider
                            style={{width: "94%", height: "50px"}}
                            className="dev-ecg-slider"
                            min={radioValue[0] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[0] == 0 ? devHistoryDataIndex : historyData ? historyData.fileIndex : devHistoryDataIndex}
                            // marks={this.state.devMarks}
                            onChange={(value) => {
                                this._initHostCharts(value, failFilesMapDev, deviceHistoryData)
                            }}
                            railStyle={{
                                backgroundImage: radioValue[0] == 0 ? (devWindImgBg ? devWindImgBg
                                        : "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) 100%)")
                                    : "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) 100%)",
                                height: '52px',
                                borderRadius: '6px',
                            }}
                            // handleStyle={{
                            //     height: "68px",
                            //     width: "58px"
                            // }}
                            //     disabled={true}
                        />
                        <Slider
                            style={{width: "94%", height: "5px"}}
                            className="dev-ecg-slider-exhibition"
                            min={radioValue[0] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[0] == 0 ? devHistoryDataIndex : historyData ? historyData.fileIndex : devHistoryDataIndex}
                            marks={devMark}
                            disabled={true}
                            railStyle={{
                                backgroundImage: "linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%)",
                            }}
                        />
                        <Slider
                            style={{width: "94%", height: "5px"}}
                            className="dev-ecg-slider-exhibition"
                            min={radioValue[0] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[0] == 0 ? devHistoryDataIndex : historyData ? historyData.fileIndex : 1}
                            marks={devMark2}
                            disabled={true}
                            railStyle={{
                                backgroundImage: "linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%)",
                            }}
                        />
                    </div>
                    来自APP的文件上传情况:
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            已读率：
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            读取异常文件数：
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Radio.Group value={radioValue[1]}>
                                <Radio value={1} onChange={(value) => {
                                    this._handleView(value, 1)
                                }}>有效范围：</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Popover content={
                                this._renderHistory(appHistoryData, 2)
                            } title="已读文件编号">
                                <Button type="link" style={{color: "red"}}
                                        size="small">{this._appHistoryDataPercentage(appHistoryData, 2, 1)}%</Button>
                            </Popover>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', width: '200px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Button type="link" style={{color: "red"}}
                                    size="small">{this._renderfailFilesMap(this.props.failFilesMapApp)}</Button>
                        </Col>
                    </Row>
                    <Row style={{backgroundColor: "#FFF"}}>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Radio.Group value={radioValue[1]}>
                                <Radio value={0} onChange={(value) => {
                                    this._handleView(value, 1)
                                }}>全范围：</Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Popover content={
                                this._renderHistory(appHistoryData, 1)
                            } title="已读文件编号">
                                <Button type="link" style={{color: "red"}}
                                        size="small">{this._appHistoryDataPercentage(appHistoryData, 1)}%</Button>
                            </Popover>
                        </Col>
                        <Col span={3} style={{
                            height: '20px', width: '200px', backgroundColor: "#FFF", paddingLeft: "5px"
                        }}>
                            <Button type="link" style={{color: "red"}}
                                    size="small">{this.props.failFilesMapApp.length}</Button>
                            (超UB件数 <Button type="link" style={{color: "red"}}
                                           size="small">{recordIndex[1]}</Button>)
                        </Col>
                    </Row>
                    <div style={{paddingLeft: "10px"}}>
                        <Slider
                            style={{width: "94%", height: "50px"}}
                            className="app-ecg-slider"
                            min={radioValue[1] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[1] == 0 ? appHistoryDataIndex
                                : historyData ? historyData.fileIndex : appHistoryDataIndex}
                            // marks={this.state.appMarks}
                            onChange={(value) => {
                                this._initHostCharts(value, failFilesMapApp, appHistoryData)
                            }}
                            railStyle={{
                                backgroundImage: radioValue[1] == 0 ? (appWindImgBg ? appWindImgBg
                                        : "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) 100%)")
                                    : "linear-gradient(90deg,rgba(248,215,3,1) 0%,rgba(248,215,3,1) 100%)",
                                height: '52px',
                                borderRadius: '6px',
                            }}/>
                        <Slider
                            style={{width: "94%", height: "5px"}}
                            className="app-ecg-slider-exhibition"
                            min={radioValue[1] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[1] == 0 ? appHistoryDataIndex : historyData ? historyData.fileIndex : appHistoryDataIndex}
                            marks={appMark}
                            disabled={true}
                            railStyle={{
                                backgroundImage: "linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%)",
                            }}
                        />
                        <Slider
                            style={{width: "94%", height: "5px"}}
                            className="app-ecg-slider-exhibition"
                            min={radioValue[1] == 0 ? 1 : historyData ? historyData.fileNumber : 1}
                            max={radioValue[1] == 0 ? appHistoryDataIndex : historyData ? historyData.fileIndex : appHistoryDataIndex}
                            marks={appMark2}
                            disabled={true}
                            railStyle={{
                                backgroundImage: "linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%)",
                            }}
                        />
                        <Row>
                            <Col span={2} style={{
                                width: '40%', height: '100%', backgroundColor: "#FFF", paddingLeft: "5px"
                            }}>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        UB:
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        UpperBound
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        LB:
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        LowerBound
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#c6156a",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        超过UB的文件
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "rgb(1,131,221)",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        已上传到服务器的文件
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "rgba(217, 217, 217, 1)",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        已被设备删除，再也无法读取的文件
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#f8d703",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        还在设备中，未上传到服务器的文件
                                    </Col>
                                </Row>
                                <Row style={{paddingTop: "50px"}}>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#46c7e4",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        读取失败，将会重读的文件
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#1feef9",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        读取失败2-4次的文件
                                    </Col>
                                </Row>
                                <Row style={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: "#FFF",
                                    paddingTop: "10px"
                                }}>
                                    <Col span={2} style={{
                                        width: '30px', height: '20px', backgroundColor: "#f798b7",
                                    }}>
                                    </Col>
                                    <Col span={2} style={{
                                        height: '20px', width: '50%', backgroundColor: "#FFF", paddingLeft: "5px"
                                    }}>
                                        读取失败5次以上的文件
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <div id="hostMain" style={{height: '400px', width: '1150px'}}></div>
                            </Col>
                        </Row>

                        {/*<div>*/}
                        {/*    <p>1</p>*/}
                        {/*    <p align="right"*/}
                        {/*       style={{paddingRight: "10px"}}>{appHistoryDataIndex}</p>*/}
                        {/*</div>*/}
                    </div>
                </div> : <div/>}
            </div>
        )
    }

    _handleView(value, index) {

        radioValue[index] = value.target.value
        this._r5()
        this.setState({r5: true})
    }


    _handleChangeFile(value) {

        fileType = value
        this._r5();
    }

    _renderfailFilesMap(failFilesMap) {
        let length = 0;
        if (failFilesMap && historyData) {
            for (let i = 0; i < failFilesMap.length; i++) {
                if (failFilesMap[i].index <= historyData.fileIndex && failFilesMap[i].index >= historyData.fileNumber) {
                    length++;
                }
            }
        }
        return length;
    }

    _appHistoryDataPercentage(HistoryData, model, index) {

        var str = 0;
        var value = 0;
        if (model == 1) {
            if (HistoryData && HistoryData.length > 0) {
                for (var i = 0; i < HistoryData.length; i++) {
                    str += HistoryData[i].endIdx - HistoryData[i].beginIdx + 1;
                }
                str = (str + 1) / historyData.fileIndex;
                str = (str * 100).toFixed(2);
            }
            //parseInt((device.readFileIndex / device.writeFileIndex) * 100)
            //    \ ? ((device.readFileIndex / device.writeFileIndex) * 100) : 0
            return str ? str : 0
        } else {
            if (HistoryData && HistoryData.length > 0) {
                for (var i = 0; i < HistoryData.length; i++) {
                    value = HistoryData[i].endIdx - HistoryData[i].beginIdx
                    if (HistoryData[i].beginIdx >= historyData.fileNumber && HistoryData[i].beginIdx <= historyData.fileIndex) {
                        if (historyData.fileIndex >= HistoryData[i].endIdx) {
                            str += HistoryData[i].endIdx - HistoryData[i].beginIdx;
                        } else {
                            str += historyData.fileIndex - HistoryData[i].beginIdx;
                        }
                    } else if (HistoryData[i].beginIdx + value >= historyData.fileNumber && HistoryData[i].beginIdx + value <= historyData.fileIndex) {
                        if (historyData.fileIndex >= HistoryData[i].endIdx) {
                            str += HistoryData[i].endIdx - historyData.fileNumber;
                        } else {
                            str += HistoryData[i].endIdx - historyData.fileNumber - HistoryData[i].endIdx + historyData.fileIndex;
                        }
                    }

                }
                if (historyData.fileIndex != historyData.fileNumber) {
                    str = (str + 1) / (historyData.fileIndex - historyData.fileNumber + 1);
                } else {
                    str = (str + 1) / historyData.fileIndex;
                }

                str = (str * 100).toFixed(2);
            }
            //parseInt((device.readFileIndex / device.writeFileIndex) * 100)
            //    \ ? ((device.readFileIndex / device.writeFileIndex) * 100) : 0
            return str ? str : 0
        }

    }

    _renderHistory(HistoryData, model) {
        let text = " ";
        var value = 0;
        this.testRef = React.createRef();
        if (HistoryData && HistoryData.length > 0) {
            for (var i = 0; i < HistoryData.length; i++) {
                value = HistoryData[i].endIdx - HistoryData[i].beginIdx
                if (model == 1) {
                    text += HistoryData[i].beginIdx + "--" + HistoryData[i].endIdx + "\n "
                } else {
                    if (historyData.fileNumber <= HistoryData[i].beginIdx && HistoryData[i].beginIdx <= historyData.fileIndex) {
                        if (historyData.fileIndex >= HistoryData[i].endIdx) {
                            text += HistoryData[i].beginIdx + "--" + HistoryData[i].endIdx + "\n "
                        } else {
                            text += HistoryData[i].beginIdx + "--" + (historyData.fileIndex - HistoryData[i].beginIdx + 1) + "\n "
                        }
                    } else if (HistoryData[i].beginIdx + value >= historyData.fileNumber && HistoryData[i].beginIdx + value <= historyData.fileIndex) {
                        if (historyData.fileIndex >= HistoryData[i].endIdx) {
                            // str += appHistoryData[i].endIdx - historyData.fileNumber;
                            text += historyData.fileNumber + "--" + HistoryData[i].endIdx + "\n "
                        } else {
                            // str += appHistoryData[i].endIdx - historyData.fileNumber - appHistoryData[i].endIdx + historyData.fileIndex;
                            text += historyData.fileNumber + "--" + historyData.fileIndex + "\n "
                        }
                    }
                }

            }
        }
        if (text == " ") {
            text = "服务器上未有数据"
        }
        return (
            <div style={{whiteSpace: 'pre-wrap', overflow: "auto", height: "200px"}} ref={this.testRef}>
                {text}
            </div>
        )
    }

    _initSlider(EcgSliderNodes, ecgSliderTrackNodes, dataSource, max, next, model, index, allModel) {

        recordIndex[index] = 0;
        var value = allModel == 0 ? 0 : historyData.fileNumber;
        max = allModel == 0 ? max : historyData.fileIndex - historyData.fileNumber;
        // var minValue = allModel == 0 ? 1 : historyData.fileNumber;
        if (EcgSliderNodes && EcgSliderNodes.length > 0) {

            if (dataSource && historyData) {

                let isFirst = true;
                let initValue = 0;
                let isOne = true;
                if (allModel == 1) {
                    for (; initValue < dataSource.length - 1 && dataSource[initValue].endIdx < historyData.fileNumber; initValue++) {
                        console.log("dataSource:", dataSource, initValue, historyData.fileNumber)
                    }
                }
                console.log("dataSource:", initValue)
                for (let i = initValue; i < dataSource.length; i++) {
                    const ecgSliderTrackNode = document.createElement("div");
                    ecgSliderTrackNode.className = "ant-slider-track";
                    ecgSliderTrackNode.style.height = "52px"
                    if (dataSource[i].beginIdx < historyData.fileNumber && isOne && allModel == 1) {
                        isOne = false
                        ecgSliderTrackNode.style.left = "0%";
                        ecgSliderTrackNode.style.width = ((((dataSource[i].endIdx - historyData.fileNumber)) / max) * 100).toString() + "%";
                        console.log(" ecgSliderTrackNode.style.width :", ecgSliderTrackNode.style.width, ecgSliderTrackNode.style.left)
                    } else {
                        ecgSliderTrackNode.style.left = (((dataSource[i].beginIdx - value) / max) * 100).toString() + "%";
                        // ecgSliderTrackNode.style.left = (((dataSource[i].beginIdx ) / max) * 100).toString() + "%";
                        ecgSliderTrackNode.style.width = ((((dataSource[i].endIdx - dataSource[i].beginIdx)) / max) * 100).toString() + "%";
                    }
                    if (allModel == 1 && dataSource[i].endIdx >= historyData.fileIndex && dataSource[i].beginIdx >= historyData.fileNumber) {
                        ecgSliderTrackNode.style.width = ((((historyData.fileIndex - dataSource[i].beginIdx)) / max) * 100).toString() + "%";
                    } else if (allModel == 1 && dataSource[i].endIdx >= historyData.fileIndex && dataSource[i].beginIdx <= historyData.fileNumber) {
                        ecgSliderTrackNode.style.width = ((((historyData.fileIndex - historyData.fileNumber)) / max) * 100).toString() + "%";

                    }
                    if (ecgSliderTrackNode.style.left <= "0%") {
                        ecgSliderTrackNode.style.left = "0%"
                    }
                    // }
                    if (ecgSliderTrackNode.style.width <= "0.1%" && ecgSliderTrackNode.style.width > "0%") {
                        ecgSliderTrackNode.style.width = "0.1%"
                    }
                    let leftText = Number(ecgSliderTrackNode.style.left.replace("%", ""));
                    let widthText = Number(ecgSliderTrackNode.style.width.replace("%", ""));
                    let nextText
                    if (next) {
                        nextText = Number(next.replace("%", ""));
                    }

                    let width;
                    let piancha = 0;
                    ecgSliderTrackNode.style.backgroundColor = "rgb(1,131,221)";//"rgb(1,131,221)"
                    if (next && leftText + widthText > nextText && allModel == 0) {
                        ecgSliderTrackNode.style.backgroundColor = "#c6156a";
                        if (isFirst && nextText > leftText) {
                            isFirst = false;
                            ecgSliderTrackNode.style.width = (nextText - leftText).toString() + "%"
                            ecgSliderTrackNode.style.backgroundColor = "rgb(1,131,221)";
                            width = (nextText - leftText) / 100
                            const ecgSliderTrackNode1 = document.createElement("div");
                            ecgSliderTrackNode1.className = "ant-slider-track";
                            ecgSliderTrackNode1.style.height = "52px"
                            ecgSliderTrackNode1.style.left = (((dataSource[i].beginIdx - 1 - width) / max) * 100)
                                .toString() + "%";
                            if (ecgSliderTrackNode1.style.left < "0%") {
                                piancha = Number(ecgSliderTrackNode1.style.left.replace("%", ""))
                                ecgSliderTrackNode1.style.left = "0%"
                            }
                            ecgSliderTrackNode1.style.right = "auto";
                            ecgSliderTrackNode1.style.backgroundColor = "#c6156a";
                            ecgSliderTrackNode1.style.width = (((dataSource[i].endIdx + 1 - dataSource[i].beginIdx - width)
                                / max) * 100).toString() + "%";
                            ecgSliderTrackNode1.style.width = Number(ecgSliderTrackNode1.style.width.replace("%", "")) - piancha + "%"
                            EcgSliderNodes[0].appendChild(ecgSliderTrackNode1);
                        }

                    }
                    EcgSliderNodes[0].appendChild(ecgSliderTrackNode);
                }

                if (model) {
                    for (let i = 0; i < model.length; i++) {
                        if (model[i].index <= historyData.fileIndex) {
                            if (allModel == 0) {
                                const ecgSliderTrackNode = document.createElement("div");
                                ecgSliderTrackNode.className = "ant-slider-track";
                                ecgSliderTrackNode.style.height = "26px"
                                ecgSliderTrackNode.style.left = ((((model[i].index) - value) / max) * 100).toString() + "%";
                                if (ecgSliderTrackNode.style.left <= "0%") {
                                    ecgSliderTrackNode.style.left = "0%"
                                }
                                // ecgSliderTrackNode.style.right = "auto";
                                // ecgSliderTrackNode.style.width = (((model[i].endIdx - dataSource[i].beginIdx) / max) * 100).toString() + "%";
                                // if (ecgSliderTrackNode.style.width <= "0.1%") {
                                ecgSliderTrackNode.style.width = "0.1%"
                                // }
                                ecgSliderTrackNode.style.backgroundColor = "#46c7e4";
                                EcgSliderNodes[0].appendChild(ecgSliderTrackNode);
                            } else if (allModel == 1 && model[i].index >= historyData.fileNumber) {
                                const ecgSliderTrackNode = document.createElement("div");
                                ecgSliderTrackNode.className = "ant-slider-track";
                                ecgSliderTrackNode.style.height = "26px"
                                ecgSliderTrackNode.style.left = ((((model[i].index) - value) / max) * 100).toString() + "%";
                                if (ecgSliderTrackNode.style.left <= "0%") {
                                    ecgSliderTrackNode.style.left = "0%"
                                }
                                // ecgSliderTrackNode.style.right = "auto";
                                // ecgSliderTrackNode.style.width = (((model[i].endIdx - dataSource[i].beginIdx) / max) * 100).toString() + "%";
                                // if (ecgSliderTrackNode.style.width <= "0.1%") {
                                ecgSliderTrackNode.style.width = "0.1%"
                                // }
                                ecgSliderTrackNode.style.backgroundColor = "#46c7e4";
                                EcgSliderNodes[0].appendChild(ecgSliderTrackNode);
                            }

                        } else {
                            recordIndex[index]++;
                        }


                    }
                }
            }
        }

    }

}

function mapStateToProps(store) {
    return {
        topQ: store.EcgDeviceReducer.topQ,
        channelType: store.EcgDeviceReducer.channelType,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        deviceCode: store.EcgDeviceReducer.deviceCode,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        deviceEventData: store.EcgDeviceReducer.deviceEventData,
        deviceTimeData: store.EcgDeviceReducer.deviceTimeData,
        appEventData: store.EcgDeviceReducer.appEventData,
        appTimeData: store.EcgDeviceReducer.appTimeData,
        version: store.EcgDeviceReducer.version,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        mapVersion: store.EcgDeviceReducer.mapVersion,
        failFilesMapApp: store.EcgDeviceReducer.failFilesMapApp,
        failFilesMapDev: store.EcgDeviceReducer.failFilesMapDev,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));