class EcgFileDate {
    constructor(dataArray, startPos) {
        let index = startPos;
        const yearArray = dataArray.slice(index, index + 2);
        this.year = new Uint16Array(yearArray.buffer.slice(-2))[0];
        index = index + 2;

        this.month = dataArray[index];
        if (this.month < 10) {
            this.month = "0" + this.month.toString();
        }
        index++;

        this.day = dataArray[index];
        if (this.day < 10) {
            this.day = "0" + this.day.toString();
        }
        index++;

        this.length = index - startPos;
    }

    getDate() {
        return this.year.toString() + this.month.toString() + this.day.toString();
    }
}

export default EcgFileDate;