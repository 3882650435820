import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {getApplicationByCode} from "../../DAS/actions/EcgApplicationAction";
import {APP_VERSION} from "../../../constants/Profile";

class CheckUpdateVersion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        this.intervalCheckUpdateVersion = setInterval(() => {
            this.props.dispatch(getApplicationByCode("ECG_WEB", this.state.formatMessage, (application) => {
                const fileVersion = application.fileVersion.substr(0, application.fileVersion.indexOf("-"));
                const fileVersionSplits = fileVersion.split(".");

                const localVersion = APP_VERSION.substr(0, APP_VERSION.indexOf("-"));
                const localVersionSplits = localVersion.split(".");

                if (fileVersionSplits[0] > localVersionSplits[0] || fileVersionSplits[1] > localVersionSplits[1] || fileVersionSplits[2] > localVersionSplits[2]) {
                    window.location.reload();
                }
            }));
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalCheckUpdateVersion);
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(CheckUpdateVersion));