import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Pagination, Divider} from 'antd';

// import htmlToDraft from 'html-to-draftjs';
//
// import UpdateModal from "./UpdateModal";
// import AttachmentModal from "./AttachmentModal";

import {searchContents, getContent, deleteContent} from '../../../actions/ContentAction';
import {RoutePath} from "../../../../../constants/RoutePath";
import {Modal} from "antd/lib/index";

import PaginationBar from "../../../../../components/PaginationBar";
import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../../../constants/Profile";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'CMS_CONTENT_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'CMS_CONTENT_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'CMS_CONTENT_FIELD_CHANNEL'}),
                    key: 'channel',
                    dataIndex: 'channel.title',
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    // _renderChannels(text, record, index) {
    //     let channelsText = "";
    //     if (record.channels !== null && record.channels.length > 0) {
    //         for (let i = 0; i < record.channels.length; i++) {
    //             if (i > 0) {
    //                 channelsText = "," + record.channels[i].title;
    //             } else {
    //                 channelsText = record.channels[i].title;
    //             }
    //         }
    //     }
    //     return (
    //         <span>{channelsText}</span>
    //     )
    // }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleEnclosure.bind(this, record)}>附件列表</a>
                <Divider type="vertical"/>
                <a onClick={this._handleModify.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>删除</a>
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handleShowAttachmentModal.bind(this, record)}>附件</a>*/}
            </div>
        )
    }

    _handleDelete(record) {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const {searchCondition, dispatch} = this.props;
        const state = this.state;
        Modal.confirm({
            title: this.state.formatMessage({id: "CMS_CONTENT_DELETE_MODAL_TITLE"}),
            content: this.state.formatMessage({id: "CMS_CONTENT_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteContent(siteCode, record, searchCondition, state));
            }
        })
    }

    _handleModify(record) {
        this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", record.id) + "?key=1");
    }

    _handleEnclosure(record) {
        console.log("this:", this)
        this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", record.id) + "?key=2");
    }

    render() {
        // this.props.contents.sort();
        this.props.contents.sort((a, b) => a.title.localeCompare(b.title))
        return (
            <div className="spacing-v page-width">

                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, 20)}/>

                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.contents} pagination={false}/>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, 20)}/>
            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;

        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        contents: store.ContentReducer.contents,
        searchCondition: store.ContentReducer.searchCondition,
        pagination: store.ContentReducer.pagination,
        channelId: store.ChannelReducer.selectTreeKey
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));