import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

export default class NavigationBar extends Component {

    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidUpdate() {

    }

    _handleLeftIconClick() {
        this.props.leftIconClick && this.props.leftIconClick.call(this);
    }

    _handleRightIconClick() {
        this.props.rightIconClick && this.props.rightIconClick.call(this);
    }

    render() {
        return (
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit"
                                onClick={() => this._handleLeftIconClick()}>
                        {this.props.leftIcon}
                    </IconButton>
                    <Typography variant="h6" style={{flexGrow: 1, textAlign: 'center'}}>
                        {this.props.title}
                    </Typography>
                    <IconButton edge="end" color="inherit"
                                onClick={() => this._handleRightIconClick()}>
                        {this.props.rightIcon}
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    }
}