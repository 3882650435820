import HuffmanDecodeNode from "./HuffmanDecodeNode";

export default class HuffmanDecode {

    constructor() {
        this.dictId = 0;
        this.numSymbol = 0;
        this.codeLen = 0;
        this.root = null;
    }

    initByLoadDictFile() {
        const self = this;
        fetch('/dict1.dict')
            .then(response => response.arrayBuffer())
            .then(data => {
                const dataArray = new Int8Array(data);
                // console.log("输入读取的字典数据流，核对一致");
                // console.log(dataArray);

                self.initFromDictData(dataArray);
            });
    }

    initFromDictData(dataArray) {
        // 初始化数据视图，操作字节流
        const bb = new DataView(dataArray.buffer);
        this.dictId = bb.getInt16(0, true);
        this.numSymbol = bb.getInt16(2, true);
        // bb.getInt16(4, true);
        this.codeLen = bb.getInt16(6, true);
        // console.log("解析dataArray.buffer.getInt16(0, true)得到的字典Id，核对一致");
        // console.log(this.dictId);
        // console.log("解析dataArray.buffer.getInt16(2, true)得到的特征值数量，核对一致");
        // console.log(this.numSymbol);
        // console.log("解析dataArray.buffer.getInt16(6, true)得到的code长度，核对一致");
        // console.log(this.codeLen);

        // 解析成一个数据字典项列表
        const dictItems = new Array(this.numSymbol);
        let offset = 8;
        for (let i = 0; i < this.numSymbol; i++) {
            dictItems[i] = {};
            dictItems[i].offset = bb.getInt16(offset, true);
            offset = offset + 2;
            dictItems[i].bitLen = bb.getInt8(offset);
            offset = offset + 1;
        }
        // console.log("解析后得到的数据字典项目列表，核对一致");
        // console.log(dictItems);

        // 填充codes字节数组
        const codes = [];
        for (let i = 0; i < this.codeLen; i++) {
            codes[i] = bb.getInt8(offset + i);
        }
        // console.log("解析后得到的字节，核对一致");
        // console.log(codes);

        // 构建 huffman tree
        this.root = new HuffmanDecodeNode(-1);
        for (let i = 0; i < this.numSymbol; i++) {
            const offset = dictItems[i].offset;
            let bitLen = dictItems[i].bitLen;
            let treeWalker = this.root;
            let j = 0;
            while (bitLen > 0) {
                let remainder = 8;
                if (bitLen < 8) {
                    remainder = bitLen;
                }
                const t = codes[offset + j];
                for (let k = 0; k < remainder; k++) {
                    if (((0x0080 >> k) & t) === 0) {
                        if (treeWalker.left == null) {
                            treeWalker.left = new HuffmanDecodeNode(0);
                        }
                        treeWalker = treeWalker.left;
                    } else {
                        if (treeWalker.right == null)
                            treeWalker.right = new HuffmanDecodeNode(1);
                        treeWalker = treeWalker.right;
                    }
                }
                j++;
                bitLen -= 8;
            }
            treeWalker.isLeaf = true;
            treeWalker.symbol = i;
        }
        // console.log("初始化完成的Huffman结构，核对一致");
        // console.log(this);
    }

    decodeDiff(bitstr, offset, nbits) {
        if (this.dictId === 1) {
            return this.decodeDiff_ecg(bitstr, offset, nbits);
        }
        if (this.dictId === 2) {
            return this.decodeDiff_mpu6050(bitstr, offset, nbits);
        }
    }

    decodeDiff_ecg(bitstr, offset, nbits) {

        // console.log("压缩的数据字节流，核对一致");
        // console.log(bitstr);
        //
        // console.log("偏移量，核对一致");
        // console.log(offset);
        //
        // console.log("字节长度，核对一致");
        // console.log(nbits);

        // 计算字节长度
        let nBytes = (nbits + 7) / 8;
        nBytes = Math.floor(nBytes);
        // console.log("计算字节长度，核对一致");
        // console.log(nBytes);

        if (nBytes + offset > bitstr.length) {
            console.log("ERROR:bitstr of insufficient length")
            return;
        }

        // 初始化解压缩逻辑的数据
        let intArray = [];
        let bb = new DataView(new ArrayBuffer(4));
        let bbPosition = 0;
        let bitLen = 8;
        let remainBits = nbits % 8;
        nBytes = offset + nBytes;
        let treeWalker = this.root;
        // console.log("初始化的DataView，核对一致");
        // console.log(bb);
        // console.log("初始化的bitLen，核对一致");
        // console.log(bitLen);
        // console.log("初始化的remainBits，核对一致");
        // console.log(remainBits);
        // console.log("初始化的nBytes，核对一致");
        // console.log(nBytes);
        // console.log("初始化的treeWalker，核对一致");
        // console.log(treeWalker);

        for (let i = offset; i < nBytes; i++) {
            let t = bitstr[i];
            if (i + 1 === nBytes) {
                if (remainBits > 0) {
                    bitLen = remainBits;
                }
            }
            for (let k = 0; k < bitLen; k++) {
                if (((0x0080 >> k) & t) === 0) {
                    treeWalker = treeWalker.left;
                } else {
                    treeWalker = treeWalker.right;
                }

                if (treeWalker.isLeaf) {
                    if (bbPosition < 2) {
                        bb.setInt8(bbPosition, treeWalker.symbol);
                        bbPosition = bbPosition + 1;
                    } else {
                        if (treeWalker.symbol < 256) {
                            bb.setInt8(bbPosition, treeWalker.symbol);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0x0);
                            bbPosition = bbPosition + 1;
                        } else {
                            bb.setInt8(bbPosition, treeWalker.symbol & 0xFF);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0xFF);
                            bbPosition = bbPosition + 1;
                        }
                        bbPosition = 0;
                        intArray.push(bb.getInt32(bbPosition, true));
                        bbPosition = 0;
                    }
                    treeWalker = this.root;
                }
            }
        }
        // console.log("解压缩得到的intArray数据");
        // console.log(intArray);

        // intArray = [/* array elements */];

        // 解压以后会多出4个数据，Java端也有同样问题，还未找到原因
        // const intArrayLength = intArray.length - 4;

        const out = new ArrayBuffer(intArray.length * 4);
        const view = new DataView(out);
        let temp = intArray[0];
        view.setInt32(0, temp, true);

        for (let i = 1; i < intArray.length; i++) {
            temp = intArray[i] + temp;
            view.setInt32(i * 4, temp, true);
        }

        // console.log("解压缩完成的数据");
        // console.log(out);
        return out;
    }

    decodeDiff_mpu6050(bitstr, offset, nbits) {
        // 计算字节长度
        let nBytes = (nbits + 7) / 8;
        nBytes = Math.floor(nBytes);

        if (nBytes + offset > bitstr.length) {
            console.log("ERROR:bitstr of insufficient length")
            return;
        }

        // 初始化解压缩逻辑的数据
        let intArray = [];
        let bb = new DataView(new ArrayBuffer(4));
        let bbPosition = 0;
        let bitLen = 8;
        let remainBits = nbits % 8;
        nBytes = offset + nBytes;
        let treeWalker = this.root;

        for (let i = offset; i < nBytes; i++) {
            let t = bitstr[i];
            if (i + 1 === nBytes) {
                if (remainBits > 0) {
                    bitLen = remainBits;
                }
            }
            for (let k = 0; k < bitLen; k++) {
                if (((0x0080 >> k) & t) === 0) {
                    treeWalker = treeWalker.left;
                } else {
                    treeWalker = treeWalker.right;
                }
                if (treeWalker.isLeaf) {
                    if (bbPosition < 1) {
                        bb.setInt8(bbPosition, treeWalker.symbol);
                        bbPosition = bbPosition + 1;
                    } else {
                        if (treeWalker.symbol < 256) {
                            bb.setInt8(bbPosition, treeWalker.symbol);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0x0);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0x0);
                        } else {
                            bb.setInt8(bbPosition, treeWalker.symbol & 0xFF);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0xFF);
                            bbPosition = bbPosition + 1;
                            bb.setInt8(bbPosition, 0xFF);
                        }
                        bbPosition = 0;
                        intArray.push(bb.getInt32(bbPosition, true));
                        bbPosition = 0;
                    }
                    treeWalker = this.root;
                }
            }
        }

        const out = new ArrayBuffer(intArray.length * 4);
        const view = new DataView(out);
        let temp = intArray[0];
        view.setInt32(0, temp, true);
        for (let i = 1; i < intArray.length; i++) {
            temp = intArray[i] + temp;
            view.setInt32(i * 4, temp, true);
        }

        return out;
    }
};