import EcgFileDate from "./EcgFileDate";

/**
 * 名称	字节数	范围	说明
 * Date	4B	0-232-1	索引日期
 * EventFileIndex	4B	0-232-1	事件索引文件编号，0表示没发生事件。
 * EventNum	4B	0-232-1	事件索引文件中的索引条数
 * TimeFileIndex	4B	0-232-1	时间索引文件编号，0表示没采集数据。
 * TimeNum	4B	0-232-1	时间索引文件中的有效（非空）索引条数
 */

class EcgFileDateIndexRecord {
    constructor(dataArray, startPos) {
        let index = startPos;

        // 索引日期
        const ecgFileDate = new EcgFileDate(dataArray, index);
        this.recordDate = ecgFileDate.getDate();
        index = index + ecgFileDate.length;

        // 事件索引文件编号，0表示没发生事件
        const eventFileIndexArray = dataArray.slice(index, index + 4);
        this.eventFileIndex = new Int32Array(eventFileIndexArray.buffer.slice(-4))[0];
        index += 4;

        // 事件索引文件中的索引条数
        const eventNumArray = dataArray.slice(index, index + 4);
        this.eventNum = new Int32Array(eventNumArray.buffer.slice(-4))[0];
        index += 4;

        // 时间索引文件编号，0表示没采集数据
        const timeFileIndexArray = dataArray.slice(index, index + 4);
        this.timeFileIndex = new Int32Array(timeFileIndexArray.buffer.slice(-4))[0];
        index += 4;

        // 时间索引文件中的有效（非空）索引条数
        const timeNumArray = dataArray.slice(index, index + 4);
        this.timeNum = new Int32Array(timeNumArray.buffer.slice(-4))[0];
        index += 4;

        this.length = index - startPos;
    }
}

export default EcgFileDateIndexRecord;