/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';

import {
    Descriptions,
} from "antd";

import {ChargeState, SysState} from "../../../Enums";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class DeviceDetailSystem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }


    render() {
        const {device} = this.props;

        const formatMessage = this.state.formatMessage;

        return (
            <Descriptions column={1}>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_TIME_DIFF"})}>
                    {
                        (device.timeDiff < -10 || device.timeDiff > 10) ?
                            <strong className="text-danger">{device.timeDiff}</strong> :
                            <span>{device.timeDiff}</span>
                    }
                    秒
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_SAMPLE_RATE"})}>
                    {device.sampleRate}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_SAMPLE_RESOLUTION"})}>
                    {device.sampleResolution}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_BATT_VOLUME"})}>
                    {device.battVolume}%
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_CHARGE_STATE"})}>
                    <EnumItemLabel map={ChargeState.Map} code={device.chargeState}/>
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_SYS_STATE"})}>
                    <EnumItemLabel map={SysState.Map} code={device.sysState}/>
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_BATTERY_VOLTAGE"})}>
                    {device.batteryVoltage}mV
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_TEMP_BQ27441"})}>
                    {device.tempBq27441 / 10}°C
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_TEMP_BLE"})}>
                    {device.tempBle / 10}°C
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_TEMP_BAT"})}>
                    {device.tempBat / 10}°C
                </Descriptions.Item>
            </Descriptions>
        );
    }
}
function mapStateToProps(store) {
    return {
    }
}
export default connect(mapStateToProps)(injectIntl(DeviceDetailSystem));