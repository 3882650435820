/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Popover, Row, Col, Form, Button, Input, Select} from 'antd';

import {
    refreshMonitorSearchCondition,
    searchDeviceList,
    searchRegionList,
    refreshRegions
} from '../../../actions/DeviceAction';
import './index.css';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {LeadState, OnlineState, SysState, DeviceCodeFilter} from "../../../Enums";
import CityTabs from "./CityTabs";
import SearchDrawer from "../../../../../components/SearchDrawer";
import '../../../../../utils/GeoUtils'
import EnumItemSelect from "../../../../../components/EnumItemSelect";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            region: "",
            speedyPickFlag: 0,
            clickFlag: 0
        }
    }

    componentWillMount() {
        // this.props.dispatch(getCountryTree(this.state.formatMessage));
        // this.props.dispatch(searchRegionList({},this.state.formatMessage));
        let monitorSearchCondition = Object.assign({}, this.props.monitorSearchCondition);
        monitorSearchCondition.deviceCodeFilter = "2";
        this.props.dispatch(refreshMonitorSearchCondition(monitorSearchCondition));
    }

    componentWillUnmount() {
        let map = this.props.map;
        const self = this;
        map && map.removeEventListener( "dragstart" , function(){ self._stopRefreshData() });
        map && map.removeEventListener( "dragend" , function(){ self._startRefreshData() });

        map && map.removeEventListener( "zoomstart" , function(){ self._stopRefreshData() });
        map && map.removeEventListener( "zoomend" , function(){ self._startRefreshData() });
    }

    _stopRefreshData(){
        window.NONITOR_DATA_REFRESH = 0;
    }

    _startRefreshData(){
        window.NONITOR_DATA_REFRESH = 1;
    }

    _handleSearch(monitorSearchCondition){
        monitorSearchCondition["paginationFlag"] = false;
        // console.log(monitorSearchCondition)
        if (this._validate(monitorSearchCondition)) {
            window.NONITOR_DATA_REFRESH = 1;
            this.props.dispatch(searchDeviceList(monitorSearchCondition, this.state.formatMessage, false, false));
            if (monitorSearchCondition && monitorSearchCondition.deviceCity){
                this._handleCircle(monitorSearchCondition.deviceCity);
            }
        }
    }


    _validate(monitorSearchCondition) {
        const {formatMessage} = this.state;
        let messageMap = {};
        if (monitorSearchCondition.deviceCodeFilter &&
            monitorSearchCondition.deviceCodeFilter === "3"){
            if (monitorSearchCondition.deviceCodeStart.length !== 8 &&
                monitorSearchCondition.deviceCodeStart !== "" && monitorSearchCondition.deviceCodeStart !== undefined){
                messageMap["deviceCodeStart"] = formatMessage({id: "COMMON_MSG_LENGTH_EIGHT"});
            }
            if (monitorSearchCondition.deviceCodeEnd.length !== 8 &&
                monitorSearchCondition.deviceCodeEnd !== "" && monitorSearchCondition.deviceCodeEnd !== undefined){
                messageMap["deviceCodeEnd"] = formatMessage({id: "COMMON_MSG_LENGTH_EIGHT"});
            }
            if (monitorSearchCondition.deviceCodeStart !== "" &&
                monitorSearchCondition.deviceCodeStart !== undefined &&
                monitorSearchCondition.deviceCodeEnd !== "" &&
                monitorSearchCondition.deviceCodeEnd !== undefined &&
                monitorSearchCondition.deviceCodeStart.length === 8 &&
                monitorSearchCondition.deviceCodeEnd.length === 8 &&
                monitorSearchCondition.deviceCodeEnd <= monitorSearchCondition.deviceCodeStart){
                messageMap["deviceCodeEnd"] = formatMessage({id: "COMMON_MSG_GREATER_START"});
            }
        }
        // console.log(searchCondition);
        // console.log(messageMap);
        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleChange(name, value) {
        window.NONITOR_DATA_REFRESH = 0;
        let monitorSearchCondition = Object.assign({}, this.props.monitorSearchCondition);
        if (value || value === 0) {
            if (name === "deviceLeadStatesTemp"){
                monitorSearchCondition[name] = value.target ? [String(value.target.value)] : [String(value)];
            } else if( monitorSearchCondition.deviceCodeFilter === "0" && name === "deviceCode" ){
                monitorSearchCondition[name] = value.target ? value.target.value + "%" : value + "%";
            } else if( monitorSearchCondition.deviceCodeFilter === "1" && name === "deviceCode" ){
                monitorSearchCondition[name] = value.target ? "%" + value.target.value : "%" + value;
            } else if( monitorSearchCondition.deviceCodeFilter === "2" && name === "deviceCode" ){
                monitorSearchCondition[name] = value.target ? "%" + value.target.value + "%" : "%" + value + "%";
            } else {
                monitorSearchCondition[name] = value.target ? value.target.value : value;
            }
        }else {
            delete monitorSearchCondition[name];
        }
        if (name === "deviceCodeFilter" &&
            (monitorSearchCondition.deviceCodeFilter === "" || monitorSearchCondition.deviceCodeFilter === undefined)){
            if (monitorSearchCondition.deviceCode || monitorSearchCondition.deviceCode === 0){
                delete monitorSearchCondition["deviceCode"];
            }
            if (monitorSearchCondition.deviceCodeStart || monitorSearchCondition.deviceCodeStart === 0){
                delete monitorSearchCondition["deviceCodeStart"];
            }
            if (monitorSearchCondition.deviceCodeEnd || monitorSearchCondition.deviceCodeEnd === 0){
                delete monitorSearchCondition["deviceCodeEnd"];
            }
        }
        if (name === "deviceCodeFilter" &&
            monitorSearchCondition.deviceCodeFilter !== "" && monitorSearchCondition.deviceCodeFilter !== undefined){
            if (monitorSearchCondition.deviceCode || monitorSearchCondition.deviceCode === 0){
                delete monitorSearchCondition["deviceCode"];
            }
            if (monitorSearchCondition.deviceCodeStart || monitorSearchCondition.deviceCodeStart === 0){
                delete monitorSearchCondition["deviceCodeStart"];
            }
            if (monitorSearchCondition.deviceCodeEnd || monitorSearchCondition.deviceCodeEnd === 0){
                delete monitorSearchCondition["deviceCodeEnd"];
            }
        }
        this.props.dispatch(refreshMonitorSearchCondition(monitorSearchCondition));
    }

    _handleEmpty(){
        this.props.dispatch(refreshMonitorSearchCondition({}));
        // const region = this.state.region;
        // if (region !== "" && region !== undefined){
            let map = this.props.map;
            const self = this;
            setTimeout( function(){
                window.NONITOR_DATA_REFRESH = 1;
            }, 500 );
            // this.setState({region:""});
            //拖动地图时停止实时刷新数据
            map && map.addEventListener( "dragstart" , function(){ self._stopRefreshData() });
            map && map.addEventListener( "dragend" , function(){ self._startRefreshData() });

            //缩放地图时停止实时刷新数据
            map && map.addEventListener( "zoomstart" , function(){ self._stopRefreshData() });
            map && map.addEventListener( "zoomend" , function(){ self._startRefreshData() });

        // }
    }

    _returnValue(monitorSearchCondition){
        if (monitorSearchCondition.deviceCodeFilter === "3"){
            return monitorSearchCondition.deviceCodeStart;
        }else {
            let deviceCode = monitorSearchCondition.deviceCode;
            if (monitorSearchCondition.deviceCodeFilter === "0"){
                deviceCode = deviceCode && deviceCode.substring(0, deviceCode.lastIndexOf('%'));
            }else if(monitorSearchCondition.deviceCodeFilter === "1"){
                deviceCode = deviceCode && deviceCode.substring(1);
            }else {
                deviceCode = deviceCode && deviceCode.substring(1, deviceCode.lastIndexOf('%'));
            }
            return deviceCode;
        }
    }

    _openInfo(content,e){
        const opts = {
            width : 250,     // 信息窗口宽度
            height: 80,     // 信息窗口高度
            title : "<h4>设备信息</h4>" , // 信息窗口标题
            enableMessage:true,//设置允许信息窗发送短息
            enableAutoPan:true//自动平移
        };
        let map = this.props.map;
        let p = e.target;
        let point = new window.BMap.Point(p.getPosition().lng, p.getPosition().lat);
        let infoWindow = new window.BMap.InfoWindow(content,opts);  // 创建信息窗口对象
        map && map.openInfoWindow(infoWindow,point); //开启信息窗口
    }

    _handleCircle(city){
        window.NONITOR_DATA_REFRESH = 0;
        // this.setState({region: city});
        const monitorSearchCondition = Object.assign({},this.props.monitorSearchCondition);
        monitorSearchCondition["deviceCity"] = city;
        this.props.dispatch(refreshMonitorSearchCondition(monitorSearchCondition));
        const allAllDevices = Object.assign([],this.props.allAllDevices);
        let map = this.props.map;
        const self = this;
        let bdary = new window.BMap.Boundary();
        bdary.get(city, function(rs) {       //获取行政区域
            map.clearOverlays();        //清除地图覆盖物
            let count = rs.boundaries.length; //行政区域的点有多少个
            let ply; //多边形
            for (let i = 0; i < count; i++) {
                ply = new window.BMap.Polygon(rs.boundaries[i],
                    {
                        strokeWeight: 2, //设置多边形边线线粗
                        strokeOpacity: 1, //设置多边形边线透明度0-1
                        StrokeStyle: "solid", //设置多边形边线样式为实线或虚线，取值 solid 或 dashed
                        strokeColor: "#00A2E8", //设置多边形边线颜色
                        fillColor: "#00ffff", //设置多边形填充颜色
                        fillOpacity: 0.01 //设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
                    }); //建立多边形覆盖物
            }
            map.addOverlay(ply);  //添加覆盖物
            map.setViewport(ply.getPath());    //调整视野

            for(let i = 0; i < allAllDevices.length; i++){
                if (allAllDevices[i] != null && map){
                    let longitude = allAllDevices[i].longitude;
                    let latitude = allAllDevices[i].latitude;
                    let content = "设备编号：" + allAllDevices[i].code;
                    const icon = new window.BMap.Icon(require("../../../../../images/icon.png")
                        , new window.BMap.Size(20, 21) ,
                        {
                            imageOffset:new window.BMap.Size(-45,0)  // 图片相对视窗的偏移
                        });
                    const point = new window.BMap.Point(longitude, latitude);
                    const result = window.BMapLib.GeoUtils.isPointInPolygon(point, ply);
                    if (result){
                        const marker = new window.BMap.Marker(point,{icon:icon});
                        marker.setAnimation(window.BMAP_ANIMATION_DROP);
                        map && map.addOverlay(marker);
                        marker.addEventListener('mouseover',(e) => self._openInfo(content,e));
                    }
                }
            }
        });
    }

    _changeRegion(){
        window.NONITOR_DATA_REFRESH = 0;
        const self = this;
        // this.setState({region:e});
        const monitorSearchCondition = Object.assign({},this.props.monitorSearchCondition);
        monitorSearchCondition["deviceCity"] = "";
        this.props.dispatch(refreshMonitorSearchCondition(monitorSearchCondition));
            // const allAllDevices = Object.assign([],this.props.allAllDevices);
        let map = this.props.map;
        map && map.clearOverlays();

        /*setTimeout( function(){
            window.NONITOR_DATA_REFRESH = 1;
        }, 50 );*/
        window.NONITOR_DATA_REFRESH = 1;

        //拖动地图时停止实时刷新数据
        map && map.addEventListener( "dragstart" , function(){ self._stopRefreshData() });
        map && map.addEventListener( "dragend" , function(){ self._startRefreshData() });

        //缩放地图时停止实时刷新数据
        map && map.addEventListener( "zoomstart" , function(){ self._stopRefreshData() });
        map && map.addEventListener( "zoomend" , function(){ self._startRefreshData() });
    }

    _handleSearchRegions(value){
        window.NONITOR_DATA_REFRESH = 0;
        if (value){
            this.setState({speedyPickFlag: 0});
            this.props.dispatch(searchRegionList({cityName:"%"+value},this.state.formatMessage));
        }
    }

    _handleChangeRegions(value){
        window.NONITOR_DATA_REFRESH = 0;
        this.setState({clickFlag: 1});
        if (value){
            this.setState({speedyPickFlag: 0});
        }
        const monitorSearchCondition = Object.assign({},this.props.monitorSearchCondition);
        monitorSearchCondition["deviceCity"] = value;
        this.props.dispatch(refreshMonitorSearchCondition(monitorSearchCondition));
        this.props.dispatch(searchDeviceList(monitorSearchCondition, this.state.formatMessage, false, true));
    }

    _handleFocus(){
        const monitorSearchCondition = Object.assign({},this.props.monitorSearchCondition);
        if (monitorSearchCondition.deviceCity){
            this.setState({speedyPickFlag: 0});
        }else {
            this.props.dispatch(refreshRegions([]));
            this.setState({speedyPickFlag: 1});
            this.setState({clickFlag: 0});
        }
    }

    _handleBlur(){
        const self = this;
        setTimeout( function(){
            if (self.state.clickFlag === 0){
                self.setState({speedyPickFlag: 0});
            }
        }, 100 );
    }

    _handleChangeTabPane(){
        this.setState({clickFlag: 1});
    }

    render() {
        // const countryTree = Object.assign([], this.props.countryTree);
        const {monitorSearchCondition,regions} = this.props;
        const {formatMessage,messageMap,speedyPickFlag} = this.state;
        const options = regions && regions.map(d => <Select.Option key={d.name}>{d.name}</Select.Option>);

        return (
            <SearchDrawer placement={"top"}>
                <Form layout="inline" style={{backgroundColor: "#ffffcc", paddingLeft: "5px"}}>
                    <Row gutter={24}>
                        <Col style={{paddingLeft:26}} span={6}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DASHBOARD_REGION'})} {...Label_6_Wrapper_18}
                                style={{paddingLeft:3}}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    showArrow={false}
                                    style={{ width: 170 }}
                                    optionFilterProp="children"
                                    value={monitorSearchCondition.deviceCity}
                                    onSearch={(value) => this._handleSearchRegions(value)}
                                    onChange={(value) => this._handleChangeRegions(value)}
                                    filterOption={false}
                                    notFoundContent={false}
                                    onFocus={() => this._handleFocus()}
                                    onBlur={() => this._handleBlur()}
                                >
                                    {/*{this._returnRegionList(monitorSearchCondition.region,regions)}*/}
                                    {options}
                                </Select>
                                {
                                    speedyPickFlag === 1?
                                        <CityTabs onClick={(e) => this._handleChangeRegions(e)}
                                                  onChange={() => this._handleChangeTabPane()} /> :
                                        null
                                }
                                {/*<Popover content={<CityTabs onClick={(e) => this._handleCircle(e)} />}
                                         placement="bottomLeft"
                                         trigger="click">
                                    <Input value={monitorSearchCondition.region}
                                           allowClear={true}
                                           onChange={() => this._changeRegion()}
                                           style={{width: 134}}/>
                                </Popover>*/}
                            </Form.Item>
                        </Col>
                        <Col span={18}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}>
                                <EnumItemSelect data={DeviceCodeFilter.List}
                                                value={monitorSearchCondition.deviceCodeFilter}
                                                style={{width: 170}}
                                                onChange={(value) => {
                                                    this._handleChange("deviceCodeFilter", value)
                                                }}
                                />
                            </Form.Item>
                            {
                                monitorSearchCondition.deviceCodeFilter ?
                                    <Form.Item
                                        validateStatus={messageMap["deviceCodeStart"] ? "error" : ""}
                                        help={messageMap["deviceCodeStart"]}
                                    >
                                        <Input
                                            style={{width: 158.5}}
                                            maxLength={8}
                                            value={this._returnValue(monitorSearchCondition)}
                                            onChange={(value) => {
                                                if (monitorSearchCondition.deviceCodeFilter === "3"){
                                                    this._handleChange("deviceCodeStart", value)
                                                }else {
                                                    this._handleChange("deviceCode", value)
                                                }
                                            }}
                                        />
                                    </Form.Item> : null
                            }
                            {
                                monitorSearchCondition.deviceCodeFilter === "3" ?
                                    <Form.Item>
                                        <Form.Item>--</Form.Item>
                                        <Form.Item
                                            validateStatus={messageMap["deviceCodeEnd"] ? "error" : ""}
                                            help={messageMap["deviceCodeEnd"]}
                                        >
                                            <Input
                                                style={{width: 158.5}}
                                                maxLength={8}
                                                value={monitorSearchCondition.deviceCodeEnd}
                                                onChange={(value) => {
                                                    this._handleChange("deviceCodeEnd", value)
                                                }}
                                            />
                                        </Form.Item>
                                    </Form.Item> : null
                            }
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_TRANSPORT_FLAG'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={monitorSearchCondition.deviceOnlineStatesTemp}
                                                mode="multiple"
                                                style={{width: 170}}
                                                onChange={(value) => {
                                                    this._handleChange("deviceOnlineStatesTemp", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item*/}
                        {/*        // style={{paddingLeft:5}}*/}
                        {/*        label={formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'})}>*/}
                        {/*        <EnumItemSelect data={SysState.List}*/}
                        {/*                        value={monitorSearchCondition.deviceSysStatesTemp}*/}
                        {/*                        mode="multiple"*/}
                        {/*                        style={{width: 170}}*/}
                        {/*                        onChange={(value) => {*/}
                        {/*                            this._handleChange("deviceSysStatesTemp", value)*/}
                        {/*                        }}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})}>
                                <EnumItemSelect data={LeadState.List}
                                                value={
                                                    monitorSearchCondition.deviceLeadStatesTemp &&
                                                    Number(monitorSearchCondition.deviceLeadStatesTemp[0])
                                                }
                                                style={{width: 170}}
                                                onChange={(value) => {
                                                    this._handleChange("deviceLeadStatesTemp", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={6}>
                            <Button type="primary" icon="delete" className="spacing-h"
                                    onClick={() => this._handleEmpty()}>
                                <FormattedMessage id='COMMON_BUTTON_EMPTY'/>
                            </Button>
                            <Button type="primary" icon="search" className="spacing-h"
                                    onClick={() => this._handleSearch(monitorSearchCondition)}>
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            {/*<Button type="primary" className="spacing-h"
                                    onClick={() => this._handleTest()}>
                                测试
                            </Button>*/}
                        </Col>
                    </Row>
                    {/*<Form.Item
                        label={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})}>
                        <EnumItemSelect data={LeadState.List}
                                        value={monitorSearchCondition.deviceLeadStates}
                                        mode="multiple"
                                        style={{width: 170}}
                                        onChange={(value) => {
                                            this._handleChange("deviceLeadStates", value)
                                        }}/>
                    </Form.Item>*/}
                    {/*<Form.Item>
                        <Button type="primary" icon="search" className="spacing-h" loading={this.props.isLoading}
                                onClick={() => this._handleSearch(this.props.monitorSearchCondition)}>
                            <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                        </Button>
                    </Form.Item>*/}
                </Form>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        mapScreenVisible: store.EcgDeviceReducer.mapScreenVisible,
        monitorSearchCondition: store.EcgDeviceReducer.monitorSearchCondition,
        allAllDevices: store.EcgDeviceReducer.allAllDevices,
        regionResearchCondition: store.EcgDeviceReducer.regionResearchCondition,
        regions: store.EcgDeviceReducer.regions,
        // countryTree: store.DistrictCountryReducer.countryTree,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));