/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input} from 'antd';

import {searchCases, refreshSearchCondition, toggleDetailModal, refreshCase} from "../../../actions/EcgCaseAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class SearchCondition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
        this.props.dispatch(refreshSearchCondition({}));
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchCases(searchCondition, this.state.formatMessage));
    }

    _handleAdd() {
        this.props.dispatch(refreshCase({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    render() {

        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_CASE_FIELD_NUMBER'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.number} onChange={(value) => {
                                    this._handleChange("number", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_CASE_FIELD_NAME'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.name} onChange={(value) => {
                                    this._handleChange("name", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus" className="spacing-h" onClick={() => this._handleAdd()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.EcgCaseReducer.searchCondition,
        pagination: store.EcgCaseReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));