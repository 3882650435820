/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {
    ECG_DEVICE_ALL_SEARCH_CONDITION,
    ECG_DEVICE_CODE,
    ECG_DEVICE_HISTORY_DATA,
    ECG_DEVICE_REFRESH_VERSION_INDEX,
    ECG_FILE_STATISTIC_USER_MEMO_LIST,
    ECG_LOG_DEVICE_READ_FAIL_FILES,
    ECG_LOG_DEVICE_READ_FAIL_FILES_DEV
} from "../ActionTypes";
import {EcgFileType} from "../Enums";
import {getUserMemoExcel, searchEcgEventRecord} from "../WebService";

export function refreshRegions(regions) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_REGIONS,
        regions: regions
    }
}

export function refreshScreenMap(screenMap) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_SCREEN_MAP,
        screenMap: screenMap
    }
}

export function toggleResultModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_RESULT_MODAL,
        showResultModal: isShow
    }
}

export function refreshChannelType(channelType) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_CHANNEL_TYPE,
        channelType: channelType
    }
}

export function refreshVersionIndex(version) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_VERSION_INDEX,
        version: version
    }
}

export function refreshMonitorSearchCondition(monitorSearchCondition) {
    return {
        type: ActionTypes.ECG_DASHBOARD_REFRESH_SEARCH_CONDITION,
        monitorSearchCondition: monitorSearchCondition
    }
}

export function toggleDeviceSelectModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_SELECT_MODAL,
        showDeviceSelectModal: isShow
    }
}

export function toggleMapScreenVisible(mapScreenVisible) {
    return {
        type: ActionTypes.ECG_DASHBOARD_TOGGLE_MAP_SCREEN,
        mapScreenVisible: mapScreenVisible
    }
}

export function refreshDevice(device) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_DEVICE,
        device: device
    }
}

export function refreshCacheDevice(device) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_CACHE_DEVICE,
        cacheDevice: device
    }
}

export function refreshDevices(devices) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_DEVICES,
        devices: devices
    }
}

export function refreshMapSearchCondition(mapSearchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_MAP_SEARCH_CONDITION,
        mapSearchCondition: mapSearchCondition
    }
}

export function refreshEcgEventRecordMap(ecgEventRecordMap) {
    return {
        type: ActionTypes.ECG_EVENT_RECORD_MAP,
        ecgEventRecordMap: ecgEventRecordMap
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshSearchConditionAll(searchConditionAll) {
    return {
        type: ActionTypes.ECG_DEVICE_ALL_SEARCH_CONDITION,
        searchConditionAll: searchConditionAll
    }
}

export function refreshDeviceMonitorColumns(deviceMonitorColumns) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_DEVICE_MONITOR_COLUMNS,
        deviceMonitorColumns: deviceMonitorColumns
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function toggleCreateModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_CREATE_MODAL,
        showCreateModal: isShow
    }
}

export function refreshFilterMapViewportFlag(viewportFlag) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_FILTER_VIEWPORT_FLAG,
        filterMapViewportFlag: viewportFlag
    }
}

export function refreshSelectDeviceId(selectedDeviceId) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_SELECT_DEVICE_ID,
        selectedDeviceId: selectedDeviceId
    }
}

export function toggleFieldSelectModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_FIELD_SELECT_MODAL,
        showFieldSelectModal: isShow
    }
}

export function toggleCacheModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_TOGGLE_CACHE_MODAL,
        showCacheModal: isShow
    }
}

export function refreshMemoTimeFrom(date) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_MEMO_TIME_FROM,
        memoTimeFrom: date
    }
}

export function refreshMemoTimeTo(date) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_MEMO_TIME_TO,
        memoTimeTo: date
    }
}

export function getAllDevices(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        let requestUrl = WebService.searchDevices;
        HttpUtils.post(requestUrl, {paginationFlag: false}, (response) => {
            console.log("allDevices", response)
            dispatch({
                type: ActionTypes.ECG_DEVICE_SEARCH_ALL,
                allDevices: response.data
            });

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function refreshSearchDeviceCode(deviceCode) {
    return {
        type: ActionTypes.ECG_DEVICE_CODE,
        deviceCode: deviceCode
    }
}

export function getDeviceHistoryData(deviceId, dataSource, fileType, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        let requestUrl = WebService.getDeviceHistoryData.replace("{deviceId}", deviceId);
        requestUrl = requestUrl.replace("{fileType}", fileType);
        requestUrl = requestUrl.replace("{dataSource}", dataSource);
        if (dataSource == "dev") {
            HttpUtils.get(requestUrl, (response) => {
                switch (fileType) {
                    case EcgFileType.Enum.MIXDATA:
                        dispatch({
                            type: ActionTypes.ECG_DEVICE_HISTORY_DATA,
                            deviceHistoryData: response.data
                        });
                        break
                    case EcgFileType.Enum.EVNTIDX:
                        dispatch({
                            type: ActionTypes.ECG_DEVICE_EVENT_DATA,
                            deviceEventData: response.data
                        });
                        break
                    case EcgFileType.Enum.TIMEIDX:
                        dispatch({
                            type: ActionTypes.ECG_DEVICE_TIME_DATA,
                            deviceTimeData: response.data
                        });
                        break
                }


                dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

            }, (response) => {
                dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
            }, formatMessage)
        } else {
            HttpUtils.get(requestUrl, (response) => {

                switch (fileType) {
                    case EcgFileType.Enum.MIXDATA:
                        dispatch({
                            type: ActionTypes.ECG_APP_HISTORY_DATA,
                            appHistoryData: response.data
                        });
                        break
                    case EcgFileType.Enum.EVNTIDX:
                        dispatch({
                            type: ActionTypes.ECG_APP_EVENT_DATA,
                            appEventData: response.data
                        });
                        break
                    case EcgFileType.Enum.TIMEIDX:
                        dispatch({
                            type: ActionTypes.ECG_APP_TIME_DATA,
                            appTimeData: response.data
                        });
                        break
                }

                dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

            }, (response) => {
                dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
            }, formatMessage)
        }

    }
}

export function searchRegionList(searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchRegions, searchCondition, (response) => {

            // console.log(response.data)
            dispatch({
                type: ActionTypes.ECG_DEVICE_REFRESH_REGIONS,
                regions: response.data,
            });

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchDeviceList(searchCondition, formatMessage, withBinders, isSimpleObject, isMonitor) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        let requestUrl = WebService.searchDevices;
        if (withBinders) {
            if (isSimpleObject) {
                requestUrl = requestUrl + "?withBinders=" + withBinders + "&simpleObject=" + isSimpleObject;
            } else {
                requestUrl = requestUrl + "?withBinders=" + withBinders;
            }
        } else {
            if (isSimpleObject) {
                requestUrl = requestUrl + "?simpleObject=" + isSimpleObject;
            } else {
                requestUrl = requestUrl + "?simpleObject=" + false;//isSimpleObject不存在即为false
            }
        }

        HttpUtils.post(requestUrl, searchCondition, (response) => {

            console.log("response", response)
            if (!isSimpleObject) {
                if (isMonitor) {
                    dispatch({
                        type: ActionTypes.ECG_MAP_DEVICE_SEARCH,
                        mapDevices: response.data,
                        mapPagination: response.pagination
                    });
                    dispatch(refreshMapSearchCondition(searchCondition));
                } else {
                    dispatch({
                        type: ActionTypes.ECG_DEVICE_SEARCH,
                        devices: response.data,
                        pagination: response.pagination
                    });
                    dispatch(refreshSearchCondition(searchCondition));
                }
            } else {
                // console.log(response.data)
                dispatch({
                    type: ActionTypes.ECG_ALL_DEVICE_SEARCH,
                    allAllDevices: response.data
                });
                dispatch(refreshSearchConditionAll(searchCondition));
            }

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchDevicesSum(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        HttpUtils.get(WebService.searchDevicesCount, (response) => {
            dispatch({
                type: ActionTypes.ECG_DEVICE_SEARCH_DEVICES_SUM,
                devicesSum: response.data
            });

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchEcgRecordSum(parem, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        console.log("ecgEventRecordMap:", parem)
        HttpUtils.post(WebService.searchEcgEventRecord, parem, (response) => {

            dispatch({
                type: ActionTypes.ECG_EVENT_RECORD_MAP,
                ecgEventRecordMap: response.data,
            });
            console.log("ecgEventRecordMap1:", response);
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getUserMemoList(params, formatMessage) {
    console.log("getUserMemoList:", params)
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        HttpUtils.post(WebService.getUserMemoList, params, (response) => {

            dispatch({
                type: ActionTypes.ECG_FILE_STATISTIC_USER_MEMO_LIST,
                userMemoList: response.data,
            });
            console.log("getUserMemoList1:", response, response.data)
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
            console.log("getUserMemoList2:", response, response.data)
        }, formatMessage)
    }
}

export function searchDevicesActiveSum(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        const requestUrl = WebService.searchDevicesCount + "?activeFlag=1";
        HttpUtils.get(requestUrl, (response) => {

            dispatch({
                type: ActionTypes.ECG_DEVICE_SEARCH_DEVICES_ACTIVE_SUM,
                devicesActiveSum: response.data
            });

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getPastDynastiesVersion(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        let requestUrl = WebService.getGenerationIDS.replace("{id}", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING,
                // type: ECG_LOG_DEVICE_DOWNLOAD_VERSION,
                mapVersion: response.data
            });
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            // dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});

        }, formatMessage)
    }
}

export function getReadFailFiles(deviceCode, version, fromApp, fileType, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        let requestUrl = WebService.getReadFailFiles.replace("{deviceCode}", deviceCode);
        requestUrl = requestUrl.replace("{version}", version);
        requestUrl = requestUrl.replace("{fromApp}", fromApp);
        requestUrl = requestUrl.replace("{fileType}", fileType);
        console.log("getReadFailFiles1:", requestUrl);
        HttpUtils.get(requestUrl, (response) => {
            if (fromApp == "1") {
                console.log("getReadFailFiles3:", response.data);
                dispatch({
                    type: ActionTypes.ECG_LOG_DEVICE_READ_FAIL_FILES_APP,
                    failFilesMapApp: response.data
                });
            } else {
                console.log("getReadFailFiles2:", response.data);
                dispatch({
                    type: ActionTypes.ECG_LOG_DEVICE_READ_FAIL_FILES_DEV,
                    failFilesMapDev: response.data
                });
            }

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getDeviceById(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        const requestURL = WebService.getDeviceById.replace("{id}", id);

        HttpUtils.get(requestURL, (response) => {

            dispatch(refreshDevice(response.data));
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getRedisData(deviceCode, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        const requestURL = WebService.getRedisData.replace("{deviceCode}", deviceCode);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshCacheDevice(response.data));
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createDevice(device, searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDevice, device, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshDevice({}));
            dispatch(searchDeviceList(searchCondition, formatMessage));
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDevice(device, searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        const requestURL = WebService.updateDeviceById.replace("{id}", device.id);
        HttpUtils.put(requestURL, device, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshDevice({}));
            dispatch(searchDeviceList(searchCondition, formatMessage));

            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDeviceRedis(device, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        const requestURL = WebService.updateDeviceRedis.replace("{deviceCode}", device.code);
        HttpUtils.put(requestURL, device, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch(getRedisData(device.code, formatMessage));
            dispatch(toggleCacheModal(false));
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteDevice(device, searchCondition, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByDeviceId.replace("{id}", device.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(searchDeviceList(searchCondition, formatMessage));
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage);
    }
}

export function deleteDeviceRedis(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});

        const requestURL = WebService.deleteDeviceRedis.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "DAS_DEVICE_MSG_DATA_INIT_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function downloadDeviceFile(condition, name, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: true});
        let conditions=new Array(condition.deviceCode,condition.deviceCodeInGuard,condition.phoneNumber);
        const requestUrl = HttpUtils.addQueryString(WebService.getUserMemoExcel, "condition", conditions);
        HttpUtils.getFile(requestUrl, name, (response) => {
            console.log("response:" + response);
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_LOADING, isLoading: false});
        }, formatMessage)
    }
}



