/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Card, Table, Button} from 'antd';

import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";

import PaginationBar from "../../../../../components/PaginationBar";

import {searchEcgFiles} from '../../../actions/ECGAction';

import {BindStatusMap} from "../../../../../constants/Enums";
import {RoutePath} from "../../../../../constants/RoutePath";
import HttpUtils from "../../../../../utils/HttpUtils";
import * as ActionTypes from "../../../ActionTypes";
import * as WebService from "../../../WebService";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'binderName',
                    dataIndex: 'binderName',
                    width: 100,
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_NAME'})
                },
                {
                    key: 'binderMobile',
                    dataIndex: 'binderMobile',
                    width: 150,
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_MOBILE'})
                },
                // {
                //     key: 'binder.userEmail',
                //     dataIndex: 'binder.userEmail',
                //     title: formatMessage({id: 'ECG_BINDER_FIELD_USER_EMAIL'})
                // },
                {
                    key: 'deviceCode',
                    dataIndex: 'deviceCode',
                    width: 100,
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})
                },
                {
                    key: 'diagnosisStartTime',
                    dataIndex: 'diagnosisStartTime',
                    width: 150,
                    title: formatMessage({id: 'ECG_FILE_FIELD_DIAGNOSIS_START_TIME'}),
                    render: (text, record, index) => this._renderDiagnosisTime(text)
                },
                {
                    key: 'diagnosisEndTime',
                    dataIndex: 'diagnosisEndTime',
                    width: 150,
                    title: formatMessage({id: 'ECG_FILE_FIELD_DIAGNOSIS_END_TIME'}),
                    render: (text, record, index) => this._renderDiagnosisTime(text)
                },
                {
                    key: 'filePath',
                    dataIndex: 'filePath',
                    title: formatMessage({id: 'DAS_ECG_FIELD_FILE_PATH'}),
                    render: (text, record, index) => <Link to={RoutePath.DAS_ECG_HISTORY_LIGHTWAVE.path.replace(":id", record.id)}>{record.filePath}</Link>
                },
            ],

            playFlag: false,
            playDirection: ""
        }
    }

    _renderDiagnosisTime(diagnosisTime) {
        if (diagnosisTime !== undefined && diagnosisTime !== "") {
            diagnosisTime = moment(diagnosisTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN)
        }
        return (
            <span>{diagnosisTime}</span>
        )
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.ecgFiles} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        console.log(searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchEcgFiles(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        ecgFiles: store.DASECGReducer.ecgFiles,
        searchCondition: store.DASECGReducer.searchCondition,
        pagination: store.DASECGReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));