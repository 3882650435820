import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {CACHE_ACCESS_TOKEN} from "../../../constants/Profile";
import {HttpStatus} from "../../../constants/Enums";

export function getOAuth2AccessToken(code, appId, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: true});

        let requestUrl = HttpUtils.addQueryString(WebService.getOAuth2AccessToken, "code", code);
        requestUrl = HttpUtils.addQueryString(requestUrl, "appId", appId);
        HttpUtils.get(requestUrl, (response) => {
            successCallback && successCallback.call(this, response.data);
            dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: false});
            console.log(response);
        })
    }
}

// user 是一个在登录的过程中，要把获取的OpenID跟该用户绑定的数据对象
export function loginByOpenId(code, appId, user, formatMessage, successCallback, failCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: true});

        let requestUrl = HttpUtils.addQueryString(WebService.loginByOpenId, "code", code);
        requestUrl = HttpUtils.addQueryString(requestUrl, "appId", appId);
        HttpUtils.post(requestUrl, user, (response) => {
            if (response.data === 'WECHAT_AUTH_MSG_USER_NOT_BIND') {
                failCallback && failCallback.call(this, response.data);
            } else {
                localStorage.setItem(CACHE_ACCESS_TOKEN, response.data.accessToken);
                successCallback && successCallback.call(this, response.data);
            }
            dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.WECHAT_AUTH_LOADING, isLoading: false});
            console.log(response);
        }, formatMessage)
    }
}