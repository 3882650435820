/**
 * Created by Ethan on 2017/9/8.
 */

import * as ActionTypes from '../constants/ActionTypes';
import {RoutePath, RoutePathKey} from '../constants/RoutePath';
import {RoleCode} from "../modules/Account/Enums";
import {
    APP_REFRESH_RECORD,
    APP_REFRESH_SEARCH_CODITION,
    APP_REFRESH_WARN_FLAG,
    APP_WARN_COUNT, APP_WARN_LOADING
} from "../constants/ActionTypes";
import HttpUtils from "../utils/HttpUtils";
import * as WebService from "../modules/DAS/WebService";

export function refreshPathname(pathname) {
    return {
        type: ActionTypes.APP_REFRESH_PATHNAME,
        pathname: pathname
    }
}

export function refreshWarnFlag(isWarn) {
    return {
        type: ActionTypes.APP_REFRESH_WARN_FLAG,
        isWarn: isWarn
    }
}

export function refreshActiveKey(setDefaultActiveKey) {
    return {
        type: ActionTypes.APP_REFRESH_ACTIVITY_KEY,
        setDefaultActiveKey: setDefaultActiveKey
    }
}

export function refreshShowWarnRecordModal(showWarnRecordModal) {
    return {
        type: ActionTypes.APP_REFRESH_SHOW_WARN_RECORD_MODAL,
        showWarnRecordModal: showWarnRecordModal
    }
}

export function refreshWarnRecord(record) {

    return {
        type: ActionTypes.APP_REFRESH_WARN_RECORD,
        warnRecord: record
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.APP_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshRecord(record) {
    return {
        type: ActionTypes.APP_REFRESH_RECORD,
        record: record
    }
}

export function addWarnCount(warnCnt) {
    return {
        type: ActionTypes.APP_WARN_COUNT,
        warnCnt: warnCnt + 1
    }
}

export function resetFlag() {
    return {
        type: ActionTypes.APP_RESET_FLAG,
    }
}

export function getDefaultLanguage() {

    /*
     *获取浏览器设置的语言；
     *按我demo中的设置，返回["zh-CN", "zh", "en-US", "en"]，排序与语言设置顺序一直；
     */
    const languages = navigator.languages;
    // const defaultLanguage = languages[0];
    const defaultLanguage = 'zh-CN';
    //console.log("languages: ", languages);
    //console.log("language: ", defaultLanguage);
    return {
        type: ActionTypes.APP_GET_DEFAULT_LANGUAGE,
        currentLanguage: defaultLanguage
    }
}

export function changeLanguage(language) {
    return dispatch => {
        dispatch({
            type: ActionTypes.APP_CHANGE_LANGUAGE,
            currentLanguage: language
        })
    }
}

export function searchWarnRecord(searchCondition, formatMessage) {
    return dispatch => {
        let flag = false;
        dispatch({type: ActionTypes.APP_WARN_LOADING, isWarnLoading: true});

        if(searchCondition.guardMode === 0){
            searchCondition.guardMode = "";
            flag = true;
        }

        HttpUtils.post(WebService.searchWarnRecord, searchCondition, (response) => {

            dispatch(refreshRecord(response.data));
            console.log("WarnRecord:" + JSON.stringify(response.data));
            dispatch({type: ActionTypes.APP_WARN_LOADING, isWarnLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.APP_WARN_LOADING, isWarnLoading: false});
        }, formatMessage)

        if(flag){
            searchCondition.guardMode = 0;
        }
    }
}

export function getMainMenu(sessionUser) {

    console.log("sessionUser:" + JSON.stringify(sessionUser));

    // 开设对外客户账号，只显示设备管理菜单。
    // 设备列表中只显示固定设备，可以访问设备详情、实时心电、日历、历史心电、定位轨迹功能画面，开发人员调查用功能和临时参数信息显示部分功能需要屏蔽掉。
    let isExternal = false;
    if (sessionUser && sessionUser.roles) {
        for (let role of sessionUser.roles) {
            if (role.code === RoleCode.Enum.External) {
                isExternal = true;
                break;
            }
        }
    }

    let menuItems = [];
    let menuItemMap = {};

    let menuHome = RoutePath.APP;
    menuItemMap[menuHome.url] = menuHome;

    if (!isExternal) {
        // 监控
        let menuMonitor = RoutePath.MONITOR;
        menuMonitor.children = [];

        menuItems.push(menuMonitor);
        menuItemMap[menuMonitor.url] = menuMonitor;

        let menuMonitorRegion = RoutePath.MONITOR_REGION;
        menuMonitor.children.push(menuMonitorRegion);
        menuItemMap[menuMonitorRegion.url] = menuMonitorRegion;
    }

    // 设备管理
    let menuDevice = RoutePath.DAS_DEVICE;
    menuDevice.children = [];
    menuItems.push(menuDevice);
    menuItemMap[menuDevice.url] = menuDevice;

    let menuDeviceList = RoutePath.DAS_DEVICE_LIST;
    menuDevice.children.push(menuDeviceList);
    menuItemMap[menuDeviceList.url] = menuDeviceList;

    //定位轨迹
    // let menuDeviceMonitor = RoutePath.DAS_DEVICE_MONITOR;
    // menuDevice.children.push(menuDeviceMonitor);
    // menuItemMap[menuDeviceMonitor.path] = menuDeviceMonitor;


    // let menuDeviceCalendar = RoutePath.ECG_HISTORY_CALENDAR;
    // menuDevice.children.push(menuDeviceCalendar);
    // menuItemMap[menuDeviceCalendar.url] = menuDeviceCalendar;


    if (!isExternal) {
        let menuDeviceDataDownload = RoutePath.DAS_DEVICE_DATA_DOWNLOAD;
        menuDeviceDataDownload.children = [];
        menuItems.push(menuDeviceDataDownload);
        menuItemMap[menuDeviceDataDownload.url] = menuDeviceDataDownload;

        let menuDeviceDownload = RoutePath.DAS_DEVICE_DOWNLOAD;
        menuDeviceDataDownload.children.push(menuDeviceDownload);
        menuItemMap[menuDeviceDownload.url] = menuDeviceDownload;

        let menuDeviceDataServerDownload = RoutePath.DAS_DEVICE_DATA_SERVER_DOWNLOAD;
        menuDeviceDataDownload.children.push(menuDeviceDataServerDownload);
        menuItemMap[menuDeviceDataServerDownload.url] = menuDeviceDataServerDownload;

        let menuAPPLogeDownload = RoutePath.CMS_CONTENT_DOWNLOAD;
        menuDeviceDataDownload.children.push(menuAPPLogeDownload);
        menuItemMap[menuAPPLogeDownload.url] = menuAPPLogeDownload;

        let menuPressureLogeDownload = RoutePath.CMS_CONTENT_DOWNLOAD_PRESSURE;
        menuDeviceDataDownload.children.push(menuPressureLogeDownload);
        menuItemMap[menuPressureLogeDownload.url] = menuPressureLogeDownload;

        // 设备用户管理
        let menuDeviceUserManagement = RoutePath.DAS_BINDER;
        menuDeviceUserManagement.children = [];
        menuItems.push(menuDeviceUserManagement);
        menuItemMap[menuDeviceUserManagement.url] = menuDeviceUserManagement;

        let menuBinderList = RoutePath.DAS_BINDER_LIST;
        menuDeviceUserManagement.children.push(menuBinderList);
        menuItemMap[menuBinderList.url] = menuBinderList;

        let menuGuardianList = RoutePath.DAS_BINDER_GUARDIAN;
        menuDeviceUserManagement.children.push(menuGuardianList);
        menuItemMap[menuGuardianList.url] = menuGuardianList;

        let menuBinderTerminalList = RoutePath.DAS_BINDER_TERMINAL_LIST;
        menuDeviceUserManagement.children.push(menuBinderTerminalList);
        menuItemMap[menuBinderTerminalList.url] = menuBinderTerminalList;

        // 病例管理
        // let menuECGCaseManagement = RoutePath.ECG_CASE_MANAGEMENT;
        // menuECGCaseManagement.children = [];
        // menuItems.push(menuECGCaseManagement);
        // menuItemMap[menuECGCaseManagement.url] = menuECGCaseManagement;

        //个人中心
        let menuOneselfUserManagement = RoutePath.ONESELF_CENTER;
        menuOneselfUserManagement.children = [];
        menuItems.push(menuOneselfUserManagement);
        menuItemMap[menuOneselfUserManagement.url] = menuOneselfUserManagement;

        let menuOneselfDeviceInfo = RoutePath.DAS_BINDER_ONESELF;
        menuOneselfUserManagement.children.push(menuOneselfDeviceInfo);
        menuItemMap[menuOneselfDeviceInfo.url] = menuOneselfDeviceInfo;

        let menuUserList = RoutePath.DAS_BINDER_USER;
        menuOneselfUserManagement.children.push(menuUserList);
        menuItemMap[menuUserList.url] = menuUserList;

        // let menuContentList = RoutePath.DAS_BINDER_DATA;
        // menuDeviceUserManagement.children.push(menuContentList);
        // menuItemMap[menuContentList.url] = menuContentList;

        // 软件管理
        // let menuContentManagement = RoutePath.CMS_SOFTWARE_MANAGEMENT;
        // menuContentManagement.children = [];
        // menuItems.push(menuContentManagement);
        // menuItemMap[menuContentManagement.path] = menuContentManagement;


        /*let menuLatestVersionDownload = RoutePath.CMS_SOFTWARE_LATEST_VERSION_DOWNLOAD;
        menuItemMap[menuLatestVersionDownload.path] = menuLatestVersionDownload;*/

        // 内容草稿
        let menuContentDrafts = RoutePath.CMS_CONTENT_DRAFTS;
        menuItemMap[menuContentDrafts.path] = menuContentDrafts;
        // 内容创建
        let menuContentsNew = RoutePath.CMS_CONTENT_CREATE;
        menuItemMap[menuContentsNew.path] = menuContentsNew;

        // menuRest.children.push(RoutePath.T_P_Y_S3)
        // menuItemMap[RoutePath.T_P_Y_S3.path] = RoutePath.T_P_Y_S3

        //个人中心

        // 仪表盘管理
        /*let menuDashboard = RoutePath.DAS_DASHBOARD;
        menuDashboard.children = [];

        menuItems.push(menuDashboard);
        menuItemMap[menuDashboard.url] = menuDashboard;

        let menuDashboardStatistics = RoutePath.DAS_DASHBOARD_STATISTICS;
        menuDashboard.children.push(menuDashboardStatistics);
        menuItemMap[menuDashboardStatistics.url] = menuDashboardStatistics;

        /!*let menuDashboardMonitor = RoutePath.DAS_DASHBOARD_MONITOR;
        menuDashboard.children.push(menuDashboardMonitor);
        menuItemMap[menuDashboardMonitor.url] = menuDashboardMonitor;*!/

        // NCOV监控
        let menuDashboardNCOV = RoutePath.DAS_DASHBOARD_NCOV;
        menuDashboard.children.push(menuDashboardNCOV);
        menuItemMap[menuDashboardNCOV.url] = menuDashboardNCOV;

        // NCOV数据导入
        let menuDashboardNCOVImport = RoutePath.DAS_DASHBOARD_NCOV_IMPORT;
        menuDashboard.children.push(menuDashboardNCOVImport);
        menuItemMap[menuDashboardNCOVImport.url] = menuDashboardNCOVImport;

        // 区域地图
        let menuDashboardDistrictMap = RoutePath.DAS_DASHBOARD_DISTRICT_MAP;
        menuDashboard.children.push(menuDashboardDistrictMap);
        menuItemMap[menuDashboardDistrictMap.url] = menuDashboardDistrictMap;*/

        // 心电图管理
        /*let menuEcg = RoutePath.DAS_ECG;
        menuEcg.children = [];
        menuItems.push(menuEcg);
        menuItemMap[menuEcg.url] = menuEcg;

        // menuEcg.children.push(RoutePath.DAS_ECG_MINE);
        // menuItemMap[RoutePath.DAS_ECG_MINE.url] = RoutePath.DAS_ECG_MINE;

        // let menuEcgLocal = RoutePath.DAS_ECG_HISTORY;
        // menuEcg.children.push(menuEcgLocal);
        // menuItemMap[menuEcgLocal.url] = menuEcgLocal;

        let menuEcgFileCalendar = RoutePath.ECG_FILE_CALENDAR;
        menuEcg.children.push(menuEcgFileCalendar);
        menuItemMap[menuEcgFileCalendar.url] = menuEcgFileCalendar;

        let menuEcgRealTIme = RoutePath.DAS_ECG_REAL_TIME;
        menuEcg.children.push(menuEcgRealTIme);
        menuItemMap[menuEcgRealTIme.url] = menuEcgRealTIme;*/

        //let menuSystemAdminAdd = RoutePath[RoutePathKey.SYS_USER_ADD];
        //let menuSystemParams = RoutePath[RoutePathKey.SYS_PARAMS];
        //let menuSystemLogs = RoutePath[RoutePathKey.SYS_LOGS];
        //menuSystem.children = [];
        //menuSystem.children.push(menuSystemAdminAdd);
        //menuSystem.children.push(menuSystemParams);
        //menuSystem.children.push(menuSystemLogs);

        // let menuDataAnalyze = RoutePath[RoutePathKey.DATA_ANALYZE];
        // let menuDataReport = RoutePath[RoutePathKey.DATA_REPORT];
        // let menuDataUsing = RoutePath[RoutePathKey.DATA_USAGE];
        // let menuDataSymptoms = RoutePath[RoutePathKey.DATA_SYMPTOMS];
        // menuDataAnalyze.children.push(menuDataReport);
        // menuDataAnalyze.children.push(menuDataUsing);
        // menuDataAnalyze.children.push(menuDataSymptoms);

        // menuContentManagement.children.push(menuContentDrafts);
        // menuContentManagement.children.push(menuContentsNew);

        let menuSysManagement = RoutePath.SYS_MANAGEMENT;
        menuSysManagement.children = [];

        // menuSysManagement.children.push(RoutePath.ORG_EMPLOYEE_LIST);
        //
        menuItems.push(menuSysManagement);
        menuItemMap[menuSysManagement.path] = menuSysManagement;


        // 最新版本下载
        let menuLatestVersionDownload = RoutePath.CMS_SOFTWARE_LATEST_VERSION_DOWNLOAD;
        menuSysManagement.children.push(menuLatestVersionDownload);
        menuItemMap[menuLatestVersionDownload.path] = menuLatestVersionDownload;

        // 增加内容管理子菜单
        // 内容一览
        let menuContents = RoutePath.CMS_SOFTWARE_VERSION_MANAGEMENT;
        menuItemMap[menuContents.path] = menuContents;
        menuSysManagement.children.push(menuContents);

        menuSysManagement.children.push(RoutePath.ECG_SYSTEM_REDIS);
        menuItemMap[RoutePath.ECG_SYSTEM_REDIS.path] = RoutePath.ECG_SYSTEM_REDIS;

        menuSysManagement.children.push(RoutePath.SYS_SETTING);
        menuItemMap[RoutePath.SYS_SETTING.path] = RoutePath.SYS_SETTING;
        // menuItemMap[RoutePath.ORG_EMPLOYEE_LIST.path] = RoutePath.ORG_EMPLOYEE_LIST;
        //
        // let menuUserSetting = RoutePath[RoutePathKey.ACCOUNT_SETTING];
        // menuUserSetting.children = [];
        // let menuUserProfile = RoutePath[RoutePathKey.ACCOUNT_PROFILE];
        // let menuUserPassword = RoutePath[RoutePathKey.ACCOUNT_PASSWORD];
        // menuUserSetting.children.push(menuUserProfile);
        // menuUserSetting.children.push(menuUserPassword);

        //医生端
        let menuDouManagement = RoutePath.DOCTOR_MANAGEMENT;
        menuDouManagement.children = [];
        menuItems.push(menuDouManagement);
        menuItemMap[menuDouManagement.path] = menuDouManagement;

        // 医师报告
        let menuECGCasePhysiciansReport = RoutePath.ECG_PHYSICIANS_REPORT;
        menuDouManagement.children.push(menuECGCasePhysiciansReport);
        menuItemMap[menuECGCasePhysiciansReport.url] = menuECGCasePhysiciansReport;

        // 病例列表
        let menuECGCaseList = RoutePath.ECG_CASE_LIST;
        menuDouManagement.children.push(menuECGCaseList);
        menuItemMap[menuECGCaseList.url] = menuECGCaseList;


        // 标注工具
        let menuAnnotationTool = RoutePath.ECG_ANNOTATION_TOOL;
        menuItemMap[menuAnnotationTool.path] = menuAnnotationTool;
        menuDouManagement.children.push(menuAnnotationTool);

        menuDouManagement.children.push(RoutePath.DOCTOR_DIAGNOSTIC_RECORD)
        menuItemMap[RoutePath.DOCTOR_DIAGNOSTIC_RECORD.path] = RoutePath.DOCTOR_DIAGNOSTIC_RECORD

        // 其他
        let menuRest = RoutePath.REST;
        menuRest.children = [];
        menuItems.push(menuRest);
        menuItemMap[menuRest.path] = menuRest;

        let menuRestNCOV = RoutePath.REST_NCOV;
        menuRest.children.push(menuRestNCOV);
        menuItemMap[menuRestNCOV.url] = menuRestNCOV;

        let menuRestNCOVImport = RoutePath.REST_NCOV_IMPORT;
        menuRest.children.push(menuRestNCOVImport);
        menuItemMap[menuRestNCOVImport.url] = menuRestNCOVImport;

        menuRest.children.push(RoutePath.T_P_Y_S)
        menuItemMap[RoutePath.T_P_Y_S.path] = RoutePath.T_P_Y_S

        menuRest.children.push(RoutePath.T_P_Y_S2)
        menuItemMap[RoutePath.T_P_Y_S2.path] = RoutePath.T_P_Y_S2

    }
    //this.setState({menuItems: [menuDevice,  menuUser, menuEcg, menuSystem, menuDataAnalyze, menuUserSetting]});
    //
    //lastSelectedMenu = menuDevice;
    //lastSelectedMenu.isSelected = true;
    return {
        type: ActionTypes.APP_GET_MAIN_MENU,
        menuItems: menuItems,
        menuItemMap: menuItemMap
    }
}