/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    Modal,
    Row,
    Col,
    Icon,
} from "antd";

import {
    refreshDeviceMonitorColumns, searchDeviceList,
    toggleFieldSelectModal
} from "../../../actions/DeviceAction";
import {CACHE_DEVICE_MONITOR_COLUMNS} from "../../../../../constants/Profile";
import {ReactSortable} from "react-sortablejs";

class FieldSelectModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }

    };

    componentWillMount() {
        const deviceMonitorColumns = JSON.parse(localStorage.getItem(CACHE_DEVICE_MONITOR_COLUMNS));
        this.props.dispatch(refreshDeviceMonitorColumns(deviceMonitorColumns));
    }

    _handleCancel() {
        this.props.dispatch(toggleFieldSelectModal(false));
        const deviceMonitorColumns = JSON.parse(localStorage.getItem(CACHE_DEVICE_MONITOR_COLUMNS));
        this.props.dispatch(refreshDeviceMonitorColumns(deviceMonitorColumns));
    }

    _handleSave() {
        localStorage.setItem(CACHE_DEVICE_MONITOR_COLUMNS, JSON.stringify(this.props.deviceMonitorColumns));
        this.props.dispatch(searchDeviceList(this.props.searchCondition, this.state.formatMessage));
        this.props.dispatch(toggleFieldSelectModal(false));
    }

    _handleSort(newSortList) {
        this.props.dispatch(refreshDeviceMonitorColumns(Object.assign([], newSortList)));
    }

    _handleToggleVisible(index, visible) {
        const deviceMonitorColumns = Object.assign([], this.props.deviceMonitorColumns);
        deviceMonitorColumns[index].visible = visible;
        this.props.dispatch(refreshDeviceMonitorColumns(deviceMonitorColumns));
    }

    render() {

        const formatMessage = this.state.formatMessage;
        console.log("deviceMonitorColumns:",this.props.deviceMonitorColumns)
        return (
            <Modal
                title={formatMessage({id: "DAS_DEVICE_TITLE_FIELD_SELECT"})}
                maskClosable={false}
                style={{top: '20px'}}
                visible={this.props.showFieldSelectModal}
                onOk={() => this._handleSave()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SAVE"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <ReactSortable Direction
                               list={this.props.deviceMonitorColumns}
                               setList={(newSortList) => this._handleSort(newSortList)}
                               ghostClass='background-sort-item'
                               dragClass='drag-sort-item'
                style={{height: '600px', overflow:'scroll'}}>
                    {this.props.deviceMonitorColumns.map((item, index) => {
                        const bgColor = item.visible ? '#ffffff' : '#cccccc';
                        return (
                            <Row className="sort-item" key={index} style={{backgroundColor: bgColor}}>
                                <Col span={4}>{+(index + 1)}</Col>
                                <Col span={16}>{item.title}</Col>
                                <Col span={2} className="text-center">
                                    {
                                        item.visible ?
                                            <Icon type="eye" onClick={() => this._handleToggleVisible(index, false)}/> :
                                            <Icon type="eye-invisible"
                                                  onClick={() => this._handleToggleVisible(index, true)}/>
                                    }
                                </Col>
                                <Col span={2} className="text-center">
                                    <Icon type="drag"/>
                                </Col>
                            </Row>
                        )
                    })}
                </ReactSortable>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showFieldSelectModal: store.EcgDeviceReducer.showFieldSelectModal,
        searchCondition: store.EcgDeviceReducer.searchCondition,
        deviceMonitorColumns: store.EcgDeviceReducer.deviceMonitorColumns
    }
}

export default connect(mapStateToProps)(injectIntl(FieldSelectModal));