/**
 * Created by Ethan on 17/3/16.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {searchDeviceList} from "./DeviceAction";
import {getBinderDevices, toggleDeviceBindModal} from "./BinderAction";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_GUARDER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleBinderListModal(isShow) {
    return {
        type: ActionTypes.ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL,
        showBinderListModal: isShow
    }
}

export function refreshGuarder(guarder) {
    return {
        type: ActionTypes.ECG_GUARDER_REFRESH_GUARDER,
        guarder: guarder
    }
}

export function searchGuardians(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchGuarderList, searchCondition, (response) => {
            dispatch({
                type: ActionTypes.ECG_GUARDER_SEARCH,
                guardians: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getGuarderBinders(guarderUserId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: true});

        const requestUrl = WebService.getGuarderBinders.replace("{userId}", guarderUserId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_GUARDER_REFRESH_BINDERS,
                guarderBinders: response.data
            });

            dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function unbindBinder(guarderUserId, binderId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        let requestUrl = WebService.unbindBinder.replace("{userId}", guarderUserId);
        requestUrl = requestUrl.replace("{binderId}", binderId);
        HttpUtils.delete(requestUrl, {}, (response) => {
            dispatch(getGuarderBinders(guarderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_GUARDER_MSG_UNBIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteGuarder(id, searchCondition, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteGuarder.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(searchGuardians(searchCondition, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}