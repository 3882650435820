/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Row, Col, Form, Button, Select, Checkbox, Badge, Icon, Switch, Menu, DatePicker, Popover, Timeline} from 'antd';

import message from "../../../../../components/toast";
import {
    LiveDataChannel,
    ChartSpeed,
    EcgFileType,
    ProtocolPackageType,
    VoltDiv,
    CH,
    AnnotationFileSourceType,
    EcgFileEventType, PageScanTime, PageScanResize
} from "../../../Enums";
import {getAllDevices, getDeviceById, toggleDetailModal} from "../../../actions/DeviceAction";
import ECGData from "../../../entities/ECGData";
import {ECG_SOCKET_SERVER_HOST, LOG_SOCKET_SERVER_HOST} from "../../../../../constants/Profile";
import ECGPoint from "../../../entities/ECGPoint";
import LogListModal from "./LogListModal";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {
    FORMAT_DATE_HYPHEN,
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_FULL_SIMPLE,
    FORMAT_DATE_TIME_FULL_SLASH, FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SLASH, FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import HttpUtils from "../../../../../utils/HttpUtils";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import DetailModal from "../../Device/Monitor/DetailModal";
import {initDeviceLogs} from "../../../actions/LogDataServerAction";
import DeviceDetailDrawer from "./DeviceDetailDrawer";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import WebSocketUtils from "../../../utils/WebSocketUtils";
import ProtocolCommonHeader from "../../../entities/ProtocolCommonHeader";
import ProtocalHistoryData from "../../../entities/ProtocalHistoryData";
import EcgFileCommonHeader from "../../../entities/EcgFileCommonHeader";
import EcgFileMixData from "../../../entities/EcgFileMixData";
import EcgFileLiveData from "../../../entities/EcgFileLiveData";
import TimeIndexSlider from "../../ECG/History/Day/TimeIndexSlider";
import locale from "antd/lib/date-picker/locale/zh_CN";
import DoubleCalendar from "../../ECG/History/Day/DateIndexCalendar";
import {getAnnotation, searchAnnotations, toggleAnnotationModal} from "../../../actions/DeviceFileAnnotationAction";
import EcgFileDateIndex from "../../../entities/EcgFileDateIndex";
import {
    readAnnotationFile,
    readMixIndexFile,
    refreshEcgDataIndexEffectiveRecords,
    refreshEcgDateIndexData,
    refreshEcgDateIndexRecordMap,
    refreshEcgEventIndexData,
    refreshEcgTimeIndexData
} from "../../../actions/HistoryAction";
import EcgFileTimeIndex from "../../../entities/EcgFileTimeIndex";
import EcgFileEventIndex from "../../../entities/EcgFileEventIndex";
import HexUtils from "../../../../../utils/HexUtils";
import EventIndexTimeLine from "../../ECG/History/Day/EventIndexTimeLine";
import EnumItemLabel from "../../../../../components/EnumItemLabel";
import {RoutePath} from "../../../../../constants/RoutePath";

// 一个导联的svg图高度
let winH;
//页扫描一个导联的高度
let pageScanWinH = 22;
// 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
let winW;
let pageScanWinW

// 采样率
// 标准值：500samples/s
// 可选值：250samples/s，1000samples/s
const sampRate = 500;

// 下采样率，默认值5，从设备中获取后改变
let downRatio = 1;

// 通过采样率和下采样率的关系，算出每秒实际多少个点
let dataRate = sampRate / downRatio;

// 格子尺度【GridScale】
// 	5个小格构成一个大格
// 标准值：1mm/小格
// 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
// 	例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
// 此处默认以大格子作为显示单位
const gridScale = 5;

// 格子的缩放
const gridRatio = 1;

// 绘图的缓冲区
let clearDrawBufferFlag = false;

// 走纸速度, 标准值：25mm/s
// 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
const chartSpeed = 25;

// 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
let gridPointNum = dataRate / (chartSpeed / gridScale);

// 播放的走点数, 每次更新的数据点的数量
// 对应的文档上的参数名是RfsNum
let playStep = 5;

// 历史心电文件的Web Socket连接对象
let webSocket;
// 时间索引文件对象
let ecgFileTimeIndex;
// 事件索引文件对象
let ecgFileEventIndex;

// 播放的速度，即刷新点的频率
let playSpeed = 1000 / (dataRate / playStep);

// // 一个格子代表0.5mV
// const gridVolt = 1 / (voltDiv / gridScale);

// Gain=2^23*6/2500=20132.6592 1/mV
// const gain = 20132.6592;
// const gain = 20132.6592 / 4;

// 其中Ga是ADS1298的增益
const ga_ch1 = 4;
const ga_ch2_8 = 6;

// Gb为王博除基算法lib的增益，目前为1
const gb = 1;

// 增益系数【Gc】
// 增益系数Gc的计算公式为：
//         5000/Ga*Gc=(2^24)*Gb；
//         所以Gc=(2^24)*Ga*Gb/5000
// 为了在APP、Web上进行缩放显示，显示前，APP/Web可能对数据进行缩放，该缩放系数为Gf。
// 	 定义Gd=Gc*Gf。
//
//         其中Ga为ADS1298的配置增益，
//                Gb为王博除基算法lib的增益，目前为1   @王博
// N表示表示数据的bit数。
const gc_ch1 = (Math.pow(2, 24) * ga_ch1 * gb) / 5000;
const gc_ch2_8 = (Math.pow(2, 24) * ga_ch2_8 * gb) / 5000;

let height;

let gf_ch1;
let gf_ch2_8;

//  为了在APP、Web上进行缩放显示，显示前，APP/Web可能对数据进行缩放，该缩放系数为Gf。
// 	定义Gd=Gc*Gf。
let dg_ch1;
let dg_che2_8;

// 算出横向格子数
let xCellCount;
let pageScanXCellCount;
// 每个导联占据纵向格子数
const yCellCount = 6;

// 算出每个格子的宽度
let cellWidth;
let pageScanCellWidth;
// 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
// 计算得出点之间的x间距
let pointSpace;
let pageScanPointSpace;

// 算出0点，以下是负值，以上是正直，需要变换数据
let zeroPointY;
let pageScanZeroPointY;

let websocketApp;
let logWebSocket;

// 绘图的缓冲区
// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
// 1、	开一个总长为A4的FIFO/Buffer，4个参数A4\A3\A2\A1。数据从右侧进来

// 为了浮动计算rfsNum，需要计算每秒的刷新效率是否达到预期
let winFpsDeviceCount = 1;
let winFpsDeviceCountResult = 25;
let winFpsAppCount = 1;
let winFpsAppCountResult = 25;

// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
let drawBufferMap = {};
// 页扫描需要单独使用一个缓存
let pageScanDrawBufferMap = {};
// 记录Web Socket的命令列表，防止重复发送指令
let webSocketCommandHistory = [];

// 8个导联的数据
const numLeads = 8;

// 窗口刷新率，每40ms刷新一次走纸
const winFps = 40;
//每次更新的数据点的数量
let baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
let baseRfsNumApp = (sampRate / downRatio) / (1000 / winFps);
let rfsNum = baseRfsNum;
let rfsNumApp = baseRfsNumApp;

// 定标一个div为100mm宽度，算出相对应的pixel
const unitWidthMill = 1;

const gc = 20132.66;

// 绘图窗口的Y轴Margin
const winMarginX = 40;
const winMarginY = 20;


// 缓冲容器的尺寸，比如缓冲一屏
// 3、	A2是缓冲长度，就和看电影一开始要缓冲一段一样。
// 开始采集实时数据后，当数据点数量>=A2时，开始波形描绘。
let bufferSizeA2;
// 缓存阈值
// 	两个因素造成当前FIFO中数据数量N的波动：
// 1）通信造成的数据波动。【主要因素】
// 2）随着时间流逝，异步系统时钟误差造成数据+1或-1。【异步时钟问题】
// 所以N会变化。
// 处理方式很简单：
// 1）A1≤N≤A3   （即在蓝色框范围内）      每次取RfsNum=4个点描绘
// 2）A1>N       （即在蓝色框右侧）         每次取RfsNum-1=3个点描绘
// 3）A3<N       （即在蓝色框左侧）       每次取RfsNum+1=5个点描绘
let bufferThresholdA1;
let bufferThresholdA3;

// 用作Undo/Redo的操作历史列表
// 为了实现Undo，Redo，需要把步骤都保存到该列表中
// 对象结构如：{action:createAnnotation, data:{before:annotation, after: annotation}}
let undoList = [];
let redoList = [];

let manualStopSearch = false;
// 是否手动关闭了log记录操作
let manualStopWriteLog = false;

// 记录最后一次接收到数据的live id 和 tcp id
let latestLiveId = -1;
let latestTcpId = -1;
let latestLiveIdApp = -1;
let latestTcpIdApp = -1;
// SVG 线的背景颜色
const lineColors = ['#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'
    , '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000', '#000000'];

// 一个心电文件的样本数，解析出心电历史文件时赋值，
// 在计算FrameSelectModal的开始位置和结束位置时需要用到
let sampleNum = 0;

// 一屏幕能绘画的点数量
let screenPointSum;
let pageScanScreenPointSum;

// 缓冲区大小过低的标识，只有达到一定的缓冲值，才开始描画
let bufferLowFlag = false;
let bufferLowFlagApp = false;
//控制合并画布bug
let marginTopValue = 10;
let eventIndex = 1;
let isOne = true;
//是否开启单导联模式
let CHFlag = false;
//导联选择
let ChValue = 0;
//页扫描情况下的导联选择
let pageScanChValue = 2;
//需要的文件数量
let pageScanRizeValue = 5;

//记录所有的异常事件
let eventList = [];

//开启页扫描
let pageScanFlag = false
//大小
let PageScanResizeValue = 1;
//一个页面展示多少条数据
let pageScanPM = 10;
//多少条数据
let PageScanTimeValue = 10;
//需求播放总时长
let pageScanELenght = 1;
//
let pageScanValueSize = 0;
//判断翻页 在哪一页
let pageScanValue = 0;

let eventTimeIndex = 1;
//一条导联多少秒数据
let pagePointSum = 0
//一共多少条数据
let pageDataLenght = 0
let aa = 0;
let timeValue = "2022/06/01 "
let cunchuValue;
let eventFlag = {};
let wuchaTime = {};
//关于时间的集合
let wuchaTimeValue = {}
let isFirst = true;
//第几个文件是错开的
let cuokaiFileValue = 0;
let cuokaiFileValue2 = 0;
//文件错开的合集
let cuokaiFileValues = [];
//文件长度
const eventFileLenght = 15.872 * 500;
//判断文件是否错开过
let cuokaiFlag = false;
//判断绘图的时候是否换行了
let huanhangFlag = false;
//记录
let huanhangValue = 0;
let huanhangValue2 = 0
//错开总时间
let cuokaiTimeValues = 0;
//判断绘图到第几行
let aaa = 0;
//判断第几页有换行
let aaaa = 0;
//时间还是事件
let caseListFlag = false
let caseBiaoJi = -1
//当前获取文件标识
let evenFileIndex = 0;
//实际获取文件标识
let evenFileIndexValue = 0;
let startTime = 0;

let initIndex;
let initHistoryIndex;
let historyFileIndex;

const heartCheck = {
    //计时器设定为30s
    timeout: 30000, timeoutObj: null, serverTimeoutObj: null,

    //重置
    reset: function () {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        this.start();
    },

    //开始
    start: function () {
        this.timeoutObj = setTimeout(function () {
            webSocket.send("HB");
        }, this.timeout);
    }
};

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            searchFlag: false,
            channelType: "0",
            //展示类型
            lookUpType: "事件内容：",
            timeFileIndex: 0,
            eventFileIndex: 0,
            sliderValue: 0,
            recordDate: "",
            playFlag: false, // 是否以滑顺缓存的方式绘制的标识
            drawBufferFlag: true,
            backgroundDisplay: true, // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // 一个格子是5mm，所以一个格子代表0.5mv
            voltDiv: 10,
            illness: "心电波形", // 走纸倍数
            chartSpeedTimes: 1, // 警告数据，如果live id，tcp id不连续，需要写入警告日志
            logs: [], // Web和APP端数据传输的警告信息
            logsWeb: [],
            logsApp: [],
            showLogModal: false,
            receiveDataWrite: false, //合并画图是否开启
            combinePaint: false, // 记录绘制掉的点，以供Debug比较
            writeLogFlag: false,
            writeLogSocketStatus: "", // 记录数据时间
            receiveDrawTime: false, // 记录描绘动态参数
            drawParamsWrite: false, // 本地时钟的测试
            localClockWrite: false,
            toggleDeviceDetail: false,
            rangeStartDate: "",
            rangeEndDate: "",
            eventIndexTimeLineVisible: true,
            bufferLengthWeb: 0,
            bufferLengthApp: 0,
            rfsNum: 0,
            CHFlag: false,
            eventTimeIndex: 0,
            PageScanTimeValue: 0,
            PageScanResizeValue: 1,
            caseList: "",
            caseBiaoJi: 0,
            height: "100%"
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllDevices(this.state.formatMessage));
        this._setQueryState()
    }

    _setQueryState() {

        this.setState({
            recordDate: "20220603",
            deviceCode: "72180000",
            timeFileIndex: 1,
            eventFileIndex: 1,
            rangeStartDate: "20220603",
            rangeEndDate: "20220603"
        })
    }

    //webSocket 通讯接收文件
    _handleSearch = (initializeFlag) => {
        webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {

            // 打开一个连接
            // message.success("链接服务器成功：" + e.target.url);
            WebSocketUtils._sendHello(webSocket, "72180000");//deviceCode
        };
        webSocket.onmessage = (e) => {
            // if (this.isHeartBeat(e.data)) {
            //     //无论收到什么信息，说明当前连接正常，将心跳检测的计时器重置
            //     heartCheck.reset();
            //     return;
            // }

            const dataArray = new Int8Array(e.data);

            console.log("e.data:", e.data, dataArray);
            if (dataArray[11] == 63) {
                message.success("获取数据结束")
                this._refreshECGView3()
            }
            let startPos = 0;

            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            // console.log(protocolCommonHeader);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                message.success("连接成功，开始发送获取日期索引文件文件的指令");
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType);
                return;
            }

            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.CommonReply) {
                // 0x0000 通用成功
                // 0xAAEF 超时
                if (dataArray[14] !== 0 && dataArray[15] !== 0) {
                    message.error("服务器处理数据超时");
                    // if (lastFetchHistoryFileIndex > 0) {
                    //     WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, lastFetchHistoryFileIndex++, webSocketCommandHistory);
                    // }
                    // return;
                }
            }

            // 服务器向客户端发送对应的文件。【上行】
            // PacketType	1B	0x33：文件请求响应	包类型
            // 其他类型的包，不需要处理
            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                return;
            }

            // 解析历史文件里的data数据
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const historyData = new ProtocalHistoryData(dataArray, startPos);

            startPos = startPos + historyData.length;
            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            // console.log(ecgFileCommonHeader);


            startPos = startPos + ecgFileCommonHeader.length;
            switch (historyData.fileType) {
                case EcgFileType.EnumInt.DATEIDX:
                    console.log("日期索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    message.success("日期索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    const ecgFileDateIndex = new EcgFileDateIndex(dataArray, startPos);
                    console.log(ecgFileDateIndex);
                    const ecgDateIndexRecordMap = {};
                    const ecgDataIndexEffectiveRecords = [];
                    for (let item of ecgFileDateIndex.records) {
                        ecgDateIndexRecordMap[item.recordDate] = item;
                        if (item.timeNum > 0) {
                            // 有心电数据的记录才需要加入显示队列
                            ecgDataIndexEffectiveRecords.push(item);
                        }
                    }
                    this.props.dispatch(refreshEcgDataIndexEffectiveRecords(ecgDataIndexEffectiveRecords));
                    this.props.dispatch(refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap));
                    this.props.dispatch(refreshEcgDateIndexData(ecgFileDateIndex));

                    console.log("日期索引文件解析完成，开始发送获取时间索引文件的指令，timeFileIndex=" + this.state.timeFileIndex);
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.TIMEIDX, this.state.timeFileIndex);//this.state.timeFileIndex
                    break;
                case EcgFileType.EnumInt.TIMEIDX:
                    console.log("时间索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex)
                    message.success("时间索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);

                    ecgFileTimeIndex = new EcgFileTimeIndex(dataArray, startPos);
                    console.log(ecgFileTimeIndex);

                    const slideMarks = {};
                    slideMarks[0] = "00:00";
                    slideMarks[Number(ecgFileTimeIndex.timeSliceNum)] = "23:59";
                    for (let i = 1, x = 0, z = 0; i == Math.round(Number(ecgFileTimeIndex.timeSliceNum) / (23 * 4)); i++) {
                        x++;
                        if (x % 4 == 0 && x != 0) {
                            slideMarks[i] = z++;
                        }else{
                            slideMarks[i] = " "
                        }
                    }
                    // console.log(slideMarks);
                    this.props.dispatch(refreshEcgTimeIndexData(ecgFileTimeIndex, slideMarks));

                    // message.success("时间索引文件解析完成，开始发送获取事件索引文件的指令");
                    console.log("日期索引文件解析完成，开始发送获取事件索引文件的指令，timeFileIndex=" + this.state.eventFileIndex);
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.EVNTIDX, this.state.eventFileIndex);//this.state.eventFileIndex

                    this.props.dispatch(searchAnnotations(this.state.deviceCode, this.state.recordDate, this.state.formatMessage));
                    break;
                case EcgFileType.EnumInt.EVNTIDX:
                    console.log("事件索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex)
                    message.success("事件索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    ecgFileEventIndex = new EcgFileEventIndex(dataArray, startPos);
                    this.props.dispatch(refreshEcgEventIndexData(ecgFileEventIndex));

                    // 由于HistoryFileIndex从1开始编号，如HistoryFileIndex=0，表示当天没有历史数据文件产生。
                    // 所以获取一个有效的起始索引
                    let index = 0;
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        const record = ecgFileTimeIndex.records[i];
                        if (record.historyFileIndex > 0) {
                            index = i;
                            historyFileIndex = record.historyFileIndex;
                            initIndex = i;
                            initHistoryIndex = record.historyFileIndex;
                            this.setState({sliderValue: i});
                            // 第一个历史文件先获取了
                            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
                            break;
                        }
                    }

                    break;
                case EcgFileType.EnumInt.MIXDATA:

                    // 心电图的点数据加入缓冲区
                    if (clearDrawBufferFlag) {
                        drawBufferMap = {};
                        webSocketCommandHistory = [];
                        pageScanDrawBufferMap = {};
                    }
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        if (ecgFileTimeIndex.records[i].historyFileIndex === historyData.fileIndex) {
                            evenFileIndex++
                            message.success("历史心电文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                            const record = ecgFileTimeIndex.records[i];
                            // console.log("record")
                            // console.log(record)
                            const ecgFileMixData = new EcgFileMixData(dataArray, startPos, i, historyData.fileIndex, record.eventRecordIndex,);
                            // console.log(ecgFileMixData)
                            const ecgPointPolymRegion = ecgFileMixData.polymRegions[0];
                            // console.log(ecgPointPolymRegion)
                            for (let i = 0; i < ecgPointPolymRegion.channelNum; i++) {
                                if (drawBufferMap[i]) {
                                    drawBufferMap[i] = drawBufferMap[i].concat(ecgPointPolymRegion.ecgData[i]);
                                } else {
                                    drawBufferMap[i] = ecgPointPolymRegion.ecgData[i];
                                }
                            }
                            console.log("historyData", historyData, aa++, ecgFileTimeIndex, ecgFileMixData)
                            //当前时间
                            startTime = new Date(
                                moment(ecgFileMixData.startTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_SLASH)).getTime()
                            // new Date(timeValue +
                            clearDrawBufferFlag = false;
                            if (initializeFlag) {
                                // message.success("历史心电文件解析完成，开始描绘心电图");
                                this._refreshECGView();
                                this._addAnnotationEvent();
                            }
                            break;
                        }
                    }
                    console.log("eventFileIndex:", Math.ceil(pageScanELenght / 15.872), evenFileIndex, pageScanFlag, evenFileIndexValue)
                    if (pageScanFlag && Math.ceil(pageScanELenght / 15.872) <= evenFileIndex) {
                        console.log("成功运行")
                        this._refreshECGView3()
                    } else {
                        evenFileIndexValue++;
                        if (Math.ceil(pageScanELenght / 15.872) == evenFileIndexValue - 1) {
                            message.success("一共获取到了" + evenFileIndex + "个文件")
                        }
                    }


                    break;
            }
        };
        webSocket.onerror = (e) => {
            // message.error("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            message.warn("与服务器断开了链接：" + evnt.target.url);

            if (!manualStopSearch) {
                message.info("尝试重新连接：" + evnt.target.url);
                this._handleSearch(false);
            }
        };
    };

    _handleResize = () => {
        this._calcParams(this.state.combinePaint);
        if (pageScanFlag) {
            aaa = pageScanValue * (pageScanScreenPointSum * pageScanPM);
            cuokaiFlag = false;
            this._drawPageScanECG()
            this._refreshECGView3()
        }
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    //计算屏幕内容
    _calcParams(combinePaintFlag) {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        const ecgViewWrapNodePagesScan = document.getElementById("ecg_view_wrap3");
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        // 定标mm和px的换算
        const unitWidth = document.getElementById("unit_width");
        const unitPageScanWidth = document.getElementById("unit_width_pagescan");
        console.log("Width:" + unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        console.log("Height" + unitWidth.style.height, unitWidth.clientHeight, unitWidth.offsetHeight, unitWidth.scrollHeight);

        winW = ecgViewWrapNode.clientWidth / (unitWidth.clientWidth / unitWidthMill);
        pageScanWinW = ecgViewWrapNodePagesScan.clientWidth / (unitPageScanWidth.clientWidth / unitWidthMill);
        console.log("winW " + winW + " mm");

        // 每个格子的宽度
        cellWidth = gridScale * (unitWidth.clientWidth / unitWidthMill);
        pageScanCellWidth = gridScale * (unitPageScanWidth.clientWidth / unitWidthMill);
        console.log("cellWidth " + cellWidth + " px");
        console.log("pageScanCellWidth " + pageScanCellWidth + " px");

        // 格子的数量，向下取整
        xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        pageScanXCellCount = Math.floor(ecgViewWrapNodePagesScan.clientWidth / cellWidth);
        console.log("xCellCount " + xCellCount);

        // 通过采样率和下采样率的关系，算出每秒实际多少个点
        dataRate = sampRate / downRatio;
        console.log("downRatio " + downRatio);
        console.log("dataRate " + dataRate);

        // 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
        gridPointNum = dataRate / (chartSpeed / gridScale);
        console.log("gridPointNum " + gridPointNum);

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        pointSpace = cellWidth / gridPointNum;
        pageScanPointSpace = pageScanCellWidth / gridPointNum;
        console.log("pointSpace " + pointSpace);

        // 算出0点，以下是负值，以上是正直，需要变换数据
        zeroPointY = (yCellCount - 3) * cellWidth + winMarginY;
        pageScanZeroPointY = (yCellCount - 2.5) * cellWidth + winMarginY;
        console.log("zeroPointY " + zeroPointY);
        console.log("pageScanZeroPointY " + pageScanZeroPointY);

        //每次更新的数据点的数量
        baseRfsNum = (sampRate / downRatio) / winFpsDeviceCountResult;//(1000 / winFps)
        baseRfsNumApp = (sampRate / downRatio) / winFpsAppCountResult;//(1000 / winFps)
        rfsNum = baseRfsNum;
        rfsNumApp = baseRfsNumApp;
        console.log("rfsNum " + rfsNum);

        // 计算出一屏的点总数
        screenPointSum = gridPointNum * xCellCount
        pageScanScreenPointSum = screenPointSum - 500;
        pagePointSum = pageScanScreenPointSum / 100 / 5;
        PageScanTimeValue = Math.floor(pageScanELenght / pagePointSum);

        console.log("一屏的描画点数：", pageScanScreenPointSum);
        console.log("每条线的时间：", pagePointSum);
        bufferSizeA2 = 2 * dataRate;
        bufferThresholdA1 = 1 * dataRate;
        bufferThresholdA3 = 3 * dataRate;

        console.log("缓存阈值A1：", bufferThresholdA1);
        console.log("缓冲长度A2：", bufferSizeA2);
        console.log("缓存阈值A3：", bufferThresholdA3);

        if (combinePaintFlag) {
            // 合并在一张画板上，则需要按导联数计算高度
            winH = yCellCount * numLeads * cellWidth + 50;
            console.log("winH:", winH);
            pageScanWinH = yCellCount * numLeads * cellWidth + 50;
            // winH = yCellCount * cellWidth + 50;
        } else {
            winH = yCellCount * cellWidth + 50;
            console.log("winH:", winH);
            pageScanWinH = yCellCount * numLeads * cellWidth + 50;
        }

        // 纵轴总共多少mv
        const winDataH = (gridScale / this.state.voltDiv) * yCellCount;
        const scrH = unitWidth.clientHeight;
        const scrSizeH = unitWidthMill;

        gf_ch1 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch1) / winH);
        gf_ch2_8 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch2_8) / winH);

        dg_ch1 = gc_ch1 * gf_ch1;
        dg_che2_8 = gc_ch2_8 * gc_ch2_8;
    }

    componentDidMount() {

        // 如果路径中带了deviceCode参数，则自动填入
        const deviceCode = HttpUtils.getQueryString("deviceCode");
        if (deviceCode !== undefined && deviceCode !== "") {
            this.setState({deviceCode: deviceCode});
        }

        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);

        this._calcParams(this.state.combinePaint);
        this._initDrawBuffer();
        //建立webSocket连接
        this._handleSearch(true);
        if (pageScanFlag) {
            this._drawPageScanECG()
        } else {
            this._drawECG(this.state.combinePaint);//, this.state.channelTypeDevice, this.state.channelTypeAPP
        }
    }

    _drawECG(combinePaintFlag) {

        let ecgViewTextPrefix = "D";

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        const ecgViewWrapNode2 = document.getElementById("ecg_view_wrap2");

        ecgViewWrapNode.innerHTML = "";
        ecgViewWrapNode2.innerHTML = "";
        // console.log("ECG View(with, clientWidth, offsetWidth, scrollWidth):", ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);
        //正常模式
        if (!CHFlag) {
            //合并画布
            if (combinePaintFlag) {
                // 创建SVG容器标签
                let ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";

                let ecgViewContent = "";
                for (let i = 0; i < numLeads; i++) {
                    const offsetY = i * yCellCount * cellWidth;

                    // 创建SVG的Group标签
                    const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

                    const ecgViewText = this._getECGViewText(i, ecgViewTextPrefix);

                    // 创建心电图背景图
                    const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                    const ecgViewBackgroundEnd = "</g>";
                    const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                    // 创建心电图波形
                    const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                    const ecgViewLightWaveEnd = "</g>";
                    // 创建心电图波形
                    const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(i) + ecgViewLightWaveEnd;

                    const ecgViewGroupEnd = "</g>";
                    ecgViewContent = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;
                }

                const ecgViewEnd = "</svg>";
                ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
                marginTopValue = 80;
                //非合并画布
            } else {
                for (let i = 0; i < numLeads; i++) {

                    let index = i;

                    // 创建SVG容器标签
                    const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                    // 创建SVG的Group标签
                    const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";

                    const ecgViewText = this._getECGViewText(index, ecgViewTextPrefix);

                    // 创建心电图背景图
                    const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                    const ecgViewBackgroundEnd = "</g>";
                    const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                    // 创建心电图波形
                    const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                    const ecgViewLightWaveEnd = "</g>";
                    // 创建心电图波形
                    const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(index) + ecgViewLightWaveEnd;

                    const ecgViewGroupEnd = "</g>";
                    const ecgViewEnd = "</svg>";


                    ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;

                }
                marginTopValue = 10;
            }

            if (!pageScanFlag) {
                //展示
                const ch = ChValue - 1;
                // 创建SVG容器标签
                const ecgViewStart = "<svg id='ecg_view2_" + 1 + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='800' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group2_" + 1 + "' >";

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group2_" + 1 + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group2_" + 1 + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(1) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                const ecgViewEnd = "</svg>";

                ecgViewWrapNode2.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;

            }

            //单导联模式
        } else {
            //事件模式下
            if (!pageScanFlag) {
                let index = ChValue - 1;
                const ch = ChValue - 1;

                // const offsetY = ch * yCellCount * cellWidth;
                const ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='1500' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                const ecgViewStart2 = "<svg id='ecg_view_1' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='800' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + ch + "' >";

                const ecgViewText = this._getECGViewText(index, ecgViewTextPrefix);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + ch + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + ch + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(index) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                const ecgViewEnd = "</svg>";


                ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
                ecgViewWrapNode2.innerHTML += ecgViewStart2 + ecgViewGroupStart + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;

                if (combinePaintFlag) {
                    marginTopValue = 80;
                } else {
                    marginTopValue = 200;
                }

            } else {
                const ch = ChValue - 1;
                // const offsetY = ch * yCellCount * cellWidth;
                const ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='1500' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + ch + "' >";

                const ecgViewText = this._getECGViewText(ch, ecgViewTextPrefix);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + ch + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + ch + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(ch) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                const ecgViewEnd = "</svg>";


                ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;

                if (combinePaintFlag) {
                    marginTopValue = 80;
                } else {
                    marginTopValue = 200;
                }
            }
        }
    }


    //秒数转化为时分秒
    formatSeconds(value) {
        var secondTime = parseInt(value);// 秒
        var minuteTime = 0;// 分
        var hourTime = 0;// 小时
        if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
            //获取分钟，除以60取整数，得到整数分钟
            minuteTime = parseInt(secondTime / 60);
            if (minuteTime < 10) {
                minuteTime = "0" + minuteTime;
            }
            //获取秒数，秒数取佘，得到整数秒数
            secondTime = parseInt(secondTime % 60);
            if (secondTime < 10) {
                secondTime = "0" + secondTime;
            }
            //如果分钟大于60，将分钟转换成小时
            if (minuteTime > 60) {
                //获取小时，获取分钟除以60，得到整数小时
                hourTime = parseInt(minuteTime / 60);
                //获取小时后取佘的分，获取分钟除以60取佘的分
                minuteTime = parseInt(minuteTime % 60);
            }
        }
        var result = "" + secondTime;
        if (minuteTime == 0) {
            result = "00:" + result;
        }

        if (minuteTime > 0) {
            result = "" + minuteTime + ":" + result;
        }
        if (hourTime > 0) {
            result = "" + parseInt(hourTime) + ":" + result;
        }
        return result;
    }

    //页扫描下的绘图
    _drawPageScanECG() {
        const ecgViewWrapNode3 = document.getElementById("ecg_view_wrap3");
        ecgViewWrapNode3.innerHTML = "";

        for (let i = 0; i < pageScanPM; i++, pageDataLenght++) {//Buff/screenPointSum
            //需求播放的时长加上
            if (pageScanELenght < Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum)) {
                return
            }
            const ecgViewStart2 = "<svg id='ecg_view_3' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + (18 * PageScanResizeValue) + "' preserveAspectRatio='xMidYMid meet'>";
            const ch = pageScanChValue - 1;

            // 创建SVG的Group标签
            const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";
            let ecgViewText;
            let ecgViewTextEnd;
            // console.log("cuokaiFileValues",cuokaiFileValues,cuokaiFileValues.length)
            if (cuokaiFileValues.length > 0) {
                console.log("cuokaiFileValues.length:", cuokaiFileValues.length)
                for (let cuokaiFile of cuokaiFileValues) {
                    // console.log("cuokaiFile:", cuokaiFile, wuchaTimeValue[cuokaiFile])
                    console.log("cuokaiFile:", wuchaTimeValue);
                    if (Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum) - cuokaiFile * 15.872 <= pagePointSum &&
                        Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum) - cuokaiFile * 15.872 > 0 && cuokaiFile != 0 && wuchaTimeValue[cuokaiFile]) {
                        console.log("pageScanValue:", pageScanValue, "pageScanPM:", pageScanPM, "pagePointSum:", pagePointSum)
                        cuokaiFlag = true;
                        cuokaiTimeValues = wuchaTimeValue[cuokaiFile] - 16;
                        ecgViewText = this._getPageScanECGViewText(this.formatSeconds(Math.round(cuokaiFile * 15.872 + wuchaTimeValue[cuokaiFile] - 16)), "")
                    } else if (cuokaiFlag) {
                        if (wuchaTimeValue[cuokaiFile]) {
                            console.log("pageScanValue1")
                            ecgViewText = this._getPageScanECGViewText(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum + cuokaiTimeValues)), "");
                        }
                    } else {
                        if (wuchaTimeValue[cuokaiFile]) {
                            console.log("pageScanValue2")
                            ecgViewText = this._getPageScanECGViewText(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum)), "");
                        }
                    }

                    //后面的时间
                    if (Math.round(((pageScanValue * pageScanPM) + i + 1) * pagePointSum) - cuokaiFile * 15.872 <= pagePointSum &&
                        Math.round(((pageScanValue * pageScanPM) + i + 1) * pagePointSum) - cuokaiFile * 15.872 > 0 && cuokaiFile != 0 && wuchaTimeValue[cuokaiFile]) {
                        console.log("pageScanValue1:", pageScanValue, "pageScanPM:", pageScanPM, "pagePointSum:", pagePointSum)
                        ecgViewTextEnd = this._getPageScanECGViewText2(this.formatSeconds(Math.round(cuokaiFile * 15.872)), "");
                    } else if (cuokaiFlag) {
                        if (wuchaTimeValue[cuokaiFile]) {
                            console.log("pageScanValue3")
                            ecgViewTextEnd = this._getPageScanECGViewText2(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i + 1) * pagePointSum + cuokaiTimeValues)), "");
                        }
                    } else {
                        if (wuchaTimeValue[cuokaiFile]) {
                            console.log("pageScanValue4")
                            ecgViewTextEnd = this._getPageScanECGViewText2(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i + 1) * pagePointSum)), "");
                        }
                    }
                }
            } else {

                console.log("cuokaiFileValues.length1:", cuokaiFileValues.length)
                ecgViewText = this._getPageScanECGViewText(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i) * pagePointSum)), "");
                ecgViewTextEnd = this._getPageScanECGViewText2(this.formatSeconds(Math.round(((pageScanValue * pageScanPM) + i + 1) * pagePointSum)), "");
            }

            //前面的时间


            // 创建心电图背景图
            // const ecgViewBackgroundStart = "<g id='ecg_view_background_group3_" + i + "'>";
            // const ecgViewBackgroundEnd = "</g>";
            // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground(i) + ecgViewBackgroundEnd;

            // 创建心电图波形
            const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group3_" + ((pageScanValue * pageScanPM) + i) + "'>";
            const ecgViewLightWaveEnd = "</g>";
            // 创建心电图波形
            const ecgViewLightWave = ecgViewLightWaveStart + this._initPageScanEcgViewLightWave(i) + ecgViewLightWaveEnd;

            const ecgViewGroupEnd = "</g>";
            const ecgViewEnd = "</svg>";

            marginTopValue = 20;
            ecgViewWrapNode3.innerHTML += ecgViewStart2 + ecgViewGroupStart + ecgViewText + ecgViewTextEnd + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
        }

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleResize);
    }

//this.props.ecgEventIndexData && this.props.ecgEventIndexData.records &&
//     _renderCaseList() {
//         let event = {};
//         eventList = []
//         return (<tr>
//             {this.props.ecgEventIndexData && this.props.ecgEventIndexData.records && this.props.ecgEventIndexData.records.map((item, index) => {
//                 // if (item.eventType == 4 || item.eventType == 5) {
//                 //     return;
//                 // }
//                 event = {};
//                 event.label = item.eventTime
//                 event.value = item.timeRecordIndex - 1;
//                 var oldFlag;
//                 if (isFirst) {
//                     cunchuValue = item.eventTime;
//                     oldFlag = true
//                     isFirst = false
//                 } else {
//                     var oldTime = (new Date(timeValue + cunchuValue)).getTime(); //得到毫秒数
//                     var nextTime = (new Date(timeValue + item.eventTime)).getTime();
//                     cunchuValue = item.eventTime;
//                     oldFlag = ((nextTime / 1000) - (oldTime / 1000)) > 16 ? false : true;
//                     if (!oldFlag) {
//                         wuchaTime[event.value] = (nextTime / 1000) - (oldTime / 1000);
//                         console.log("oldTime:", oldTime, "nextTime:", nextTime, oldFlag, wuchaTime[event.value], event.value);
//                     }
//                 }
//
//                 eventFlag[event.value] = oldFlag;
//                 eventList.push(event)
//                 const timeRecordIndex = item.timeRecordIndex - 1
//                 const color = this.props.eventRecordIndex === index ? "red" : "gray";
//                 return (<div id={"event_index_" + timeRecordIndex}>
//                     <div style={{
//                         fontWeight: "bold", float: "left", width: "30%", textAlign: "right", cursor: "pointer"
//                     }} onClick={() => this.onPlaySliderChange(item)}>
//                         {item.eventTime}
//                     </div>
//                     <div style={{
//                         fontWeight: "bold", float: "left", width: "60%", marginLeft: '15px', cursor: "pointer"
//                     }} onClick={() => this.onPlaySliderChange(item, index)}>
//                         <EnumItemLabel map={EcgFileEventType.Map} code={item.eventType}/>
//                     </div>
//                     <div style={{clear: "both"}}/>
//                 </div>)
//             })}
//         </tr>)
//
//     }

    setLookUpType() {
        caseListFlag = !caseListFlag
        if (caseListFlag) {
            this.setState({lookUpType: "请选择事件种类："});
        } else {
            this.setState({lookUpType: "事件内容："});
        }

    }

    _renderCaseList() {

        let userMessage
        if (caseListFlag) {
            userMessage = (
                <div>
                    <div id={"time_index_" + 0}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(-1)}>
                        所有事件内容
                    </div>
                    <div id={"time_index_" + 1}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(0)}>
                        <EnumItemLabel map={EcgFileEventType.Map} code={0}/>
                    </div>
                    <div id={"time_index_" + 2}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(1)}>
                        <EnumItemLabel map={EcgFileEventType.Map} code={1}/>
                    </div>
                    <div id={"time_index_" + 3}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(2)}>
                        <EnumItemLabel map={EcgFileEventType.Map} code={2}/>
                    </div>
                    <div id={"time_index_" + 4}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(3)}>
                        <EnumItemLabel map={EcgFileEventType.Map} code={3}/>
                    </div>
                    <div id={"time_index_" + 5}
                         style={{
                             fontWeight: "bold",
                             cursor: "pointer"
                         }}
                         onClick={() => this._onPlaySlider(4)}>
                        <EnumItemLabel map={EcgFileEventType.Map} code={4}/>
                    </div>
                </div>
            )
        } else {
            let event = {};
            eventList = []
            userMessage = (
                <tr>
                    {
                        this.props.ecgEventIndexData && this.props.ecgEventIndexData.records &&
                        this.props.ecgEventIndexData.records.map((item, index) => {
                            event = {};
                            event.label = item.eventTime
                            event.value = item.timeRecordIndex - 1;
                            var oldFlag;
                            if (isFirst) {
                                cunchuValue = item.eventTime;
                                oldFlag = true
                                isFirst = false
                            } else {
                                var oldTime = (new Date(timeValue + cunchuValue)).getTime(); //得到毫秒数
                                var nextTime = (new Date(timeValue + item.eventTime)).getTime();
                                cunchuValue = item.eventTime;
                                oldFlag = ((nextTime / 1000) - (oldTime / 1000)) > 16 ? false : true;
                                if (!oldFlag) {
                                    wuchaTime[event.value] = (nextTime / 1000) - (oldTime / 1000);
                                }
                            }

                            eventFlag[event.value] = oldFlag;
                            eventList.push(event)
                            if (caseBiaoJi != -1) {
                                if (item.eventType != caseBiaoJi) {
                                    return;
                                }
                            }

                            const timeRecordIndex = item.timeRecordIndex - 1
                            const color = this.props.eventRecordIndex === index ? "red" : "gray";
                            return (
                                <div id={"event_index_" + timeRecordIndex}>
                                    <div style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        width: "30%",
                                        textAlign: "right",
                                        cursor: "pointer"
                                    }} onClick={() => this.onPlaySliderChange(item)}>
                                        {item.eventTime}
                                    </div>
                                    <div style={{
                                        fontWeight: "bold",
                                        float: "left",
                                        width: "60%",
                                        marginLeft: '15px',
                                        cursor: "pointer"
                                    }} onClick={() => this.onPlaySliderChange(item, index)}>
                                        <EnumItemLabel map={EcgFileEventType.Map} code={item.eventType}/>
                                    </div>
                                    <div style={{clear: "both"}}/>
                                </div>
                            )
                        })
                    }
                </tr>
            )
        }
        return (
            <div>{userMessage}</div>
        )
    }

    _onPlaySlider(index) {
        console.log(index)
        caseBiaoJi = index
        switch (index) {
            case -1:
                message.success("展示所有事件内容")
                break
            case 0:
                message.success("展示诊断紧急事件内容")
                break
            case 1:
                message.success("展示跌倒紧急事件内容")
                break
            case 2:
                message.success("展示导联脱落事件内容")
                break
            case 3:
                message.success("展示诊断结果事件内容")
                break
            case 4:
                message.success("展示设备状态事件内容")
                break
        }
        caseListFlag = false;
        this.setState({lookUpType: "事件内容："});
    }

    // _renderCaseList() {
    //     return (
    //         <tr>
    //             {
    //                 this.props.ecgEventIndexData && this.props.ecgEventIndexData.records &&
    //                 this.props.ecgEventIndexData.records.map((item, index) => {
    //                     // if (item.eventType == 4 || item.eventType == 5) {
    //                     //     return;
    //                     // }
    //                     const timeRecordIndex = item.timeRecordIndex - 1
    //                     const color = this.props.eventRecordIndex === index ? "red" : "gray";
    //                     return (
    //                         <div id={"event_index_" + timeRecordIndex}>
    //                             <div style={{
    //                                 fontWeight: "bold",
    //                                 float: "left",
    //                                 width: "30%",
    //                                 textAlign: "right",
    //                                 cursor: "pointer"
    //                             }} onClick={() => this.onPlaySliderChange(item)}>
    //                                 {item.eventTime}
    //                             </div>
    //                             <div style={{
    //                                 fontWeight: "bold",
    //                                 float: "left",
    //                                 width: "60%",
    //                                 marginLeft: '15px',
    //                                 cursor: "pointer"
    //                             }} onClick={() => this.onPlaySliderChange(item, index)}>
    //                                 <EnumItemLabel map={EcgFileEventType.Map} code={item.eventType}/>
    //                             </div>
    //                             <div style={{clear: "both"}}/>
    //                         </div>
    //                     )
    //                 })
    //             }
    //         </tr>
    //     )
    //
    // }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <div lassName="page-content position-relative" style={{padding: 5, maxHeight: height}}>
                <div style={{marginTop: "10px", marginLeft: "10px"}}>
                    <Button className="spacing-h"> <FormattedMessage id='DOCTOR_RECORD_MESSAGE'/></Button>
                    <Button className="spacing-h" id="event" onClick={() => this.onListClick(1)}> <FormattedMessage
                        id='DOCTOR_EVENT'/></Button>
                    <Button className="spacing-h" id="pageScan" onClick={() => this.onListClick(2)}>页扫描</Button>
                    <Button className="spacing-h"> <FormattedMessage id='DOCTOR_TAGGING'/></Button>
                    <Button className="spacing-h"> <FormattedMessage id='DOCTOR_REPORT'/></Button>
                    <Button className="spacing-h"> <FormattedMessage id='COMMON_BUTTON_BACK'/></Button>
                    <Button className="spacing-h" onClick={() => this.setLookUpType()}>事件分类</Button>
                </div>
                <Form id="eventForm"
                      style={{width: '100%', height: '20%', backgroundColor: "#FFF", paddingLeft: "5px"}}>
                    <Row style={{width: '100%', height: '100%', backgroundColor: "#FFF"}}>
                        <Col span={2} style={{
                            width: '25%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <Form.Item label={this.state.lookUpType}>
                                <div style={{
                                    padding: 5,
                                    width: '100%',
                                    height: '260px',
                                    paddingLeft: "5px",
                                    paddingRight: '5px',
                                    border: '1px solid #e8e8e8',
                                    overflow: 'auto'
                                }}>
                                    <table id="caseList">
                                        {this._renderCaseList()}
                                    </table>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{
                            width: '75%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <Form.Item id="" label={this.state.illness}>
                                <div style={{
                                    padding: 5,
                                    width: '100%',
                                    height: '220px',
                                    paddingLeft: "5px",
                                    paddingRight: '5px',
                                    border: '1px solid #e8e8e8',
                                    overflow: 'auto'
                                }}>
                                    <table>
                                        <tr>
                                            <div id="ecg_view_wrap2"
                                                 style={{width: '100%', height: '100%', paddingTop: '10px'}}
                                                 onClick={() => this._refreshECGView2()}>
                                            </div>
                                        </tr>
                                    </table>

                                </div>
                                <div>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_DELETE'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_DELETE_PAGE'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_DELETE_ALL'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_SAVE_FRAGMENT'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_SAVE_CANEL'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage
                                        id='COMMON_BUTTON_DISPALY_SORT'/></Button>
                                    <Button className="spacing-h"> <FormattedMessage id='DAS_ECG_CH_0'/></Button>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Form id="pageScanForm"
                      style={{
                          width: '100%',
                          height: '20%',
                          display: "none",
                          backgroundColor: "#FFF",
                          paddingLeft: "5px"
                      }}>
                    <div style={{marginTop: 10}}>
                        <div>

                            时间：<EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={eventList} value={this.state.eventTimeIndex}
                            onChange={(value) => {
                                this._handleChangeTimeList(value)
                            }}/>

                            导联： <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={CH.List} value={ChValue}
                            onChange={(value) => this._handleChangePageScanCH(value)}/>

                            时长：<EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={PageScanTime.List} value={this.state.PageScanTimeValue}
                            onChange={(value) => {
                                this._handleChangePageScanTime(value)
                            }}/>

                            大小：<EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={PageScanResize.List} value={this.state.PageScanResizeValue}
                            onChange={(value) => this._handleChangePageScanSize(value)}/>

                            <Button className="spacing-h" onClick={() => this.prePage()}> 上翻页</Button>
                            <Button className="spacing-h" onClick={() => this.nextPage()}> 下翻页</Button>
                        </div>
                    </div>
                    <div id="unit_width_pagescan" style={{
                        padding: 5,
                        width: '100%',
                        height: '260px',
                        paddingLeft: "5px",
                        paddingRight: '5px',
                        border: '1px solid #e8e8e8',
                        overflow: 'auto',
                        marginTop: 10
                    }}>
                        <table>
                            <div id="ecg_view_wrap3"
                                 style={{width: '100%', height: '100%', paddingTop: '10px'}}
                                 onClick={() => this._refreshECGView3()}>
                            </div>
                        </table>
                    </div>
                </Form>


                <DetailModal/>
                <div id="unit_width" style={{
                    width: unitWidthMill + 'mm', height: unitWidthMill + 'mm', position: 'absolute', zIndex: -1
                }}>
                </div>
                <LogListModal visible={this.state.showLogModal} logs={this.state.logs}
                              onClose={() => this.setState({showLogModal: false})}/>

                <div style={{marginTop: marginTopValue}}>
                    <div>
                        <Button icon={"step-backward"} className="spacing-h" onClick={() => this._handlePreFile()}/>
                        <Button icon={"pause"} className="spacing-h" onClick={() => this._handleStopPlay()}/>
                        <Button loading={this.state.playFlag} icon={"caret-right"} className="spacing-h"
                                onClick={() => this._handlePlayRight()}/>
                        <Button icon={"step-forward"} className="spacing-h" onClick={() => this._handleNextFile()}/>
                        {/*TODO：暂时关闭选项*/}
                        {/*<Select style={{width: '150px'}}*/}
                        {/*        placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}*/}
                        {/*        value={this.state.deviceCode}*/}
                        {/*        onChange={(value) => {*/}
                        {/*            this._handleChange("code", value)*/}
                        {/*        }}>*/}
                        {/*    {*/}
                        {/*        this.props.allDevices.map((item, index) => {*/}
                        {/*            return <Select.Option key={index}*/}
                        {/*                                  value={item.code}>{item.code}</Select.Option>*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*</Select>*/}

                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={ChartSpeed.List} value={this.state.chartSpeedTimes}
                            onChange={(value) => {
                                this.setState({chartSpeedTimes: value});
                            }}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={VoltDiv.List} value={this.state.voltDiv}
                            onChange={(value) => this._handleChangeVoltDiv(value, 2)}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={CH.List} value={ChValue}
                            onChange={(value) => this._handleChangeCH(value)}/>
                        <DatePicker className="spacing-h"
                                    value={this.state.recordDate ? moment(this.state.recordDate, FORMAT_DATE_HYPHEN) : null}
                                    locale={locale}
                                    disabled={true}
                        />
                        <Popover className="spacing-h"
                                 content={<DoubleCalendar visible={this.state.doubleCalendarVisible}
                                                          ecgDateIndexRecordMap={this.props.ecgDateIndexRecordMap}
                                                          onSelect={(date) => this._handleChangeRecordDate(date)}/>}
                                 visible={this.state.doubleCalendarVisible}
                                 trigger="click"
                                 placement="bottom"
                                 onVisibleChange={(value) => this.setState({doubleCalendarVisible: value})}>
                        </Popover>
                        <DatePicker
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}/>
                        <Checkbox style={{marginLeft: "5px"}} checked={this.state.combinePaint}
                                  onChange={(value) => this._handleSwitchCombinePaint(value)}>合并画布</Checkbox>
                    </div>
                </div>
                {/*<EventIndexTimeLine ecgEventIndexData={this.props.ecgEventIndexData}*/}
                {/*                    eventRecordIndex={this.props.ecgTimeIndexData.records &&*/}
                {/*                    this.props.ecgTimeIndexData.records[this.state.sliderValue] &&*/}
                {/*                    this.props.ecgTimeIndexData.records[this.state.sliderValue].eventRecordIndex - 1}*/}
                {/*                    onTimeLineItemClick={(value) => this.onPlaySliderChange(value.timeRecordIndex - 1)}*/}
                {/*                    visible={this.state.eventIndexTimeLineVisible}/>*/}
                {/* 播放进度条 */}
                <TimeIndexSlider style={{marginTop: "5px"}}
                                 sliderValue={this.state.sliderValue}
                                 initIndex={initIndex}
                                 initHistoryIndex={initHistoryIndex}
                                 ecgTimeIndexData={this.props.ecgTimeIndexData}
                                 ecgTimeSlideMarks={this.props.ecgTimeSlideMarks}
                                 ecgTimeSlideData={this.props.channelType == "20" ? this.props.appHistoryData : this.props.deviceHistoryData}
                                 deviceHistoryData={this.props.deviceHistoryData}
                                 historyFileIndex={this.state.historyFileIndexV}
                                 onChange={(value) => {
                                     this.setState({sliderValue: value});
                                     this._handleStopPlay()
                                 }}
                                 onChange={(value) => this.onPlaySliderChange2(value, true)}/>

                <DeviceDetailDrawer visible={this.state.toggleDeviceDetail} setVis={this._setVis}/>

                <div id="ecg_view_wrap" style={{width: '100%', height: '100%', paddingTop: '10px'}}/>
            </div>);
    }

    _setVis = value => {
        this.setState({
            toggleDeviceDetail: value,
        });

    };

    //切换不同的展示页面
    onListClick(index) {
        const event = document.getElementById("event");
        const pageScan = document.getElementById("pageScan");
        var eventForm = document.getElementById('eventForm');
        var pageScanForm = document.getElementById('pageScanForm');
        switch (index) {
            //事件
            case 1:
                pageScan.style.background = "white"
                pageScan.style.color = "black"
                event.style.background = "#2956b1"
                event.style.color = "white"
                eventForm.style.display = 'block';
                pageScanForm.style.display = 'none';
                pageScanFlag = false;
                this._drawECG(this.state.combinePaint)
                clearDrawBufferFlag = true
                break
            //页扫描
            case 2:
                event.style.background = "white"
                event.style.color = "black"
                pageScan.style.background = "#2956b1"
                pageScan.style.color = "white"
                eventForm.style.display = 'none';
                pageScanForm.style.display = 'block';
                pageScanFlag = true;
                clearDrawBufferFlag = true
                this._calcParams(this.state.combinePaint)
                this._drawPageScanECG()
                //如果不初始化的话会导致数据没有正常清空
                this._handleChangePageScanCH(pageScanChValue + 1)
                break

        }

    }

    // onPageScanClick(){
    //     const pageScan = document.getElementById("pageScan");
    //     const event = document.getElementById("event");
    //     var eventForm = document.getElementById('eventForm');
    //     var pageScanForm = document.getElementById('pageScanForm');
    //     event.style.background = "white"
    //     pageScan.style.background = "blue"
    //     eventForm.style.display = 'none';
    //     pageScanForm.style.display = 'block';
    // }

    _handleChangeRecordDate(date) {
        const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
        if (ecgDateIndexRecord) {
            let requestUrl = RoutePath.ECG_HISTORY_DAY.path;
            requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", this.state.deviceCode);
            requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
            requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
            requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
            requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
            requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);

            this.props.router.push(requestUrl);

            this.setState({
                doubleCalendarVisible: false,
                deviceCode: this.state.deviceCode,
                recordDate: ecgDateIndexRecord.recordDate,
                timeFileIndex: ecgDateIndexRecord.timeFileIndex,
                eventFileIndex: ecgDateIndexRecord.eventFileIndex
            });

            webSocketCommandHistory = [];
            clearDrawBufferFlag = true;
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.DATEIDX, 1);
        }
    }

    _handleStopSearch() {
        webSocketCommandHistory = [];
        drawBufferMap = {};
        pageScanDrawBufferMap = {};
        this._handleStopPlay(true);
        webSocket && webSocket.close();
    }

    _handleStopPlay(manualStop) {
        manualStopSearch = manualStop;
        this.setState({playFlag: false});
        clearInterval(this.intervalPlay);
        this.intervalPlay = undefined;

        this._addAnnotationEvent();
    }

    _addAnnotationEvent() {
        // 为每个Annotation的Line注册相关事件
        const self = this;
        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            annotationElement.onmouseenter = function (e) {
                this.setAttribute("stroke", "red");
                e.stopPropagation();
            };
            annotationElement.onmouseleave = function (e) {
                this.setAttribute("stroke", "grey");
                e.stopPropagation();
            };
            annotationElement.onclick = function (e) {
                self.props.dispatch(getAnnotation(this.id, self.state.formatMessage));
                self.props.dispatch(toggleAnnotationModal(true));
                e.stopPropagation();
            };
        }
    }

    _handlePreFile() {
        if (this.state.sliderValue > 0) {
            this.onPlaySliderChange2(this.state.sliderValue - 1, false);
        }
    }

    _handleNextFile() {
        this.onPlaySliderChange2(this.state.sliderValue + 1, true);
    }

    /** 点击控制进度条播放 */
    onPlaySliderChange = (value) => {
        //实现点击变色
        if (!isOne) {
            const eventIndexVaule2 = document.getElementById("event_index_" + eventIndex);
            if (eventIndexVaule2) {
                eventIndexVaule2.style.background = "white"
                eventIndexVaule2.style.color = "black"
            }
        }
        const timeRecordIndex = value.timeRecordIndex - 1
        const eventIndexVaule = document.getElementById("event_index_" + timeRecordIndex);
        eventIndexVaule.style.color = "white"
        eventIndexVaule.style.background = "#2956b1";
        isOne = false;
        eventIndex = value.timeRecordIndex - 1;
        // this.setState({illness: value.eventType})
        // 获取当前刻度所对应的历史文件索引
        const record = this.props.ecgTimeIndexData.records[value.timeRecordIndex - 1];
        if (!record || record.historyFileIndex <= 0) {
            message.warn("该时间点没有心电数据");
            return;
        }
        if (record) {
            // 第一个历史文件先获取了
            clearDrawBufferFlag = true;
            this._handleStopPlay(true);
            this.setState({sliderValue: value.timeRecordIndex - 1});
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
        }

    };

    /** 点击控制进度条播放 */
    onPlaySliderChange2 = (value) => {
        const pageScanTimeFlag = false;
        // 获取当前刻度所对应的历史文件索引
        const record = this.props.ecgTimeIndexData.records[value];
        if (!record || record.historyFileIndex <= 0) {
            message.warn("该时间点没有心电数据");
            return;
        }
        //实现点击变色
        if (!isOne) {
            const eventIndexVaule2 = document.getElementById("event_index_" + eventIndex);
            if (eventIndexVaule2) {
                eventIndexVaule2.style.background = "white"
                eventIndexVaule2.style.color = "black"
            }
        }
        const eventIndexVaule = document.getElementById("event_index_" + value);
        if (eventIndexVaule) {
            eventIndexVaule.style.background = "#2956b1";
            eventIndexVaule.style.color = "white"
            isOne = false;
            eventIndex = value;
        }
        if (record) {
            // 第一个历史文件先获取了
            clearDrawBufferFlag = true;
            this._handleStopPlay(true);
            this.setState({sliderValue: value});
            // if (pageScanFlag) {
            // for (let i = 0; i < pageScanRizeValue; i++) {
            //     WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex + i, webSocketCommandHistory)
            // }
            // } else {
            if (pageScanFlag) {
                cuokaiFileValue = 0;
                cuokaiFileValues = [];
                huanhangValue = 0;
                huanhangValue2 = 0;
                for (var i = value; i < value + pageScanValueSize; i++, cuokaiFileValue++) {
                    if (!eventFlag[i]) {
                        cuokaiFileValues.push(cuokaiFileValue);
                        wuchaTimeValue[cuokaiFileValue] = wuchaTime[i]
                        console.log("误差时间：", wuchaTime[i])
                        if (cuokaiFileValue != 0 && cuokaiFileValues) {
                            // console.log("总共有" + cuokaiFileValues.length + ",第一个错开的文件是" + cuokaiFileValue + ",错开的时间为" + wuchaTimeValue[cuokaiFileValue] + ",错开文件0")
                            // message.success("总共有" + cuokaiFileValues.length + ",第一个错开的文件是" + cuokaiFileValue + ",错开的时间为" + wuchaTimeValue[cuokaiFileValue] + ",错开文件0");
                        }

                        cuokaiFileValue2 = cuokaiFileValue;

                    }
                }
                WebSocketUtils._sendGetEcgDeviceFiles(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory, pageScanValueSize);
            } else {
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
            }

            // }

        }
    };


    /** 点击控制进度条播放 */
    // onPlaySliderChangeIndex = (index) => {
    //     // 获取当前刻度所对应的历史文件索引
    //     const record = this.props.ecgTimeIndexData.records[value];
    //     if (!record || record.historyFileIndex <= 0) {
    //         message.warn("该时间点没有心电数据");
    //         return;
    //     }
    //     if (record) {
    //         // 第一个历史文件先获取了
    //         clearDrawBufferFlag = true;
    //         this._handleStopPlay(true);
    //         this.setState({sliderValue: value});
    //         WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
    //     }
    // };

    /** 心电图正向播放 */
    _handlePlayRight = () => {

        if (!this.state.playFlag) {
            this.setState({playFlag: true});
            this.intervalPlay = setInterval(() => {
                this._refreshECGView2();

                if (drawBufferMap[0] && drawBufferMap[0][0] && drawBufferMap[0][0].timeRecordIndex !== this.state.sliderValue) {
                    this.setState({sliderValue: drawBufferMap[0][0].timeRecordIndex});
                }

                // 如果当前点的时间记录索引跟滑动条上的标识不符合，则调整滑动条的值
                if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500] && drawBufferMap[0][0]) {
                    if (!isOne) {
                        const eventIndexVaule2 = document.getElementById("event_index_" + eventIndex);
                        if (eventIndexVaule2) {
                            eventIndexVaule2.style.background = "white"
                            eventIndexVaule2.style.color = "black"
                        }
                    }
                    const timeRecordIndex = drawBufferMap[0][0].timeRecordIndex + 1
                    const eventIndexVaule = document.getElementById("event_index_" + timeRecordIndex);
                    if (eventIndexVaule) {
                        eventIndexVaule.style.background = "#2956b1";
                        eventIndexVaule.style.color = "white"
                        isOne = false;
                        eventIndex = drawBufferMap[0][0].timeRecordIndex + 1;
                    }
                    // 缓存的数据不够，需要重新获取下一个历史文件数据
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, drawBufferMap[0][0].historyFileIndex + 1, webSocketCommandHistory);
                }

            }, winFps);
        }

    };

    //改变时长
    _handleChangePageScanTime(value) {
        //向下取整
        const wucha = Math.floor(value / pagePointSum);
        pageScanValueSize = Math.ceil(value / 15.872);
        //重置翻页
        pageScanValue = 0;
        pageScanELenght = value;
        aaa = 0;
        huanhangFlag = false;
        PageScanTimeValue = Math.floor(pageScanELenght / pagePointSum);
        this._drawPageScanECG()
        this.setState({PageScanTimeValue: value});

    }

    //改变大小
    _handleChangePageScanSize(value) {
        PageScanResizeValue = value;
        pageScanPM = Math.ceil(10 / value);

        //将翻页数字归零
        pageScanValue = 0;
        // 重新计算点
        for (let i = 0; i < numLeads; i++) {

            if (drawBufferMap[i]) {
                const drawBuffer = drawBufferMap[i];
                if (!drawBuffer) {
                    return;
                }

                for (let i = 0; i < drawBuffer.length; i++) {
                    drawBuffer[i].newPoint = null;
                }
            }

        }
        aaa = pageScanValue * (pageScanScreenPointSum * pageScanPM);
        cuokaiFlag = false;
        this._drawPageScanECG()
        this._refreshECGView3()
        this.setState({PageScanResizeValue: value});
    }

    //改变导联数
    _handleChangePageScanCH(value) {
        pageScanChValue = value;
        // 重新计算点
        if (drawBufferMap[pageScanChValue - 1]) {
            const drawBuffer = drawBufferMap[pageScanChValue - 1];
            if (!drawBuffer) {
                return;
            }
            for (let i = 0; i < drawBuffer.length; i++) {
                drawBuffer[i].newPoint = null;
            }
        }
        aaa = 0;
        huanhangFlag = false;
        this._drawPageScanECG()
        this._refreshECGView3()
        this.setState({CHFlag: CHFlag});
    }

    //改变导联数
    _handleChangeCH(value) {
        if (value != 0) {
            CHFlag = true;
        } else {
            CHFlag = false;
        }
        ChValue = value;
        this._drawECG(this.state.combinePaint)
        this.setState({CHFlag: CHFlag});
    }

    //改变时间列表选项
    _handleChangeTimeList(value) {
        console.log("选择时间：", value);
        eventTimeIndex = value
        evenFileIndex = 0;
        evenFileIndexValue = 1;
        drawBufferMap = null;
        cuokaiTimeValues = 0;
        this.onPlaySliderChange2(value)
        // 重新计算点

        cuokaiFlag = false;
        // for (let i = 0; i < numLeads; i++) {
        //
        //     if (drawBufferMap[i]) {
        //         const drawBuffer = drawBufferMap[i];
        //         if (!drawBuffer) {
        //             return;
        //         }
        //
        //         for (let i = 0; i < drawBuffer.length; i++) {
        //             drawBuffer[i].newPoint = null;
        //         }
        //     }
        //
        // }
        aaa = 0;
        huanhangFlag = false;
        this._drawPageScanECG()
        this.setState({eventTimeIndex: value});
    }

    //改变电压值
    _handleChangeVoltDiv(value, index) {
        this.setState({voltDiv: value});
        // 重新计算点
        for (let i = 0; i < numLeads; i++) {

            if (drawBufferMap[i]) {
                const drawBuffer = drawBufferMap[i];
                if (!drawBuffer) {
                    return;
                }

                for (let i = 0; i < screenPointSum; i++) {
                    drawBuffer[i].newPoint = null;
                }
            }

        }
    }

    _handleWriteLog(content) {
        if (this.state.writeLogFlag) {
            if (logWebSocket && logWebSocket.readyState === 1) {
                logWebSocket.send(content);
            } else if (logWebSocket && logWebSocket.readyState === 0) {
                message.warn("Log 服务连接中");
            } else if (logWebSocket && logWebSocket.readyState === 2) {
                message.warn("Log 服务链接关闭中");
            } else if (logWebSocket && logWebSocket.readyState === 3) {
                message.warn("Log 服务已断开链接");
            }
        }
    }

    _handleConnectLogSocket() {
        const self = this;
        logWebSocket = new WebSocket(LOG_SOCKET_SERVER_HOST);
        self.setState({writeLogSocketStatus: "（连接中）"});
        logWebSocket.onopen = function (evnt) {
            self.setState({writeLogSocketStatus: "（连接成功）"});
            message.success("Log 服务连接成功：");
        };
        logWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            console.log(data);
        };
        logWebSocket.onerror = function (evnt) {
            self.setState({writeLogSocketStatus: "（连接异常）"});
            message.error("Log 服务连接异常：" + evnt.target.url);
        };
        logWebSocket.onclose = function (evnt) {
            self.setState({writeLogSocketStatus: "（连接断开）"});
            message.warn("Log 服务连接断开：" + evnt.target.url);
            if (!manualStopWriteLog) {
                self.setState({writeLogSocketStatus: "（重新连接）"});
                message.info("Log 服务尝试重新连接：" + evnt.target.url);
                self._handleConnectLogSocket();
            }
        };
    }


    _handleSwitchCombinePaint(value) {
        this.setState({combinePaint: value.target.checked});
        this._calcParams(value.target.checked);
        this._drawECG(value.target.checked);
    }

    // _handleSwitchBuffer(value) {
    //     this.setState({drawBufferFlag: value.target.checked});
    //     if (!value.target.checked) {
    //         clearInterval(this.intervalPlay);
    //         this.intervalPlay = undefined;
    //
    //         // 清空缓存
    //         // const pointNum = gridPointNum * xCellCount;
    //         // const bufferDataLength = Object.values(drawBufferMap[0]).length;
    //         // if (bufferDataLength > screenPointSum) {
    //         //     for (let i = 0; i < numLeads; i++) {
    //         //         drawBufferMap[i] = drawBufferMap[i].slice(bufferDataLength - screenPointSum);
    //         //     }
    //         // }
    //         this._initDrawBuffer();
    //         rfsNum = (sampRate / downRatio) / (1000 / winFps);
    //         rfsNumApp = (sampRate / downRatio) / (1000 / winFps);
    //     } else {
    //         this._handleDrawBufferEcg();
    //     }
    // }


    // _handleDrawBufferEcg() {
    //     if (this.intervalPlay === undefined || this.intervalPlay === null) {
    //         bufferLowFlag = true;
    //         this.intervalPlay = setInterval(() => {
    //
    //             const bufferDataLength = drawBufferMap[0].length;
    //             if (bufferLowFlag) {
    //                 // 缓冲区大小过低，需要等待
    //                 if (bufferDataLength >= screenPointSum + bufferSizeA2) {
    //                     bufferLowFlag = false;
    //                     const time = Date.now();
    //                     this._handleWriteLog("缓冲区已经填满，开始绘制" + "," + time + "," + moment(time).format(FORMAT_DATE_TIME_SLASH));
    //                 }
    //             } else {
    //                 // 如果启动缓冲区
    //                 // 缓存一定的量再启动描画
    //                 // const pointNum = gridPointNum * xCellCount;
    //                 // 3、	A2是缓冲长度，就和看电影一开始要缓冲一段一样。
    //                 // 开始采集实时数据后，当数据点数量>=A2时，开始波形描绘。
    //                 // if (bufferDataLength >= bufferSizeA2) {
    //                 // 如果点的长度减去本次要刷新的点，依然超过缓存的最长度，则增加刷新的点数
    //                 // 1）A1≤N≤A3   （即在蓝色框范围内）      每次取RfsNum=4个点描绘
    //                 // 2）A1>N       （即在蓝色框右侧）         每次取RfsNum-1=3个点描绘
    //                 // 3）A3<N       （即在蓝色框左侧）       每次取RfsNum+1=5个点描绘
    //                 baseRfsNum = (sampRate / downRatio) / winFpsDeviceCountResult;
    //                 if (bufferDataLength < screenPointSum + bufferThresholdA1) {
    //                     rfsNum = baseRfsNum - 1 * (5 / downRatio);
    //                 } else if (bufferDataLength > screenPointSum + bufferThresholdA3) {
    //                     rfsNum = baseRfsNum + 1 * (5 / downRatio);
    //                 } else {
    //                     rfsNum = baseRfsNum;
    //                 }
    //
    //                 if (this.state.drawParamsWrite) {
    //                     this._handleWriteLog("ScreenPointSum, BaseRfsNum, BufferDataLength, RfsNum:" + screenPointSum + "," + baseRfsNum + "," + bufferDataLength + "," + rfsNum);
    //                 }
    //                 this._refreshECGView(rfsNum);
    //             }
    //             winFpsDeviceCount++;
    //             this.setState({bufferLengthWeb: bufferDataLength, rfsNum: rfsNum});
    //         }, winFps);
    //
    //         this.intervalRecalculate = setInterval(() => {
    //             winFpsDeviceCountResult = winFpsDeviceCount;
    //             winFpsDeviceCount = 0;
    //         }, 1000);
    //     }
    // }

    // _handleChange(name, value) {
    //     this.setState({deviceCode: value.target ? value.target.value : value});
    //     this._initDrawBuffer();
    //     // this._initDrawBufferApp();
    //     this._drawECG(this.state.combinePaint);
    //     this._handleStopSearch();
    // }


// 设备切换，停止重新开始的时候，需要清空画板
    _initDrawBuffer() {
        // 改变设备，清空数据重新绘制，否则不同的设备数据混在一起
        for (let i = 0; i < numLeads; i++) {
            // 初始化固定长度的绘画缓冲区
            drawBufferMap[i] = [];
            for (let j = 0; j < screenPointSum; j++) {
                drawBufferMap[i][j] = new ECGPoint();
            }
        }
    }


    /** 刷新小心电图 */
    _refreshECGView = () => {
        // for (let i = 0; i < numLeads; i++) {

        // if (!drawBufferMap[i] || !drawBufferMap[i][rfsNum * this.state.chartSpeedTimes]) {
        //     continue;
        // }

        if (this.state.playFlag) {
            // 如果在播放，则把rfsNum这个索引之前的数据删掉
            drawBufferMap[1].splice(0, rfsNum * this.state.chartSpeedTimes);
        }

        const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group2_" + 1);


        // 创建心电图波形
        const ecgViewLightWave = this._getECGViewLightWave(1);
        if (ecgViewLightWave && ecgViewLightwaveGroup) {
            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }

        // }
    };

    nextPage = () => {
        // aaa = 0;
        // cuokaiFlag = false;
        if (((pageScanValue + 1) * pageScanPM) < PageScanTimeValue) {
            pageScanValue++;
            console.log("pageScanScreenPointSum * pageScanPM:", pageScanScreenPointSum * pageScanPM)
            aaa = pageScanValue * (pageScanScreenPointSum * pageScanPM);
            this._drawPageScanECG()
            this._refreshECGView3()
        } else {
            message.success("这是最后一页");
        }

    };

    prePage = () => {
        // aaa = 0;
        // cuokaiFlag = false;
        if (pageScanValue == 1) {
            huanhangValue = 0;
            huanhangFlag = false;
            cuokaiFlag = false;
            console.log("pageScanScreenPointSum:", pageScanScreenPointSum, "pageScanPM:", pageScanPM)
        }
        if (pageScanValue > 0) {
            pageScanValue--;
            aaa = pageScanValue * (pageScanScreenPointSum * pageScanPM);
            this._drawPageScanECG()
            this._refreshECGView3()
        } else {
            message.success("这是第一页");
        }

    };


    /** 刷新心电图 */
    _refreshECGView2 = () => {
        if (!CHFlag) {
            for (let i = 0; i < numLeads; i++) {

                if (!drawBufferMap[i] || !drawBufferMap[i][rfsNum * this.state.chartSpeedTimes]) {
                    continue;
                }

                if (this.state.playFlag) {
                    // 如果在播放，则把rfsNum这个索引之前的数据删掉
                    drawBufferMap[i].splice(0, rfsNum * this.state.chartSpeedTimes);
                }

                const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);

                // 创建心电图波形
                const ecgViewLightWave = this._getECGViewLightWave(i);
                ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
            }
        } else {
            if (!drawBufferMap[(ChValue - 1)] || !drawBufferMap[(ChValue - 1)][rfsNum * this.state.chartSpeedTimes]) {
                return;
            }

            if (this.state.playFlag) {
                // 如果在播放，则把rfsNum这个索引之前的数据删掉
                drawBufferMap[(ChValue - 1)].splice(0, rfsNum * this.state.chartSpeedTimes);
            }

            const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + (ChValue - 1));

            // 创建心电图波形
            const ecgViewLightWave = this._getECGViewLightWave((ChValue - 1));
            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }
    };

    /** 刷新心电图 */
    _refreshECGView3 = () => {
        //不需要删除
        // if (this.state.playFlag) {
        //     // 如果在播放，则把rfsNum这个索引之前的数据删掉
        //     drawBufferMap[1].splice(0, rfsNum * this.state.chartSpeedTimes);
        // }
        aaa = pageScanValue * (pageScanScreenPointSum * pageScanPM);
        for (var i = 0; i < pageScanPM; i++) {
            const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group3_" + ((pageScanValue * pageScanPM) + i));
            // 创建心电图波形
            let ecgViewLightWave;
            if (aaaa == i) {
                ecgViewLightWave = this._getPageScanECGViewLightWave(((pageScanValue * pageScanPM) + i), huanhangValue);
            } else {
                ecgViewLightWave = this._getPageScanECGViewLightWave(((pageScanValue * pageScanPM) + i), 0);
            }

            if (ecgViewLightwaveGroup && ecgViewLightWave) {
                ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
            }
        }

    };

    _getECGViewText(index, prefix) {
        // const centerY = (yCellCount * cellWidth + winMarginY) / 2;
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;

        // 画导联的名称
        const chainName = "<text fill='" + lineColors[index] + "' x=0 y=" + zeroPoint + ">" + prefix + "CH" + (index + 1) + "</text>";
        let result = chainName;
        return result;
    }

    _getPageScanECGViewText(index, prefix) {
        // const centerY = (yCellCount * cellWidth + winMarginY) / 2;
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;
        const zeroPoint2 = (yCellCount - 3) * cellWidth;
        // 画导联的名称
        const chainName = "<text fill='" + lineColors[index] + "' x=0 y=" + (9 * PageScanResizeValue + 1) + ">" + prefix + (index) + "</text>";
        let result = chainName;
        return result;
    }

    _getPageScanECGViewText2(index, prefix) {
        // const centerY = (yCellCount * cellWidth + winMarginY) / 2;
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;
        const zeroPoint2 = (yCellCount - 3) * cellWidth;
        // 画导联的名称
        const chainName = "<text fill='" + lineColors[index] + "' x=" + (document.body.clientWidth - 265) + " y=" + (10 * PageScanResizeValue + 1) + ">" + prefix + (index) + "</text>";
        let result = chainName;
        return result;
    }

    _getPageScanECGViewBackground(index) {
        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * 5;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + 5 + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * 5;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + 5;
        }

        let result
        if (index % 2 != 0) {
            result = "<path stroke='rgb(0,0,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";
        } else {
            result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";
        }

        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (winMarginY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    }

    _getECGViewBackground() {
        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    }

    /**
     * 初始化一条执行作为心电图的初始状态
     * @private
     */
    _initPageScanEcgViewLightWave(index) {
        let path = "";
        let x = 0;
        for (let i = 0; i < pageScanScreenPointSum; i++) {
            // 计算前一个点
            let preY = zeroPointY;

            // 计算当前点
            let y = zeroPointY;

            if (i === 0) {
                // 定位第一个点
                path = path + "M" + winMarginX + "," + (9 * PageScanResizeValue);
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }
            x = x + pointSpace;
        }
        let result = "<g><path stroke='" + lineColors[index] + "' fill='none' strokeWidth=1 d='" + path + "'></path></g>";
        return result;
    }

    /**
     * 初始化一条执行作为心电图的初始状态
     * @private
     */
    _initEcgViewLightWave(index) {
        let path = "";
        let x = 0;
        for (let i = 0; i < screenPointSum; i++) {
            // 计算前一个点
            let preY = zeroPointY;

            // 计算当前点
            let y = zeroPointY;

            if (i === 0) {
                // 定位第一个点
                path = path + "M" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }
            x = x + pointSpace;
        }
        let result = "<g><path stroke='" + lineColors[index] + "' fill='none' stroke-dasharray='2 2' d='" + path + "'></path></g>";
        return result;
    }

    /** 画心电图的折线 */
    _getECGViewLightWave = (index) => {

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";

        const drawBuffer = drawBufferMap[index];
        if (!drawBuffer) {
            return;
        }

        let x = winMarginX;

        let result = "";
        for (let i = 0; i < screenPointSum; i++) {

            // 计算前一个点
            let preY = 0;
            if (i !== 0) {
                if (drawBuffer[i - 1].newPoint !== null) {
                    preY = drawBuffer[i - 1].newPoint;
                } else {
                    preY = drawBuffer[i - 1].point;
                    if (preY !== 0) {
                        preY = preY / gc;// * 0.000049;
                        preY = preY / (1 / (this.state.voltDiv / gridScale));
                        preY = preY * cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = zeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + zeroPointY;
                    }
                }
            }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (drawBuffer[i] === null || drawBuffer[i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (drawBuffer[i].newPoint !== null) {
                y = drawBuffer[i].newPoint;
            } else {
                y = drawBuffer[i].point;
                y = y / gc;// * 0.000049;
                y = y / (1 / (this.state.voltDiv / gridScale));
                y = y * cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = zeroPointY - y;
                } else {
                    y = Math.abs(y) + zeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                drawBuffer[i].newPoint = y;
            }

            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }


            x = x + pointSpace;

            // result = result + this._getECGViewLightWaveAnnotationLine(drawBuffer[i], x);
        }

        // console.log(result);

        result = result + "<path stroke='rgb(0,0,0)' fill='none' strokeWidth='1' d='" + path + "'></path>";
        return result;
    };

    /** 画心电图的折线 */
    _getPageScanECGViewLightWave = (index, value) => {

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";
        const paths = [];
        var drawBuffer;
        if (drawBufferMap) {
            drawBuffer = drawBufferMap[pageScanChValue - 1];
        } else {
            message.success("没有获取到数据")
        }
        if (!drawBuffer) {
            return;
        }

        // const timeTextArray = [];
        // const timeSvgArray = [];
        // const timeStampSvgArray = [];
        // 需要区分path是实线和虚线，有心电数据的实线，无心电数据的虚线，
        // 所以需要分段描绘
        const virtualPaths = [];
        let virtualPath = "";
        // cuokaiFileValue;
        // eventFileLenght;


        let x = winMarginX;
        let result = "";


        for (let i = 0; i < pageScanScreenPointSum; i++, aaa++) {
            //错开的地方
            if (aaa == cuokaiFileValue2 * eventFileLenght && aaa != 0) {
                // index++;
                // aaaa++;
                aaaa = index;
                huanhangFlag = true;
                if (huanhangFlag) {
                    huanhangValue = pageScanScreenPointSum - i;
                }
                i = pageScanScreenPointSum;
                console.log("huanhangValue:", huanhangValue)
                // break;
            }
            // 计算前一个点
            let preY = 0;
            // if (i !== 0 && !huanhangFlag) {
            //     if (drawBuffer[(index * pageScanScreenPointSum) - huanhangValue + i - 1].newPoint !== null) {
            //         preY = drawBuffer[(index * pageScanScreenPointSum) - huanhangValue + i - 1].newPoint;
            //     } else {
            //         preY = drawBuffer[(index * pageScanScreenPointSum) - huanhangValue + i - 1].point;
            //         if (preY !== 0) {
            //             preY = preY / gc;// * 0.000049;
            //             preY = preY / (1 / ((PageScanResizeValue * 5) / gridScale));
            //             preY = preY * cellWidth;
            //         }
            //         // 如果是正值，则需要以zeroPoint - 该值，
            //         // 如果是负值，则需要以zeroPoint + 该值的绝对值
            //         if (preY > 0) {
            //             preY = zeroPointY - preY;
            //         } else {
            //             preY = Math.abs(preY) + zeroPointY;
            //         }
            //     }
            // } else if (i !== 0 && huanhangFlag) {
            if (i !== 0) {
                if (drawBuffer[(index * pageScanScreenPointSum) + i - value - 1].newPoint !== null) {
                    preY = drawBuffer[(index * pageScanScreenPointSum) + i - value - 1].newPoint;
                } else {
                    preY = drawBuffer[(index * pageScanScreenPointSum) + i - value - 1].point;
                    if (preY !== 0) {
                        preY = preY / gc;// * 0.000049;
                        preY = preY / (1 / ((PageScanResizeValue * 5) / gridScale));//PageScanResizeValue * 5
                        preY = preY * cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = pageScanZeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + pageScanZeroPointY;
                    }
                }
            }
            // }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (drawBuffer[(index * pageScanScreenPointSum) - value + i] === null
                || drawBuffer[(index * pageScanScreenPointSum) - value + i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (drawBuffer[(index * pageScanScreenPointSum) - value + i].newPoint !== null) {
                y = drawBuffer[(index * pageScanScreenPointSum) - value + i].newPoint;
            } else {
                y = drawBuffer[(index * pageScanScreenPointSum) - value + i].point;
                y = y / gc;// * 0.000049;
                y = y / (1 / ((PageScanResizeValue * 5) / gridScale));
                y = y * cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = pageScanZeroPointY - y;
                } else {
                    y = Math.abs(y) + pageScanZeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                drawBuffer[(index * pageScanScreenPointSum) + i - value].newPoint = y;
                // if ((0.03178914261702914) != Math.abs(y - preY) && (0.03178914261701493) != Math.abs(y - preY)) {
                //     console.log("差值:", y - preY, index, (index * pageScanScreenPointSum) - value + i);
                // }

            }


            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + winMarginX + "," + (PageScanResizeValue * 9);
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }


            x = x + pointSpace;

            // result = result + this._getECGViewLightWaveAnnotationLine(drawBuffer[i], x);
        }
        // console.log(result);

        result = result + "<path stroke='rgb(0,0,0)' fill='none' strokeWidth='1' d='" + path + "'></path>";
        return result;
    };

}


function mapStateToProps(store) {
    return {
        allDevices: store.EcgDeviceReducer.allDevices,
        ecgDateIndexData: store.EcgHistoryReducer.ecgDateIndexData,
        ecgDataIndexEffectiveRecords: store.EcgHistoryReducer.ecgDataIndexEffectiveRecords,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        ecgTimeIndexData: store.EcgHistoryReducer.ecgTimeIndexData,
        ecgTimeSlideMarks: store.EcgHistoryReducer.ecgTimeSlideMarks,
        timeRecordIndex: store.EcgHistoryReducer.timeRecordIndex,
        ecgEventIndexData: store.EcgHistoryReducer.ecgEventIndexData,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        deviceCode: store.EcgDeviceReducer.deviceCode,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        ecgDateIndexRecord: store.EcgDeviceReducer.ecgDateIndexRecord,
        user: store.AccountUserReducer.user,
        channelType: store.EcgDeviceReducer.channelType,
        version: store.EcgDeviceReducer.version,
        mapVersion: store.EcgDeviceReducer.mapVersion,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));
