/**
 * Created by Ethan on 17/1/13.
 */

import { ORG_SERVER_HOST} from '../../constants/Profile';


export const getSessionOrg = ORG_SERVER_HOST + "/org/employees/{id}";


// 组织
export const getOrganizationTree = ORG_SERVER_HOST + "/org/organizations/tree";
export const searchOrganizations = ORG_SERVER_HOST + "/org/organizations/queries";
export const getOrganization = ORG_SERVER_HOST + "/org/organizations/{id}";
export const createOrganization = ORG_SERVER_HOST + "/org/organizations";
export const updateOrganization = ORG_SERVER_HOST + "/org/organizations/{id}";
export const deleteOrganization = ORG_SERVER_HOST + "/org/organizations/{id}";

// 员工
export const searchEmployees = ORG_SERVER_HOST + "/org/employees/queries";
export const getEmployee = ORG_SERVER_HOST + "/org/employees/{id}";
export const getEmployeeByUserId = ORG_SERVER_HOST + "/org/employee?userId={userId}";
export const registerEmployee = ORG_SERVER_HOST + "/openAPI/org/employees";
export const createEmployee = ORG_SERVER_HOST + "/org/employees";
export const updateEmployee = ORG_SERVER_HOST + "/org/employees/{id}";
export const deleteEmployee = ORG_SERVER_HOST + "/org/employees/{id}";

