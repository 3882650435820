/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Menu, Icon} from 'antd';

class MainMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onMenuClick = ({item, key, keyPath, domEvent}) => {
        this.props.onMenuClick && this.props.onMenuClick.call(this, item, key, keyPath, domEvent);
    };

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <Menu onClick={this.onMenuClick} mode="horizontal">
                <Menu.Item key="file">
                    <Icon type="folder-open"/>
                    文件
                </Menu.Item>
                <Menu.Item key="undo">
                    <Icon type="undo"/>
                    撤销
                </Menu.Item>
                <Menu.Item key="redo">
                    <Icon type="redo"/>
                    重做
                </Menu.Item>
                <Menu.Item key="save">
                    <Icon type="save"/>
                    保存
                </Menu.Item>
                <Menu.Item key="delete">
                    <Icon type="delete"/>
                    删除
                </Menu.Item>
                <Menu.Item key="select">
                    <Icon type="select"/>
                    选择模式
                </Menu.Item>
                <Menu.Item key="check-on">
                    <Icon type="check-square"/>
                    全选中
                </Menu.Item>
                < Menu.Item key="check-off">
                    <Icon type="border"/>
                    全不选
                </Menu.Item>
                <Menu.Item key="multiple">
                    <Icon type="bulb"/>
                    批量标注
                </Menu.Item>
                <Menu.Item key="frame">
                    <Icon type="profile"/>
                    Frame选择查看
                </Menu.Item>
                <Menu.Item key="previous">
                    <Icon type="caret-left"/>
                    前一个
                </Menu.Item>
                <Menu.Item key="next">
                    <Icon type="caret-right"/>
                    后一个
                </Menu.Item>
                <Menu.Item key="ruler-w">
                    <Icon type="column-width"/>
                    标尺(W)
                </Menu.Item>
                <Menu.Item key="ruler-h">
                    <Icon type="column-height"/>
                    标尺(H)
                </Menu.Item>
                <Menu.Item key="ruler-clear">
                    <Icon type="dash"/>
                    标尺清除
                </Menu.Item>
                <Menu.Item key="screen-shot">
                    <Icon type="camera"/>
                    截图
                </Menu.Item>
                {/*<Menu.Item key="picture">*/}
                    {/*<Icon type="picture"/>*/}
                    {/*图像显示*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="zoom">
                    <Icon type="zoom-in"/>
                    局部放大
                </Menu.Item>
                <Menu.Item key="overlap">
                    <Icon type="vertical-align-middle" />
                    重叠导联
                </Menu.Item>
            </Menu>
        );
    }
}

const mapStateToProps = (store) => {
    return {}
};

export default connect(mapStateToProps)(injectIntl(MainMenu));
