/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    Modal,
    Button,
    Tabs, Popconfirm
} from "antd";

import moment from 'moment';

import {
    deleteDeviceRedis,
    getDeviceById, getRedisData, getUserMemoList,
    refreshDevice, refreshSelectDeviceId, toggleCacheModal,
    toggleDetailModal,
} from "../../../actions/DeviceAction";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";

import DetailModalDeviceTab from "./DetailModalDeviceTab";
import DetailModalRouteEventTab from "./DetailModalRouteEventTab";
import DeviceMapTab from "./DeviceMapTab";
import CacheDeviceModal from "./CacheDeviceModal";
import {RoutePath} from "../../../../../constants/RoutePath";
import RealTimeModel from "../../ECG/RealTime/Index"
import HistoryModel from "../../ECG/History/Day/Index"
import LorenzModel from "../../ECG/History/Lorenz/Index"
import HistoryStatis from "../../ECG/History/Statis/Index"
import LorenzSampleModel from "../../ECG/History/Lorenz/Index2"
import UserRecordModel from "../User/Index"
import CalendarModel from "../../ECG/History/Calendar/Index"
import {refreshRealtimeScreenPointSum} from "../../../actions/LogDataServerAction";
import HttpUtils from "../../../../../utils/HttpUtils";
import {refreshActiveKey} from "../../../../../actions/AppAction";
import message from "../../../../../components/toast";
import {getDateIndexFile, refreshEcgDateIndexFlag} from "../../../actions/HistoryAction";
import DeviceDoctorTab from "./DeviceDoctorTab";

let isShow = [];
let lorenzFlag = false

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            editNameFlag: false,
            inputReadFileIndexFlag: false,
            inputReadFileIndex: 0,
            defaultActiveKey: "4",
            recordValue: {},
            historyIndex: 0,
            historyFlag: true,
            lorenzFlag: false,
            eventNumDevice: 0,
            eventNumAPP: 0,
            EventFile: [],
        }
    }

    componentWillMount() {
        console.log("tabIndex:" + HttpUtils.getQueryString("tabIndex"));
        isShow[9] = true;
        let tabIndex = HttpUtils.getQueryString("tabIndex");
        if (tabIndex !== undefined) {
            this.setState({defaultActiveKey: tabIndex});
        }
        //     function scroll(color) {
        //         let scrollStyle = `
        //   <style css-id="scroll">
        //     /* 滚动条 */
        //     /*定义整个滚动条高宽及背景：高宽分别对应横竖滚动条的尺寸*/
        //     ::-webkit-scrollbar
        //     {
        //         width:30px;
        //         background-color:#F5F5F5;
        //     }
        //     /*定义滚动条轨道：内阴影+圆角*/
        //     ::-webkit-scrollbar-track
        //     {
        //         background-color:#F5F5F5;
        //     }
        //     /*定义滑块：内阴影+圆角*/
        //     ::-webkit-scrollbar-thumb
        //     {
        //         border-radius:10px;
        //         background-color:${color};
        //     }
        //
        //   </style>
        //
        // `
        //         let div = document.createElement("div");
        //         let _scrollNode = document.querySelector("[css-id='scroll']") || null;
        //         if (_scrollNode) {
        //             document.querySelector("head").removeChild(document.querySelector("[css-id='scroll']"));
        //         }
        //         div.innerHTML = scrollStyle;
        //         let newScrollNode = div.querySelector("[css-id='scroll']");
        //         document.getElementsByTagName("head")[0].appendChild(newScrollNode)
        //     }
        //
        //     scroll('red');
    }

    componentWillReceiveProps(nextProps, nextContext) {

        // if (chann != nextProps.allDevices) {
        // }

    }


    componentWillUnmount() {
        clearInterval(this.intervalRefresh);
    }

    componentDidMount() {
        this.props.dispatch(refreshActiveKey(this._setDefaultActiveKey));
        console.log("refreshActiveKey");
    }

    _handleRefresh() {
        this.props.dispatch(getDeviceById(this.props.device.id, this.state.formatMessage));
    }

    _handleCancel() {
        this.props.dispatch(refreshDevice({}));
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleInit() {
        this.props.dispatch(deleteDeviceRedis(this.props.device.id, this.state.formatMessage));
    }

    _handleShowCacheModal() {
        this.props.dispatch(getRedisData(this.props.device.code, this.state.formatMessage));
        this.props.dispatch(toggleCacheModal(true));
    }

    _handleEnclosure(name, data) {
        for (let i = 0; i < isShow.length; i++) {
            isShow[i] = false;
        }
        isShow[data] = true
        if (this.historyModel) {
            this.historyModel._handleStopPlay();
        }
        switch (Number(data)) {

            case 1:

                break
            case 2:
            // this.props.dispatch(getDateIndexFile(this.props.deviceCode, this.props.channelType, this.props.version, "detailIndex"));
            case 10:
                const getUserMemoListParem = {
                    "deviceCode": this.props.deviceCode,//zhi
                    // "deviceCodeInGuard": "72020000",
                    "generationId": this.props.mapVersion[0],
                    // "generationIdInGuard": "0001_20220501000000",
                    // "userMemo": "信号",
                    // "userId": "205f459b7de3454db3a5e954e481385e"
                    // "createTime": "20221209091839793"
                    // "memoTimeFrom": "20221107114447362",
                    // "memoTimeTo": "20221107114447363",
                }
                this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))

                break
            case 3:

                break
            case 4:
                this.props.dispatch(refreshRealtimeScreenPointSum(180));
                break
            case 5:
                this.props.dispatch(refreshRealtimeScreenPointSum(180));
                break
            case 6:
                lorenzFlag = true;
                this.setState({lorenzFlag:true})
                break
            case 7:

                break
            case 8:

                break
            case 9:

                if (this.historyStatis) {
                    this.historyStatis._r5();
                }
                break
            case 11:
                // console.log("this.props.deviceValue.id:" + this.props.deviceValue.id);
                // this.props.dispatch(refreshSelectDeviceId(this.props.deviceValue.id));
                break
        }
        this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + this.props.deviceCode + "&tabIndex=" + data);
        this.setState({defaultActiveKey: data + ""})
    }

    _setDefaultActiveKey = value => {
        this.setState({
            defaultActiveKey: value,
        });

    };

    _setRecordValue = value => {
        this.setState({
            recordValue: value,
        });
    }

    _setHistoryIndex = value => {
        this.setState({
            historyIndex: value,
        });
    }

    _setHistoryFlag = value => {
        this.setState({
            historyFlag: value,
        });
    }

    _setEventNumDevice = value => {
        this.setState({
            eventNumDevice: value,
        });
    }
    _setEventNumAPP = value => {
        this.setState({
            eventNumAPP: value,
        });
    }

    _setEventFile = value => {
        this.setState({
            EventFile: value,
        });
    }

    _setLorenzFlag = value => {
        lorenzFlag = value
        this.setState({lorenzFlag:value})
    }


    _back() {
        // this.props.router.push(RoutePath.DAS_DEVICE);
        this.props.router.push(RoutePath.DAS_DEVICE_LIST.path);
    }

    historyRef = (ref) => {
        this.historyStatis = ref
    }

    historyModelRef = (ref) => {
        this.historyModel = ref
    }

    render() {
        const {device} = this.props;
        // console.log("deviceValue", this.props.deviceValue)
        // console.log("this.props.deviceValue.id:" + this.props.deviceValue.id);
        const formatMessage = this.state.formatMessage;
        const lastUpdateTime = (device.lastUpdateTime && device.lastUpdateTime !== '') ?
            formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_REDIS_UPDATE_TIME"}) + "：" + moment(device.lastUpdateTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : "";

        return (
            <div className="device-detail" style={{paddingLeft: "10px"}}>
                <CacheDeviceModal/>
                <Tabs defaultActiveKey={"1"}
                    // destroyInactiveTabPane
                      activeKey={this.state.defaultActiveKey}
                      onChange={(data) => {
                          this._handleEnclosure("version", data)
                      }}
                      tabBarExtraContent={<div>
                          <Button onClick={this._back.bind(this)} className="spacing-h">
                              返回
                          </Button>
                      </div>}
                >
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_DETAIL'})} key="1">
                        <DetailModalDeviceTab isShow={isShow[1]}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_DATA_STATISTICS'})} key="9">
                        <HistoryStatis isShow={isShow[9]}
                                       historyRef={this.historyRef}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_ROUTE_REAL_TIME'})} key="4">
                        <RealTimeModel
                            isShow={isShow[4]}
                            eventNumDevice={this.state.eventNumDevice}
                            eventNumAPP={this.state.eventNumAPP}
                            setEventNumDevice={this._setEventNumDevice}
                            setEventNumAPP={this._setEventNumAPP}
                            EventFile={this.state.EventFile} setEventFile={this._setEventFile}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={"实时异常事件(Dev:" + this.state.eventNumDevice + "/App:" + this.state.eventNumAPP + ")"}
                        key="8">
                        <DetailModalRouteEventTab dataSource={this.state.EventFile}
                                                  setDefaultActiveKey={this._setDefaultActiveKey}
                                                  setRecordValue={this._setRecordValue}
                                                  setHistoryIndex={this._setHistoryIndex}
                                                  setHistoryFlag={this._setHistoryFlag}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_ROUTE_HISTORY_DATE'})} key="2">
                        <CalendarModel {...this.props} setDefaultActiveKey={this._setDefaultActiveKey}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_ROUTE_HISTORY'})} key="5">
                        <HistoryModel deviceCode={this.props.deviceCode}
                                      historyModelRef={this.historyModelRef}
                                      setDefaultActiveKey={this._setDefaultActiveKey}
                                      setHistoryFlag={this._setHistoryFlag}
                                      recordValue={this.state.recordValue}
                                      historyFlag={this.state.historyFlag}
                                      historyIndex={this.state.historyIndex}
                                      isShow={isShow[5]}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'ECG_CASE_ROUTE_PHYSICIANS_REPORT'})} key="11">
                        <DeviceDoctorTab deviceCode={this.props.deviceCode} deviceId={this.props.deviceValue.id} isShow={isShow[11]}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_MAP'})} key="3">
                        <DeviceMapTab/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_ROUTE_LORENZ'})} key="6">
                        <LorenzModel lorenzFlag={lorenzFlag} setLorenzFlag={this._setLorenzFlag}/>
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_ROUTE_SAMPLE_LORENZ'})} key="7">*/}
                    {/*    <LorenzSampleModel/>*/}
                    {/*</Tabs.TabPane>*/}
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_ECG_USER_RECORD'})} key="10">
                        <UserRecordModel deviceCode={this.props.deviceCode}/>
                    </Tabs.TabPane>
                </Tabs>

            </div>

            // </Modal>
        );
    }

}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceReducer.isLoading,
        device: store.EcgDeviceReducer.device,
        showDetailModal: store.EcgDeviceReducer.showDetailModal,
        mapVersion: store.EcgDeviceReducer.mapVersion,
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));