const FileUtils = {
    getFileName: (fileName) => {
        return fileName.substring(0, fileName.lastIndexOf("."));
    },
    save: (blob, fileName) => {

        // console.log(window);
        // const aLink = document.createElement('a');
        // document.body.appendChild(aLink);
        // aLink.style.display = 'none';
        // const objectUrl = window.URL.createObjectURL(blob);
        // aLink.href = objectUrl;
        // aLink.download = fileName;
        // aLink.click();
        // document.body.removeChild(aLink);

        let type = blob.type;

        let force_saveable_type = 'application/octet-stream';

        if (type && type !== force_saveable_type) { // 强制下载，而非在浏览器中打开

            let slice = blob.slice || blob.webkitSlice || blob.mozSlice;

            blob = slice.call(blob, 0, blob.size, force_saveable_type);

        }

        // window.URL.createObjectURL(blob);
        // Failed to execute ‘createObjectURL’ on ‘URL’: Overload resolution failed.
        // 百度了下，是因为Chrome更新后不支持这种用法，需要改为：
        let binaryData = [];
        binaryData.push(blob);
        let url = window.URL.createObjectURL(new Blob(binaryData));

        let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');

        save_link.href = url;

        save_link.download = fileName;

        let event = document.createEvent('MouseEvents');

        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);

        save_link.dispatchEvent(event);

        URL.revokeObjectURL(url);
    },
};

export default FileUtils;