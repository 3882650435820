/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Table, Divider, Popconfirm, Button, Modal} from 'antd';//, Drawer, Row, Col

import PaginationBar from "../../../../components/PaginationBar";

import {
    searchBinders,
    getBinderDevices,
    toggleDeviceListModal,
    refreshBinder,
    getBinderGuarders,
    toggleGuarderListModal,
    deleteBinder,
    refreshSearchCondition, toggleDeviceBindModal, unbindDevice
} from '../../actions/BinderAction';
import {CACHE_SESSION_USER} from "../../../../constants/Profile";

// import {Modal} from "antd/lib/index";
// import {deleteDevice} from "../../../actions/DeviceAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            showDetail: false,
            columns: [
                {
                    key: 'code',
                    dataIndex: 'code',
                    width: 300,
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})
                },
                {
                    key: 'name',
                    dataIndex: 'name',
                    width: 200,
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})
                },
                {
                    key: 'mac',
                    dataIndex: 'mac',
                    width: 300,
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }


    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleUnbind.bind(this, record)}>
                    <FormattedMessage id="DAS_DEVICE_BINDER_BUTTON_UNBIND"/>
                </a>
            </div>
        )
    }

    _handleUnbind(record) {
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        const {dispatch, binder} = this.props;
        const formatMessage = this.state.formatMessage;
        console.log("4:" + formatMessage);
        Modal.confirm({
            title: formatMessage({id: "ECG_BINDER_TITLE_DEVICE_UNBIND"}),
            content: formatMessage({id: "ECG_BINDER_MSG_UNBIND_CONFIRM"}),
            okType: 'danger',
            okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
            cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
            onOk() {
                console.log("1:" + sessionUser.id + ",2:" + record.id + ",3:" + formatMessage);
                dispatch(unbindDevice(sessionUser.id, record.id, formatMessage));
            }
        })
    }

    _handleGetDevices(record) {
        this.props.dispatch(refreshBinder(record));
        console.log("4:" + this.state.formatMessage);
        this.props.dispatch(getBinderDevices(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleDeviceListModal(true));
    }

    _handleGetGuarders(record) {
        this.props.dispatch(refreshBinder(record));
        this.props.disp0atch(getBinderGuarders(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleGuarderListModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteBinder(record, searchCondition, formatMessage));
    }

    render() {
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        return (
            <Card className="spacing-v page-width">
                {/*<PaginationBar pagination={this.props.pagination}*/}
                {/*               onChange={(sessionUser) => this._handlePagination(sessionUser)}/>*/}
                <div className="text-right">
                    <Button size="small" onClick={() => this.props.dispatch(toggleDeviceBindModal(true))}>
                        <FormattedMessage id="ECG_BINDER_BUTTON_BIND_DEVICE"/>
                    </Button>
                </div>
                <div className="spacing-v">
                    <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                           dataSource={this.props.binderDevices} pagination={false}/>
                </div>
            </Card>
        )
    }

    _handlePagination(sessionUser) {
        // let searchCondition = Object.assign({}, this.props.searchCondition);
        // searchCondition.currentPage = page;
        // this.props.dispatch(refreshBinder(record));
        this.props.dispatch(getBinderDevices(sessionUser.id, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        binders: store.BinderReducer.binders,
        searchCondition: store.BinderReducer.searchCondition,
        pagination: store.BinderReducer.pagination,
        binderDevices: store.BinderReducer.binderDevices
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));