/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    ncovDataList: [],
    countryStatistic: {},
    provinceStatistic: {}
};

const NcovReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DAS_NCOV_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DAS_NCOV_GET_DATA:
            return Object.assign({}, state, {
                ncovDataList: action.ncovDataList
            });
        case ActionTypes.DAS_NCOV_STATISTIC:
            return Object.assign({}, state, {
                countryStatistic: action.countryStatistic,
                provinceStatistic: action.provinceStatistic
            });
        default:
            return state
    }
};

export default NcovReducer;