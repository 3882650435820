/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";

export function refreshVillage(village) {
    return {
        type: ActionTypes.DISTRICT_VILLAGE_REFRESH_VILLAGE,
        village: village
    }
}

export function refreshVillageMap(villageMap) {
    return {
        type: ActionTypes.DISTRICT_VILLAGE_REFRESH_VILLAGE_MAP,
        villageMap: villageMap
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_VILLAGE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getVillagesByTownId(townId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: true});

        const requestURL = WebService.getVillagesByTownId.replace("{id}", townId);
        HttpUtils.get(requestURL, (response) => {

            dispatch({
                type: ActionTypes.DISTRICT_VILLAGE_SEARCH,
                towns: response.data
            });

            dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getVillage(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: true});
        const requestURL = WebService.getVillageById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshVillage(response.data));
            dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createVillage(village, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createVillage, village, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshVillage({}));
            dispatch(getVillagesByTownId(response.data.townId, formatMessage));
            dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateVillage(village, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: true});

        const requestURL = WebService.updateVillageById.replace("{id}", village.id);
        HttpUtils.put(requestURL, village, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshVillage({}));
            dispatch(getVillagesByTownId(response.data.townId, formatMessage));

            dispatch({type: ActionTypes.DISTRICT_VILLAGE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteVillage(village, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByVillageId.replace("{id}", village.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getVillagesByTownId(response.data.townId, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



