/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Row, Col} from "antd";
import message from "../../../../components/toast";

import EcgFileSourceModal from "./EcgFileSourceModal";
import {
    refreshAnnotationsMap, refreshEcgRulersMap, saveAnnotations, searchAnnotations
} from "../../actions/DeviceFileAnnotationAction";
import {
    readAnnotationFile,
    readMixIndexFile,
    readTimeIndexFile, writeAnnotationFile
} from "../../actions/HistoryAction";
import {AnnotationFileSourceType, EcgLightWaveType} from "../../Enums";
import TimeIndexSlider from "../ECG/History/Day/TimeIndexSlider";

import EcgDrawUtils from "../../utils/EcgDrawUtils";
import MainMenu from "./MainMenu";
import {
    FORMAT_DATE_TIME_FULL_SIMPLE, FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../constants/DateTimeFormats";
import AnnotationModal from "./AnnotationModal";
import FrameSelectModal from "./FrameSelectModal";
import moment from "moment";
import MultiAnnotationModal from "./MultiAnnotationModal";

import "./Index.css";
import PageTitle from "./PageTitle";

import HexUtils from "../../../../utils/HexUtils";
import EcgRuler from "../../entities/EcgRuler";

import domtoimage from 'dom-to-image';
import EcgLightWave from "../../entities/EcgLightWave";
import EcgSelectRectangle from "../../entities/EcgSelectRectangle";
import CheckUpdateVersion from "../../../CMS/components/CheckUpdateVersion";
import MedfiltDeWander from "../../utils/MedfiltDeWander";

// 用作Undo/Redo的操作历史列表
// 为了实现Undo，Redo，需要把步骤都保存到该列表中
// 对象结构如：{action:createAnnotation, data:{before:annotation, after: annotation}}
let undoList = [];
let redoList = [];

// 一个心电文件的样本数，解析出心电历史文件时赋值，
// 在计算FrameSelectModal的开始位置和结束位置时需要用到
let sampleNum = 0;

// 移动中的虚拟标注对象
let movingAnnotation = null;

// 心电图对象
let ecgLightWave;
let ecgLightWaveOverlap;

// 中值滤波的处理对象
let medfiltDeWander = null;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            timeFileIndex: 0,
            eventFileIndex: 0,
            sliderValue: 0,
            showControl: true,
            recordDate: "",
            sliderMarks: {},
            backgroundDisplay: true,
            // // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // // 一个格子是5mm，所以一个格子代表0.5mv
            // voltDiv: 10,
            // 走纸倍数
            // chartSpeedTimes: 1,
            combinePaint: false,
            // 工具栏的选择模式按钮
            selectModal: false,
            // 心电文件源显示画面
            showEcgFileSourceModal: false,
            // 批量标注画面
            showMultiAnnotationModal: false,
            // Frame查看画面
            showFrameSelectModal: false,
            // 当前文件的文件头对象信息
            ecgFileCommonHeader: {},
            // 当前文件的解析结果信息对象
            ecgFileMixData: {},
            // 时间索引文件对象
            ecgFileTimeIndex: {},
            // 事件索引文件对象
            ecgFileEventIndex: {},
            fileSourceType: AnnotationFileSourceType.Enum.D9K_LOCAL,
            ctrlKeyDown: false,
            shiftKeyDown: false,
            ecgClickPointIndex: -1,
            // D9K本地心电数据库根目录
            d9kEcgDatabaseRoot: "",
            // 画标尺的功能
            rulerWOperationChecked: false,
            rulerHOperationChecked: false,
            ecgRuler: {},
            // 移动的时候绘制虚拟的尺度对象
            ecgRulerVirtual: {},
            ecgSelectRectangle: {},
            zoomModal: false,
            overlapSelectMode: false,
            selectArea: {}
        }
    }

    componentWillMount() {
        this._initlizeMedfiltDeWander();
    }

    componentWillUnmount() {
        //恢复页面选择以及鼠标右键
        document.oncontextmenu = function () {
            return true;
        };
        document.onselectstart = function () {
            return true;
        };
    }

    /** 初始化动作 */
    componentDidMount() {
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
        this._calcParams();
        // this._drawECG(this.state.combinePaint);

        let ecgData = {};
        if (this.state.ecgFileMixData && this.state.ecgFileMixData.polymRegions && this.state.ecgFileMixData.polymRegions.length > 0) {
            const ecgPointPolymRegion = this.state.ecgFileMixData.polymRegions[0];
            ecgData = ecgPointPolymRegion.ecgData
        }

        let annotationMap = {};
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (this.props.annotationsMap[fileName]) {
            annotationMap = this.props.annotationsMap[fileName];
        }
        ecgLightWave.render(ecgData, annotationMap);
        ecgLightWaveOverlap.render(ecgData, annotationMap);

        const self = this;
        // 监听键盘事件，处理ctrl和shift + 鼠标的选择
        window.addEventListener('keydown', function (event) {
            console.log(event);
            let selectedAnnotations = [];
            switch (event.key) {
                case "Control":
                    self.setState({ctrlKeyDown: true});
                    break;
                case "Shift":
                    self.setState({shiftKeyDown: true});
                    break;
                case "Backspace":
                    selectedAnnotations = self._getSelectedAnnotations();
                    self._removeAnnotation(selectedAnnotations, false);
                    break;
                case "ArrowRight":
                    selectedAnnotations = self._getSelectedAnnotations();
                    if (selectedAnnotations.length === 1) {
                        self._moveAnnotation(selectedAnnotations[0].pointIndex, selectedAnnotations[0].pointIndex + 1, true);
                        self._setAnnotationsAttribute(selectedAnnotations[0].pointIndex, "onclick");
                    }
                    break;
                case "ArrowLeft":
                    selectedAnnotations = self._getSelectedAnnotations();
                    if (selectedAnnotations.length === 1) {
                        self._moveAnnotation(selectedAnnotations[0].pointIndex, selectedAnnotations[0].pointIndex - 1, true);
                        self._setAnnotationsAttribute(selectedAnnotations[0].pointIndex, "onclick");
                    }
                    break;
            }
        });
        window.addEventListener('keyup', function (event) {
            // console.log(event);
            // Cancel the default action, if needed
            event.preventDefault();
            self.setState({ctrlKeyDown: false, shiftKeyDown: false});
        });

        //禁止页面选择以及鼠标右键
        document.oncontextmenu = function () {
            self._clearMenuState();
            return false;
        };
        document.onselectstart = function () {
            return false;
        };

        // 添加鼠标悬浮事件监听，以获取辅助蓝线的描画坐标
        const ecgSvgElements = document.getElementsByClassName("svgplot");
        for (let ecgSvgElement of ecgSvgElements) {
            // 在心电图上双击添加标注
            ecgSvgElement.ondblclick = function (e) {

                // 非选择模式，才允许做标记
                if (!self.state.zoomModal && !self.state.selectModal && self.state.ecgFileMixData && self.state.ecgFileMixData.polymRegions && self.state.ecgFileMixData.polymRegions.length > 0) {
                    // 事件的layerX和layerY来计算当前点到了哪个点位置，保存该点的index
                    const ecgClickX = e.layerX - ecgLightWave.winMarginX;
                    const ecgClickY = e.layerY - ecgLightWave.winMarginY;
                    console.log(ecgClickX, ecgClickY);
                    const ecgClickPointIndex = (ecgClickX / ecgLightWave.pointSpace);
                    // console.log(ecgClickPointIndex);
                    // console.log(self.state.ecgFileMixData.polymRegions[0][0][ecgClickPointIndex]);

                    // 点击的范围超出绘制的点数，不处理
                    const ecgPointPolymRegion = self.state.ecgFileMixData.polymRegions[0];
                    if (ecgPointPolymRegion.ecgData[0][ecgClickPointIndex]) {
                        const selectEcgPoint = ecgPointPolymRegion.ecgData[0][ecgClickPointIndex];
                        const annotation = {
                            id: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE),
                            deviceCode: self.state.deviceCode,
                            recordDate: self.state.recordDate,
                            historyFileIndex: selectEcgPoint.historyFileIndex,
                            fileName: self._getEcgFileName(self.state.sliderValue),
                            pointIndex: selectEcgPoint.index,
                            // screenPointIndex: ecgClickPointIndex,
                            annotation: "NULL"
                        };
                        self._addAnnotation(annotation);
                        // self.props.dispatch(refreshAnnotation(annotation));
                        // self.props.dispatch(toggleAnnotationModal(true));
                    }
                }
            };
            ecgSvgElement.onmousedown = function (e) {
                const ecgClickX = e.layerX - ecgLightWave.winMarginX;
                const ecgClickY = e.layerY - ecgLightWave.winMarginY;
                const ecgClickPointIndex = (ecgClickX / ecgLightWave.pointSpace);

                if (self.state.rulerWOperationChecked ||
                    self.state.rulerHOperationChecked) {
                    // 如果是标尺操作，则需要描述出心电数据之间的距离
                    const ecgRuler = new EcgRuler();
                    ecgRuler.startX = e.layerX;
                    ecgRuler.startY = e.layerY;

                    const ecgRulerVirtual = new EcgRuler("ecg_ruler_virtual");
                    ecgRulerVirtual.startX = e.layerX;
                    ecgRulerVirtual.startY = e.layerY;

                    self.setState({ecgRuler: ecgRuler, ecgRulerVirtual: ecgRulerVirtual});
                } else if (self.state.overlapSelectMode) {
                    const ecgSelectRectangle = new EcgSelectRectangle();
                    ecgSelectRectangle.startX = e.layerX;
                    ecgSelectRectangle.startY = e.layerY;
                    self.setState({ecgSelectRectangle: ecgSelectRectangle});
                } else {
                    // console.log(ecgClickPointIndex);
                    if (!self.state.ctrlKeyDown) {
                        self._unselectAnnotationElements();
                    }

                    if (self.state.shiftKeyDown) {
                        if (self.state.ecgClickPointIndex >= 0) {
                            self._selectAnnotationElements(self.state.ecgClickPointIndex, ecgClickPointIndex);
                        }
                    } else {
                        self._selectAnnotationElements(ecgClickPointIndex - 20, ecgClickPointIndex + 20);
                    }

                    self.setState({
                        ecgClickPointIndex: ecgClickPointIndex
                    });
                }
            };
            // 鼠标移入小图内才会显示遮罩和跟随移动样式，移出小图后消失
            ecgSvgElement.onmouseenter = function (e) {
                if (self.state.zoomModal) {
                    const idSuffix = ecgSvgElement.id.substring(ecgSvgElement.id.lastIndexOf("_") + 1);
                    const ecgSvgZoomMark = document.getElementById("ecg_view_zoom_mark_" + idSuffix);
                    ecgSvgZoomMark.innerHTML = ecgSvgElement.outerHTML;
                    self._clearChildNodeId(ecgSvgZoomMark.childNodes[0]);
                    ecgSvgZoomMark.childNodes[0].setAttribute("viewBox", e.offsetX + " 0 100 100");
                    ecgSvgZoomMark.style.display = "block";
                    ecgSvgZoomMark.style.left = e.offsetX + 60 + "px";
                    ecgSvgZoomMark.style.top = e.offsetY + 60 + "px";
                }
            };
            ecgSvgElement.onmouseleave = function (e) {
                if (self.state.zoomModal) {
                    const idSuffix = ecgSvgElement.id.substring(ecgSvgElement.id.lastIndexOf("_") + 1);
                    const ecgSvgZoomMark = document.getElementById("ecg_view_zoom_mark_" + idSuffix);
                    ecgSvgZoomMark.style.display = "none";
                }
            };
            ecgSvgElement.onmousemove = function (e) {

                if (self.state.zoomModal) {
                    const idSuffix = ecgSvgElement.id.substring(ecgSvgElement.id.lastIndexOf("_") + 1);
                    const ecgSvgZoomMark = document.getElementById("ecg_view_zoom_mark_" + idSuffix);
                    ecgSvgZoomMark.childNodes[0].setAttribute("viewBox", e.offsetX + " 0 100 100");
                    ecgSvgZoomMark.style.left = e.offsetX + 60 + "px";
                    ecgSvgZoomMark.style.top = e.offsetY + 60 + "px";
                } else if (self.state.rulerWOperationChecked || self.state.rulerHOperationChecked) {

                    // 绘制新的节点
                    const ecgRulerVirtual = self.state.ecgRulerVirtual;
                    if (ecgRulerVirtual && ecgRulerVirtual.startX && ecgRulerVirtual.startY) {
                        ecgRulerVirtual.endX = e.layerX;
                        ecgRulerVirtual.endY = e.layerY;

                        // 查找出已有的节点数据，需要被替换
                        const ecgRulerVirtualNode = document.getElementById("ecg_ruler_virtual");

                        if (self.state.rulerWOperationChecked) {
                            // 算出前后时间差
                            ecgRulerVirtual.calculateDistance_W(ecgLightWave.pointSpace, ecgLightWave.sampRate);
                            if (ecgRulerVirtualNode) {
                                ecgSvgElement.innerHTML = ecgSvgElement.innerHTML.replace(ecgRulerVirtualNode.outerHTML, ecgRulerVirtual.toSVG_W());
                            } else {
                                ecgSvgElement.innerHTML += ecgRulerVirtual.toSVG_W();
                            }
                        } else {
                            ecgRulerVirtual.calculateDistance_H(ecgLightWave.cellWidth);
                            if (ecgRulerVirtualNode) {
                                ecgSvgElement.innerHTML = ecgSvgElement.innerHTML.replace(ecgRulerVirtualNode.outerHTML, ecgRulerVirtual.toSVG_H());
                            } else {
                                ecgSvgElement.innerHTML += ecgRulerVirtual.toSVG_H();
                            }
                        }
                    }
                } else if (self.state.overlapSelectMode) {

                    // if (selectRectangleNode) {
                    //     selectRectangleNode.parentNode.removeChild(selectRectangleNode);
                    // }

                    // 绘制新的节点
                    const ecgSelectRectangle = self.state.ecgSelectRectangle;
                    if (ecgSelectRectangle && ecgSelectRectangle.startX && ecgSelectRectangle.startY) {
                        ecgSelectRectangle.endX = e.layerX;
                        ecgSelectRectangle.endY = e.layerY;
                        // ecgSvgElement.innerHTML += ecgSelectRectangle.toSVG();

                        // 删除节点
                        const selectRectangleNode = document.getElementById("ecg_view_select_rectangle");
                        if (selectRectangleNode) {
                            ecgSvgElement.innerHTML = ecgSvgElement.innerHTML.replace(selectRectangleNode.outerHTML, ecgSelectRectangle.toSVG());
                        } else {
                            ecgSvgElement.innerHTML += ecgSelectRectangle.toSVG();
                        }
                    }
                }
            };
            ecgSvgElement.onmouseup = function (e) {

                const idSuffix = ecgSvgElement.id.substring(ecgSvgElement.id.lastIndexOf("_") + 1);

                // 事件的layerX和layerY来计算当前点到了哪个点位置，保存该点的index
                const ecgClickX = e.layerX - ecgLightWave.winMarginX;
                const ecgClickY = e.layerY - ecgLightWave.winMarginY;

                console.log(ecgClickX, ecgClickY);
                const ecgClickPointIndex = (ecgClickX / ecgLightWave.pointSpace);
                // console.log(ecgClickPointIndex);
                // console.log(self.state.ecgFileMixData.polymRegions[0][0][ecgClickPointIndex]);

                if (self.state.rulerWOperationChecked || self.state.rulerHOperationChecked) {

                    // 删除节点
                    const ecgRulerVirtualNode = document.getElementById("ecg_ruler_virtual");
                    if (ecgRulerVirtualNode) {
                        ecgRulerVirtualNode.parentNode.removeChild(ecgRulerVirtualNode);
                        self.setState({ecgRulerVirtual: null});
                    }

                    // 点击的范围超出绘制的点数，不处理
                    const ecgPointPolymRegion = self.state.ecgFileMixData.polymRegions && self.state.ecgFileMixData.polymRegions[0];
                    if (ecgPointPolymRegion && ecgPointPolymRegion.ecgData[0] && ecgPointPolymRegion.ecgData[0][ecgClickPointIndex]) {
                        const selectEcgPoint = ecgPointPolymRegion.ecgData[0][ecgClickPointIndex];

                        const ecgRuler = self.state.ecgRuler;
                        if (self.state.rulerWOperationChecked) {
                            ecgRuler.endX = e.layerX;
                            ecgRuler.endY = ecgRuler.startY;
                            // 算出前后时间差
                            ecgRuler.calculateDistance_W(ecgLightWave.pointSpace, ecgLightWave.sampRate);
                            ecgRuler.setSvg();
                        } else {
                            ecgRuler.endX = ecgRuler.startX;
                            ecgRuler.endY = e.layerY;
                            // 算出前后电压差
                            // 一个格子是5mm，所以一个格子代表0.5mv
                            ecgRuler.calculateDistance_H(ecgLightWave.cellWidth);
                            ecgRuler.setSvg();
                        }

                        ecgRuler.id = moment().format(FORMAT_DATE_TIME_FULL_SIMPLE);
                        ecgRuler.historyFileIndex = selectEcgPoint.historyFileIndex;
                        ecgRuler.fileName = self._getEcgFileName(self.state.sliderValue);
                        ecgRuler.pointIndex = selectEcgPoint.index;
                        ecgRuler.index = idSuffix;

                        self._addEcgRuler(ecgRuler);
                    }
                } else if (self.state.overlapSelectMode) {
                    const ecgSelectRectangle = self.state.ecgSelectRectangle;
                    ecgSelectRectangle.endX = e.layerX;
                    ecgSelectRectangle.endY = e.layerY;

                    // 删除节点
                    const selectRectangleNode = document.getElementById("ecg_view_select_rectangle");
                    if (selectRectangleNode) {
                        selectRectangleNode.parentNode.removeChild(selectRectangleNode);
                    }
                    self.setState({ecgSelectRectangle: null});

                    // 绘制心电图重叠效果
                    if (self.state.ecgFileMixData && self.state.ecgFileMixData.polymRegions && self.state.ecgFileMixData.polymRegions.length > 0) {
                        const ecgPointPolymRegion = self.state.ecgFileMixData.polymRegions[0];
                        // console.log(ecgPointPolymRegion.ecgData);
                        // console.log(ecgClickPointIndex);
                        ecgLightWaveOverlap.changeGridScale(1);

                        const ecgClickPointStartIndex = ((ecgSelectRectangle.startX - ecgLightWave.winMarginX) / ecgLightWave.pointSpace);
                        const ecgClickPointEndIndex = ((ecgSelectRectangle.endX - ecgLightWave.winMarginX) / ecgLightWave.pointSpace);
                        // 取出要在重叠区域显示的心电图数据
                        const ecgData = {};
                        for (let i = 0; i < Object.keys(ecgPointPolymRegion.ecgData).length; i++) {
                            const ecgDataArray = ecgPointPolymRegion.ecgData[i].slice(ecgClickPointStartIndex, ecgClickPointEndIndex);
                            ecgData[i] = [];
                            for (let j = 0; j < ecgDataArray.length; j++) {
                                ecgData[i].push({
                                    point: ecgDataArray[j].point,
                                    newPoint: null,
                                    index: ecgDataArray[j].index
                                })
                            }
                        }

                        ecgLightWaveOverlap._refreshLightWave(ecgData, {});
                    }
                } else {
                    if (!self.state.shiftKeyDown && !self.state.ctrlKeyDown) {
                        const selectedAnnotations = self._getSelectedAnnotations();
                        if (selectedAnnotations.length > 0) {
                            const ecgClickPointIndex = (ecgClickX / ecgLightWave.pointSpace);
                            self._moveAnnotation(selectedAnnotations[0].pointIndex, ecgClickPointIndex, true);
                            self._setAnnotationsAttribute(selectedAnnotations[0].pointIndex, "onclick");
                        }
                    }
                }
            };
        }
    }

    _initlizeMedfiltDeWander() {
        //初始化中值滤波除基工具
        const sampleRate = 500;
        const sampleNum = 7936;
        let filterSize = 0.6 * sampleRate;
        //确保为奇数
        filterSize += (filterSize % 2 == 0 ? 1 : 0);

        // filterSize = 5;
        // console.log(filterSize);
        // const POINTS_IN_1FILE = 15.872 * 100 * 8 * 5;
        // const dataLen = POINTS_IN_1FILE / 8;
        medfiltDeWander = new MedfiltDeWander(8, filterSize, sampleNum);
    }

    _clearChildNodeId(node) {
        if (node && node.id) {
            node.removeAttribute("id");
            if (node.childNodes && node.childNodes.length > 0) {
                for (let childNode of node.childNodes) {
                    this._clearChildNodeId(childNode);
                }
            }
        }
    }

    _handleResize = () => {
        this._calcParams();
        this._handleChangeBackgroundDisplay(this.state.backgroundDisplay);
    };

    /** 计算参数 */
    _calcParams = () => {

        ecgLightWave = new EcgLightWave("ecg_view");
        ecgLightWaveOverlap = new EcgLightWave("ecg_view_overlap", EcgLightWaveType.Enum.Overlap);
    };

    // 一个AnnotationElement的事件响应时，需要同时处理关联的Annotation
    _setAnnotationsAttribute(eventPointIndex, eventType) {
        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        // const id = annotationElement.id.substr(0, annotationElement.id.indexOf("_"));
        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            const pointIndex = annotationElement.id.substring(annotationElement.id.lastIndexOf("_") + 1);
            // console.log(pointIndex);
            // console.log(eventPointIndex);
            // 判断选中，设定正负5个点的误差值，操作体验友好
            if ((eventPointIndex - pointIndex) <= 5 && (eventPointIndex - pointIndex) >= -5) {
                // console.log(annotationElement);
                switch (eventType) {
                    case "onmouseenter":
                        annotationElement.setAttribute("stroke", "red");
                        break;
                    case "onmouseleave":
                        annotationElement.setAttribute("stroke", "grey");
                        break;
                    case "onclick":
                        if (annotationElement.getAttribute("stroke") === "red") {
                            annotationElement.setAttribute("stroke", "grey");
                        } else {
                            annotationElement.setAttribute("stroke", "red");
                        }
                        break;
                }
            }
        }
    }

    _selectAnnotationElements(startEventPointIndex, endEventPointIndex) {
        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            const pointIndex = annotationElement.id.substring(annotationElement.id.lastIndexOf("_") + 1);
            if (pointIndex >= startEventPointIndex && pointIndex <= endEventPointIndex) {
                annotationElement.setAttribute("stroke", "red");
            }
        }
    }

    _setAnnotationElementsSelected(selectedAnnotations) {

        // Key: id, Value: annotation
        const annotationMap = {};
        for (let annotation of selectedAnnotations) {
            annotationMap[annotation.id] = annotation;
        }

        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            const currentId = annotationElement.id.substr(0, annotationElement.id.indexOf("_"));

            if (annotationMap[currentId]) {
                annotationElement.setAttribute("stroke", "red");
            }
        }
    }

    _unselectAnnotationElements() {
        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            annotationElement.setAttribute("stroke", "grey");
        }
    }

    /** 点击控制进度条播放 */
    onPlaySliderChange = (value, flag) => {

        // 获取当前刻度所对应的历史文件索引
        if (value < 0) {
            message.warn("此文件是第一个没有前面的文件了")
            return;
        }
        if (!this.props.ecgTimeIndexData.records) {
            message.warn("请先选择历史文件")
            return;
        }
        console.log("标注页面onPlaySliderChange:", value, flag, this.props.ecgTimeIndexData);
        let record = this.props.ecgTimeIndexData.records[value];

        if ((!record || record.historyFileIndex <= 0) && flag) {
            if (value < this.props.ecgTimeIndexData.records.length) {
                console.log(value)
                this.onPlaySliderChange(value + 1, true);
            } else {
                message.warn("此文件是最后一个没有后面的文件了")
            }
            return;
        } else if ((!record || record.historyFileIndex <= 0) && !flag) {
            if (value < this.props.ecgTimeIndexData.records.length) {
                console.log(value)
                this.onPlaySliderChange(value - 1, false);
            }
            // else {
            //     message.warn("此文件是第一个没有前面的文件了")
            // }
            return;
        }
        // message.warn(this.props.ecgTimeIndexData.length)
        console.log(value)
        console.log(this.props.ecgTimeIndexData)
        if (record) {
            this._readHistoryFile(record.historyFileIndex, value, this.props.channelType);
        }
    };
    /** 是否显示心电图网格背景 */
    _handleChangeBackgroundDisplay = (value) => {
        this.setState({backgroundDisplay: value});
        for (let i = 0; i < ecgLightWave.numLeads; i++) {
            if (value) {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = EcgDrawUtils._getECGViewBackground(ecgLightWave.winMarginX, ecgLightWave.winMarginY, ecgLightWave.xCellCount, ecgLightWave.yCellCount, ecgLightWave.cellWidth);
            } else {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = "";
            }
        }
    };

    /** 刷新心电图 */
    _refreshECGView = (annotationPointMap, ecgRulerPointMap) => {

        if (this.state.ecgFileMixData && this.state.ecgFileMixData.polymRegions && this.state.ecgFileMixData.polymRegions.length > 0) {
            const ecgPointPolymRegion = this.state.ecgFileMixData.polymRegions[0];
            const ecgData = Object.assign({}, ecgPointPolymRegion.ecgData);
            ecgLightWave._refreshLightWave(ecgData, annotationPointMap, ecgRulerPointMap);
        }
    };

    _handleSelectDate(ecgDateIndexRecord, fileSourceType, d9kEcgDatabaseRoot, deviceNumber, channelType) {
        this.setState({
            fileSourceType: fileSourceType,
            showEcgFileSourceModal: false,
            d9kEcgDatabaseRoot: d9kEcgDatabaseRoot,
            deviceCode: deviceNumber
        });
        let timeIndexFilePath = ecgDateIndexRecord.timeFileIndex;
        if (fileSourceType === AnnotationFileSourceType.Enum.D9K_LOCAL) {
            const fileIndex = HexUtils.intToHexEcg(Number(ecgDateIndexRecord.timeFileIndex), 4);
            timeIndexFilePath = d9kEcgDatabaseRoot + "\\" + deviceNumber + "\\0000\\idx\\time\\" + deviceNumber + "_time_" + ecgDateIndexRecord.recordDate + "_" + fileIndex + ".idx";
            console.log("timeIndexFilePath:", timeIndexFilePath);

        }
        this.props.dispatch(readTimeIndexFile(fileSourceType, timeIndexFilePath, this.state.deviceCode, (ecgFileTimeIndex) => {
            this.setState({ecgFileTimeIndex: ecgFileTimeIndex});
            // 由于HistoryFileIndex从1开始编号，如HistoryFileIndex=0，表示当天没有历史数据文件产生。
            // 所以获取一个有效的歧视索引
            let index = 0;
            for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                const record = ecgFileTimeIndex.records[i];
                if (record.historyFileIndex > 0) {
                    index = i;
                    this.setState({sliderValue: i});
                    this._readHistoryFile(record.historyFileIndex, i, channelType);
                    break;
                }
            }
        }, channelType));
    }

    _getEcgFileName(sliderValue) {
        const ecgFileTimeIndexRecord = this.state.ecgFileTimeIndex.records && this.state.ecgFileTimeIndex.records[sliderValue];
        if (ecgFileTimeIndexRecord) {
            const historyFileIndexHex = HexUtils.intToHexEcg(Number(ecgFileTimeIndexRecord.historyFileIndex), 4);
            return this.state.deviceCode + "_" + historyFileIndexHex;
        } else {
            return "";
        }
    }

    _getEcgFileFolder(historyFileIndex) {
        let historyFileFolder = this.state.d9kEcgDatabaseRoot + "\\" + this.state.deviceCode + "\\0000";
        // 历史文件按5000个一组进行分文件夹存储
        const historyFileGroupIndex = historyFileIndex / 5000;
        historyFileFolder = historyFileFolder + "\\" + (Math.ceil(historyFileGroupIndex) * 5000) + "\\";
        return historyFileFolder;
    }

    _readHistoryFile(historyFileIndex, sliderValue, channelType) {
        const historyFileFolder = this._getEcgFileFolder(historyFileIndex);
        const fileName = this._getEcgFileName(sliderValue);
        const filePath = this.state.fileSourceType === AnnotationFileSourceType.Enum.D9K_LOCAL ? historyFileFolder + fileName + ".ecg" : historyFileIndex;
        // 第一个历史文件先获取了
        this.props.dispatch(readMixIndexFile(this.state.fileSourceType, filePath, this.state.deviceCode, (ecgFileCommonHeader, ecgFileMixData) => {
            this._clearRule();
            undoList = [];
            redoList = [];
            this._saveAnnotations();
            this.setState({
                ecgFileCommonHeader: ecgFileCommonHeader,
                ecgFileMixData: ecgFileMixData,
                showFrameSelectModal: false,
                sliderValue: sliderValue
            });
            // this._readEcgFile(dataArray, historyFileFolder, fileName);
            const ecgPointPolymRegion = ecgFileMixData.polymRegions[0];
            sampleNum = ecgPointPolymRegion.sampleNum;

            const annotationsMap = Object.assign({}, this.props.annotationsMap);

            switch (this.state.fileSourceType) {
                case AnnotationFileSourceType.Enum.D9K_SERVER:
                    this.props.dispatch(searchAnnotations(this.state.deviceCode, this.state.recordDate, historyFileIndex, fileName, annotationsMap, ecgFileMixData.labelRecords, this.state.formatMessage, (annotationPointMap) => {
                        this._refreshECGView(annotationPointMap, this.props.ecgRulersMap[fileName]);
                    }, medfiltDeWander));
                    break;
                case AnnotationFileSourceType.Enum.D9K_LOCAL:
                    this.props.dispatch(readAnnotationFile(historyFileFolder + fileName + ".anno", fileName, annotationsMap, ecgFileMixData.labelRecords, (annotationPointMap) => {
                        this._refreshECGView(annotationPointMap, this.props.ecgRulersMap[fileName]);
                    }, medfiltDeWander));
                    break;
            }
        }, () => {
            message.error("文件读取失败，请确认文件是否存在：" + filePath);
        }, channelType, medfiltDeWander));
    }

    render() {
        return (
            <div className="position-relative" style={{padding: 5, width: '100%', height: '100%'}}>
                {/*<div id="unit_width" style={{*/}
                {/*width: '1mm',*/}
                {/*height: '1mm',*/}
                {/*position: 'absolute',*/}
                {/*zIndex: -1*/}
                {/*}}>*/}
                {/*</div>*/}
                <CheckUpdateVersion/>
                <EcgFileSourceModal {...this.props} showEcgFileSourceModal={this.state.showEcgFileSourceModal}
                                    onSelectDate={(ecgDateIndexRecord, fileSourceType, d9kEcgDatabaseRoot, deviceNumber, channelType) => this._handleSelectDate(ecgDateIndexRecord, fileSourceType, d9kEcgDatabaseRoot, deviceNumber, channelType)}
                                    onSelectDevice={(deviceCode) => this.setState({deviceCode: deviceCode})}
                                    onCancel={() => this.setState({showEcgFileSourceModal: false})}/>

                <MainMenu
                    onMenuClick={(item, key, keyPath, domEvent) => this._handleMenuClick(item, key, keyPath, domEvent)}/>
                <br/>
                {/*播放进度条*/}
                <TimeIndexSlider sliderValue={this.state.sliderValue}
                                 ecgTimeIndexData={this.props.ecgTimeIndexData}
                                 ecgTimeSlideMarks={this.props.ecgTimeSlideMarks}
                                 onChange={(value) => {
                                     this.setState({sliderValue: value});
                                     // this._handleStopPlay()
                                 }}
                                 onAfterChange={(value) => this.onPlaySliderChange(value, true)}/>
                <br/>
                <br/>
                <div>
                    <Row>
                        <Col span={18} id={"ecg_paper"}>
                            <div style={{paddingLeft: '40px'}}>
                                <PageTitle deviceId={this.state.ecgFileCommonHeader.deviceID}
                                           fileTime={this.state.ecgFileCommonHeader.fileTime ? moment(this.state.ecgFileCommonHeader.fileTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}
                                           fileName={this._getEcgFileName(this.state.sliderValue)}/>
                            </div>
                            <div id="ecg_view" style={{width: '100%', height: '100%'}}>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div id="ecg_view_overlap" style={{width: '100%', height: '100%', paddingTop: '30px'}}>
                            </div>
                        </Col>
                    </Row>

                </div>
                <AnnotationModal
                    annotation={this._getSelectedAnnotations()}
                    onUpdate={(annotation) => this._updateMultiAnnotations([annotation], annotation.annotation, true)}
                    onDelete={(annotation) => this._removeAnnotation([annotation], false)}/>
                <MultiAnnotationModal
                    showMultiAnnotationModal={this.state.showMultiAnnotationModal}
                    onOk={(annotation) => this._updateMultiAnnotations(this._getSelectedAnnotations(), annotation, true)}
                    onCancel={() => this.setState({showMultiAnnotationModal: false})}/>
                <FrameSelectModal
                    deviceCode={this.state.deviceCode}
                    showFrameSelectModal={this.state.showFrameSelectModal}
                    onSelect={(record, index) => this.onPlaySliderChange(index, true)}
                    sampleNum={sampleNum} annotationsMap={this.props.annotationsMap}
                    onCancel={() => this.setState({showFrameSelectModal: false})}/>
            </div>
        );
    }

    _clearRule() {
        // 删除所有的标尺
        const ecgViewRulerGroups = document.getElementsByClassName("ecg_view_ruler_group");
        if (ecgViewRulerGroups.length > 0) {
            for (let ecgViewRulerGroup of ecgViewRulerGroups) {
                ecgViewRulerGroup.parentNode.removeChild(ecgViewRulerGroup);
            }
        }
    }

    _clearMenuState() {
        this.setState({
            selectModal: false,
            rulerWOperationChecked: false,
            rulerHOperationChecked: false,
            zoomModal: false,
            overlapSelectMode: false
        });

        const zoomMarks = document.getElementsByClassName("ecg_svg_zoom_mark");
        if (zoomMarks.length > 0) {
            for (let zoomMark of zoomMarks) {
                zoomMark.style.display = 'none';
            }
        }
    }

    _handleMenuClick(item, key, keyPath, domEvent) {

        this._clearMenuState();
        switch (key) {
            case "file":
                this.setState({showEcgFileSourceModal: true});
                break;
            case "undo":
                this._handleUndo();
                break;
            case "redo":
                this._handleRedo();
                break;
            case "save":
                this._saveAnnotations();
                break;
            case "delete":
                const selectedAnnotations = this._getSelectedAnnotations();
                this._removeAnnotation(selectedAnnotations, false);
                break;
            case "select":
                this.setState({"selectModal": !this.state.selectModal});
                break;
            case "check-on":
                const annotationElementsCheckOn = document.getElementsByClassName("ecg-device-file-annotation");
                for (let annotationElement of annotationElementsCheckOn) {
                    annotationElement.setAttribute("stroke", "red");
                }
                break;
            case "check-off":
                const annotationElementsCheckOff = document.getElementsByClassName("ecg-device-file-annotation");
                for (let annotationElement of annotationElementsCheckOff) {
                    annotationElement.setAttribute("stroke", "grey");
                }
                break;
            case "multiple":
                this.setState({showMultiAnnotationModal: true});
                break;
            case "frame":
                this.setState({showFrameSelectModal: true});
                break;
            case "previous":
                if (this.state.sliderValue > 0) {
                    this.onPlaySliderChange(this.state.sliderValue - 1, false);
                } else {
                    message.warn({content: "此文件是第一个文件，没有前一个文件了。"})
                }
                break;
            case "next":
                this._saveAnnotations();
                this.onPlaySliderChange(this.state.sliderValue + 1, true);
                break;
            case "ruler-w":
                this.setState({rulerWOperationChecked: true});
                break;
            case "ruler-h":
                this.setState({rulerHOperationChecked: true});
                break;
            case "ruler-clear":
                const fileName = this._getEcgFileName(this.state.sliderValue);
                const ecgRulersMap = Object.assign({}, this.props.ecgRulersMap);
                ecgRulersMap[fileName] = {};
                this.props.dispatch(refreshEcgRulersMap(ecgRulersMap));
                this._refreshECGView(this.props.annotationsMap[fileName], ecgRulersMap[fileName]);
                break;
            case "screen-shot":
                const self = this;
                domtoimage.toPng(document.getElementById('ecg_paper'), {bgcolor: 'white', cacheBust: true})
                    .then(function (dataUrl) {
                        // image.crossOrigin = 'Anonymous'
                        const link = document.createElement('a');
                        link.download = self._getEcgFileName(self.state.sliderValue) + '.png';
                        link.href = dataUrl;
                        link.click();
                    }).catch(function (error) {
                    message.error("图片导出出错了～");
                });
                break;
            case "zoom":
                this.setState({zoomModal: true});
                break;
            case "overlap":
                this.setState({overlapSelectMode: !this.state.overlapSelectMode});
                break;
        }
    };

    _handleUndo() {
        if (undoList.length <= 0) {
            message.warn({content: "没有Undo数据"});
            return;
        }
        const undoData = undoList.pop();
        switch (undoData.action) {
            case "addAnnotation":
                this._removeAnnotation([Object.assign({}, undoData.data.before)], true);
                break;
            case "removeAnnotation":
                this._updateMultiAnnotations(Object.assign([], undoData.data.before), undoData.data.before[0].annotation, false);
                break;
            case "updateAnnotation":
                this._updateMultiAnnotations(Object.assign([], undoData.data.before), undoData.data.before[0].annotation, false);
                break;
            case "moveAnnotation":
                this._moveAnnotation(undoData.data.after.pointIndex, undoData.data.before.pointIndex, false);
                break;
            case "addEcgRuler":
                this._removeEcgRuler([Object.assign({}, undoData.data.before)], true);
                break;
            case "removeEcgRuler":
                this._updateMultiEcgRulers(Object.assign([], undoData.data.before), undoData.data.before[0].ecgRuler, false);
                break;
            case "updateEcgRuler":
                this._updateMultiEcgRulers(Object.assign([], undoData.data.before), undoData.data.before[0].ecgRuler, false);
                break;
        }
        redoList.push(undoData);
    }

    _handleRedo() {
        if (redoList.length <= 0) {
            message.warn({content: "没有Redo数据"});
            return;
        }
        const redoData = redoList.pop();
        switch (redoData.action) {
            case "addAnnotation":
                this._addAnnotation(Object.assign({}, redoData.data.after), true);
                break;
            case "removeAnnotation":
                this._updateMultiAnnotations(Object.assign([], redoData.data.after), redoData.data.after[0].annotation, false);
                break;
            case "updateAnnotation":
                this._updateMultiAnnotations(Object.assign([], redoData.data.after), redoData.data.after[0].annotation, false);
                break;
            case "moveAnnotation":
                this._moveAnnotation(redoData.data.before.pointIndex, redoData.data.after.pointIndex, false);
                break;
            case "addEcgRuler":
                this._addEcgRuler(Object.assign({}, redoData.data.after), true);
                break;
            case "removeEcgRuler":
                this._updateMultiEcgRulers(Object.assign([], redoData.data.after), redoData.data.after[0].ecgRuler, false);
                break;
            case "updateEcgRuler":
                this._updateMultiEcgRulers(Object.assign([], redoData.data.after), redoData.data.after[0].ecgRuler, false);
                break;
        }
        undoList.push(redoData);
    }

    _addAnnotation(annotation, isUndoRedo) {
        console.log(annotation);
        const annotationsMap = Object.assign({}, this.props.annotationsMap);
        if (annotationsMap[annotation.fileName]) {
            const annotationPointMap = annotationsMap[annotation.fileName];
            annotationPointMap[annotation.pointIndex] = annotation;
        } else {
            const annotationPointMap = {};
            annotationPointMap[annotation.pointIndex] = annotation;
            annotationsMap[annotation.fileName] = annotationPointMap;
        }

        this.props.dispatch(refreshAnnotationsMap(annotationsMap));
        this._refreshECGView(annotationsMap[annotation.fileName], this.props.ecgRulersMap[annotation.fileName]);

        if (!isUndoRedo) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "addAnnotation",
                data: {"before": Object.assign({}, annotation), "after": Object.assign({}, annotation)}
            });
        }
    }

    _addEcgRuler(ecgRuler, isUndoRedo) {
        console.log(ecgRuler);
        const ecgRulersMap = Object.assign({}, this.props.ecgRulersMap);
        if (ecgRulersMap[ecgRuler.fileName]) {
            const ecgRulerPointMap = ecgRulersMap[ecgRuler.fileName];
            ecgRulerPointMap[ecgRuler.pointIndex] = ecgRuler;
        } else {
            const ecgRulerPointMap = {};
            ecgRulerPointMap[ecgRuler.pointIndex] = ecgRuler;
            ecgRulersMap[ecgRuler.fileName] = ecgRulerPointMap;
        }

        console.log(ecgRulersMap);

        this.props.dispatch(refreshEcgRulersMap(ecgRulersMap));
        this._refreshECGView(this.props.annotationsMap[ecgRuler.fileName], this.props.ecgRulersMap[ecgRuler.fileName]);

        if (!isUndoRedo) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "addEcgRuler",
                data: {"before": Object.assign({}, ecgRuler), "after": Object.assign({}, ecgRuler)}
            });
        }
    }

    _moveAnnotation(fromPointIndex, toPointIndex, isRecordUndoRedo) {

        const annotationsMap = Object.assign({}, this.props.annotationsMap);
        let beforeAnnotation;
        let afterAnnotation;

        const fileName = this._getEcgFileName(this.state.sliderValue);
        const annotationPointMap = annotationsMap[fileName];
        const annotation = annotationPointMap[fromPointIndex];

        beforeAnnotation = Object.assign({}, annotation);

        // 描画的时候，需要从标注数据的key来判断是否存在，所以需要更新Key的新点
        delete annotationPointMap[annotation.pointIndex];

        annotation.pointIndex = toPointIndex;

        annotationPointMap[annotation.pointIndex] = annotation;

        afterAnnotation = Object.assign({}, annotation);

        this.props.dispatch(refreshAnnotationsMap(annotationsMap));
        this._refreshECGView(annotationPointMap, this.props.ecgRulersMap[fileName]);

        if (isRecordUndoRedo) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "moveAnnotation",
                data: {"before": Object.assign({}, beforeAnnotation), "after": Object.assign({}, afterAnnotation)}
            });
        }
    }

    _getSelectedAnnotations() {
        const annotations = [];

        const annotationsMap = Object.assign({}, this.props.annotationsMap);
        const ecgDeviceFileAnnotationElements = document.getElementsByClassName("ecg-device-file-annotation");
        let id;

        // Key: id, Value: annotation
        const annotationMap = {};
        for (let key of Object.keys(annotationsMap)) {
            const annotationPointMap = annotationsMap[key];
            for (let annotationPointKey of Object.keys(annotationPointMap)) {
                const annotation = annotationPointMap[annotationPointKey];
                annotationMap[annotation.id] = annotation;
            }
        }

        for (let annotationElement of ecgDeviceFileAnnotationElements) {
            if (annotationElement.getAttribute("stroke") === "red") {

                const currentId = annotationElement.id.substr(0, annotationElement.id.indexOf("_"));
                if (currentId === id) {
                    // 这个元素同层处理过了，不需要再处理
                    continue;
                }
                // 未处理的话，则把当前处理的id赋值保存，等待下个循环确认
                id = currentId;
                annotations.push(annotationMap[id]);
            }
        }

        return annotations;
    }

    _getAnnotationById(id) {
        // Key: id, Value: annotation
        const annotationMap = {};
        for (let key of Object.keys(this.props.annotationsMap)) {
            const annotationPointMap = this.props.annotationsMap[key];
            for (let annotationPointKey of Object.keys(annotationPointMap)) {
                const annotation = annotationPointMap[annotationPointKey];
                annotationMap[annotation.pointIndex] = annotation.annotation;
            }
        }
        return annotationMap[id];
    }

    _saveAnnotations() {
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (!this.props.annotationsMap[fileName]) {
            return;
        }

        const annotationPointMap = this.props.annotationsMap[fileName];
        switch (this.state.fileSourceType) {
            case AnnotationFileSourceType.Enum.D9K_LOCAL:

                // 保存精简的结构，key: pointIndex, value: annotation
                const saveAnnotationPointMap = {};

                for (let annotationPointKey of Object.keys(annotationPointMap)) {
                    const annotation = annotationPointMap[annotationPointKey];
                    if (annotation.deleteFlag !== 1) {
                        // 删除标识的不保存
                        saveAnnotationPointMap[annotation.pointIndex] = annotation.annotation;
                    }
                }
                const annotationFileName = fileName + ".anno";
                const ecgFileTimeIndexRecord = this.state.ecgFileTimeIndex.records && this.state.ecgFileTimeIndex.records[this.state.sliderValue];
                if (ecgFileTimeIndexRecord) {
                    const annotationFilePath = this._getEcgFileFolder(ecgFileTimeIndexRecord.historyFileIndex) + annotationFileName;
                    this.props.dispatch(writeAnnotationFile(annotationFilePath, JSON.stringify(saveAnnotationPointMap), () => {
                        message.success("保存标注文件成功，路径：" + annotationFilePath);
                        console.log("保存标注文件成功，路径：" + annotationFilePath);
                    }));
                }
                break;
            case AnnotationFileSourceType.Enum.D9K_SERVER:
                this.props.dispatch(saveAnnotations(Object.values(annotationPointMap), this.state.formatMessage));
                break;
        }
    }

    _removeAnnotation(selectedAnnotations, isUndoRedo) {
        if (!selectedAnnotations || selectedAnnotations.length <= 0) {
            return;
        }
        const annotationsMap = Object.assign({}, this.props.annotationsMap);
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (!annotationsMap[fileName]) {
            return;
        }

        const beforeAnnotations = [];
        const afterAnnotations = [];

        const annotationPointMap = annotationsMap[fileName];
        for (let selectedAnnotation of selectedAnnotations) {
            const annotation = annotationPointMap[selectedAnnotation.pointIndex];
            if (annotation) {
                beforeAnnotations.push(Object.assign({}, annotation));
                annotation.deleteFlag = 1;
                afterAnnotations.push(Object.assign({}, annotation));
            }
        }

        this.props.dispatch(refreshAnnotationsMap(annotationsMap));
        this._refreshECGView(annotationsMap[fileName], this.props.ecgRulersMap[fileName]);

        if (!isUndoRedo && beforeAnnotations.length > 0 && afterAnnotations.length > 0) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "removeAnnotation",
                data: {"before": Object.assign([], beforeAnnotations), "after": Object.assign([], afterAnnotations)}
            });
        }
    }

    _removeEcgRuler(selectedEcgRulers, isUndoRedo) {
        if (!selectedEcgRulers || selectedEcgRulers.length <= 0) {
            return;
        }
        const ecgRulersMap = Object.assign({}, this.props.ecgRulersMap);
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (!ecgRulersMap[fileName]) {
            return;
        }

        const beforeEcgRulers = [];
        const afterEcgRulers = [];

        const ecgRulerPointMap = ecgRulersMap[fileName];
        for (let selectedEcgRuler of selectedEcgRulers) {
            const ecgRuler = ecgRulerPointMap[selectedEcgRuler.pointIndex];

            if (ecgRuler) {
                beforeEcgRulers.push(Object.assign({}, ecgRuler));
                ecgRuler.deleteFlag = 1;
                afterEcgRulers.push(Object.assign({}, ecgRuler));
            }
        }
        console.log(ecgRulersMap);

        this.props.dispatch(refreshEcgRulersMap(ecgRulersMap));
        this._refreshECGView(this.props.annotationsMap[fileName], ecgRulersMap[fileName]);

        if (!isUndoRedo && beforeEcgRulers.length > 0 && afterEcgRulers.length > 0) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "removeEcgRuler",
                data: {"before": Object.assign([], beforeEcgRulers), "after": Object.assign([], afterEcgRulers)}
            });
        }
    }

    _updateMultiAnnotations(selectedAnnotations, annotationLabel, isRecordUndoRedo) {

        if (selectedAnnotations.length <= 0) {
            return;
        }

        const annotationsMap = Object.assign({}, this.props.annotationsMap);
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (!annotationsMap[fileName]) {
            return;
        }

        this.setState({showMultiAnnotationModal: false});

        const beforeAnnotations = [];
        const afterAnnotations = [];

        const annotationPointMap = annotationsMap[fileName];
        for (let selectedAnnotation of selectedAnnotations) {
            const annotation = annotationPointMap[selectedAnnotation.pointIndex];
            if (annotation) {
                beforeAnnotations.push(Object.assign({}, annotation));
                annotation.annotation = annotationLabel;
                if (!isRecordUndoRedo) {
                    annotation.deleteFlag = selectedAnnotation.deleteFlag;
                }
                afterAnnotations.push(Object.assign({}, annotation));
            }
        }

        this.props.dispatch(refreshAnnotationsMap(annotationsMap));
        this._refreshECGView(annotationsMap[fileName], this.props.ecgRulersMap[fileName]);

        // 选中的状态不丢失
        this._setAnnotationElementsSelected(selectedAnnotations);

        if (isRecordUndoRedo && beforeAnnotations.length > 0 && afterAnnotations.length > 0) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "updateAnnotation",
                data: {"before": Object.assign([], beforeAnnotations), "after": Object.assign([], afterAnnotations)}
            });
        }
    }

    _updateMultiEcgRulers(selectedEcgRulers, isRecordUndoRedo) {

        if (selectedEcgRulers.length <= 0) {
            return;
        }

        const ecgRulersMap = Object.assign({}, this.props.ecgRulersMap);
        const fileName = this._getEcgFileName(this.state.sliderValue);
        if (!ecgRulersMap[fileName]) {
            return;
        }

        const beforeEcgRulers = [];
        const afterEcgRulers = [];

        const ecgRulerPointMap = ecgRulersMap[fileName];
        for (let selectedEcgRuler of selectedEcgRulers) {
            const ecgRuler = ecgRulerPointMap[selectedEcgRuler.pointIndex];
            if (ecgRuler) {
                beforeEcgRulers.push(Object.assign({}, ecgRuler));
                if (!isRecordUndoRedo) {
                    ecgRuler.deleteFlag = selectedEcgRuler.deleteFlag;
                }
                afterEcgRulers.push(Object.assign({}, ecgRuler));
            }
        }

        this.props.dispatch(refreshEcgRulersMap(ecgRulersMap));
        this._refreshECGView(this.props.annotationsMap[fileName], ecgRulersMap[fileName]);

        if (isRecordUndoRedo && beforeEcgRulers.length > 0 && afterEcgRulers.length > 0) {

            if (redoList.length > 0) {
                // 如果操作了undo，redo，又做了新的操作，则清空undo，redo列表
                undoList = [];
                redoList = [];
            }

            // 操作行为添加到undoList中
            undoList.push({
                action: "updateEcgRuler",
                data: {"before": Object.assign([], beforeEcgRulers), "after": Object.assign([], afterEcgRulers)}
            });
        }
    }
}

const mapStateToProps = (store) => {
    return {
        ecgDateIndexData: store.EcgHistoryReducer.ecgDateIndexData,
        ecgDataIndexEffectiveRecords: store.EcgHistoryReducer.ecgDataIndexEffectiveRecords,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        ecgTimeIndexData: store.EcgHistoryReducer.ecgTimeIndexData,
        ecgTimeSlideMarks: store.EcgHistoryReducer.ecgTimeSlideMarks,
        timeRecordIndex: store.EcgHistoryReducer.timeRecordIndex,
        ecgEventIndexData: store.EcgHistoryReducer.ecgEventIndexData,
        annotationsMap: store.EcgDeviceFileAnnotationReducer.annotationsMap,
        ecgRulersMap: store.EcgDeviceFileAnnotationReducer.ecgRulersMap,
        channelType: store.EcgDeviceReducer.channelType
    }
};

export default connect(mapStateToProps)(injectIntl(Index));
