/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, Select, DatePicker} from "antd";

import moment from 'moment';

import {toggleCreateModal, createDevice, refreshDevice, updateDevice} from "../../../actions/DeviceAction";
import Validator from "../../../../../utils/Validator";

class CreateDeviceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        const device = Object.assign({}, this.props.device);
        device[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshDevice(device));
    }

    _handleChangeMac(value, index) {
        const device = Object.assign({}, this.props.device);
        const macArray = device.mac ? device.mac.split(":") : [6];
        macArray[index] = value.target.value;
        device["mac"] = macArray.join(":");
        this.props.dispatch(refreshDevice(device));
    }

    _handleOk() {
        const {dispatch, searchCondition, device} = this.props;
        if (this._validate(device)) {
            if (device.id !== undefined && device.id !== "") {
                dispatch(updateDevice(device, searchCondition, this.state.formatMessage));
            } else {
                dispatch(createDevice(device, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(device) {
        let messageMap = {};

        const formatMessage = this.state.formatMessage;
        if (device.code === undefined || device.code === "") {
            messageMap["code"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (device.name === undefined || device.name === "") {
            messageMap["name"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (device.mac === undefined || device.mac === "") {
            messageMap["mac"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        } else if (!Validator.isMacAddress(device.mac)) {
            messageMap["mac"] = formatMessage({id: "DAS_DEVICE_MSG_MAC_FORMAT_WRONG"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleCancel() {
        this.props.dispatch(toggleCreateModal(false));
        this.setState({messageMap: {}});
    }

    render() {
        const {device} = this.props;
        const formatMessage = this.state.formatMessage;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        let title = formatMessage({id: 'DAS_DEVICE_TITLE_CREATE'});
        if (device.id !== undefined && device.id !== "") {
            title = formatMessage({id: "DAS_DEVICE_TITLE_UPDATE"});
        }

        const macArray = this.props.device.mac ? this.props.device.mac.split(":") : [];

        return (
            <Modal
                title={title}
                maskClosable={false}
                visible={this.props.showCreateModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
                width={600}>
                <Form>
                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={device.code} onChange={(data) => {
                            this._handleChange("code", data)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={device.name} onChange={(data) => {
                            this._handleChange("name", data)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["mac"] ? "error" : ""}
                               help={this.state.messageMap["mac"]} style={{marginBottom: 0}}>
                        <Input.Group compact>
                            <Input style={{width: 40, textAlign: 'center'}} value={macArray[0]} onChange={(value) => {
                                this._handleChangeMac(value, 0)
                            }}/>
                            <Input
                                style={{
                                    width: 30,
                                    borderLeft: 0,
                                    pointerEvents: 'none',
                                    backgroundColor: '#fff',
                                }}
                                placeholder=":"
                                disabled
                            />
                            <Input style={{width: 40, textAlign: 'center', borderLeft: 0}} value={macArray[1]}
                                   onChange={(value) => {
                                       this._handleChangeMac(value, 1)
                                   }}/>
                            <Input
                                style={{
                                    width: 30,
                                    borderLeft: 0,
                                    pointerEvents: 'none',
                                    backgroundColor: '#fff',
                                }}
                                placeholder=":"
                                disabled
                            />
                            <Input style={{width: 40, textAlign: 'center', borderLeft: 0}} value={macArray[2]}
                                   onChange={(value) => {
                                       this._handleChangeMac(value, 2)
                                   }}/>
                            <Input
                                style={{
                                    width: 30,
                                    borderLeft: 0,
                                    pointerEvents: 'none',
                                    backgroundColor: '#fff',
                                }}
                                placeholder=":"
                                disabled
                            />
                            <Input style={{width: 40, textAlign: 'center', borderLeft: 0}} value={macArray[3]}
                                   onChange={(value) => {
                                       this._handleChangeMac(value, 3)
                                   }}/>
                            <Input
                                style={{
                                    width: 30,
                                    borderLeft: 0,
                                    pointerEvents: 'none',
                                    backgroundColor: '#fff',
                                }}
                                placeholder=":"
                                disabled
                            />
                            <Input style={{width: 40, textAlign: 'center', borderLeft: 0}} value={macArray[4]}
                                   onChange={(value) => {
                                       this._handleChangeMac(value, 4)
                                   }}/>
                            <Input
                                style={{
                                    width: 30,
                                    borderLeft: 0,
                                    pointerEvents: 'none',
                                    backgroundColor: '#fff',
                                }}
                                placeholder=":"
                                disabled
                            />
                            <Input style={{width: 40, textAlign: 'center', borderLeft: 0}} value={macArray[5]}
                                   onChange={(value) => {
                                       this._handleChangeMac(value, 5)
                                   }}/>
                        </Input.Group>
                    </Form.Item>
                    {/*<Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})} {...formItemLayout} required={true}*/}
                    {/*validateStatus={this.state.messageMap["mac"] ? "error" : ""}*/}
                    {/*help={this.state.messageMap["mac"]}>*/}
                    {/*<Input value={device.mac} onChange={(data) => {*/}
                    {/*this._handleChange("mac", data)*/}
                    {/*}}/>*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        device: store.EcgDeviceReducer.device,
        searchCondition: store.EcgDeviceReducer.searchCondition,
        showCreateModal: store.EcgDeviceReducer.showCreateModal,
    }
}

export default connect(mapStateToProps)(injectIntl(CreateDeviceModal));