/**
 * Created by Ethan on 17/1/13.
 */

import {CMS_SERVER_HOST, DAS_SERVER_HOST, MGG_WEB_URL} from '../../constants/Profile';

// 得到最新的Application信息
export const getApplicationLatestVersion = DAS_SERVER_HOST + "/openAPI/das/applications/latest?code=";

export const getDeviceHistoryData = DAS_SERVER_HOST +"/ecg/devices/{deviceId}/filetype/{fileType}/{dataSource}/readstatus"
// export const getDeviceHistoryData = DAS_SERVER_HOST + "/ecg/devices/{deviceId}/filetype/3/readstatus"


export const searchDevices = DAS_SERVER_HOST + "/das/devices/queries";
export const searchDevicesCount = DAS_SERVER_HOST + "/das/devices/count";
export const getUserMemoList = DAS_SERVER_HOST +"/das/ecg/file/statistic/getUserMemoList"
export const getUserMemoExcel = DAS_SERVER_HOST +"/das/ecg/file/statistic/getUserMemoXlsx"
// export const getUserMemoList = "http://140.206.121.162:8766/ecgsuit-manage-server/das/ecg/file/statistic/getUserMemoList"
export const getDeviceById = DAS_SERVER_HOST + "/das/devices/{id}";
export const getGenerationIDS = DAS_SERVER_HOST + "/ecg/devices/{id}/getGenerationIDS";
export const getReadFailFiles = DAS_SERVER_HOST + "/ecg/devices/{deviceCode}/{version}/{fromApp}/{fileType}/getReadFailFiles"
export const getRedisData = DAS_SERVER_HOST + "/ecg/device/getRedisData?deviceCode={deviceCode}";
export const createDevice = DAS_SERVER_HOST + "/das/devices";
export const updateDeviceById = DAS_SERVER_HOST + "/das/devices/{id}";
export const updateDeviceRedis = DAS_SERVER_HOST + "/das/device/updateRedisData?deviceCode={deviceCode}";
export const deleteDeviceRedis = DAS_SERVER_HOST + "/das/devices/{id}/redis";
export const deleteByDeviceId = DAS_SERVER_HOST + "/das/devices/{id}";
export const getDeviceCoordinates = DAS_SERVER_HOST + "/ecg/devices/{id}/coordinates?startTime={startTime}&endTime={endTime}";

export const searchEcgEventRecord = DAS_SERVER_HOST+"/das/ecg/file/statistic/ecgEventCount"

export const searchRegions = DAS_SERVER_HOST + "/district/cities/queries";
// // 导入设备一览
// export const uploadDevices = DAS_SERVER_HOST + "/ecg/devices/import";
//
// export const getDeviceLocationList = DAS_SERVER_HOST + "/ecg/device/locations/";


// 查询绑定用户一览
export const searchBinderList = DAS_SERVER_HOST + "/ecg/binders/queries";
export const getBinder = DAS_SERVER_HOST + "/ecg/binders/{userId}";
export const deleteBinder = DAS_SERVER_HOST + "/ecg/binders/delete";
export const getBinderDevices = DAS_SERVER_HOST + "/ecg/binders/{userId}/devices";
export const bindDevice = DAS_SERVER_HOST + "/ecg/binders/{userId}/devices";
export const unbindDevice = DAS_SERVER_HOST + "/ecg/binders/{userId}/devices";
export const getBinderGuarders = DAS_SERVER_HOST + "/ecg/binders/{userId}/guarders";
export const bindGuarder = DAS_SERVER_HOST + "/ecg/binders/{userId}/guarders?guarderMobile={guarderMobile}";
export const unbindGuarder = DAS_SERVER_HOST + "/ecg/binders/{userId}/guarders/{guarderId}";
export const searchBinderCount = DAS_SERVER_HOST + "/ecg/binders/statistics/count";

// 设备绑定者
export const getBinderById = DAS_SERVER_HOST + "/ecg/binders/{id}";

// 监护人
export const searchGuarderList = DAS_SERVER_HOST + "/ecg/guarders/queries";
export const getGuarderBinders = DAS_SERVER_HOST + "/ecg/guarders/{userId}/binders";
export const unbindBinder = DAS_SERVER_HOST + "/ecg/guarders/{userId}/binders/{binderId}";
export const getBinderData = DAS_SERVER_HOST + "/ecg/binders/{binderId}/data";
export const deleteGuarder = DAS_SERVER_HOST + "/ecg/guarders/{id}";

// 心电数据
export const getHistoryEcgFile = DAS_SERVER_HOST + "/ecg/file/history?id={id}";
export const getNextECGSignals = DAS_SERVER_HOST + "/ecg/files/{id}/next?diagnosisTime={diagnosisTime}";
// export const
export const getECGFilesCount = DAS_SERVER_HOST + "/das/ecg/files/count";
export const searchECGFiles = DAS_SERVER_HOST + "/das/ecg/files/queries";
export const getEcgFileStatistic = DAS_SERVER_HOST + "/das/ecg/file/statistic";
export const searchDateIndexData = DAS_SERVER_HOST + "/ecg/device/date/query?deviceId={deviceId}&startDate={startDate}&endDate={endDate}";

// NCOV数据
export const getNCOV = DAS_SERVER_HOST + "/openAPI/das/ncovs";
export const importNCOV = DAS_SERVER_HOST + "/das/ncovs/import";

// 设备日志
export const searchDeviceLogs = DAS_SERVER_HOST + "/ecg/logs/queries";
export const downloadDeviceLogs = DAS_SERVER_HOST + "/ecg/logs/download";

//历史文件
export const searchFileBound = DAS_SERVER_HOST + "/ecg/device/history/file/queries";
export const downloadHistoryFile = DAS_SERVER_HOST + "/ecg/device/history/file/download";
export const downloadFileBytes = DAS_SERVER_HOST + "/ecg/device/history/file/downloadBytes";

// 系统信息
export const getSystemRedis = DAS_SERVER_HOST + "/ecg/system/redis/detail";
export const updateSystemRedis = DAS_SERVER_HOST + "/ecg/system/redis/update";

// 设备文件标注
export const searchDeviceFileAnnotation = DAS_SERVER_HOST + "/ecg/device/file/annotation/query";
export const getDeviceFileAnnotation = DAS_SERVER_HOST + "/ecg/device/file/annotation/detail";
export const saveDeviceFileAnnotations = DAS_SERVER_HOST + "/ecg/device/file/annotation/save";
export const createDeviceFileAnnotation = DAS_SERVER_HOST + "/ecg/device/file/annotation/create";
export const updateDeviceFileAnnotation = DAS_SERVER_HOST + "/ecg/device/file/annotation/update";
export const deleteDeviceFileAnnotation = DAS_SERVER_HOST + "/ecg/device/file/annotation/delete";

// 病例
export const searchCases = DAS_SERVER_HOST + "/ecg/case/query";
export const getCase = DAS_SERVER_HOST + "/ecg/case/detail";
export const createCase = DAS_SERVER_HOST + "/ecg/case/create";
export const updateCase = DAS_SERVER_HOST + "/ecg/case/update";
export const deleteCase = DAS_SERVER_HOST + "/ecg/case/delete";

// 设备绑定历史
export const searchDeviceBindHistory = DAS_SERVER_HOST + "/ecg/device/bind/history/query";

// 监护人绑定历史
export const searchGuarderBindHistory = DAS_SERVER_HOST + "/ecg/guarder/bind/history/query";

//报警信息
export const searchWarnRecord = DAS_SERVER_HOST + "/ecg/usersos/query";

//医师报告
export const getDiagnosticReports = CMS_SERVER_HOST + "/ecg/diagnostic/report/list?deviceCode={deviceCode}";
export const getDiagnosticReportsDetail = CMS_SERVER_HOST + "/ecg/diagnostic/report/list/detail?deviceId={deviceId}";
export const getDiagnosticComment = CMS_SERVER_HOST + "/ecg/diagnostic/report/comment/detail?id={id}";
export const createDiagnosticReport = CMS_SERVER_HOST + "/ecg/diagnostic/report/comment/create";
export const updateDiagnosticReport = CMS_SERVER_HOST + "/ecg/diagnostic/report/comment/update";