/**
 * Created by Ethan on 2017/9/8.
 */

export const LanguageMap = {
    "zh-CN": '中文',
    "en-US": 'English',
    "ja-JP": '日本語'
};

export const LanguageArray = ["zh-CN", "en-US", "ja-JP"];

// 存储访问令牌的键
export const CACHE_ACCESS_TOKEN = 'CACHE_ACCESS_TOKEN';
export const CACHE_SESSION_USER = "CACHE_SESSION_USER";
export const CACHE_DEVICE_MONITOR_COLUMNS = "CACHE_DEVICE_MONITOR_COLUMNS";
export const CACHE_SITE_ID = "CACHE_SITE_ID";
export const CACHE_SITE_CODE = "CACHE_SITE_CODE";
export const WARN_RECORD = "WARN_RECORD";
export const DETAIL_MODAL = "DETAIL_MODAL"

//设备管理详情
export const CHANNEL_TYPE = "CHANNEL_TYPE"

// 账户管理微服务
export const ACCOUNT_WEB_HOST = "http://140.206.121.162:3001/login";

// 字典项服务的访问地址
// export const DICT_SERVER_HOST = "http://localhost:8889"; // Dev
// export const DICT_SERVER_HOST = "http://192.168.31.69:8080"; // Test
export const DICT_SERVER_HOST = "http://140.206.121.162:8766/ecgsuit-manage-server";

// export const ACCOUNT_SERVER_HOST = "http://localhost:8880";
// export const ACCOUNT_SERVER_HOST = "http://140.206.121.164:8880";
export const ACCOUNT_SERVER_HOST = "http://140.206.121.162:8766/passport-server"; // Prod
// export const ACCOUNT_SERVER_HOST = "http://account-svr.d9ktech.com:8880";

// 组织服务
// export const ORG_SERVER_HOST = "http://localhost:8881";
// export const ORG_SERVER_HOST = "http://140.206.121.164:8881";
export const ORG_SERVER_HOST = "http://140.206.121.162:8766/org-server"; // Prod
// export const ORG_SERVER_HOST = "http://org-svr.d9ktech.com:8881";

// 微信服务
// export const WECHAT_SERVER_HOST = "http://localhost:8883";
export const WECHAT_SERVER_HOST = "http://140.206.121.162:8883";//Prod

// 心电衣数据分析系统服务
// export const DAS_SERVER_HOST = "http://localhost:8889";
// export const DAS_SERVER_HOST = "http://140.206.121.164:8889";
export const DAS_SERVER_HOST = "http://140.206.121.162:8766/ecgsuit-manage-server"; // Prod
// export const DAS_SERVER_HOST = "http://ecg-svr.d9ktech.com:8890";
export const MGG_WEB_URL = "http://192.168.3.70:8766"
// export const ECG_SOCKET_SERVER_HOST = "ws://192.168.3.7:8766/D9KDataServer_v2/websocket";
export const ECG_SOCKET_SERVER_HOST = "ws://140.206.121.162:8770/D9KDataServer_v3/websocket";//正常使用
// export const ECG_SOCKET_SERVER_HOST = "ws://192.168.3.70:8770/D9KDataServer_v3/websocket";
// export const ECG_SOCKET_SERVER_HOST = "ws://140.206.121.162:8766/D9KDataServer_v2/websocket";

// export const FTP_SERVER_HOST = "http://localhost:8110";
// export const FTP_SERVER_HOST = "http://140.206.121.164:8889";
export const FTP_SERVER_HOST = "http://140.206.121.162:8766/ecgsuit-manage-server"; // Prod
// export const FTP_SERVER_HOST = "http://ecg-svr.d9ktech.com:8890";

// 行政区域服务
// export const DISTRICT_SERVER_HOST = "http://localhost:8801";
// export const DISTRICT_SERVER_HOST = "http://140.206.121.164:8889";
export const DISTRICT_SERVER_HOST = "http://140.206.121.162:8766/ecgsuit-manage-server"; // Prod
// export const DISTRICT_SERVER_HOST = "http://ecg-svr.d9ktech.com:8890";

// WBX服务的访问地址
// export const CMS_SERVER_HOST = "http://localhost:8889";
// export const CMS_SERVER_HOST = "http://140.206.121.164:8889";
export const CMS_SERVER_HOST = "http://140.206.121.162:8766/ecgsuit-manage-server"; // Prod
// export const CMS_SERVER_HOST = "http://ecg-svr.d9ktech.com:8890";

// export const LOG_SOCKET_SERVER_HOST = "ws://192.168.3.155:8090/log"; // dev
export const LOG_SOCKET_SERVER_HOST = "ws://127.0.0.1:8090/log"; // prod

// export const APP_SETTING_SOCKET_SERVER_HOST = "ws://192.168.3.155:8090/appSetting"; // dev
export const APP_SETTING_SOCKET_SERVER_HOST = "ws://127.0.0.1:8090/appSetting"; // prod

// export const FILE_IO_SOCKET_SERVER_HOST = "ws://192.168.3.155:8090/fileIO"; // dev
export const FILE_IO_SOCKET_SERVER_HOST = "ws://127.0.0.1:8090/fileIO"; // prod

// export const PERFORMANCE_SOCKET_SERVER_HOST = "ws://192.168.3.155:8090/performance"; // dev
export const PERFORMANCE_SOCKET_SERVER_HOST = "ws://127.0.0.1:8090/performance"; // prod

// export const SYSTEM_INFO_SOCKET_SERVER_HOST = "ws://192.168.3.155:8090/systemInfo"; // dev
export const SYSTEM_INFO_SOCKET_SERVER_HOST = "ws://127.0.0.1:8090/systemInfo"; // prod

export const MQTT_HOST = "ws://140.206.121.162:7768/mqtt";

export const TERMINAL_SOCKET_PATH = "http://140.206.121.162:8766/upload/ecg/contents/202204/TerminalSocket_v1.0.1.20220418.zip";

// 是否开放注册功能
export const REGISTRABLE_FLAG = true;

// 密码强度分三档，1：无限制，2：长度超过8位，3：长度+字母数字特殊符号组合
export const PASSWORD_STRENGTH_LEVEL = 1;

export const APP_NAME = "D9K-ECGSystem";
export const APP_VERSION = "3.12.175-20231124";