import HexUtils from "../../../utils/HexUtils";

const WebSocketUtils = {
    _sendHello: (webSocket, deviceCode) => {
        // 与服务端握手
        const claspHandCommand = "64 03 04 FF FF FF FF 1C 00 00 00 81 00 00 00 00 00 0D 00 00 " + deviceCode + " 00 0D 00 00";
        webSocket.send(claspHandCommand); // 发送一个消息
    },

    _sendGetDeviceDatas: (webSocket, deviceCode, commandHistory) => {//
        const command = "64 03 04 FF FF FF FF 10 00 00 00 00 01 00 00 00";
        if (!commandHistory || !commandHistory.includes(command)) {
            webSocket.send(command);
            commandHistory && commandHistory.push(command);
        }
    },

    //加入等待指令 回复的内容
    _sendGetEcgDeviceFile: (webSocket, deviceCode, fileType, fileIndex, commandHistory, channelType, version) => {
        // 查询时间索引文件
        fileIndex = HexUtils.intToHexEcg(Number(fileIndex), 4);
        let command = "64 03 04 FF FF FF FF 24 00 00 00 32 " + fileType + " 00 00 00 01 00 00 00 " + fileIndex.toUpperCase() + " 01 00 00 00 00 00 00 00 FF FF FF FF";

        if (channelType) {
            command = command + " 00 " + channelType;
        } else {
            command = command + " 00 " + "10";
        }

        //世代顺位
        if (version) {
            command = command + " " + version + " 00 00 00";
        } else {
            command = command + " 00 00 00 00";
        }

        console.log(command);

        if (!commandHistory || !commandHistory.includes(command)) {
            webSocket.send(command);
            console.log(command);
            commandHistory && commandHistory.push(command);
        }
    },


    _sendGetDeviceDataStatic: (webSocket, deviceCode, fileType, fileOper) => {
        let command = "64 03 04 FF FF FF FF 14 00 00 00 30 " + fileType + " 00 00 00 01 00 00 00 ";

        // if (channelType) {
        //     command = command + channelType;
        // } else {
        //     command = command + "10";
        // }

        console.log(command);

        // if (!commandHistory || !commandHistory.includes(command)) {
        webSocket.send(command);
        //     commandHistory && commandHistory.push(command);
        // }
    },

    _sendGetEcgDeviceFiles: (webSocket, deviceCode, fileType, fileIndex, commandHistory, length) => {

        // 查询时间索引文件
        fileIndex = HexUtils.intToHexEcg(Number(fileIndex), 4);
        length = HexUtils.intToHexEcg(Number(length), 4);
        const command = "64 03 04 FF FF FF FF 24 00 00 00 32 " + fileType + " 00 00 00 01 00 00 00 " + fileIndex.toUpperCase() + length.toUpperCase() + "00 00 00 00 FF FF FF FF";

        if (!commandHistory || !commandHistory.includes(command)) {
            webSocket.send(command);
            commandHistory && commandHistory.push(command);
        }
    },

    _sendGetLiveEcg: (webSocket, channel) => {
        const liveDataCommand = "64 03 04 FF FF FF FF 16 00 00 00 40 " + channel;
        webSocket.send(liveDataCommand);
    }
};
export default WebSocketUtils;