import * as ActionTypes from "../ActionTypes";
import HexUtils from "../../../utils/HexUtils";
import {ECG_SOCKET_SERVER_HOST, FILE_IO_SOCKET_SERVER_HOST} from "../../../constants/Profile";
import {AnnotationFileSourceType, AnnotationLabel, EcgFileType, ProtocolPackageType} from "../Enums";
import EcgFileDateIndex from "../entities/EcgFileDateIndex";
import message from "../../../components/toast";
import ProtocalHistoryData from "../entities/ProtocalHistoryData";
import ProtocolCommonHeader from "../entities/ProtocolCommonHeader";
import EcgFileCommonHeader from "../entities/EcgFileCommonHeader";
import WebSocketUtils from "../utils/WebSocketUtils";
import EcgFileEventIndex from "../entities/EcgFileEventIndex";
import EcgFileTimeIndex from "../entities/EcgFileTimeIndex";
import {refreshAnnotationsMap} from "./DeviceFileAnnotationAction";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../constants/DateTimeFormats";
import moment from "moment";
import EcgFileMixData from "../entities/EcgFileMixData";
import {DAS_ECG_HISTORY_VERSION_CHANGE, ECG_DEVICE_DATE_INDEX_RECORD, ECG_DEVICE_DATE_TOP_POINT} from "../ActionTypes";

export function refreshAnchorMap(anchorMap) {
    return {
        type: ActionTypes.DAS_ECG_REFRESH_ANCHOR_MAP,
        anchorMap: anchorMap
    }
}

export function refreshEcgTimeIndexData(ecgTimeIndexData, ecgTimeSlideMarks) {
    return {
        type: ActionTypes.DAS_ECG_REFRESH_TIME_INDEX_DATA,
        ecgTimeIndexData: ecgTimeIndexData,
        ecgTimeSlideMarks: ecgTimeSlideMarks
    }
}

export function refreshLoading(isLoading) {
    return {
        type: ActionTypes.DAS_ECG_HISTORY_LOADING,
        isLoading: isLoading
    }
}

export function refreshVersionChange(isVersionChange) {
    return {
        type: ActionTypes.DAS_ECG_HISTORY_VERSION_CHANGE,
        isVersionChange: isVersionChange
    }
}

export function refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap) {
    return {
        type: ActionTypes.ECG_HISTORY_REFRESH_DATE_INDEX_RECORD_MAP,
        ecgDateIndexRecordMap: ecgDateIndexRecordMap
    }
}

export function refreshEcgDataIndexEffectiveRecords(ecgDataIndexEffectiveRecords) {
    return {
        type: ActionTypes.ECG_HISTORY_REFRESH_DATE_INDEX_EFFECTIVE_RECORDS,
        ecgDataIndexEffectiveRecords: ecgDataIndexEffectiveRecords
    }
}

export function refreshCalendarSearchCondition(calendarSearchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_REFRESH_CALENDAR_SEARCH_CONDITION,
        calendarSearchCondition: calendarSearchCondition
    }
}

export function refreshCalendarEcgDateIndexRecord(ecgDateIndexRecord) {
    return {
        type: ActionTypes.ECG_DEVICE_DATE_INDEX_RECORD,
        ecgDateIndexRecord: ecgDateIndexRecord
    }
}

export function refreshTopQ(topQ) {
    return {
        type: ActionTypes.ECG_DEVICE_DATE_TOP_POINT,
        topQ: topQ
    }
}

export function refreshEcgDateIndexData(ecgDateIndexData) {
    return {
        type: ActionTypes.DAS_ECG_DATE_INDEX_DATA_SEARCH,
        ecgDateIndexData: ecgDateIndexData,
        ecgDataIndexDataRefreshFlag: true
    }
}

export function refreshEcgDateIndexFlag(refreshFlag) {
    return {
        type: ActionTypes.DAS_ECG_DATE_INDEX_DATA_REFRESH_FLAG,
        ecgDataIndexDataRefreshFlag: refreshFlag
    }
}

export function refreshEcgEventIndexData(ecgEventIndexData) {
    return {
        type: ActionTypes.DAS_ECG_EVENT_INDEX_DATA_SEARCH,
        ecgEventIndexData: ecgEventIndexData
    }
}

export function refreshTimeRecordIndex(timeRecordIndex) {
    return {
        type: ActionTypes.ECG_HISTORY_REFRESH_TIME_RECORD_INDEX,
        timeRecordIndex: timeRecordIndex
    }
}

/**
 * 通过WebSocket获取历史心电文件
 * @param webSocket WebSocket的通信对象
 * @param deviceCode 设备号
 * @param fileType 文件类型
 * @param fileIndex 文件索引
 * @returns {Function}
 */
export function getEcgDeviceFile(webSocket, deviceCode, fileType, fileIndex) {
    // const deviceCodeByte = deviceCode.slice(0, 2) + " " + deviceCode.slice(2, 4) + " " + deviceCode.slice(4, 6) + " " + deviceCode.slice(6, 8);
    // 与服务端握手
    const claspHandCommand = "64 03 04 FF FF FF FF 1C 00 00 00 81 00 00 00 00 00 0D 00 00 " + deviceCode + " 00 0D 00 00";
    webSocket.send(claspHandCommand); // 发送一个消息
    // 查询时间索引文件
    fileIndex = HexUtils.intToHexEcg(fileIndex, 4);
    const command = "64 03 04 FF FF FF FF 24 00 00 00 32 " + fileType + " 00 00 00 01 00 00 00 " + fileIndex.toUpperCase() + " 01 00 00 00 00 00 00 00 FF FF FF FF";
    webSocket.send(command);
}

// 读取日期索引文件，一台设备中，只有一个日期索引文件
export function getDateIndexFile(deviceCode, channelType, version, name) {
    console.log("getDateIndexFile:", deviceCode, channelType, version, name)
    return dispatch => {
        const webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            WebSocketUtils._sendHello(webSocket, deviceCode);
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            let startPos = 0;

            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            console.log(protocolCommonHeader);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                console.log("连接成功，开始发送获取日期索引文件文件的指令");
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, deviceCode, EcgFileType.Enum.DATEIDX, 1, null, channelType, version);
                return;
            }

            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.CommonReply) {
                // 0x0000 通用成功
                // 0xAAEF 超时
                if (dataArray[14] !== 0 && dataArray[15] !== 0) {
                    // message.error("服务器处理数据超时");
                    // return;
                }
            }

            // 服务器向客户端发送对应的文件。【上行】
            // PacketType	1B	0x33：文件请求响应	包类型
            // 其他类型的包，不需要处理
            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                dispatch(refreshEcgDateIndexRecordMap({}));
                dispatch(refreshEcgDateIndexData({}));
                return;
            }

            // 解析历史文件里的data数据
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const historyData = new ProtocalHistoryData(dataArray, startPos);
            console.log(historyData);

            startPos = startPos + historyData.length;
            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            console.log(ecgFileCommonHeader);

            startPos = startPos + ecgFileCommonHeader.length;
            switch (historyData.fileType) {
                case EcgFileType.EnumInt.DATEIDX:
                    console.log("日期索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    const ecgFileDateIndex = new EcgFileDateIndex(dataArray, startPos);
                    let ecgDateIndexRecordMap = {};
                    if (ecgFileDateIndex) {
                        for (let item of ecgFileDateIndex.records) {
                            if (item.eventNum > 0 || item.timeNum > 0) {
                                ecgDateIndexRecordMap[item.recordDate] = item;
                            }
                        }
                    }
                    console.log("日期索引文件:", ecgDateIndexRecordMap, ecgFileDateIndex)
                    dispatch(refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap));
                    dispatch(refreshEcgDateIndexData(ecgFileDateIndex));

                    webSocket.close();
                    break;
            }
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取日期索引文件，一台设备中，只有一个日期索引文件
export function readDateIndexFile(filePath) {
    return dispatch => {
        const webSocket = new WebSocket(FILE_IO_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            webSocket.send(JSON.stringify({command: 'readBytes', filePath: filePath}));
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            console.log(dataArray);

            if (dataArray.length <= 0) {
                message.error("文件读取失败，请确认文件是否存在：" + filePath);
                return;
            }

            let startPos = 0;

            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            console.log(ecgFileCommonHeader);

            startPos = startPos + ecgFileCommonHeader.length;
            const ecgFileDateIndex = new EcgFileDateIndex(dataArray, startPos);
            console.log(ecgFileDateIndex);
            const ecgDateIndexRecordMap = {};
            for (let item of ecgFileDateIndex.records) {
                ecgDateIndexRecordMap[item.recordDate] = item;
            }

            dispatch(refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap));
            dispatch(refreshEcgDateIndexData(ecgFileDateIndex));

            webSocket.close();
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取时间索引文件，一台设备中，只有一个日期索引文件
export function readTimeIndexFile(fileSource, filePath, deviceCode, successCallback, channelType) {
    return dispatch => {
        const socketServerHost = fileSource === AnnotationFileSourceType.Enum.D9K_LOCAL ? FILE_IO_SOCKET_SERVER_HOST : ECG_SOCKET_SERVER_HOST;
        const webSocket = new WebSocket(socketServerHost);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            switch (fileSource) {
                case AnnotationFileSourceType.Enum.D9K_LOCAL:
                    webSocket.send(JSON.stringify({command: 'readBytes', filePath: filePath}));
                    break;
                case AnnotationFileSourceType.Enum.D9K_SERVER:
                    WebSocketUtils._sendHello(webSocket, deviceCode);
                    break;
            }
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);
            console.log(dataArray);
            if (dataArray.length <= 0) {
                message.error("文件读取失败，请确认文件是否存在：" + filePath);
                return;
            }
            let startPos = 0;

            if (fileSource === AnnotationFileSourceType.Enum.D9K_SERVER) {
                // 解析通用协议头
                const protocolCommonHeader = new ProtocolCommonHeader(dataArray);

                // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
                // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
                // 但由于转换成了有符号的证书，82编程了-126
                if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                    console.log("连接成功，开始发送获取时间索引文件文件的指令");
                    // 如果是服务器端获取，则filePath为timeFileIndex
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, deviceCode, EcgFileType.Enum.TIMEIDX, filePath, null, channelType);
                    return;
                }

                // 服务器向客户端发送对应的文件。【上行】
                // PacketType	1B	0x33：文件请求响应	包类型
                // 其他类型的包，不需要处理
                if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                    return;
                }

                // 解析历史文件里的data数据
                // 参考：《客户端与WebSocketServer间网络通信协议》
                startPos = startPos + protocolCommonHeader.length;
                const historyData = new ProtocalHistoryData(dataArray, startPos);

                startPos = startPos + historyData.length;
            }

            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            console.log(ecgFileCommonHeader);

            startPos = startPos + ecgFileCommonHeader.length;
            const ecgFileTimeIndex = new EcgFileTimeIndex(dataArray, startPos);
            console.log(ecgFileTimeIndex);

            const slideMarks = {};
            slideMarks[0] = "00:00";
            slideMarks[Number(ecgFileTimeIndex.timeSliceNum)] = "23:59";
            for (let i = 1, x = 0, z = 0; i == Math.round(Number(ecgFileTimeIndex.timeSliceNum) / (23 * 4)); i++) {
                x++;
                if (x % 4 == 0 && x != 0) {
                    slideMarks[i] = z++;
                } else {
                    slideMarks[i] = " "
                }
            }

            // console.log(slideMarks);
            dispatch(refreshEcgTimeIndexData(ecgFileTimeIndex, slideMarks));

            webSocket.close();

            successCallback && successCallback.call(this, ecgFileTimeIndex);
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取事件索引文件，一台设备中，只有一个日期索引文件
export function readEventIndexFile(filePath) {
    return dispatch => {
        const webSocket = new WebSocket(FILE_IO_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            webSocket.send(JSON.stringify({command: 'readBytes', filePath: filePath}));
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            console.log(dataArray);
            if (dataArray.length <= 0) {
                message.error("文件读取失败，请确认文件是否存在：" + filePath);
                return;
            }
            let startPos = 0;

            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            console.log(ecgFileCommonHeader);

            startPos = startPos + ecgFileCommonHeader.length;
            const ecgFileEventIndex = new EcgFileEventIndex(dataArray, startPos);
            dispatch(refreshEcgEventIndexData(ecgFileEventIndex));

            webSocket.close();
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取心电数据文件，一台设备中，只有一个日期索引文件
export function readMixIndexFile(fileSource, filePath, deviceCode, successCallback, failedCallback, channelType) {
    return dispatch => {
        const socketServerHost = fileSource === AnnotationFileSourceType.Enum.D9K_LOCAL ? FILE_IO_SOCKET_SERVER_HOST : ECG_SOCKET_SERVER_HOST;
        const webSocket = new WebSocket(socketServerHost);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            switch (fileSource) {
                case AnnotationFileSourceType.Enum.D9K_LOCAL:
                    webSocket.send(JSON.stringify({command: 'readBytes', filePath: filePath}));
                    break;
                case AnnotationFileSourceType.Enum.D9K_SERVER:
                    WebSocketUtils._sendHello(webSocket, deviceCode);
                    break;
            }
        };
        webSocket.onmessage = (e) => {

            console.log(e.data);

            const dataArray = new Int8Array(e.data);
            if (dataArray.length <= 0) {
                failedCallback && failedCallback.call(this);
                return;
            }
            let startPos = 0;
            if (fileSource === AnnotationFileSourceType.Enum.D9K_SERVER) {
                // 解析通用协议头
                const protocolCommonHeader = new ProtocolCommonHeader(dataArray);

                // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
                // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
                // 但由于转换成了有符号的证书，82编程了-126
                if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                    console.log("连接成功，开始发送获取日期索引文件文件的指令");
                    // 如果是服务器端获取，则filePath为historyFileIndex
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, deviceCode, EcgFileType.Enum.MIXDATA, filePath, null, channelType);
                    return;
                }

                // 服务器向客户端发送对应的文件。【上行】
                // PacketType	1B	0x33：文件请求响应	包类型
                // 其他类型的包，不需要处理
                if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                    return;
                }

                // 解析历史文件里的data数据
                // 参考：《客户端与WebSocketServer间网络通信协议》
                startPos = startPos + protocolCommonHeader.length;
                const historyData = new ProtocalHistoryData(dataArray, startPos);

                startPos = startPos + historyData.length;
            }

            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);
            console.log(ecgFileCommonHeader);

            startPos = startPos + ecgFileCommonHeader.length;
            const ecgFileMixData = new EcgFileMixData(dataArray, startPos, 0, 0, 0, null, null);
            console.log(ecgFileMixData);

            webSocket.close();

            successCallback && successCallback.call(this, ecgFileCommonHeader, ecgFileMixData);
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取心电数据文件，一台设备中，只有一个日期索引文件
export function readAnnotationFile(filePath, fileName, annotationsMap, labelRecords, successCallback, medfiltDeWander) {
    return dispatch => {
        const webSocket = new WebSocket(FILE_IO_SOCKET_SERVER_HOST);
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            console.log(filePath);
            webSocket.send(JSON.stringify({command: 'readText', filePath: filePath}));
        };
        webSocket.onmessage = (e) => {

            // 初始化标注存储结构
            let annotationPointMap = annotationsMap[fileName];
            if (!annotationPointMap) {
                annotationPointMap = {};
                annotationsMap[fileName] = annotationPointMap;
            }

            console.log(e.data);

            const socketRequest = JSON.parse(e.data);
            // 如果标注文件读取成功，则以标注文件内容作为当前心电文件的标注数据
            if (socketRequest.status === 1) {
                const newAnnotationPointMap = JSON.parse(socketRequest.fileContents);
                if (newAnnotationPointMap) {
                    for (let key in newAnnotationPointMap) {
                        annotationPointMap[key] = {
                            id: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE) + key,
                            pointIndex: parseInt(key),
                            annotation: newAnnotationPointMap[key]
                        };
                    }
                }
            } else {
                // 如果标注文件读取失败，则以当前心电文件的labelRecords数据作为标注数据
                // labelRecords 是设备算法获得的高点位置，自动设定上Normal标记
                for (let i = 0; i < labelRecords.length; i++) {
                    const labelRecord = labelRecords[i];

                    // 如果是已经存在的，则已存在的优先级高，所以需要判断不存在的话，取默认值
                    if (!annotationPointMap[labelRecord.topPoint]) {
                        const annotation = {
                            id: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE) + labelRecord.topPoint,
                            // deviceCode: this.state.deviceCode,
                            // recordDate: this.state.recordDate,
                            // fileName: this._getEcgFileName(),
                            pointIndex: labelRecord.topPoint,
                            annotation: AnnotationLabel.Enum.NORMAL
                        };

                        if (medfiltDeWander) {
                            annotation.pointIndex = annotation.pointIndex + medfiltDeWander.nPrevWinLen
                        }

                        annotationPointMap[annotation.pointIndex] = annotation;
                    }
                }
            }
            webSocket.close();
            dispatch(refreshAnnotationsMap(annotationsMap));
            successCallback && successCallback.call(this, annotationPointMap);
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}

// 读取心电数据文件，一台设备中，只有一个日期索引文件
export function writeAnnotationFile(filePath, fileContents, successCallback) {
    return dispatch => {
        const webSocket = new WebSocket(FILE_IO_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {
            // 打开一个连接
            console.log("链接服务器成功：" + e.target.url);
            webSocket.send(JSON.stringify({command: 'write', filePath: filePath, fileContents: fileContents}));
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            webSocket.close();

            successCallback && successCallback.call(this, dataArray);
        };
        webSocket.onerror = (e) => {
            console.log("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            console.log("与服务器断开了链接：" + evnt.target.url);
        };
    }
}