import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    dictTypes: [],
    dictType: {},
    showDetailModal: false,
};

const DictTypeReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DICT_TYPE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DICT_TYPE_SEARCH:
            return Object.assign({}, state, {
                dictTypes: action.dictTypes
            });
        case ActionTypes.DICT_TYPE_REFRESH_TYPE:
            return Object.assign({}, state, {
                dictType: action.dictType
            });
        case ActionTypes.DICT_TYPE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default DictTypeReducer;