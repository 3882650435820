import * as ActionTypes from '../ActionTypes';
import {ContentTitleLayout, ContentType} from "../Enums";
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import moment from 'moment';

const markdownDefault = "标题\n" +
    "---------------------------\n" +
    "\n" +
    "# 1级标题\n" +
    "\n" +
    "## 2级标题\n" +
    "\n" +
    "### 3级标题\n" +
    "\n" +
    "#### 四级标题 \n" +
    " \n" +
    "##### 五级标题  \n" +
    "\n" +
    "###### 六级标题\n" +
    "\n" +
    "\n" +
    "文本样式\n" +
    "---------------------------\n" +
    "\n" +
    "*强调文本* _强调文本_\n" +
    "\n" +
    "**加粗文本** __加粗文本__\n" +
    "\n" +
    "==标记文本==\n" +
    "\n" +
    "~~删除文本~~\n" +
    "\n" +
    "> 引用文本\n" +
    "\n" +
    "H~2~O is是液体。\n" +
    "\n" +
    "2^10^ 运算结果是 1024。\n" +
    "\n" +
    "\n" +
    "\n" +
    "列表\n" +
    "---------------------------\n" +
    "\n" +
    "- 项目\n" +
    "  * 项目\n" +
    "    + 项目\n" +
    "\n" +
    "1. 项目1\n" +
    "2. 项目2\n" +
    "3. 项目3\n" +
    "\n" +
    "- [ ] 计划任务\n" +
    "- [x] 完成任务\n" +
    "\n" +
    "\n" +
    "\n" +
    "链接\n" +
    "---------------------------\n" +
    "\n" +
    "链接: [link](https://mp.csdn.net).\n" +
    "\n" +
    "图片: ![Alt](https://avatar.csdn.net/7/7/B/1_ralf_hx163com.jpg)\n" +
    "\n" +
    "带尺寸的图片: ![Alt](https://avatar.csdn.net/7/7/B/1_ralf_hx163com.jpg =30x30)\n" +
    "\n" +
    "\n" +
    "\n" +
    "代码片\n" +
    "---------------------------\n" +
    "\n" +
    "下面展示一些 `内联代码片`。\n" +
    "\n" +
    "```\n" +
    "// A code block\n" +
    "var foo = 'bar';\n" +
    "```\n" +
    "\n" +
    "```javascript\n" +
    "// An highlighted block\n" +
    "var foo = 'bar';\n" +
    "```\n" +
    "\n" +
    "\n" +
    "\n" +
    "表格\n" +
    "---------------------------\n" +
    "\n" +
    "项目     | Value\n" +
    "-------- | -----\n" +
    "电脑  | $1600\n" +
    "手机  | $12\n" +
    "导管  | $1\n" +
    "\n" +
    "\n" +
    "| Column 1 | Column 2      |\n" +
    "|:--------:| -------------:|\n" +
    "| centered 文本居中 | right-aligned 文本居右 |\n" +
    "\n" +
    "\n" +
    "\n" +
    "自定义列表\n" +
    "---------------------------\n" +
    "\n" +
    "Markdown\n" +
    ":  Text-to-HTML conversion tool\n" +
    "\n" +
    "Authors\n" +
    ":  John\n" +
    ":  Luke\n" +
    "\n" +
    "\n" +
    "\n" +
    "注脚\n" +
    "---------------------------\n" +
    "\n" +
    "一个具有注脚的文本。[^1]\n" +
    "\n" +
    "[^1]: 注脚的解释\n" +
    "\n" +
    "\n" +
    "\n" +
    "注释\n" +
    "---------------------------\n" +
    "\n" +
    "Markdown将文本转换为 HTML。\n" +
    "\n" +
    "*[HTML]:   超文本标记语言\n" +
    "\n" +
    "\n" +
    "\n" +
    "LaTeX 数学公式\n" +
    "---------------------------\n" +
    "\n" +
    "Gamma公式展示 $\\Gamma(n) = (n-1)!\\quad\\forall\n" +
    "n\\in\\mathbb N$ 是通过 Euler integral\n" +
    "\n" +
    "$$\n" +
    "\\Gamma(z) = \\int_0^\\infty t^{z-1}e^{-t}dt\\,.\n" +
    "$$\n" +
    "\n" +
    "\n" +
    "插入甘特图\n" +
    "---------------------------\n" +
    "\n" +
    "```mermaid\n" +
    "gantt\n" +
    "        dateFormat  YYYY-MM-DD\n" +
    "        title Adding GANTT diagram functionality to mermaid\n" +
    "        section 现有任务\n" +
    "        已完成               :done,    des1, 2014-01-06,2014-01-08\n" +
    "        进行中               :active,  des2, 2014-01-09, 3d\n" +
    "        计划中               :         des3, after des2, 5d\n" +
    "```\n" +
    "\n" +
    "\n" +
    "插入UML图\n" +
    "------------\n" +
    "```mermaid\n" +
    "sequenceDiagram\n" +
    "张三 ->> 李四: 你好！李四, 最近怎么样?\n" +
    "李四-->>王五: 你最近怎么样，王五？\n" +
    "李四--x 张三: 我很好，谢谢!\n" +
    "李四-x 王五: 我很好，谢谢!\n" +
    "Note right of 王五: 李四想了很长时间, 文字太长了<br/>不适合放在一行.\n" +
    "\n" +
    "李四-->>张三: 打量着王五...\n" +
    "张三->>王五: 很好... 王五, 你怎么样?\n" +
    "```\n" +
    "\n" +
    "插入Mermaid流程图\n" +
    "--------\n" +
    "```mermaid\n" +
    "graph LR\n" +
    "A[长方形] -- 链接 --> B((圆))\n" +
    "A --> C(圆角长方形)\n" +
    "B --> D{菱形}\n" +
    "C --> D\n" +
    "```\n" +
    "\n" +
    "插入Flowchart流程图\n" +
    "-------\n" +
    "```mermaid\n" +
    "flowchat\n" +
    "st=>start: 开始\n" +
    "e=>end: 结束\n" +
    "op=>operation: 我的操作\n" +
    "cond=>condition: 确认？\n" +
    "\n" +
    "st->op->cond\n" +
    "cond(yes)->e\n" +
    "cond(no)->op\n" +
    "```";


const initialState = {
    contents: [],
    content: {
        code: moment().format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
        body: markdownDefault,
        type: ContentType.Enum.ARTICLE,
        titleLayout: ContentTitleLayout.Enum.NO_PICTURE
    },
    searchCondition: {},
    pagination: {
        pageSize: 10
    },
    // showCreateModal: false,
    // showUpdateModal: false,
    // showAttachmentModal : false,
    isLoading: false,
    showHistoryModal: false,
    basicContent: {},
    latestContents: []
};

const ContentReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.CMS_CONTENT_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.CMS_LATEST_CONTENT_SEARCH:
            return Object.assign({}, state, {
                latestContents: action.latestContents,
            });
        case ActionTypes.CMS_CONTENT_TOGGLE_HISTORY_MODAL:
            return Object.assign({}, state, {
                showHistoryModal: action.showHistoryModal,
                basicContent: action.basicContent
            });
        // case ActionTypes.CMS_CONTENT_TOGGLE_CREATE_MODAL:
        //     return Object.assign({}, state, {
        //         showCreateModal: action.showCreateModal
        //     });
        // case ActionTypes.CMS_CONTENT_TOGGLE_UPDATE_MODAL:
        //     return Object.assign({}, state, {
        //         showUpdateModal: action.showUpdateModal
        //     });
        // case ActionTypes.CMS_CONTENT_TOGGLE_ATTACHMENT_MODAL:
        //     return Object.assign({}, state, {
        //         showAttachmentModal: action.showAttachmentModal
        //     })
        case ActionTypes.CMS_CONTENT_SEARCH:
            return Object.assign({}, state, {
                contents: action.contents,
                pagination: action.pagination
            });
        case ActionTypes.CMS_CONTENT_REFRESH_CONTENT:
            return Object.assign({}, state, {
                content: action.content
            });
        case ActionTypes.CMS_CONTENT_INITIALIZE_CONTENT:
            return Object.assign({}, state, {
                content: initialState.content
            });
        case ActionTypes.CMS_CONTENT_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        default:
            return state
    }
};

export default ContentReducer;