import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {HttpStatus} from '../../../constants/Enums';
import HttpUtils from '../../../utils/HttpUtils';
import { Modal} from 'antd';
import message from "../../../components/toast";

import {refreshFiles} from "./ContentFileAction";

// export function toggleDetailModal(isShow) {
//     return {
//         type: ActionTypes.CMS_CONTENT_TOGGLE_CREATE_MODAL,
//         showCreateModal: isShow
//     }
// }
//
// export function toggleUpdateModal(isShow) {
//     return {
//         type: ActionTypes.CMS_CONTENT_TOGGLE_UPDATE_MODAL,
//         showUpdateModal: isShow
//     }
// }

export function toggleHistoryModal(isShow, basicContent) {
    return {
        type: ActionTypes.CMS_CONTENT_TOGGLE_HISTORY_MODAL,
        showHistoryModal: isShow,
        basicContent: basicContent
    }
}

export function toggleAttachmentModal(isShow) {
    return {
        // type: ActionTypes.CMS_CONTENT_TOGGLE_ATTACHMENT_MODAL,
        showAttachmentModal: isShow
    }
}

export function refreshContent(content) {
    return {
        type: ActionTypes.CMS_CONTENT_REFRESH_CONTENT,
        content: content,
    }
}

export function initContent() {
    return {
        type: ActionTypes.CMS_CONTENT_INITIALIZE_CONTENT
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.CMS_CONTENT_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function searchContents(siteCode, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: true});

        let requestURL = WebService.searchContents.replace("{siteCode}", siteCode);

        HttpUtils.post(requestURL, condition, (response) => {
                dispatch({
                    type: ActionTypes.CMS_CONTENT_SEARCH,
                    contents: response.data,
                    pagination: response.pagination
                });

            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getContent(contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: true});

        let requestURL = WebService.getContent.replace("{id}", contentId);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshContent(response.data));
            dispatch(refreshFiles(response.data.files));
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createContent(content, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: true});

        let requestURL = WebService.createContent.replace("{channelId}", content.channelId);

        HttpUtils.post(requestURL, content, (response) => {
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
            dispatch(initContent());
            dispatch(refreshFiles([]));
            message.success(formatMessage({id: "CMS_CONTENT_MSG_CREATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateContent(content, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: true});

        let requestURL = WebService.updateContent.replace("{id}", content.id);

        HttpUtils.put(requestURL, content, (response) => {
            message.success(formatMessage({id: "CMS_CONTENT_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CONTENT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteContent(siteCode, content, condition, state) {

    return dispatch => {
        let requestURL = WebService.deleteContent.replace("{id}", content.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(searchContents(siteCode, condition, state.formatMessage));
            if (response.status === HttpStatus.SUCCESS) {
                message.success(state.formatMessage({id: "CMS_CONTENT_MSG_DELETE_SUCCESS"}));
            } else {
                Modal.error({
                    title: "ERROR",
                    content: state.formatMessage({id: response.data}),
                    okText: "OK"
                });
            }
        }, (error) => {
            message.error(error);
        });
    }
}