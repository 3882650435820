import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Select} from "antd";

class EnumItemSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        let localFlag = true;
        if (this.props.localFlag !== undefined && this.props.localFlag === false) {
            localFlag = false;
        }

        return (
            <Select
                mode={this.props.mode}
                placeholder={this.props.placeholder}
                style={this.props.style ? this.props.style : {width: '100%'}}
                allowClear={this.props.allowClear}
                disabled={this.props.disabled}
                value={this.props.value}
                defaultValue={this.props.defaultValue}
                onChange={this.onChange}>
                {
                    this.props.data.map((item, index) => {
                        return <Select.Option key={index} value={item.value}>
                            {localFlag ? <FormattedMessage id={item.label}/> : item.label || item.name}
                        </Select.Option>
                    })
                }
            </Select>
        );
    }
}

export default (injectIntl(EnumItemSelect));