import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";

export function initECGSignal() {
    return {
        type: ActionTypes.DAS_ECG_INIT_SIGNAL
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.DAS_ECG_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function getEcgFilesCount(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: true});

        HttpUtils.get(WebService.getECGFilesCount, (response) => {
            dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: false});
            dispatch({
                type: ActionTypes.DAS_ECG_FILES_COUNT,
                ecgFileCount: response.data
            });
        }, (response) => {
            dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchEcgFiles(searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchECGFiles, searchCondition, (response) => {
            dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: false});
            dispatch({
                type: ActionTypes.DAS_ECG_FILES_SEARCH,
                ecgFiles: response.data,
                pagination: response.pagination
            });
        }, (response) => {
            dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: false});
        }, formatMessage)
    }
}