import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    dictClass: {},
    dictClasses: [],
    showDetailModal: false,
};

const DictClassReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DICT_CLASS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DICT_CLASS_SEARCH:
            return Object.assign({}, state, {
                dictClasses: action.dictClasses
            });
        case ActionTypes.DICT_CLASS_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.DICT_CLASS_REFRESH_CLASS:
            return Object.assign({}, state, {
                dictClass: action.dictClass
            });
        default:
            return state
    }
};

export default DictClassReducer;