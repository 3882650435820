/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    Form,
    Input,
    InputNumber,
    Row,
    Col,
    Avatar,
    Button,
    Descriptions,
    Icon,
    Popconfirm,
    Tag,
    message, Divider
} from "antd";

import moment from 'moment';

import {
    getDeviceById, getRedisData, toggleCacheModal,
    updateDeviceRedis
} from "../../../actions/DeviceAction";
import {ChargeState, DeviceColor, EcgFileSource, SysState, UserSex} from "../../../Enums";
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_YEAR_MONTH_ZH
} from "../../../../../constants/DateTimeFormats";
import DeviceDetailBasic from "./DeviceDetailBasic";
import DeviceDetailSystem from "./DeviceDetailSystem";
import DeviceDetailUsage from "./DeviceDetailUsage";
import {unbindDevice} from "../../../actions/BinderAction";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class DetailModalDeviceTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            editNameFlag: false,
            inputReadFileIndexFlag: false,
            inputReadFileIndex: 0,
            binderIndex: 0
        }
    }

    _updateFileIndex(type) {
        if (this.props.device.devHisOnlineStatus !== 0 || this.props.device.appHisOnlineStatus !== 0) {
            message.warning('设备在线无法更新');
            return;
        }

        const device = Object.assign({}, this.props.device);
        if (type === 0) {
            if (device.writeFileIndex > 0) {
                device.writeFileIndex = device.writeFileIndex - 1;
            }
        } else if (type === 1) {
            device.readFileIndex = 0;
        } else {

            if (this.state.inputReadFileIndex > device.writeFileIndex) {
                message.warning('输入的值过大');
                return;
            }
            this.setState({inputReadFileIndexFlag: false});
            device.readFileIndex = this.state.inputReadFileIndex;
        }
        this.props.dispatch(updateDeviceRedis(device, this.state.formatMessage));
    }

    render() {
        const {device, deviceHistoryData, appHistoryData} = this.props;
        // 默认只取第一个绑定用户
        const binder = device.binders && device.binders.length > 0 ? device.binders[this.state.binderIndex] : {};
        const portrait = binder.portrait ? "data:image/png;base64," + binder.portrait : require("../../../../../images/logo.png");
        const lastUpdateTime = (device.lastUpdateTime && device.lastUpdateTime !== '') ?
            formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_REDIS_UPDATE_TIME"}) + "：" + moment(device.lastUpdateTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : "";

        const formatMessage = this.state.formatMessage;
        // console.log("device:", device, deviceHistoryData)

        return (
            <Form>
                <Row gutter={16}>
                    <Col span={6}>
                        <DeviceDetailBasic device={device} deviceHistoryData={deviceHistoryData}/>
                    </Col>
                    <Col span={6}>
                        <DeviceDetailSystem device={device} deviceHistoryData={deviceHistoryData}/>
                    </Col>
                    <Col span={6}>
                        <DeviceDetailUsage device={device} deviceHistoryData={deviceHistoryData}
                                           appHistoryData={appHistoryData}/>
                    </Col>
                    <Col span={6}>
                        <Descriptions
                            title={<div>{formatMessage({id: "DAS_DEVICE_TITLE_BINDER"})}&nbsp;&nbsp;
                                <Button type="link"
                                        icon="left"
                                        onClick={() => this._showPreBinder()}/>&nbsp;&nbsp;
                                <Button type="link" icon="right" onClick={() => this._showNextBinder()}/>
                                <Popconfirm title={this.state.formatMessage({id: "ECG_BINDER_MSG_UNBIND_CONFIRM"})}
                                            onConfirm={() => this._handleUnbind(device, binder)}>
                                    <Button size="small"><FormattedMessage id="ECG_BINDER_BUTTON_UNBIND"/></Button>
                                </Popconfirm>
                            </div>}
                            column={1}>
                            <Descriptions.Item
                                label={formatMessage({id: "ECG_BINDER_FIELD_USER_PORTRAIT"})}>
                                <Avatar shape="square" className="avatar" src={portrait}/>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({id: "ECG_BINDER_FIELD_USER_MOBILE"})}>
                                {binder.userMobile}
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_NICKNAME"})}>
                                {binder.userNickname}
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_NAME"})}>
                                {binder.userName}
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_SEX"})}>
                                <EnumItemLabel map={UserSex.Map} code={binder.userSex}/>
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_BIRTHDAY"})}>
                                {binder.userBirthday !== "" ?
                                    moment(binder.userBirthday, FORMAT_DATE_SIMPLE).format(FORMAT_DATE_YEAR_MONTH_ZH) : ""}
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_HEIGHT"})}>
                                {binder.userHeight}cm
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_WEIGHT"})}>
                                {binder.userWeight}kg
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_DEVICE_SUM"})}>
                                <Button type="link" size="small">
                                    {binder.deviceSum}
                                </Button>
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_GUARDER_SUM"})}>
                                <Button type="link" size="small">
                                    {binder.guarderSum}
                                </Button>
                            </Descriptions.Item>
                            <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_WARD_SUM"})}>
                                <Button type="link" size="small">
                                    {binder.wardSum}
                                </Button>
                            </Descriptions.Item>
                            {/*<Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_HABITS"})}>*/}
                            {/*    <Button type="link" size="small">*/}
                            {/*        <FormattedMessage id="COMMON_BUTTON_DETAIL"/>*/}
                            {/*    </Button>*/}
                            {/*</Descriptions.Item>*/}
                        </Descriptions>
                    </Col>
                    <div className="history-events-switch pull-right spacing-h">
                     <span style={{marginRight: '50px', fontSize: '16px'}}>
                         {lastUpdateTime}
                     </span>
                        {/*<Button key="back" onClick={() => this._handleCancel()}>*/}
                        {/*    <FormattedMessage id="COMMON_BUTTON_CLOSE"/>*/}
                        {/*</Button>*/}
                        {this._renderButtonGroup()}
                    </div>

                </Row>
            </Form>
        );
    }

    _renderButtonGroup() {
        const isExternalUser = (this.props.user && this.props.user.roles && this.props.user.roles[0] && this.props.user.roles[0].code === 'ROLE_EXTERNAL') || !this.props.showRoles;
        if (isExternalUser) {
            return (
                <Button key="submit" type="primary" loading={this.props.isLoading}
                        onClick={() => this._handleRefresh()}>
                    <FormattedMessage id="COMMON_BUTTON_REFRESH"/>
                </Button>
            )
        } else {
            return (
                <div>
                    <Button key="submit" type="primary" loading={this.props.isLoading}
                            onClick={() => this._handleRefresh()}>
                        <FormattedMessage id="COMMON_BUTTON_REFRESH"/>
                    </Button>
                    <Divider type="vertical"/>
                    <Popconfirm title={this.state.formatMessage({id: "DAS_DEVICE_MSG_DATA_INIT_CONFIRM"})}
                                onConfirm={() => this._handleInit()}>
                        <Button key="init">
                            <FormattedMessage id="DAS_DEVICE_BUTTON_DATA_INIT"/>
                        </Button>
                    </Popconfirm>
                    <Divider type="vertical"/>
                    <Button key="cache" onClick={() => this._handleShowCacheModal()}>
                        <FormattedMessage id="DAS_DEVICE_BUTTON_DATA_CACHE"/>
                    </Button>
                </div>
            )
        }
    }

    _handleRefresh() {
        window.location.reload();
        // this.props.dispatch(getDeviceById(this.props.device.id, this.state.formatMessage));
    }

    _handleShowCacheModal() {
        this.props.dispatch(getRedisData(this.props.device.code, this.state.formatMessage));
        this.props.dispatch(toggleCacheModal(true));
    }

    _handleUnbind(device, binder) {
        const {dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(unbindDevice(binder.userId, device.id, formatMessage));
        // this.setState({binderIndex: 0})
    }


    _showPreBinder() {
        const {device} = this.props;
        const binders = device.binders && device.binders.length > 0 ? device.binders : [];
        if (binders.length <= 0) {
            return;
        }

        if (this.state.binderIndex > 0) {
            this.setState({binderIndex: this.state.binderIndex - 1})
        } else {
            this.setState({binderIndex: binders.length - 1})
        }
    }

    _showNextBinder() {
        const {device} = this.props;
        const binders = device.binders && device.binders.length > 0 ? device.binders : [];
        if (binders.length <= 0) {
            return;
        }

        if (this.state.binderIndex < binders.length - 1) {
            this.setState({binderIndex: this.state.binderIndex + 1})
        } else {
            this.setState({binderIndex: 0})
        }
    }
}

function mapStateToProps(store) {
    return {
        device: store.EcgDeviceReducer.device,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        user: store.AccountUserReducer.user,
        showRoles: store.AccountRoleReducer.showRoles,
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModalDeviceTab));