import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import FontAwesome from 'react-fontawesome';
import {Card, Form, Input, Button, Row, Col} from "antd";
import {
    refreshContent,
    refreshSearchCondition,
    toggleCreateModal,
    searchContents
} from '../../../actions/ContentAction';
import {refreshScreenMap} from "../../../../DAS/actions/DeviceAction";

import ChannelSelect from "../../../components/ChannelSelect";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../../../constants/Profile";
import {RoutePath} from "../../../../../constants/RoutePath";
import SearchDrawer from "../../../../../components/SearchDrawer";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        console.log("siteCode:", siteCode, this.props)
        const {searchCondition, pagination, channelId} = this.props;
        searchCondition.draftFlags = [0];
        searchCondition.pageSize = 20;
        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));

        const screenMap = this.props.screenMap;
        const pathname = "/cms/software/version";
        screenMap[pathname] = true;
        if (screenMap && screenMap[pathname]) {
            screenMap[pathname] = !screenMap[pathname];
        } else {
            screenMap[pathname] = true;
        }
        const searchMapTemp = Object.assign({}, screenMap);
        this.props.dispatch(refreshScreenMap(searchMapTemp));
    }

    _handleCreate() {
        this.props.router.push(RoutePath.CMS_CONTENT_CREATE.path);
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    // _handleShowCreateModal() {
    //
    //     this.props.dispatch(refreshContent({}));
    //     this.props.dispatch(toggleDetailModal(true));
    // }
    //
    // _linkToCreateView() {
    //     this.props.dispatch(refreshContent({}));
    //     const siteId = this.props.params.siteId;
    //     const {channelId} = this.props;
    //     console.log(this.props);
    //     let path = RoutePath.CMS_CONTENT_CREATE.path.replace(":siteId", siteId);
    //     this.props.router.push(path);
    // }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <SearchDrawer placement={"top"}>
                <Form style={{backgroundColor: "#ffffcc"}}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CONTENT_FIELD_CHANNEL"})} {...Label_6_Wrapper_18}>
                                <ChannelSelect multiple={true}
                                               channelTree={this.props.channelTree}
                                               value={searchCondition.channelIds}
                                               onChange={(value) => this._handleChange("channelIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CONTENT_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" icon="search" className="spacing-h"
                                    onClick={() => this._handleSearch()}>
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button icon="plus"
                                    onClick={() => this._handleCreate()}>
                                <FormattedMessage id='COMMON_BUTTON_CREATE'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.ChannelReducer.channelTree,
        searchCondition: store.ContentReducer.searchCondition,
        pagination: store.ContentReducer.pagination,
        channelId: store.ChannelReducer.selectTreeKey,
        screenMap: store.EcgDeviceReducer.screenMap
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));