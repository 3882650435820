// 字段	长度（字节）	例值（16进制）	说明
// ProtocalVersion	1	64	V1.00
// SourceType	1	02	00：设备
// 01：手机APP
// 02：服务器
// 03：WEB客户端
// DestType	1	03	同上
// SourceID	4	FF FF FF FF	除了设备以外统一ID为
// FF FF FF FF
// PacketLength	4	2F 00 00 00	47字节包
// PacketType	1	42	实时数据B包
// DownsamplingRatio	1	05	下采样速率，即抽样采样的速率，1-500Hz，2-250Hz，5-100Hz…
// N7	2	0E 00	ECGData中的每个导联的心电数据个数。
// LiveId	4		Live的计数，每次都从0开始递增，每个包+1
// Date	7		当前时间,同currenttime
// 0x3B,3B,17,1E,0B,D007(59秒，59分，23点，31日，12月，2000年)
// ECGData	8*4*N7		1组8通道实时数据（未压缩）

class ECGPoint {
    constructor() {
        this.package = {};
        this.point = 0;
        // 计算后的新点，避免重复计算损耗性能
        this.newPoint = null;
        // 记录心电图上要标识的时间点
        this.time = null;
        this.historyFileIndex = 0;
        // 时间索引的记录索引，保存在点的属性中，在播放的时候可以进行反查
        this.timeRecordIndex = 0;
        // 为了实现播放以后还能获取该点在原来心电文件中的索引
        this.index = 0;
        // 标注对象
        this.annotation = undefined;
    }
}

export default ECGPoint;