/**
 * Created by Ethan on 2017/11/7.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from "react-intl";
import {Col, Row, Collapse, Icon, Radio} from "antd";
import ReactEcharts from 'echarts-for-react';

import {FORMAT_DATE_SIMPLE, FORMAT_MONTY_SLASH} from "../../../../../../constants/DateTimeFormats";

import moment from "moment";

class CountryChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classify: "累计"
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {


        const countryStatisticKeys = Object.keys(this.props.countryStatistic);
        const confirmedValues = [];
        const suspectedValues = [];
        const deadValues = [];
        const cureValues = [];

        for (let key of countryStatisticKeys) {
            const item = this.props.countryStatistic[key];
            if (this.state.classify === "累计") {
                confirmedValues.push(item.confirmed);
                suspectedValues.push(item.suspected);
                deadValues.push(item.dead);
                cureValues.push(item.cure);
            } else {
                if (confirmedValues.length <= 0) {
                    confirmedValues.push(item.confirmed);
                    suspectedValues.push(item.suspected);
                    deadValues.push(item.dead);
                    cureValues.push(item.cure);
                } else {
                    const preItem = this.props.countryStatistic[countryStatisticKeys[confirmedValues.length - 1]];
                    confirmedValues.push(item.confirmed - preItem.confirmed);
                    suspectedValues.push(item.suspected - preItem.suspected);
                    deadValues.push(item.dead - preItem.dead);
                    cureValues.push(item.cure - preItem.cure);
                }
            }
        }

        const option = {
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['确诊', '死亡', '治愈']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: countryStatisticKeys.map((item, index) => {
                    return moment(item, FORMAT_DATE_SIMPLE).format(FORMAT_MONTY_SLASH)
                })
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: '确诊',
                    type: 'line',
                    data: confirmedValues
                },
                {
                    name: '死亡',
                    type: 'line',
                    data: deadValues
                },
                {
                    name: '治愈',
                    type: 'line',
                    data: cureValues
                },
            ]
        };

        return (
            <div>
                <ReactEcharts
                    option={option}
                    style={{height: '350px', width: '100%'}}
                    className='react_for_echarts'/>
                <div className="text-center">
                    <Radio.Group value={this.state.classify}
                                 onChange={(value) => {
                                     this.setState({classify: value.target.value})
                                 }}>
                        <Radio.Button value="累计">累计</Radio.Button>
                        <Radio.Button value="新增">新增</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        countryStatistic: store.DASNcovReducer.countryStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(CountryChart));