import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import message from "../../../components/toast";

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshOrganization(organization) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_REFRESH_ORGANIZATION,
        organization: organization
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_REFRESH_SEARCH_CONDITION,
        searchCondition: condition
    }
}

export function refreshExpandedKeys(expandedKeys) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_REFRESH_EXPANDED_KEYS,
        expandedKeys: expandedKeys
    }
}

export function selectTreeNode(selectedKey) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_TREE_SELECT,
        selectTreeKey: selectedKey
    }
}

export function selectListItem(selectItemKey) {
    return {
        type: ActionTypes.ORG_ORGANIZATION_LIST_SELECT,
        selectItemKey: selectItemKey
    }
}

export function searchOrganizations(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchOrganizations, condition, (response) => {
            dispatch({
                type: ActionTypes.ORG_ORGANIZATION_SEARCH,
                organizations: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getOrganizationTree(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        HttpUtils.get(WebService.getOrganizationTree, (response) => {
            dispatch({
                type: ActionTypes.ORG_ORGANIZATION_TREE,
                organizationsTree: response.data
            });

            // 计算所有的展开节点
            let expandedKeys = [];
            for (let i = 0; i < response.data.length; i++) {
                const item = response.data[i];
                expandedKeys.push(item.id);
                _pushChildrenExpandedKeys(expandedKeys, item.children);
            }
            dispatch(refreshExpandedKeys(expandedKeys));

            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function _pushChildrenExpandedKeys(expandedKeys, children) {
    if (children !== null && children.length > 0) {
        for (let i = 0; i < children.length; i++) {
            const item = children[i];
            expandedKeys.push(item.id);
            _pushChildrenExpandedKeys(expandedKeys, item.children);
        }
    }
}

export function getOrganization(organization, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        const requestUrl = WebService.getOrganization.replace("{id}", organization.id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshOrganization(response.data));
            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createOrganization(organization, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        HttpUtils.post(WebService.createOrganization, organization, (response) => {
            message.success(formatMessage({id: "ORG_ORGANIZATION_MSG_CREATE_SUCCESS"}));
            dispatch(getOrganizationTree(formatMessage));
            dispatch(searchOrganizations(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function updateOrganization(organization, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        const requestUrl = WebService.updateOrganization.replace("{id}", organization.id);
        HttpUtils.put(requestUrl, organization, (response) => {
            message.success(formatMessage({id: "ORG_ORGANIZATION_MSG_UPDATE_SUCCESS"}));
            dispatch(getOrganizationTree(formatMessage));
            dispatch(searchOrganizations(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteOrganization(organization, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: true});

        let requestURL = WebService.deleteOrganization.replace("{id}", organization.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "ORG_ORGANIZATION_MSG_DELETE_SUCCESS"}));
            dispatch(getOrganizationTree(formatMessage));
            dispatch(searchOrganizations(condition, formatMessage));
            dispatch({type: ActionTypes.ORG_ORGANIZATION_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}




