import React, {Component} from 'react';
import {Drawer, Select, Tabs} from 'antd';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

let self

class HistoryDataDrawer extends Component {

    constructor(props) {
        super(props);
        self = this
        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {

    }

    componentWillUnmount() {
    }


    render() {
        const onClose = () => {
            console.log("onClose", this, self);
            this.props.setVis(false)
        };
        return (
            <Drawer
                onClose={onClose}
                className="device-detail-drawer"
                placement="right"
                closable={true}
                visible={this.props.visible}
                destroyOnClose={true}
                mask={false}
                width={400}>
                {
                    this.props.mapVersion.map((item, index) => {
                        return <b key={index}
                                              value={item.code}>{item.code}</b>
                        //device={this.state.deviceCode}
                    })
                }
            </Drawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        mapVersion: store.EcgDeviceReducer.mapVersion,
    }
}

export default connect(mapStateToProps)(injectIntl(HistoryDataDrawer));