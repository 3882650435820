import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Row, Col, Form, Button, Select, Checkbox, Badge, Icon, Switch, Menu, DatePicker, Popover, Timeline} from 'antd';
import EChartsModel from "./EChartsModel";
import EChartsModel2 from "./EChartsModel2";

class index extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentWillMount() {
    }

    componentDidMount() {
    }


    render() {
        return (
            <div>
                <Form id="eventForm"
                      style={{width: '100%', height: '20%', backgroundColor: "#FFF", paddingLeft: "5px"}}>
                    <Row style={{width: '100%', height: '100%', backgroundColor: "#FFF"}}>
                        <Col span={2} style={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <EChartsModel2 dataName={"main"}/>
                        </Col>
                        <Col span={2} style={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <EChartsModel2 dataName={"main2"}/>
                        </Col>
                    </Row>
                    <Row style={{width: '100%', height: '100%', backgroundColor: "#FFF"}}>
                        <Col span={2} style={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <EChartsModel2 dataName={"main3"}/>
                        </Col>
                        <Col span={2} style={{
                            width: '50%',
                            height: '100%',
                            backgroundColor: "#FFF",
                            paddingLeft: "5px",
                            paddingRight: '5px'
                        }}>
                            <EChartsModel2 dataName={"main4"}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    componentWillUnmount() {

    }

}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(
    injectIntl(index)
);