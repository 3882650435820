/**
 * Created by Ethan on 17/3/7.
 */

import MenuItem from '../entities/MenuItem';

export const RoutePathKey = {

    APP: 'APP',

    DASHBOARD: 'DAS_DASHBOARD',

    DEVICE: 'DAS_DEVICE',
    DEVICE_LIST: 'DAS_DEVICE_LIST',
    DEVICE_DETAIL: 'DEVICE_DETAIL',
    DEVICE_HISTORY_DATA: 'DEVICE_HISTORY_DATA',

    ECG: 'ECG',
    ECG_LIST: 'ECG_LIST',
    ECG_CHART: 'ECG_DETAIL',

    USER: '/user',
    USER_LIST: '/users',
    USER_DETAIL: '/users/:userId',
    DOCTOR_LIST: '/doctors',

    ACCOUNT_SETTING: '/account',
    ACCOUNT_PROFILE: '/account/profile',
    ACCOUNT_PASSWORD: '/account/password',

    DATA_ANALYZE: '/data',
    DATA_SYMPTOMS: '/data/symptoms',
    DATA_USAGE: '/data/usage',

    SYS_MANAGE: '/sys',
    SYS_USER_LIST: '/sys/users',
    SYS_USER_DETAIL: '/sys/users/:userId',
    SYS_USER_ADD: '/sys/user/add',
    SYS_PARAMS: '/sys/params',
    SYS_LOGS: '/sys/logs',
};

// 路径重定向的定义
export const RedirectURL = {
    PASSPORT_LOGIN_VIEW: 'http://localhost:8081/login',
    PASSPORT_PROFILE_VIEW: 'http://localhost:8081/profile',
    PASSPORT_PASSPORT_VIEW: 'http://localhost:8081/password'
};

export const RoutePath = {
    //'/': new MenuItem('设备一览', '', '/'),
    APP: new MenuItem("DAS_DASHBOARD_ROUTE_STATISTICS", "app", "app"),

    ACCOUNT_USER_LOGIN_PASSWORD: new MenuItem("ACCOUNT_USER_ROUTE_LOGIN_PASSWORD", "/account/login"),
    ACCOUNT_USER_PROFILE: new MenuItem("ACCOUNT_USER_ROUTE_PROFILE", "/account/user/profile"),
    ACCOUNT_USER_REGISTER: new MenuItem("ACCOUNT_USER_ROUTE_REGISTER", "/account/user/register"),
    ACCOUNT_USER_PASSWORD: new MenuItem("ACCOUNT_USER_ROUTE_PASSWORD", "/account/user/password"),
    ACCOUNT_USER_PASSWORD_FORGOT: new MenuItem("ACCOUNT_USER_ROUTE_PASSWORD_FORGOT", "/account/user/password/forgot"),


    SYS_MANAGEMENT: new MenuItem("COMMON_ROUTER_SYS_MANAGEMENT", "/ecg/system/redis", "setting"),//ECG_SYSTEM_REDIS: new MenuItem('ECG_SYSTEM_ROUTE_REDIS', '/ecg/system/redis'),
    ORG_ORGANIZATION_LIST: new MenuItem("ORG_ORGANIZATION_ROUTE_LIST", "/org/organizations"),
    ORG_EMPLOYEE_LIST: new MenuItem("ORG_EMPLOYEE_ROUTE_LIST", "/org/employees"),

    CMS_SITE_LIST: new MenuItem("CMS_SITE_ROUTE_SITE_LIST", "/cms/sites", "cluster"),
    CMS_CHANNEL_LIST: new MenuItem("CMS_CHANNEL_ROUTE_CHANNEL_LIST", "/cms/channels", "book"),
    // CMS_CONTENT_MANAGEMENT: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_MANAGEMENT", "/cms/contents", "file"),
    // CMS_CONTENT_LIST: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_LIST", "/cms/contents"),
    CMS_CONTENT_DRAFTS: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_DRAFTS", "/cms/contents/drafts"),
    CMS_CONTENT_CREATE: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_CREATE", "/cms/contents/new"),
    CMS_CONTENT_UPDATE: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_UPDATE", "/cms/contents/:contentId"),//:contentId
    CMS_CONTENT_UPDATE2: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_UPDATE2", "/abc/content/:contentId"),
    CMS_CONTENT_UPDATE3: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_UPDATE3", "/efg/content/:contentId"),
    CMS_CONTENT_DOWNLOAD: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_DOWNLOAD", "/abc/content/b9474f7a36d94568a330151cd0f98afa?key=2"),
    CMS_CONTENT_DOWNLOAD_PRESSURE: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_DOWNLOAD_PRESSURE", "/efg/content/9490506419d74a8c88c79faf7b3029e0?key=2"),
    // 软件管理
    CMS_SOFTWARE_MANAGEMENT: new MenuItem("CMS_SOFTWARE_ROUTE_SOFTWARE_MANAGEMENT", "/cms/software", "file"),
    CMS_SOFTWARE_VERSION_MANAGEMENT: new MenuItem("CMS_SOFTWARE_ROUTE_VERSION_MANAGEMENT", "/cms/software/version"),
    CMS_SOFTWARE_LATEST_VERSION_DOWNLOAD: new MenuItem("CMS_SOFTWARE_ROUTE_LATEST_VERSION_DOWNLOAD", "/cms/software/download"),
    // 标注程序
    ECG_ANNOTATION_TOOL: new MenuItem("ECG_ANNOTATION_ROUTE_TOOL", "/ecg/annotation/tool"),

    //医生端
    DOCTOR_MANAGEMENT: new MenuItem("DOCTOR_LIST", "/doc/list", "laptop"),
    DOCTOR_DIAGNOSTIC_RECORD: new MenuItem("DOC_DIAGNOSTIC_RECORD", "/doc/diagnostic"),
    T_P_Y_S:new MenuItem("TPYS","/doc/imageexhibition"),
    T_P_Y_S2:new MenuItem("TPYS2","/doc/imageexhibition2"),
    T_P_Y_S3:new MenuItem("TPYS3","/doc/imageexhibition3"),

    MONITOR: new MenuItem("MONITOR_ROUTE_MANAGEMENT", '/monitor', 'dashboard'),
    MONITOR_REGION: new MenuItem("MONITOR_ROUTE_REGION", '/monitor/region'),

    DAS_DASHBOARD: new MenuItem("DAS_DASHBOARD_ROUTE_MANAGEMENT", '/das/dashboard', 'dashboard'),
    DAS_DASHBOARD_NCOV: new MenuItem("DAS_DASHBOARD_ROUTE_NCOV", '/das/dashboard/ncov'),
    DAS_DASHBOARD_NCOV_IMPORT: new MenuItem("DAS_DASHBOARD_ROUTE_NCOV_IMPORT", '/das/dashboard/ncov/import'),
    DAS_DASHBOARD_STATISTICS: new MenuItem("DAS_DASHBOARD_ROUTE_STATISTICS", '/das/dashboard/statistics'),
    // DAS_DASHBOARD_MONITOR: new MenuItem("DAS_DASHBOARD_ROUTE_MONITOR", '/das/dashboard/monitor'),
    DAS_DASHBOARD_DISTRICT_MAP: new MenuItem("DAS_DASHBOARD_ROUTE_DISTRICT_MAP", '/das/dashboard/district/map'),

    // 其他
    REST: new MenuItem("REST_ROUTE_MANAGEMENT", '/rest', 'appstore'),
    REST_NCOV: new MenuItem("REST_ROUTE_NCOV", '/rest/ncov'),
    REST_NCOV_IMPORT: new MenuItem("REST_ROUTE_NCOV_IMPORT", '/rest/ncov/import'),

    // 设备管理
    DAS_DEVICE: new MenuItem('DAS_DEVICE_ROUTE_MANAGEMENT', '/das/devices', 'laptop'),
    DAS_DEVICE_DATA_DOWNLOAD: new MenuItem('DAS_DEVICE_DATA_DOWNLOAD', '/das/devices/data', 'laptop'),
    DAS_DEVICE_LIST: new MenuItem('DAS_DEVICE_ROUTE_LIST', '/das/devices'),
    DAS_DEVICE_DETAIL: new MenuItem('DAS_DEVICE_DETAIL', '/abc/abc/detail'),
    DAS_DEVICE_MONITOR: new MenuItem('DAS_DEVICE_ROUTE_MONITOR', '/das/devices/monitor'),
    DAS_DEVICE_DATA_SERVER_DOWNLOAD: new MenuItem('ECG_DATA_SERVER_DOWNLOAD', '/das/devices/server/download'),
    DAS_DEVICE_DOWNLOAD: new MenuItem('ECG_HISTORY_DATA_FILE_DOWNLOAD', '/das/devices/download'),
    ECG_HISTORY_QUERY: new MenuItem('ECG_HISTORY_ROUTE_QUERY', '/ecg/history/query'),
    ECG_HISTORY_CALENDAR: new MenuItem('ECG_HISTORY_ROUTE_CALENDAR', '/ecg/history/calendar'),
    ECG_HISTORY_DAY: new MenuItem('ECG_HISTORY_ROUTE_CANVAS', '/ecg/history/day'),
    CLOTHES_LIST: new MenuItem('routeClothesList', '/device/clothes', '/device/clothes'),

    //个人中心
    ONESELF_CENTER: new MenuItem('ECG_ONESELF_CENTER_INFO', '/das/oneself', 'user'),
    DAS_BINDER_ONESELF: new MenuItem('ECG_BINDER_ROUTE_ONESELF', '/das/oneself'),

    // 绑定用户
    DAS_BINDER: new MenuItem('DAS_DEVICE_BINDER_ROUTE_MANAGEMENT', '/das/binders', 'team'),
    DAS_BINDER_LIST: new MenuItem('ECG_BINDER_ROUTE_BINDERS', '/das/binders'),
    DAS_BINDER_TERMINAL_LIST: new MenuItem('DAS_BINDER_TERMINAL_FUNCTION_LIST', '/das/terminal'),
    DAS_MQTT_LIST: new MenuItem('ECG_BINDER_MOBILE', '/das/binders/mobile'),
    DAS_BINDER_GUARDIAN: new MenuItem('ECG_BINDER_ROUTE_GUARDERS', '/das/guardians'),
    DAS_BINDER_DATA: new MenuItem('ECG_BINDER_ROUTE_BINDERS_DATA', '/das/binders/data'),
    DAS_BINDER_USER: new MenuItem('ECG_BINDER_ROUTE_USERS', '/das/users'),

    // 心电图管理
    DAS_ECG: new MenuItem('DAS_ECG_ROUTE_MANAGEMENT', "/das/ecg", 'heart'),
    DAS_ECG_MINE: new MenuItem('DAS_ECG_ROUTE_MINE', '/das/ecg/my/files'),
    DAS_ECG_PHYSIONET: new MenuItem('DAS_ECG_ROUTE_PHYSIONET', '/lightwave/client/lightwave.html'),
    ECG_FILE_CALENDAR: new MenuItem('ECG_FILE_ROUTE_CALENDAR', '/ecg/files/calendar'),
    DAS_ECG_HISTORY: new MenuItem('DAS_ECG_ROUTE_HISTORY', '/das/ecg/history/files'),
    DAS_ECG_REAL_TIME: new MenuItem('DAS_ECG_ROUTE_REAL_TIME', '/das/ecg/real/time'),
    DAS_ECG_HISTORY_LIGHTWAVE: new MenuItem('DAS_ECG_ROUTE_LIGHT', 'das/ecg/history/lightwave/:id'),
    DAS_ECG_LORENZ: new MenuItem('DAS_ECG_ROUTE_LORENZ', '/das/ecg/lorenz/files'),
    DAS_ECG_LORENZ_SAMPLE: new MenuItem('DAS_ECG_ROUTE_SAMPLE_LORENZ', '/das/ecg/lorenz/sample/files'),

    ECG_SYSTEM_REDIS: new MenuItem('ECG_SYSTEM_ROUTE_REDIS', '/ecg/system/redis'),
    SYS_USER_LIST: new MenuItem('menuSysUserList', '/sys/users', "/sys/users"),
    SYS_SETTING:new MenuItem('sysSetting', '/sys/setting', "/sys/setting"),

    // 病例管理
    ECG_CASE_MANAGEMENT: new MenuItem('ECG_CASE_ROUTE_MANAGEMENT', '/ecg/case/list', "heart"),
    ECG_CASE_LIST: new MenuItem('ECG_CASE_ROUTE_LIST', '/ecg/case/list'),
    ECG_PHYSICIANS_REPORT: new MenuItem('ECG_CASE_ROUTE_PHYSICIANS_REPORT', '/cms/contents/3a7787a65d44413cb968e39f79921d4b?key=2'),
};