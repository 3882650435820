import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl, FormattedMessage} from 'react-intl';

import {Layout, Button} from 'antd';

import PageTitle from "../../../../../components/PageTitle";
import OrganizationTree from '../../../components/OrganizationTree';
import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';
import DetailModal from "./DetailModal";
import {searchOrganizations} from "../../../actions/OrganizationAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    _handleTreeNodeSelect(selectedKey) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.parentIds = [];
        searchCondition.parentIds.push(selectedKey);
        this.props.dispatch(searchOrganizations(searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <div className="page-content">
                <DetailModal/>
                <PageTitle title="ORG_ORGANIZATION_ROUTE_LIST"/>
                <Layout>
                    <Layout.Sider width={200}
                                  style={{background: '#fff', borderRight: '1px solid #e8e8e8', height: '100%'}}>
                        <OrganizationTree onSelect={(selectedKey) => this._handleTreeNodeSelect(selectedKey)}/>
                    </Layout.Sider>
                    <Layout.Content style={{paddingLeft: '10px', minHeight: 280}}>
                        <SearchCondition/>
                        <SearchResult/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.OrgOrganizationReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));