/**
 * 字段    字节    范围|示例    说明
 * ProtocolVersion    1B    V1.00=100=0x64
 * V1.08=108=0x6C    网络协议版本号
 * SourceType    1B    0：设备
 * v1：手机App
 * 2：服务器(DataServer)
 * 3：浏览器(客户端)
 * 4：服务器(WebSocketServer)    源类型
 *
 * 注意点：连接WebSocketServer的时候，无论是手机还是浏览器，都用3:浏览器(客户端)，不然服务器端验证通不过。
 * DestType    1B    0：设备
 * 1：手机App
 * 2：服务器(DataServer)
 * 3：浏览器(客户端)
 * 4：服务器(WebSocketServer)    目标类型
 * SourceID    4B    0-232-1    源设备号，根据SourceType，{4}
 * 设备的设备号：DeviceID
 * 手机的设备号：PhoneID（未定义）
 * 服务器的设备号：ServerID（未定义，0xFFFFFFFF）
 * 浏览器的设备号：ClientID（未定义，0xFFFFFFFF）
 * PacketLength    4B    0-232-1    包长度，是指整个包的长度
 * PacketType    1B    0x30：文件Index请求    包类型
 * Data    XXB        数据
 */
class ProtocolCommonHeader {
    constructor(dataArray) {
        let index = 0;
        this.protocolVersion = dataArray[index];
        index++;

        this.sourceType = dataArray[index];
        index++;

        this.destType = dataArray[index];
        index++;

        const sourceIDArray = dataArray.slice(index, index + 4);
        this.sourceID = new Int32Array(sourceIDArray.buffer.slice(-4))[0];
        index = index + 4;

        const packetLengthArray = dataArray.slice(index, index + 4);
        this.packetLength = new Int32Array(packetLengthArray.buffer.slice(-4))[0];
        index = index + 4;

        this.packetType = dataArray[index];
        index++;

        this.length = index;
    }
}

export default ProtocolCommonHeader;