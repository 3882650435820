/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input, DatePicker} from 'antd';

import DateRangeDivider from "../../../../../../components/DateRangeDivider";

import {refreshSearchCondition, searchEcgFiles} from '../../../../actions/ECGAction';
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../../constants/DateTimeFormats";
import {Label_6_Wrapper_18, Label_3_Wrapper_21} from "../../../../../../constants/FormItemLayout";
import moment from "moment";
import HttpUtils from "../../../../../../utils/HttpUtils";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const searchCondition = {
            diagnosisStartTime: moment().startOf("month").format(FORMAT_DATE_SIMPLE + "000000"),
            diagnosisEndTime: moment().format(FORMAT_DATE_SIMPLE + "235959"),
        };

        // 如果路径中带了deviceCode参数，则自动填入
        const deviceCode = HttpUtils.getQueryString("deviceCode");
        if (deviceCode !== undefined && deviceCode !== "") {
            searchCondition.deviceCode = deviceCode;
        }

        this._handleSearch(searchCondition);
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchEcgFiles(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleExport() {
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_BINDER_FIELD_USER_NAME'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.binderName} onChange={(value) => {
                                    this._handleChange("binderName", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_BINDER_FIELD_USER_MOBILE'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.binderMobile} onChange={(value) => {
                                    this._handleChange("binderMobile", value)
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.deviceCode} onChange={(value) => {
                                    this._handleChange("deviceCode", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_ECG_FIELD_DIAGNOSIS_TIME'})} {...Label_3_Wrapper_21}>
                                <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                            format={FORMAT_DATE_TIME_HYPHEN}
                                            allowClear={true}
                                            value={searchCondition.diagnosisStartTime ? moment(searchCondition.diagnosisStartTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                            placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                            onChange={(value) => {
                                                this._handleChange("diagnosisStartTime", value ? value.format(FORMAT_DATE_TIME_SIMPLE) : "")
                                            }}/>
                                <DateRangeDivider/>
                                <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                            format={FORMAT_DATE_TIME_HYPHEN}
                                            allowClear={true}
                                            value={searchCondition.diagnosisEndTime ? moment(searchCondition.diagnosisEndTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                            placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                            onChange={(value) => {
                                                this._handleChange("diagnosisEndTime", value ? value.format(FORMAT_DATE_TIME_SIMPLE) : "")
                                            }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div className="text-center">
                    <Button type="primary" icon="search" className="spacing-h" loading={this.props.isLoading}
                            onClick={() => this._handleSearch(this.props.searchCondition)}>
                        <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                    </Button>
                    <Button icon="download" onClick={() => this._handleExport()}>
                        <FormattedMessage id='COMMON_BUTTON_EXPORT'/>
                    </Button>
                </div>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.DASECGReducer.isLoading,
        searchCondition: store.DASECGReducer.searchCondition,
        pagination: store.DASECGReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));