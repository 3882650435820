import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

import {Card, Modal, Icon, Tag, Button, Table, Divider} from 'antd';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {RoutePath} from "../../../../constants/RoutePath";
import CreateModal from "./CreateModal";
import UpdateModal from "./UpdateModal";

import {
    getSites,
    toggleCreateModal,
    toggleUpdateModal,
    getSite,
    deleteSite,
    refreshSite
} from '../../actions/SiteAction';

import {CACHE_SITE_ID, CACHE_SITE_CODE} from "../../../../constants/Profile";

class Index extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'CMS_SITE_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'CMS_SITE_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {title: formatMessage({id: 'CMS_SITE_FIELD_URL'}), dataIndex: 'url', key: 'url'},
                {title: formatMessage({id: 'CMS_SITE_FIELD_REMARKS'}), dataIndex: 'remarks', key: 'remarks'},
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        this.props.dispatch(getSites(this.state));
    }

    _renderButtonGroup(text, record, index) {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        if (record.id === siteId) {
            return (
                <div>
                    <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                        id="COMMON_BUTTON_MODIFY"/></a>
                    <Divider type="vertical"/>
                    <a onClick={this._handleDelete.bind(this, record)}><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                    <Divider type="vertical"/>
                    <Tag color="green"><Icon type={"check"}/></Tag>
                </div>
            )
        } else {
            return (
                <div>
                    <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                        id="COMMON_BUTTON_MODIFY"/></a>
                    <Divider type="vertical"/>
                    <a onClick={this._handleDelete.bind(this, record)}><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                    <Divider type="vertical"/>
                    <a onClick={this._handleSelectSite.bind(this, record)}><FormattedMessage
                        id="COMMON_BUTTON_SELECT"/></a>
                </div>
            )
        }
    }

    _handleSelectSite(record) {
        // 站点内部都需要该站点ID，存入缓存
        localStorage.setItem(CACHE_SITE_ID, record.id);
        localStorage.setItem(CACHE_SITE_CODE, record.code);
        this.props.router.push(RoutePath.CMS_CHANNEL_LIST.path)
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshSite({}));
        this.props.dispatch(toggleCreateModal(true));
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getSite(record.id, this.state));
        this.props.dispatch(toggleUpdateModal(true));
    }

    _handleDelete(record) {

        const {dispatch} = this.props;
        const state = this.state;
        Modal.confirm({
            title: this.state.formatMessage({id: "CMS_SITE_DELETE_MODAL_TITLE"}),
            content: this.state.formatMessage({id: "CMS_SITE_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteSite(record.id, state.formatMessage));
            }
        });
    }

    render() {
        return (
            <Card>
                <CreateModal/>
                <UpdateModal/>
                <Table rowKey="id" size="middle" columns={this.state.columns} dataSource={this.props.sites}
                       pagination={false}/>
                <div className="text-center spacing-v">
                    <Button type="primary" onClick={() => this._handleShowCreateModal()}>
                        <FormattedMessage id="COMMON_BUTTON_ADD"/>
                    </Button>
                </div>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        sites: store.SiteReducer.sites,
        site: store.SiteReducer.site
    }
}

export default connect(mapStateToProps)(injectIntl(Index));