/**
 * 名称	字节数	范围	说明
 * StartDate	4B	0-232-1	索引文件的起始日期：（日期表示方式见蓝牙协议currenttime中的年月日表示方法）
 * EndDate	4B		结束日期【这其实是个冗余项，因为有StartDate和RecordNum可以计算出EndDate】
 * RecordNum	4B	0-232-1	日期索引中记录条的数量
 * LastEventFileIndex	4B		最后一个事件索引文件编号[供DSP使用]
 * LastTimeFileIndex	4B		最后一个时间索引文件编号[供DSP使用]
 * TotalEventNum	4B	0-232-1	事件索引条目的总个数
 * TotalTimeNum	4B		有效时间索引条目的总个数
 * TotalTimeNum* MiniSecPerFile毫秒即为总记录时长
 * Record1	20B		内容见索引内容
 * Record2	20B		内容见索引内容
 */
import EcgFileDate from "./EcgFileDate";
import EcgFileDateIndexRecord from "./EcgFileDateIndexRecord";

class EcgFileDateIndex {
    constructor(dataArray, startPos) {

        let index = startPos;

        // 索引文件的起始日期
        const ecgFileStartDate = new EcgFileDate(dataArray, index);
        this.startDate = ecgFileStartDate.getDate();
        index = index + ecgFileStartDate.length;

        // 索引文件的结束日期
        const ecgFileEndDate = new EcgFileDate(dataArray, index);
        this.endDate = ecgFileEndDate.getDate();
        index = index + ecgFileEndDate.length;

        // 日期索引中记录条的数量
        const recordNumArray = dataArray.slice(index, index + 4);
        this.recordNum = new Int32Array(recordNumArray.buffer.slice(-4))[0];
        index += 4;

        // 最后一个事件索引文件编号[供DSP使用]
        const lastEventFileIndexArray = dataArray.slice(index, index + 4);
        this.lastEventFileIndex = new Int32Array(lastEventFileIndexArray.buffer.slice(-4))[0];
        index += 4;

        // 最后一个时间索引文件编号[供DSP使用]
        const lastTimeFileIndexArray = dataArray.slice(index, index + 4);
        this.lastTimeFileIndex = new Int32Array(lastTimeFileIndexArray.buffer.slice(-4))[0];
        index += 4;

        // 事件索引条目的总个数
        const totalEventNumArray = dataArray.slice(index, index + 4);
        this.totalEventNum = new Int32Array(totalEventNumArray.buffer.slice(-4))[0];
        index += 4;

        // 有效时间索引条目的总个数
        // totalTimeNum* miniSecPerFile毫秒即为总记录时长
        const totalTimeNumArray = dataArray.slice(index, index + 4);
        this.totalTimeNum = new Int32Array(totalTimeNumArray.buffer.slice(-4))[0];
        index += 4;

        // 日期索引
        this.records = [];
        for (let i = 0; i < this.recordNum; i++) {
            const ecgFileDateIndexRecord = new EcgFileDateIndexRecord(dataArray, index);
            this.records.push(ecgFileDateIndexRecord);
            index = index + ecgFileDateIndexRecord.length;
        }

        this.length = index - startPos;
    }
}

export default EcgFileDateIndex;