import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber, TreeSelect, Select, Upload, Button} from "antd";

import * as WebService from '../../../../../constants/WebService';
import {ACCESS_TOKEN} from "../../../../../constants/Profile";

import {OrganizationTypes} from "../../../Enums";

import OrganizationTreeSelect from "../../../components/OrganizationTreeSelect";

import {
    toggleDetailModal,
    refreshOrganization,
    createOrganization,
    updateOrganization
} from '../../../actions/OrganizationAction';

// import {getSite} from "../../actions/SiteAction";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(organization) {
        let messageMap = {};
        if (organization.code === undefined || organization.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        if (organization.name === undefined || organization.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        if (organization.type === undefined || organization.type === "") {
            messageMap["type"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {organization, searchCondition} = this.props;
        if (this._validate(organization)) {
            if (organization.id === undefined || organization.id === "") {
                this.props.dispatch(createOrganization(organization, searchCondition, this.state.formatMessage));
            }
            else {
                this.props.dispatch(updateOrganization(organization, searchCondition, this.state.formatMessage));
            }

        }
    }

    _handleChange(name, value) {

        const organization = Object.assign({}, this.props.organization);
        organization[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshOrganization(organization));
    }

    render() {
        const {showDetailModal, organization} = this.props;
        const formatMessage = this.state.formatMessage;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        let title = "";
        if (organization.id === undefined || organization.id === "") {
            title = formatMessage({id: "ORG_ORGANIZATION_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ORG_ORGANIZATION_TITLE_UPDATE"});
        }
        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: title})}
                visible={showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_PARENT'})} {...formItemLayout}>
                        <OrganizationTreeSelect value={organization.parentId} onChange={(value) => {
                            this._handleChange("parentId", value)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_CODE'})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={organization.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_NAME'})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={organization.name}
                               onChange={(value) => {
                                   this._handleChange("name", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_TYPE'})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["type"] ? "error" : ""}
                               help={this.state.messageMap["type"]}>
                        <Select value={organization.type}
                                onChange={(value) => {
                                    this._handleChange("type", value)
                                }}>
                            {
                                OrganizationTypes.map((item, i) => {
                                    return (
                                        <Select.Option key={i} value={item.value}>
                                            <FormattedMessage id={item.label}/>
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_CONTACTS'})} {...formItemLayout}>
                        <Input value={organization.contacts}
                               onChange={(value) => {
                                   this._handleChange("contacts", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_TELEPHONE'})} {...formItemLayout}>
                        <Input value={organization.telephone}
                               onChange={(value) => {
                                   this._handleChange("telephone", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_ORGANIZATION_FIELD_ADDRESS'})} {...formItemLayout}>
                        <Input value={organization.address}
                               onChange={(value) => {
                                   this._handleChange("address", value)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }

    _renderChannelTreeNode(channel, index) {
        if (channel.children && channel.children.length > 0) {
            return (
                channel.children.map((item, i) => {
                    return (
                        <TreeSelect.TreeNode value={item.id} title={item.title} key={index + "-" + i}>
                            {this._renderChannelTreeNode(item, index + "-" + i)}
                        </TreeSelect.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        organization: store.OrgOrganizationReducer.organization,
        channelTree: store.OrgOrganizationReducer.channelTree,
        showDetailModal: store.OrgOrganizationReducer.showDetailModal,
        searchCondition: store.OrgOrganizationReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));