import * as ActionTypes from "../ActionTypes";

const initialState = {
    showDetailModal: [],
    searchCondition: {},
    isLoading: false,
    isDownload: false,
    isFinish: false,
    fileSize: 0,
    fileReadStatus:[],
    mapVersion:[],
};

const DeviceFileDownloadReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_CONFIRM:
            return Object.assign({}, state, {
                isDownload: action.isDownload
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ECG_DEVICE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_FINISH:
            return Object.assign({}, state, {
                isFinish: action.isFinish,
                fileSize: action.fileSize,
            });
        case ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS:
            return Object.assign({}, state, {
                fileReadStatus: action.fileReadStatus
            });
        case ActionTypes.ECG_LOG_DEVICE_DOWNLOAD_VERSION:
            return Object.assign({}, state, {
                mapVersion: action.mapVersion
            });
        default:
            return state
    }
};

export default DeviceFileDownloadReducer;