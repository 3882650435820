import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Input, Form, Table, Row, Col, Button} from "antd";
import {
    refreshSearchCondition, searchDeviceList,
    toggleDeviceSelectModal
} from "../actions/DeviceAction";
import PaginationBar from "../../../components/PaginationBar";
import {Label_6_Wrapper_18} from "../../../constants/FormItemLayout";

class DeviceSelectModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            columns: [
                {
                    key: 'code',
                    dataIndex: 'code',
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})
                },
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})
                },
                {
                    key: 'mac',
                    dataIndex: 'mac',
                    title: formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        this._handleSearch({});
    }

    _handleOk() {
        const {dispatch, searchCondition, ecgCase} = this.props;
        // if (this._validate(ecgCase)) {
        //     if (ecgCase.id !== undefined && ecgCase.id !== "") {
        //         dispatch(updateCase(ecgCase, searchCondition, this.state.formatMessage));
        //     } else {
        //         dispatch(createCase(ecgCase, searchCondition, this.state.formatMessage));
        //     }
        // }
    }

    _handleCancel() {
        this.props.dispatch(toggleDeviceSelectModal(false));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleSelect.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_SELECT"/>
                </a>
            </div>
        )
    }

    _handleSelect(record) {
        this.props.onSelect && this.props.onSelect.call(this, record);
        this.props.dispatch(toggleDeviceSelectModal(false));
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchDeviceList(searchCondition, this.state.formatMessage));
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchDeviceList(searchCondition, this.state.formatMessage));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;

        return (
            <Modal
                width={800}
                title={formatMessage({id: 'DAS_DEVICE_TITLE_SELECT'})}
                maskClosable={false}
                visible={this.props.showDeviceSelectModal}
                onCancel={() => this._handleCancel()}
                cancelText={formatMessage({id: "COMMON_BUTTON_CLOSE"})}
                footer={[
                    <Button key="back" onClick={() => this._handleCancel()}>
                        <FormattedMessage id="COMMON_BUTTON_CLOSE"/>
                    </Button>
                ]}>
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.code} onChange={(value) => {
                                    this._handleChange("code", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.devices} pagination={false}/>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showDeviceSelectModal: store.EcgDeviceReducer.showDeviceSelectModal,
        devices: store.EcgDeviceReducer.devices,
        searchCondition: store.EcgDeviceReducer.searchCondition,
        pagination: store.EcgDeviceReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceSelectModal));