import React, {Component} from "react";
import * as echarts from 'echarts';
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Button} from "antd";
import Plotly from 'plotly.js-dist-min'
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {CH, DataStyle, DataSize} from "../../../Enums";
// import d3 from "d3."

let option;
let myChart;
let main
let dataValue = 1;
let dataSizeValue = 1000;
let txValue = 0;
let fileType = ["事件1", "事件2", "事件3", "事件4", "事件5", "事件6", "事件7", "事件8", "事件9", "事件10"]
const dig = Math.PI / 90;
let dataStyle = "scatter"
let color;
let color2;
let colors = [];

let max;
let min

let eChartData = []
let data = []
let data2 = []
let datas;
let txStyle = [
    {value: 0, label: "折线图"},
    {value: 1, label: "柱状图"},
    // {value: 2, label: "饼图"},
    // {value: 3, label: "雷达图"},
    // {value: 4, label: "热力图"},
    // {value: 5, label: "伪色彩图"},
    // {value: 6, label: "时钟图"},
    // {value: 7, label: "散点图"},
    {value: 8, label: "散点图"},
]

class EChartsModel2 extends Component {
// function EChartsModel(props,ref){

    constructor(props) {
        super(props);
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            txStyle: [],
            min: 0,
            max: 500,
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        // this._initChart();
        color = this._initColor();
        color2 = this._initColor();
        eChartData = [];
        // for (var i = 0; i < dataSizeValue; i++) {
        //     data.push((Math.random() * 500).toFixed(2))
        // }
        data = []
        data2 = []
        for (var i = 0; i < dataSizeValue; i++) {
            data.push(i)
            data2.push(150 - 120 * Math.sin(i * dig))
            eChartData.push([i, 150 - 120 * Math.sin(i * dig)])
        }
        for (var i = 0; i < 11; i++) {
            colors.push(this._initColor())
        }
        this._init("scatter")
        // this._initOption('line');
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log("nextProps:", nextProps, "nextContext:", nextContext)
    }

    _initOption(style) {
        option = null;
        let self = this
        option = {
            xAxis: [{
                type: 'value',
                min: min,
                max: max
            }],
            tooltip: {
                trigger: "axis"
            },
            yAxis: [{
                type: 'value'
            }],
            visualMap: {
                calculable: true,
                inRange: {
                    color: [color, color2]
                }
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: 0,
                    filterMode: 'none'
                },
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    filterMode: 'none'
                },
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    filterMode: 'none'
                },
                {
                    type: 'inside',
                    yAxisIndex: 0,
                    filterMode: 'none'
                }
            ],
            animation: false,
            series: [
                {
                    data: eChartData,
                    type: style,
                    showSymbol: false,
                    animationType: "scale",
                    // sampling: "lttb",
                    large: true,
                    largeThreshold: 100001,
                    markPoint: {
                        data: [
                            {type: 'max', name: 'Max'},
                            {type: 'min', name: 'Min'}
                        ]
                    }
                },
            ]
        };
        myChart.setOption(option)
        // myChart.on('dataZoom', function (params) {
        //     console.log(params);
        //     // switch (data.length) {
        //     //     case 10000:
        //     if ((params.end - params.start) * self.state.max / 100 <= 10000) {
        //         option.series[0].sampling = null;
        //     } else {
        //         option.series[0].sampling = "lttb"
        //     }
        //     //         break
        //     //     case 100000:
        //     //         if (params.end-params.start <= 1){
        //     //             option.series[0].sampling = null;
        //     //         }else{
        //     //             option.series[0].sampling = "lttb"
        //     //         }
        //     //         break
        //     //     case 1000000:
        //     //         if (params.end-params.start <= 0.1){
        //     //             option.series[0].sampling = null;
        //     //         }else{
        //     //             option.series[0].sampling = "lttb"
        //     //         }
        //     //         break
        //     // }
        //     myChart.setOption(option)
        // })
    }

    _initChart() {
        if (myChart) {
            myChart.clear()
            myChart.dispose()
        }
        main = document.getElementById(this.props.dataName ? this.props.dataName : "main");
        // main.innerHTML = '';
        // main.setAttribute("_echarts_instance_", "");
        myChart = echarts.init(main);
    }

    _initColor() {
        const color = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
        var a = color[Math.floor(Math.random() * 16)]
        var a1 = color[Math.floor(Math.random() * 16)]
        var a2 = color[Math.floor(Math.random() * 16)]
        var a3 = color[Math.floor(Math.random() * 16)]
        var a4 = color[Math.floor(Math.random() * 16)]
        var a5 = color[Math.floor(Math.random() * 16)]
        return "#" + a + a1 + a2 + a3 + a4 + a5
    }

    render() {
        return (
            <div>
                <div>
                    {/*<Button className="spacing-h" onClick={() => this._setRadar(18)}*/}
                    {/*        style={{backgroundColor: '#2956b1', color: 'white'}}>导入</Button>*/}
                    数据大小：<EnumItemSelect
                    style={{width: '100px', marginRight: '10px'}} allowClear={false}
                    data={DataSize.List} value={dataSizeValue}
                    onChange={(value) => this._handleChangeDataSize(value)}/>
                    {/*数据选择： <EnumItemSelect*/}
                    {/*style={{width: '100px', marginRight: '10px'}} allowClear={false}*/}
                    {/*data={DataStyle.List} value={dataValue}*/}
                    {/*onChange={(value) => this._handleChangeDataStyle(value)}/>*/}
                    图形选择： <EnumItemSelect
                    style={{width: '100px', marginRight: '10px'}} allowClear={false}
                    data={txStyle} value={txValue}
                    onChange={(value) => this._handleChangetxStyle(value)}/>
                    测试使用：最大值：<input onChange={(value) => {
                    // this.setState({max: parseFloat(value.target.value)})
                    max = parseFloat(value.target.value)
                    this._handleChangetxStyle(txValue)
                    this.setState({max: parseFloat(value.target.value)})
                }}/>
                    最小值：<input onChange={(value) => {
                    min = parseFloat(value.target.value)
                    // this.setState({min: parseFloat(value.target.value)})
                    this._handleChangetxStyle(txValue)
                    this.setState({min: parseFloat(value.target.value)})
                }}/>
                    <Button style={{marginLeft: '10px'}} className="spacing-h" onClick={(value) => {
                        this._handleChangetxStyle(txValue)
                    }}>回归原始</Button>
                </div>
                <div id={this.props.dataName ? this.props.dataName : "main"} style={{height: '500px', width: '90%'}}>

                </div>
            </div>
        )
    }

    _handleChangeDataSize(value) {
        dataSizeValue = value;
        data = [];
        data2 = []
        eChartData = []
        //TODO:随机生成数据
        // for (var i = 0; i < value; i++) {
        //     switch (dataValue) {
        //         case 1:
        //             data.push((Math.random() * 500).toFixed(2))
        //             break;
        //         case 2:
        //             // data.push((Math.random() * 500).toFixed(2))
        //             // data2.push((Math.random() * 500).toFixed(2))
        //             data.push([(Math.random() * 500).toFixed(2), (Math.random() * 500).toFixed(2)])
        //             break
        //         case 3:
        //             // data.push([fileType[Math.floor(Math.random() * 10)], (Math.random() * 500).toFixed(2)])
        //             break
        //     }
        // }
        for (var i = 0; i < value; i++) {
            data.push(i);
            data2.push(150 - 120 * Math.sin(i * dig))
            eChartData.push([i, 150 - 120 * Math.sin(i * dig)])
        }
        this._handleChangetxStyle(txValue);

        this.setState({txStyle: txStyle});
    }

    _handleChangeDataStyle(value) {
        dataValue = value;
        switch (value) {
            case 1:
                txStyle = [
                    {value: 0, label: "折线图"},
                    {value: 1, label: "柱状图"},
                    {value: 2, label: "饼图"},
                    {value: 3, label: "雷达图"},
                    {value: 4, label: "热力图"},
                    {value: 5, label: "伪色彩图"},
                    {value: 6, label: "时钟图"},
                    {value: 7, label: "散点图"},
                ]
                break

            case 2:
                txStyle = [
                    {value: 0, label: "折线图"},
                    {value: 1, label: "柱状图"},
                    {value: 7, label: "散点图"},
                    {value: 8, label: "测试"},
                ]
                break
            case 3:
                txStyle = [
                    {value: 0, label: "折线图"},
                    {value: 1, label: "柱状图"},
                    {value: 7, label: "散点图"},
                ]
                break
        }
        this.setState({txStyle: txStyle});
    }

    _init(style) {

        let Max_x = 0
        let Max_y = 0

        let dataX = []
        let dataY = []

        let data3X = [];
        let data3Y = []

        for (var i = 0, x = 100; i <= 100; i += 5, x -= 5) {
            dataX.push(i)
            dataY.push(x);
        }

        for (var i = 0; i < data.length; i++) {
            Max_x = data[i] > Max_x ? data[i] : Max_x
            Max_y = data2[i] > Max_y ? data2[i] : Max_y
        }
        for (var i = 0; i <= Max_x; i++) {
            data3X.push(i)
            data3Y.push(i * Max_y / Max_x)
        }

        var trace1 = {
            x: data,
            y: data2,
            // type: style,//pointcloud,scattergl
            // toolbox: null
            mode: 'markers'
        };

        var trace2 = {
            x: dataX,
            y: dataY,
            // type: "pointcloud",//pointcloud,scattergl,scatter
            // toolbox: null
            // mode: 'markers'
        };

        var trace3 = {
            x: data3X,//[0, Max_x],
            y: data3Y,//[0, Max_y],
            type: "pointcloud",//pointcloud,scattergl
            // toolbox: null
            // mode: 'lines'
        }

        var layout = {}

        var config = {
            displayModeBar: false,//是否显示模式栏
            scrollZoom: true,//是否可滚动缩放
            editable: false,//在可编辑模式下，用户可以编辑图例中的图表标题、轴标签和跟踪名称。
            doubleClickDelay: 0,//双击延迟
        }

        // if (style == "pointcloud") {
        //     datas = [trace1, trace2, trace3];//trace3
        // } else {
        datas = [trace1]
        // }

        Plotly.newPlot(this.props.dataName ? this.props.dataName : "main", datas, layout, config);
    }


    componentWillUnmount() {
        this.init()
    }

    init() {
        dataValue = 1;
        dataSizeValue = 1000;
        txValue = 0;
    }

    _handleChangetxStyle(value) {
        txValue = value
        this._initChart()
        switch (value) {
            case 0:
                // this._initOption('line');
                this._init('scatter')
                break
            case 1:
                this._initOption('bar');
                // this._init("bar")
                break
            case 2:
                this._initPie();
                break
            case 3:
                this._initRadar()
                break
            case 4:
                this._initHeatmap("piecewise")
                // option.series[0].type = 'heatmap'
                break
            case 5:
                this._initHeatmap('continuous')
                break
            case 6:
                this._initClock()
                break
            case 7:
                this._initOption('scatter');
                break
            case 8:
                this._init('pointcloud');
                break
            // case 8:
            //     this._test2();
            //     break
        }
        // option.series[0].large = true;
        if (value == 7) {
            myChart.setOption(option)
        }
        this.setState({txStyle: txStyle});
    }

    _initClock() {
        let datas = [];
        let dataV = [];
        for (var x = 0; x < 4; x++) {
            for (var i = 0; i < data.length / 4; i++) {
                dataV.push(data[x * data.length / 4 + i])

            }
            datas.push(dataV)
            dataV = []
        }
        option = null
        option = {
            angleAxis: {
                type: 'category',
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
            },
            radiusAxis: {},
            polar: {},
            series: [
                {
                    type: 'bar',
                    data: datas[0],
                    coordinateSystem: 'polar',
                    name: 'A',
                    stack: 'a',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    type: 'bar',
                    data: datas[1],
                    coordinateSystem: 'polar',
                    name: 'B',
                    stack: 'a',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    type: 'bar',
                    data: datas[2],
                    coordinateSystem: 'polar',
                    name: 'C',
                    stack: 'a',
                    emphasis: {
                        focus: 'series'
                    }
                },
                {
                    type: 'bar',
                    data: datas[3],
                    coordinateSystem: 'polar',
                    name: 'C',
                    stack: 'a',
                    emphasis: {
                        focus: 'series'
                    }
                }
            ],
            legend: {
                show: true,
                data: ['事件1', '事件2', '事件3', '事件4']
            }
        };
    }

    _initPie() {
        option = null;
        option = {
            series: [
                {
                    data: data,
                    type: 'pie',
                    sampling: "lttb"
                },
            ]
        };
    }

    handleDownloadCSV() {
        const tHeader = "Id,标题,作者,读数,时间,";
        const filterVal = [
            "id",
            "title",
            "author",
            "pageviews",
            "display_time"
        ];
        // this.list.unshift(Headers)
        const list = this.list;
        let csvString = '';
        // csvString += '\r\n'
        data.map(item => {
            // filterVal.map(key => {
            let value = item;
            csvString += value + ",";
            // });
            csvString += "\r\n";
        });
        console.log(csvString)
        csvString = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(csvString);
        let link = document.createElement('a');
        link.href = csvString;
        //对下载的文件命名
        link.download = "1000一维数据.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    _initHeatmap(type) {
        option = null
        let datas = [[]];
        let hours = [];
        let days = [];
        for (var i = 0; i < 50; i++) {
            days.push(i);
        }
        for (var i = 0; i < data.length / days.length; i++) {
            hours.push(i)
        }
        for (var i = 0, x = 0, z = 0; i < data.length; i++, z++) {
            if (i % days.length == 0 && i != 0) {
                x++;
                z = 0;
            }
            datas[i] = [x, z, data[i]]
        }
        option = {
            xAxis: {
                type: 'category',
                data: hours,
            },
            yAxis: {
                type: 'category',
                data: days,
            },
            tooltip: {
                trigger: "axis"
            },
            visualMap: {
                min: min,
                max: max,
                calculable: true,
                left: 'left',
                bottom: '15%'
            },
            series: [
                {
                    name: 'Punch Card',
                    type: 'heatmap',
                    data: datas,
                    sampling: 'lttb'
                }
            ]
        };
        if (type == 'piecewise') {
            option.visualMap.type = 'piecewise'
        } else {
            option.visualMap.type = 'continuous';
        }
    }

    _initRadar() {
        let indicator = [];
        for (var i = 0; i < data.length; i++) {
            indicator[i] = {name: i + '', max: 500};
        }
        option = null
        option = {
            radar: {
                indicator: indicator
            },
            series: [
                {
                    name: 'Budget vs spending',
                    type: 'radar',
                    data: [{value: data}],
                }
            ]
        };
    }
}

function mapStateToProps(store) {
    return {
        ecgEventIndexData: store.EcgHistoryReducer.ecgEventIndexData,
    }
}

export default connect(mapStateToProps)(injectIntl(EChartsModel2));