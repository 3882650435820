/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import PageTitle from "../../../../../components/PageTitle";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import DeviceListModal from "./DeviceListModal";
import DeviceBindModal from "./DeviceBindModal";
import GuarderListModal from "./GuarderListModal";
import GuarderBindModal from "./GuarderBindModal";
import HttpUtils from "../../../../../utils/HttpUtils";
import {refreshSearchDeviceCode} from "../../../actions/DeviceAction";
import DeviceBindHistoryModal from "./DeviceBindHistoryModal";
import GuarderBindHistoryModal from "./GuarderBindHistoryModal";

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            height: "100%",
        }
    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    render() {
        return (
            <div className="page-content position-relative" style={{maxHeight: height}}>
                <PageTitle title="ECG_BINDER_ROUTE_BINDERS"/>
                <DeviceListModal/>
                <DeviceBindModal/>
                <DeviceBindHistoryModal/>
                <GuarderListModal/>
                <GuarderBindModal/>
                <GuarderBindHistoryModal/>
                <SearchCondition/>
                <SearchResult/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));