import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import DetailModal from "./DetailModal";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        return (
            <div className="page-content">
                <DetailModal/>
                <SearchCondition/>
                <SearchResult/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));