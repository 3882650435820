/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import PageTitle from "../../../../../../components/PageTitle";
import {Upload, Button, Icon, Modal, Form, Row, Col, Spin, DatePicker} from 'antd';
import message from "../../../../../../components/toast";
import {CACHE_ACCESS_TOKEN} from "../../../../../../constants/Profile";
import {importNCOV} from "../../../../WebService";
import {HttpStatus} from "../../../../../../constants/Enums";
import {Label_8_Wrapper_16} from "../../../../../../constants/FormItemLayout";
import moment from "moment";
import reqwest from 'reqwest';
import {FORMAT_DATE_TIME_SIMPLE} from "../../../../../../constants/DateTimeFormats";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            publishTime: new moment(),
            fileList: [],
            uploading: false,
        }
    }

    _validate() {
        let messageMap = {};

        const formatMessage = this.state.formatMessage;
        if (this.state.publishTime === undefined || this.state.publishTime === "") {
            messageMap["publishTime"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (this.state.fileList === undefined || this.state.fileList.length <= 0) {
            messageMap["fileList"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleSubmit = () => {
        const {fileList} = this.state;

        // 验证
        if (!this._validate()) {
            return;
        }

        const formData = new FormData();
        formData.append('file', fileList[0]);

        this.setState({
            uploading: true,
        });

        // You can use any AJAX library you like
        reqwest({
            url: importNCOV + "?publishTime=" + this.state.publishTime.format(FORMAT_DATE_TIME_SIMPLE),
            method: 'post',
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN)
            },
            processData: false,
            data: formData,
            success: (response) => {
                this.setState({
                    fileList: [],
                    uploading: false,
                });
                if (response.status === HttpStatus.SUCCESS) {
                    message.success(this.state.formatMessage({id: "COMMON_MSG_UPLOAD_SUCCESS"}));
                } else {
                    Modal.error({
                        title: "ERROR",
                        content: this.state.formatMessage({id: response.data}),
                        okText: "OK",
                        zIndex: 9999,
                        mask: false
                    });
                }
            },
            error: (response) => {
                console.log(response);
                this.setState({
                    uploading: false,
                });
                Modal.error({
                    title: "ERROR",
                    content: "upload failed.",
                    okText: "OK",
                    zIndex: 9999,
                    mask: false
                });
            },
        });
    };

    render() {
        const self = this;
        const uploadProps = {
            onRemove: (file) => {
                this.setState(({fileList}) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file) => {
                this.setState({fileList: [file]});
                return false;
            },
            fileList: this.state.fileList,
        };

        return (
            <Spin spinning={this.state.uploading}>
                <div className="page-content">
                    <PageTitle title="DAS_DASHBOARD_ROUTE_NCOV_IMPORT"/>
                    <Form>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label={this.state.formatMessage({id: 'COMMON_FIELD_LAST_MODIFY_TIME'})}
                                    {...Label_8_Wrapper_16} required={true}
                                    validateStatus={this.state.messageMap["publishTime"] ? "error" : ""}
                                    help={this.state.messageMap["publishTime"]}>
                                    <DatePicker
                                        showTime format="YYYY-MM-DD HH:mm"
                                        placeholder={this.state.formatMessage({id: 'COMMON_FIELD_FILE'})}
                                        value={this.state.publishTime}
                                        onChange={(value) => this.setState({publishTime: value})}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label={this.state.formatMessage({id: 'COMMON_FIELD_FILE'})}
                                    {...Label_8_Wrapper_16} required={true}
                                    validateStatus={this.state.messageMap["fileList"] ? "error" : ""}
                                    help={this.state.messageMap["fileList"]}>
                                    <Upload {...uploadProps}>
                                        <Button>
                                            <Icon type="upload"/> <FormattedMessage id="COMMON_BUTTON_SELECT_FILE"/>
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8} className="text-right">
                                <Form.Item>
                                    <Button type="primary" icon="upload"
                                            onClick={() => this._handleSubmit()}>
                                        <FormattedMessage id='COMMON_BUTTON_SUBMIT'/>
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Spin>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));