import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Form, Row, Col, Input, Tabs} from "antd";
import ReactMarkdown from 'react-markdown';
import {refreshContent} from '../actions/ContentAction';
import {findDOMNode} from "react-dom";
import {CACHE_ACCESS_TOKEN, CACHE_SITE_CODE, FTP_SERVER_HOST} from "../../../constants/Profile";
// import Simditor from "simditor";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../../../constants/DateTimeFormats";
import moment from "moment";

class ContentSummaryEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedTabKey: "rich"
        }
    }

    componentDidMount() {
        // const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        // let textbox = findDOMNode(this.componentRef);
        // if (this.componentRef) {
        //     this.editor = new Simditor({
        //         textarea: textbox,
        //         toolbar: [
        //             'title',
        //             'bold',
        //             'italic',
        //             'underline',
        //             'strikethrough',
        //             'fontScale',
        //             'color',
        //             'ol',
        //             'ul',
        //             'blockquote',
        //             'code',
        //             'table',
        //             'image',
        //             'indent',
        //             'outdent',
        //             'alignment',
        //             'hr',
        //         ],
        //         defaultImage: 'images/image.png',
        //         upload: {
        //             url: FTP_SERVER_HOST + "/ftp/oss/file?editorType=Simditor&X-Auth-Token=" + localStorage.getItem(CACHE_ACCESS_TOKEN),
        //             params: {
        //                 folder: siteCode + "/contents/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE)
        //             },
        //             fileKey: 'file',
        //             connectionCount: 1,
        //             leaveConfirm: 'Uploading is in progress, are you sure to leave this page?'
        //         },
        //         pasteImage: true
        //     });
        //     // this.props.dispatch(refreshMailContentEditor(this.editor));
        //     this.editor.setValue(this.props.content.summary);
        //
        //     //监听改变
        //     this.editor.on("valuechanged", (e, src) => {
        //         if (this.state.selectedTabKey === "rich") {
        //             const html = this.editor.getValue().trim();
        //             console.log(html);
        //             this._handleChange("summary", html);
        //         }
        //     });
        // }
    }

    _handleChange(name, value) {

        const content = Object.assign({}, this.props.content);
        content[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshContent(content));
    }

    render() {
        const {content} = this.props;
        return (
            <Tabs type="card" onChange={(key) => this.setState({selectedTabKey: key})}>
                <Tabs.TabPane tab={this.state.formatMessage({id: "CMS_CONTENT_TITLE_EDITOR_RICH"})} key="rich">
                    {this._renderRich(content)}
                </Tabs.TabPane>
                <Tabs.TabPane tab={this.state.formatMessage({id: "CMS_CONTENT_TITLE_EDITOR_MARKDOWN"})} key="markdown">
                    {this._renderMarkdown(content)}
                </Tabs.TabPane>
            </Tabs>
        );
    }

    _renderMarkdown(content) {
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Input.TextArea rows={47} value={content.summary} onChange={(data) => {
                        this._handleChange("summary", data);
                        this.editor.setValue(this.props.content.summary);
                    }}/>
                </Col>
                <Col span={12}>
                    <div style={{height: '1000px', overflowY: 'scroll'}}>
                        <ReactMarkdown source={content.summary} escapeHtml={false}/>
                    </div>
                </Col>
            </Row>
        )
    }

    _renderRich() {
        return (
            <div>
            <textarea id="editer" ref={el => {
                console.log("editer", el);
                this.componentRef = el;
            }} placeholder="" autoFocus></textarea>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        content: store.ContentReducer.content
    }
}

export default connect(mapStateToProps)(injectIntl(ContentSummaryEditor));