/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';

import {Drawer} from 'antd';
import DateIndexOverviewChart from "./DateIndexOverviewChart";

export default class DateIndexOverview extends Component {

    render() {
        return (
            <Drawer
                className="history-events-modal"
                title={false}
                placement="bottom"
                closable={false}
                visible={this.props.visible}
                destroyOnClose={true}
                mask={false}
                height={"auto"}
                style={{width: document.body.clientWidth - 180}}>
                <DateIndexOverviewChart {...this.props} />
            </Drawer>
        );
    }
}
