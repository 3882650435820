/**
 * Created by Ethan on 2017/11/7.
 */


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import {Panel, Form, FormGroup, Col, FormControl, Button, ControlLabel, Modal} from 'react-bootstrap';
import {Map, Marker, NavigationControl, InfoWindow, PointLabel} from 'react-bmap';

import {searchDeviceList} from '../../modules/DAS/actions/DeviceAction';

import "./MonitorView.css";

class MonitorView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //currentPage: 99999

            isPagination : false,
        }
    }

    componentWillMount() {
        // this.props.dispatch(searchDeviceList(this.state, {}, {isPagination : false}));
    }

    componentDidMount() {
//
//        var BMap = window.BMap//取出window中的BMap对象
//        console.log(BMap);
//        var map = new BMap.Map("container");
//// 创建地图实例
//        var point = new BMap.Point(116.404, 39.915);
//// 创建点坐标
//        map.centerAndZoom(point, 15);
//// 初始化地图，设置中心点坐标和地图级别
    }

    render() {
        const data = [];
        this.props.deviceList.map((item, i) => {

            if(item.lastLocation) {
                let lng = item.lastLocation.longitude;
                if (item.longitude) {
                    lng = (parseInt(item.lastLocation.longitude / 100)) + (item.lastLocation.longitude % 100) / 60;
                }

                let lat = item.lastLocation.latitude;
                if (item.latitude) {
                    lat = (parseInt(item.lastLocation.latitude / 100)) + (item.lastLocation.latitude % 100) / 60;
                }

                data.push({
                    name: item.deviceName,
                    index: i,
                    color: 'red',
                    isShowNumber: false,
                    point: {
                        lng: lng,
                        lat: lat
                    }
                });
            }
        })
        return (
            <div style={{height:'100%'}}>
                <Map style={{height:'100%'}} center={{lng: 121, lat: 42}} zoom="6" enableScrollWheelZoom>
                    <PointLabel data={data} changePosition={(point, index) => {console.log(point, index);}}/>
                </Map>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        deviceList: store.DeviceListReducer.deviceList
    }
}
export default connect(mapStateToProps)(MonitorView);