import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    roles: [],
    selectedRoles: [],
    showRoles: false
};

const RoleReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ROLE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ROLE_SEARCH:
            return Object.assign({}, state, {
                roles: action.roles
            });
        case ActionTypes.ROLE_REFRESH_SELECTED_ROLES:
            return Object.assign({}, state, {
                selectedRoles: action.selectedRoles
            });
        case ActionTypes.ROLE_REFRESH_SHOW_ROLES:
            return Object.assign({}, state, {
                showRoles: action.showRoles
            });
        default:
            return state
    }
};

export default RoleReducer;