/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {
    Button, Select, Table, Radio, Modal
} from "antd";
import {refreshEcgDateIndexFlag} from "../../../actions/HistoryAction";
import moment from "moment";
import {FORMAT_DATE_SIMPLE, FORMAT_DATE_TIME_SLASH} from "../../../../../constants/DateTimeFormats";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import {
    getDeviceById,
    getDeviceHistoryData,
    refreshChannelType,
    toggleDetailModal
} from "../../../actions/DeviceAction";
import {initDeviceLogs} from "../../../actions/LogDataServerAction";
import {RoutePath} from "../../../../../constants/RoutePath";

//10全部 0诊断紧急 1跌倒紧急 2电极紧急 3诊断紧急 4设备状态
let model = 10;
let dataSource = [];

class DetailModalRouteEventTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            editNameFlag: false,
            inputReadFileIndexFlag: false,
            inputReadFileIndex: 0,
            binderIndex: 0,
            dataSource: [],
            columns: [
                {
                    key: 'EventDate',
                    dataIndex: 'EventDate',
                    title: "年月日",
                },
                {
                    key: 'EventTime',
                    dataIndex: 'EventTime',
                    title: "时分秒",
                },
                {
                    key: "EventLevel",
                    dataIndex: "EventLevel",
                    title: "事件类型",
                    render: (text, record, index) => this._renderEventLevel(text, record, index)
                },
                {
                    key: "EventCode",
                    dataIndex: "EventCode",
                    title: "诊断结果",
                    render: (text, record, index) => this._renderEventCode(text, record, index)
                },
                // {
                //     key: "model",
                //     dataIndex: "model",
                //     title: "数据源",
                //     render: (text, record, index) => this._renderModel(text, record, index)
                // }
            ]
        }
    }

    _renderEventLevel(text, record, index) {
        switch (text) {
            case 0:
                return "诊断紧急"
            case 1:
                return "跌倒紧急"
            case 2:
                return "电极紧急"
            case 3:
                return "诊断结果"
            case 4:
                return "设备状态"
            default:
                break

        }
    }

    _renderEventCode(text, record, index) {
        let str;
        switch (text) {
            case 0xF0:
                return "心肌梗塞"
                break
            case 0xF1:
                return "心动极缓"
                break
            case 0xF2:
                return "心动急速"
                break
            case 0xe0:
                return "跌倒"
                break
            case 0xe1:
                return "撞击"
                break
            case 0xe2:
                return "平躺"
                break
            case 0xd0:
                return "电极轻微脱落<=2个电极"
                break
            case 0xd1:
                return "电极严重脱落>=3个电极"
                break
            case 0xd2:
                return "RLD电极脱落 未使用"
                break
            case 0x0:
                return "正常"
                break
            case 0x01:
                return "心动过缓"
                break
            case 0x02:
                return "心动过速"
                break
            case 0x03:
                return "左束枝传导组织"
            default:
                return "未知";
        }
        // return <a onClick={this._handleShowDetailModal.bind(this, record)}>{str}</a>
        // console.log("str:",str)
        // return {str};
    }

    _renderModel(text, record, index) {
        switch (text) {
            case 10:
                return "设备"
            case 20:
                return "APP"
            default:
                return "未知";
        }
    }

    _handleShowDetailModal(record) {
        let self = this;
        if (new Date().getTime() -
            new Date(new Date().toJSON().substr(0, 11) + record.EventTime).getTime() < 60000) {
            Modal.confirm({
                title: '提示',
                content: '刚刚生成的历史文件服务器中可能没有，是否继续读取',
                okText: 'Yes',
                cancelText: 'No',
                onOk() {
                    self._onOK(record, 1)
                },
            })
        } else {
            this._onOK(record, 2)
        }
        // console.log("_handleShowDetailModal:",
        //     new Date().getTime() -
        //     new Date(new Date().toJSON().substr(0, 11) + record.EventTime).getTime())

    }


    _onOK(record, index) {
        if (record) {
            this.props.setDefaultActiveKey("5");
            this.props.setHistoryIndex(record.HistoryFileIndex)
            this.props.setHistoryFlag(false);
            this.props.setRecordValue(record);
        }
        console.log("_onOK:",record)
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentDidMount() {
        this.setState({dataSource: this.props.dataSource})
        this.intervalClockLog = setInterval(() => {
            dataSource = []
            this.props.dataSource.map((item, index) => {
                if ((item.EventLevel == model || model == 10) && item.model == this.props.channelType) {
                    dataSource.push(item);
                }
            })
            this.setState({dataSource: dataSource})
            // console.log("componentWillReceiveProps:", dataSource, model);
            // this.setState({dataSource: this.props.dataSource})
        }, 500);
    }

    componentWillUnmount() {
        clearInterval(this.intervalClockLog);
    }

    render() {
        const columns = Object.assign([], this.state.columns);
        return (
            <div>
                <p>在实时心电页面点击“开始”，开始接收异常事件</p>
                {/*<Radio.Group onChange={(event) => {*/}
                {/*    this._handleChannelTypeChange(event.target.value);*/}
                {/*}} value={this.props.channelType}>*/}
                {/*    <Radio value={'10'}>设备</Radio>*/}
                {/*    <Radio value={'20'}>手机</Radio>*/}
                {/*</Radio.Group>*/}
                <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                    <Button className="spacing-h"  onClick={() => this._setModel(10)}>
                        全部
                    </Button>
                    <Button className="spacing-h" style={{background:"#d71b38",color:"#ffffff"}} onClick={() => this._setModel(0)}>
                        诊断紧急
                    </Button>
                    <Button className="spacing-h" style={{background:"#f87703",color:"#ffffff"}} onClick={() => this._setModel(1)}>
                        跌倒紧急
                    </Button>
                    <Button className="spacing-h" style={{background:"#f5c74d",color:"#ffffff"}} onClick={() => this._setModel(2)}>
                        电极脱落
                    </Button>
                    <Button className="spacing-h" style={{background:"#2956b1",color:"#ffffff"}} onClick={() => this._setModel(3)}>
                        诊断结果
                    </Button>
                </div>

                <Table rowKey="id"
                       size="middle"
                       className="spacing-v"
                       pagination={"bottom"}
                       columns={columns}
                       scroll={{y: 638}}
                       dataSource={dataSource}
                       style={{marginBottom: 10}}
                       onRow={record => {
                           return {
                               onClick: this._handleShowDetailModal.bind(this, record)
                           }
                       }}
                />
            </div>
        );
    }

    // _handleChannelTypeChange(value) {
    //     this.props.dispatch(refreshChannelType(value));
    // }

    _setModel(mo) {
        model = mo;
    }

}

function mapStateToProps(store) {
    return {
        device: store.EcgDeviceReducer.device,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        user: store.AccountUserReducer.user,
        channelType: store.EcgDeviceReducer.channelType
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModalRouteEventTab));