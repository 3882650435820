/**
 * Created by Ethan on 2018/1/12.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Drawer} from "antd";

class SearchDrawer extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const pathname = this.props.pathname;
        const screenMap = this.props.screenMap;
        console.log(screenMap);
        return (
            // 该容器的父容器样式必须有声明相对定位
            <Drawer
                title={false}
                placement={this.props.placement}
                closable={false}
                // visible={true}
                visible={this.props.visible?this.props.visible:screenMap && pathname && screenMap[pathname]}
                getContainer={false}
                mask={false}
                height={"auto"}
                style={{ position: 'absolute'}}
            >
                {this.props.children}
            </Drawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        pathname: store.AppReducer.pathname,
        screenMap: store.EcgDeviceReducer.screenMap,
    }
}


export default connect(mapStateToProps)(injectIntl(SearchDrawer));