/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    provincials: [],
    provincial: {},
    provincialMap: {}, // key: provincialId, value: provincial
    showDetailModal: false
};

const ProvincialReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_PROVINCIAL_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_PROVINCIAL_SEARCH:
            return Object.assign({}, state, {
                provincials: action.provincials
            });
        case ActionTypes.DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL:
            return Object.assign({}, state, {
                provincial: action.provincial
            });
        case ActionTypes.DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL_MAP:
            return Object.assign({}, state, {
                provincialMap: action.provincialMap
            });
        case ActionTypes.DISTRICT_PROVINCIAL_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default ProvincialReducer;