export const ContentType = {
    List: [
        {value: "01", label: "CMS_CONTENT_TYPE_ARTICLE"}
    ],
    Map: {
        "01": "CMS_CONTENT_TYPE_ARTICLE"
    },
    Enum: {
        ARTICLE: "01"
    },
};

export const ContentTitleLayout = {
    List: [
        {value: "00", label: "CMS_CONTENT_TITLE_LAYOUT_NO_PICTURE"},
        {value: "01", label: "CMS_CONTENT_TITLE_LAYOUT_RIGHT_PICTURE"},
        {value: "02", label: "CMS_CONTENT_TITLE_LAYOUT_LEFT_PICTURE"},
        {value: "03", label: "CMS_CONTENT_TITLE_LAYOUT_THREE_PICTURE"},
        {value: "04", label: "CMS_CONTENT_TITLE_LAYOUT_BOTTOM_PICTURE"},
        {value: "05", label: "CMS_CONTENT_TITLE_LAYOUT_SLIDE_SHOW"},
    ],
    Map: {
        "00": "CMS_CONTENT_TITLE_LAYOUT_NO_PICTURE",
        "01": "CMS_CONTENT_TITLE_LAYOUT_RIGHT_PICTURE",
        "02": "CMS_CONTENT_TITLE_LAYOUT_LEFT_PICTURE",
        "03": "CMS_CONTENT_TITLE_LAYOUT_THREE_PICTURE",
        "04": "CMS_CONTENT_TITLE_LAYOUT_BOTTOM_PICTURE",
        "05": "CMS_CONTENT_TITLE_LAYOUT_SLIDE_SHOW"
    },
    Enum: {
        NO_PICTURE: "00",
        RIGHT_PICTURE: "01",
        LEFT_PICTURE: "02",
        THREE_PICTURE: "03",
        BOTTOM_PICTURE: "04",
        SLIDE_SHOW: "04"
    }
};