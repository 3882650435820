/**
 * Created by Ethan on 2018/1/12.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Button, Icon} from "antd";
import {refreshScreenMap} from "../modules/DAS/actions/DeviceAction";
import HttpUtils from "../utils/HttpUtils";

const deviceCode = HttpUtils.getQueryString("deviceCode");

class DrawerSwitch extends Component {

    constructor(props) {
        super(props);

        this.state = {
            screens: [
                "/monitor/region",
                "/das/devices",
                "/das/devices/monitor",
                "/das/binders",
                "/das/guardians",
                "/cms/software/version",
                "/ecg/history/query",
                "/ecg/history/calendar",
                // "/das/ecg/real/time"
            ]
        }
    }

    showDrawer = (pathname,screenMap) => {
        window.NONITOR_DATA_REFRESH = 0;
        setTimeout( function(){
            window.NONITOR_DATA_REFRESH = 1;
        }, 500 );
        // searchMap && searchMap[pathname];
        if (screenMap && screenMap[pathname]){
            screenMap[pathname] = !screenMap[pathname];
        }else {
            screenMap[pathname] = true;
        }
        const searchMapTemp = Object.assign({}, screenMap);
        this.props.dispatch(refreshScreenMap(searchMapTemp));
    };

    render() {
        const screenMap = this.props.screenMap;
        const pathname = this.props.pathname;
        const screens = this.state.screens;
        console.log("1:"+pathname);
        return (
            // 该容器的父容器样式必须有声明相对定位
            <div className={screens.indexOf(pathname) > -1 ? "pull-right" : ""}
                 style={screens.indexOf(pathname) > -1 ? {marginRight:50} : {display:"none"}}>
                {
                    screens.indexOf(pathname) > -1 ?
                        <Button type="primary"
                                onClick={() => this.showDrawer(pathname,screenMap)}>
                            <FormattedMessage id="COMMON_BUTTON_SCREEN"/>
                            <Icon type={screenMap && screenMap[pathname] ? 'up' : 'down'} />
                        </Button> : null
                }
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        mapScreenVisible: store.EcgDeviceReducer.mapScreenVisible,
        deviceListScreenVisible: store.EcgDeviceReducer.deviceListScreenVisible,
        screenMap: store.EcgDeviceReducer.screenMap
    }
}


export default connect(mapStateToProps)(injectIntl(DrawerSwitch));