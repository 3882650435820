import React, {Component} from 'react';

import {Tabs, Descriptions, Button} from 'antd';

import "./CityTabs.css";
const cityWordMap = require("./CityTabs.json");

export default class CityTabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sessionUser: {}
        };
    }

    _handleSelectCity(value) {
        this.props.onClick && this.props.onClick.call(this, value);
    }

    _handleChangeTabPane() {
        this.props.onChange && this.props.onChange.call(this);
    }

    render() {
        return (
            <Tabs id="city-tabs"
                  className="city-tabs"
                  defaultActiveKey="0"
                  onChange={() =>  this._handleChangeTabPane()}>
                {
                    Object.keys(cityWordMap).map((key, index) => {
                        if (key === "热门城市") {
                            return this._renderHotCity(key, index);
                        } else {
                            return this._renderCity(key, index);
                        }
                    })
                }
            </Tabs>
        );
    }

    _renderHotCity(key, index) {
        return (
            <Tabs.TabPane tab={key} key={index}>
                {
                    cityWordMap[key].map((item, index) => {
                        return (
                            Object.keys(item).map((region, regionIndex) => {
                                return (
                                    <Descriptions title={region} size="small" column={5} key={regionIndex}>
                                        {
                                            item[region].map((regionItem, regionItemIndex) => {
                                                return <Descriptions.Item key={regionItemIndex}
                                                                          className="city-word-button">
                                                    <Button type="link"
                                                            onClick={() => this._handleSelectCity(regionItem.value)}>{regionItem.title}</Button>
                                                </Descriptions.Item>
                                            })
                                        }
                                    </Descriptions>
                                )
                            })
                        )
                    })
                }

            </Tabs.TabPane>
        )
    }

    _renderCity(key, index) {
        return (
            <Tabs.TabPane tab={key} key={index}>
                <Descriptions column={5}>
                    {
                        cityWordMap[key].map((item, index) => {
                            return <Descriptions.Item key={index}
                                                      className="city-word-button">
                                <Button type="link"
                                        onClick={() => this._handleSelectCity(item.value)}>{item.title}</Button>
                            </Descriptions.Item>
                        })
                    }
                </Descriptions>
            </Tabs.TabPane>
        )
    }
}