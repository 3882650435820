/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Form, Button, Input} from 'antd';

import {
    refreshDevice,
    refreshMapSearchCondition,
    searchDeviceList, toggleCreateModal
} from '../../../actions/DeviceAction';

import {refreshScreenMap} from "../../../actions/DeviceAction";
import {LeadState, OnlineState, SysState} from "../../../Enums";
import SearchDrawer from "../../../../../components/SearchDrawer";
import EnumItemSelect from "../../../../../components/EnumItemSelect";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch(this.props.mapSearchCondition);
        // this.intervalRefresh = setInterval(() => {
        //     this._handleSearch(this.props.searchCondition);
        // }, 5000);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        // clearInterval(this.intervalRefresh);
    }

    _handleSearch(mapSearchCondition) {
        this.props.dispatch(searchDeviceList(mapSearchCondition, this.state.formatMessage, true,false,true));
        const screenMap = this.props.screenMap;
        const pathname = "/das/devices/monitor";
        screenMap[pathname] = true;
        if (screenMap && screenMap[pathname]){
            screenMap[pathname] = !screenMap[pathname];
        }else {
            screenMap[pathname] = true;
        }
        const searchMapTemp = Object.assign({}, screenMap);
        this.props.dispatch(refreshScreenMap(searchMapTemp));
    }

    _handleChange(name, value) {
        let mapSearchCondition = Object.assign({}, this.props.mapSearchCondition);
        if( name === "deviceCode" ){
            mapSearchCondition[name] = value.target ? "%" + value.target.value + "%" : "%" + value + "%";
        } else {
            mapSearchCondition[name] = value.target ? value.target.value : value;
        }
        // mapSearchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshMapSearchCondition(mapSearchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshDevice({}));
        this.props.dispatch(toggleCreateModal(true));
    }

    _handleShowImportModal() {
        // this.props.dispatch(toggleImportModal(true));
    }

    _returnValue(mapSearchCondition){
        let deviceCode = mapSearchCondition.deviceCode;
            deviceCode = deviceCode && deviceCode.substring(1, deviceCode.lastIndexOf('%'));
        return deviceCode;
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <SearchDrawer placement={"top"}>
                <div>
                    <Form layout="inline" style={{backgroundColor: "#ffffcc", paddingLeft: "5px"}}>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}>
                            <Input value={this._returnValue(this.props.mapSearchCondition)} onChange={(value) => {
                                this._handleChange("deviceCode", value)
                            }}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})}>
                            <Input value={this.props.mapSearchCondition.deviceMac} onChange={(value) => {
                                this._handleChange("deviceMac", value)
                            }}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})}>
                            <Input value={this.props.mapSearchCondition.deviceName} onChange={(value) => {
                                this._handleChange("deviceName", value)
                            }}/>
                        </Form.Item>
                    </Form>
                    <Form layout="inline" style={{backgroundColor: "#ffffcc", paddingLeft: "5px"}}>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'})}>
                            <EnumItemSelect data={SysState.List}
                                            value={this.props.mapSearchCondition.deviceSysStates}
                                            mode="multiple"
                                            style={{width: 170}}
                                            onChange={(value) => {
                                                this._handleChange("deviceSysStates", value)
                                            }}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_ONLINE_STATUS'})}>
                            <EnumItemSelect data={OnlineState.List}
                                            value={this.props.mapSearchCondition.deviceOnlineStates}
                                            mode="multiple"
                                            style={{width: 170}}
                                            onChange={(value) => {
                                                this._handleChange("deviceOnlineStates", value)
                                            }}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})}>
                            <EnumItemSelect data={LeadState.List}
                                            value={this.props.mapSearchCondition.deviceLeadStates}
                                            mode="multiple"
                                            style={{width: 170}}
                                            onChange={(value) => {
                                                this._handleChange("deviceLeadStates", value)
                                            }}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" icon="search" className="spacing-h" loading={this.props.isLoading}
                                    onClick={() => this._handleSearch(this.props.mapSearchCondition)}>
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button icon="plus" className="spacing-h" onClick={() => this._handleShowCreateModal()}>
                                <FormattedMessage id='COMMON_BUTTON_ADD'/>
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        mapSearchCondition: store.EcgDeviceReducer.mapSearchCondition,
        isLoading: store.EcgDeviceReducer.isLoading,
        screenMap: store.EcgDeviceReducer.screenMap
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));