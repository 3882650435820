/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Divider, Table} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";

import {
    deleteGuarder,
    getGuarderBinders,
    refreshGuarder, searchGuardians,
    toggleBinderListModal
} from "../../../actions/GuarderAction";
import {Modal} from "antd/lib/index";
import {UserSex} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'userName',
                    dataIndex: 'userName',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_NAME'})
                },
                {
                    key: 'userMobile',
                    dataIndex: 'userMobile',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_MOBILE'})
                },
                {
                    key: 'userNickname',
                    dataIndex: 'userNickname',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_NICKNAME'})
                },
                {
                    key: 'userEmail',
                    dataIndex: 'userEmail',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_EMAIL'})
                },
                {
                    key: 'userSex',
                    dataIndex: 'userSex',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_SEX'}),
                    render: (text, record, index) => <EnumItemLabel map={UserSex.Map} code={text}/>
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowBindersModal.bind(this, record)}>
                    <FormattedMessage id="ECG_GUARDER_BUTTON_BINDERS"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </a>
            </div>
        )
    }

    _handleShowBindersModal(record) {
        this.props.dispatch(refreshGuarder(record));
        this.props.dispatch(toggleBinderListModal(true));
        this.props.dispatch(getGuarderBinders(record.userId, this.state.formatMessage));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "COMMON_MSG_DELETE_TITLE"}),
            content: formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"}),
            okType: 'danger',
            okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
            cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
            onOk() {
                dispatch(deleteGuarder(record.id, searchCondition, formatMessage));
            }
        })
    }

    render() {
        return (
            <Card className="spacing-v page-width">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.guardians} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchGuardians(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        guardians: store.GuardianReducer.guardians,
        searchCondition: store.GuardianReducer.searchCondition,
        pagination: store.GuardianReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));