/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl} from 'react-intl';
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";

import {Card, Col, Calendar, Tag, List} from 'antd';
import {RoutePath} from "../../../../../constants/RoutePath";
import {refreshSearchCondition} from "../../../actions/BinderAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedDataList: []
        }
    }

    render() {
        return (
            <Card className="spacing-v">
                <Col span={16}>
                    <Calendar onPanelChange={(value, mode) => this.onPanelChange(value, mode)}
                              onSelect={(value) => this.onSelect(value)}
                              dateCellRender={(value) => this.dateCellRender(value)}/>
                </Col>
                <Col span={8}>
                    <List
                        size="small"
                        dataSource={this.state.selectedDataList}
                        renderItem={item => (
                            <List.Item><a href={RoutePath.DAS_BINDER_DATA_ECG.path.replace(":id", item.id)}
                                          target="_blank">{item.filePath}</a></List.Item>)}
                    />

                </Col>
            </Card>
        )
    }

    // onItemClick(item){
    //     this.props.dispatch(refreshBinderDataItem(item));
    //     this.props.router.push(RoutePath.DAS_BINDER_DATA_ECG.path);
    // }

    dateCellRender(value) {
        const key = value.format(DateTimeFormats.FORMAT_DATE_SIMPLE);
        let listData = this.props.binderDataMap[key];
        if (listData == null) {
            return null;
        }
        return (
            <div className='text-center'>
                <Tag color="red">{listData.length}</Tag>
            </div>
        );
    }

    onSelect(value) {
        const key = value.format(DateTimeFormats.FORMAT_DATE_SIMPLE);
        let listData = this.props.binderDataMap[key];
        this.setState({selectedDataList: listData});
    }

    onPanelChange(value, mode) {
        console.log(value, mode);

        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.startTime = value.startOf('month').format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE);
        searchCondition.endTime = value.endOf('month').format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE);
        console.log(searchCondition);
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }
}

function mapStateToProps(store) {
    return {
        binderData: store.BinderDataReducer.binderData,
        binderDataMap: store.BinderDataReducer.binderDataMap,
        searchCondition: store.BinderReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));