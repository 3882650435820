import React, {Component} from 'react';
import {Drawer, Timeline} from 'antd';
import EnumItemLabel from "../../../../../../components/EnumItemLabel";
import {EcgFileEventType, EcgFileType} from "../../../../Enums";
import WebSocketUtils from "../../../../utils/WebSocketUtils";

let backgroundColorFlag = []
let backgroundColorFlag2 = []
export default class EventIndexTimeLine extends Component {

    constructor(props) {
        super(props);

        this.state = {
            backgroundColor: ""
        }
    }

    componentWillMount() {
        // this.timeTicket = setInterval(() => {
        //     console.log(backgroundColorFlag, backgroundColorFlag2, "backgroundColorFlag")
        //     if (backgroundColorFlag != backgroundColorFlag2) {
        //
        //         this.setState({backgroundColor: Math.random() + ""})
        //         backgroundColorFlag2 = backgroundColorFlag
        //     }
        // }, 500);
        // this._setQueryState();
    }

    componentDidUpdate() {
        // const eventIndexNode = document.getElementById("event_index_" + this.props.eventRecordIndex);
        // if (eventIndexNode && !this.isInViewPortOfOne(eventIndexNode)) {
        //     eventIndexNode && eventIndexNode.scrollIntoView({block: "end"});
        // }
    }

    /** 点击异常事件，控制播放进度条 */
    _handleTimeLineItemClick = (item) => {
        this.props.onTimeLineItemClick && this.props.onTimeLineItemClick.call(this, item);
    };

    setBackground(value, index) {
        backgroundColorFlag[index] = value;
        this.setState({backgroundColor: Math.random() + ""})
    }

    render() {
        return (
            <Drawer
                className="anomalous-events-modal"
                title="异常事件"
                placement="right"
                closable={false}
                visible={this.props.visible}
                destroyOnClose={true}
                mask={false}
                width={400}
                bodyStyle={{height: document.body.clientHeight - 240}}>
                <Timeline className="anomalous-events-timeline">
                    {
                        this.props.ecgEventIndexData && this.props.ecgEventIndexData.records && this.props.ecgEventIndexData.records.map((item, index) => {
                            const color = this.props.eventRecordIndex === index ? "red" : "gray";
                            return (
                                <Timeline.Item key={index} color={color}>
                                    <div id={"event_index_" + index}
                                         onMouseOver={() => this.setBackground(true, index)}
                                         style={{backgroundColor: backgroundColorFlag[index] ? "#B0E0E6" : "white"}}
                                         onMouseOut={() => this.setBackground(false, index)}
                                        // onMouseDown={this.setBackground("white")}
                                    >
                                        <div style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            width: "30%",
                                            textAlign: "right",
                                            cursor: "pointer"
                                        }} onClick={() => this._handleTimeLineItemClick(item)}>
                                            {item.eventTime}
                                        </div>
                                        <div style={{
                                            fontWeight: "bold",
                                            float: "left",
                                            width: "60%",
                                            marginLeft: '5px',
                                            cursor: "pointer"
                                        }}
                                             onClick={() => this._handleTimeLineItemClick(item)}>
                                            <EnumItemLabel map={EcgFileEventType.Map} code={item.eventType}/>
                                        </div>
                                        <div style={{clear: "both"}}/>
                                    </div>
                                </Timeline.Item>
                            )
                        })
                    }
                </Timeline>
            </Drawer>
        );
    }
}