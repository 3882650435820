/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';
import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";

import {Card, Table, Divider, Modal, Spin} from 'antd';

import {
    getDeviceById,
    searchDeviceList,
    toggleDetailModal,
    refreshSearchCondition
} from '../../../actions/DeviceAction';

import PaginationBar from "../../../../../components/PaginationBar";
import SearchResultItem from "./SearchResultItem";
import {FORMAT_DATE_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </a>
            </div>
        )
    }

    render() {
        return (
            <div>
                <PaginationBar pagination={this.props.mapPagination}
                               showSizeChanger={true}
                               onShowSizeChange={(page, pageSize) => this._handlePagination(page, pageSize)}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <div style={{height: (document.documentElement.clientHeight - 64 - 50 - 50), overflow: 'auto'}}>
                    {
                        this.props.mapDevices.map((item, index) => {
                            return <SearchResultItem key={index} item={item} index={index}/>
                        })
                    }
                </div>
            </div>
        )
    }

    _handlePagination(page, pageSize) {
        let mapSearchCondition = Object.assign({}, this.props.mapSearchCondition);
        mapSearchCondition["currentPage"] = page;
        mapSearchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchDeviceList(mapSearchCondition, this.state.formatMessage, true, false, true));
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getDeviceById(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));

        const startTime = moment().subtract(10, 'days').format(FORMAT_DATE_SIMPLE + "000000");
        const endTime = moment().format(FORMAT_DATE_SIMPLE + "235959");
        this.props.dispatch(searchDeviceCoordinates(record.id, startTime, endTime, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceReducer.isLoading,
        mapDevices: store.EcgDeviceReducer.mapDevices,
        mapPagination: store.EcgDeviceReducer.mapPagination,
        mapSearchCondition: store.EcgDeviceReducer.mapSearchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));