/**
 * Created by Ethan on 2017/11/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Form, DatePicker, Button, Select, Switch, Divider, Icon} from "antd";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import DateRangeDivider from "../../../../../components/DateRangeDivider";
import moment from "moment";
import {searchDeviceCoordinates, refreshSearchCondition} from "../../../actions/DeviceCoordinateAction";
import {getDeviceById} from "../../../actions/DeviceAction";
import DeviceDetailDrawer from "../../ECG/RealTime/DeviceDetailDrawer";
import selectedUrl from "../../../../../images/bmap/location-red.svg";
import blueUrl from "../../../../../images/bmap/location-blue.svg";
import blueCUrl from "../../../../../images/bmap/location-blue-change.svg";
import HttpUtils from "../../../../../utils/HttpUtils";

let locationMap;
const {Option} = Select;
// let bmap;
let viewPoints;
let qcFlag = true;

class DeviceMapTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            zoom: 17,
            toggleDeviceDetail: false,
            isFullScreen: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let lng = this.props.device && this.props.device.longitude;
        let lat = this.props.device && this.props.device.latitude;

        if (HttpUtils.getQueryString("lng") !== undefined && HttpUtils.getQueryString("lat") !== undefined) {
            lng = HttpUtils.getQueryString("lng");
            lat = HttpUtils.getQueryString("lat");
        }

        console.log("this.props", this.props.device, lng, lat)
        locationMap = new window.BMap.Map("locationMap");    // 创建Map实例
        locationMap.centerAndZoom(new window.BMap.Point(lng, lat), this.state.zoom);  // 初始化地图,设置中心点坐标和地图级别
        locationMap.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        this.forceUpdate();
    }


    render() {

        const {formatMessage} = this.state;
        const {searchCondition} = this.props;
        console.log(searchCondition);
        // const data = [];
        // let lng = this.props.device.longitude;
        // // if (this.props.longitude) {
        // //     lng = (parseInt(this.props.longitude / 100)) + (this.props.longitude % 100) / 60;
        // // }
        //
        // let lat = this.props.device.latitude;
        // // if (this.props.latitude) {
        // //     lat = (parseInt(this.props.latitude / 100)) + (this.props.latitude % 100) / 60;
        // // }
        //
        // console.log(lng);
        // console.log(lat);
        // data.push({
        //     name: this.props.device.code,
        //     color: 'red',
        //     isShowNumber: false,
        //     point: {
        //         lng: lng,
        //         lat: lat
        //     }
        // });
        // console.log(this.props.device);
        console.log("locationMap:", locationMap, this.props.coordinates.length)
        if (qcFlag) {
            this._renderMapMarker2(this.props.device)
        }

        if (locationMap && this.props.device && this.props.coordinates.length && !qcFlag) {
            locationMap.clearOverlays();
            this._renderMapMarker(this.props.device)
            // this._renderMapMarker2(this.props.device), zIndex: 9999
        }

        return (
            <div style={{height: '100%', position: 'relative'}}>
                <Form layout="inline" style={{position: 'absolute', zIndex: 1}}>
                    <Form.Item>

                        <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                    format={FORMAT_DATE_TIME_HYPHEN}
                                    value={searchCondition.startTime ? moment(searchCondition.startTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                    placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                    onChange={(value) => {
                                        this._handleChange("startTime", value.format(FORMAT_DATE_TIME_SIMPLE))
                                    }}/>
                        <DateRangeDivider/>
                        <DatePicker className="spacing-h"
                                    showTime={{format: FORMAT_TIME_COLON}}
                                    format={FORMAT_DATE_TIME_HYPHEN}
                                    value={searchCondition.endTime ? moment(searchCondition.endTime, FORMAT_DATE_TIME_SIMPLE) : null}
                                    placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                    onChange={(value) => {
                                        this._handleChange("endTime", value.format(FORMAT_DATE_TIME_SIMPLE))
                                    }}/>
                        {/*
                        <Select defaultValue="0" style={{ width: 140 }}>
                            <Option value="0">GPS+网络定位</Option>
                            <Option value="1">仅GPS</Option>
                            <Option value="2">仅网络定位</Option>
                        </Select>
                        */}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" loading={this.props.isLoading}
                                onClick={() => this._handleSearch(this.props.searchCondition, 1)}>
                            查询轨迹
                        </Button>
                        <Divider type="vertical"/>
                        <Button type="primary" loading={this.props.isLoading}
                                onClick={() => this._handleSearch(this.props.searchCondition, 2)}>
                            清除轨迹
                        </Button>
                    </Form.Item>
                </Form>
                <DeviceDetailDrawer visible={this.state.toggleDeviceDetail} setVis={this._setVis}/>
                <div id="locationMap" style={{height: '100%', width: "100%"}}></div>
                <div style={{
                    right: 50,
                    top: 50,
                    width: 35,
                    height: 35,
                    borderRadius: 7,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    cursor: "pointer"
                }}
                     onClick={() => this._fullScreen()}>

                    <Icon style={{fontSize: 30, margin: "2px 0 0 2px"}}
                          type={this.state.isFullScreen ? "fullscreen-exit" : "fullscreen"}/>
                </div>
            </div>
        );
    }

    _fullScreen() {
        let docElm = document.documentElement;
        //W3C
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        }
        //FireFox
        else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        }
        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
        //IE11
        else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
        let exitFullScreen = document.exitFullScreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen
        if (exitFullScreen) {
            exitFullScreen.call(document)
        }
        this.setState({isFullScreen: !this.state.isFullScreen});

    }

    _setVis = value => {
        this.setState({
            toggleDeviceDetail: value,
        });

    };

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleSearch(value, index) {
        const {device, searchCondition} = this.props;
        const {formatMessage} = this.state;
        if (index == 1) {
            qcFlag = false;
            this.props.dispatch(searchDeviceCoordinates(device.id, searchCondition.startTime, searchCondition.endTime, formatMessage));
        } else {
            // this.props.dispatch(searchDeviceCoordinates(device.id, "000000000","000000000", formatMessage));
            qcFlag = true;
            const lng = this.props.device && this.props.device.longitude;
            const lat = this.props.device && this.props.device.latitude;
            locationMap = new window.BMap.Map("locationMap");    // 创建Map实例
            locationMap.centerAndZoom(new window.BMap.Point(lng, lat), this.state.zoom);  // 初始化地图,设置中心点坐标和地图级别
            locationMap.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
            this.setState({zoom: this.state.zoom})
        }
    }

    _renderMapMarker(item) {
        const point = new window.BMap.Point(item.longitude, item.latitude);
        // const label = new window.BMap.Label;
        // label.setContent(item.code);
        //Label的中心定位，个位数的时候和2位数的时候分开处理。
        //标注在图片上显示的偏移量
        // label.setOffset(new window.BMap.Size(11, 5));
        // label.setStyle({
        //     width: "26px", height: "28px",
        //     fontSize: "12px", backgroundColor: "transparent",
        //     border: "none", fontWeight: "900"
        // });
        const blueUrl = require("../../../../../images/bmap/location-blue.svg");
        //蓝色默认
        let blueIcon = new window.BMap.Icon(blueUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });

        const marker = new window.BMap.Marker(point, {icon: blueIcon});
        // marker.setLabel(label);
        let sMarker;
        let eMarker;
        const viewPointList = [];
        for (let i = 0; i < this.props.coordinates.length; i++) {
            const coordinate = this.props.coordinates[i];
            if (i === 0) {
                const sPoint = new window.BMap.Point(coordinate.longitude, coordinate.latitude);
                sMarker = new window.BMap.Marker(sPoint);
                const sLabel = new window.BMap.Label;
                sLabel.setContent("起");
                //Label的中心定位，个位数的时候和2位数的时候分开处理。
                //标注在图片上显示的偏移量
                sLabel.setOffset(new window.BMap.Size(3, 3));
                sLabel.setStyle({
                    width: "26px", height: "28px",
                    fontSize: "10px", backgroundColor: "transparent",
                    color: "#FFF",
                    border: "none", fontWeight: "900"
                });
                sMarker.setLabel(sLabel);
            } else if (i === this.props.coordinates.length - 1) {
                const ePoint = new window.BMap.Point(coordinate.longitude, coordinate.latitude);
                eMarker = new window.BMap.Marker(ePoint);
                const eLabel = new window.BMap.Label;
                eLabel.setContent("终");
                //Label的中心定位，个位数的时候和2位数的时候分开处理。
                //标注在图片上显示的偏移量
                eLabel.setOffset(new window.BMap.Size(3, 3));
                eLabel.setStyle({
                    width: "26px", height: "28px",
                    fontSize: "10px", backgroundColor: "transparent",
                    color: "#FFF",
                    border: "none", fontWeight: "900"
                });
                eMarker.setLabel(eLabel);
            }
            viewPointList.push(new window.BMap.Point(coordinate.longitude, coordinate.latitude));
        }

        console.log("centerAndZoom");
        locationMap.centerAndZoom(new window.BMap.Point(item.longitude, item.latitude), this.state.zoom);  // 初始化地图,设置中心点坐标和地图级别

        const polyline = new window.BMap.Polyline(viewPointList
            , {
                strokeColor: 'blue',
                strokeWeight: 3,
                strokeOpacity: 0.5,
                strokeStyle: 'dashed'
            });

        if (locationMap) {
            locationMap.addOverlay(marker);
            locationMap.addOverlay(sMarker);
            locationMap.addOverlay(eMarker);
            locationMap.addOverlay(polyline);
            // setTimeout(()=>{
            //     const trackAni = new window.BMapLib.TrackAnimation(locationMap, polyline, {
            //         overallView: true,
            //         tilt: 30,
            //         duration: 20000,
            //         delay: 300
            //     });
            //     trackAni.start();
            // }, 3000);
        }
    }

    _renderMapMarker2(item, index) {
        const self = this;
        const point = new window.BMap.Point(item.longitude, item.latitude);
        const label = new window.BMap.Label;
        label.setContent(item.name);
        //Label的中心定位，个位数的时候和2位数的时候分开处理。
        //标注在图片上显示的偏移量
        // if (index >= 9) {
        label.setOffset(new window.BMap.Size(0, -15));
        // } else if (index >= 99) {
        //     label.setOffset(new window.BMap.Size(3, 5));
        // } else {
        //     label.setOffset(new window.BMap.Size(11, 5));
        // }

        label.setStyle({
            width: "26px", height: "28px",
            fontSize: "5px", backgroundColor: "transparent",
            border: "none", fontWeight: "900", color: "blue"
        });

        // 创建标注
        //黄色被选中
        let yellowIcon = new window.BMap.Icon(selectedUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });
        let marker;
        //点击标注修改图标
        // if (this.props.selectedDeviceId === item.id) {

        marker = new window.BMap.Marker(point, {icon: yellowIcon});
        marker.setTop(true);//将标注置于其他标注之上。
        // }
        marker.setLabel(label);
        console.log("marker:", marker)

        if (locationMap) {
            // marker.addEventListener("mouseover", () => ZoneMapInfoBoxUtils.drawOpen(marker, bmap, {
            //         name: item.name,
            //         number: item.number,
            //         photos: item.photos
            //     },
            //     {
            //         markerWidth: 5,
            //         markerHeight: 5
            //     }));
            // marker.addEventListener("mouseout", () => ZoneMapInfoBoxUtils.drawClose());
            locationMap.addOverlay(marker);
            //console.log(marker);
        }
        // if (viewPoints) {
        //     viewPoints.push(point);
        // }
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceCoordinateReducer.isLoading,
        device: store.EcgDeviceReducer.device,
        coordinates: store.EcgDeviceCoordinateReducer.coordinates,
        searchCondition: store.EcgDeviceCoordinateReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceMapTab));
