import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Descriptions, Divider, Icon, Tag, Popover, Row, Col, Empty, Avatar, Popconfirm} from "antd";

import {VIEWPORT_FLAG} from "../../../Enums";
import {
    deleteDevice,
    getDeviceById,
    refreshFilterMapViewportFlag,
    refreshSelectDeviceId, toggleDetailModal
} from "../../../actions/DeviceAction";
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_FULL_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN
} from "../../../../../constants/DateTimeFormats";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import EcgTransferStatus from "../../../components/EcgTransferStatus";
import moment from "moment";

class SearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        };
    }

    _handleClickLocation(item) {
        this.props.dispatch(refreshSelectDeviceId(item.id));
        this.props.dispatch(refreshFilterMapViewportFlag(VIEWPORT_FLAG.CHANGE));
    }

    _handleEdit(item) {
        // this.props.dispatch(refreshZoneLocationMapSearchResult([]));
        // this.props.dispatch(refreshZoneLocationIsLocationModalShow(false));
        this.props.dispatch(getDeviceById(item.id, this.state.formatMessage));
        // this.props.dispatch(addReadZone(item.id, this.props.readZoneIdList));
        this.props.dispatch(refreshFilterMapViewportFlag(VIEWPORT_FLAG.NO_CHANGE));
        this.props.dispatch(toggleDetailModal(true));

        const startTime = moment().subtract(10, 'days').format(FORMAT_DATE_SIMPLE + "000000");
        const endTime = moment().format(FORMAT_DATE_SIMPLE + "235959");
        this.props.dispatch(searchDeviceCoordinates(item.id, startTime, endTime, this.state.formatMessage));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        this.props.dispatch(deleteDevice(record, searchCondition, formatMessage));
    }

    render() {
        let cardStyle = {};
        const {formatMessage} = this.state;
        const {item} = this.props;
        // if (this.props.readZoneIdList.indexOf(item.id) >= 0) {
        //     cardStyle = {background: '#ffffed'};
        // }
        if (this.props.selectedDeviceId === item.id) {
            cardStyle = {background: '#ECF4FF'};
        }
        let proposalGroup = this.props.proposalGroup ? this.props.proposalGroup : {};
        let proposals = proposalGroup.proposals ? proposalGroup.proposals : [];
        let currentResearchProposalList = this.props.currentResearchProposalList && this.props.currentResearchProposalList.length > 0 ? this.props.currentResearchProposalList : [];
        let selectZoneIds = [];
        // let calculateResult = RentCalculateUtils.doCalculateZoneRentDay(item);
        if (proposals.length > 0) {
            selectZoneIds = proposals.map(proposal => proposal.zoneId);
        } else {
            selectZoneIds = currentResearchProposalList.map(researchProposal => researchProposal.zoneId);
        }

        // 默认只取第一个绑定用户
        const binder = item.binders && item.binders.length > 0 ? item.binders[0] : {};
        const portrait = binder.userPortraitFile ? binder.userPortraitFile : require("../../../../../images/logo.png");

        return (
            <Card size="small" id={item.id}
                  bodyStyle={cardStyle}
                  headStyle={cardStyle}
                  onClick={() => this._handleClickLocation(item)}
                  title={<div><span
                      className={"filter-index-image " + (this.props.selectedDeviceId === item.id ? "select" : "unSelect")}>{this.props.index + 1}</span>

                      <span className="spacing-h">#{item.code}</span><span>{item.name}</span></div>}
                  className="spacing-v"
                  extra={<div>

                      <Popover content={
                          <Descriptions column={2} size={"small"} bordered={true}>
                              <Descriptions.Item label="设备实时传输状态">
                                  <EcgTransferStatus status={item.devLiveOnlineStatus}/>
                              </Descriptions.Item>
                              <Descriptions.Item label="设备历史传输状态">
                                  <EcgTransferStatus status={item.devHisOnlineStatus}/>
                              </Descriptions.Item>
                              <Descriptions.Item label="手机实时传输状态">
                                  <EcgTransferStatus status={item.appLiveOnlineStatus}/>
                              </Descriptions.Item>
                              <Descriptions.Item label="手机历史传输状态">
                                  <EcgTransferStatus status={item.appHisOnlineStatus}/>
                              </Descriptions.Item>
                          </Descriptions>
                      } title="详细">
                          {
                              (item.devLiveOnlineStatus === 1 ||
                                  item.devHisOnlineStatus === 1 ||
                                  item.appLiveOnlineStatus === 1 ||
                                  item.appHisOnlineStatus === 1) ?
                                  <Tag color="green">
                                      <Icon type="cloud-sync" spin/>
                                  </Tag> :
                                  <Tag color="red">
                                      <Icon type="cloud-sync" style={{color: 'red'}}/>
                                  </Tag>
                          }
                      </Popover>

                      <Popover content={
                          <Descriptions column={2} size={"small"} bordered={true}>
                              <Descriptions.Item
                                  label="RA导联状态">{item.leadAState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="V2导联状态">{item.leadEState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="LA导联状态">{item.leadBState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="V3导联状态">{item.leadFState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="LL导联状态">{item.leadCState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="V4导联状态">{item.leadGState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="V1导联状态">{item.leadDState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                              <Descriptions.Item
                                  label="V5导联状态">{item.leadHState ? <Tag color="green">正常 </Tag> :
                                  <Tag color="red">脱落
                                  </Tag>}</Descriptions.Item>
                          </Descriptions>
                      } title="详细">
                          {
                              (item.leadAState && item.leadBState && item.leadCState && item.leadDState && item.leadEState && item.leadFState && item.leadGState && item.leadHState) ?
                                  <Tag color="green"><Icon type="link"/></Tag> :
                                  <Tag color="red"><Icon type="disconnect"/></Tag>
                          }
                      </Popover>

                      <Icon type="environment" onClick={() => this._handleClickLocation(item)}/>
                      <Divider type="vertical"/>
                      <Icon type="edit" onClick={() => this._handleEdit(item)}/>
                      <Divider type="vertical"/>
                      <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                                  onConfirm={() => this._handleDelete(item)}>
                          <Icon type="delete"/>
                      </Popconfirm>
                  </div>}>
                {
                    (item.binders && item.binders.length > 0) ?
                        <Row>
                            <Col span={6} className='text-center'>
                                <img style={{width: '80%'}} src={portrait}/>
                            </Col>
                            <Col span={18}>
                                <Descriptions size={"small"} column={1}>
                                    <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_NAME"})}>
                                        {binder.userName}
                                    </Descriptions.Item>
                                    <Descriptions.Item label={formatMessage({id: "ECG_BINDER_FIELD_USER_NICKNAME"})}>
                                        {binder.userNickname}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={formatMessage({id: "ECG_BINDER_FIELD_USER_MOBILE"})}>
                                        {binder.userMobile}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row> :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                               description="无绑定者数据"/>
                }

                <div className="clearfix"/>
                <div className="text-muted" style={{fontSize: "12px", marginTop: "10px"}}>
                    <FormattedMessage
                        id="COMMON_FIELD_UPDATE_TIME"/>：{item.updateTime ? moment(item.updateTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) :
                    moment(item.createTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN)}
                </div>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.EcgDeviceReducer.searchCondition,
        selectedDeviceId: store.EcgDeviceReducer.selectedDeviceId,
        //
        // currentResearchProposalList: store.WMSZoneReducer.currentResearchProposalList,
        // proposalGroup: store.WMSZoneReducer.currentProposalGroup,
        // zoneList: store.WMSZoneReducer.filterZoneList,

    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));