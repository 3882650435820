export const Label_4_Wrapper_20 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
    style: {marginBottom: "3px"}
};

export const Label_6_Wrapper_18 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
    style: {marginBottom: "3px"}
};

export const Label_8_Wrapper_16 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
    style: {marginBottom: "3px"}
};

export const Label_10_Wrapper_14 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
};

export const Label_12_Wrapper_12 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
    },
};

export const Label_3_Wrapper_21 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 3},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 21},
    },
    style: {marginBottom: "3px"}
};