/**
 * Created by Ethan on 17/1/13.
 */

import {DISTRICT_SERVER_HOST} from '../../constants/Profile';

export const getAllCountries = DISTRICT_SERVER_HOST + "/district/countries";
export const getCountryById = DISTRICT_SERVER_HOST + "/district/countries/{id}";
export const createCountry = DISTRICT_SERVER_HOST + "/district/countries";
export const updateCountryById = DISTRICT_SERVER_HOST + "/district/countries/{id}";
export const deleteByCountryId = DISTRICT_SERVER_HOST + "/district/countries/{id}";

export const getProvincialsByCountryId = DISTRICT_SERVER_HOST + "/district/countries/{id}/provincials";
export const getProvincialById = DISTRICT_SERVER_HOST + "/district/provincials/{id}";
export const createProvincial = DISTRICT_SERVER_HOST + "/district/provincials";
export const updateProvincialById = DISTRICT_SERVER_HOST + "/district/provincials/{id}";
export const deleteByProvincialId = DISTRICT_SERVER_HOST + "/district/provincials/{id}";

export const getCitiesByProvincialId = DISTRICT_SERVER_HOST + "/district/provincials/{id}/cities";
export const getCityById = DISTRICT_SERVER_HOST + "/district/cities/{id}";
export const createCity = DISTRICT_SERVER_HOST + "/district/cities";
export const updateCityById = DISTRICT_SERVER_HOST + "/district/cities/{id}";
export const deleteByCityId = DISTRICT_SERVER_HOST + "/district/cities/{id}";

export const getCountiesByCityId = DISTRICT_SERVER_HOST + "/district/cities/{id}/counties";
export const getCountyById = DISTRICT_SERVER_HOST + "/district/counties/{id}";
export const createCounty = DISTRICT_SERVER_HOST + "/district/counties";
export const updateCountyById = DISTRICT_SERVER_HOST + "/district/counties/{id}";
export const deleteByCountyId = DISTRICT_SERVER_HOST + "/district/counties/{id}";

export const getTownsByCountyId = DISTRICT_SERVER_HOST + "/district/counties/{id}/towns";
export const getTownById = DISTRICT_SERVER_HOST + "/district/towns/{id}";
export const createTown = DISTRICT_SERVER_HOST + "/district/towns";
export const updateTownById = DISTRICT_SERVER_HOST + "/district/towns/{id}";
export const deleteByTownId = DISTRICT_SERVER_HOST + "/district/towns/{id}";

export const getVillagesByTownId = DISTRICT_SERVER_HOST + "/district/towns/{id}/villages";
export const getVillageById = DISTRICT_SERVER_HOST + "/district/villages/{id}";
export const createVillage = DISTRICT_SERVER_HOST + "/district/villages";
export const updateVillageById = DISTRICT_SERVER_HOST + "/district/villages/{id}";
export const deleteByVillageId = DISTRICT_SERVER_HOST + "/district/villages/{id}";