import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Table, Divider} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";

import {OrganizationTypesMap} from "../../../Enums";

import {
    getOrganization,
    toggleDetailModal,
    deleteOrganization,
    searchOrganizations,
    refreshSearchCondition
} from '../../../actions/OrganizationAction';
import {Modal} from "antd/lib/index";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'code', dataIndex: 'code', title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_CODE'}),
                    width: 150,
                },
                {key: 'name', dataIndex: 'name', title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_NAME'})},
                {
                    key: 'type', dataIndex: 'type', title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_TYPE'}),
                    width: 150,
                    render: (text, record, index) => formatMessage({id: OrganizationTypesMap[record.type]}),
                },
                {key: 'contacts', dataIndex: 'contacts', title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_CONTACTS'})},
                {
                    key: 'telephone',
                    dataIndex: 'telephone',
                    title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_TELEPHONE'}),
                    width: 150,
                },
                {
                    key: 'parent.name',
                    dataIndex: 'parent.name',
                    title: formatMessage({id: 'ORG_ORGANIZATION_FIELD_PARENT'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index),
                    width: 150,
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
            </div>
        )
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getOrganization(record, this.state));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        const {dispatch, searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "ORG_ORGANIZATION_TITLE_DELETE"}),
            content: formatMessage({id: "ORG_ORGANIZATION_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteOrganization(record, searchCondition, formatMessage));
            }
        });
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.organizations} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;

        this.props.dispatch(refreshSearchCondition(searchCondition));
        this.props.dispatch(searchOrganizations(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        organizations: store.OrgOrganizationReducer.organizations,
        organization: store.OrgOrganizationReducer.organization,
        searchCondition: store.OrgOrganizationReducer.searchCondition,
        pagination: store.OrgOrganizationReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));