import {
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_DATE_SIMPLE,
    FORMAT_TIME_SIMPLE,
    FORMAT_DATE_SLASH, FORMAT_TIME_COLON
} from "../../../constants/DateTimeFormats";

class EcgFileDateTime {
    constructor(dataArray, startPos) {
        let index = startPos;
        // console.log("EcgFileDateTime:", dataArray, startPos)
        const yearArray = dataArray.slice(index, index + 2);
        this.year = new Uint16Array(yearArray.buffer.slice(-2))[0];
        index = index + 2;

        this.month = dataArray[index];
        if (this.month < 10) {
            this.month = "0" + this.month.toString();
        }
        index++;

        this.day = dataArray[index];
        if (this.day < 10) {
            this.day = "0" + this.day.toString();
        }
        index++;

        this.hour = dataArray[index];
        if (this.hour < 10) {
            this.hour = "0" + this.hour.toString();
        }
        index++;

        this.minute = dataArray[index];
        if (this.minute < 10) {
            this.minute = "0" + this.minute.toString();
        }
        index++;

        this.second = dataArray[index];
        if (this.second < 10) {
            this.second = "0" + this.second.toString();
        }
        index++;

        this.millisecond = dataArray[index];
        index++;

        this.length = index - startPos;
    }

    getDateTime(format) {
        switch (format) {
            case FORMAT_DATE_TIME_SIMPLE:
                return this.year.toString() + this.month.toString() + this.day.toString() + this.hour.toString() + this.minute.toString() + this.second.toString();
            case FORMAT_DATE_SIMPLE:
                return this.year.toString() + this.month.toString() + this.day.toString();
            case FORMAT_DATE_SLASH:
                return this.year.toString() + "/" + this.month.toString() + "/" + this.day.toString();
            case FORMAT_TIME_SIMPLE:
                return this.hour.toString() + this.minute.toString() + this.second.toString();
            case FORMAT_TIME_COLON:
                return this.hour.toString() + ":" + this.minute.toString() + ":" + this.second.toString();
        }

    }
}

export default EcgFileDateTime;