/**
 * Created by Ethan on 2017/9/6.
 */

const ja_JP = {
    brand: "心電服",
    copyright:"Copyright ©2017 D9K",

    // 角色
    role: "角色",
    administrator: "超级管理员",
    sysmanager: "系统管理员",
    manager: "管理员",
    normal: "普通",

    titleSysUserAdd: "系统用户添加",
    titleSysUserUpdate: "系统用户更新",

    // 路由的标题，菜单，路径导航上使用
    DAS_DASHBOARD_ROUTE_STATISTICS:"Home",
    routeDashboard:"Dashboard",
    DAS_DEVICE_ROUTE_MANAGEMENT:"Device Manage",
    DAS_DEVICE_ROUTE_LIST:"Device List",
    routeDeviceDetail:"Device Detail",
    routeDeviceRegister:"Device Register",
    routeDeviceImport:"Device Import",
    routeClothesList: "衣服一览",

    routeEcg:"心电图",
    routeEcgList:"心电图一览",
    routeEcgChart:"心电图展示",

    routeUser:"User",
    routeUserList:"User List",
    routeDoctorList:"Doctor List",

    // 设备注册画面
    deviceCode:"装置番号",
    deviceName:"装置名称",
    deviceBinder: "使用者",
    productionDate: "製造日",
    cardNo: "カード番号",

    // 设备上传画面
    deviceImport: "设备批量导入",
    deviceImportTemplate: "设备导入模版下载",

    // 心电图一览
    ecgChart:"心电图",

    menuSysManage: "SYSTEM MANAGE",
    menuSysUserList: "User Role List",

    btnAdd: "添加",
    btnSubmit: "保存",
    btnCancel: "キャセル",
    btnModify: "修改",
    btnDelete: "删除",
    btnOK: "确定",
    btnSearch: "查询",

    lblLoginId:"用户名",
    lblUsername: "姓名",
    lblFamilyName: "姓",
    lblGivenName: "名",
    lblEmail: "邮箱",
    lblMobile: "电话",
    lblRole: "角色",
    lblOperation: "操作",

    login: "ログイン",
    loginTime: "ログイン時間",
    forgetPassword: "パースワードを忘れ？",
    email: "メール",
    username: "登録名",
    birthday: "誕生日",
    mobile: "携帯電話",
    password: "パースワード",
    welcome: "ようこそ",
    profile: "アカンート",
    resetPassword: "パースワード",
    logout: "ログアウト",
    import:"導入",
    export: "出力",
    downloadTemplate: "テンプレートをダウンロード",

    search:"検索",
    advanceSearch:"高級検索",
    register:"新規",
    registerDevice: "新規装置",
    registerDate: "登録日",
    importDevice: "導入装置",

    activateDate: "アクティブ日",

    deviceDataSum: "ECGファイル数",
    deviceMacAddress: "Mac アドレス",
    deviceSyncTime: "同期時間",
    activateState: "アクテブ状態",
    bindState: "縛る状態",
    unactivated:"未アクテブ",
    activated:"アクテブ",
    unbound:"未縛る",
    bound:"縛る",
    userType:"ユーザータイプ",
    user:"使用者",
    guardian:"監護者",

    msgDeleteConfirmTitle: "删除确认",
    msgDeleteConfirmContent: "删除以后无法恢复，确定吗？",

    msgPasswordRequired: "密码不能为空。",
    msgAuthFailed: "登录信息错误，认证失败。",
};
export default ja_JP;