/**
 * Created by Ethan on 17/1/13.
 */

import {FTP_SERVER_HOST} from '../../constants/Profile';

// 发送邮箱验证码
export const uploadWebFile = FTP_SERVER_HOST + "/ftp/web/file";
export const uploadOSSFile = FTP_SERVER_HOST + "/ftp/oss/file";
export const downloadFile = FTP_SERVER_HOST + "/ftp/file";
