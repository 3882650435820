/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Input, Form, Table} from "antd";
import {bindGuarder, searchBinders, toggleBindHistoryModal} from "../../../actions/BinderAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {BindOperationType} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";
import {FORMAT_DATE_TIME_FULL_SIMPLE, FORMAT_DATE_TIME_SLASH} from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import PaginationBar from "../../../../../components/PaginationBar";
import {searchGuarderBindHistoryList, toggleGuarderBindHistoryModal} from "../../../actions/GuarderBindHistoryAction";

class GuarderBindHistoryModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'binderName',
                    dataIndex: 'binderName',
                    title: formatMessage({id: 'ECG_GUARDER_BIND_HISTORY_FIELD_BINDER_NAME'})
                },
                {
                    key: 'guarderName',
                    dataIndex: 'guarderName',
                    title: formatMessage({id: 'ECG_GUARDER_BIND_HISTORY_FIELD_GUARDER_NAME'})
                },
                {
                    key: 'operationType',
                    dataIndex: 'operationType',
                    title: formatMessage({id: 'ECG_GUARDER_BIND_HISTORY_FIELD_OPERATION_TYPE'}),
                    render:  (text, record, index) => <EnumItemLabel map={BindOperationType.Map} code={text}/>
                },
                {
                    key: 'createTime',
                    dataIndex: 'createTime',
                    title: formatMessage({id: 'ECG_GUARDER_BIND_HISTORY_FIELD_CREATE_TIME'}),
                    render: (text, record, index) => moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_SLASH)
                }
            ]
        }
    }

    componentDidMount() {
    }

    _handleOk() {
        this.props.dispatch(toggleGuarderBindHistoryModal(false));
    }

    _handleCancel() {
        this.props.dispatch(toggleGuarderBindHistoryModal(false));
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                title={formatMessage({id: "ECG_BINDER_TITLE_GUARDER_BIND"})}
                maskClosable={false}
                visible={this.props.showBindHistoryModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.guarderBindHistoryList} pagination={false}/>
            </Modal>
        );
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        console.log("pagination", this.props.pagination, "searchCondition:", searchCondition);
        searchCondition.currentPage = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchGuarderBindHistoryList(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        showBindHistoryModal: store.EcgGuarderBindHistoryReducer.showBindHistoryModal,
        guarderBindHistoryList: store.EcgGuarderBindHistoryReducer.guarderBindHistoryList,
        searchCondition: store.EcgGuarderBindHistoryReducer.searchCondition,
        pagination: store.EcgGuarderBindHistoryReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(GuarderBindHistoryModal));