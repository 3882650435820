import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

import {Card, Form, Icon, Input, Button, Divider, Upload} from 'antd';
import {FormattedMessage, injectIntl} from 'react-intl';
import {RoutePath} from '../../../../../constants/RoutePath'
import {REGISTRABLE_FLAG, ACCOUNT_WEB_HOST} from "../../../../../constants/Profile";
import {refreshUser, login} from '../../../actions/UserAction';
import HttpUtils from "../../../../../utils/HttpUtils";
import HuffmanDecode from "../../../../DAS/utils/HuffmanDecode";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    componentWillMount() {
    }

    _validate() {
        let messageMap = {};
        if (this.props.user.uid === undefined || this.props.user.uid === "") {
            messageMap["uid"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        if (this.props.user.password === undefined || this.props.user.password === "") {
            messageMap["password"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleLogin() {
        if (this._validate()) {
            this.props.dispatch(login(this.props.user, this.state.formatMessage, () => {
                // 获得重定向参数
                let redirect = HttpUtils.getQueryString("redirect");
                if (redirect !== undefined && redirect !== "") {
                    window.parent.location.href = redirect;
                }
                else {
                    this.props.router.push(RoutePath.DAS_DEVICE_LIST.path);
                }
            }));
        }
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        if (name === "uid") {
            // 后台支持邮箱和手机号登录，在uid无法登录的时候，进行后续判断
            user["email"] = value.target ? value.target.value : value;
            user["mobile"] = value.target ? value.target.value : value;
        }
        user[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshUser(user));
    }

    render() {
        let huffmanDecode;
        const readDictProps = {
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            transformFile(file) {
                return new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        const dataArray = new Int8Array(reader.result);

                        huffmanDecode = new HuffmanDecode();
                        huffmanDecode.initFromDictData(dataArray);
                    };
                });
            },
        };

        const readCompressedProps = {
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            transformFile(file) {
                return new Promise(resolve => {
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(file);
                    reader.onload = () => {
                        const dataArray = new Int8Array(reader.result);
                        huffmanDecode.decodeDiff(dataArray, 0, dataArray.length * 8);
                    };
                });
            },
        };

        const {formatMessage, messageMap} = this.state;
        return (
            <Card style={{width: 400, margin: "50px auto"}} bordered={false}
                  title={formatMessage({id: "ACCOUNT_USER_ROUTE_LOGIN_PASSWORD"})}>
                <Form>
                    <Form.Item required
                               validateStatus={messageMap["uid"] ? "error" : ""}
                               help={messageMap["uid"]}>
                        <Input size="large" prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                               placeholder={formatMessage({id: "ACCOUNT_USER_FIELD_UID_PLACEHOLDER"})}
                               onChange={(value) => this._handleChange("uid", value)}/>
                    </Form.Item>
                    <br/>
                    <Form.Item required
                               validateStatus={messageMap["password"] ? "error" : ""}
                               help={messageMap["password"]}>
                        <Input size="large" type="password"
                               prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                               placeholder={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})}
                               onChange={(value) => this._handleChange("password", value)}/>
                    </Form.Item>
                </Form>
                {this._renderActionLink()}
                <div className="spacing-v">
                    <Button type="primary" size="large" block onClick={() => this._handleLogin()}>
                        <FormattedMessage id="ACCOUNT_USER_BUTTON_SUBMIT"/>
                    </Button>
                </div>


                {/*<Upload {...readDictProps}>*/}
                    {/*<Button>*/}
                        {/*<Icon type="upload"/> 读取字典数据*/}
                    {/*</Button>*/}
                {/*</Upload>*/}

                {/*<Upload {...readCompressedProps}>*/}
                    {/*<Button>*/}
                        {/*<Icon type="upload"/> 读取压缩文件*/}
                    {/*</Button>*/}
                {/*</Upload>*/}
            </Card>
        );
    }

    _renderActionLink() {
        if (REGISTRABLE_FLAG) {
            return (
                <div className="spacing-v text-right">
                    <a href={ACCOUNT_WEB_HOST + RoutePath.ACCOUNT_USER_REGISTER.path} target="_blank"><FormattedMessage
                        id="ACCOUNT_USER_ROUTE_REGISTER"/></a>
                    <Divider type="vertical"/>
                    <a href={ACCOUNT_WEB_HOST + RoutePath.ACCOUNT_USER_PASSWORD_FORGOT.path}
                       target="_blank"><FormattedMessage
                        id="ACCOUNT_USER_ROUTE_PASSWORD_FORGOT"/></a>
                </div>
            )
        } else {
            return (
                <div className="spacing-v text-right">
                    <a href={ACCOUNT_WEB_HOST + RoutePath.ACCOUNT_USER_PASSWORD_FORGOT.path}
                       target="_blank"><FormattedMessage
                        id="ACCOUNT_USER_ROUTE_PASSWORD_FORGOT"/></a>
                </div>
            )
        }
    }
}

function mapStateToProps(store) {
    return {
        user: store.AccountUserReducer.user
    }
}

export default connect(mapStateToProps)(injectIntl(Index));
