/**
 * Created by Ethan on 2017/11/15.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Row, Col} from "antd";
import moment from 'moment';

import PageTitle from "../../components/PageTitle";

import * as Messages from '../../constants/Messages'
import {RoutePath, RoutePathKey} from '../../constants/RoutePath';

import MonitorView from "./MonitorView";
import Events from "./Events";
import Map from "./Map";

class DashboardView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isSubmit: false
        }
    }

    render() {
        return (
            <div>
                <Map/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}
export default connect(mapStateToProps)(DashboardView);