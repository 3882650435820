/**
 * Created by Ethan on 16/12/26.
 * 定义Action的类型
 */

export const DISTRICT_COUNTRY_LOADING = 'DISTRICT_COUNTRY_LOADING';
export const DISTRICT_COUNTRY_TREE = 'DISTRICT_COUNTRY_TREE';
export const DISTRICT_COUNTRY_SEARCH = 'DISTRICT_COUNTRY_SEARCH';
export const DISTRICT_COUNTRY_REFRESH_COUNTRY = 'DISTRICT_COUNTRY_REFRESH_COUNTRY';
export const DISTRICT_COUNTRY_REFRESH_COUNTRY_TREE_EXPAND_KEYS = 'DISTRICT_COUNTRY_REFRESH_COUNTRY_TREE_EXPAND_KEYS';
export const DISTRICT_COUNTRY_TOGGLE_DETAIL_MODAL = 'DISTRICT_COUNTRY_TOGGLE_DETAIL_MODAL';

export const DISTRICT_PROVINCIAL_LOADING = 'DISTRICT_PROVINCIAL_LOADING';
export const DISTRICT_PROVINCIAL_SEARCH = 'DISTRICT_PROVINCIAL_SEARCH';
export const DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL = 'DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL';
export const DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL_MAP = 'DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL_MAP';
export const DISTRICT_PROVINCIAL_TOGGLE_DETAIL_MODAL = 'DISTRICT_PROVINCIAL_TOGGLE_DETAIL_MODAL';

export const DISTRICT_CITY_LOADING = 'DISTRICT_CITY_LOADING';
export const DISTRICT_CITY_SEARCH = 'DISTRICT_CITY_SEARCH';
export const DISTRICT_CITY_REFRESH_CITY = 'DISTRICT_CITY_REFRESH_CITY';
export const DISTRICT_CITY_REFRESH_CITY_MAP = 'DISTRICT_CITY_REFRESH_CITY_MAP';
export const DISTRICT_CITY_TOGGLE_DETAIL_MODAL = 'DISTRICT_CITY_TOGGLE_DETAIL_MODAL';

export const DISTRICT_COUNTY_LOADING = 'DISTRICT_COUNTY_LOADING';
export const DISTRICT_COUNTY_SEARCH = 'DISTRICT_COUNTY_SEARCH';
export const DISTRICT_COUNTY_REFRESH_COUNTY = 'DISTRICT_COUNTY_REFRESH_COUNTY';
export const DISTRICT_COUNTY_REFRESH_COUNTY_MAP = 'DISTRICT_COUNTY_REFRESH_COUNTY_MAP';
export const DISTRICT_COUNTY_TOGGLE_DETAIL_MODAL = 'DISTRICT_COUNTY_TOGGLE_DETAIL_MODAL';

export const DISTRICT_TOWN_LOADING = 'DISTRICT_TOWN_LOADING';
export const DISTRICT_TOWN_SEARCH = 'DISTRICT_TOWN_SEARCH';
export const DISTRICT_TOWN_REFRESH_TOWN = 'DISTRICT_TOWN_REFRESH_TOWN';
export const DISTRICT_TOWN_REFRESH_TOWN_MAP = 'DISTRICT_TOWN_REFRESH_TOWN_MAP';
export const DISTRICT_TOWN_TOGGLE_DETAIL_MODAL = 'DISTRICT_TOWN_TOGGLE_DETAIL_MODAL';

export const DISTRICT_VILLAGE_LOADING = 'DISTRICT_VILLAGE_LOADING';
export const DISTRICT_VILLAGE_SEARCH = 'DISTRICT_VILLAGE_SEARCH';
export const DISTRICT_VILLAGE_REFRESH_VILLAGE = 'DISTRICT_VILLAGE_REFRESH_VILLAGE';
export const DISTRICT_VILLAGE_REFRESH_VILLAGE_MAP = 'DISTRICT_VILLAGE_REFRESH_VILLAGE_MAP';
export const DISTRICT_VILLAGE_TOGGLE_DETAIL_MODAL = 'DISTRICT_VILLAGE_TOGGLE_DETAIL_MODAL';