/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {
    Button,
    Popover,
    DatePicker,
    Icon,
    Switch,
    Alert,
    Spin,
    Radio,
    Tag,
    Row,
    Col,
    Input,
    TimePicker,
    Divider
} from 'antd';
import message from "../../../../../../components/toast";
import {ChartSpeed, EcgFileType, ProtocolPackageType, VoltDiv, leadName} from "../../../../Enums";
// Ant Design 汉化注入
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import "./Index.css";
import moment from 'moment';
import {
    FORMAT_DATE_HYPHEN, FORMAT_DATE_SIMPLE, FORMAT_TIME_COLON
} from "../../../../../../constants/DateTimeFormats";
import DoubleCalendar from "./DateIndexCalendar";
import DateIndexOverview from "./DateIndexOverview";
import EventIndexTimeLine from "./EventIndexTimeLine";
import TimeIndexSlider from "./TimeIndexSlider";
import {
    getDateIndexFile,
    refreshCalendarEcgDateIndexRecord,
    refreshEcgDataIndexEffectiveRecords,
    refreshEcgDateIndexData, refreshEcgDateIndexFlag, refreshEcgDateIndexRecordMap,
    refreshEcgEventIndexData,
    refreshEcgTimeIndexData, refreshTopQ, refreshVersionChange
} from "../../../../actions/HistoryAction";
import {ECG_SOCKET_SERVER_HOST} from "../../../../../../constants/Profile";
import EcgFileCommonHeader from "../../../../entities/EcgFileCommonHeader";
import ProtocolCommonHeader from "../../../../entities/ProtocolCommonHeader";
import ProtocalHistoryData from "../../../../entities/ProtocalHistoryData";
import EcgFileTimeIndex from "../../../../entities/EcgFileTimeIndex";
import EcgFileEventIndex from "../../../../entities/EcgFileEventIndex";
import EcgFileMixData from "../../../../entities/EcgFileMixData";
import EcgFileDateIndex from "../../../../entities/EcgFileDateIndex";
import WebSocketUtils from "../../../../utils/WebSocketUtils";
import {
    getDeviceById,
    getPastDynastiesVersion, getUserMemoList,
    refreshChannelType,
    refreshVersionIndex, searchEcgRecordSum
} from "../../../../actions/DeviceAction";
import DeviceDetailDrawer from "../../RealTime/DeviceDetailDrawer";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";
import MedfiltDeWander from "../../../../utils/MedfiltDeWander";
import Toast from "../../../../../../components/toast";
import HuffmanDecode from "../../../../utils/HuffmanDecode";

let pointIndex = 0;
let ecgTextY = [];
let drawFlag = false;

let xuanzeValue

// 一个导联的svg图高度
let winH;
// 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
let winW;

// 采样率
// 标准值：500samples/s
// 可选值：250samples/s，1000samples/s
const sampRate = 500;

// 下采样率，默认值1，从设备中获取后改变
let downRatio = 1;

// 通过采样率和下采样率的关系，算出每秒实际多少个点
let dataRate = sampRate / downRatio;

// 画布高度
// const height = 1200;
// 采集频率，每秒500次
const frequency = 500;
// 显示的时间（秒）
let duration = 10;

// 格子尺度【GridScale】
// 	5个小格构成一个大格
// 标准值：1mm/小格
// 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
// 	例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
// 此处默认以大格子作为显示单位
const gridScale = 5;

// 格子的缩放
const gridRatio = 1;
let eventRecords = [];

// 走纸速度, 标准值：25mm/s
// 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
const chartSpeed = 25;

// 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
const gridPointNum = dataRate / (chartSpeed / gridScale);
// console.log("gridPointNum:" + gridPointNum);

// 播放的走点数, 每次更新的数据点的数量
// 对应的文档上的参数名是RfsNum
let playStep = 5;

// 转换成一次刷新走多少个点，按500的采样率，则100个点代表0.2秒=是5mm，就是一格的描画点数
// 10ms刷新一次，则刷新100次是1s，那么刷100次则需要行走5个格子，则刷新一次走0.05个格子，也就是说是5个点
let playSpeed = 1000 / (dataRate / playStep);

const gc = 20132.66;
// // CH1（呼吸通路）
// // Gain=2^24*4*1/5000
// const gain1 = 13421.7728;
//
// // CH2~CH8
// // Gain=2^24*6*1/5000=20132.6592 1/mV
// const gain = 20132.6592;

// 计算出横向的格子数
// 一个格子是0.2S(0.04S的小格子不显示)
// 如果是10S是50个格子
const unit = 0.2;

// 算出横向格子数
let xCellCount;
// 每个导联占据纵向格子数
const yCellCount = 6;

// 算出每个格子的宽度
let cellWidth;
// 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
// 计算得出点之间的x间距
let pointSpace;

// 算出0点，以下是负值，以上是正直，需要变换数据
let zeroPointY;

// 绘图的缓冲区
let clearDrawBufferFlag = false;
// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
let drawBufferMap = {};
let drawCountValue = {};

// 8个导联的数据
let numLeads = 12;

// 窗口刷新率，每40ms刷新一次走纸
const winFps = 40;
//每次更新的数据点的数量
// const baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
let rfsNum = (sampRate / downRatio) / (1000 / winFps);

// 定标一个div为100mm宽度，算出相对应的pixel
const unitWidthMill = 1;

// 绘图窗口的Y轴Margin
const winMarginX = 70;
const winMarginY = 20;

// SVG 线的背景颜色
const lineColors = ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E'];

// 一屏幕能绘画的点数量
let screenPointSum;

// 历史心电文件的Web Socket连接对象
let webSocket;
// 时间索引文件对象
let ecgFileTimeIndex;
// 事件索引文件对象
let ecgFileEventIndex;

let timeFlag = false;
let eventFlag = false;

// 记录Web Socket的命令列表，防止重复发送指令
let webSocketCommandHistory = [];

let manualStopSearch = false;
let lastFetchHistoryFileIndex = -1;
let historyFileIndex;
let initIndex;
let startTime;
let deviceTime;
let initHistoryIndex;
let historyFileIndexV;
let historyFileFlag = false;

let onClickFlag = false;
let serverFlag = false;
let playFlag = true;
let zidongFlag = false;

// 中值滤波的处理对象
window.medfiltDeWander = null;

let ecgDateIndexRecord;
let topQ = [];
let historyFlag = false;

let channelType;
let version

let timeValue;
let fileIndexValue

// 无损压缩对象
let huffmanDecode;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            timeFileIndex: 0,
            eventFileIndex: 0,
            sliderValue: 0,
            showControl: true,
            recordDate: "",
            sliderMarks: {},
            historyFileIndexV: "",
            backgroundDisplay: true,
            // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // 一个格子是5mm，所以一个格子代表0.5mv
            voltDiv: 10,
            // 走纸倍数
            chartSpeedTimes: 1,
            combinePaint: false,
            playFlag: false,
            playFlag2: true,
            dateIndexOverviewVisible: false,
            eventIndexTimeLineVisible: false,
            timeNode: "",
            doubleCalendarVisible: false,
            rangeStartDate: "",
            rangeEndDate: "",
            toggleDeviceDetail: false,
            medfiltDeWanderEnable: true
        }
    }

    componentWillMount() {

        // 读取本地的无损压缩字典文件
        huffmanDecode = new HuffmanDecode();
        huffmanDecode.initByLoadDictFile();

        channelType = this.props.channelType;
        version = this.props.version;
        let self = this;
        this.timeTicket = setInterval(() => {
            if (ecgDateIndexRecord != this.props.ecgDateIndexRecord && drawFlag && this.props.ecgDateIndexRecord) {
                historyFlag = false;
                // for (let i = 0; i < 8; i++) {
                //     if (drawBufferMap[i])
                //         drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
                // }
                drawBufferMap = {}
                drawCountValue = {};
                webSocketCommandHistory = [];
                this._calcParams();
                this._drawECG(this.state.combinePaint);
                this.setState({historyFileIndexV: ""})
                this._setQueryState();
                clearDrawBufferFlag = true;
                if (webSocket) {
                    onClickFlag = true;
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType, this.props.version);
                }

            }
        }, 500);

        // this.history1 = setInterval(() => {
        //     if (!this.props.historyFlag) {
        //         historyFlag = false;
        //         // for (let i = 0; i < 8; i++) {
        //         //     if (drawBufferMap[i])
        //         //         drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
        //         // }
        //         drawBufferMap = {}
        //         drawCountValue = {};
        //         webSocketCommandHistory = [];
        //         if (webSocket) {
        //             historyFlag = true;
        //             onClickFlag = true;
        //             // WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, this.props.historyIndex, webSocketCommandHistory, this.props.channelType);
        //             WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType, this.props.version);
        //             // self.props.setHisoryFlag(true);
        //             this.props.setHistoryFlag(true);
        //             this.setState({playFlag2: false})
        //             // WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType);
        //         }
        //     }
        // }, 500)
        // this._setQueryState();

        //获取设备的所有世代信息
        const deviceCode = this.props.deviceCode;
        this.props.dispatch(getPastDynastiesVersion(deviceCode, this.state.formatMessage));
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (channelType != nextProps.channelType) {
            channelType = nextProps.channelType
            console.log("componentWillReceiveProps2", nextProps)
            this._handleChannelTypeChange(channelType);
        }

        if (version != nextProps.version) {
            version = nextProps.version
            console.log("componentWillReceiveProps22")
            this._handleVersionChange(version);
        }

        if (!nextProps.historyFlag) {
            historyFlag = false;
            // for (let i = 0; i < 8; i++) {
            //     if (drawBufferMap[i])
            //         drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
            // }
            drawBufferMap = {}
            drawCountValue = {};
            webSocketCommandHistory = [];
            if (webSocket) {
                historyFlag = true;
                onClickFlag = true;
                // WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, this.props.historyIndex, webSocketCommandHistory, this.props.channelType);
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType, this.props.version);
                // self.props.setHisoryFlag(true);
                this.props.setHistoryFlag(true);
                this.setState({playFlag2: false})
                // WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType);
            }
        }
    }

    componentWillUnmount() {
        window.medfiltDeWander = null;
        historyFileIndex = null;
        ecgFileTimeIndex = null;
        ecgFileEventIndex = null;
        timeFlag = false;
        eventFlag = false;
        this.setState({historyFileIndexV: ""})
        this._handleStopSearch();
        this.props.dispatch(refreshCalendarEcgDateIndexRecord(ecgDateIndexRecord));
        clearInterval(this.intervalGetDeviceById);
        clearInterval(this.timeTicket);
        // clearInterval(this.history1);
        clearInterval(this.discardData)
    }

    _setQueryState() {
        historyFileIndex = null;
        ecgFileTimeIndex = null;
        ecgFileEventIndex = null;
        timeFlag = false;
        eventFlag = false;
        const recordDate = this.props.ecgDateIndexRecord.recordDate;
        const deviceCode = this.props.ecgDateIndexRecord.deviceCode;
        const timeFileIndex = this.props.ecgDateIndexRecord.timeFileIndex;
        const eventFileIndex = this.props.ecgDateIndexRecord.eventFileIndex;
        // console.log("this.props.ecgDateIndexRecord:", this.props.ecgDateIndexRecord)
        this.setState({
            recordDate: recordDate,
            deviceCode: deviceCode,
            timeFileIndex: timeFileIndex,
            eventFileIndex: eventFileIndex,
            rangeStartDate: recordDate,
            doubleCalendarVisible: false,
            rangeEndDate: recordDate
        })
        ecgDateIndexRecord = this.props.ecgDateIndexRecord


    }

    /** 初始化动作 */
    componentDidMount() {
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
        this.props.historyModelRef(this)
        this._calcParams();

        this._drawECG(this.state.combinePaint);

        // 时间索引文件编号，0表示没采集数据。
        // 每天生成一个时间索引文件，其文件大小固定
        this._handleSearch(true);
    }

    _handleSearch = (initializeFlag) => {
        webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {

            // 打开一个连接
            message.success("链接服务器成功：" + e.target.url);
            // WebSocketUtils._sendHello(webSocket, this.props.deviceCode);
            const claspHandCommand = "64 03 04 FF FF FF FF 1C 00 00 00 81 00 00 00 00 00 0D 00 00 " + this.props.deviceCode + " 00 0D 00 00";
            webSocket.send(claspHandCommand); // 发送一个消息
        };
        webSocket.onmessage = (e) => {

            const dataArray = new Int8Array(e.data);

            let startPos = 0;

            console.log("dataArray:", dataArray);

            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                // message.success("连接成功，开始发送获取日期索引文件文件的指令");
                // message.warn("重新获取数据" + this.props.channelType)
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1, null, this.props.channelType, this.props.version);
                // WebSocketUtils._sendGetDeviceDatas(webSocket, this.props.deviceCode);
                return;
            }
            console.log("protocolCommonHeader:", protocolCommonHeader, protocolCommonHeader.packetType)
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.CommonReply) {
                // 0x0000 通用成功
                // 0xAAEF 超时
                if (dataArray[14] !== 0 && dataArray[15] !== 0) {
                    message.error("服务器中没有数据");
                    // this.props.dispatch(getDeviceHistoryData(this.props.deviceCode, this.state.formatMessage));
                    serverFlag = true;
                    this._handleDevice(this.props.channelType);
                    return;
                }
            }

            // 服务器向客户端发送对应的文件。【上行】
            // PacketType	1B	0x33：文件请求响应	包类型
            // 其他类型的包，不需要处理
            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                return;
            }

            // 解析历史文件里的data数据
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const historyData = new ProtocalHistoryData(dataArray, startPos);
            console.log("historyData:", historyData)

            startPos = startPos + historyData.length;
            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);

            startPos = startPos + ecgFileCommonHeader.length;

            switch (historyData.fileType) {
                case EcgFileType.EnumInt.DATEIDX:
                    // message.success("日期索引文件获得成功，开始解析数据包，FileIndex:"
                    //     + historyData.fileIndex + historyFlag + this.state.timeFileIndex + onClickFlag);
                    const ecgFileDateIndex = new EcgFileDateIndex(dataArray, startPos);
                    const ecgDateIndexRecordMap = {};
                    const ecgDataIndexEffectiveRecords = [];
                    for (let item of ecgFileDateIndex.records) {
                        ecgDateIndexRecordMap[item.recordDate] = item;
                        if (item.timeNum > 0) {
                            // 有心电数据的记录才需要加入显示队列
                            ecgDataIndexEffectiveRecords.push(item);
                        }
                    }
                    this.props.dispatch(refreshEcgDataIndexEffectiveRecords(ecgDataIndexEffectiveRecords));
                    this.props.dispatch(refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap));
                    this.props.dispatch(refreshEcgDateIndexData(ecgFileDateIndex));

                    if (historyFlag) {
                        for (let i = 0; i < ecgDataIndexEffectiveRecords.length; i++) {
                            if (ecgDataIndexEffectiveRecords[i].recordDate == this.props.recordValue.EventDateV) {
                                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.TIMEIDX
                                    , ecgDataIndexEffectiveRecords[i].timeFileIndex, null, this.props.channelType, this.props.version);
                                onClickFlag = false
                                return;
                            }
                        }

                    }
                    if (onClickFlag) {
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.TIMEIDX, this.state.timeFileIndex, null, this.props.channelType, this.props.version);
                        onClickFlag = false
                    }
                    break;
                case EcgFileType.EnumInt.TIMEIDX:
                    // message.success("时间索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    ecgFileTimeIndex = new EcgFileTimeIndex(dataArray, startPos);
                    timeFlag = true;

                    const slideMarks = {};
                    slideMarks[0] = "0";
                    slideMarks[Number(ecgFileTimeIndex.timeSliceNum)] = "24";
                    for (let i = 1, x = 0, z = 0; i < Number(ecgFileTimeIndex.timeSliceNum); i++) {
                        if (i - (Math.round((x + 1) * Number(ecgFileTimeIndex.timeSliceNum) / (24 * 4))) == 0) {
                            x++;
                            if (x % 4 == 0 && x != 0) {
                                slideMarks[i] = ++z;
                            } else {
                                slideMarks[i] = " "
                            }
                        }
                    }
                    console.log("slideMarks:", slideMarks,
                        Math.round(Number(ecgFileTimeIndex.timeSliceNum) / (24 * 4)));
                    this.props.dispatch(refreshEcgTimeIndexData(ecgFileTimeIndex, slideMarks));

                    // message.success("时间索引文件解析完成，开始发送获取事件索引文件的指令");
                    if (!historyFlag) {
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.EVNTIDX, this.state.eventFileIndex, null, this.props.channelType, this.props.version);

                    } else {
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, this.props.historyIndex, webSocketCommandHistory, this.props.channelType, this.props.version);
                    }

                    break;
                case EcgFileType.EnumInt.EVNTIDX:
                    // message.success("事件索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    ecgFileEventIndex = new EcgFileEventIndex(dataArray, startPos);
                    eventFlag = true;
                    this.props.dispatch(refreshEcgEventIndexData(ecgFileEventIndex));
                    // 由于HistoryFileIndex从1开始编号，如HistoryFileIndex=0，表示当天没有历史数据文件产生。
                    // 所以获取一个有效的起始索引
                    let index = 0;
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        const record = ecgFileTimeIndex.records[i];
                        if (record.historyFileIndex > 0) {
                            index = i;
                            historyFileIndex = record.historyFileIndex;
                            initIndex = i;
                            eventRecords[record.historyFileIndex] = record;
                            initHistoryIndex = record.historyFileIndex;
                            this.setState({sliderValue: i});
                            // 第一个历史文件先获取了
                            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory, this.props.channelType, this.props.version);
                            break;
                        }
                    }
                    break;
                case EcgFileType.EnumInt.MIXDATA:
                    // 心电图的点数据加入缓冲区
                    if (clearDrawBufferFlag) {
                        // drawBufferMap = {};
                        for (let i = 0; i < numLeads; i++) {
                            if (drawBufferMap[i])
                                drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
                            console.log("drawBufferMap:", drawBufferMap, screenPointSum)
                        }
                        drawCountValue = {};
                        webSocketCommandHistory = [];
                    }
                    let countValue = [];
                    historyFileIndex = historyData.fileIndex;
                    if (historyFlag && this.props.historyIndex == historyData.fileIndex) {
                        historyFileFlag = true;
                        // message.success("历史心电文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                        // const record = ecgFileTimeIndex.records[i];
                        window.medfiltDeWander && window.medfiltDeWander.times++;
                        const ecgFileMixData = new EcgFileMixData(dataArray, startPos, 0, historyData.fileIndex, 0, null, null, null, this.state.medfiltDeWanderEnable, huffmanDecode);
                        const ecgPointPolymRegion = ecgFileMixData.polymRegions[0];

                        for (let i = 0; i < ecgFileMixData.labelRecords.length; i++) {
                            this.props.topQ.push(ecgFileMixData.labelRecords[i].topPoint);
                        }

                        // for (let i = 0; i < ecgPointPolymRegion.channelNum; i++) {
                        for (let i = 0; i < numLeads; i++) {
                            if (serverFlag) {
                                for (let x = 0; x < 1000; x++) {
                                    countValue[x] = 0;
                                }
                                if (drawCountValue[i]) {
                                    drawCountValue[i] = drawCountValue[i].concat(countValue);
                                } else {
                                    drawCountValue[i] = countValue;
                                }
                            }
                            for (let x = 0, z = 0; z < ecgPointPolymRegion.ecgData[i].length; x++, z += 500) {
                                ecgPointPolymRegion.ecgData[i][z].time = ecgFileMixData.ecgFileDateTime.hour
                                    + ":" + ecgFileMixData.ecgFileDateTime.minute
                                    + ":" + (Number(ecgFileMixData.ecgFileDateTime.second) + x);
                            }

                            if (drawBufferMap[i]) {
                                drawBufferMap[i] = drawBufferMap[i].concat(ecgPointPolymRegion.ecgData[i]);
                            } else {
                                startTime = ecgPointPolymRegion.ecgData[i][0].time
                                drawBufferMap[i] = ecgPointPolymRegion.ecgData[i];
                            }
                            if (clearDrawBufferFlag) {
                                startTime = ecgPointPolymRegion.ecgData[i][0].time
                            }
                            deviceTime = ecgFileMixData.startTime;
                        }
                        if (zidongFlag) {
                            this._handlePlayRight()
                            zidongFlag = false
                        }
                        clearDrawBufferFlag = false;
                        // if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500] && drawBufferMap[0][0] && historyFlag) {
                        //     // 缓存的数据不够，需要重新获取下一个历史文件数据
                        //     historyFileIndex = drawBufferMap[0][0].historyFileIndex + 1
                        //     WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, drawBufferMap[0][0].historyFileIndex + 1, webSocketCommandHistory, this.props.channelType);
                        // }
                        if (initializeFlag) {
                            // message.success("历史心电文件解析完成，开始描绘心电图");

                            if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500]) {
                                this.setState({playFlag2: true})
                                this._handlePlayRight()
                                this._refreshECGView();
                            }

                        }
                        // this.props.setHistoryFlag(true);
                        // this.setState({playFlag2: true})
                        return;
                    }
                    if (historyFlag) {
                        return;
                    }
                    // 获得当前的TimeIndexRecord, 播放的时候，需要根据这个index控制进度条
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        if (ecgFileTimeIndex.records[i].historyFileIndex === historyData.fileIndex) {
                            historyFileFlag = true;
                            // message.success("历史心电文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                            const record = ecgFileTimeIndex.records[i];
                            window.medfiltDeWander && window.medfiltDeWander.times++;
                            const ecgFileMixData = new EcgFileMixData(dataArray, startPos, i, historyData.fileIndex, record.eventRecordIndex, null, null, null, this.state.medfiltDeWanderEnable, huffmanDecode);
                            const ecgPointPolymRegion = ecgFileMixData.polymRegions[0];
                            // const getUserMemoListParem = {
                            //     "deviceCode": this.props.deviceCode,//zhi
                            //     "memoTimeFrom": Number(ecgFileMixData.startTime * 1000),
                            //     "memoTimeTo": Number(ecgFileMixData.startTime * 1000) + 15872,
                            // }
                            // this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))

                            // 记录topPoints的高点数组，在下面循环心电图点的时候，把包含的点需要设置上高点标示
                            const topPoints = [];
                            for (let j = 0; j < ecgFileMixData.labelRecords.length; j++) {
                                let topPointIndex = ecgFileMixData.labelRecords[j].topPoint;
                                // 中值滤波除基线开的时候，高点位偏移量
                                if (this.state.medfiltDeWanderEnable) {
                                    topPointIndex = topPointIndex - 6 + 150;
                                }
                                topPoints.push(topPointIndex);
                                this.props.topQ.push(ecgFileMixData.labelRecords[j].topPoint);
                            }
                            console.log("topPoints");
                            console.log(topPoints);

                            for (let x = 0; x < numLeads; x++) {

                                for (let j = 0; j < topPoints.length; j++) {
                                    // 如果这个点序号是高点，则设置上高点特征值
                                    // 后续描画的时候，可以根据该特征值绘画特征标记
                                    const topPointIndex = topPoints[j];
                                    if (ecgPointPolymRegion.ecgData[x][topPointIndex]) {
                                        ecgPointPolymRegion.ecgData[x][topPointIndex].topPointFlag = true;
                                    } else {
                                        console.log(topPointIndex);
                                    }
                                }

                                for (let i = 0; i < ecgFileMixData.eventRecords.length; i++) {
                                    const time = (ecgFileMixData.eventRecords[i].time) - (ecgFileMixData.startTime % 1000000 * 1000)
                                    if (ecgPointPolymRegion.ecgData[x][time / 2]) {
                                        ecgPointPolymRegion.ecgData[x][time / 2].eventType = ecgFileMixData.eventRecords[i].diagnosis +
                                            "(" + ecgFileMixData.eventRecords[i].h + ":" + ecgFileMixData.eventRecords[i].m + ":"
                                            + ecgFileMixData.eventRecords[i].s + ":" + ecgFileMixData.eventRecords[i].ss + ")";
                                    }

                                }
                            }

                            for (let i = 0; i < numLeads; i++) {
                                if (serverFlag) {
                                    for (let x = 0; x < 1000; x++) {
                                        countValue[x] = 0;
                                    }
                                    if (drawCountValue[i]) {
                                        drawCountValue[i] = drawCountValue[i].concat(countValue);
                                    } else {
                                        drawCountValue[i] = countValue;
                                    }
                                }

                                for (let x = 0, z = 0; z < ecgPointPolymRegion.ecgData[i].length; x++, z += 500) {
                                    // ecgPointPolymRegion.ecgData[i][z].time = ecgFileMixData.ecgFileDateTime.year + "/"
                                    //     + ecgFileMixData.ecgFileDateTime.month + "/" + ecgFileMixData.ecgFileDateTime.day + " "
                                    //     + ecgFileMixData.ecgFileDateTime.hour + ":" + ecgFileMixData.ecgFileDateTime.minute
                                    //     + ":" + (ecgFileMixData.ecgFileDateTime.second + (z / 500));
                                    var hour = Number(ecgFileMixData.ecgFileDateTime.hour)
                                    var minute = ecgFileMixData.ecgFileDateTime.minute
                                    var second
                                    if ((Number(ecgFileMixData.ecgFileDateTime.second) + x) >= 60) {
                                        second = (Number(ecgFileMixData.ecgFileDateTime.second) + x) - 60 > 9 ?
                                            ((Number(ecgFileMixData.ecgFileDateTime.second) + x) - 60) :
                                            "0" + ((Number(ecgFileMixData.ecgFileDateTime.second) + x) - 60)
                                        minute++;
                                        minute = minute > 9 ? minute : "0" + minute
                                    } else {
                                        second = (Number(ecgFileMixData.ecgFileDateTime.second) + x) >= 10 ?
                                            (Number(ecgFileMixData.ecgFileDateTime.second) + x) :
                                            "0" + (Number(ecgFileMixData.ecgFileDateTime.second) + x)
                                    }
                                    if (minute >= 60) {
                                        minute = "0" + (minute - 60)
                                        hour++
                                    }
                                    hour = hour > 9 ? hour : "0" + hour
                                    ecgPointPolymRegion.ecgData[i][z].time = hour
                                        + ":" + minute
                                        + ":" + second;
                                }

                                deviceTime = ecgFileMixData.startTime;
                                for (let y = 0; y < this.props.userMemoList.length; y++) {
                                    if (deviceTime * 1000 + 15872 > this.props.userMemoList[y].createTime &&
                                        this.props.userMemoList[y].createTime > deviceTime * 1000) {
                                        // console.log("nb:", this.props.userMemoList, this.props.userMemoList[y].createTime - deviceTime * 1000 / 2, ecgPointPolymRegion.ecgData[i]
                                        //     , deviceTime * 1000, this.props.userMemoList[y].createTime)
                                        var str = this.props.userMemoList[y].createTime.substr(0, 4) + "/" + this.props.userMemoList[y].createTime.substr(4, 2)
                                            + "/" + this.props.userMemoList[y].createTime.substr(6, 2)
                                            + " " + this.props.userMemoList[y].createTime.substr(8, 2)
                                            + ":" + this.props.userMemoList[y].createTime.substr(10, 2)
                                            + ":" + this.props.userMemoList[y].createTime.substr(12, 2)

                                        ecgPointPolymRegion.ecgData[i][(this.props.userMemoList[y].createTime - deviceTime * 1000) / 2].biaoqian
                                            = this.props.userMemoList[y].userMemo + "  (" + str + ")"
                                    } else {
                                    }
                                }
                                if (drawBufferMap[i]) {
                                    drawBufferMap[i] = drawBufferMap[i].concat(ecgPointPolymRegion.ecgData[i]);
                                } else {
                                    startTime = ecgPointPolymRegion.ecgData[i][0].time
                                    drawBufferMap[i] = ecgPointPolymRegion.ecgData[i];
                                }
                                if (clearDrawBufferFlag) {
                                    startTime = ecgPointPolymRegion.ecgData[i][0].time
                                }
                                console.log("ecgPointPolymRegion.ecgData[i][0].time:", ecgPointPolymRegion.ecgData)

                            }
                            if (zidongFlag) {
                                this._handlePlayRight()
                                zidongFlag = false
                            }
                            clearDrawBufferFlag = false;
                            if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500] && drawBufferMap[0][0] && !historyFlag) {
                                // 缓存的数据不够，需要重新获取下一个历史文件数据
                                historyFileIndex = drawBufferMap[0][drawBufferMap[0].length - 1].historyFileIndex + 1
                                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, drawBufferMap[0][drawBufferMap[0].length - 1].historyFileIndex + 1, webSocketCommandHistory, this.props.channelType, this.props.version);
                            }
                            if (initializeFlag) {
                                // message.success("历史心电文件解析完成，开始描绘心电图");
                                this.setState({playFlag2: true})
                                this._handlePlayRight()
                                this._refreshECGView();
                            }

                            break;
                        }
                    }
            }
        };
        webSocket.onerror = (e) => {
            // message.error("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            message.warn("与服务器断开了链接：" + evnt.target.url);
            if (!manualStopSearch) {
                message.info("尝试重新连接：" + evnt.target.url);
                this._handleSearch(false);
            }
        };
    };

    sleep(ms, callback) {
        setTimeout(callback, ms);
    }

    _handleResize = () => {
        this._calcParams();
        this._handleChangeBackgroundDisplay(this.state.backgroundDisplay);
    };

    _handleDevice(channelType) {
        let index = 0;
        let index2 = 0;
        let index3 = 0;
        let dataSource;
        if (historyFlag) {
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, this.props.historyIndex, webSocketCommandHistory, this.props.channelType, this.props.version);
            message.error("读取文件失败，尝试重新获取");
        }
        if (channelType == "20") {
            dataSource = this.props.appHistoryData;
        } else {
            dataSource = this.props.deviceHistoryData;
        }

        if (dataSource != "" && dataSource) {

            if (ecgFileTimeIndex) {
                for (index2 = 0; ecgFileTimeIndex.records[index2].historyFileIndex == 0; index2++) {

                }
                for (index3 = ecgFileTimeIndex.records.length - 1; ecgFileTimeIndex.records[index3].historyFileIndex == 0; index3--) {

                }
            } else {
                if (!historyFileFlag) {
                    message.error("没有获取到时间索引文件");
                }
                return
            }
            //     , historyFileIndex, ecgFileTimeIndex.timeNum, index2, index3)
            for (var i = 0; i < dataSource.length; i++) {
                if (dataSource[i].beginIdx > ecgFileTimeIndex.records[index3].historyFileIndex) {
                    if (dataSource[i - 1] && dataSource[i - 1].beginIdx > ecgFileTimeIndex.records[index2].historyFileIndex) {
                        message.error("服务器中的当日数据已播完");
                        this.setState({playFlag2: true})
                        return
                    }
                    if (dataSource[i].beginIdx > ecgFileTimeIndex.records[index2].historyFileIndex +
                        (ecgFileTimeIndex ? ecgFileTimeIndex.timeNum : 5444)) {
                        message.error("当天没有数据");
                        this.setState({playFlag2: true})
                        return
                    }
                } else {
                    if (dataSource[i].beginIdx > historyFileIndex) {
                        message.success("自动跳转到最近的数据");
                        historyFileIndex = dataSource[i].beginIdx
                        for (var x = 0; x < this.props.ecgTimeIndexData.records.length; x++) {
                            if (parseInt(dataSource[i].beginIdx) == parseInt(this.props.ecgTimeIndexData.records[x].historyFileIndex)) {
                                index = x
                            }
                        }
                        zidongFlag = true;
                        this.setState({sliderValue: index})
                        // this.onPlaySliderChange(index, false);
                        historyFileIndex = dataSource[i].beginIdx;
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, historyFileIndex, webSocketCommandHistory, this.props.channelType, this.props.version);

                        return;
                    } else if (dataSource[i].beginIdx < historyFileIndex
                        && dataSource[i].endIdx > historyFileIndex) {
                        historyFileIndex = dataSource[i].beginIdx + 1;
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, historyFileIndex, webSocketCommandHistory, this.props.channelType, this.props.version);
                    }
                }

            }

        } else {
            message.error("服务器上未获取到数据");
            return
        }
        message.success("服务器上没有该数据");
    }

    /** 计算参数 */
    _calcParams = (combinePaintFlag) => {

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap2");
        // console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        // 定标mm和px的换算
        const unitWidth = document.getElementById("unit_width");
        console.log("Width:" + unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        console.log("Height" + unitWidth.style.height, unitWidth.clientHeight, unitWidth.offsetHeight, unitWidth.scrollHeight);

        winW = ecgViewWrapNode.clientWidth / (unitWidth.clientWidth / unitWidthMill);
        console.log("winW " + winW + " mm");

        // 每个格子的宽度
        cellWidth = gridScale * (unitWidth.clientWidth / unitWidthMill);
        console.log("cellWidth " + cellWidth + " px");

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        pointSpace = cellWidth / gridPointNum;
        console.log("pointSpace " + pointSpace);

        // 格子的数量，向下取整
        xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        console.log("xCellCount " + xCellCount);

        // 算出0点，以下是负值，以上是正直，需要变换数据
        zeroPointY = (yCellCount - 3) * cellWidth + winMarginY + 15;
        console.log("zeroPointY " + zeroPointY);

        // 计算出一屏的点总数
        screenPointSum = gridPointNum * xCellCount;
        console.log("一屏的描画点数：", screenPointSum);

        if (combinePaintFlag) {
            // 合并在一张画板上，则需要按导联数计算高度
            winH = yCellCount * numLeads * cellWidth + 50;
        } else {
            winH = yCellCount * cellWidth + 50;
        }

        ecgTextY = [];
        pointIndex = 0;
    };

    _drawEcgChainCombine(i, ecgViewContent) {
        let result = '';
        const offsetY = i * yCellCount * cellWidth;

        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='history_ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

        const ecgViewText = this._getECGViewText(i);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='history_ecg_view_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        // var backgroundGroupId = document.getElementById("history_ecg_view_background_group_"+i);
        // backgroundGroupId.addEventListener('click',function (){
        //
        // })
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='history_ecg_view_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        console.log("_getECGViewLightWave:", i);
        const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        result = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;

        return result;
    }

    _drawEcgChain(i, ecgViewWrapNode) {
        // 创建SVG容器标签
        const ecgViewStart = "<svg id='history_ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='history_ecg_view_group_" + i + "' >";

        const ecgViewText = this._getECGViewText(i);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='history_ecg_view_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        // const backgroundGroupId = document.getElementById("history_ecg_view_background_group_"+i);
        // backgroundGroupId.addEventListener('click',function (){
        //
        // })
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='history_ecg_view_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        console.log("_getECGViewLightWave:", i);
        const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        const ecgViewEnd = "</svg>";

        ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
    }

    /** 绘制心电图 */
    _drawECG = (combinePaintFlag) => {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap2");
        ecgViewWrapNode.innerHTML = "";
        if (combinePaintFlag) {
            // 创建SVG容器标签
            const ecgViewStart = "<svg id='history_ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";

            let ecgViewContent = "";
            for (let i = 1; i < numLeads; i++) {

                // const offsetY = i * yCellCount * cellWidth;
                //
                // // 创建SVG的Group标签
                // const ecgViewGroupStart = "<g id='history_ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";
                //
                // const ecgViewText = this._getECGViewText(i);
                //
                // // 创建心电图背景图
                // const ecgViewBackgroundStart = "<g id='history_ecg_view_background_group_" + i + "'>";
                // const ecgViewBackgroundEnd = "</g>";
                // // var backgroundGroupId = document.getElementById("history_ecg_view_background_group_"+i);
                // // backgroundGroupId.addEventListener('click',function (){
                // //
                // // })
                // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
                //
                // // 创建心电图波形
                // const ecgViewLightWaveStart = "<g id='history_ecg_view_lightwave_group_" + i + "'>";
                // const ecgViewLightWaveEnd = "</g>";
                // // 创建心电图波形
                // const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;
                //
                // const ecgViewGroupEnd = "</g>";
                // ecgViewContent = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;
                ecgViewContent = this._drawEcgChainCombine(i, ecgViewContent);
            }

            // 把第一个导联放到最后描画
            ecgViewContent = this._drawEcgChainCombine(0, ecgViewContent);

            const ecgViewEnd = "</svg>";
            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
        } else {
            for (let i = 1; i < numLeads; i++) {

                // // 创建SVG容器标签
                // const ecgViewStart = "<svg id='history_ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // // 创建SVG的Group标签
                // const ecgViewGroupStart = "<g id='history_ecg_view_group_" + i + "' >";
                //
                // const ecgViewText = this._getECGViewText(i);
                //
                // // 创建心电图背景图
                // const ecgViewBackgroundStart = "<g id='history_ecg_view_background_group_" + i + "'>";
                // const ecgViewBackgroundEnd = "</g>";
                // // const backgroundGroupId = document.getElementById("history_ecg_view_background_group_"+i);
                // // backgroundGroupId.addEventListener('click',function (){
                // //
                // // })
                // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
                //
                // // 创建心电图波形
                // const ecgViewLightWaveStart = "<g id='history_ecg_view_lightwave_group_" + i + "'>";
                // const ecgViewLightWaveEnd = "</g>";
                // // 创建心电图波形
                // const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;
                //
                // const ecgViewGroupEnd = "</g>";
                // const ecgViewEnd = "</svg>";
                //
                // ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
                this._drawEcgChain(i, ecgViewWrapNode);
            }
            // 把第一个导联放到最后描画
            this._drawEcgChain(0, ecgViewWrapNode);
        }
        drawFlag = true;
    };


    /** 点击控制进度条播放 */
    onPlaySliderChange = (value, flag) => {
        historyFlag = false;
        // 获取当前刻度所对应的历史文件索引
        this.props.dispatch(refreshTopQ([]));
        var record
        if (this.props.ecgTimeIndexData.length > value) {
            record = this.props.ecgTimeIndexData.records[value];
        } else {
            message.error("没有该文件")
        }

        console.log("onPlaySliderChange:", value, this.props.ecgTimeIndexData)
        if (!record || record.historyFileIndex <= 0) {
            message.warn("该时间点没有心电数据");
            return;
        }

        // 当前文件的FileIndex跟最后一次取到的文件进行比较，如果不同则允许取该文件的数据
        if (record && historyFileIndex !== record.historyFileIndex) {
            // 第一个历史文件先获取了
            clearDrawBufferFlag = true;
            for (let i = 0; i < numLeads; i++) {
                if (drawBufferMap[i])
                    drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
            }

            // 初始化中值滤波，否则会有跳变
            if (this.state.medfiltDeWanderEnable) {
                window.medfiltDeWander = null;
            }

            drawCountValue = {};
            webSocketCommandHistory = [];
            this._handleStopPlay(true);
            this.setState({sliderValue: value, playFlag2: false});
            historyFileIndex = record.historyFileIndex;
            console.log("record.historyFileIndex:", record.historyFileIndex);
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory, this.props.channelType, this.props.version);
        }
    };

    /** 是否显示心电图网格背景 */
    _handleChangeBackgroundDisplay = (value) => {
        this.setState({backgroundDisplay: value});
        for (let i = 0; i < numLeads; i++) {
            if (value) {
                const ecgViewBackgroundGroup = document.getElementById("history_ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = this._getECGViewBackground();
            } else {
                const ecgViewBackgroundGroup = document.getElementById("history_ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = "";
            }
        }
    };

    _handleChangeMedfiltDeWanderEnable = (value) => {
        this.setState({medfiltDeWanderEnable: value});
    };

    /** 心电图正向播放 */
    _handlePlayRight = () => {

        if (!historyFileIndex) {
            message.error("请先选择日期")
            return
        }

        if (!this.state.playFlag) {
            this.setState({playFlag: true});

            this.intervalPlay = setInterval(() => {

                this._refreshECGView();

                if (drawBufferMap[0] && drawBufferMap[0][screenPointSum] && drawBufferMap[0][screenPointSum].timeRecordIndex !== this.state.sliderValue) {
                    this.setState({sliderValue: drawBufferMap[0][screenPointSum].timeRecordIndex});
                }

                // 如果当前点的时间记录索引跟滑动条上的标识不符合，则调整滑动条的值
                if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500] && drawBufferMap[0][0] && !historyFlag) {
                    // 缓存的数据不够，需要重新获取下一个历史文件数据
                    historyFileIndex = drawBufferMap[0][drawBufferMap[0].length - 1].historyFileIndex + 1
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.MIXDATA, drawBufferMap[0][drawBufferMap[0].length - 1].historyFileIndex + 1, webSocketCommandHistory, this.props.channelType, this.props.version);
                    if (!drawBufferMap[0][screenPointSum]) {
                        if (this.state.playFlag2) {
                            this.setState({playFlag2: false})
                        }
                    }
                }

                // if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum] && drawBufferMap[0][0] && !historyFlag) {
                //
                // }

            }, winFps);
        }
    };

    _handleStopSearch() {
        webSocketCommandHistory = [];
        // for (let i = 0; i < 8; i++) {
        //     if (drawBufferMap[i])
        //         drawBufferMap[i].splice(screenPointSum, drawBufferMap[i].length - screenPointSum);
        // }
        drawBufferMap = {}
        this._handleStopPlay(true);
        webSocket && webSocket.close();
    }

    _handleStopPlay(manualStop) {
        manualStopSearch = manualStop;
        this.setState({playFlag: false});
        clearInterval(this.intervalPlay);
        this.intervalPlay = undefined;
    }

    _handlePreFile() {
        if (this.state.sliderValue > 0) {
            this.onPlaySliderChange(this.state.sliderValue - 1, true);
        }
    }

    _handleNextFile() {
        this.onPlaySliderChange(this.state.sliderValue + 1, true);
    }

    _refreshEcgChainView(i) {
        if (!drawBufferMap[i] || !drawBufferMap[i][rfsNum * this.state.chartSpeedTimes]) {
            return;
        }

        if (this.state.playFlag && this.state.playFlag2) {//
            // 如果在播放，则把rfsNum这个索引之前的数据删掉
            drawBufferMap[i].splice(0, rfsNum * this.state.chartSpeedTimes);
        }

        const ecgViewLightwaveGroup = document.getElementById("history_ecg_view_lightwave_group_" + i);

        // 创建心电图波形
        const ecgViewLightWave = this._getECGViewLightWave(i);
        ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
    }

    /** 刷新心电图 */
    _refreshECGView = () => {
        for (let i = 1; i < numLeads; i++) {

            // if (!drawBufferMap[i] || !drawBufferMap[i][rfsNum * this.state.chartSpeedTimes]) {
            //     continue;
            // }
            //
            // if (this.state.playFlag && this.state.playFlag2) {//
            //     // 如果在播放，则把rfsNum这个索引之前的数据删掉
            //     drawBufferMap[i].splice(0, rfsNum * this.state.chartSpeedTimes);
            // }
            //
            // const ecgViewLightwaveGroup = document.getElementById("history_ecg_view_lightwave_group_" + i);
            //
            // // 创建心电图波形
            // const ecgViewLightWave = this._getECGViewLightWave(i);
            // ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
            this._refreshEcgChainView(i);
        }
        // 把第一个导联放到最后描画
        this._refreshEcgChainView(0);
    };

    /** 画心电图的上的文本 */
    _getECGViewText = (index) => {
        const centerY = winH / 2 + 15;
        // 画导联的名称
        const result = "<text x=-10 y=" + centerY + ">CH-" + (index + 1) + ":" + leadName.List[index].label + "</text>";
        return result;
    };

    /** 画心电图的背景 */
    _getECGViewBackground = () => {

        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        // let path = "M" + winMarginX + "," + winMarginY;
        let path = "M" + winMarginX + "," + (winMarginY + 15);

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        // path = path + " M" + winMarginX + "," + winMarginY;
        path = path + " M" + winMarginX + "," + (winMarginY + 15);
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        // 画原点
        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        // console.log("_getECGViewBackground:", path, result, zeroRect)
        return result + zeroRect;
    };

    /** 画心电图的折线 */
    _getECGViewLightWave = (index) => {

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";
        let line = "";
        let historyIndex = 0;
        let timeSvg = "";
        const drawBuffer = drawBufferMap[index];
        // let drawBufferValue = 1;
        if (!drawBuffer) {
            return;
        }
        //const line = "<line x1=0 y1=10 x2=0 y2=100 stroke='blue'></line>"
        let x = winMarginX;
        // if (drawBuffer.length > screenPointSum) {
        if (drawBuffer[screenPointSum - 1]) {
            historyFileIndexV = drawBuffer[screenPointSum - 1].historyFileIndex ? drawBuffer[screenPointSum - 1].historyFileIndex : ""
            if (historyFileIndexV != this.state.historyFileIndexV) {
                this.setState({
                    historyFileIndexV: drawBuffer[screenPointSum - 1].historyFileIndex ? drawBuffer[screenPointSum - 1].historyFileIndex : ""
                })
            }
        }
        // } else {
        //     if (drawBuffer[screenPointSum - 1].historyFileIndex > 0) {
        //         historyFileIndexV = drawBuffer[screenPointSum - 1].historyFileIndex ? drawBuffer[screenPointSum - 1].historyFileIndex : ""
        //     } else {
        //         historyFileIndexV = drawBuffer[drawBuffer.length - 1].historyFileIndex ? drawBuffer[drawBuffer.length - 1].historyFileIndex : ""
        //     }
        // }
        let svgDate;
        let svgBiaoqian;
        let svgEventType;
        let svgTopPoint = '';
        let result = "";
        for (let i = 0; i < screenPointSum; i++) {

            // 计算前一个点
            let preY = 0;
            if (i !== 0) {
                if (drawBuffer[i - 1].newPoint !== null) {
                    preY = drawBuffer[i - 1].newPoint;
                } else {
                    preY = drawBuffer[i - 1].point;
                    if (preY !== 0) {
                        preY = preY / gc;// * 0.000049;
                        preY = preY / (1 / (this.state.voltDiv / gridScale));
                        preY = preY * cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = zeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + zeroPointY;
                    }
                }
                if (drawBuffer[i - 1] && drawBuffer.length > i) {
                    if (Math.abs(drawBuffer[i].historyFileIndex - drawBuffer[i - 1].historyFileIndex) > 1 && drawBuffer[i - 1].historyFileIndex > 0) {
                        //     drawBuffer[i].historyFileIndex, drawBuffer[i - 1].historyFileIndex)
                        line += "<line x1='" + (x) + "' y1=35 x2='" + (x) + "' y2=155 stroke='blue' stroke-width='3'></line>"
                    }
                }

            }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (drawBuffer[i] === null || drawBuffer[i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (drawBuffer[i].newPoint !== null) {
                y = drawBuffer[i].newPoint;
            } else {
                y = drawBuffer[i].point;
                y = y / gc;// * 0.000049;
                y = y / (1 / (this.state.voltDiv / gridScale));
                y = y * cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = zeroPointY - y;
                } else {
                    y = Math.abs(y) + zeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                drawBuffer[i].newPoint = y;

            }


            // if (drawBuffer[screenPointSum - 1].historyFileIndex > drawBufferValue + 1 && drawBufferValue != 0) {
            //     line = "<line x1='" + (x + 5) + "' y1=20 x2='" + (x + 5) + "' y2=150 stroke='blue'></line>"
            // } else {
            //     line = "<line x1='" + (x + 5) + "' y1=20 x2='" + (x + 5) + "' y2=150 stroke='blue'></line>"
            //     drawBufferValue = drawBuffer;
            // }
            // 绘制心电图上的时间标识
            // if (drawBuffer[i].historyFileIndex && drawBuffer[i].historyFileIndex != historyIndex && drawBuffer[i].index == 0) {
            if (drawBuffer[i] && drawBuffer[i].time) {
                // svgDate = "<text y='32' x='" + (x + 5) + "'>" + "Idx:" + drawBuffer[i].historyFileIndex + "  (" + drawBuffer[i].time + ")" + "</text>";
                if (drawBuffer[i].index == 0) {
                    svgDate = "<text y='32' x='" + (x + 5) + "'>" + "Idx:" + drawBuffer[i].historyFileIndex + "</text>";
                } else {
                    svgDate = "<text y='32' x='" + (x + 5) + "'>" + drawBuffer[i].time + "</text>";
                }
                startTime = drawBuffer[i].time
                // maxLiveId = drawBuffer[i].package.liveId > maxLiveId ? drawBuffer[i].package.liveId : maxLiveId;
                // preTime = drawBuffer[i].devTime;
                historyIndex = drawBuffer[i].historyFileIndex;
                const svgStamp = "<polygon points='" + x + ",37 " + (x - 5) + ",47 " + (x + 5) + ",47' fill='blue' stroke='rgb(0,0,255)' stroke-dasharray='2 2'></polygon>";
                timeSvg = timeSvg + svgDate + svgStamp;
            }

            if (drawBuffer[i].biaoqian) {
                // svgBiaoqian = "<text y='15' x='" + (x + 5) + "'>" + drawBuffer[i].biaoqian + "</text>";
                // const svgBiaoqianStamp = "<polygon points='" + x + ",20 " + (x - 5) + ",30 " + (x + 5) + ",30' fill='blue' stroke='rgb(0,0,255)' stroke-dasharray='2 2'></polygon>";
                const svgBiaoqianStamp = "<rect x='" + x + "' y='35' fill='green' width='10' height='10' stroke='yellow'></rect>";
                const textBiaoqian = " <text y='15' x='" + (x + 5) + "' style='fill: green'>" + drawBuffer[i].biaoqian + "</text>"
                // timeSvg += svgBiaoqian
                svgBiaoqian += textBiaoqian + svgBiaoqianStamp;
            }

            if (drawBuffer[i].eventType) {
                const textEventType = " <text y='15' x='" + (x + 5) + "' style='fill: red'>" + drawBuffer[i].eventType + "</text>"
                const svgEventTypeStamp = "<circle cx='" + x + "' cy='40' r='5' style='fill:#FF0000' /> "
                svgEventType += textEventType + svgEventTypeStamp
            }

            if (drawBuffer[i].topPointFlag) {
                // 如果是高点，则标注上高点特征标记
                const svgTopPointItem = "<circle cx='" + x + "' cy='" + y + "' r='5' stroke='blue' fill='transparent' /> ";
                svgTopPoint = svgTopPoint + svgTopPointItem;
            }

            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }

            x = x + pointSpace;
        }

        result = result + "<path stroke='rgb(0,0,0)' fill='none' strokeWidth='1' d='" + path + "'></path>";
        // line = "<line x1='" + (700) + "' y1='" + (zeroPointY - 2) + "' x2='" + (700) + "' y2=500 stroke='blue'></line>"//x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2)
        // line = "<line x1='" + (780) + "' y1=20 x2='" + (780) + "' y2=140 stroke='blue'></line>"
        if (line !== "") {
            result = result + line;
        }
        if (timeSvg !== "") {
            result = result + timeSvg;
        }
        if (svgBiaoqian) {
            result = result + svgBiaoqian;
        }
        if (svgEventType) {
            result = result + svgEventType;
        }
        if (svgTopPoint) {
            result = result + svgTopPoint;
        }
        return result;


    };

    _handleChangeRecordDate(date) {
        // historyFileIndex = null;
        // ecgFileTimeIndex = null;
        // ecgFileEventIndex = null;
        // timeFlag = false;
        // eventFlag = false;
        const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
        if (ecgDateIndexRecord) {
            // let requestUrl = RoutePath.ECG_HISTORY_DAY.path;
            // requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", this.props.deviceCode);
            // requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
            // requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
            // requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
            // requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
            // requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);
            //
            // this.props.router.push(requestUrl);
            this.props.dispatch(refreshCalendarEcgDateIndexRecord(ecgDateIndexRecord));
            historyFlag = false;
            drawBufferMap = {}
            drawCountValue = {};
            webSocketCommandHistory = [];
            this._calcParams();
            this._drawECG(this.state.combinePaint);
            this.setState({historyFileIndexV: ""})
            // const recordDate = ecgDateIndexRecord.recordDate;
            // const deviceCode = ecgDateIndexRecord.deviceCode;
            // const timeFileIndex = ecgDateIndexRecord.timeFileIndex;
            // const eventFileIndex = ecgDateIndexRecord.eventFileIndex;
            //
            // this.setState({
            //     recordDate: recordDate,
            //     deviceCode: deviceCode,
            //     timeFileIndex: timeFileIndex,
            //     eventFileIndex: eventFileIndex,
            //     rangeStartDate: recordDate,
            //     doubleCalendarVisible: false,
            //     rangeEndDate: recordDate
            // })

            // this.setState({
            //
            //     deviceCode: this.props.deviceCode,
            //     recordDate: this.props.ecgDateIndexRecord.recordDate,
            //     timeFileIndex: this.props.ecgDateIndexRecord.timeFileIndex,
            //     eventFileIndex: this.props.ecgDateIndexRecord.eventFileIndex
            // });
            // webSocketCommandHistory = [];
            // clearDrawBufferFlag = true;
            // WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.props.deviceCode, EcgFileType.Enum.DATEIDX, 1);
        }
        onClickFlag = true;
    }

    _handleChangeVoltDiv(value) {
        this.setState({voltDiv: value});
        // 重新计算点
        for (let i = 0; i < numLeads; i++) {

            const drawBuffer = drawBufferMap[i];
            if (!drawBuffer) {
                return;
            }

            for (let i = 0; i < screenPointSum; i++) {
                drawBuffer[i].newPoint = null;
            }
        }
    }

    _handleChannelTypeChange(value) {
        onClickFlag = true;
        const parem = {
            "deviceCode": this.props.deviceCode,
            // "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(this.props.xuanzeValue)],
            "fromApp": value == "10" ? "0" : "1",
        }
        this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
        this._handleStopSearch();
        this._handleSearch(true);
    }

    _handleVersionChange(value) {
        if (value == "00") {
            xuanzeValue = this.props.mapVersion.length;
        } else {
            xuanzeValue = value;
        }
        const parem = {
            "deviceCode": this.props.deviceCode,
            "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(xuanzeValue)],
            "fromApp": this.props.channelType == "10" ? "0" : "1"
        }
        this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
        this._handleStopSearch();
        this._handleSearch(true);
    }

    render() {
        const isExternalUser = (this.props.user && this.props.user.roles && this.props.user.roles[0] && this.props.user.roles[0].code === 'ROLE_EXTERNAL') || !this.props.showRoles;

        return (
            <div className="ecg-history-canvas drawer-container">
                <div id="unit_width" style={{
                    width: unitWidthMill + 'mm',
                    height: unitWidthMill + 'mm',
                    position: 'absolute',
                    zIndex: -1
                }}>

                </div>
                <div
                    style={{
                        display: !this.state.playFlag2 ? "block" : "none", //playFlag ? "block" : "none",
                        transform: " translate(-50%,-50%)",
                        position: "absolute",
                        top: "10%",
                        left: "50%",
                        // zIndex: 400
                    }}
                >

                    <Spin tip={"Loading..." + historyFileIndex}>
                        <Alert
                            message="正在获取数据请稍等"
                            type="info"
                        />
                    </Spin>

                </div>


                <div>
                    <div>
                        <b>日期选择：</b>
                        <DatePicker className="spacing-h"
                                    value={this.state.recordDate ? moment(this.state.recordDate, FORMAT_DATE_HYPHEN) : null}
                                    locale={locale}
                                    suffixIcon={<div></div>}
                                    disabled={true}
                        />
                        <Popover className="spacing-h"
                                 content={<DoubleCalendar visible={this.state.doubleCalendarVisible}
                                                          ecgDateIndexRecordMap={this.props.ecgDateIndexRecordMap}
                                                          onSelect={(date) => this._handleChangeRecordDate(date)}/>}
                                 visible={this.state.doubleCalendarVisible}
                                 trigger="click"
                                 placement="bottom"
                                 onVisibleChange={(value) => this.setState({doubleCalendarVisible: value})}>
                            <Icon className="ecg-history-double-calendar spacing-h"
                                  type="carry-out"
                                  theme="twoTone"/>
                        </Popover>
                        {/*<Button className="spacing-h" onClick={() => this._toLorenz()}>开始统计</Button>*/}
                        <Switch className="history-events-switch pull-right spacing-h"
                                checkedChildren="异常事件"
                                unCheckedChildren="异常事件"
                                onChange={(value) => this.setState({eventIndexTimeLineVisible: value})}/>
                        <Switch className="history-events-switch pull-right spacing-h"
                                checkedChildren="历史总览"
                                unCheckedChildren="历史总览"
                                onChange={(value) => this.setState({dateIndexOverviewVisible: value})}/>
                        <b className="history-events-switch pull-right spacing-h">辅助窗口：</b>
                        {/*<div>*/}
                        <Divider dashed={true}
                                 style={{
                                     top: 18,
                                     position: "absolute",
                                     cursor: "pointer",
                                     paddingLeft: 5,
                                     fontSize: 12,
                                     fontWeight: "bold"
                                 }}/>
                        {/*</div>*/}
                        <div style={{paddingTop: "10px", paddingBottom: "10px"}}>
                            <Row style={{width: '100%', height: '100%', backgroundColor: "#FFF"}}>
                                <Col span={2} style={{
                                    width: '50%', height: '100%', backgroundColor: "#FFF",
                                }}>
                                    <b>文件检索：</b>
                                    <Input id="fileIndex"
                                           className="spacing-h"
                                           style={{width: "180px"}}
                                           placeholder={"输入编号"}
                                           value={fileIndexValue}
                                           onChange={(value) => {
                                               this._setChangeFileIndex(value, 1)
                                           }}/>
                                    <TimePicker id="fileIndex2"
                                                placeholder={"选择时间"}
                                                className="spacing-h"
                                                style={{width: "180px"}}
                                                value={timeValue}
                                                onChange={(value) => {
                                                    this._setChangeFileIndex(value, 2)
                                                }}/>
                                    <Button className="spacing-h"
                                            onClick={() => this._handleChangeFileIndex()}>检索</Button>
                                </Col>
                                <Col span={2} style={{
                                    width: '700px', height: '100%', backgroundColor: "#FFF", float: "right"
                                }}>
                                    <b>播放功能：</b>
                                    <Button icon={"step-backward"} className="spacing-h"
                                            onClick={() => this._handlePreFile()}/>
                                    <Button icon={"pause"} className="spacing-h"
                                            onClick={() => this._handleStopPlay()}/>
                                    <Button loading={this.state.playFlag} icon={"caret-right"} className="spacing-h"
                                            onClick={() => this._handlePlayRight()}/>
                                    <Button icon={"step-forward"} className="spacing-h"
                                            onClick={() => this._handleNextFile()}/>
                                    <EnumItemSelect
                                        style={{width: '100px', marginRight: '10px'}} allowClear={false}
                                        data={ChartSpeed.List} value={this.state.chartSpeedTimes}
                                        onChange={(value) => {
                                            this.setState({chartSpeedTimes: value});
                                        }}/>
                                    <EnumItemSelect
                                        style={{width: '100px', marginRight: '10px'}} allowClear={false}
                                        data={VoltDiv.List} value={this.state.voltDiv}
                                        onChange={(value) => this._handleChangeVoltDiv(value)}/>
                                    <Switch className="spacing-h"
                                            checkedChildren="显示格子" unCheckedChildren="隐藏格子"
                                            checked={this.state.backgroundDisplay}
                                            onChange={(value) => this._handleChangeBackgroundDisplay(value)}/>
                                    <Switch className="spacing-h"
                                            checkedChildren="中值滤波" unCheckedChildren="中值滤波"
                                            checked={this.state.medfiltDeWanderEnable}
                                            onChange={(value) => this._handleChangeMedfiltDeWanderEnable(value)}/>
                                </Col>
                            </Row>


                            {/*<div className="history-events-switch pull-right spacing-h">*/}
                            {/*    */}
                            {/*</div>*/}
                            <Divider
                                style={{
                                    top: 62,
                                    position: "absolute",
                                    cursor: "pointer",
                                    paddingLeft: 5,
                                    fontSize: 12,
                                    fontWeight: "bold"
                                }}
                                dashed={true} plain/>
                        </div>
                        {/*<span>{this.props.deviceCode}</span>*/}
                    </div>
                </div>
                {/* 播放进度条 */}
                {
                    isExternalUser ? null :
                        <TimeIndexSlider sliderValue={this.state.sliderValue}
                                         initIndex={initIndex}
                                         startTime={startTime}
                                         initHistoryIndex={initHistoryIndex}
                                         ecgTimeIndexData={timeFlag ? this.props.ecgTimeIndexData : ""}//
                                         ecgTimeSlideMarks={this.props.ecgTimeSlideMarks}
                                         ecgTimeSlideData={this.props.channelType == "20" ? this.props.appHistoryData : this.props.deviceHistoryData}
                                         deviceHistoryData={this.props.deviceHistoryData}
                                         historyFileIndex={this.state.historyFileIndexV}
                                         onChange={(value) => {
                                             this.setState({sliderValue: value});
                                             this._handleStopPlay()
                                         }}
                                         onAfterChange={(value) => this.onPlaySliderChange(value, true)}/>
                }
                {/*onClick={() => this._handleStopPlay()}*/}
                {/*onDrop={(value) => this.onPlaySliderChange(value, true)}*/}
                <div id="ecg_view_wrap2" style={{paddingTop: '115px'}}>
                </div>
                <DateIndexOverview visible={this.state.dateIndexOverviewVisible}
                                   ecgDateIndexRecords={this.props.ecgDataIndexEffectiveRecords}
                                   rangeStartDate={this.state.rangeStartDate}
                                   rangeEndDate={this.state.rangeEndDate}
                                   onRangeStartDateChange={(rangeStartDate, rangeEndDate) => this._handleRangeStartDateChange(rangeStartDate, rangeEndDate)}
                                   onRangeEndDateChange={(rangeStartDate, rangeEndDate) => this._handleRangeEndDateChange(rangeStartDate, rangeEndDate)}
                                   {...this.props} />
                <EventIndexTimeLine ecgEventIndexData={this.props.ecgEventIndexData}
                                    eventRecordIndex={this.props.ecgTimeIndexData.records &&
                                        this.props.ecgTimeIndexData.records[this.state.sliderValue] &&
                                        this.props.ecgTimeIndexData.records[this.state.sliderValue].eventRecordIndex - 1}
                                    onTimeLineItemClick={(value) => this.onPlaySliderChange(value.timeRecordIndex - 1, true)}
                                    visible={this.state.eventIndexTimeLineVisible}/>
                <DeviceDetailDrawer visible={this.state.toggleDeviceDetail} setVis={this._setVis}/>
            </div>
        );
    }

    _setChangeFileIndex(value, model) {

        if (model == 1) {
            fileIndexValue = value.target.value
            timeValue = null
        } else {

            timeValue = value
            fileIndexValue = null

        }
        console.log("_setChangeFileIndex:", value, timeValue, model)
        this.setState({
            toggleDeviceDetail: this.state.toggleDeviceDetail,
        });
    }

    _handleChangeFileIndex() {
        // console.log("_handleChangeFileIndex:", value, value.target.value, this.props.ecgTimeIndexData.records);
        if (fileIndexValue) {
            if (this.props.ecgTimeIndexData.length > 0) {
                for (let i = 0; i < this.props.ecgTimeIndexData.records.length; i++) {
                    if (this.props.ecgTimeIndexData.records[i].historyFileIndex == fileIndexValue) {
                        this.onPlaySliderChange(i);
                        return
                    }
                }
                message.error("今日文件中没有该数据")
            } else {
                message.error("请先选择日期")
            }


        } else if (timeValue) {
            // if (timeValue.length == 6) {
            var time = moment(timeValue._d, FORMAT_TIME_COLON).format(FORMAT_TIME_COLON).toString().split(":");
            var S = time[2]
            var M = time[1]
            var H = time[0]
            console.log("timeValue:", timeValue, time, S, M, H)
            var miniSecPerFile = timeFlag ? this.props.ecgTimeIndexData.miniSecPerFile / 1000 : 15.872;
            // console.log("_handleChangeFileIndex:", H, M, S, (H * 60 * 60 + M * 60 + S),
            //     Math.floor((H * 60 * 60 + M * 60 + S) / miniSecPerFile), miniSecPerFile, this.props.ecgTimeIndexData.miniSecPerFile)
            this.onPlaySliderChange(Math.floor((Number(H) * 60 * 60 + Number(M) * 60 + Number(S)) / miniSecPerFile));
            // } else {
            //     message.error("请输入正确格式时间")
            // }
        } else {
            message.error("请输入检索内容")
        }
    }

    _setVis = value => {
        this.setState({
            toggleDeviceDetail: value,
        });

    };

    _toLorenz() {
        // console.log("topQ:", topQ)
        this.props.dispatch(refreshTopQ(topQ));
        this.props.setDefaultActiveKey("6");
        this.props.setLorenzFlag(true);
    }


    _handleRangeStartDateChange(rangeStartDate, rangeEndDate) {
        this.setState({rangeStartDate: rangeStartDate});
        const startDate = moment(rangeStartDate, FORMAT_DATE_SIMPLE);
        const endDate = moment(rangeEndDate, FORMAT_DATE_SIMPLE);
        const dayCount = endDate.diff(startDate, "days");
        for (let i = 0; i <= dayCount; i++) {
            const date = startDate.add(i, 'days');
            const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
            if (ecgDateIndexRecord && ecgDateIndexRecord.timeNum > 0) {
                this._handleChangeRecordDate(date);
                break;
            }
        }
    }

    _handleRangeEndDateChange(rangeStartDate, rangeEndDate) {
        this.setState({rangeEndDate: rangeEndDate});
    }
}

const mapStateToProps = (store) => {
    return {
        ecgDateIndexData: store.EcgHistoryReducer.ecgDateIndexData,
        ecgDataIndexEffectiveRecords: store.EcgHistoryReducer.ecgDataIndexEffectiveRecords,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        ecgTimeIndexData: store.EcgHistoryReducer.ecgTimeIndexData,
        ecgTimeSlideMarks: store.EcgHistoryReducer.ecgTimeSlideMarks,
        timeRecordIndex: store.EcgHistoryReducer.timeRecordIndex,
        ecgEventIndexData: store.EcgHistoryReducer.ecgEventIndexData,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        deviceCode: store.EcgDeviceReducer.deviceCode,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        ecgDateIndexRecord: store.EcgDeviceReducer.ecgDateIndexRecord,
        user: store.AccountUserReducer.user,
        channelType: store.EcgDeviceReducer.channelType,
        version: store.EcgDeviceReducer.version,
        mapVersion: store.EcgDeviceReducer.mapVersion,
        userMemoList: store.EcgDeviceReducer.userMemoList,
        topQ: store.EcgDeviceReducer.topQ,
        showRoles: store.AccountRoleReducer.showRoles,
    }
};

export default connect(mapStateToProps)(injectIntl(Index));
