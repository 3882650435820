import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {Select} from "antd";
import {getDictItemsByClassCode} from "../actions/DictItemAction";
import {connect} from "react-redux";
import {Language} from "../../../constants/Enums";

class DictItemSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        if (this.props.classCode) {
            this.props.dispatch(getDictItemsByClassCode(this.props.classCode, this.props.dictItemMap, this.state.formatMessage));
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        const {dictItemMap, classCode} = this.props;
        return (
            <Select
                mode={this.props.mode}
                placeholder={this.props.placeholder}
                style={this.props.style ? this.props.style : {width: '100%'}}
                allowClear={this.props.allowClear}
                disabled={this.props.disabled}
                value={this.props.value}
                onChange={this.onChange}>
                {
                    dictItemMap[classCode] && dictItemMap[classCode].map((item, index) => {
                        return <Select.Option key={index} value={item.value}>
                            {this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn}
                        </Select.Option>
                    })
                }
            </Select>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage,
        dictItemMap: store.DictItemReducer.dictItemMap
    }
}

export default connect(mapStateToProps)(injectIntl(DictItemSelect));