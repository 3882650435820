/**
 * Created by Ethan on 2017/9/6.
 */
const zh_CN = {

    APP_BRAND: "心电衣运营平台",

    COMMON_BUTTON_ADD: "添加",
    COMMON_BUTTON_CREATE: "创建",
    COMMON_BUTTON_SAVE: "保存",
    COMMON_BUTTON_SAVE_FRAGMENT: "保存片段",
    COMMON_BUTTON_SAVE_CANEL: "取消保存",
    COMMON_BUTTON_SAVE_DRAFT: "保存草稿",
    COMMON_BUTTON_DISPALY_SORT: "显示排序",
    COMMON_BUTTON_MODIFY: "修改",
    COMMON_BUTTON_REMOVE: "移除",
    COMMON_BUTTON_DELETE: "删除",
    COMMON_BUTTON_DELETE_PAGE: "删除本页",
    COMMON_BUTTON_DELETE_ALL: "删除所有",
    COMMON_BUTTON_SUBMIT: "提交",
    COMMON_BUTTON_CANCEL: "取消",
    COMMON_BUTTON_SEARCH: "查询",
    COMMON_BUTTON_SEARCH2: "开始",
    COMMON_BUTTON_EMPTY: "清空",
    COMMON_BUTTON_SEARCH_ADVANCE: "高级查询",
    COMMON_BUTTON_DRAFT: "保存草稿",
    COMMON_BUTTON_EXPORT: "导出",
    COMMON_BUTTON_SELECT: "选择",
    COMMON_BUTTON_BACK: "返回",
    COMMON_BUTTON_UPLOAD: "上传",
    COMMON_BUTTON_DOWNLOAD: "下载",
    COMMON_BUTTON_ELDER_VERSIONS_DOWNLOAD: "旧版下载",
    COMMON_BUTTON_NEXT_ITEM: "下一条",
    COMMON_BUTTON_PRINT: "打印",
    COMMON_BUTTON_DETAIL: "查看详情",
    COMMON_BUTTON_STOP: "停止",
    COMMON_BUTTON_OK: "确定",
    COMMON_BUTTON_SELECT_FILE: "选择文件",
    COMMON_BUTTON_CLOSE: "关闭",
    COMMON_BUTTON_REFRESH: "刷新",
    COMMON_BUTTON_SCREEN: "筛选",
    COMMON_BUTTON_UPDATE: "更新",
    COMMON_BUTTON_DIAGNOSIS: "诊断",
    COMMON_BUTTON_CHECK: "查看",

    COMMON_FIELD_CREATE_TIME: "创建时间",
    COMMON_FIELD_PUBLISH_DATE: "发布日",
    COMMON_FIELD_EXPLANATION: "说明",
    COMMON_FIELD_UPDATE_TIME: "更新时间",
    COMMON_FIELD_UPLOAD_TIME: "上传时间",
    COMMON_FIELD_ENABLED: "有效",
    COMMON_FIELD_DISABLED: "无效",
    COMMON_FIELD_NO: "序号",
    COMMON_FIELD_TRUE: "是",
    COMMON_FIELD_FALSE: "否",
    COMMON_FIELD_REMARKS: "备注",
    COMMON_FIELD_ACTION: "操作",
    COMMON_FIELD_PAGINATION_TOTAL: "共 {sum} 条",
    COMMON_FIELD_LAST_MODIFY_TIME: "最后更新时间",
    COMMON_FIELD_FILE: "数据文件",

    COMMON_PLACEHOLDER_FUZZY_QUERY: "模糊查询",
    COMMON_PLACEHOLDER_SELECT_DATE: "选择日期",

    COMMON_MSG_REQUIRED: "不能为空",
    COMMON_MSG_UPLOAD_SUCCESS: "上传成功",
    COMMON_MSG_CREATE_SUCCESS: "创建已完成",
    COMMON_MSG_UPDATE_SUCCESS: "更新已完成",
    COMMON_MSG_DELETE_SUCCESS: "删除已完成",
    COMMON_MSG_DELETE_TITLE: "删除确认",
    COMMON_MSG_UPDATE_TITLE: "修改确认",
    COMMON_MSG_DELETE_CONFIRM: "删除后无法恢复，请确认是否删除？",
    COMMON_MSG_LENGTH_EIGHT: "请输入完整的8位编号",
    COMMON_MSG_GREATER_START: "此值须大于起始值",
    COMMON_MSG_SAVE_SUCCESS: "保存成功",

    COMMON_ROUTER_SYS_MANAGEMENT: "系统管理",

    COMMON_WARN_MAP: "定位查看",

    // 邮件系统
    MAIL_TEMPLATE_MSG_VERIFY_CODE_REQUIRED: "Email verify code is required.",
    MAIL_TEMPLATE_MSG_VERIFY_CODE_SEND_SUCCESS: "Mail verify code send succeed.",

    // 账户管理-角色
    ACCOUNT_ROLE_FIELD_NAME: "角色",

    ACCOUNT_USER_ROUTE_MANAGEMENT: "账户管理",
    ACCOUNT_USER_ROUTE_LOGOUT: "退出登录",
    ACCOUNT_USER_ROUTE_LOGIN_PASSWORD: "密码登录",
    ACCOUNT_USER_ROUTE_REGISTER: "用户注册",
    ACCOUNT_USER_ROUTE_LIST: "用户一览",
    ACCOUNT_USER_ROUTE_PROFILE: "用户基本信息",
    ACCOUNT_USER_ROUTE_PASSWORD: "用户密码",
    ACCOUNT_USER_ROUTE_PASSWORD_MODIFY: "修改密码",
    ACCOUNT_USER_ROUTE_PASSWORD_FORGOT: "忘记密码",

    ACCOUNT_USER_TITLE_CREATE: "创建新用户",
    ACCOUNT_USER_TITLE_UPDATE: "更新用户",
    ACCOUNT_USER_TITLE_DELETE: "删除用户",
    ACCOUNT_USER_TITLE_LOGOUT: "用户登出",
    ACCOUNT_USER_FIELD_UID: "用户ID",
    ACCOUNT_USER_FIELD_UID_PLACEHOLDER: "用户ID/邮箱/手机号",
    ACCOUNT_USER_FIELD_PASSWORD_OLD: "旧密码",
    ACCOUNT_USER_FIELD_PASSWORD_NEW: "新密码",
    ACCOUNT_USER_FIELD_PASSWORD: "密码",
    ACCOUNT_USER_FIELD_PASSWORD_CONFIRM: "确认密码",
    ACCOUNT_USER_FIELD_EMAIL: "邮箱",
    ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE: "邮箱验证码",
    ACCOUNT_USER_FIELD_MOBILE: "手机号",
    ACCOUNT_USER_FIELD_FIRST_NAME: "名",
    ACCOUNT_USER_FIELD_LAST_NAME: "姓",
    ACCOUNT_USER_FIELD_FULL_NAME: "姓名",
    ACCOUNT_USER_FIELD_ROLE: "角色",
    ACCOUNT_USER_FIELD_ENABLE: "有效性",
    ACCOUNT_USER_BUTTON_SUBMIT: "登录",
    ACCOUNT_USER_BUTTON_REGISTER: "注册新用户",
    ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE: "发送验证码",
    ACCOUNT_USER_MSG_UID_REQUIRED: "用户ID是不能为空",
    ACCOUNT_USER_MSG_PASSWORD_REQUIRED: "密码不能为空",
    ACCOUNT_USER_MSG_PASSWORD_WRONG: "密码错误",
    ACCOUNT_USER_MSG_OLD_PASSWORD_REQUIRED: "旧密码不能为空",
    ACCOUNT_USER_MSG_OLD_PASSWORD_WRONG: "旧密码错误",
    ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED: "密码长度不能少于{value}个字符",
    ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED: "密码必须包含字符，数字，特殊符号的组合",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED: "密码确认不能为空",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED: "两次密码不一致",
    ACCOUNT_USER_MSG_MOBILE_REQUIRED: "手机号不能为空",
    ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG: "手机号格式不正确",
    ACCOUNT_USER_MSG_EMAIL_REQUIRED: "邮箱不能为空",
    ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG: "邮箱格式不正确",
    ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED: "邮箱验证码不能为空",
    ACCOUNT_USER_MSG_EMAIL_CODE_SEND_SUCCESS: "邮箱验证码发送成功",
    ACCOUNT_USER_MSG_EMAIL_CODE_NOT_FOUND: "邮箱验证码不正确",
    ACCOUNT_USER_MSG_LAST_NAME_REQUIRED: "姓不能为空",
    ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED: "名不能为空",
    ACCOUNT_USER_MSG_ROLE_REQUIRED: "角色不能为空",
    ACCOUNT_USER_MSG_AUTH_TOKEN_EXPIRED: "登录令牌过期",
    ACCOUNT_USER_MSG_AUTH_TOKEN_NON_PRINCIPAL: "登录令牌不存在",
    ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS: "密码重制成功",
    ACCOUNT_USER_MSG_PASSWORD_RESET_CONFIRM: "确定要重制该用户的密码吗？",
    ACCOUNT_USER_MSG_CREATE_SUCCESS: "用户创建成功",
    ACCOUNT_USER_MSG_UPDATE_SUCCESS: "用户更新成功",
    ACCOUNT_USER_MSG_DELETE_SUCCESS: "用户删除成功",
    ACCOUNT_USER_MSG_DELETE_CONFIRM: "用户删除确认吗？",
    ACCOUNT_USER_MSG_NOT_FOUND: "该用户不存在",
    ACCOUNT_USER_MSG_DISABLED: "该用户未激活",

    // 组织管理-组织
    ORG_ORGANIZATION_ROUTE_LIST: "组织一览",
    ORG_ORGANIZATION_TITLE_CREATE: "创建新组织",
    ORG_ORGANIZATION_TITLE_UPDATE: "更新组织",
    ORG_ORGANIZATION_TITLE_DELETE: "删除组织",
    ORG_ORGANIZATION_FIELD_PARENT: "上级组织",
    ORG_ORGANIZATION_FIELD_CODE: "编号",
    ORG_ORGANIZATION_FIELD_NAME: "名称",
    ORG_ORGANIZATION_FIELD_TYPE: "类型",
    ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT: "部门",
    ORG_ORGANIZATION_FIELD_TYPE_TEAM: "组",
    ORG_ORGANIZATION_FIELD_TYPE_REPAIRMAN: "维修员",
    ORG_ORGANIZATION_FIELD_TYPE_COMPANY: "公司",
    ORG_ORGANIZATION_FIELD_TYPE_REPAIR_UNIT: "维保公司",
    ORG_ORGANIZATION_FIELD_TYPE_EMPLOYEE: "工作人员",
    ORG_ORGANIZATION_FIELD_CONTACTS: "联系人",
    ORG_ORGANIZATION_FIELD_TELEPHONE: "联系电话",
    ORG_ORGANIZATION_FIELD_ADDRESS: "联系地址",
    ORG_ORGANIZATION_MSG_CREATE_SUCCESS: "组织创建成功",
    ORG_ORGANIZATION_MSG_UPDATE_SUCCESS: "组织更新成功",
    ORG_ORGANIZATION_MSG_DELETE_SUCCESS: "组织删除成功",
    ORG_ORGANIZATION_MSG_DELETE_CONFIRM: "组织删除确认吗？",
    ORG_ORGANIZATION_MSG_CODE_DUPLICATE: "组织编号重复",

    // 组织管理-员工
    ORG_EMPLOYEE_ROUTE_LIST: "系统用户",
    ORG_EMPLOYEE_TITLE_CREATE: "员工创建",
    ORG_EMPLOYEE_TITLE_UPDATE: "员工更新",
    ORG_EMPLOYEE_TITLE_DELETE: "员工删除",
    ORG_EMPLOYEE_FIELD_CODE: "工号",
    ORG_EMPLOYEE_FIELD_FULL_NAME: "姓名",
    ORG_EMPLOYEE_FIELD_MOBILE: "手机号",
    ORG_EMPLOYEE_FIELD_ORGANIZATION: "组织",
    ORG_EMPLOYEE_FIELD_ORGANIZATION_PARENT: "上级组织",
    ORG_EMPLOYEE_FIELD_LAST_NAME: "姓",
    ORG_EMPLOYEE_FIELD_FIRST_NAME: "名",
    ORG_EMPLOYEE_FIELD_EMAIL: "邮箱",
    ORG_EMPLOYEE_FIELD_EMAIL_VERIFY_CODE: "Verify Code",
    ORG_EMPLOYEE_BUTTON_SEND_VERIFY_EMAIL_CODE: "Send verify code",
    ORG_EMPLOYEE_BUTTON_REGISTER: "Register",
    ORG_EMPLOYEE_MSG_EMAIL_DUPLICATE: "员工邮箱重复。",
    ORG_EMPLOYEE_MSG_MOBILE_FORMAT_WRONG: "手机号格式不正确。",
    ORG_EMPLOYEE_MSG_EMAIL_FORMAT_WRONG: "员工邮箱格式不正确。",
    ORG_EMPLOYEE_MSG_REGISTER_SUCCESS: "注册成功",
    ORG_EMPLOYEE_MSG_CREATE_SUCCESS: "员工创建成功",
    ORG_EMPLOYEE_MSG_UPDATE_SUCCESS: "员工更新成功",
    ORG_EMPLOYEE_MSG_DELETE_SUCCESS: "员工删除成功",
    ORG_EMPLOYEE_MSG_DELETE_CONFIRM: "员工删除确认吗？",

    // 微信服务
    WECHAT_AUTH_TITLE_USER_BIND: "用户绑定",
    WECHAT_AUTH_FIELD_USER_BIND_EMAIL: "绑定邮箱",
    WECHAT_AUTH_FIELD_USER_BIND_EMAIL_PLACEHOLDER: "请输入要绑定的用户邮箱",
    WECHAT_AUTH_FIELD_USER_BIND_MOBILE: "绑定手机号",
    WECHAT_AUTH_FIELD_USER_BIND_MOBILE_PLACEHOLDER: "请输入要绑定的用户手机号",
    WECHAT_AUTH_BUTTON_USER_BIND: "用户绑定",
    WECHAT_AUTH_MSG_USER_NOT_BIND: '当前用户未绑定微信',
    WECHAT_AUTH_MSG_USER_BIND_SUCCESS: '用户绑定成功',

    // 站点
    CMS_SITE_ROUTE_SITE_LIST: "站点管理",
    CMS_SITE_CREATE_MODAL_TITLE: "创建新站点",
    CMS_SITE_UPDATE_MODAL_TITLE: "更新站点",
    CMS_SITE_DELETE_MODAL_TITLE: "删除站点",
    CMS_SITE_FIELD_CODE: "编号",
    CMS_SITE_FIELD_TITLE: "名称",
    CMS_SITE_FIELD_URL: "URL",
    CMS_SITE_FIELD_REMARKS: "备注",
    CMS_SITE_MSG_CODE_REQUIRED: "站点编号不能为空",
    CMS_SITE_MSG_TITLE_REQUIRED: "站点标题不能为空",
    CMS_SITE_MSG_URL_REQUIRED: "站点地址不能为空",
    CMS_SITE_MSG_DELETE_CONFIRM: "站点删除后无法恢复，确认吗？",
    CMS_SITE_MSG_CREATE_SUCCESS: "站点创建成功",
    CMS_SITE_MSG_UPDATE_SUCCESS: "站点更新成功",
    CMS_SITE_MSG_DELETE_SUCCESS: "站点删除成功",

    // 栏目
    CMS_CHANNEL_ROUTE_CHANNEL_LIST: "栏目管理",
    CMS_CHANNEL_TITLE_CREATE: "创建新栏目",
    CMS_CHANNEL_TITLE_UPDATE: "更新栏目",
    CMS_CHANNEL_DELETE_MODAL_TITLE: "删除栏目",
    CMS_CHANNEL_FIELD_PARENT: "父栏目",
    CMS_CHANNEL_FIELD_CODE: "编号",
    CMS_CHANNEL_FIELD_TITLE: "标题",
    CMS_CHANNEL_FIELD_TITLE_DESC: "请输入栏目标题关键字",
    CMS_CHANNEL_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CHANNEL_FIELD_TITLE_IMAGE_PREVIEW: "标题图片预览",
    CMS_CHANNEL_FIELD_SEQUENCE: "序号",
    CMS_CHANNEL_FIELD_REMARKS: "备注",
    CMS_CHANNEL_MSG_CODE_REQUIRED: "栏目编号不能为空",
    CMS_CHANNEL_MSG_CODE_DUPLICATE: "栏目编号重复",
    CMS_CHANNEL_MSG_TITLE_REQUIRED: "栏目标题不能为空",
    CMS_CHANNEL_MSG_CREATE_SUCCESS: "栏目创建成功",
    CMS_CHANNEL_MSG_DELETE_CONFIRM: "栏目删除后无法恢复，确认吗？",

    // 内容
    CMS_CONTENT_ROUTE_CONTENT_MANAGEMENT: "内容管理",
    CMS_SOFTWARE_ROUTE_SOFTWARE_MANAGEMENT: "软件管理",
    CMS_SOFTWARE_ROUTE_VERSION_MANAGEMENT: "版本管理",
    CMS_SOFTWARE_ROUTE_LATEST_VERSION_DOWNLOAD: "最新版本下载",
    CMS_CONTENT_ROUTE_CONTENT_LIST: "内容列表",
    CMS_CONTENT_ROUTE_CONTENT_DRAFTS: "内容草稿箱",
    CMS_CONTENT_ROUTE_CONTENT_DOWNLOAD: "app日志下载",
    TPYS: "图形演示",
    TPYS2: "多功能图模块",
    TPYS3: "多功能图模块性能测试",
    CMS_CONTENT_ROUTE_CONTENT_DOWNLOAD_PRESSURE: "压力测试结果下载",
    CMS_CONTENT_ROUTE_CONTENT_CREATE: "创建新内容",
    CMS_CONTENT_ROUTE_CONTENT_UPDATE: "更新内容",
    CMS_CONTENT_TITLE_BASIC_INFO: "基本信息",
    CMS_CONTENT_TITLE_IMAGE_INFO: "标题图片",
    CMS_CONTENT_TITLE_SUMMARY_EDITOR: "摘要编辑",
    CMS_CONTENT_TITLE_BODY_EDITOR: "内容编辑",
    CMS_CONTENT_TITLE_EDITOR_MARKDOWN: "Markdown",
    CMS_CONTENT_TITLE_EDITOR_RICH: "富文本框",
    CMS_CONTENT_TITLE_FILE_LIST: "附件列表",
    CMS_CONTENT_DELETE_MODAL_TITLE: "删除内容",
    CMS_CONTENT_ATTACHMENT_MODAL_TITLE: "附件一览",
    CMS_CONTENT_FIELD_CHANNEL: "所属栏目",
    CMS_CONTENT_FIELD_CODE: "编号",
    CMS_CONTENT_FIELD_CLASSIFY: "分类",
    CMS_CONTENT_FIELD_TITLE: "标题",
    CMS_CONTENT_FIELD_TITLE_DESC: "请输入内容标题",
    CMS_CONTENT_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD: "标题图片上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT: "点击或拖拽到该区域上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT: "支持单个或批量上传",
    CMS_CONTENT_FIELD_TITLE_LAYOUT: "布局分类",
    CMS_CONTENT_FIELD_SUB_TITLE: "副标题",
    CMS_CONTENT_FIELD_TYPE: "类型",
    CMS_CONTENT_FIELD_SOURCE: "来源",
    CMS_CONTENT_FIELD_PUBLISH_TIME: "发布时间",
    CMS_CONTENT_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FIELD_BODY: "内容",
    CMS_CONTENT_MSG_CHANNEL_REQUIRED: "栏目必须选择",
    CMS_CONTENT_MSG_CODE_REQUIRED: "内容编号不能为空",
    CMS_CONTENT_MSG_CLASSIFY_REQUIRED: "内容分类不能为空",
    CMS_CONTENT_MSG_TITLE_REQUIRED: "内容标题不能为空",
    CMS_CONTENT_MSG_TYPE_REQUIRED: "内容类型不能为空",
    CMS_CONTENT_MSG_CREATE_SUCCESS: "内容创建成功",
    CMS_CONTENT_MSG_UPDATE_SUCCESS: "内容更新成功",
    CMS_CONTENT_MSG_DELETE_SUCCESS: "内容删除成功",
    CMS_CONTENT_MSG_DELETE_CONFIRM: "内容删除确认吗？",
    CMS_CONTENT_TYPE_ARTICLE: "文章",
    CMS_CONTENT_TITLE_LAYOUT_NO_PICTURE: "纯文字",
    CMS_CONTENT_TITLE_LAYOUT_RIGHT_PICTURE: "左字右图",
    CMS_CONTENT_TITLE_LAYOUT_LEFT_PICTURE: "左图右字",
    CMS_CONTENT_TITLE_LAYOUT_THREE_PICTURE: "上字下三图",
    CMS_CONTENT_TITLE_LAYOUT_BOTTOM_PICTURE: "上字下大图",
    CMS_CONTENT_TITLE_LAYOUT_SLIDE_SHOW: "轮播图",

    // 内容附件
    CMS_CONTENT_FILE_TITLE: "内容附件",
    CMS_CONTENT_FILE_TITLE_CREATE_MODAL: "创建内容附件",
    CMS_CONTENT_FILE_TITLE_DELETE_MODAL: "删除内容附件",
    CMS_CONTENT_FILE_FIELD_PREVIEW: "预览",
    CMS_CONTENT_FILE_FIELD_NAME: "名称",
    CMS_CONTENT_FILE_FIELD_PATH: "路径",
    CMS_CONTENT_FILE_FIELD_SEQUENCE: "序号",
    CMS_CONTENT_FILE_FIELD_TYPE: "类型",
    CMS_CONTENT_FILE_FIELD_SIZE: "大小",
    CMS_CONTENT_FILE_FIELD_REMARKS: "备注",
    CMS_CONTENT_FILE_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FILE_FIELD_FILE_VERSION: "版本号",
    CMS_CONTENT_FILE_FIELD_FILE_REMARKS: "备注说明",
    CMS_CONTENT_FILE_MSG_NAME_REQUIRED: "附件名称不能为空",
    CMS_CONTENT_FILE_MSG_PATH_REQUIRED: "附件路径不能为空",
    CMS_CONTENT_FILE_MSG_SEQUENCE_REQUIRED: "附件序号不能为空",
    CMS_CONTENT_FILE_MSG_FILE_VERSION_REQUIRED: "附件版本号不能为空",
    CMS_CONTENT_FILE_MSG_REMARKS_REQUIRED: "附件备注不能为空",

    // 监控
    MONITOR_ROUTE_MANAGEMENT: "监控",
    MONITOR_ROUTE_REGION: "区域监控",

    // 设备仪表盘
    DAS_DASHBOARD_ROUTE_MANAGEMENT: "仪表盘",
    DAS_DASHBOARD_ROUTE_STATISTICS: "平台统计",
    DAS_DASHBOARD_ROUTE_MONITOR: "监控台",
    DAS_DASHBOARD_ROUTE_NCOV: "COVID-19疫情发布",
    DAS_DASHBOARD_ROUTE_NCOV_IMPORT: "COVID-19数据导入",
    DAS_DASHBOARD_ROUTE_DISTRICT_MAP: "区域地图",
    DAS_DASHBOARD_REGION: "地区",

    // 其他
    REST_ROUTE_MANAGEMENT: "其他",
    REST_ROUTE_NCOV: "COVID-19疫情发布",
    REST_ROUTE_NCOV_IMPORT: "COVID-19数据导入",

    // 心电采集终端设备
    DAS_DEVICE_ROUTE_MANAGEMENT: "设备管理",
    DAS_DEVICE_ROUTE_LIST: "设备列表", // 原 设备监控（未完成）
    DAS_DEVICE_DETAIL: "设备详细",
    DAS_DEVICE_ROUTE_MONITOR: "设备定位", // 原 设备列表
    DAS_DEVICE_FIELD_CODE: "设备编号",
    DAS_HISTORY_ITERA: "历代版本",
    DAS_DEVICE_FIELD_NAME: "设备名称",
    DAS_DEVICE_FIELD_COLOR: "设备颜色",
    DAS_DEVICE_FIELD_COLOR_WHITE: "白色",
    DAS_DEVICE_FIELD_COLOR_BLUE: "蓝色",
    DAS_DEVICE_FIELD_DSP_VERSION: "DSP版本号",
    DAS_DEVICE_FIELD_ESP_VERSION: "ESP32版本号",
    DAS_DEVICE_FIELD_MAC: "设备MAC地址",
    DAS_DEVICE_FIELD_ACTIVE_FLAG: "激活状态",
    DAS_DEVICE_FIELD_TRANSPORT_FLAG: "传输状态",
    DAS_DEVICE_FIELD_ACTIVE_FLAG_TRUE: "已激活",
    DAS_DEVICE_FIELD_ACTIVE_FLAG_FALSE: "未激活",
    DAS_DEVICE_FIELD_ACTIVE_TIME: "激活时间",
    DAS_DEVICE_FIELD_LATEST_USAGE_TIME: "最近使用时间",
    DAS_DEVICE_FIELD_DEVICES_SUM: "登记设备总数",
    DAS_DEVICE_FIELD_DEVICES_ACTIVE_SUM: "已激活设备数",
    DAS_DEVICE_FIELD_DEVICES_TIME: "设备时间",
    DAS_DEVICE_FIELD_TIME_DIFF: "和服务器时间偏差",
    DAS_DEVICE_FIELD_SAMPLE_RATE: "采样率",
    DAS_DEVICE_FIELD_SAMPLE_RESOLUTION: "保存文件的数据精度",
    DAS_DEVICE_FIELD_BATT_VOLUME: "电池电量",
    DAS_DEVICE_FIELD_CHARGE_STATE: "是否在充电",
    DAS_DEVICE_FIELD_CHARGE_STATE_NOT: "不在充电",
    DAS_DEVICE_FIELD_CHARGE_STATE_ING: "在充电",
    DAS_DEVICE_FIELD_CHARGE_STATE_NO_BATT: "没有电池",
    DAS_DEVICE_FIELD_HEAD_SAME: "前方一致",
    DAS_DEVICE_FIELD_TAIL_SAME: "后方一致",
    DAS_DEVICE_FIELD_CONTAIN: "包含",
    DAS_DEVICE_FIELD_BATCH_SEARCH: "批量检索",
    DAS_DEVICE_FIELD_SYS_STATE: "工作状态",
    DAS_DEVICE_FIELD_SYS_STATE_NOT: "不工作",
    DAS_DEVICE_FIELD_SYS_STATE_RESET: "上电复位",
    DAS_DEVICE_FIELD_SYS_STATE_WORK: "工作",
    DAS_DEVICE_FIELD_SYS_STATE_SLEEP: "休眠",
    DAS_DEVICE_FIELD_BATTERY_VOLTAGE: "电池电压",
    DAS_DEVICE_FIELD_TEMP_BQ27441: "MPU6050芯片的温度",
    DAS_DEVICE_FIELD_TEMP_BLE: "ESP32芯片温度",
    DAS_DEVICE_FIELD_TEMP_BAT: "电池温度",
    DAS_DEVICE_FIELD_ONLINE_STATUS: "在线状态",
    DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE: "在线传输中",
    DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE_NO_TRANSFER: "在线未传输",
    DAS_DEVICE_FIELD_ONLINE_STATUS_OFFLINE: "离线",
    DAS_DEVICE_FIELD_ONLINE_STATE_OFFLINE: "未在线",
    DAS_DEVICE_FIELD_ONLINE_STATE_ONLINE: "在线",
    DAS_DEVICE_FIELD_LOCATION: "定位",
    DAS_DEVICE_FIELD_LOCATION_CURRENT: "当前",
    DAS_DEVICE_FIELD_LOCATION_TRAIL: "轨迹",
    DAS_DEVICE_FIELD_WEB_SUBSCRIBER_NUM: "在线WEB监护者数",
    DAS_DEVICE_FIELD_APP_SUBSCRIBER_NUM: "在线监护人数",
    DAS_DEVICE_FIELD_FILE_UPLOAD_PERCENT: "历史数据上传完成率",
    DAS_DEVICE_FIELD_FILE_WIFI_NUM: "WIFI设定组数",
    DAS_DEVICE_FIELD_FILE_LEAD_STATE: "电极状态",
    DAS_DEVICE_FIELD_FILE_LEAD_STATE_ON: "正常",
    DAS_DEVICE_FIELD_FILE_LEAD_STATE_OFF: "脱落",
    DAS_DEVICE_FIELD_FILE_LAST_REDIS_UPDATE_TIME: "更新时间",
    DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_TIME: "上次历史数据接收的时间",
    DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_SOURCE: "上次历史数据接收的来源",
    DAS_DEVICE_FIELD_FILE_LOG_LEVEL: "日志级别",
    DAS_DEVICE_DEVICE_NUMBER: "设备数",
    DAS_DEVICE_DEVICE_RUNNING: "正在运行",
    DAS_DEVICE_DEVICE_UNUSED_ACTIVE: "已激活未使用",
    DAS_DEVICE_FIELD_DEV_LIVE_ONLINE_STATUS: "设备实时传输",
    DAS_DEVICE_FIELD_DEV_HIS_ONLINE_STATUS: "设备历史传输",
    DAS_DEVICE_FIELD_APP_LIVE_ONLINE_STATUS: "手机实时传输",
    DAS_DEVICE_FIELD_APP_HIS_ONLINE_STATUS: "手机历史传输",
    DAS_DEVICE_TITLE_DETAIL: "设备详情",
    DAS_DEVICE_ABNORMAL_FILE: "异常事件",
    DAS_DEVICE_TITLE_BASIC: "基本信息",
    DAS_DEVICE_TITLE_SYSTEM: "系统数据",
    DAS_DEVICE_TITLE_USAGE: "使用情况",
    DAS_DEVICE_TITLE_BINDER: "用户信息",
    DAS_DEVICE_TITLE_CREATE: "新注册设备信息",
    DAS_DEVICE_TITLE_UPDATE: "更新设备信息",
    DAS_DEVICE_TITLE_DELETE: "删除设备信息",
    DAS_DEVICE_TITLE_FIELD_SELECT: "显示字段选择",
    DAS_DEVICE_TITLE_LOG: "日志",
    DAS_DEVICE_TITLE_MAP: "移动轨迹",
    DAS_DEVICE_TITLE_SELECT: "设备选择",
    DAS_DEVICE_MSG_MAC_FORMAT_WRONG: "设备MAC地址格式错误",
    DAS_DEVICE_MSG_NOT_FOUND: "未找到匹配设备",
    DAS_DEVICE_MSG_REQUIRED: "请输入设备编号",
    DAS_DEVICE_ECG_MSG_NOT_FOUND: "未获取到设备心电数据",
    DAS_DEVICE_MSG_DATA_INIT_CONFIRM: "该设备的数据将从缓存中清空，确定吗？",
    DAS_DEVICE_MSG_DATA_INIT_SUCCESS: "数据初始化成功",
    DAS_DEVICE_BUTTON_FIELD_SELECT: "显示字段",
    DAS_DEVICE_BUTTON_FIELD_SELECT_RESET: "重置字段",
    DAS_DEVICE_BUTTON_DATA_INIT: "数据初始化",
    DAS_DEVICE_BUTTON_DATA_CACHE: "缓存数据",
    ECG_DEVICE_BUTTON_SELECT_DEVICE: "选择设备",
    ECG_HISTORY_ROUTE_CALENDAR: "历史心电图",
    ECG_HISTORY_DATA_FILE_DOWNLOAD: "历史数据文件下载",
    ECG_DATA_SERVER_DOWNLOAD: "DataServer日志下载",
    DAS_DEVICE_FILE_TYPE: "文件类型",
    DAS_DEVICE_FILE_CODE_START: "下载文件起始编号",
    DAS_DEVICE_FILE_CODE_END: "下载文件末尾编号",
    DAS_DEVICE_FILE_COUNT: "下载文件数量",
    DAS_DEVICE_FILE_BOUND: "已读文件编号范围（自动刷新）",
    DAS_DEVICE_FILE_BOUND_LAST: "最新文件编号范围（自动刷新）",
    DAS_DEVICE_FILE_TERMINAL_SOCKET: "通过Terminal Socket下载",
    DAS_DEVICE_FILE_ROOT_PATH: "D9K文件根目录",
    DAS_DEVICE_FILE_TERMINAL_SOCKET_DOWNLOAD: "点击下载Terminal Socket",
    DAS_DEVICE_FILE_SOURCE: "数据源",
    DAS_DEVICE_FILE_VERSION: "世代编号",

    //数据下载
    DAS_DEVICE_DATA_DOWNLOAD: "数据下载",

    // 设备用户管理
    DAS_DEVICE_BINDER_ROUTE_MANAGEMENT: "用户管理",
    DAS_DEVICE_BINDER_TITLE_DELETE: "解绑设备",
    DAS_DEVICE_BINDER_FIELD_BIND_FLAG: "是否绑定",
    DAS_DEVICE_BINDER_FIELD_BIND_TIME: "绑定时间",
    DAS_DEVICE_BINDER_FIELD_DEVICE_CODE: "绑定设备号",
    DAS_DEVICE_BINDER_BUTTON_UNBIND: "解绑",
    DAS_DEVICE_BINDER_MSG_UNBIND_CONFIRM: "确定要解绑该设备吗？",
    DAS_DEVICE_BINDER_MSG_UNBIND_SUCCESS: "解绑成功",

    ECG_BINDER_MOBILE: "用户手机信息",

    DAS_TERMINAL_IP: "终端IP",
    DAS_FILE_TYPE: "文件类型",
    DAS_PROCESS_LIST: "进程",

    // 设备绑定者
    ECG_BINDER_ROUTE_BINDERS: "设备注册者信息", // 原 设备用户
    ECG_BINDER_ROUTE_GUARDERS: "监护人信息", // 原 监护人
    ECG_BINDER_ROUTE_BINDERS_DATA: "心电图管理",
    ECG_BINDER_TITLE_DEVICES: "绑定设备列表",
    ECG_BINDER_TITLE_DEVICE_BIND: "绑定设备",
    ECG_BINDER_TITLE_DEVICE_UNBIND: "解绑设备",
    ECG_BINDER_TITLE_GUARDERS: "绑定监护人列表",
    ECG_BINDER_TITLE_GUARDER_BIND: "绑定监护人",
    ECG_BINDER_TITLE_GUARDER_UNBIND: "解绑监护人",
    ECG_BINDER_TITLE_LIST: "设备注册用户",
    ECG_BINDER_FIELD_USER_NAME: "姓名",
    ECG_BINDER_FIELD_USER_NICKNAME: "昵称",
    ECG_BINDER_FIELD_USER_MOBILE: "手机号",
    ECG_BINDER_FIELD_USER_MOBILES: "在线手机",
    ECG_BINDER_FIELD_USER_EMAIL: "邮箱",
    ECG_BINDER_FIELD_USER_PORTRAIT: "头像",
    ECG_BINDER_FIELD_USER_SEX: "性别",
    ECG_BINDER_FIELD_USER_SEX_UNKNOWN: "未知",
    ECG_BINDER_FIELD_USER_SEX_MALE: "男性",
    ECG_BINDER_FIELD_USER_SEX_FEMALE: "女性",
    ECG_BINDER_FIELD_USER_BIRTHDAY: "生日",
    ECG_BINDER_FIELD_USER_HEIGHT: "身高",
    ECG_BINDER_FIELD_USER_WEIGHT: "体重",
    ECG_BINDER_FIELD_DEVICE_SUM: "绑定设备台数",
    ECG_BINDER_FIELD_GUARDER_SUM: "监护人",
    ECG_BINDER_FIELD_WARD_SUM: "被监护人",
    ECG_BINDER_FIELD_USER_HABITS: "用户使用习惯",
    ECG_BINDER_BUTTON_DEVICES: "绑定设备",
    ECG_BINDER_BUTTON_GUARDERS: "绑定监护人",
    ECG_BINDER_BUTTON_BIND_DEVICE: "绑定新设备",
    ECG_BINDER_BUTTON_BIND_GUARDER: "绑定新监护人",
    ECG_BINDER_BUTTON_UNBIND: "解绑",
    ECG_BINDER_BUTTON_DEVICE_BIND_HISTORY: "设备绑定履历",
    ECG_BINDER_BUTTON_GUARDER_BIND_HISTORY: "监护人绑定履历",
    ECG_BINDER_MSG_BIND_SUCCESS: "绑定成功",
    ECG_BINDER_MSG_UNBIND_SUCCESS: "解绑成功",
    ECG_BINDER_MSG_UNBIND_CONFIRM: "确定要解绑吗？",
    ECG_BINDER_MSG_NOT_FOUND: "绑定者信息不存在",
    ECG_BINDER_MSG_BIND_GUARDER_DUPLICATE: "重复绑定监护人异常",
    ECG_BINDER_MSG_GUARDER_NOT_FOUND: "未找到所绑定的监护人",
    ECG_BINDER_ROUTE_USERS: "我的监护对象", // 当前 登录者

    //终端设备
    DAS_BINDER_TERMINAL_FUNCTION_LIST: "用户终端运行状况",

    //个人中心
    ECG_ONESELF_CENTER_INFO: "个人中心",
    ECG_BINDER_ROUTE_ONESELF: "我的设备信息",

    // 监护人
    ECG_GUARDER_TITLE_UNBIND: "解除监护角色",
    ECG_GUARDER_FIELD_USER_NAME: "姓名",
    ECG_GUARDER_FIELD_USER_MOBILE: "手机号",
    ECG_GUARDER_FIELD_USER_EMAIL: "邮箱",
    ECG_GUARDER_FIELD_USER_NICKNAME: "昵称",
    ECG_GUARDER_FIELD_USER_SEX: "性别",
    ECG_GUARDER_TITLE_BINDERS: "被监护人列表",
    ECG_GUARDER_BUTTON_BINDERS: "被监护人",
    ECG_GUARDER_BUTTON_UNBIND: "解绑监护",
    ECG_GUARDER_MSG_UNBIND_CONFIRM: "确定要解除监护角色吗？",
    ECG_GUARDER_MSG_UNBIND_SUCCESS: "解除监护角色成功",
    ECG_GUARDER_MSG_DELETE_FORBIDDEN_WITH_BINDERS: "删除禁止，因为该监护人正在监护心电设备用户。",

    // 心电图
    DAS_ECG_ROUTE_MANAGEMENT: "心电图",
    DAS_ECG_ROUTE_MINE: "我的心电图",
    DAS_ECG_ROUTE_REAL_TIME: "实时心电图",
    DAS_ECG_DATA_STATISTICS: "文件上传状态",
    DAS_ECG_ROUTE_HISTORY: "历史心电图",
    DAS_ECG_ROUTE_LORENZ: "数据统计",
    DAS_ECG_ROUTE_SAMPLE_LORENZ: "数据统计sample",
    DAS_ECG_USER_RECORD: "用户记录",
    DAS_ECG_ROUTE_HISTORY_DATE: "设备日历",
    DAS_ECG_ROUTE_PHYSIONET: "Physionet",
    DAS_ECG_ROUTE_EVENTS: "异常事件",
    DAS_ECG_ROUTE_REPORT: "健康报告",
    ECG_FILE_ROUTE_CALENDAR: "历史心电图",
    ECG_FILE_TITLE_WARN_LOG: "实时心电图警告日志",
    DAS_ECG_FIELD_DIAGNOSIS_TIME: "诊断时间",
    ECG_FILE_FIELD_DIAGNOSIS_START_TIME: "诊断开始时间",
    ECG_FILE_FIELD_DIAGNOSIS_END_TIME: "诊断结束时间",
    ECG_FILE_FIELD_SOURCE: "来源",
    ECG_FILE_FIELD_SOURCE_DEVICE: "设备",
    ECG_FILE_FIELD_SOURCE_MOBILE: "手机",
    ECG_FILE_FIELD_SOURCE_SERVER: "服务器",
    DAS_ECG_FIELD_FILE_PATH: "文件路径",
    DAS_ECG_FIELD_CHART_SPEED_HALF: "12.5mm/s",
    DAS_ECG_FIELD_CHART_SPEED_ONE: "25mm/s",
    DAS_ECG_FIELD_CHART_SPEED_TWO: "50mm/s",
    DAS_ECG_FIELD_CHART_SPEED_FOUR: "100mm/s",
    DAS_ECG_FIELD_CHART_SPEED_EIGHT: "200mm/s",
    DAS_ECG_FIELD_VOLT_DIV_1_1024: "1/1024缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_512: "1/512缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_256: "1/256缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_128: "1/128缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_64: "1/64缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_32: "1/32缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_16: "1/16缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_8: "1/8缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_4: "1/4缩放",
    DAS_ECG_FIELD_VOLT_DIV_1_2: "1/2缩放",
    DAS_ECG_FIELD_VOLT_DIV_1: "标准电压",
    DAS_ECG_FIELD_VOLT_DIV_2: "2倍缩放",
    DAS_ECG_FIELD_VOLT_DIV_4: "4倍缩放",
    DAS_ECG_FIELD_VOLT_DIV_8: "8倍缩放",
    DAS_ECG_FIELD_VOLT_DIV_16: "16倍缩放",
    DAS_ECG_FIVE_MINUTE: "5分钟",
    DAS_ECG_TEN_MINUTE: "10分钟",
    DAS_ECG_TWENTY_MINUTE: "20分钟",
    DAS_ECG_THIRTY_MINUTE: "30分钟",
    DAS_ECG_ONE_RESIZE: "x1",
    DAS_ECG_TWO_RESIZE: "x2",
    DAS_ECG_THREE_RESIZE: "x3",
    DAS_ECG_FOUR_RESIZE: "x4",

    DAS_ECG_CH_0: "导联",
    DAS_ECG_CH_1: "CH1",
    DAS_ECG_CH_2: "CH2",
    DAS_ECG_CH_3: "CH3",
    DAS_ECG_CH_4: "CH4",
    DAS_ECG_CH_5: "CH5",
    DAS_ECG_CH_6: "CH6",
    DAS_ECG_CH_7: "CH7",
    DAS_ECG_CH_8: "CH8",

    data: "数据类型",
    data1: "一维数据",
    data2: "二维数据",
    data3: "带字符的二维数据",

    thousand: "一千",
    tenthousand: "一万",
    onehundredthousand: "十万",
    million: "百万",

    DAS_ECG_MSG_STOP_REAL_TIME: "已停止获取实时心电图获取",
    DAS_ECG_MSG_PLAY_COMPLETE: "心电图播放完毕",
    DAS_ECG_FILE_MSG_FILE_NOT_FOUND: "没有找到心电图",
    DAS_ECG_FIELD_CHANNEL_TYPE_MOBILE: "手机APP",
    DAS_ECG_FIELD_CHANNEL_TYPE_DEVICE: "设备",

    // D9K Server的日志信息
    ECG_SERVER_LOG_FIELD_NAME: "名称",
    ECG_SERVER_LOG_FIELD_SIZE: "大小",
    ECG_SERVER_LOG_FIELD_DATE: "日期",
    ECG_SERVER_LOG_FIELD_LEVEL: "级别",
    ECG_SERVER_LOG_FIELD_THREAD_NO: "线程号",
    ECG_SERVER_LOG_FIELD_SERVICE_TYPE: "服务类型",
    ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DL: "设备实时数据业务",
    ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DH: "设备历史数据业务",
    ECG_SERVER_LOG_FIELD_SERVICE_TYPE_ML: "手机实时数据业务",
    ECG_SERVER_LOG_FIELD_SERVICE_TYPE_MH: "手机历史数据业务",
    ECG_SERVER_LOG_FIELD_SERVICE_ID: "服务ID",
    ECG_SERVER_LOG_FIELD_DEVICE_ID: "设备编号",
    ECG_SERVER_LOG_FIELD_STATUS: "状态",
    ECG_SERVER_LOG_FIELD_CLASS_NAME: "类名",
    ECG_SERVER_LOG_FIELD_LINE_NO: "行号",
    ECG_SERVER_LOG_FIELD_METHOD_NAME: "方法名",
    ECG_SERVER_LOG_FIELD_MESSAGE: "信息",
    ECG_SERVER_LOG_FIELD_EXCEPTION: "异常",
    ECG_DEVICE_LOG_MSG_NOT_FOUND: "该设备日志不存在",

    // 设备上传画面
    deviceImportTemplate: "设备导入模版下载",

    ECG_SYSTEM_ROUTE_REDIS: "系统redis信息",
    menuSysUserList: "系统用户管理",
    sysSetting: "系统设定",


    login: "登录",
    email: "邮箱",
    username: "用户姓名",
    mobile: "手机号",
    birthday: "生日",
    password: "密码",
    welcome: "欢迎",
    profile: "个人信息",
    resetPassword: "重设密码",
    logout: "注销",

    search: "查询",
    register: "注册",

    // 设备文件标注
    ECG_DEVICE_FILE_ANNOTATION_TITLE_ANNOTATION: "设备历史文件标注",
    ECG_DEVICE_FILE_ANNOTATION_TITLE_FRAME_SELECT: "Frame选择",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_DEVICE_CODE: "设备编号",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_RECORD_DATE: "记录日期",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_INDEX: "历史文件索引",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_POINT_INDEX: "特征点索引",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_ANNOTATION: "标注内容",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_REMARKS: "备注说明",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_START_POSITION: "起始位置",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_END_POSITION: "结束位置",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_NUMBER_BEATS: "心跳数",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_NUMBER_ANNOTATION: "标记数",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS: "标注状态",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS_NONE: "未标注",
    ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS_COMPLETE: "已标注",
    ECG_DEVICE_FILE_ANNOTATION_MSG_DEVICE_CODE_REQUIRED: "设备编号是必填项",
    ECG_DEVICE_FILE_ANNOTATION_MSG_RECORD_DATE_REQUIRED: "记录日期是必填项",
    ECG_DEVICE_FILE_ANNOTATION_MSG_HISTORY_FILE_INDEX_REQUIRED: "历史文件索引是必填项",
    ECG_DEVICE_FILE_ANNOTATION_MSG_POINT_INDEX_REQUIRED: "特征点索引是必填项",
    ECG_DEVICE_FILE_ANNOTATION_MSG_ANNOTATION_REQUIRED: "标注内容是必填项",

    // 病例管理
    ECG_CASE_ROUTE_MANAGEMENT: "病例管理",
    ECG_CASE_ROUTE_LIST: "分析记录",
    ECG_CASE_TITLE_CREATE: "创建病例",
    ECG_CASE_TITLE_UPDATE: "更新病例",
    ECG_CASE_FIELD_NAME: "姓名",
    ECG_CASE_FIELD_NUMBER: "病例号",
    ECG_CASE_FIELD_AGE: "年龄",
    ECG_CASE_FIELD_GENDER: "性别",
    ECG_CASE_FIELD_RECORD_TIME: "记录时间",
    ECG_CASE_FIELD_RECORD_START_TIME: "记录开始时间",
    ECG_CASE_FIELD_RECORD_END_TIME: "记录结束时间",
    ECG_CASE_FIELD_RECORD_DURATION: "记录长度",
    ECG_CASE_FIELD_ANALYSIS_DATE: "分析日期",
    ECG_CASE_FIELD_REPORT_DATE: "报告日期",
    ECG_CASE_FIELD_DEVICE_NUMBER: "设备编号",
    ECG_CASE_BUTTON_SELECT_BINDER: "选择用户",
    ECG_CASE_MSG_NUMBER_REQUIRED: "病例号是必填项",
    ECG_CASE_MSG_NAME_REQUIRED: "姓名是必填项",
    ECG_CASE_MSG_DEVICE_NAME_REQUIRED: "设备编号是必填项",

    // 标注工具
    ECG_ANNOTATION_ROUTE_TOOL: "标注工具",
    ECG_ANNOTATION_TITLE_BUTTON_READ_DATE_FILE: "读取日期索引文件",
    ECG_ANNOTATION_TITLE_FILE_SOURCE: "文件数据源",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE: "数据源",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_LOCAL: "D9K_本地",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_SERVER: "D9K_服务端",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_MIT: "MIT",
    ECG_ANNOTATION_FIELD_ROOT_FOLDER_SELECT: "选择根目录",
    ECG_ANNOTATION_FIELD_LOCAL_FILE_PERMISSION: "本地文件操作权限",
    ECG_ANNOTATION_FIELD_LOCAL_FILE_PERMISSION_HELP: "本地文件操作权限（离线心电数据处理，需要本地文件的读写权限，由Terminal Socket程序支持）",
    ECG_ANNOTATION_FIELD_DATABASE_ROOT: "D9K心电数据库根目录",
    ECG_ANNOTATION_FIELD_DATABASE_ROOT_HELP: "D9K的文件夹结构需保持和服务器上一直，设定了根目录以后，程序会按照此文件结构快速定位相关文件",
    ECG_ANNOTATION_FIELD_DEVICE_CODE: "设备编号",
    ECG_ANNOTATION_FIELD_DEVICE_CODE_HELP: "如设备编号是72010000，则程序会去扫描 数据库根目录 72010000的文件，日期索引文件会第一个被扫描到，然后开始依次解析",
    ECG_ANNOTATION_FIELD_ANNOTATION_FILE: "标注文件",
    ECG_ANNOTATION_FIELD_ECG_FILE: "心电文件",
    ECG_ANNOTATION_FIELD_DATE_INDEX_FILE: "日期索引文件",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NORMAL: "窦性正常搏动(N)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LBBB: "左束支阻滞搏动(L)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RBBB: "右束支阻滞搏动(R)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BBB: "左或右束支阻滞(B)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_APC: "房性早搏搏动(A)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ABERR: "融合性房性早搏搏动(a)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NPC: "交界性早搏搏动(J)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVPB: "室上性早搏或逸搏性搏动(S)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PVC: "室性早搏搏动(V)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RONT: "R-on-T现象(r)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FUSION: "室性与窦性正常搏动的融合性搏动(F)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AESC: "房性逸搏搏动(e)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NESC: "交界性逸搏搏动(j)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVESC: "交界性逸搏搏动(n)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VESC: "室性逸搏搏动(E)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACE: "起搏刺激搏动(/)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PFUS: "起搏刺激与窦性正常搏动的融合性搏动(f)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UNKNOWN: "未能分类的搏动(Q)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LEARN: "学习(?)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFON: "心室扑动/颤动的开始([)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FLWAV: "心室扑动波(!)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFOFF: "心室扑动/颤动的结束(])",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NAPC: "非传导的p波（窦房传导阻滞性P波）(x)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFON: "波形的开始(()",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFOFF: "波形的结束())",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PWAVE: "p波峰(p)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TWAVE: "T波峰(t)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UWAVE: "U波峰(u)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PQ: "p和q波连接结点（qrs波开始点）(`)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_JPT: "J点（qrs波结束点）(')",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACESP: "未传导起搏器(^)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ARFCT: "单列的似QRS波(|)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOISE: "信号质量变化(~)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RHYTHM: "节律变化(+)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_STCH: "ST段变化(s)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TCH: "T波变化(T)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SYSTOLE: "心脏收缩期(*)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_DIASTOLE: "心脏舒张期(D)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_MEASURE: "测量注释(=)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOTE: "意见注释(\"\")",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LINK: "链接到外部数据（aux包含url）(@)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AB: "心房肥大((AB)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFIB: "心房颤动((AFIB)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFL: "心房扑动((AFL)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_B: "室早二联律((B)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BII: "II度房室传导阻滞((BII)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_IVR: "心室自主心律((IVR)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_N: "正常窦性心律((N)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOD: "交界性自主心律((NOD)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_P: "起搏刺激节律((P)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PREX: "预激综合征（WPW）((PREX)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SBR: "窦性心动过缓((SBR)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVTA: "室上性快速性心律失常((SVTA)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_T: "室早三联律((T)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFL: "心室扑动((VFL)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VT: "室性心动过速((VT)",
    ECG_ANNOTATION_MSG_DATABASE_ROOT_REQUIRED: "D9K心电数据库根目录不能为空",
    ECG_ANNOTATION_MSG_DEVICE_NUMBER_REQUIRED: "设备编号不能为空",

    //医生端
    DOCTOR_LIST: "D9K医生端",
    DOC_DIAGNOSTIC_RECORD: "数据分析",
    DOCTOR_RECORD_MESSAGE: "记录消息",
    DOCTOR_EVENT: "事件",
    DOCTOR_TAGGING: "标注",
    DOCTOR_REPORT: "报告",
    ECG_CASE_ROUTE_PHYSICIANS_REPORT: "医师报告",

    // 设备绑定历史
    ECG_DEVICE_BIND_HISTORY_FIELD_BINDER_NAME: "绑定者",
    ECG_DEVICE_BIND_HISTORY_FIELD_DEVICE_CODE: "设备编号",
    ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE: "操作类型",
    ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_BIND: "绑定",
    ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_UNBIND: "解绑",
    ECG_DEVICE_BIND_HISTORY_FIELD_CREATE_TIME: "创建时间",

    // 监护人绑定历史
    ECG_GUARDER_BIND_HISTORY_FIELD_BINDER_NAME: "绑定者",
    ECG_GUARDER_BIND_HISTORY_FIELD_GUARDER_NAME: "监护人",
    ECG_GUARDER_BIND_HISTORY_FIELD_OPERATION_TYPE: "操作类型",
    ECG_GUARDER_BIND_HISTORY_FIELD_OPERATION_TYPE_BIND: "绑定",
    ECG_GUARDER_BIND_HISTORY_FIELD_OPERATION_TYPE_UNBIND: "解绑",
    ECG_GUARDER_BIND_HISTORY_FIELD_CREATE_TIME: "创建时间",

    //求助信息
    ECG_WARN_BUTTON_SEARCH: "历史查询",
    ECG_WARN_SEARCH_USAGE_TIME: "求助时间范围",
    ECG_WARN_SEARCH_USER: "用户名",
    ECG_WARN_SEARCH_DEVICE_CODE: "设备编号",
    ECG_WARN_SEARCH_PHONE_NUMBER: "手机号",
    ECG_WARN_SEARCH_GUARDER_MODEL: "监护模式",

    //医师报告
    ECG_DEVICE_DOCTOR_DIAGNOSIS_STATE:"诊断状态",
    ECG_DEVICE_DOCTOR_DIAGNOSIS_NAME:"诊断医师",
    ECG_DEVICE_DOCTOR_HOSPITAL:"医院",
    ECG_DEVICE_DOCTOR_DIAGNOSIS_CONTENT:"诊断内容",
    ECG_DEVICE_DOCTOR_DIAGNOSIS_TIME:"诊断时间",
    ECG_DEVICE_DOCTOR_DIAGNOSIS_RESULT:"诊断结论（患者可浏览）",
    ECG_DEVICE_DOCTOR_REMARKS:"备注（患者不可浏览）",
};
export default zh_CN;