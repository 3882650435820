/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input, Checkbox, DatePicker} from 'antd';

import {refreshSearchCondition, searchGuardians} from '../../../actions/GuarderAction';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import SearchDrawer from "../../../../../components/SearchDrawer";
import {refreshScreenMap} from "../../../actions/DeviceAction";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
        this.props.dispatch(refreshSearchCondition({}));
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchGuardians(searchCondition, this.state.formatMessage));
        const screenMap = this.props.screenMap;
        const pathname = "/das/guardians";
        screenMap[pathname] = true;
        if (screenMap && screenMap[pathname]){
            screenMap[pathname] = !screenMap[pathname];
        }else {
            screenMap[pathname] = true;
        }
        const searchMapTemp = Object.assign({}, screenMap);
        this.props.dispatch(refreshScreenMap(searchMapTemp));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;
        return (
            <SearchDrawer placement={"top"}>
                <Form style={{backgroundColor: "#ffffcc"}}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_GUARDER_FIELD_USER_NAME'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.guarderName} onChange={(value) => {
                                    this._handleChange("guarderName", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_GUARDER_FIELD_USER_MOBILE'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.guarderMobile} onChange={(value) => {
                                    this._handleChange("guarderMobile", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" icon="search"
                                    onClick={() => this._handleSearch(this.props.searchCondition)}>
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.GuardianReducer.searchCondition,
        pagination: store.GuardianReducer.pagination,
        screenMap: store.EcgDeviceReducer.screenMap
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));