/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    cities: [],
    city: {},
    cityMap: {}, // key: cityId, value: city
    showDetailModal: false
};

const CityReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_CITY_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_CITY_SEARCH:
            return Object.assign({}, state, {
                cities: action.cities
            });
        case ActionTypes.DISTRICT_CITY_REFRESH_CITY:
            return Object.assign({}, state, {
                city: action.city
            });
        case ActionTypes.DISTRICT_CITY_REFRESH_CITY_MAP:
            return Object.assign({}, state, {
                cityMap: action.cityMap
            });
        case ActionTypes.DISTRICT_CITY_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default CityReducer;