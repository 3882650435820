/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    Modal,
    Button,
    Tabs, Popconfirm
} from "antd";

import moment from 'moment';

import {
    deleteDeviceRedis,
    getDeviceById, getRedisData,
    refreshDevice, toggleCacheModal,
    toggleDetailModal,
} from "../../../actions/DeviceAction";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";

import DetailModalDeviceTab from "./DetailModalDeviceTab";
import DeviceModalLogTab from "./DeviceModalLogTab";
import DeviceMapTab from "./DeviceMapTab";
// import CacheDeviceModal from "./CacheDeviceModal";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            editNameFlag: false,
            inputReadFileIndexFlag: false,
            inputReadFileIndex: 0
        }
    }

    componentWillMount() {
        // this.intervalRefresh = setInterval(() => {
        //     if (this.props.device.id && !this.props.isLoading) {
        //         this.props.dispatch(getDeviceById(this.props.device.id, this.state.formatMessage));
        //     }
        // }, 2000)
    }

    componentWillUnmount() {
        // clearInterval(this.intervalRefresh);
    }

    componentDidMount() {
    }

    _handleRefresh() {
        this.props.dispatch(getDeviceById(this.props.device.id, this.state.formatMessage));
    }

    _handleCancel() {
        this.props.dispatch(refreshDevice({}));
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleInit() {
        this.props.dispatch(deleteDeviceRedis(this.props.device.id, this.state.formatMessage));
    }

    _handleShowCacheModal() {
        this.props.dispatch(getRedisData(this.props.device.code, this.state.formatMessage));
        this.props.dispatch(toggleCacheModal(true));
    }

    render() {
        const {device} = this.props;

        const formatMessage = this.state.formatMessage;
        const lastUpdateTime = (device.lastUpdateTime && device.lastUpdateTime !== '') ?
            formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_REDIS_UPDATE_TIME"}) + "：" + moment(device.lastUpdateTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : "";

        return (
            <Modal
                title={formatMessage({id: "DAS_DEVICE_TITLE_DETAIL"})}
                maskClosable={false}
                visible={this.props.showDetailModal}
                onOk={() => this._handleRefresh()}
                onCancel={() => this._handleCancel()}
                width={1200} style={{top: '20px'}}
                footer={
                    <div>
                        <span style={{marginRight: '50px', fontSize: '16px'}}>
                            {lastUpdateTime}
                        </span>

                        {/*<Button key="back" onClick={() => this._handleCancel()}>*/}
                        {/*    <FormattedMessage id="COMMON_BUTTON_CLOSE"/>*/}
                        {/*</Button>*/}
                        <Button key="submit" type="primary" loading={this.props.isLoading}
                                onClick={() => this._handleRefresh()}>
                            <FormattedMessage id="COMMON_BUTTON_REFRESH"/>
                        </Button>
                        <Popconfirm title={this.state.formatMessage({id: "DAS_DEVICE_MSG_DATA_INIT_CONFIRM"})}
                                    onConfirm={() => this._handleInit()}>
                            <Button key="init">
                                <FormattedMessage id="DAS_DEVICE_BUTTON_DATA_INIT"/>
                            </Button>
                        </Popconfirm>
                        <Button key="cache" onClick={() => this._handleShowCacheModal()}>
                            <FormattedMessage id="DAS_DEVICE_BUTTON_DATA_CACHE"/>
                        </Button>
                    </div>
                }>
                {/*<CacheDeviceModal/>*/}
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_DETAIL'})} key="1">
                        <DetailModalDeviceTab/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_LOG'})} key="2">
                        <DeviceModalLogTab/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_MAP'})} key="3">
                        <DeviceMapTab/>
                    </Tabs.TabPane>
                </Tabs>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceReducer.isLoading,
        device: store.EcgDeviceReducer.device,
        showDetailModal: store.EcgDeviceReducer.showDetailModal,
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));