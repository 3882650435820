import React from 'react'
import ReactDOM from 'react-dom'
import Toast from './Toast'
import './Toast.css'

function createNotification() {
    const div = document.createElement('div')
    document.body.appendChild(div)
    let notification = ReactDOM.render(<Toast />, div)

    // return {
    //     addNotice(notice) {
    //         return notification.addNotice(notice)
    //     },
    //     destroy() {
    //         ReactDOM.unmountComponentAtNode(div)
    //         document.body.removeChild(div)
    //     }
    // }
    setTimeout(() => {
        var t = ReactDOM.render(<Toast />, div);
            // resolve(t)
            notification = t;
    })

    return new Promise ((resolve, reject) => {
        var t ;
        setTimeout(() => {
            t= {
                addNotice(notice) {
                    return notification.addNotice(notice)
                },
                destroy() {
                    ReactDOM.unmountComponentAtNode(div)
                    document.body.removeChild(div)
                }
            }
            resolve(t)
        })
    })

}

let notification
const notice = (type, content, duration = 2000, onClose) => {
    if (!notification) notification = createNotification()
    // return notification.addNotice({ type, content, duration, onClose })
    notification.then(res => {
        // console.log(res,'ccccccccc')
        return res.addNotice({ type, content, duration, onClose })
    })
}

export default {
    info(content, duration, onClose) {
        return notice('info', content, duration, onClose)
    },
    success(content = '操作成功', duration, onClose) {
        return notice('success', content, duration, onClose)
    },
    error(content, duration , onClose) {
        return notice('error', content, duration, onClose)
    },
    warn(content, duration , onClose) {
        return notice('info', content, duration, onClose)
    },
    loading(content = '加载中...', duration = 0, onClose) {
        return notice('loading', content, duration, onClose)
    }
}