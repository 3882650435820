import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    ecgDateIndexData: [],
    ecgTimeSlideMarks: {},
    ecgDataIndexEffectiveRecords: [],
    // 日期索引文件的Map表，Key：日期，Value：日期索引文件一天的记录
    ecgDateIndexRecordMap: {},
    calendarSearchCondition: {},
    ecgTimeIndexData: {},
    ecgEventIndexData: {},
    anchorMap: {},
    // 当前的时间索引梨的记录索引，历史文件和事件文件都需要根据这个索引号进行获取
    timeRecordIndex: 0,
    // 日历的空间原因，需要手动控制心电日历数据的刷新
    ecgDataIndexDataRefreshFlag: false,
    isVersionChange: false,
};

const EcgHistoryReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DAS_ECG_REFRESH_ANCHOR_MAP:
            return Object.assign({}, state, {
                anchorMap: action.anchorMap
            });
        case ActionTypes.ECG_HISTORY_REFRESH_TIME_RECORD_INDEX:
            return Object.assign({}, state, {
                timeRecordIndex: action.timeRecordIndex
            });
        case ActionTypes.DAS_ECG_HISTORY_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DAS_ECG_REFRESH_TIME_INDEX_DATA:
            return Object.assign({}, state, {
                ecgTimeIndexData: action.ecgTimeIndexData,
                ecgTimeSlideMarks: action.ecgTimeSlideMarks
            });
        case ActionTypes.ECG_HISTORY_REFRESH_DATE_INDEX_RECORD_MAP:
            return Object.assign({}, state, {
                ecgDateIndexRecordMap: action.ecgDateIndexRecordMap
            });
        case ActionTypes.DAS_ECG_EVENT_INDEX_DATA_SEARCH:
            return Object.assign({}, state, {
                ecgEventIndexData: action.ecgEventIndexData
            });
        case ActionTypes.DAS_ECG_DATE_INDEX_DATA_SEARCH:
            return Object.assign({}, state, {
                ecgDateIndexData: action.ecgDateIndexData,
                ecgDataIndexDataRefreshFlag: action.ecgDataIndexDataRefreshFlag
            });
        case ActionTypes.ECG_DEVICE_REFRESH_CALENDAR_SEARCH_CONDITION:
            return Object.assign({}, state, {
                calendarSearchCondition: action.calendarSearchCondition,
            });
        case ActionTypes.ECG_HISTORY_REFRESH_DATE_INDEX_EFFECTIVE_RECORDS:
            return Object.assign({}, state, {
                ecgDataIndexEffectiveRecords: action.ecgDataIndexEffectiveRecords
            });
        case ActionTypes.DAS_ECG_DATE_INDEX_DATA_REFRESH_FLAG:
            return Object.assign({}, state, {
                ecgDataIndexDataRefreshFlag: action.ecgDataIndexDataRefreshFlag
            });
        case ActionTypes.DAS_ECG_HISTORY_VERSION_CHANGE:
            return Object.assign({}, state, {
                isVersionChange: action.isVersionChange
            });
        default:
            return state
    }
};

export default EcgHistoryReducer;
