import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Layout} from 'antd';

import PageTitle from "../../../../components/PageTitle";


import DetailModal from "./DetailModal";

import ChannelTree from '../../components/ChannelTree';
import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';
import {
    refreshSearchCondition,
    searchChannels,
    selectTreeNode,
    searchSiteRootChannels,
    getChannelTree
} from "../../actions/ChannelAction";
import {CACHE_SITE_ID} from "../../../../constants/Profile";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    _handleTreeNodeSelect(selectedKey) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["channelIds"] = [selectedKey];
        this.props.dispatch(refreshSearchCondition(searchCondition));
        // this.props.dispatch(selectTreeNode(selectedKey));
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(searchChannels(siteId, searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <div className="page-content">
                <DetailModal/>
                <PageTitle title="CMS_CHANNEL_ROUTE_CHANNEL_LIST"/>
                <Layout style={{padding: '10px', background: '#fff'}}>
                    <Layout.Sider width={200}
                                  style={{background: '#fff', borderRight: '1px solid #e8e8e8', height: '100%'}}>
                        <ChannelTree channelTree={this.props.channelTree}
                                     onSelect={(selectedKey) => this._handleTreeNodeSelect(selectedKey)}/>
                    </Layout.Sider>
                    <Layout.Content style={{padding: '0 10px', minHeight: 280}}>
                        <SearchCondition {...this.props}/>
                        <SearchResult {...this.props}/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.ChannelReducer.channelTree,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));