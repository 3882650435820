import React, {Component} from 'react';
import G2, {Chart} from '@antv/g2';
import Slider from '@antv/g2-plugin-slider';
import './DateIndexOverviewChart.css'
import moment from 'moment';
import {FORMAT_DATE_SIMPLE} from "../../../../../../constants/DateTimeFormats";

export default class DateIndexOverviewChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rangeStartDate: "",
            rangeEndDate: ""
        }
    }

    // const ecgDateIndexData = props.ecgDateIndexData.records;
    // const diagnosisStartDate = props.location.query.recordDate;
    // const diagnosisStartDateFormat = diagnosisStartDate.slice(0, 4) + "-" + diagnosisStartDate.slice(4, 6) + "-" + diagnosisStartDate.slice(6, 8);
    // const diagnosisEndDateFormat = new Date(new Date(diagnosisStartDateFormat).getTime() + 24 * 60 * 60 * 1000);
    // const year = diagnosisEndDateFormat.getFullYear().toString();
    // const month = diagnosisEndDateFormat.getMonth() + 1 < 10 ?
    //     "0" + (diagnosisEndDateFormat.getMonth() + 1).toString() : (diagnosisEndDateFormat.getMonth() + 1).toString();
    // const day = diagnosisEndDateFormat.getDate() < 10 ?
    //     "0" + diagnosisEndDateFormat.getDate().toString() : diagnosisEndDateFormat.getDate().toString();
    // const diagnosisEndDate = year + month + day;
    // diagnosisEndDateFormat.getDate().toString();
    // const diagnosisDate = props.diagnosisDate && moment(props.diagnosisDate).format(FORMAT_DATE_SIMPLE);

    /** 刷新游标图表 */
    componentDidMount() {
        const chart = new Chart({
            container: 'ecg-history-chart',
            forceFit: true,
            height: 300
        });
        chart.source(this.props.ecgDateIndexRecords);
        chart.scale('sales', {
            tickInterval: 20
        });
        if (this.props.ecgDateIndexRecords.length <= 5) {
            // size参数可以改变柱子宽度
            chart.interval().position('recordDate*timeNum').size(100).label("timeNum");
        } else {
            chart.interval().position('recordDate*timeNum').label("timeNum");
        }
        chart.on('interval:click', ev => {
            console.log(1)
            const records = chart.getSnapRecords({x: ev.x, y: ev.y});
            console.log(records);
            const data = records[0]._origin;
            if (data) {
                console.log(data)
                // do something
            }
        });
        chart.render();

        const slider = new Slider({
            container: 'ecg-history-chart-slider',
            height: 200,
            padding: [0, 0, 0, 0],  //[0, 160, 0, 160]
            data: this.props.ecgDateIndexRecords,
            start: this.props.rangeStartDate,
            end: this.props.rangeEndDate,
            xAxis: 'recordDate',
            yAxis: 'timeNum',
            fillerStyle: {
                fill: '#5AA8D8',
                fillOpacity: 0.1
            },
            backgroundStyle: {
                stroke: '#dddddd',  //border
                fill: '#000000',   //背景色
                fillOpacity: 0.005,
                lineWidth: 0
            },
            backgroundChart: {
                type: ['interval'], // 图表的类型，可以是字符串也可是是数组  line  area
                color: "rgba(0,0,0,0)",
            },
            textStyle: {
                fill: '#000000'  //333333
            },
            onChange: ({startText, endText}) => {
                this.setState({rangeStartDate: startText, rangeEndDate: endText});
            },
        });

        slider.render();
        return () => {
            chart.destroy();
            slider.destroy();
        }
    };

    _handSliderMouseUp(rangeStartDate, rangeEndDate) {
        if (this.state.rangeStartDate && this.props.rangeStartDate !== this.state.rangeStartDate) {
            this.props.onRangeStartDateChange && this.props.onRangeStartDateChange.call(this, rangeStartDate, rangeEndDate);
        }

        if (this.state.rangeEndDate && this.props.rangeEndDate !== this.state.rangeEndDate) {
            this.props.onRangeEndDateChange && this.props.onRangeEndDateChange.call(this, rangeStartDate, rangeEndDate);
        }
    }

    render() {
        return (
            <div className="ecg-history-chart-wrapper">
                <div id="ecg-history-chart" className="ecg-history-chart">
                </div>
                <div id='ecg-history-chart-slider' className="ecg-history-chart-slider" onMouseUp={(e) => {
                    this._handSliderMouseUp(this.state.rangeStartDate, this.state.rangeEndDate)
                }}>
                </div>
            </div>
        );
    }
}
