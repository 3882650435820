import "./SiderLayout.css"

import {
    addWarnCount,
    getMainMenu,
    refreshPathname,
    refreshShowWarnRecordModal,
    refreshWarnRecord
} from "../actions/AppAction";

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import * as StorageKeys from '../constants/StorageKeys';
import {logout, getSessionUser, togglePasswordChangeModal} from "../modules/Account/actions/UserAction";
import {toggleMapScreenVisible} from '../modules/DAS/actions/DeviceAction';
import {RoutePath} from '../constants/RoutePath';

import {Layout, Menu, Icon, Avatar, Modal, Form, Row, Col, Switch} from 'antd';
import {APP_NAME, APP_VERSION, CACHE_SESSION_USER, CACHE_SITE_ID, MQTT_HOST, WARN_RECORD} from "../constants/Profile";
import PasswordChange from "../modules/Account/components/PasswordChange";
import DrawerSwitch from "../components/DrawerSwitch";
import MsgInfo from "../images/bmap/ic_menu_info.png";
import MsgWarn from "../images/bmap/ic_warn.png";
import MusicInfo from "../images/bmap/music_info.mp3";
import mqtt from "mqtt";
import WarnRecordModal from "./WarnRecordModal";
import {refreshShowRoles} from "../modules/Account/actions/RoleAction";
import AccountRoleReducer from "../modules/Account/reducers/RoleReducer";

const {Header, Sider, Content} = Layout;
let client;
const childRef = React.createRef();

class SiderLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            collapsed: false,
            visible: false,
            modalVisible:false,
            secs:"2s",
            opacity:1,
            lastRecord:{},
            userShowRoles:false,
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentWillMount() {
        // localStorage.removeItem(WARN_RECORD);
        this.props.dispatch(getSessionUser(this.state.formatMessage, (data) => {
            this.props.dispatch(getMainMenu(data));
        }));

        this._mqttConnect();
        // const timer1 = setInterval(() => {
        //     // console.log("warnCnt:" + this.props.warnCnt);
        //
        //     // if(this.props.isWarn){
        //     //     this._handleMsg();
        //     // }
        //     // let newRecord = {createTime:new Date().toLocaleString(),phoneNumber:"13651999541", deviceCode:"72140000"};
        //     let newRecord ={
        //         "createTime":new Date().toLocaleString(),
        //         "appVersion":"3.03.31",
        //         "deviceCode":"72040000",
        //         "guardMode":"2",
        //         "latitude":31.224620520713398,
        //         "longitude":121.43709940358937,
        //         "phoneBrand":"HUAWEI",
        //         "phoneModel":"VOG-AL10",
        //         "phoneNumber":"15900909626",
        //         "userId":"205f459b7de3454db3a5e954e481385e",
        //         "userName":"mengshao1shao"
        //     }
        //     this._handleAddWarnCount(newRecord);
        // }, 10000);
    }

    componentDidUpdate() {
        const {location: {pathname}} = this.props;
        this.props.dispatch(refreshPathname(pathname));
    }

    _mqttConnect(){
        let self = this;
        // mqtt 连接
        client = mqtt.connect(MQTT_HOST);
        client.on('connect', function () {
            client.subscribe('D9K_MQTT/SOS/#', function (err) {
                console.log(err);
                if (!err) {
                    console.log("subscribe successed");
                } else {
                    console.log("subscribe failed");
                }
            })
        })

        //mqtt 接收信息
        client.on('message', function (topic, message) {
            let obj = JSON.parse(message.toString());
            console.log("obj:" + obj);
            console.log("message:" + message.toString());

            if(self.props.isWarn){
                self._handleMsg();
            }

            self._handleAddWarnCount(obj);
        })

    }

    _handleAddWarnCount = (data) => {
        let record = Object.assign([], this.props.warnRecord);
        data.createTime = new Date().toLocaleString();
        record.unshift(data);

        //长久保存数据
        if(localStorage.getItem(WARN_RECORD) === undefined || localStorage.getItem(WARN_RECORD) === null){
            localStorage.setItem(WARN_RECORD, JSON.stringify(record));
        }else{
            let dataTemp = JSON.parse(localStorage.getItem(WARN_RECORD));
            dataTemp.unshift(data);
            localStorage.setItem(WARN_RECORD, JSON.stringify(dataTemp));
        }
        console.log("localStorage:" + JSON.stringify(localStorage.getItem(WARN_RECORD)));

        this.setState({lastRecord:data});
        this.props.dispatch(refreshWarnRecord(record));
        this.props.dispatch(addWarnCount(this.props.warnCnt));
    }

    _handleMsg = () => {

        //播放报警声音
        let audio = new Audio(MusicInfo);
        audio.play();
        setTimeout(() => {
            audio.pause();
        }, 2000);

        //弹出弹框，两秒后关闭
        this.setState({modalVisible:true,secs:"2S"});
        let sec = 3;
        const timer = setInterval(() => {
            sec -= 1;
            this.setState({secs: sec.toString() + "s"});
        }, 1000);
        setTimeout(() => {
            clearInterval(timer);
            this.setState({modalVisible:false});
        }, 3000);

        //弹框闪烁
        const timerOpa =setInterval(() =>{
            var opacity = this.state.opacity;
            opacity -= 0.01;
            if(opacity < 0.1){
                opacity = 1;
            }
            this.setState({opacity:opacity});
        },10);
        setTimeout(() => {
            clearInterval(timerOpa);
            this.setState({opacity:1});
        }, 3000);

    }

    _handleShowModal(){
        console.log("_handleShowModal");
        this.props.dispatch(refreshShowWarnRecordModal(true));
    }

    _handleUserShowModal(value){
        this.props.dispatch(refreshShowRoles(value));
        this.setState({userShowRoles: value});
        this.props.dispatch(getSessionUser(this.state.formatMessage, (data) => {
            data.roles[0].showRoles = value;
            this.props.dispatch(getMainMenu(data));
        }));
    }

    render() {
        const {location: {pathname}} = this.props;
        const formatMessage = this.state.formatMessage;
        const isExternalUser = this.props.user && this.props.user.roles && this.props.user.roles[0] && this.props.user.roles[0].code === 'ROLE_EXTERNAL';
        console.log("pathname:" + this.props.children);
        console.log("roles:" + JSON.stringify(this.props.user));
        return (
            <Layout id="classic-layout">
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    width={180}>
                    <div className="logo">
                        <img src={this.state.collapsed ? require("../assets/logo.png") : require("../assets/logo.png")}
                             alt="logo"/>
                        <span><FormattedMessage id="APP_BRAND"/></span>
                    </div>
                    <Menu theme="dark" mode="inline"
                        //defaultSelectedKeys={['1']}
                          defaultOpenKeys={this.getCurrentOpenKeys()}
                        //onOpenChange={this.handleOpenChange}
                          selectedKeys={this.getCurrentMenuSelectedKeys()}
                          style={{margin: '16px 0', width: '100%'}}>
                        {this._renderMenuItems(this.props.menuItems)}
                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{background: '#fff', padding: 0}}>
                        <Icon className="trigger" type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                              onClick={this.toggle}/>
                        <span style={{fontSize: '10px'}}>（版本：{APP_NAME} {APP_VERSION}）</span>
                        <div className="pull-right">
                            <PasswordChange/>
                            {this._renderAccount()}
                        </div>

                        <DrawerSwitch
                            pathname={pathname}
                        />

                        <div className="pull-right" style={{marginRight:50}} onClick={() =>this._handleShowModal()}>
                            <img src={MsgInfo}/>

                            <div className="circle">
                                <div style={{marginTop: -24,fontSize:1,color:"white",textAlign:"center"}}>
                                    {this.props.warnCnt > 99 ? "99+" : this.props.warnCnt}
                                </div>
                            </div>

                        </div>

                        {
                            isExternalUser ?
                                null :
                                <div className="pull-right" style={{marginRight:50}}>
                                    <Switch
                                        checkedChildren="开发者模式"
                                        unCheckedChildren="非开发者模式"
                                        checked={this.state.userShowRoles}
                                        onChange={(value) => this._handleUserShowModal(value)}/>
                                </div>
                        }

                    </Header>
                    <Content>
                        {this.props.children}

                        <Modal
                            title={null}
                            visible={this.state.modalVisible}
                            footer={null}
                            width={300}
                            centered
                            mask={false}
                            bodyStyle={{background:"lightgray"}}
                            onCancel={() => {
                                this.setState({modalVisible:false})
                            }}
                            style={{opacity: this.state.opacity}}
                        >
                            <div style={{marginTop: 7,marginLeft:230}}>{this.state.secs}</div>
                            <div style={{textAlign:"center"}}>
                                <Row>
                                    <Col span={24}>
                                        <img src={MsgWarn} style={{width:150,height:150}}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <Form.Item
                                            label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} style={{paddingTop:10,marginLeft:-50}}>
                                        {this.state.lastRecord.deviceCode}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <Form.Item
                                            label={formatMessage({id: 'ACCOUNT_USER_FIELD_MOBILE'})}>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} style={{paddingTop:10,marginLeft:-50}}>
                                        {this.state.lastRecord.phoneNumber}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <p>收到来自手机用户的报警！</p>
                                    </Col>
                                </Row>

                                {/*<div style={{marginTop:5}}>*/}
                                {/*    设备编号：{this.state.lastRecord.deviceCode}*/}
                                {/*</div>*/}
                                {/*<div style={{marginTop:5}}>*/}
                                {/*    手机号：{this.state.lastRecord.phoneNumber}*/}
                                {/*</div>*/}
                                {/*<p>收到来自手机用户的报警！</p>*/}
                            </div>

                        </Modal>
                        <WarnRecordModal {...this.props}/>
                    </Content>
                    {/*<Footer style={{ textAlign: 'center' }}>*/}
                    {/*<FormattedMessage id="copyright"/>*/}
                    {/*</Footer>*/}
                </Layout>

            </Layout>
        );
    }

    _renderMenuItems(menusData, parentPath) {

        return menusData.map((item, i) => {
            if (!item.children || item.children.length <= 0) {
                const newParentPath = (parentPath === undefined || parentPath === '') ? "" : (parentPath + "/");
                const path = (item.path.indexOf('http') === -1) ? ("/app/" + newParentPath + item.path) : item.path;
                const title = item.icon ? (
                        <span><Icon type={item.icon}/><span><FormattedMessage
                            id={item.title}/></span></span>) :
                    <span><FormattedMessage id={item.title}/></span>;
                return (
                    <Menu.Item key={item.path}>
                        <Link to={item.path} target={item.target}>
                            {title}
                        </Link>
                    </Menu.Item>
                )
            } else {
                const title = item.icon ? (
                        <span><Icon type={item.icon}/><span><FormattedMessage
                            id={item.title}/></span></span>) :
                    <span><FormattedMessage id={item.title}/></span>;
                return (
                    <Menu.SubMenu key={item.path} title={title}>
                        {this._renderMenuItems(item.children, item.path)}
                    </Menu.SubMenu>
                );
            }
        })
    }

    _renderAccount() {
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        const portrait = sessionUser && sessionUser.portrait ? "data:image/png;base64," + sessionUser.portrait : require("../images/logo.png");
        return (
            <Menu mode="horizontal" onClick={this.onMenuClick}>
                <Menu.SubMenu
                    title={<span><Avatar className="avatar"
                                         src={portrait}/>{sessionUser && sessionUser.fullName}</span>}>
                    {/*<Menu.Item><Icon type="user"/>个人中心</Menu.Item>*/}
                    {/*<Menu.Item><Icon type="setting"/>设置</Menu.Item>*/}
                    <Menu.Item key="modifyPassword"><Icon type="key"/><FormattedMessage
                        id="ACCOUNT_USER_ROUTE_PASSWORD_MODIFY"/></Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item key="logout"><Icon type="logout"/><FormattedMessage
                        id="ACCOUNT_USER_ROUTE_LOGOUT"/></Menu.Item>
                </Menu.SubMenu>
            </Menu>
        )
    }

    onMenuClick = ({key}) => {
        switch (key) {
            case'logout':
                const self = this;
                Modal.confirm({
                    title: self.state.formatMessage({id: "ACCOUNT_USER_TITLE_LOGOUT"}),
                    content: self.state.formatMessage({id: "ACCOUNT_USER_MSG_LOGOUT_CONFIRM"}),
                    okType: 'danger',
                    okText: self.state.formatMessage({id: "COMMON_BUTTON_OK"}),
                    cancelText: self.state.formatMessage({id: "COMMON_BUTTON_CANCEL"}),
                    onOk() {
                        self.props.dispatch(logout(self.props.router, self.state.formatMessage));
                    }
                });
                break;
            case "modifyPassword":
                this.props.dispatch(togglePasswordChangeModal(true));
                // this.props.router.push(RoutePath.ACCOUNT_USER_PASSWORD_MODIFY.path);
                break;
        }
    }

    getCurrentMenuSelectedKeys() {
        let keys = [];
        const {location: {pathname}} = this.props;
        keys.push(pathname);
        return keys;
    }

    getCurrentOpenKeys() {
        let keys = [];
        const {location: {pathname}} = this.props;
        const splits = pathname.split('/').slice(1);
        let path = "";
        for (let i = 0; i < splits.length; i++) {
            path = (path === "") ? splits[i] : path + "/" + splits[i];
            keys.push("/" + path);
        }
        return keys;
    }

    //handleOpenChange = (openKeys) => {
    //    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    //    this.setState({
    //        openKeys: latestOpenKey ? [latestOpenKey] : [],
    //    });
    //}
}

function mapStateToProps(store) {
    return {
        menuItems: store.AppReducer.menuItems,
        mapScreenVisible: store.EcgDeviceReducer.mapScreenVisible,
        screenMap: store.EcgDeviceReducer.screenMap,
        warnCnt: store.AppReducer.warnCnt,
        isWarn: store.AppReducer.isWarn,
        warnRecord: store.AppReducer.warnRecord,
        setDefaultActiveKey: store.AppReducer.setDefaultActiveKey,
        user: store.AccountUserReducer.user,
        showRoles: store.AccountRoleReducer.showRoles,
    }
}

export default connect(mapStateToProps)(injectIntl(SiderLayout));