/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import PageTitle from "../../../../../components/PageTitle";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";


import {initSearchCondition} from '../../../actions/ECGAction';

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        return (
            <div className="page-content">
                <PageTitle title="DAS_ECG_ROUTE_MINE"/>
                <SearchCondition/>
                <SearchResult {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));