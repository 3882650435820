/**
 * CompMethod    4B    UInt32    0-232-1    数据的压缩方式
 * 这里主要是采用有损压缩算法
 * 0: 无压缩
 * 1: 压缩函数版本1
 * 2: 压缩函数版本2
 * PolymID    4B    UInt32    0-232-1    0x01表示心电，0x02表示MPU6050
 * Gc    4B    UInt32    0-232-1    增益，用它将数值换算到物理量
 * ChannelNum    4B    UInt32    1-16    通道数量
 * SampleRate    4B        0-1000    采样率，默认值500
 * SampleResolution    4B        1-32    数据精度，8/16/24/32
 * SampleNum    4B        0-65535    此文件中的采样点个数
 * DataLen    4B            DataLen= ChannelNum* SampleNum* SampleResolution/8
 * Data    DataLen
 */
import ByteUtils from "../../../utils/ByteUtils";
import ECGPoint from "./ECGPoint";
import MedfiltDeWander from "../utils/MedfiltDeWander";
import DecompressUtils from "../utils/DecompressUtils";
import EcgFileCompressData from "./EcgFileCompressData";

let startTime;

class EcgFileMixDataPolymRegion {
    constructor(dataArray, startPos, timeRecordIndex, historyFileIndex, eventRecordIndex, drawBufferMap, ecgFilePackage, polymIndex, preTimeIndex, medfiltDeWanderEnable, huffmanDecode) {

        let index = startPos;

        // 数据的压缩方式
        // 这里主要是采用有损压缩算法
        // 0: 无压缩
        // 1: 压缩函数版本1
        // 2: 压缩函数版本2
        const compMethodArray = dataArray.slice(index, index + 4);
        this.compMethod = new Int32Array(compMethodArray.buffer.slice(-4))[0];
        console.log("数据的压缩方式:" + this.compMethod);
        index += 4;

        // 0x01表示心电，0x02表示MPU6050
        const polymIDArray = dataArray.slice(index, index + 4);
        this.polymID = new Int32Array(polymIDArray.buffer.slice(-4))[0];
        index += 4;

        // 增益，用它将数值换算到物理量
        const gcArray = dataArray.slice(index, index + 4);
        this.gc = new Float32Array(gcArray.buffer.slice(-4))[0];
        index += 4;

        // 通道数量
        const channelNumArray = dataArray.slice(index, index + 4);
        this.channelNum = new Int32Array(channelNumArray.buffer.slice(-4))[0];
        console.log("通道数量:" + this.channelNum);
        index += 4;

        // 采样率，默认值500
        const sampleRateArray = dataArray.slice(index, index + 4);
        this.sampleRate = new Int32Array(sampleRateArray.buffer.slice(-4))[0];
        index += 4;
        console.log("采样率:" + this.sampleRate);

        // 下采样率，默认值1。(该值要能够被FrameLen整除) 1/2/4/8/16
        const downSampleRatioArray = dataArray.slice(index, index + 4);
        this.downSampleRatio = new Int32Array(downSampleRatioArray.buffer.slice(-4))[0];
        console.log("下采样率:" + this.downSampleRatio);
        index += 4;

        // 数据精度，8/16/24/32
        const sampleResolutionArray = dataArray.slice(index, index + 4);
        this.sampleResolution = new Int32Array(sampleResolutionArray.buffer.slice(-4))[0];
        index += 4;

        // 压缩后数据精度
        const compResolutionArray = dataArray.slice(index, index + 4);
        this.compResolution = new Int32Array(compResolutionArray.buffer.slice(-4))[0];
        index += 4;

        // 数据排布方式
        // 0：先时间再导联
        // 1：先导联再时间
        const storePriArray = dataArray.slice(index, index + 4);
        this.storePri = new Int32Array(storePriArray.buffer.slice(-4))[0];
        index += 4;

        // 此文件中的采样点个数
        const sampleNumArray = dataArray.slice(index, index + 4);
        this.sampleNum = new Int32Array(sampleNumArray.buffer.slice(-4))[0];
        console.log("此文件中的采样点个数：" + this.sampleNum);
        index += 4;

        // DataLen= ChannelNum* SampleNum* SampleResolution/8
        const dataLenArray = dataArray.slice(index, index + 4);
        this.dataLen = new Int32Array(dataLenArray.buffer.slice(-4))[0];
        console.log("dataLen:" + this.dataLen);
        index += 4;

        // ecgdata的排列
        // Channel1 data0 最低字节
        // Channel1 data0 中间字节
        // Channel1 data0 最高字节
        // Channel1 data1 最低字节
        // Channel1 data1 中间字节
        // Channel1 data1 最高字节
        // …
        // …
        // …
        // Channel1 dataN 最低字节
        // Channel1 dataN 中间字节
        // Channel1 dataN 最高字节
        // Channel2 data0 最低字节
        // Channel2 data0 中间字节
        // Channel2 data0 最高字节
        // 每3个字节是一个心电数据，每个导联的样本数sampleNum一般是7936个，心电数据数ecgPoints会是63488个，
        // 因此channelNum * sampleNum = ecgPoints.length是吻合的
        let ecgPoints = [];
        // const ecgDataPointArray = [];
        if (this.compMethod === 1) {
            // 无损压缩解析
            const ecgFileCompressData = new EcgFileCompressData(dataArray, index, this.dataLen);
            console.log("收到的压缩心电数据");
            console.log(ecgFileCompressData);
            const ecgDataArrayBuffer = huffmanDecode.decodeDiff(ecgFileCompressData.data, 0, ecgFileCompressData.codeLenInBits);
            console.log("解压缩后ecgDataArrayBuffer数据");
            console.log(ecgDataArrayBuffer);
            const ecgDataArray = new Int8Array(ecgDataArrayBuffer);
            for (let i = 0; i < ecgDataArray.length; i = i + 4) {
                const ecgDataPointArray = ecgDataArray.slice(i, i + 4);
                const ecgDataPoint = new Int32Array(ecgDataPointArray.buffer.slice(-4))[0];
                ecgPoints.push(ecgDataPoint);
            }
            console.log("解析成心电数据点");
            console.log(ecgPoints)
        } else if (this.compMethod === 2) {
            // 有损压缩解析
            const ecgFileCompressData = new EcgFileCompressData(dataArray, index, this.dataLen);
            // console.log("解压缩算法前");
            // console.log(ecgFileCompressData);
            const ecgFileCompressChannelPoint = DecompressUtils.d9k_dec(ecgFileCompressData.data.buffer);
            // console.log("解压缩算法后");
            // console.log(ecgFileCompressChannelPoint);

            // 把点添加到缓存列表中，进行实时心电图的绘制
            for (let i = 0; i < this.channelNum; i++) {
                // 压缩的数据，点数是8192个，但无压缩的数据是7396个点，
                // 8192个点是在7396个点的基础上补0操作的，所以把每个导联的最后256个0去掉
                const ecgDataPoints = ecgFileCompressChannelPoint[i];

                // console.log(ecgDataPoints);
                if (ecgDataPoints.length === 8192) {
                    ecgPoints = ecgPoints.concat(ecgDataPoints.slice(0, ecgDataPoints.length - 256));
                } else {
                    ecgPoints = ecgPoints.concat(ecgDataPoints);
                }
            }
            // console.log(ecgPoints);
        } else {
            console.log("收到的心电数据包");
            console.log(dataArray);
            const ecgDataArray = dataArray.slice(index, index + this.dataLen);
            console.log("截取的心电数据包");
            console.log(ecgDataArray);
            for (let i = 0; i < this.dataLen; i = i + 3) {
                // const ecgPoint = new ECGPoint();
                const ecgData1 = ecgDataArray[i];
                const ecgData2 = ecgDataArray[i + 1];
                const ecgData3 = ecgDataArray[i + 2];
                const ecgDataPoint = ByteUtils.bytesToInt(ecgData1, ecgData2, ecgData3);
                ecgPoints.push(ecgDataPoint);
            }
        }

        // 中值滤波对象需要根据解析后的样本数进行初始化，因为不同的协议固件版本点数不一样
        // 历史数据才需要做中值滤波
        if (window.medfiltDeWander == null && !drawBufferMap && medfiltDeWanderEnable) {
            console.log(window.medfiltDeWander);
            //初始化中值滤波除基工具
            // const sampleRate = 500;
            // const sampleNum = 7936;
            let filterSize = 0.6 * this.sampleRate;
            //确保为奇数
            filterSize += (filterSize % 2 == 0 ? 1 : 0);

            // filterSize = 5;
            window.medfiltDeWander = new MedfiltDeWander(8, filterSize, this.sampleNum);
        }

        // 非实时心电图，需要做中值滤波和基线漂移算法处理
        if (polymIndex === 0 && window.medfiltDeWander && !drawBufferMap && medfiltDeWanderEnable) {
            //中值滤波除基线
            console.log(window.medfiltDeWander.times);
            if (window.medfiltDeWander.times === 0) {
                window.medfiltDeWander.init1(ecgPoints, this.sampleNum);
            }
            const dewanderEcgPoints = window.medfiltDeWander.dewander(ecgPoints);
            if (dewanderEcgPoints != null) {
                ecgPoints = dewanderEcgPoints;
            }
        }

        const ecgData = {};
        // 把心电数据整理到ecgData[][]的数据结构中
        // 实时心电图的下采样计算
        // const downSamplingRatio = drawBufferMap ? 5 : 1;
        for (let i = 0; i < 12; i++) {

            // 一个导联的心电数组
            const channelEcgPoints = ecgPoints.splice(0, this.sampleNum);
            ecgData[i] = [];
            // ecgData[i] = ecgDataPointArray.splice(0, this.sampleNum);
            // 设置每个点在文件中的索引
            for (let j = 0; j < this.sampleNum; j = j + 1) {
                ecgData[i][j] = new ECGPoint();
                switch (i) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        ecgData[i][j].point = channelEcgPoints[j];
                        break;
                    case 8:
                        ecgData[i][j].point = ecgData[i - 6][j].point - ecgData[i - 7][j].point;
                        break
                    case 9:
                        ecgData[i][j].point = ecgData[i - 6][j].point - ecgData[i - 7][j].point / 2;
                        break
                    case 10:
                        ecgData[i][j].point = (ecgData[i - 6][j].point + ecgData[i - 7][j].point) / 2;
                        break
                    case 11:
                        ecgData[i][j].point = ecgData[i - 7][j].point - ecgData[i - 6][j].point / 2;
                        break
                }

                ecgData[i][j].timeRecordIndex = timeRecordIndex;
                ecgData[i][j].historyFileIndex = historyFileIndex;
                ecgData[i][j].eventRecordIndex = eventRecordIndex;
                ecgData[i][j].package = ecgFilePackage;
                ecgData[i][j].index = j;
                if (preTimeIndex) {
                    if (preTimeIndex[i] % (500 / this.downSampleRatio) === 0) {
                        if (preTimeIndex[i] === 0) {
                            ecgData[i][j].devTime = ecgFilePackage.time;
                            ecgData[i][j].startPoint = true;
                            startTime = new Date(new Date().toJSON().substr(0, 11) + ecgFilePackage.time).getTime();
                            console.log("startTime:", new Date(startTime).toLocaleTimeString()
                                , preTimeIndex[i], startTime, ecgFilePackage.time, new Date().toJSON().substr(0, 11),
                                new Date().toJSON().substr(0, 11) + ecgFilePackage.time)
                        } else {
                            ecgData[i][j].devTime =
                                new Date(startTime + (preTimeIndex[i] / 100 * 1000)).toLocaleTimeString();
                        }
                    }
                    preTimeIndex[i]++;
                }

                // console.log("preTimeIndex:", preTimeIndex, ecgData[i][j].devTime, ecgData[i][0].devTime);
                if (drawBufferMap) {
                    // 把点添加到缓存列表中，进行实时心电图的绘制
                    drawBufferMap[i].push(ecgData[i][j]);
                }
            }
        }

        this.ecgData = ecgData;
        index += this.dataLen;
        // console.log("ecgData:", ecgData)
        this.length = index - startPos;
    }
}

export default EcgFileMixDataPolymRegion;