import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Layout, Menu, Breadcrumb, Icon} from 'antd';

import PageTitle from "../../../../../components/PageTitle";
import ChannelTree from '../../../components/ChannelTree';
import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';

import {getChannelTree, selectTreeNode} from "../../../actions/ChannelAction";
import {refreshSearchCondition, searchContents} from '../../../actions/ContentAction';
import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../../../constants/Profile";

const {Content, Sider} = Layout;

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            height: "100%",
        }
    }

    componentWillMount() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };


    _handleTreeNodeSelect(selectedKey) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
            searchCondition["channelIds"] = [selectedKey];

        this.props.dispatch(refreshSearchCondition(searchCondition));
        this.props.dispatch(selectTreeNode(selectedKey));
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <div className="page-content position-relative" style={{maxHeight: height}}>
                <PageTitle title="CMS_CONTENT_ROUTE_CONTENT_LIST"/>
                <SearchCondition {...this.props}/>
                <div style={{padding: '10px', background: '#fff',minHeight:800}}>
                    <div style={{background: '#fff', borderRight: '1px solid #e8e8e8',float:"left",width:"15%", minHeight: 780}}>
                        <ChannelTree channelTree={this.props.channelTree}
                                     onSelect={(selectedKey) => this._handleTreeNodeSelect(selectedKey)}/>
                    </div>
                    <div style={{padding: '0 10px', minHeight: 280,float:"left",width:"85%"}}>
                        <SearchResult {...this.props}/>
                    </div>
                    <div style={{clear:"both"}} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.ChannelReducer.channelTree,
        searchCondition: store.ContentReducer.searchCondition,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));