/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Form, Modal, Button} from "antd";

import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";
import EnumItemSelect from "../../../../components/EnumItemSelect";
import {AnnotationLabel} from "../../Enums";

class MultiAnnotationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            annotation: ""
        }
    }

    componentDidMount() {
    }

    _handleCancel() {
        // this.props.dispatch(toggleMultiAnnotationModalModal(false));
        this.props.onCancel && this.props.onCancel.call(this);
    }

    _handleOk() {
        const {onOk} = this.props;
        if (this.state.annotation !== "") {
            onOk && onOk.call(this, this.state.annotation);
        }
    }

    render() {
        const {formatMessage} = this.state;

        return (
            <Modal
                title={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_TITLE_ANNOTATION"})}
                maskClosable={false}
                visible={this.props.showMultiAnnotationModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
            >
                <Form>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_ANNOTATION"})} {...Label_6_Wrapper_18}
                        required={true}>
                        <EnumItemSelect data={AnnotationLabel.List}
                                        value={this.state.annotation}
                                        onChange={(data) => {
                                            this.setState({annotation: data});
                                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        // showMultiAnnotationModal: store.EcgAnnotationToolReducer.showMultiAnnotationModal
    }
}

export default connect(mapStateToProps)(injectIntl(MultiAnnotationModal));