// 用户
export const USER_LOADING = 'USER_LOADING';
export const USER_REFRESH_SEARCH_CONDITION = "USER_REFRESH_SEARCH_CONDITION";
export const USER_REFRESH_USER = 'USER_REFRESH_USER';
export const USER_SEARCH = 'USER_SEARCH';
export const USER_TOGGLE_DETAIL_MODAL = "USER_TOGGLE_DETAIL_MODAL";
export const USER_TOGGLE_PASSWORD_CHANGE_MODAL = "USER_TOGGLE_PASSWORD_CHANGE_MODAL";

// 角色
export const ROLE_LOADING = 'ROLE_LOADING';
export const ROLE_SEARCH = 'ROLE_SEARCH';
export const ROLE_REFRESH_SELECTED_ROLES = 'ROLE_REFRESH_SELECTED_ROLES';
export const ROLE_REFRESH_SHOW_ROLES = 'ROLE_REFRESH_SHOW_ROLES';