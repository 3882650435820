/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    binders: [],
    binder: {},
    binderDevices: [],
    binderGuarders: [],
    bindersSum: 0,
    pagination: {},
    isLoading: false,
    showDeviceListModal: false,
    showDeviceBindModal: false,
    showGuarderListModal: false,
    showGuarderBindModal: false,
    showBinderSelectModal: false,
    selectedBinders: []
};

const BinderReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_BINDER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_BINDER_TOGGLE_DEVICE_LIST_MODAL:
            return Object.assign({}, state, {
                showDeviceListModal: action.showDeviceListModal
            });
        case ActionTypes.ECG_BINDER_TOGGLE_DEVICE_BIND_MODAL:
            return Object.assign({}, state, {
                showDeviceBindModal: action.showDeviceBindModal
            });
        case ActionTypes.ECG_BINDER_REFRESH_DEVICES:
            return Object.assign({}, state, {
                binderDevices: action.binderDevices
            });
        case ActionTypes.ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL:
            return Object.assign({}, state, {
                showGuarderListModal: action.showGuarderListModal
            });
        case ActionTypes.ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL:
            return Object.assign({}, state, {
                showGuarderBindModal: action.showGuarderBindModal
            });
        case ActionTypes.ECG_BINDER_TOGGLE_BINDER_SELECT_MODAL:
            return Object.assign({}, state, {
                showBinderSelectModal: action.showBinderSelectModal
            });
        case ActionTypes.ECG_BINDER_REFRESH_SELECTED_BINDERS:
            return Object.assign({}, state, {
                selectedBinders: action.selectedBinders
            });
        case ActionTypes.ECG_BINDER_REFRESH_GUARDERS:
            return Object.assign({}, state, {
                binderGuarders: action.binderGuarders
            });
        case ActionTypes.ECG_BINDER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_BINDER_SEARCH:
            return Object.assign({}, state, {
                binders: action.binders,
                pagination: action.pagination
            });
        case ActionTypes.ECG_BINDER_SEARCH_BINDERS_SUM:
            return Object.assign({}, state, {
                bindersSum: action.bindersSum
            });
        case ActionTypes.ECG_BINDER_REFRESH_BINDER:
            return Object.assign({}, state, {
                binder: action.binder
            });
        default:
            return state
    }
};

export default BinderReducer;