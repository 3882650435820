/**
 * Created by Ethan on 17/3/16.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'

import moment from "moment";
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import {ReturnCodes} from "../../../constants/Enums";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.DAS_BINDER_DATA_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}


export function refreshBinderDataItem(item) {
    return {
        type: ActionTypes.DAS_BINDER_DATA_REFRESH_BINDER_DATA_ITEM,
        binderDataItem: item
    }
}

export function searchBinderData(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DAS_BINDER_DATA_LOADING, isLoading: true});

        let requestUrl = WebService.getBinderData.replace("{binderId}", searchCondition.binderId);
        requestUrl = requestUrl + "?startTime=" + searchCondition.startTime + "&endTime=" + searchCondition.endTime;
        HttpUtils.get(requestUrl, (response) => {
            console.log(response);
            let binderDataMap = {};
            for (let i = 0; i < response.data.length; i++) {
                const item = response.data[i];
                if (item.diagnosisTime !== null && item.diagnosisTime !== "") {
                    const dateTime = moment(item.diagnosisTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_SIMPLE);
                    let binderDataList = binderDataMap[dateTime];
                    if (binderDataList == null) {
                        binderDataList = [];
                        binderDataList.push(item);
                        binderDataMap[dateTime] = binderDataList;
                    } else {
                        binderDataList.push(item);
                    }
                }
            }

            dispatch({
                type: ActionTypes.DAS_BINDER_DATA_SEARCH,
                binderData: response.data,
                binderDataMap: binderDataMap
            });

            dispatch({type: ActionTypes.DAS_BINDER_DATA_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}


// export function searchBinderDataCount(formatMessage) {
//
//     return dispatch => {
//         dispatch({type: ActionTypes.DAS_BINDER_DATA_LOADING, isLoading: true});
//
//         HttpUtils.get(WebService.searchBinderDataSum, (response) => {
//
//             dispatch({
//                 type: ActionTypes.DAS_BINDER_DATA_SEARCH_COUNT,
//                 binderDataCount: response.data
//             });
//
//             dispatch({type: ActionTypes.DAS_BINDER_DATA_LOADING, isLoading: false});
//         }, (response) => {
//         }, formatMessage)
//     }
// }

