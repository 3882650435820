/**
 * 名称    字节数    范围    说明
 * EventDate    4B        年/月/日     8B时间类型的高4字节
 * EventNum    4B    0-232-1    事件条数[也即RecordNum]
 * WorkTime    4B        当天记录总时长，以毫秒为单位
 * Record1    16B        单条记录内容见下表
 * Record2    16B        单条记录内容见下表
 */
import EcgFileDate from "./EcgFileDate";
import EcgFileEventIndexRecord from "./EcgFileEventIndexRecord";

class EcgFileEventIndex {
    constructor(dataArray, startPos) {
        let index = startPos;

        const ecgFileDate = new EcgFileDate(dataArray, index);
        this.eventDate = ecgFileDate.getDate();
        index = index + ecgFileDate.length;

        const eventNumArray = dataArray.slice(index, index + 4);
        this.eventNum = new Int32Array(eventNumArray.buffer.slice(-4))[0];
        index = index + 4;

        // 当天记录总时长，以毫秒为单位
        const workTimeArray = dataArray.slice(index, index + 4);
        this.workTime = new Int32Array(workTimeArray.buffer.slice(-4))[0];
        index = index + 4;

        // 时间记录
        this.records = [];
        for (let i = 0; i < this.eventNum; i++) {
            const ecgFileEventIndexRecord = new EcgFileEventIndexRecord(dataArray, index);
            this.records.push(ecgFileEventIndexRecord);
            index = index + ecgFileEventIndexRecord.length;
        }
        console.log("this.records:",this.records)
        this.length = index - startPos;
    }
}

export default EcgFileEventIndex;