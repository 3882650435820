import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Tree} from 'antd';

import {getOrganizationTree, refreshExpandedKeys} from '../actions/OrganizationAction';

class OrganizationTree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        this.props.dispatch(getOrganizationTree(this.state.formatMessage));
    }

    _handleSelect(selectedKeys) {
        if (selectedKeys.length > 0) {
            const selectedKey = selectedKeys[0];
            this.props.onSelect && this.props.onSelect.call(this, selectedKey);
        }
    }

    _handleExpand(expandedKeys) {
        this.props.dispatch(refreshExpandedKeys(expandedKeys));
    }

    render() {
        return (
            <div>
                <Tree
                    showLine
                    expandedKeys={this.props.expandedKeys}
                    // selectedKeys={[this.props.selectTreeKey]}
                    onSelect={(selectedKeys) => this._handleSelect(selectedKeys)}
                    onExpand={(expandedKeys) => this._handleExpand(expandedKeys)}>
                    {
                        this.props.organizationsTree.map((item, i) => {
                            return (
                                <Tree.TreeNode title={item.name} key={item.id}>
                                    {this._renderTreeNode(item)}
                                </Tree.TreeNode>
                            )
                        })
                    }
                </Tree>
            </div>
        );
    }

    _renderTreeNode(item) {
        if (item.children && item.children.length > 0) {
            return (
                item.children.map((item, i) => {
                    return (
                        <Tree.TreeNode title={item.name} key={item.id}>
                            {this._renderTreeNode(item)}
                        </Tree.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        organizationsTree: store.OrgOrganizationReducer.organizationsTree,
        selectTreeKey: store.OrgOrganizationReducer.selectTreeKey,
        expandedKeys: store.OrgOrganizationReducer.expandedKeys,
        searchCondition: store.OrgOrganizationReducer.searchCondition,
    }
}

export default connect(mapStateToProps)(injectIntl(OrganizationTree));