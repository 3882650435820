import React, {Component} from 'react';
import {Tag} from "antd";
import {OnlineState} from "../Enums";

export default class EcgTransferStatus extends Component {

    render() {
        let color = "red";
        let text = "离线";
        switch (this.props.status) {
            case OnlineState.Enum.OnNoTransfer:
                color = "orange";
                text = "未传输";
                break;
            case OnlineState.Enum.On:
                color = "green";
                text = "传输中";
                break;
        }
        return (
            <Tag color={color}>{text}</Tag>
        );
    }
}