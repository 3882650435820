import * as ActionTypes from '../ActionTypes';

const initialState = {
    channelTree: [],
    channels: [],
    // // The tree node select channel id
    // selectTreeKey: '',
    // // 栏目下拉框组件的选择项
    // selectItemKey: "",
    // // 动态的树无法自动展开，需要配置展开Keys数组
    // expandedKeys: [],
    channel: {},
    searchCondition: {},
    pagination: {
        // currentPage: 1,
        // pageSize: 10
    },
    showDetailModal: false,
    isLoading: false
};

const ChannelReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.CMS_CHANNEL_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.CMS_CHANNEL_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.CMS_CHANNEL_TREE:
            return Object.assign({}, state, {
                channelTree: action.channelTree,
                // expandedKeys: action.expandedKeys
            });
        // case ActionTypes.CMS_CHANNEL_TREE_SELECT:
        //     return Object.assign({}, state, {
        //         selectTreeKey: action.selectTreeKey
        //     });
        // case ActionTypes.CMS_CHANNEL_LIST_SELECT:
        //     return Object.assign({}, state, {
        //         selectItemKey: action.selectItemKey
        //     });
        case ActionTypes.CMS_CHANNEL_SEARCH:
            return Object.assign({}, state, {
                channels: action.channels,
                pagination: action.pagination
            });
        case ActionTypes.CMS_CHANNEL_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.CMS_CHANNEL_REFRESH_CHANNEL:
            return Object.assign({}, state, {
                channel: action.channel
            });
        default:
            return state
    }
};

export default ChannelReducer;