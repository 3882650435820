import React, {Component} from 'react';
import {Descriptions} from 'antd';

export default class PageTitle extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Descriptions size="small" layout="horizontal">
                <Descriptions.Item label="设备ID">{this.props.deviceId}</Descriptions.Item>
                <Descriptions.Item label="文件时间">{this.props.fileTime}</Descriptions.Item>
                <Descriptions.Item label="文件名">{this.props.fileName}</Descriptions.Item>
            </Descriptions>
        );
    };
}
