
import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    deviceBindHistoryList: [],
    searchCondition: {},
    pagination: {},
    showBindHistoryModal:false
};

const DeviceBindHistoryReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_DEVICE_BIND_HISTORY_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case ActionTypes.ECG_DEVICE_BIND_HISTORY_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition,
            });
        case ActionTypes.ECG_DEVICE_BIND_HISTORY_SEARCH:
            return Object.assign({}, state, {
                deviceBindHistoryList: action.deviceBindHistoryList,
                pagination: action.pagination
            });
        case ActionTypes.ECG_DEVICE_BIND_HISTORY_TOGGLE_LIST_MODAL:
            return Object.assign({}, state, {
                showBindHistoryModal: action.showBindHistoryModal,
            });
        default:
            return state
    }
};

export default DeviceBindHistoryReducer;