/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    towns: [],
    town: {},
    townMap: {}, // key: townId, value: town
    showDetailModal: false
};

const TownReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_TOWN_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_TOWN_SEARCH:
            return Object.assign({}, state, {
                towns: action.towns
            });
        case ActionTypes.DISTRICT_TOWN_REFRESH_TOWN:
            return Object.assign({}, state, {
                town: action.town
            });
        case ActionTypes.DISTRICT_TOWN_REFRESH_TOWN_MAP:
            return Object.assign({}, state, {
                townMap: action.townMap
            });
        case ActionTypes.DISTRICT_TOWN_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default TownReducer;