/**
 * Created by Ethan on 2017/5/25.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import {Modal, Form, Input, Card, Button} from "antd";

import {Label_6_Wrapper_18} from "../../../constants/FormItemLayout";
import {PASSWORD_STRENGTH_LEVEL} from "../../../constants/Profile";
import Validator from "../../../utils/Validator";
import {refreshUser, togglePasswordChangeModal, updateSessionUserPassword} from "../actions/UserAction";

class PasswordChange extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        user[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshUser(user));
    }

    _validate(user) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};

        if (!user.oldPassword || user.oldPassword === "") {
            messageMap["oldPassword"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }

        if (!user.password || user.password === "") {
            messageMap["password"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (user.passwordConfirm === undefined || user.passwordConfirm === "") {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        } else if (user.password !== user.passwordConfirm) {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED'});
        } else if (PASSWORD_STRENGTH_LEVEL >= 2) {
            if (user.password.length < 8) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED"}, {value: '8'});
            }
            if (PASSWORD_STRENGTH_LEVEL >= 3 && !Validator.isPasswordStrength(user.password)) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED"});
            }
        }
        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleOk() {
        const {dispatch, user} = this.props;
        if (this._validate(user)) {
            dispatch(updateSessionUserPassword(user, this.state.formatMessage));
        }
    }

    _handleCancel() {
        this.props.dispatch(togglePasswordChangeModal(false));

        const user = Object.assign({}, this.props.user);
        user.oldPassword = "";
        user.password = "";
        user.passwordConfirm = "";
        this.props.dispatch(refreshUser(user));

        this.setState({messageMap: {}})
    }

    render() {

        const formatMessage = this.state.formatMessage;
        const messageMap = this.state.messageMap;

        return (
            <Modal
                title={formatMessage({id: "ACCOUNT_USER_ROUTE_PASSWORD_MODIFY"})}
                maskClosable={false}
                visible={this.props.showPasswordChangeModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SUBMIT"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item
                        label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_OLD"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["oldPassword"] ? "error" : ""}
                        help={messageMap["oldPassword"]}>
                        <Input type="password" value={this.props.user.oldPassword}
                               onChange={(value) => this._handleChange("oldPassword", value)}/>
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_NEW"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["password"] ? "error" : ""}
                        help={messageMap["password"]}>
                        <Input type="password" value={this.props.user.password}
                               onChange={(value) => this._handleChange("password", value)}/>
                    </Form.Item>

                    <Form.Item
                        label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_CONFIRM"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["passwordConfirm"] ? "error" : ""}
                        help={messageMap["passwordConfirm"]}>
                        <Input type="password" value={this.props.user.passwordConfirm}
                               onChange={(value) => this._handleChange("passwordConfirm", value)}/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

function mapStateToProps(store) {
    return {
        showPasswordChangeModal: store.AccountUserReducer.showPasswordChangeModal,
        user: store.AccountUserReducer.user
    }
}

export default connect(mapStateToProps)(injectIntl(PasswordChange));