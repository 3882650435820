/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    villages: [],
    village: {},
    villageMap: {}, // key: villageId, value: village
    showDetailModal: false
};

const VillageReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_VILLAGE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_VILLAGE_SEARCH:
            return Object.assign({}, state, {
                villages: action.villages
            });
        case ActionTypes.DISTRICT_VILLAGE_REFRESH_VILLAGE:
            return Object.assign({}, state, {
                village: action.village
            });
        case ActionTypes.DISTRICT_VILLAGE_REFRESH_VILLAGE_MAP:
            return Object.assign({}, state, {
                villageMap: action.villageMap
            });
        case ActionTypes.DISTRICT_VILLAGE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default VillageReducer;