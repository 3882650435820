import EcgFileDateTime from "./EcgFileDateTime";
import {FORMAT_DATE_SLASH, FORMAT_DATE_TIME_FULL_HYPHEN, FORMAT_TIME_COLON} from "../../../constants/DateTimeFormats";
import EcgFileMixData from "./EcgFileMixData";
import EcgFileCommonHeader from "./EcgFileCommonHeader";
import moment from "moment";


class EcgFileLiveData {
    constructor(preTimeIndex, liveIdFlag, lastLiveId, dataArray, startPos, drawBufferMap, huffmanDecode) {

        // console.log("开始解析：" + moment.now(FORMAT_DATE_TIME_FULL_HYPHEN));

        let index = startPos;

        const downSamplingRatioArray = dataArray.slice(index, index + 4);
        this.downSamplingRatio = new Int32Array(downSamplingRatioArray.buffer.slice(-4))[0];

        // 注释掉，采用心电图的真实采样进行描画
        // // 实时心电图由于描画性能的影响，需要下采样
        // this.downSamplingRatio = 5;
        index += 4;

        const n7Array = dataArray.slice(index, index + 4);
        this.n7 = new Int32Array(n7Array.buffer.slice(-4))[0];
        index = index + 4;

        const liveId = dataArray.slice(index, index + 4);
        this.liveId = new Int32Array(liveId.buffer.slice(-4))[0];
        index = index + 4;

        const tcpId = dataArray.slice(index, index + 4);
        this.tcpId = new Int32Array(tcpId.buffer.slice(-4))[0];
        index = index + 4;

        // 开始采集的时间
        // 此次将时间变更为了8个字节，以便对齐。
        // 高4字节表示：YYYY年，MM月，DD日
        // 低4字节表示：HH时，MM分，SS秒，XX毫秒
        // 目前默认为00毫秒，即实际精度只到秒。
        const ecgFileDateTime = new EcgFileDateTime(dataArray, index);
        this.year = ecgFileDateTime.getDateTime(FORMAT_DATE_SLASH);
        this.time = ecgFileDateTime.getDateTime(FORMAT_TIME_COLON);
        index = index + ecgFileDateTime.length;

        this.ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, index);
        index = index + this.ecgFileCommonHeader.length;
        if (liveIdFlag[0]) {
            if (lastLiveId[0] + 1 != this.liveId) {
                for (let i = 0; i < preTimeIndex.length; i++) {
                    preTimeIndex[i] = 0;
                }
                lastLiveId[0] = 0;
                liveIdFlag[0] = this.liveId;
            }
        } else {
            liveIdFlag[0] = this.liveId
            this.devTime = this.time;
        }
        lastLiveId[0] = this.liveId;

        this.ecgFileMixData = new EcgFileMixData(dataArray, index, 0, 0, 0, drawBufferMap, this, preTimeIndex, null, huffmanDecode);

        // console.log("结束解析：" + moment.now(FORMAT_DATE_TIME_FULL_HYPHEN));
    }
}

export default EcgFileLiveData;