/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input, Checkbox, DatePicker} from 'antd';

import DateRangeDivider from "../../../../../components/DateRangeDivider";

import {refreshSearchCondition, searchEcgFiles} from '../../../actions/ECGAction';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {CACHE_SESSION_USER} from "../../../../../constants/Profile";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        let sessionUser = localStorage.getItem(CACHE_SESSION_USER);
        if (sessionUser) {
            sessionUser = JSON.parse(sessionUser);
            console.log(sessionUser);
            let searchCondition = Object.assign({}, this.props.searchCondition);
            searchCondition["mobile"] = sessionUser.mobile;
            this.props.dispatch(refreshSearchCondition(searchCondition));
            this._handleSearch(searchCondition);
        }
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchEcgFiles(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleExport() {
    }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_6_Wrapper_18}>
                                <Input onChange={(value) => {
                                    this._handleChange("code", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})} {...Label_6_Wrapper_18}>
                                <Input onChange={(value) => {
                                    this._handleChange("mac", value)
                                }}/>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_ECG_FIELD_DIAGNOSIS_TIME'})} {...Label_6_Wrapper_18}>
                                <Col span={11}>
                                    <DatePicker style={{width: '100%'}}
                                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                                onChange={(data) => {
                                                    this._handleChange("startDiagnosisTime", data)
                                                }}/>
                                </Col>
                                <Col span={2}>
                                    <DateRangeDivider/>
                                </Col>
                                <Col span={11}>
                                    <DatePicker style={{width: '100%'}}
                                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                                onChange={(data) => {
                                                    this._handleChange("endDiagnosisTime", data)
                                                }}/>

                                </Col>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="download" onClick={() => this._handleExport()}>
                                    <FormattedMessage id='COMMON_BUTTON_EXPORT'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.DASECGReducer.searchCondition,
        pagination: store.DASECGReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));