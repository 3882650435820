/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Icon} from "antd";

import {getEcgFilesCount} from "../../../actions/ECGAction";
import {RoutePath} from "../../../../../constants/RoutePath";

class DevicesSum extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getEcgFilesCount(this.state.formatMessage));
    }

    _handleShowDetailType(showDetailType) {
        // this.props.router.push(RoutePath.DAS_ECG_HISTORY.path);
    }

    render() {
        return (
            <div className="small-box purple">
                <div className="inner">
                    <h3>{this.props.ecgFileCount}</h3>
                    <p><FormattedMessage id="DAS_BINDER_DATA_FIELD_SUM"/></p>
                </div>
                <div className="icon">
                    <Icon type="file" theme="outlined" />
                </div>
                <Link to={RoutePath.DAS_ECG_HISTORY.path}className="small-box-footer" onClick={() => this._handleShowDetailType()}>
                    <FormattedMessage id="COMMON_BUTTON_DETAIL"/> <Icon type="right-circle" />
                </Link></div>
        );
    }
}

function mapStateToProps(store) {
    return {
        ecgFileCount: store.DASECGReducer.ecgFileCount
    }
}

export default connect(mapStateToProps)(injectIntl(DevicesSum));