/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Form, Modal, Input, Button, Popconfirm} from "antd";
import {
    refreshAnnotation,
    toggleAnnotationModal
} from "../../actions/DeviceFileAnnotationAction";
import DictItemSelect from "../../../Dict/components/DictItemSelect";
import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";
import {ECG_DEVICE_FILE_ANNOTATION} from "../../../Dict/constants/DictClassCode";
import EnumItemSelect from "../../../../components/EnumItemSelect";
import {AnnotationLabel} from "../../Enums";

//Frame选择查看
class AnnotationModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.props.dispatch(toggleAnnotationModal(false));
    }

    _handleOk() {
        const {annotation, onCreate, onUpdate} = this.props;
        if (this._validate(annotation)) {
            if (annotation.id !== undefined && annotation.id !== "") {
                onUpdate && onUpdate.call(this, annotation);
            } else {
                // onCreate && onCreate.call(this, annotation);
            }
        }
    }

    _handleDelete() {
        const {annotation, onDelete} = this.props;
        onDelete && onDelete.call(this, annotation);
    }

    _validate(annotation) {
        let messageMap = {};

        const formatMessage = this.state.formatMessage;
        if (annotation.deviceCode === undefined || annotation.deviceCode === "") {
            messageMap["deviceCode"] = formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_MSG_DEVICE_CODE_REQUIRED"});
        }
        if (annotation.recordDate === undefined || annotation.recordDate === "") {
            messageMap["recordDate"] = formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_MSG_RECORD_DATE_REQUIRED"});
        }
        if (annotation.historyFileIndex === undefined || annotation.historyFileIndex === "") {
            messageMap["historyFileIndex"] = formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_MSG_HISTORY_FILE_INDEX_REQUIRED"});
        }
        if (annotation.pointIndex === undefined || annotation.pointIndex === "") {
            messageMap["pointIndex"] = formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_MSG_POINT_INDEX_REQUIRED"});
        }
        if (annotation.annotation === undefined || annotation.annotation === "") {
            messageMap["annotation"] = formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_MSG_ANNOTATION_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleChange(name, value) {
        let annotation = Object.assign({}, this.props.annotation);
        annotation[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshAnnotation(annotation));
    }

    render() {
        const {formatMessage, messageMap} = this.state;
        const {annotation} = this.props;

        const footerButtons = [
            <Button key="cancel" onClick={() => this._handleCancel()}>
                <FormattedMessage id="COMMON_BUTTON_CANCEL"/>
            </Button>,
            <Button key="submit" type="primary" onClick={() => this._handleOk()}>
                <FormattedMessage id="COMMON_BUTTON_SAVE"/>
            </Button>];

        if (annotation && annotation.id) {
            footerButtons.push(
                <Popconfirm key="delete" title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete()}>
                    <Button type="danger">
                        <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                    </Button>
                </Popconfirm>
            )
        }

        return (
            <Modal
                title={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_TITLE_ANNOTATION"})}
                maskClosable={false}
                visible={this.props.showAnnotationModal}
                onCancel={() => this._handleCancel()}
                footer={footerButtons}>
                <Form>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_DEVICE_CODE"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["deviceCode"] ? "error" : ""}
                        help={messageMap["deviceCode"]}>
                        <Input value={annotation && annotation.deviceCode} disabled={true}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_RECORD_DATE"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["recordDate"] ? "error" : ""}
                        help={messageMap["recordDate"]}>
                        <Input value={annotation && annotation.recordDate} disabled={true}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_INDEX"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["historyFileIndex"] ? "error" : ""}
                        help={messageMap["historyFileIndex"]}>
                        <Input value={annotation && annotation.historyFileIndex} disabled={true}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_POINT_INDEX"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["pointIndex"] ? "error" : ""}
                        help={messageMap["pointIndex"]}>
                        <Input value={annotation && annotation.pointIndex} disabled={true}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_ANNOTATION"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={messageMap["annotation"] ? "error" : ""}
                        help={messageMap["annotation"]}>
                        <EnumItemSelect data={AnnotationLabel.List}
                                        value={annotation && annotation.annotation}
                                        onChange={(data) => {
                                            this._handleChange("annotation", data)
                                        }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_REMARKS"})} {...Label_6_Wrapper_18}>
                        <Input.TextArea value={annotation && annotation.remarks} onChange={(value) => {
                            this._handleChange("remarks", value)
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        // annotation: store.EcgDeviceFileAnnotationReducer.annotation,
        showAnnotationModal: store.EcgDeviceFileAnnotationReducer.showAnnotationModal,
        // operations: store.EcgAnnotationToolReducer.operations,
        // operationIndex: store.EcgAnnotationToolReducer.operationIndex
    }
}

export default connect(mapStateToProps)(injectIntl(AnnotationModal));