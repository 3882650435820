import Medfilt from "./d9k_medfilt";

export default class MedfiltDeWander {
    constructor(nLeads, nFilterSz, nDataLen) {
        this.times = 0;
        this.nLeads = nLeads;
        this.nFilterSz = nFilterSz;
        if (nFilterSz > ((nFilterSz >> 1) << 1))
            nFilterSz++;
        this.nFilterSize2 = nFilterSz >> 1;
        this.nDataLen = nDataLen;
        this.prevWinData = new Array(nLeads);
        this.filters = new Array(nLeads);
        if (this.nFilterSize2 < nDataLen) {
            this.nPrevWinLen = this.nFilterSize2;
        } else {
            this.nPrevWinLen = this.nFilterSize2 + nDataLen;
        }
        for (let i = 0; i < nLeads; i++) {
            this.prevWinData[i] = new Array(this.nPrevWinLen);
            for (let j = 0; j < this.nPrevWinLen; j++) {
                this.prevWinData[i][j] = 0;
            }
            this.filters[i] = new Medfilt(nFilterSz);
        }
    }

    // data is size of skip*nLeads
    init1(data, skip) {
        let nLeads = this.nLeads;
        let nFilterSz2 = this.nFilterSz >> 1;
        for (let i = 0; i < nLeads; i++) {
            if (skip > this.nPrevWinLen) {
                for (let j = 0; j < nFilterSz2; j++) {
                    this.filters[i].insert(data[i * skip + nFilterSz2 - 1 - j]);
                }
            } else {
                let firstEle = data[i * skip];
                for (let j = 0; j < nFilterSz2; j++) {
                    this.filters[i].insert(firstEle);
                }
            }
        }
    }

    // data is 1d-array, store 2d-data
    dewander(data) {
        let nDataLen = this.nDataLen;
        let nLeads = this.nLeads;
        let nPrevWinLen = this.nPrevWinLen;
        if (data.length != (nDataLen * nLeads))
            return null;
        let output = new Array(nDataLen * nLeads);
        let dLen = nPrevWinLen - nDataLen;
        let output0 = new Array(nDataLen);
        for (let i = 0; i < nLeads; i++) {
            this.filters[i].stream(data, i * nDataLen, nDataLen, output0, 0);
            if (dLen < 0) {
                for (let j = 0; j < nPrevWinLen; j++) {
                    output[i * nDataLen + j] = this.prevWinData[i][j] - output0[j];
                }
                this.prevWinData[i] = data.slice(i * nDataLen + nDataLen - nPrevWinLen, i * nDataLen + nDataLen);
                for (let j = nPrevWinLen; j < nDataLen; j++) {
                    output[i * nDataLen + j] = data[i * nDataLen + j - nPrevWinLen] - output0[j];
                }
            } else {
                this.prevWinData[i] = this.prevWinData[i].slice(nDataLen, nDataLen + dLen).concat(data.slice(i * nDataLen, i * nDataLen + nDataLen));
                for (let j = 0; j < nDataLen; j++) {
                    output[i * nDataLen + j] = this.prevWinData[i][j] - output0[j];
                }
            }
        }
        return output;
    }
}
