/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    Modal, Form, Row, Col, Input, Tabs
} from "antd";

import moment from 'moment';

import {
    refreshCacheDevice, toggleCacheModal, updateDeviceRedis,
} from "../../../actions/DeviceAction";
import {ChargeState, DeviceLogLevel, EcgFileSource, LeadState, OnlineState, SysState} from "../../../Enums";
import {Label_12_Wrapper_12} from "../../../../../constants/FormItemLayout";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import DetailModalDeviceTab from "./DetailModalDeviceTab";
import DeviceModalLogTab from "./DeviceModalLogTab";
import DeviceMapTab from "./DeviceMapTab";

class CacheDeviceModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _handleOk() {
        this.props.dispatch(updateDeviceRedis(this.props.cacheDevice, this.state.formatMessage));
    }

    _handleCancel() {
        this.props.dispatch(toggleCacheModal(false));
    }

    _handleChange(name, value) {
        const device = Object.assign({}, this.props.cacheDevice);
        device[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshCacheDevice(device));
    }

    render() {
        const {cacheDevice} = this.props;
        const {formatMessage} = this.state;
        return (
            <Modal
                title={"缓存设备数据"}
                maskClosable={false}
                width={1200} style={{top: '10px'}}
                visible={this.props.showCacheModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_TITLE_BASIC'})} key="1">
                        <Form>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.code} onChange={(data) => {
                                            this._handleChange("code", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_COLOR'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.color} onChange={(data) => {
                                            this._handleChange("color", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_DSP_VERSION'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.dspVersion} onChange={(data) => {
                                            this._handleChange("dspVersion", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_ESP_VERSION'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.espVersion} onChange={(data) => {
                                            this._handleChange("espVersion", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_TIME_DIFF'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.timeDiff} onChange={(data) => {
                                            this._handleChange("timeDiff", data)
                                        }} addonAfter="秒"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_SAMPLE_RATE'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.sampleRate} onChange={(data) => {
                                            this._handleChange("sampleRate", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_SAMPLE_RESOLUTION'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.sampleResolution} onChange={(data) => {
                                            this._handleChange("sampleResolution", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_BATT_VOLUME'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.battVolume} onChange={(data) => {
                                            this._handleChange("battVolume", data)
                                        }} addonAfter="%"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_CHARGE_STATE'})} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={ChargeState.List} value={cacheDevice.chargeState}
                                                        onChange={(data) => {
                                                            this._handleChange("chargeState", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {/*<Col span={8}>*/}
                                {/*    <Form.Item*/}
                                {/*        label={formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'})} {...Label_12_Wrapper_12}>*/}
                                {/*        <EnumItemSelect data={SysState.List} value={cacheDevice.sysState} onChange={(data) => {*/}
                                {/*            this._handleChange("sysState", data)*/}
                                {/*        }}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: "DAS_DEVICE_FIELD_WEB_SUBSCRIBER_NUM"})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.webSubscriberNum} onChange={(data) => {
                                            this._handleChange("webSubscriberNum", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_BATTERY_VOLTAGE'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.batteryVoltage} onChange={(data) => {
                                            this._handleChange("batteryVoltage", data)
                                        }} addonAfter="mV"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BQ27441'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.tempBq27441} onChange={(data) => {
                                            this._handleChange("tempBq27441", data)
                                        }} addonAfter="°C"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BLE'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.tempBle} onChange={(data) => {
                                            this._handleChange("tempBle", data)
                                        }} addonAfter="°C"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BAT'})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.tempBat} onChange={(data) => {
                                            this._handleChange("tempBat", data)
                                        }} addonAfter="°C"/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"设备实时传输状态"} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={OnlineState.List}
                                                        value={cacheDevice.devLiveOnlineStatus}
                                                        onChange={(data) => {
                                                            this._handleChange("devLiveOnlineStatus", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label={"设备历史传输状态"} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={OnlineState.List}
                                                        value={cacheDevice.devHisOnlineStatus}
                                                        onChange={(data) => {
                                                            this._handleChange("devHisOnlineStatus", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"手机实时传输状态"} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={OnlineState.List}
                                                        value={cacheDevice.appLiveOnlineStatus}
                                                        onChange={(data) => {
                                                            this._handleChange("appLiveOnlineStatus", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"手机历史传输状态"} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={OnlineState.List}
                                                        value={cacheDevice.appHisOnlineStatus}
                                                        onChange={(data) => {
                                                            this._handleChange("appHisOnlineStatus", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label={"最大历史数据文件编号"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.writeFileIndex} onChange={(data) => {
                                            this._handleChange("writeFileIndex", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"未删除最小历史数据文件编号"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.notEasedFileIndex} onChange={(data) => {
                                            this._handleChange("notEasedFileIndex", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"已读取最小历史数据文件编号"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.readFileIndex} onChange={(data) => {
                                            this._handleChange("readFileIndex", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {/*<Col span={8}>*/}
                                {/*    <Form.Item*/}
                                {/*        label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_WIFI_NUM"})} {...Label_12_Wrapper_12}>*/}
                                {/*        <Input value={cacheDevice.wifiNum} onChange={(data) => {*/}
                                {/*            this._handleChange("wifiNum", data)*/}
                                {/*        }}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                {/*<Col span={8}>*/}
                                {/*    <Form.Item*/}
                                {/*        label={formatMessage({id: "DAS_DEVICE_FIELD_DEVICES_TIME"})} {...Label_12_Wrapper_12}>*/}
                                {/*        <Input value={cacheDevice.deviceTime} onChange={(data) => {*/}
                                {/*            this._handleChange("deviceTime", data)*/}
                                {/*        }}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_SOURCE"})} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={EcgFileSource.List} value={cacheDevice.lastHisDataSource}
                                                        onChange={(data) => {
                                                            this._handleChange("lastHisDataSource", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LOG_LEVEL"})} {...Label_12_Wrapper_12}>
                                        <EnumItemSelect data={DeviceLogLevel.List} value={cacheDevice.logLevel}
                                                        onChange={(data) => {
                                                            this._handleChange("logLevel", data)
                                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"自动升级"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.firmwareAutoUpgrade} onChange={(data) => {
                                            this._handleChange("firmwareAutoUpgrade", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col span={8}>
                                    <Form.Item label={"DSP版本创建时间"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.dspFirmwareCreateTime} onChange={(data) => {
                                            this._handleChange("dspFirmwareCreateTime", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label={"ESP版本创建时间"} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.espFirmwareCreateTime} onChange={(data) => {
                                            this._handleChange("espFirmwareCreateTime", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_TIME"})} {...Label_12_Wrapper_12}>
                                        <Input value={cacheDevice.lastHisDataReceivedTime} onChange={(data) => {
                                            this._handleChange("lastHisDataReceivedTime", data)
                                        }}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})} key="2">
                        <Row gutter={14}>
                            <Col span={8}>
                                <Form.Item label={"RA电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadAState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadAState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={"V2电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadEState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadEState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={"LA电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadBState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadBState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={14}>
                            <Col span={8}>
                                <Form.Item label={"V3电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadFState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadFState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={"LL电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadCState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadCState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={"V4电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadGState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadGState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={14}>
                            <Col span={8}>
                                <Form.Item label={"V1电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadDState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadDState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={"V5电极状态"} {...Label_12_Wrapper_12}>
                                    <EnumItemSelect data={LeadState.List}
                                                    value={cacheDevice.leadHState}
                                                    onChange={(data) => {
                                                        this._handleChange("leadHState", data)
                                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Tabs.TabPane>

                </Tabs>


            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceReducer.isLoading,
        device: store.EcgDeviceReducer.device,
        cacheDevice: store.EcgDeviceReducer.cacheDevice,
        showCacheModal: store.EcgDeviceReducer.showCacheModal,
    }
}

export default connect(mapStateToProps)(injectIntl(CacheDeviceModal));