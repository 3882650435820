import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    showDetailModal: false,
    file: {},
    files: [],
    // Key: contentId
    // Value: contentFiles
    contentFileMap:{}
};

const ContentFileReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.CMS_CONTENT_FILE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.CMS_CONTENT_FILE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.CMS_CONTENT_FILE_REFRESH_FILE:
            return Object.assign({}, state, {
                file: action.file
            });
        case ActionTypes.CMS_CONTENT_FILE_REFRESH_FILES:
            return Object.assign({}, state, {
                files: action.files
            });
        case  ActionTypes.CMS_CONTENT_FILE_REFRESH_CONTENT_FILE_MAP:
            return Object.assign({}, state, {
                contentFileMap: action.contentFileMap
            });
        default:
            return state
    }
};

export default ContentFileReducer;