import React, {createContext} from "react";
import ProvinceChart from "./ProvinceChart";
import mqtt from "mqtt";
import HttpUtils from "../../../../utils/HttpUtils";
import HCanvas from "./HCanvas";
import HCanvas2 from "./HCanvas2";
import {Button} from "antd";
import {FormattedMessage} from "react-intl";

export const QosOption = createContext([]);

let client;
let dataSource = [];
let dataSourceName = [];
let lineThickness = [];
let data = [];
let verticalMin;
let scale;
let lineColor = [];
let YStart = [];

//手机
let activeTaskCountData = [];
let corePoolSize = [];
let datatime = [];
let memory = [];
//web
let deviceId = null;
let activeTaskCount = [];
let cpu = [];

class ClassMqtt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentWillMount() {

        //手机
        activeTaskCountData = [];
        corePoolSize = [];
        datatime = [];
        memory = [];

        //web

        const userId = HttpUtils.getQueryString("userId");
        deviceId = HttpUtils.getQueryString("deviceId")
        // const userName = HttpUtils.getQueryString("userName");

        if (deviceId) {
            for (var i = 0; i < 100; i++) {
                activeTaskCount.push(5);
                cpu.push(10);
                memory.push(15);
                datatime.push(0)
            }
        } else {
            for (var i = 0; i < 100; i++) {
                activeTaskCountData.push(5);
                corePoolSize.push(10);
                memory.push(15);
                datatime.push(0)
            }
        }

        // for (var i = 0; i < 10; i++) {
        //     datatime.push(0)
        // }

        dataSource = [activeTaskCountData, corePoolSize, memory];

        // mqtt 连接
        client = mqtt.connect('ws://140.206.121.162:7768/mqtt');
        client.on('connect', function () {
            if (deviceId) {

                // client.subscribe('D9K_MQTT/dW5kZWZpbmVk/' + deviceId + '/' + userId + '/#', function (err) {
                client.subscribe('D9K_MQTT/dW5kZWZpbmVk/' + deviceId + '/' + userId + '/#', function (err) {
                    if (!err) {
                        console.log("subscribe successed");
                    }
                })
            } else {
                client.subscribe('D9K_MQTT/APPPERFORM/' + userId + '/#', function (err) {
                    if (!err) {
                        console.log("subscribe successed");
                    }
                })
            }


        })

        //mqtt 接收信息
        client.on('message', function (topic, message) {
            // message is Buffer
            console.log(topic + ":" + message.toString())
            var obj = JSON.parse(message);

            if (deviceId) {
                cpu.splice(0, 1);
                cpu.push(obj.cpu)
                activeTaskCount.splice(0, 1);
                activeTaskCount.push(obj.activeTaskCount);
            } else {
                var url = window.location.href; //获取当前页面的url
                url = decodeURI(url);//转码
                var len = url.length; //获取url的长度值
                var a = url.indexOf("?"); //获取第一次出现？的位置下标
                var b = url.substr(a + 1, len); //截取问号之后的内容
                var c = b.split("&"); //从指定的地方将字符串分割成字符串数组
                var arr = new Array(); //新建一个数组

                for (var i = 0; i < c.length; i++) {
                    var d = c[i].split("=")[1]; //从=处将字符串分割成字符串数组,并选择第2个元素
                    arr.push(d); //将获取的元素存入到数组中
                }
                if (obj.activeTaskCount != null || obj.activeTaskCount != undefined || obj.activeTaskCount != "") {
                    activeTaskCountData.splice(0, 1);
                    activeTaskCountData.push(obj.activeTaskCount);
                } else {
                    activeTaskCountData.splice(0, 1);
                    activeTaskCountData.push(obj.activeThreadCount);
                }
                corePoolSize.splice(0, 1);
                corePoolSize.push(obj.corePoolSize);
            }
            var datetimeV = obj.datetime.toString().substr(10, 10);
            console.log("dataTime", datetimeV)
            datatime.splice(0, 1);
            datatime.push(datetimeV);
            memory.splice(0, 1);
            memory.push(obj.memory);

            // dataSource = [activeTaskCountData, corePoolSize, memory];

            // self.setState({"dataSource": dataSource});
        });

    }

    componentDidMount() {

        const self = this;

        // const userId = HttpUtils.getQueryString("userId");
        // const userName = HttpUtils.getQueryString("userName");

        console.log("数据发送")
        // dataSource = [activeTaskCount, cpu, memory];
        console.log("deviceId:", deviceId)
        if (deviceId) {
            dataSourceName = ["activeTaskCount", "cpu", "memory"]
            dataSource = [activeTaskCount, cpu, memory];
        } else {
            dataSourceName = ["activeTaskCountData", "corePoolSize", "memory"]
            dataSource = [activeTaskCountData, corePoolSize, memory];
        }
        lineThickness = [1, 2, 3]
        verticalMin = [0, 0, 0];
        scale = [1, 2, 3];
        lineColor = ["red", "blue", "green"]
        YStart = [true, true, true];
        data = [dataSource, dataSourceName, lineThickness, verticalMin, scale, lineColor, YStart, datatime];
        self.setState({"data": data});

    }


// <Button type="primary" loading={this.state.searchFlag} icon="search"
// className="spacing-h"
// onClick={() => this._handleSearch()}>
// <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
// </Button>

//<ProvinceChart/>
    render() {

        return (
            <div>
                <Button onClick={() => this._handleXS()}>
                    x轴起始点 开/关
                </Button>
                <HCanvas id="myCanvas" data={this.state.data}/>
            </div>
        );
    }

    _handleXS() {
        for (var i = 0; i < YStart.length; i++) {
            YStart[i] = !YStart[i]
        }
        data = [dataSource, dataSourceName, lineThickness, verticalMin, scale, lineColor, YStart, datatime];
        this.setState({"data": data});
    }


    componentWillUnmount() {
        this._handleStopSearch();
    }

    _handleStopSearch() {
        client.end();
    }
}

export default ClassMqtt;
