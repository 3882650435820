import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";
import message from "../../../components/toast";

export function refreshSystemProperties(cacheSystemProperties) {
    return {
        type: ActionTypes.ECG_SYSTEM_REFRESH_PROPERTIES,
        cacheSystemProperties: cacheSystemProperties
    }
}

export function getSystemRedis(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_SYSTEM_LOADING, isLoading: true});

        HttpUtils.get(WebService.getSystemRedis, (response) => {
            dispatch({
                type: ActionTypes.ECG_SYSTEM_REFRESH_PROPERTIES,
                cacheSystemProperties: response.data
            });

            dispatch({type: ActionTypes.ECG_SYSTEM_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DAS_NCOV_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateSystemRedis(cacheSystemProperties, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_SYSTEM_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateSystemRedis, cacheSystemProperties, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_SYSTEM_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DAS_NCOV_LOADING, isLoading: false});
        }, formatMessage)
    }
}