import React, {Component} from 'react';
import {Descriptions, Slider} from 'antd';
import './TimeIndexSlider.css'
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../../constants/DateTimeFormats";

import moment from "moment";

export default class TimeIndexSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 0,
        }
    }

    componentDidMount() {
    }

    /** 子组件方法传递给父组件 */
    onPlaySliderChange = (value) => {
        this.props.onAfterChange && this.props.onAfterChange(value);
    };

    onChange = (value) => {
        this.props.onChange && this.props.onChange(value);
    };

    render() {

        const ecgSliderNodes = document.getElementsByClassName("ecg-slider");
        const ecgSliderTrackNodes = document.getElementsByClassName("ant-slider-track");
        // const ecgSliderTrack = <div className="ant-slider-track" style="left: 0%; right: auto; width: 100%;"></div>
        if (ecgSliderNodes && ecgSliderNodes.length > 0) {

            if (ecgSliderTrackNodes && ecgSliderTrackNodes.length > 0) {
                for (let i = 0; i < ecgSliderTrackNodes.length; i++) {
                    ecgSliderTrackNodes[i].parentNode.removeChild(ecgSliderTrackNodes[i]);
                }
            }

            let left = -1;
            let width = -1;
            if (this.props.ecgTimeIndexData.timeSliceNum !== "") {
                for (let i = 0; i < this.props.ecgTimeIndexData.timeSliceNum; i++) {
                    const record = this.props.ecgTimeIndexData.records[i];

                    if (record && record.historyFileIndex > 0) {
                        if (left < 0) {
                            left = i;
                            width = 0;
                        }
                        width++;
                    }

                    if (!record || record.historyFileIndex <= 0 || (i + 1) === this.props.ecgTimeIndexData.timeSliceNum) {
                        if (width > 0) {
                            const ecgSliderTrackNode = document.createElement("div");
                            ecgSliderTrackNode.className = "ant-slider-track";
                            ecgSliderTrackNode.style.left = ((left / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                            ecgSliderTrackNode.style.right = "auto";
                            ecgSliderTrackNode.style.width = ((width / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                            ecgSliderNodes[0].appendChild(ecgSliderTrackNode);
                            left = -1;
                            width = -1;
                        }
                    }
                }
            }

        }

        return (
            <div>
                <div id="time_index_slider" className="ecg-slider-container">
                    <Descriptions size="small" column={6}>
                        <Descriptions.Item
                            label="日期">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.date : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="文件时长">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.miniSecPerFile : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="记录条数">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.recordNum : ""}</Descriptions.Item>
                        {/*<Descriptions.Item*/}
                            {/*label="非空记录条数">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.timeNum : ""}</Descriptions.Item>*/}
                        {/*<Descriptions.Item*/}
                            {/*label="记录总时长">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.workTime : ""}</Descriptions.Item>*/}
                        {/*<Descriptions.Item*/}
                            {/*label="时间隙数量">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.timeSliceNum : ""}</Descriptions.Item>*/}
                    </Descriptions>
                    {/*<div className="ecg-slider-overlay" ref={this.containerRef}/>*/}
                    <Slider className="ecg-slider"
                        // tooltipVisible={true}
                            value={this.props.sliderValue}
                            marks={this.props.ecgTimeSlideMarks}
                            onChange={(value) => this.onChange(value)}
                            onAfterChange={(value) => this.onPlaySliderChange(value)}
                        // onDrop = {(value) => this.onPlaySliderChange(value)}
                            max={this.props.ecgTimeIndexData.timeSliceNum}
                            tipFormatter={() => {
                                const sliderValueMS = this.props.sliderValue * this.props.ecgTimeIndexData.miniSecPerFile
                                const dateMoment = moment(this.props.ecgTimeIndexData.date, FORMAT_DATE_SIMPLE).millisecond(sliderValueMS);
                                return dateMoment.format(FORMAT_TIME_COLON);
                            }}
                    />
                </div>
            </div>
        );
    };
}
