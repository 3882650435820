import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {ACCESS_TOKEN} from '../../../constants/Profile';
import HttpUtils from '../../../utils/HttpUtils';
import message from "../../../components/toast";
import {RoutePath} from "../../../constants/RoutePath";

export function refreshEmployee(employee) {
    return {
        type: ActionTypes.ORG_EMPLOYEE_REFRESH_EMPLOYEE,
        employee: employee
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ORG_EMPLOYEE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.ORG_EMPLOYEE_TOGGLE_DETAIL_MODAL,
        showDetailModal: showDetailModal
    }
}

export function getEmployeeDetailById(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        let requestURL = WebService.getEmployee.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshEmployee(response.data));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getEmployeeByUserId(userId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        let requestURL = WebService.getEmployeeByUserId.replace("{userId}", userId);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshEmployee(response.data));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchEmployees(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchEmployees, condition, (response) => {
            dispatch({
                type: ActionTypes.ORG_EMPLOYEE_SEARCH,
                employees: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function registerEmployee(employee, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.registerEmployee, employee, (response) => {
            message.success(formatMessage({id: "ORG_EMPLOYEE_MSG_REGISTER_SUCCESS"}));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createEmployee(employee, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createEmployee, employee, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshEmployee({}));
            dispatch(searchEmployees(condition, formatMessage));
            message.success(formatMessage({id: "ORG_EMPLOYEE_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateEmployee(employee, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});

        let requestURL = WebService.updateEmployee.replace("{id}", employee.id);

        HttpUtils.put(requestURL, employee, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshEmployee({}));
            dispatch(searchEmployees(condition, formatMessage));
            message.success(formatMessage({id: "ORG_EMPLOYEE_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteEmployee(employee, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: true});
        let requestURL = WebService.deleteEmployee.replace("{id}", employee.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(searchEmployees(condition, formatMessage));
            message.success(formatMessage({id: "ORG_EMPLOYEE_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.ORG_EMPLOYEE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}