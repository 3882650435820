/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Descriptions, Table, Tag} from "antd";
import HexUtils from "../../../../utils/HexUtils";
import FileUtils from "../../../../utils/FileUtils";

class FrameSelectModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'startPosition',
                    dataIndex: 'startPosition',
                    title: formatMessage({id: 'ECG_DEVICE_FILE_ANNOTATION_FIELD_START_POSITION'})
                }, {
                    key: 'endPosition',
                    dataIndex: 'endPosition',
                    title: formatMessage({id: 'ECG_DEVICE_FILE_ANNOTATION_FIELD_END_POSITION'})
                }, {
                    key: 'numberBeats',
                    dataIndex: 'numberBeats',
                    title: formatMessage({id: 'ECG_DEVICE_FILE_ANNOTATION_FIELD_NUMBER_BEATS'})
                }, {
                    key: 'numberAnnotation',
                    dataIndex: 'numberAnnotation',
                    title: formatMessage({id: 'ECG_DEVICE_FILE_ANNOTATION_FIELD_NUMBER_ANNOTATION'})
                }, {
                    key: 'historyFileIndex',
                    dataIndex: 'historyFileIndex',
                    title: formatMessage({id: 'ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_INDEX'}),
                    render: (text, record, index) => this._getEcgFileName(text)
                }, {
                    key: 'status',
                    dataIndex: 'status',
                    title: formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS"}),
                    render: (text, record, index) => record.numberAnnotation > 0 ?
                        <Tag color="green">
                            <FormattedMessage id="ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS_COMPLETE"/>
                        </Tag> :
                        <Tag color="red">
                            <FormattedMessage id="ECG_DEVICE_FILE_ANNOTATION_FIELD_HISTORY_FILE_STATUS_NONE"/>
                        </Tag>
                }, {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleSelect.bind(this, record, index)}>
                    <FormattedMessage id="COMMON_BUTTON_SELECT"/>
                </a>
            </div>
        )
    }

    _handleSelect(record, index) {
        this.props.onSelect && this.props.onSelect.call(this, record, index);
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.props.onCancel && this.props.onCancel.call(this);
    }

    _handleOk() {

    }

    render() {
        const {formatMessage, columns} = this.state;
        const {ecgTimeIndexData, showFrameSelectModal, annotationsMap} = this.props;
        console.log(ecgTimeIndexData);
        console.log(annotationsMap);
        const records = [];

        if (ecgTimeIndexData.records && ecgTimeIndexData.records.length > 0) {
            for (let i = 0; i < ecgTimeIndexData.records.length; i++) {
                records.push(ecgTimeIndexData.records[i]);
                records[i].index = i;
                records[i].startPosition = i * this.props.sampleNum + 1;
                records[i].endPosition = i * this.props.sampleNum + this.props.sampleNum;
                records[i].numberBeats = 0;
                records[i].numberAnnotation = 0;
                // 如果标注的Map中存在该历史索引文件Id，则需要计算有多少个值，否则都是零
                const fileName = this._getEcgFileName(records[i].historyFileIndex);
                if (annotationsMap[fileName]) {
                    const annotationPointMap = annotationsMap[fileName];
                    for (let pointIndex of Object.keys(annotationPointMap)) {
                        if (annotationPointMap[pointIndex].deleteFlag === 1) {
                            continue;
                        }

                        records[i].numberBeats = records[i].numberBeats + 1;
                        if (annotationPointMap[pointIndex].annotation !== "NULL") {
                            records[i].numberAnnotation = records[i].numberAnnotation + 1;
                        }
                    }
                }
            }
        }
        return (
            <Modal
                title={formatMessage({id: "ECG_DEVICE_FILE_ANNOTATION_TITLE_FRAME_SELECT"})}
                maskClosable={false}
                width={600}
                visible={showFrameSelectModal}
                onCancel={() => this._handleCancel()}
                footer={null}>
                <Table rowKey="index" size="small" className="spacing-v" columns={columns}
                       dataSource={records}/>
            </Modal>
        );
    }

    _getEcgFileName(historyFileIndex) {
        const historyFileIndexHex = HexUtils.intToHexEcg(Number(historyFileIndex), 4);
        return this.props.deviceCode + "_" + historyFileIndexHex;
    }
}

function mapStateToProps(store) {
    return {
        // showFrameSelectModal: store.EcgAnnotationToolReducer.showFrameSelectModal,
        ecgDateIndexData: store.EcgHistoryReducer.ecgDateIndexData,
        ecgTimeIndexData: store.EcgHistoryReducer.ecgTimeIndexData
    }
}

export default connect(mapStateToProps)(injectIntl(FrameSelectModal));