export const LiveDataChannel = {
    List: [
        {value: "10", label: "DAS_ECG_FIELD_CHANNEL_TYPE_DEVICE"},
        {value: "20", label: "DAS_ECG_FIELD_CHANNEL_TYPE_MOBILE"}
    ], Map: {
        "10": "DAS_ECG_FIELD_CHANNEL_TYPE_DEVICE",
        "20": "DAS_ECG_FIELD_CHANNEL_TYPE_MOBILE",
    }, Enum: {
        Device: "10",
        Mobile: "20"
    }
};

export const EcgFileSource = {
    List: [
        {value: "0", label: "ECG_FILE_FIELD_SOURCE_DEVICE"},
        {value: "1", label: "ECG_FILE_FIELD_SOURCE_MOBILE"},
        {value: "2", label: "ECG_FILE_FIELD_SOURCE_SERVER"},
    ], Map: {
        "0": "ECG_FILE_FIELD_SOURCE_DEVICE",
        "1": "ECG_FILE_FIELD_SOURCE_MOBILE",
        "2": "ECG_FILE_FIELD_SOURCE_SERVER"
    }, Enum: {
        Device: "0",
        Mobile: "1",
        Server: "2"
    }
};

export const DestType = {
    List: [
        {value: "0", label: "DAS_ECG_FIELD_DEST_TYPE_DEVICE"},
        {value: "1", label: "DAS_ECG_FIELD_DEST_TYPE_MOBILE"},
        {value: "2", label: "DAS_ECG_FIELD_DEST_TYPE_SERVER"},
        {value: "3", label: "DAS_ECG_FIELD_DEST_TYPE_WEB"}
    ], Map: {
        "0": "DAS_ECG_FIELD_DEST_TYPE_DEVICE",
        "1": "DAS_ECG_FIELD_DEST_TYPE_MOBILE",
        "2": "DAS_ECG_FIELD_DEST_TYPE_SERVER",
        "3": "DAS_ECG_FIELD_DEST_TYPE_WEB",
    }, Enum: {
        Device: "0",
        Mobile: "1",
        Server: "2",
        Web: "3"
    }
};

export const ChartSpeed = {
    List: [
        {value: 0.5, label: "DAS_ECG_FIELD_CHART_SPEED_HALF"},
        {value: 1, label: "DAS_ECG_FIELD_CHART_SPEED_ONE"},
        {value: 2, label: "DAS_ECG_FIELD_CHART_SPEED_TWO"},
        {value: 4, label: "DAS_ECG_FIELD_CHART_SPEED_FOUR"},
        {value: 8, label: "DAS_ECG_FIELD_CHART_SPEED_EIGHT"},
    ], Map: {
        0.5: "DAS_ECG_FIELD_CHART_SPEED_HALF",
        1: "DAS_ECG_FIELD_CHART_SPEED_ONE",
        2: "DAS_ECG_FIELD_CHART_SPEED_TWO",
        4: "DAS_ECG_FIELD_CHART_SPEED_FOUR",
        8: "DAS_ECG_FIELD_CHART_SPEED_EIGHT",
    }, Enum: {
        half: 0.5,
        one: 1,
        two: 2,
        four: 4,
        eight: 8
    }
};

export const lorenzType = {
    List: [
        {value: 1, label: "RR"},
        {value: 2, label: "DRR"},
        {value: 3, label: "RR间期"},
    ]
}

export const Year = {
    List: [
        {value: 2022, label: "2022"},
        {value: 2021, label: "2021"},
        {value: 2020, label: "2020"},
        {value: 2019, label: "2019"},
        {value: 2018, label: "2018"},
        {value: 2017, label: "2017"},
        {value: 2016, label: "2016"},
        {value: 2015, label: "2015"},
        {value: 2014, label: "2014"},
        {value: 2013, label: "2013"},
        {value: 2012, label: "2012"},
        {value: 2011, label: "2011"},
        {value: 2010, label: "2010"},
        {value: 2009, label: "2009"},
        {value: 2008, label: "2008"},
    ]
}


export const Month = {
    List: [
        {value: 12, label: "12"},
        {value: 11, label: "11"},
        {value: 10, label: "10"},
        {value: 9, label: "9"},
        {value: 8, label: "8"},
        {value: 7, label: "7"},
        {value: 6, label: "6"},
        {value: 5, label: "5"},
        {value: 4, label: "4"},
        {value: 3, label: "3"},
        {value: 2, label: "2"},
        {value: 1, label: "1"},
    ]
}

export const userModel = {
    List: [
        {value: 0, label: "全部"},
        {value: 1, label: "监护他人"},
        {value: 2, label: "自我监护"},
        {value: 3, label: "监护自己和他人"}
    ]
}

export const VoltDiv = {
    List: [
        {value: 0.009765625, label: "DAS_ECG_FIELD_VOLT_DIV_1_1024"},
        {value: 0.01953125, label: "DAS_ECG_FIELD_VOLT_DIV_1_512"},
        {value: 0.0390625, label: "DAS_ECG_FIELD_VOLT_DIV_1_256"},
        {value: 0.078125, label: "DAS_ECG_FIELD_VOLT_DIV_1_128"},
        {value: 0.15625, label: "DAS_ECG_FIELD_VOLT_DIV_1_64"},
        {value: 0.3125, label: "DAS_ECG_FIELD_VOLT_DIV_1_32"},
        {value: 0.625, label: "DAS_ECG_FIELD_VOLT_DIV_1_16"},
        {value: 1.25, label: "DAS_ECG_FIELD_VOLT_DIV_1_8"},
        {value: 2.5, label: "DAS_ECG_FIELD_VOLT_DIV_1_4"},
        {value: 5, label: "DAS_ECG_FIELD_VOLT_DIV_1_2"},
        {value: 10, label: "DAS_ECG_FIELD_VOLT_DIV_1"},
        {value: 20, label: "DAS_ECG_FIELD_VOLT_DIV_2"},
        {value: 40, label: "DAS_ECG_FIELD_VOLT_DIV_4"},
        {value: 80, label: "DAS_ECG_FIELD_VOLT_DIV_8"},
        {value: 160, label: "DAS_ECG_FIELD_VOLT_DIV_16"},
    ], Map: {
        0.009765625: "DAS_ECG_FIELD_VOLT_DIV_1_1024",
        0.01953125: "DAS_ECG_FIELD_VOLT_DIV_1_512",
        0.0390625: "DAS_ECG_FIELD_VOLT_DIV_1_256",
        0.078125: "DAS_ECG_FIELD_VOLT_DIV_1_128",
        0.15625: "DAS_ECG_FIELD_VOLT_DIV_1_64",
        0.3125: "DAS_ECG_FIELD_VOLT_DIV_1_32",
        0.625: "DAS_ECG_FIELD_VOLT_DIV_1_16",
        1.25: "DAS_ECG_FIELD_VOLT_DIV_1_8",
        2.5: "DAS_ECG_FIELD_VOLT_DIV_1_4",
        5: "DAS_ECG_FIELD_VOLT_DIV_1_2",
        10: "DAS_ECG_FIELD_VOLT_DIV_1",
        20: "DAS_ECG_FIELD_VOLT_DIV_2",
        40: "DAS_ECG_FIELD_VOLT_DIV_4",
        80: "DAS_ECG_FIELD_VOLT_DIV_8",
        160: "DAS_ECG_FIELD_VOLT_DIV_16",
    }
};

export const leadName = {
    List: [
        {value: 1, label: "呼吸"},
        {value: 2, label: "I"},
        {value: 3, label: "II"},
        {value: 4, label: "V2"},
        {value: 5, label: "V3"},
        {value: 6, label: "V4"},
        {value: 7, label: "V5"},
        {value: 8, label: "V1"},
        {value: 9, label: "III"},
        {value: 10, label: "AVR"},
        {value: 11, label: "AVL"},
        {value: 12, label: "AVF"},
    ]
}

export const PageScanTime = {
    List: [
        {value: 300, label: "DAS_ECG_FIVE_MINUTE"},
        {value: 600, label: "DAS_ECG_TEN_MINUTE"},
        {value: 1200, label: "DAS_ECG_TWENTY_MINUTE"},
        {value: 1800, label: "DAS_ECG_THIRTY_MINUTE"},
    ]
};

export const PageScanResize = {
    List: [
        {value: 1, label: "DAS_ECG_ONE_RESIZE"},
        {value: 2, label: "DAS_ECG_TWO_RESIZE"},
        {value: 3, label: "DAS_ECG_THREE_RESIZE"},
        // {value: 4, label: "DAS_ECG_FOUR_RESIZE"},
    ]
}

export const CH = {
    List: [
        {value: 0, label: "DAS_ECG_CH_0"},
        {value: 1, label: "DAS_ECG_CH_1"},
        {value: 2, label: "DAS_ECG_CH_2"},
        {value: 3, label: "DAS_ECG_CH_3"},
        {value: 4, label: "DAS_ECG_CH_4"},
        {value: 5, label: "DAS_ECG_CH_5"},
        {value: 6, label: "DAS_ECG_CH_6"},
        {value: 7, label: "DAS_ECG_CH_7"},
        {value: 8, label: "DAS_ECG_CH_8"},
    ]
}

export const DataStyle = {
    List: [
        {value: 0, label: "data"},
        {value: 1, label: "data1"},
        {value: 2, label: "data2"},
        {value: 3, label: "data3"},
    ]
}

export const DataSize = {
    List: [
        {value: 1000, label: "thousand"},
        {value: 10000, label: "tenthousand"},
        {value: 100000, label: "onehundredthousand"},
        {value: 1000000, label: "million"},
    ]
}

export const ChargeState = {
    List: [
        {value: "0", label: "DAS_DEVICE_FIELD_CHARGE_STATE_NOT"},
        {value: "1", label: "DAS_DEVICE_FIELD_CHARGE_STATE_ING"},
        {value: "2", label: "DAS_DEVICE_FIELD_CHARGE_STATE_NO_BATT"},
    ], Map: {
        "0": "DAS_DEVICE_FIELD_CHARGE_STATE_NOT",
        "1": "DAS_DEVICE_FIELD_CHARGE_STATE_ING",
        "2": "DAS_DEVICE_FIELD_CHARGE_STATE_NO_BATT",
    }, Enum: {
        Not: "0",
        Ing: "1",
        NoBatt: "2",
    }
};

export const SysState = {
    List: [
        {value: "0", label: "DAS_DEVICE_FIELD_SYS_STATE_NOT"},
        {value: "1", label: "DAS_DEVICE_FIELD_SYS_STATE_RESET"},
        {value: "2", label: "DAS_DEVICE_FIELD_SYS_STATE_WORK"},
        {value: "3", label: "DAS_DEVICE_FIELD_SYS_STATE_SLEEP"},
    ], Map: {
        "0": "DAS_DEVICE_FIELD_SYS_STATE_NOT",
        "1": "DAS_DEVICE_FIELD_SYS_STATE_RESET",
        "2": "DAS_DEVICE_FIELD_SYS_STATE_WORK",
        "3": "DAS_DEVICE_FIELD_SYS_STATE_SLEEP",
    }, Enum: {
        Not: "0",
        Reset: "1",
        Work: "2",
        Sleep: "3",
    }
};

export const LeadState = {
    List: [
        {value: 0, label: "DAS_DEVICE_FIELD_FILE_LEAD_STATE_OFF"},
        {value: 1, label: "DAS_DEVICE_FIELD_FILE_LEAD_STATE_ON"},
    ], Map: {
        0: "DAS_DEVICE_FIELD_FILE_LEAD_STATE_OFF",
        1: "DAS_DEVICE_FIELD_FILE_LEAD_STATE_ON",
    }, Enum: {
        Off: 0,
        On: 1,
    }
};

export const DeviceCodeFilter = {
    List: [
        {value: "0", label: "DAS_DEVICE_FIELD_HEAD_SAME"},
        {value: "1", label: "DAS_DEVICE_FIELD_TAIL_SAME"},
        {value: "2", label: "DAS_DEVICE_FIELD_CONTAIN"},
        {value: "3", label: "DAS_DEVICE_FIELD_BATCH_SEARCH"},
    ], Map: {
        "0": "DAS_DEVICE_FIELD_HEAD_SAME",
        "1": "DAS_DEVICE_FIELD_TAIL_SAME",
        "2": "DAS_DEVICE_FIELD_CONTAIN",
        "3": "DAS_DEVICE_FIELD_BATCH_SEARCH",
    }
};

export const OnlineState = {
    List: [
        {value: 0, label: "DAS_DEVICE_FIELD_ONLINE_STATUS_OFFLINE"},
        {value: 1, label: "DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE_NO_TRANSFER"},
        {value: 3, label: "DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE"},
    ], Map: {
        0: "DAS_DEVICE_FIELD_ONLINE_STATUS_OFFLINE",
        1: "DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE_NO_TRANSFER",
        3: "DAS_DEVICE_FIELD_ONLINE_STATUS_ONLINE",
    }, Enum: {
        Off: 0,
        OnNoTransfer: 1,
        On: 3
    }
};

export const OnlineStatus = {
    List: [
        {value: 0, label: "DAS_DEVICE_FIELD_ONLINE_STATE_OFFLINE"},
        {value: 1, label: "DAS_DEVICE_FIELD_ONLINE_STATE_ONLINE"},
    ], Map: {
        0: "DAS_DEVICE_FIELD_ONLINE_STATE_OFFLINE",
        1: "DAS_DEVICE_FIELD_ONLINE_STATE_ONLINE",
    }, Enum: {
        Off: 0,
        On: 1,
    }
}

export const UserSex = {
    List: [
        {value: "0", label: "ECG_BINDER_FIELD_USER_SEX_UNKNOWN"},
        {value: "1", label: "ECG_BINDER_FIELD_USER_SEX_MALE"},
        {value: "2", label: "ECG_BINDER_FIELD_USER_SEX_FEMALE"},
    ], Map: {
        "0": "ECG_BINDER_FIELD_USER_SEX_UNKNOWN",
        "1": "ECG_BINDER_FIELD_USER_SEX_MALE",
        "2": "ECG_BINDER_FIELD_USER_SEX_FEMALE",
    }, Enum: {
        Unknown: "0",
        Male: "1",
        Female: "2",
    }
};

export const DeviceColor = {
    List: [
        {value: "1", label: "DAS_DEVICE_FIELD_COLOR_WHITE"},
        {value: "2", label: "DAS_DEVICE_FIELD_COLOR_BLUE"},
    ], Map: {
        "1": "DAS_DEVICE_FIELD_COLOR_WHITE",
        "2": "DAS_DEVICE_FIELD_COLOR_BLUE",
    }, Enum: {
        White: "1",
        Blue: "2",
    }
};

export const LogServiceType = {
    List: [
        {value: "DL", label: "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DL"},
        {value: "DH", label: "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DH"},
        {value: "ML", label: "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_ML"},
        {value: "MH", label: "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_MH"},
    ], Map: {
        "DL": "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DL",
        "DH": "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_DH",
        "ML": "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_ML",
        "MH": "ECG_SERVER_LOG_FIELD_SERVICE_TYPE_MH",
    }, Enum: {
        DeviceReal: "DL",
        DeviceHistory: "DH",
        MobileReal: "ML",
        MobileHistory: "MH",
    }
};
export const VIEWPORT_FLAG = {
    CHANGE: "01",
    NO_CHANGE: "02",
};

export const EcgFileType = {
    List: [
        {value: "00", label: "ECG_FILE_TYPE_LIVDATA"},
        {value: "01", label: "ECG_FILE_TYPE_ECGDATA"},
        {value: "02", label: "ECG_FILE_TYPE_MPUDATA"},
        {value: "03", label: "ECG_FILE_TYPE_MIXDATA"},
        {value: "04", label: "ECG_FILE_TYPE_EVNTIDX"},
        {value: "05", label: "ECG_FILE_TYPE_TIMEIDX"},
        {value: "06", label: "ECG_FILE_TYPE_DATEIDX"},
        {value: "07", label: "ECG_FILE_TYPE_LOGGERR"},
        {value: "08", label: "ECG_FILE_TYPE_POWERRR"},
        {value: "09", label: "ECG_FILE_TYPE_DEVICEE"},
        {value: "0A", label: "ECG_FILE_TYPE_AISBINN"},
        {value: "10", label: "ECG_FILE_TYPE_FILELST"},
        {value: "11", label: "ECG_FILE_TYPE_CFGDATA"},
    ], ListValue: [
        {value: "03", label: "历史文件"},
        {value: "04", label: "事件文件"},
        {value: "05", label: "时间文件"},
    ], Map: {
        "00": "ECG_FILE_TYPE_LIVDATA",
        "01": "ECG_FILE_TYPE_ECGDATA",
        "02": "ECG_FILE_TYPE_MPUDATA",
        "03": "ECG_FILE_TYPE_MIXDATA",
        "04": "ECG_FILE_TYPE_EVNTIDX",
        "05": "ECG_FILE_TYPE_TIMEIDX",
        "06": "ECG_FILE_TYPE_DATEIDX",
        "07": "ECG_FILE_TYPE_LOGGERR",
        "08": "ECG_FILE_TYPE_POWERRR",
        "09": "ECG_FILE_TYPE_DEVICEE",
        "0A": "ECG_FILE_TYPE_AISBINN",
        "10": "ECG_FILE_TYPE_FILELST",
        "11": "ECG_FILE_TYPE_CFGDATA",
    }, Enum: {
        LIVDATA: "00", // 心电实时数据
        ECGDATA: "01", // 心电历史数据
        MPUDATA: "02", // MPU6050历史数据
        MIXDATA: "03", // 混合历史数据
        EVNTIDX: "04", // 事件索引文件
        TIMEIDX: "05", // 时间索引文件
        DATEIDX: "06", // 日期索引文件
        LOGGERR: "07", // 调试LOG文件
        POWERRR: "08", // 功耗记录文件
        DEVICEE: "09", // 设备身份文件
        AISBINN: "0A", // DSP固件
        FILELST: "10", // 文件列表文件
        CFGDATA: "11", // 设备配置文件
    }, EnumInt: {
        LIVDATA: 0, // 心电实时数据
        ECGDATA: 1, // 心电历史数据
        MPUDATA: 2, // MPU6050历史数据
        MIXDATA: 3, // 混合历史数据
        EVNTIDX: 4, // 事件索引文件
        TIMEIDX: 5, // 时间索引文件
        DATEIDX: 6, // 日期索引文件
        LOGGERR: 7, // 调试LOG文件
        POWERRR: 8, // 功耗记录文件
        DEVICEE: 9, // 设备身份文件
        AISBINN: 10, // DSP固件
        FILELST: 16, // 文件列表文件
        CFGDATA: 17, // 设备配置文件
    }
};

// 0x01	诊断紧急事件类型
// 0x02	跌倒紧急事件类型
// 0x03	导联脱落事件类型
// 0x04	诊断结果事件类型
// 0x05 设备状态事件
export const EcgFileEventType = {
    List: [
        {value: "0", label: "诊断紧急事件"},
        {value: "1", label: "跌倒紧急事件"},
        {value: "2", label: "导联脱落事件"},
        {value: "3", label: "诊断结果事件"},
        {value: "4", label: "设备状态事件"},
        // {value: "0", label: "诊断紧急事件"},
        // {value: "1", label: "跌倒紧急事件"},
        // {value: "2", label: "导联脱落事件"},
        // {value: "3", label: "诊断结果事件"},
        // {value: "4", label: "设备状态事件"},
    ], Map: {
        "0": "诊断紧急事件",
        "1": "跌倒紧急事件",
        "2": "导联脱落事件",
        "3": "诊断结果事件",
        "4": "设备状态事件",
        // "0": "诊断紧急事件",
        // "1": "跌倒紧急事件",
        // "2": "导联脱落事件",
        // "3": "诊断结果事件",
        // "4": "设备状态事件",
    }
};

export const ProtocolPackageType = {
    EnumInt: {
        MessageConfirmationResponse: -126, // 0x82：信息确认应答
        FileRequestResponse: 51, // x33：文件请求响应
        CommonReply: 63, // 0x3F：错误数据包
        Live: 66, // 实时数据
        Statis: 49,
        Event: 70
    }
};

export const DeviceLogLevel = {
    List: [
        {value: 1, label: "#1(Fatal):致命错误信息"},
        {value: 2, label: "#2(Error):错误信息"},
        {value: 3, label: "#3(Warn):非常重要/关键的消息"},
        {value: 4, label: "#4(Notice):有提示作用的信息"},
        {value: 5, label: "#5(Info):需要参考的信息"},
        {value: 6, label: "#6(Verbose):一般性信息"},
        {value: 7, label: "#7(Debug):大量调试信息，对开发测试时很有用"},
    ], Map: {
        1: "#1(Fatal):致命错误信息",
        2: "#2(Error):错误信息",
        3: "#3(Warn):非常重要/关键的消息",
        4: "#4(Notice):有提示作用的信息",
        5: "#5(Info):需要参考的信息",
        6: "#6(Verbose):一般性信息",
        7: "#7(Debug):大量调试信息，对开发测试时很有用",
    }, Enum: {
        Fatal: 1,
        Error: 2,
        Warn: 3,
        Notice: 4,
        Info: 5,
        Verbose: 6,
        Debug: 7
    }
};

export const AnnotationFileSourceType = {
    List: [
        {value: "01", label: "ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_LOCAL"},
        {value: "02", label: "ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_SERVER"},
        {value: "03", label: "ECG_ANNOTATION_FIELD_SOURCE_TYPE_MIT"},
    ], Map: {
        "01": "ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_LOCAL",
        "02": "ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_SERVER",
        "03": "ECG_ANNOTATION_FIELD_SOURCE_TYPE_MIT",
    }, Enum: {
        D9K_LOCAL: "01",
        D9K_SERVER: "02",
        MIT: "03",
    }
};

export const DeviceFileType = {
    List: [
        {value: "0", label: "历史文件"},
        {value: "1", label: "日期文件"},
        {value: "2", label: "时间文件"},
        {value: "3", label: "事件文件"},
        {value: "4", label: "设备身份文件"},
        {value: "5", label: "LOGINFO"},
        {value: "6", label: "工作日志"},
        {value: "7", label: "错误日志"},
        {value: "8", label: "警告日志"},
        {value: "9", label: "上电自检日志"},
    ], Map: {
        "0": "历史文件",
        "1": "日期文件",
        "2": "时间文件",
        "3": "事件文件",
        "4": "设备身份文件",
        "5": "LOGINFO",
        "6": "工作日志",
        "7": "错误日志",
        "8": "警告日志",
        "9": "上电自检日志",
    }
};

export const DeviceFileTypeTemp = {
    List: [
        {value: "0", label: "历史文件"},
        {value: "1", label: "日期文件"},
        {value: "2", label: "时间文件"},
        {value: "3", label: "事件文件"},
        {value: "4", label: "设备身份文件"},
    ], Map: {
        "0": "历史文件",
        "1": "日期文件",
        "2": "时间文件",
        "3": "事件文件",
        "4": "设备身份文件",
    }
};

export const AnnotationLabel = {
    List: [
        {value: "N", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NORMAL"},
        {value: "L", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LBBB"},
        {value: "R", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RBBB"},
        {value: "B", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BBB"},
        {value: "A", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_APC"},
        {value: "a", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ABERR"},
        {value: "J", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NPC"},
        {value: "S", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVPB"},
        {value: "V", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PVC"},
        {value: "r", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RONT"},
        {value: "F", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FUSION"},
        {value: "e", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AESC"},
        {value: "j", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NESC"},
        {value: "n", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVESC"},
        {value: "E", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VESC"},
        {value: "/", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACE"},
        {value: "f", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PFUS"},
        {value: "Q", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UNKNOWN"},
        {value: "?", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LEARN"},
        {value: "[", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFON"},
        {value: "!", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FLWAV"},
        {value: "]", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFOFF"},
        {value: "x", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NAPC"},
        {value: "(", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFON"},
        {value: ")", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFOFF"},
        {value: "p", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PWAVE"},
        {value: "t", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TWAVE"},
        {value: "u", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UWAVE"},
        {value: "`", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PQ"},
        {value: "'", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_JPT"},
        {value: "^", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACESP"},
        {value: "|", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ARFCT"},
        {value: "~", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOISE"},
        {value: "+", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RHYTHM"},
        {value: "s", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_STCH"},
        {value: "T", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TCH"},
        {value: "*", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SYSTOLE"},
        {value: "D", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_DIASTOLE"},
        {value: "=", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_MEASURE"},
        {value: "\"\"", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOTE"},
        {value: "@", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LINK"},
        {value: "(AB", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AB"},
        {value: "(AFIB", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFIB"},
        {value: "(AFL", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFL"},
        {value: "(B", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_B"},
        {value: "(BII", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BII"},
        {value: "(IVR", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_IVR"},
        {value: "(N", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_N"},
        {value: "(NOD", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOD"},
        {value: "(P", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_P"},
        {value: "(PREX", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PREX"},
        {value: "(SBR", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SBR"},
        {value: "(SVTA", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVTA"},
        {value: "(T", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_T"},
        {value: "(VFL", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFL"},
        {value: "(VT", label: "ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VT"},
    ], Enum: {
        NORMAL: "N",
        LBBB: "L",
        RBBB: "R",
        BBB: "B",
        APC: "A",
        ABERR: "a",
        NPC: "J",
        SVPB: "S",
        PVC: "V",
        RONT: "r",
        FUSION: "F",
        AESC: "e",
        NESC: "j",
        SVESC: "n",
        VESC: "E",
        PACE: "/",
        PFUS: "f",
        UNKNOWN: "Q",
        LEARN: "?",
        VFON: "[",
        FLWAV: "!",
        VFOFF: "]",
        NAPC: "x",
        WFON: "(",
        WFOFF: ")",
        PWAVE: "p",
        TWAVE: "t",
        UWAVE: "u",
        PQ: "`",
        JPT: "'",
        PACESP: "^",
        ARFCT: "|",
        NOISE: "~",
        RHYTHM: "+",
        STCH: "s",
        TCH: "T",
        SYSTOLE: "*",
        DIASTOLE: "D",
        MEASURE: "=",
        NOTE: "\"\"",
        LINK: "@",
        AB: "(AB",
        AFIB: "(AFIB",
        AFL: "(AFL",
        B: "(B",
        BII: "(BII",
        IVR: "(IVR",
        N: "(N",
        NOD: "(NOD",
        P: "(P",
        PREX: "(PREX",
        SBR: "(SBR",
        SVTA: "(SVTA",
        T: "(T",
        VFL: "(VFL",
        VT: "(VT",
    }
};

export const EcgLightWaveType = {
    List: [
        {value: "01", label: "ECG_LIGHT_WAVE_TYPE_SPLIT"},
        {value: "02", label: "ECG_LIGHT_WAVE_TYPE_COMBINED"},
        {value: "03", label: "ECG_LIGHT_WAVE_TYPE_OVERLAP"},
    ], Map: {
        "01": "ECG_LIGHT_WAVE_TYPE_SPLIT",
        "02": "ECG_LIGHT_WAVE_TYPE_COMBINED",
        "03": "ECG_LIGHT_WAVE_TYPE_OVERLAP",
    }, Enum: {
        Split: "01",
        Combined: "02",
        Overlap: "03",
    }
};


export const DataFileSourceType = {
    List: [
        {value: "10", label: "设备"},
        {value: "20", label: "手机"},
    ], Map: {
        "10": "设备",
        "20": "手机",
    }
};

export const BindOperationType = {
    List: [
        {value: "01", label: "ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_BIND"},
        {value: "02", label: "ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_UNBIND"},
    ], Map: {
        "01": "ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_BIND",
        "02": "ECG_DEVICE_BIND_HISTORY_FIELD_OPERATION_TYPE_UNBIND"
    }, Enum: {
        Bind: "01",
        Unbind: "02",
    }
};