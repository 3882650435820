/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Table, Drawer, Row, Col, Divider, Popconfirm} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";

import {
    searchBinders,
    getBinderDevices,
    toggleDeviceListModal, refreshBinder, getBinderGuarders, toggleGuarderListModal, deleteBinder
} from '../../../actions/BinderAction';
import {deleteCase, getCase, refreshCase, toggleDetailModal} from "../../../actions/EcgCaseAction";
import {RoutePath} from "../../../../../constants/RoutePath";
import {Link} from "react-router";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: formatMessage({id: 'ECG_CASE_FIELD_NAME'})
                },
                {
                    key: 'number',
                    dataIndex: 'number',
                    title: formatMessage({id: 'ECG_CASE_FIELD_NUMBER'})
                },
                {
                    key: 'recordTime',
                    dataIndex: 'recordTime',
                    title: formatMessage({id: 'ECG_CASE_FIELD_RECORD_TIME'})
                },
                {
                    key: 'recordDuration',
                    dataIndex: 'recordDuration',
                    title: formatMessage({id: 'ECG_CASE_FIELD_RECORD_DURATION'})
                },
                {
                    key: 'analysisDate',
                    dataIndex: 'analysisDate',
                    title: formatMessage({id: 'ECG_CASE_FIELD_ANALYSIS_DATE'})
                },
                {
                    key: 'reportDate',
                    dataIndex: 'reportDate',
                    title: formatMessage({id: 'ECG_CASE_FIELD_REPORT_DATE'})
                },
                {
                    key: 'deviceNumber',
                    dataIndex: 'deviceNumber',
                    title: formatMessage({id: 'ECG_CASE_FIELD_DEVICE_NUMBER'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {

    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <Link to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.deviceNumber}>
                    <FormattedMessage id="DAS_ECG_ROUTE_HISTORY"/>
                </Link>
                <Divider type="vertical"/>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a> <FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleModify(record) {
        this.props.dispatch(getCase(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteCase(record, searchCondition, formatMessage));
    }

    render() {
        return (
            <Card className="spacing-v page-width">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.ecgCases} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        ecgCases: store.EcgCaseReducer.ecgCases,
        searchCondition: store.EcgCaseReducer.searchCondition,
        pagination: store.EcgCaseReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));