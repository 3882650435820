/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Row, Col, Form, Button, Input} from 'antd';//Card,

import {getBinderDevices, refreshSearchCondition, searchBinders} from '../../actions/BinderAction';
import SearchDrawer from "../../../../components/SearchDrawer";
import {CACHE_SESSION_USER} from "../../../../constants/Profile";

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 18}, style: {marginBottom: "3px"}};

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        this._handleSearch({});
        this.props.dispatch(getBinderDevices(sessionUser.id, this.state.formatMessage));
        this.props.dispatch(refreshSearchCondition({}));
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleExport() {
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;
        return (
            <SearchDrawer placement={"top"}>
                <Form style={{backgroundColor: "#ffffcc"}}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_BINDER_BUTTON_DEVICES'})} {...formItemLayout}>
                                <Input value={searchCondition.binderName} onChange={(value) => {
                                    this._handleChange("binderName", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})} {...formItemLayout}>
                                <Input value={searchCondition.binderMobile} onChange={(value) => {
                                    this._handleChange("binderMobile", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.BinderReducer.searchCondition,
        pagination: store.BinderReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));