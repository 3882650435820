/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card,  Table, Button} from 'antd';

import {
    getBinderGuarders,
    searchGuardians,
    toggleGuarderBindModal,
    unbindGuarder
} from '../../actions/UserAction';

import {Modal} from "antd/lib/index";
import {UserSex} from "../../Enums";
import {CACHE_SESSION_USER} from "../../../../constants/Profile";
import EnumItemLabel from "../../../../components/EnumItemLabel";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'userName',
                    dataIndex: 'userName',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_NAME'})
                },
                {
                    key: 'userMobile',
                    dataIndex: 'userMobile',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_MOBILE'})
                },
                {
                    key: 'userNickname',
                    dataIndex: 'userNickname',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_NICKNAME'})
                },
                {
                    key: 'userEmail',
                    dataIndex: 'userEmail',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_EMAIL'})
                },
                {
                    key: 'userSex',
                    dataIndex: 'userSex',
                    title: formatMessage({id: 'ECG_GUARDER_FIELD_USER_SEX'}),
                    render: (text, record, index) => <EnumItemLabel map={UserSex.Map} code={text}/>
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="ECG_BINDER_BUTTON_UNBIND"/>
                </a>
            </div>
        )
    }

    _handleDelete(record) {
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        const {dispatch, binder} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "ECG_BINDER_TITLE_GUARDER_UNBIND"}),
            content: formatMessage({id: "ECG_BINDER_MSG_UNBIND_CONFIRM"}),
            okType: 'danger',
            okText: formatMessage({id: 'COMMON_BUTTON_OK'}),
            cancelText: formatMessage({id: 'COMMON_BUTTON_CANCEL'}),
            onOk() {
                dispatch(unbindGuarder(sessionUser.id, record.id, formatMessage));
            }
        })
    }

    render() {
        //console.log(this.props.guarderBinders);
        const sessionUser = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
        let propsGuardians = this.props.guardians.length > 0 ? this.props.guardians[0] : {};

        //查询本人的监护人信息
        //console.log(sessionUser.id);
        // this.props.dispatch(getBinderGuarders(sessionUser.id, this.state.formatMessage));

        return (
            <Card className="spacing-v page-width">
                {/*<PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>*/}
                <div className="text-right">
                    <Button size="small" onClick={() => this.props.dispatch(toggleGuarderBindModal(true))}>
                        <FormattedMessage id="ECG_BINDER_BUTTON_BIND_GUARDER"/>
                    </Button>
                </div>

                <div className="spacing-v">
                    <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                           dataSource={this.props.binderGuarders} pagination={false}/>
                </div>

            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchGuardians(searchCondition, this.state.formatMessage));
        //this.props.dispatch(getGuarderBinders(record.userId, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        guardians: store.UserReducer.guardians,
        searchCondition: store.UserReducer.searchCondition,
        pagination: store.UserReducer.pagination,
        guarderBinders: store.UserReducer.guarderBinders,
        binderGuarders: store.BinderReducer.binderGuarders
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));