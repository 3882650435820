// 字典类型
export const DICT_TYPE_LOADING = 'DICT_TYPE_LOADING';
export const DICT_TYPE_SEARCH = 'DICT_TYPE_SEARCH';
export const DICT_TYPE_REFRESH_TYPE = "DICT_TYPE_REFRESH_TYPE";
export const DICT_TYPE_TOGGLE_DETAIL_MODAL = "DICT_TYPE_TOGGLE_DETAIL_MODAL";

// 字典分类
export const DICT_CLASS_LOADING = 'DICT_CLASS_LOADING';
export const DICT_CLASS_SEARCH = 'DICT_CLASS_SEARCH';
export const DICT_CLASS_REFRESH_CLASS = "DICT_CLASS_REFRESH_CLASS";
export const DICT_CLASS_TOGGLE_DETAIL_MODAL = "DICT_CLASS_TOGGLE_DETAIL_MODAL";

// 字典项
export const DICT_ITEM_LOADING = 'DICT_ITEM_LOADING';
export const DICT_ITEM_REFRESH_ITEM = "DICT_ITEM_REFRESH_ITEM";
export const DICT_ITEM_REFRESH_ITEMS = "DICT_ITEM_REFRESH_ITEMS";
export const DICT_ITEM_REFRESH_ITEM_MAP = "DICT_ITEM_REFRESH_ITEM_MAP";
export const DICT_ITEM_TOGGLE_DETAIL_MODAL = "DICT_ITEM_TOGGLE_DETAIL_MODAL";