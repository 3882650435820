/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Button, Popover, DatePicker, Icon, Switch} from 'antd';
import message from "../../../../../../components/toast";

import {ChartSpeed, EcgFileType, ProtocolPackageType, VoltDiv} from "../../../../Enums";
// Ant Design 汉化注入
import locale from 'antd/lib/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
import "./Index.css";
import moment from 'moment';
import {
    FORMAT_DATE_HYPHEN, FORMAT_DATE_SIMPLE
} from "../../../../../../constants/DateTimeFormats";
import DoubleCalendar from "./DateIndexCalendar";
import TimeIndexSlider from "./TimeIndexSlider";
import {
    refreshEcgDataIndexEffectiveRecords,
    refreshEcgDateIndexData, refreshEcgDateIndexRecordMap,
    refreshEcgEventIndexData,
    refreshEcgTimeIndexData
} from "../../../../actions/HistoryAction";
import {ECG_SOCKET_SERVER_HOST} from "../../../../../../constants/Profile";
import EcgFileCommonHeader from "../../../../entities/EcgFileCommonHeader";
import ProtocolCommonHeader from "../../../../entities/ProtocolCommonHeader";
import ProtocalHistoryData from "../../../../entities/ProtocalHistoryData";
import EcgFileTimeIndex from "../../../../entities/EcgFileTimeIndex";
import EcgFileEventIndex from "../../../../entities/EcgFileEventIndex";
import EcgFileMixData from "../../../../entities/EcgFileMixData";
import EcgFileDateIndex from "../../../../entities/EcgFileDateIndex";
import {RoutePath} from "../../../../../../constants/RoutePath";
import HttpUtils from "../../../../../../utils/HttpUtils";
import WebSocketUtils from "../../../../utils/WebSocketUtils";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";

let pointIndex = 0;
let ecgTextY = [];

// 一个导联的svg图高度
let winH;
// 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
let winW;

// 采样率
// 标准值：500samples/s
// 可选值：250samples/s，1000samples/s
const sampRate = 500;

// 下采样率，默认值1，从设备中获取后改变
let downRatio = 1;

// 通过采样率和下采样率的关系，算出每秒实际多少个点
let dataRate = sampRate / downRatio;

// 画布高度
// const height = 1200;
// 采集频率，每秒500次
const frequency = 500;
// 显示的时间（秒）
let duration = 10;

// 格子尺度【GridScale】
// 	5个小格构成一个大格
// 标准值：1mm/小格
// 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
// 	例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
// 此处默认以大格子作为显示单位
const gridScale = 5;

// 格子的缩放
const gridRatio = 1;

// 走纸速度, 标准值：25mm/s
// 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
const chartSpeed = 25;

// 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
const gridPointNum = dataRate / (chartSpeed / gridScale);
// console.log("gridPointNum:" + gridPointNum);

// 播放的走点数, 每次更新的数据点的数量
// 对应的文档上的参数名是RfsNum
let playStep = 5;

// 转换成一次刷新走多少个点，按500的采样率，则100个点代表0.2秒=是5mm，就是一格的描画点数
// 10ms刷新一次，则刷新100次是1s，那么刷100次则需要行走5个格子，则刷新一次走0.05个格子，也就是说是5个点
let playSpeed = 1000 / (dataRate / playStep);

const gc = 20132.66;
// // CH1（呼吸通路）
// // Gain=2^24*4*1/5000
// const gain1 = 13421.7728;
//
// // CH2~CH8
// // Gain=2^24*6*1/5000=20132.6592 1/mV
// const gain = 20132.6592;

// 计算出横向的格子数
// 一个格子是0.2S(0.04S的小格子不显示)
// 如果是10S是50个格子
const unit = 0.2;


// 算出横向格子数
let xCellCount;
// 每个导联占据纵向格子数
const yCellCount = 6;

// 算出每个格子的宽度
let cellWidth;
// 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
// 计算得出点之间的x间距
let pointSpace;

// 算出0点，以下是负值，以上是正直，需要变换数据
let zeroPointY;

// 绘图的缓冲区
let clearDrawBufferFlag = false;
// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
let drawBufferMap = {};

// 8个导联的数据
let numLeads = 8;

// 窗口刷新率，每40ms刷新一次走纸
const winFps = 40;
//每次更新的数据点的数量
// const baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
let rfsNum = (sampRate / downRatio) / (1000 / winFps);

// 定标一个div为100mm宽度，算出相对应的pixel
const unitWidthMill = 1;

// 绘图窗口的Y轴Margin
const winMarginX = 40;
const winMarginY = 20;

// SVG 线的背景颜色
const lineColors = ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E'];

// 一屏幕能绘画的点数量
let screenPointSum;

// 历史心电文件的Web Socket连接对象
let webSocket;
// 时间索引文件对象
let ecgFileTimeIndex;
// 事件索引文件对象
let ecgFileEventIndex;

let timeFlag = false;
let eventFlag = false;

// 记录Web Socket的命令列表，防止重复发送指令
let webSocketCommandHistory = [];

let handleDeviceIndex = 0;

let manualStopSearch = false;
let lastFetchHistoryFileIndex = -1;
let historyFileIndex;
let historyFileFlag = false;

const heartCheck = {
    //计时器设定为30s
    timeout: 30000,
    timeoutObj: null,
    serverTimeoutObj: null,

    //重置
    reset: function () {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        this.start();
    },

    //开始
    start: function () {
        this.timeoutObj = setTimeout(function () {
            webSocket.send("HB");
        }, this.timeout);
    }
};

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            timeFileIndex: 0,
            eventFileIndex: 0,
            sliderValue: 0,
            showControl: true,
            recordDate: "",
            sliderMarks: {},
            backgroundDisplay: true,
            // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // 一个格子是5mm，所以一个格子代表0.5mv
            voltDiv: 10,
            // 走纸倍数
            chartSpeedTimes: 1,
            combinePaint: false,
            playFlag: false,
            dateIndexOverviewVisible: false,
            eventIndexTimeLineVisible: false,
            timeNode: "",
            doubleCalendarVisible: false,
            rangeStartDate: "",
            rangeEndDate: "",
            toggleDeviceDetail: false
        }
    }

    componentWillMount() {
        this._setQueryState();
    }

    componentWillUnmount() {
        historyFileIndex = null;
        ecgFileTimeIndex = null;
        ecgFileEventIndex = null;
        timeFlag = false;
        eventFlag = false;
        this._handleStopSearch();
        clearInterval(this.intervalGetDeviceById);
    }

    _setQueryState() {
        const recordDate = this.props.location.query.recordDate;
        const deviceCode = this.props.location.query.deviceCode;
        const timeFileIndex = this.props.location.query.timeFileIndex;
        const eventFileIndex = this.props.location.query.eventFileIndex;

        this.setState({
            recordDate: recordDate,
            deviceCode: deviceCode,
            timeFileIndex: timeFileIndex,
            eventFileIndex: eventFileIndex,
            rangeStartDate: recordDate,
            rangeEndDate: recordDate
        })
    }

    /**
     * 心跳检测
     */
    isHeartBeat(data) {
        const msg = new Uint8Array(data);
        if (msg === null || msg.byteLength !== 2) {
            return false;
        }

        //H:0x48  B:0x42
        return (0x48 === msg[0] && 0x42 === msg[1]);
    }

    /** 初始化动作 */
    componentDidMount() {
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);

        this._calcParams();

        this._drawECG(this.state.combinePaint);

        // 时间索引文件编号，0表示没采集数据。
        // 每天生成一个时间索引文件，其文件大小固定
        this._handleSearch(true);
    }

    _handleSearch = (initializeFlag) => {
        webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = (e) => {

            //心跳检测启动
            heartCheck.start();

            // 打开一个连接
            // message.success("链接服务器成功：" + e.target.url);
            WebSocketUtils._sendHello(webSocket, this.state.deviceCode);
        };
        webSocket.onmessage = (e) => {
            if (this.isHeartBeat(e.data)) {
                //无论收到什么信息，说明当前连接正常，将心跳检测的计时器重置
                heartCheck.reset();
                return;
            }

            const dataArray = new Int8Array(e.data);

            let startPos = 0;

            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            // console.log(protocolCommonHeader);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                // message.success("连接成功，开始发送获取日期索引文件文件的指令");
                WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.DATEIDX, 1);
                // WebSocketUtils._sendGetDeviceDatas(webSocket, this.state.deviceCode);
                return;
            }

            console.log("dataArray", dataArray, protocolCommonHeader, protocolCommonHeader.packetType)
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.CommonReply) {
                // 0x0000 通用成功
                // 0xAAEF 超时
                if (dataArray[14] !== 0 && dataArray[15] !== 0) {
                    message.error("服务器中未有数据");
                    this.discardData = setInterval(() => {
                            this._handleDevice();
                        },
                        1000)
                    // deviceHistoryData = JSON.parse(this.props.deviceHistoryData);
                    // console.log(this.props.deviceHistoryData)
                    // webSocket.send(command);
                    // WebSocketUtils._sendGetDeviceDatas(webSocket, this.state.deviceCode);

                    // if (lastFetchHistoryFileIndex > 0) {
                    //     WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, lastFetchHistoryFileIndex++, webSocketCommandHistory);
                    // }
                    return;
                }
            }

            // 服务器向客户端发送对应的文件。【上行】
            // PacketType	1B	0x33：文件请求响应	包类型
            // 其他类型的包，不需要处理
            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.FileRequestResponse) {
                return;
            }

            // 解析历史文件里的data数据
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const historyData = new ProtocalHistoryData(dataArray, startPos);

            startPos = startPos + historyData.length;
            const ecgFileCommonHeader = new EcgFileCommonHeader(dataArray, startPos);

            startPos = startPos + ecgFileCommonHeader.length;

            switch (historyData.fileType) {
                case EcgFileType.EnumInt.DATEIDX:
                    // message.success("日期索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    console.log("5")
                    const ecgFileDateIndex = new EcgFileDateIndex(dataArray, startPos);
                    console.log(ecgFileDateIndex);
                    const ecgDateIndexRecordMap = {};
                    const ecgDataIndexEffectiveRecords = [];
                    for (let item of ecgFileDateIndex.records) {
                        ecgDateIndexRecordMap[item.recordDate] = item;
                        if (item.timeNum > 0) {
                            // 有心电数据的记录才需要加入显示队列
                            ecgDataIndexEffectiveRecords.push(item);
                        }
                    }
                    this.props.dispatch(refreshEcgDataIndexEffectiveRecords(ecgDataIndexEffectiveRecords));
                    this.props.dispatch(refreshEcgDateIndexRecordMap(ecgDateIndexRecordMap));
                    this.props.dispatch(refreshEcgDateIndexData(ecgFileDateIndex));

                    console.log("日期索引文件解析完成，开始发送获取时间索引文件的指令，timeFileIndex=" + this.state.timeFileIndex);
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.TIMEIDX, this.state.timeFileIndex);
                    break;
                case EcgFileType.EnumInt.TIMEIDX:
                    // message.success("时间索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    console.log("6:", startPos)
                    ecgFileTimeIndex = new EcgFileTimeIndex(dataArray, startPos);
                    timeFlag = true;
                    console.log(ecgFileTimeIndex);

                    const slideMarks = {};
                    slideMarks[0] = "00:00";
                    slideMarks[Number(ecgFileTimeIndex.timeSliceNum)] = "23:59";
                    for (let i = 1, x = 0, z = 0; i == Math.round(Number(ecgFileTimeIndex.timeSliceNum) / (23 * 4)); i++) {
                        x++;
                        if (x % 4 == 0 && x != 0) {
                            slideMarks[i] = z++;
                        }else{
                            slideMarks[i] = " "
                        }
                    }
                    // console.log(slideMarks);
                    this.props.dispatch(refreshEcgTimeIndexData(ecgFileTimeIndex, slideMarks));

                    // message.success("时间索引文件解析完成，开始发送获取事件索引文件的指令");
                    console.log("日期索引文件解析完成，开始发送获取事件索引文件的指令，eventFileIndex=" + this.state.eventFileIndex);
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.EVNTIDX, this.state.eventFileIndex);

                    break;
                case EcgFileType.EnumInt.EVNTIDX:
                    // message.success("事件索引文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                    ecgFileEventIndex = new EcgFileEventIndex(dataArray, startPos);
                    eventFlag = true;
                    this.props.dispatch(refreshEcgEventIndexData(ecgFileEventIndex));
                    console.log("4")
                    // 由于HistoryFileIndex从1开始编号，如HistoryFileIndex=0，表示当天没有历史数据文件产生。
                    // 所以获取一个有效的起始索引
                    let index = 0;
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        const record = ecgFileTimeIndex.records[i];
                        if (record.historyFileIndex > 0) {
                            index = i;
                            historyFileIndex = record.historyFileIndex;
                            this.setState({sliderValue: i});
                            // 第一个历史文件先获取了
                            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
                            break;
                        }
                    }
                    break;
                case EcgFileType.EnumInt.MIXDATA:
                    console.log("7")
                    // 心电图的点数据加入缓冲区
                    if (clearDrawBufferFlag) {
                        drawBufferMap = {};
                        webSocketCommandHistory = [];
                    }

                    // 获得当前的TimeIndexRecord, 播放的时候，需要根据这个index控制进度条
                    for (let i = 0; i < ecgFileTimeIndex.records.length; i++) {
                        if (ecgFileTimeIndex.records[i].historyFileIndex === historyData.fileIndex) {
                            historyFileFlag = true;
                            // message.success("历史心电文件获得成功，开始解析数据包，FileIndex:" + historyData.fileIndex);
                            const record = ecgFileTimeIndex.records[i];
                            const ecgFileMixData = new EcgFileMixData(dataArray, startPos, i, historyData.fileIndex, record.eventRecordIndex, this.props.annotationsMap[historyData.fileIndex]);
                            const ecgPointPolymRegion = ecgFileMixData.polymRegions[0];
                            for (let i = 0; i < ecgPointPolymRegion.channelNum; i++) {
                                if (drawBufferMap[i]) {
                                    drawBufferMap[i] = drawBufferMap[i].concat(ecgPointPolymRegion.ecgData[i]);
                                } else {
                                    drawBufferMap[i] = ecgPointPolymRegion.ecgData[i];
                                }
                            }

                            clearDrawBufferFlag = false;
                            if (initializeFlag) {
                                // message.success("历史心电文件解析完成，开始描绘心电图");
                                this._refreshECGView();
                            }
                            break;
                        }
                    }
            }
        };
        webSocket.onerror = (e) => {
            // message.error("链接服务器异常：" + e.target.url);
        };
        webSocket.onclose = (evnt) => {
            message.warn("与服务器断开了链接：" + evnt.target.url);

            if (!manualStopSearch) {
                message.info("尝试重新连接：" + evnt.target.url);
                this._handleSearch(false);
            }
        };
    };

    _handleResize = () => {
        this._calcParams();
        this._handleChangeBackgroundDisplay(this.state.backgroundDisplay);
    };

    _handleDevice() {
        handleDeviceIndex++;
        // console.log("_handleDevice", this.props.deviceHistoryData)
        if (this.props.deviceHistoryData != "") {
            console.log("_handleDevice", historyFileIndex, ecgFileTimeIndex)
            for (var i = 0; i < this.props.deviceHistoryData.length; i++) {
                if (this.props.deviceHistoryData[i].beginIdx > historyFileIndex + (ecgFileTimeIndex ? ecgFileTimeIndex.recordNum : 5444)) {
                    console.log("1")
                    handleDeviceIndex = 0;
                    console.log("_handleDevice", this.props.deviceHistoryData[i].beginIdx, historyFileIndex)
                    message.error("当天没有数据");
                    clearInterval(this.discardData)
                    return
                } else {
                    console.log("2")
                    if (this.props.deviceHistoryData[i].beginIdx > historyFileIndex) {
                        handleDeviceIndex = 0;
                        console.log("3")
                        message.success("自动跳转到最近的数据");
                        WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode,
                            EcgFileType.Enum.MIXDATA, this.props.deviceHistoryData[i].beginIdx, webSocketCommandHistory);
                        clearInterval(this.discardData)
                        return
                    } else {
                        handleDeviceIndex = 0;
                        console.log("4")
                        message.success("服务器上未有数据");
                        clearInterval(this.discardData)
                        return
                    }
                }
            }

        } else if (handleDeviceIndex == 5) {
            message.error("未获取到数据");
            clearInterval(this.discardData)
            handleDeviceIndex = 0;
            return
        }
    }

    /** 计算参数 */
    _calcParams = (combinePaintFlag) => {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        // 定标mm和px的换算
        const unitWidth = document.getElementById("unit_width");
        console.log("Width:" + unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        console.log("Height" + unitWidth.style.height, unitWidth.clientHeight, unitWidth.offsetHeight, unitWidth.scrollHeight);

        winW = ecgViewWrapNode.clientWidth / (unitWidth.clientWidth / unitWidthMill);
        console.log("winW " + winW + " mm");

        // 每个格子的宽度
        cellWidth = gridScale * (unitWidth.clientWidth / unitWidthMill);
        console.log("cellWidth " + cellWidth + " px");

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        pointSpace = cellWidth / gridPointNum;
        console.log("pointSpace " + pointSpace);

        // 格子的数量，向下取整
        xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        console.log("xCellCount " + xCellCount);

        // 算出0点，以下是负值，以上是正直，需要变换数据
        zeroPointY = (yCellCount - 3) * cellWidth + winMarginY;
        console.log("zeroPointY " + zeroPointY);

        // 计算出一屏的点总数
        screenPointSum = gridPointNum * xCellCount;
        console.log("一屏的描画点数：", screenPointSum);

        if (combinePaintFlag) {
            // 合并在一张画板上，则需要按导联数计算高度
            winH = yCellCount * numLeads * cellWidth + 50;
        } else {
            winH = yCellCount * cellWidth + 50;
        }

        ecgTextY = [];
        pointIndex = 0;
    };

    /** 绘制心电图 */
    _drawECG = (combinePaintFlag) => {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        ecgViewWrapNode.innerHTML = "";
        // console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);
        if (combinePaintFlag) {
            // 创建SVG容器标签
            const ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";

            let ecgViewContent = "";
            for (let i = 0; i < numLeads; i++) {

                const offsetY = i * yCellCount * cellWidth;

                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

                const ecgViewText = this._getECGViewText(i);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                ecgViewContent = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;
            }

            const ecgViewEnd = "</svg>";
            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
        } else {
            for (let i = 0; i < numLeads; i++) {

                // 创建SVG容器标签
                const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";

                const ecgViewText = this._getECGViewText(i);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                const ecgViewEnd = "</svg>";

                ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
            }
        }
    };

    /** 点击控制进度条播放 */
    onPlaySliderChange = (value) => {
        // 获取当前刻度所对应的历史文件索引
        const record = this.props.ecgTimeIndexData.records[value];

        if (!record || record.historyFileIndex <= 0) {
            message.warn("该时间点没有心电数据");
            return;
        }
        if (record) {
            // 第一个历史文件先获取了
            clearDrawBufferFlag = true;
            this._handleStopPlay(true);
            this.setState({sliderValue: value});
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, record.historyFileIndex, webSocketCommandHistory);
        }
    };

    /** 是否显示心电图网格背景 */
    _handleChangeBackgroundDisplay = (value) => {
        this.setState({backgroundDisplay: value});
        for (let i = 0; i < numLeads; i++) {
            if (value) {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = this._getECGViewBackground();
            } else {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = "";
            }
        }
    };

    /** 心电图正向播放 */
    _handlePlayRight = () => {

        if (!this.state.playFlag) {
            this.setState({playFlag: true});

            this.intervalPlay = setInterval(() => {

                this._refreshECGView();

                if (drawBufferMap[0] && drawBufferMap[0][0] && drawBufferMap[0][0].timeRecordIndex !== this.state.sliderValue) {
                    this.setState({sliderValue: drawBufferMap[0][0].timeRecordIndex});
                }

                // 如果当前点的时间记录索引跟滑动条上的标识不符合，则调整滑动条的值
                if (drawBufferMap[0] && !drawBufferMap[0][screenPointSum + 500] && drawBufferMap[0][0]) {
                    // 缓存的数据不够，需要重新获取下一个历史文件数据
                    WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.MIXDATA, drawBufferMap[0][0].historyFileIndex + 1, webSocketCommandHistory);
                }

            }, winFps);
        }
    };

    _handleStopSearch() {
        webSocketCommandHistory = [];
        drawBufferMap = {};
        this._handleStopPlay(true);
        webSocket && webSocket.close();
    }

    _handleStopPlay(manualStop) {
        manualStopSearch = manualStop;
        this.setState({playFlag: false});
        clearInterval(this.intervalPlay);
        this.intervalPlay = undefined;
    }

    _handlePreFile() {
        console.log(this.state.sliderValue);
        if (this.state.sliderValue > 0) {
            this.onPlaySliderChange(this.state.sliderValue - 1);
        }
    }

    _handleNextFile() {
        console.log(this.state.sliderValue);
        this.onPlaySliderChange(this.state.sliderValue + 1);
    }

    /** 刷新心电图 */
    _refreshECGView = () => {
        for (let i = 0; i < numLeads; i++) {

            if (!drawBufferMap[i] || !drawBufferMap[i][rfsNum * this.state.chartSpeedTimes]) {
                continue;
            }

            if (this.state.playFlag) {
                // 如果在播放，则把rfsNum这个索引之前的数据删掉
                drawBufferMap[i].splice(0, rfsNum * this.state.chartSpeedTimes);
            }

            const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);

            // 创建心电图波形
            const ecgViewLightWave = this._getECGViewLightWave(i);
            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }
    };

    /** 画心电图的上的文本 */
    _getECGViewText = (index) => {
        const centerY = winH / 2;
        // 画导联的名称
        const result = "<text x=0 y=" + centerY + ">CH-" + (index + 1) + "</text>";
        return result;
    };

    /** 画心电图的背景 */
    _getECGViewBackground = () => {

        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        // 画原点
        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    };

    /** 画心电图的折线 */
    _getECGViewLightWave = (index) => {

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";

        const drawBuffer = drawBufferMap[index];
        if (!drawBuffer) {
            return;
        }

        let x = winMarginX;

        let result = "";
        for (let i = 0; i < screenPointSum; i++) {

            // 计算前一个点
            let preY = 0;
            if (i !== 0) {
                if (drawBuffer[i - 1].newPoint !== null) {
                    preY = drawBuffer[i - 1].newPoint;
                } else {
                    preY = drawBuffer[i - 1].point;
                    if (preY !== 0) {
                        preY = preY / gc;// * 0.000049;
                        preY = preY / (1 / (this.state.voltDiv / gridScale));
                        preY = preY * cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = zeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + zeroPointY;
                    }
                }
            }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (drawBuffer[i] === null || drawBuffer[i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (drawBuffer[i].newPoint !== null) {
                y = drawBuffer[i].newPoint;
            } else {
                y = drawBuffer[i].point;
                y = y / gc;// * 0.000049;
                y = y / (1 / (this.state.voltDiv / gridScale));
                y = y * cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = zeroPointY - y;
                } else {
                    y = Math.abs(y) + zeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                drawBuffer[i].newPoint = y;
            }

            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }

            x = x + pointSpace;
        }

        // console.log(result);

        result = result + "<path stroke='rgb(0,0,0)' fill='none' strokeWidth='1' d='" + path + "'></path>";
        return result;
    };

    _handleChangeRecordDate(date) {
        historyFileIndex = null;
        ecgFileTimeIndex = null;
        ecgFileEventIndex = null;
        timeFlag = false;
        eventFlag = false;
        const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
        if (ecgDateIndexRecord) {
            let requestUrl = RoutePath.ECG_HISTORY_DAY.path;
            requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", this.state.deviceCode);
            requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
            requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
            requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
            requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
            requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);

            this.props.router.push(requestUrl);

            this.setState({
                doubleCalendarVisible: false,
                deviceCode: this.state.deviceCode,
                recordDate: ecgDateIndexRecord.recordDate,
                timeFileIndex: ecgDateIndexRecord.timeFileIndex,
                eventFileIndex: ecgDateIndexRecord.eventFileIndex
            });

            webSocketCommandHistory = [];
            clearDrawBufferFlag = true;
            WebSocketUtils._sendGetEcgDeviceFile(webSocket, this.state.deviceCode, EcgFileType.Enum.DATEIDX, 1);
        }
    }

    _handleChangeVoltDiv(value) {
        this.setState({voltDiv: value});
        // 重新计算点
        for (let i = 0; i < numLeads; i++) {

            const drawBuffer = drawBufferMap[i];
            if (!drawBuffer) {
                return;
            }

            for (let i = 0; i < screenPointSum; i++) {
                drawBuffer[i].newPoint = null;
            }
        }
    }


    render() {
        return (
            <div className="ecg-history-canvas drawer-container">
                <div id="unit_width" style={{
                    width: unitWidthMill + 'mm',
                    height: unitWidthMill + 'mm',
                    position: 'absolute',
                    zIndex: -1
                }}>

                </div>

                <div style={{marginTop: 20}}>
                    <div>
                        <Button icon={"step-backward"} className="spacing-h" onClick={() => this._handlePreFile()}/>
                        <Button icon={"pause"} className="spacing-h" onClick={() => this._handleStopPlay()}/>
                        <Button loading={this.state.playFlag} icon={"caret-right"} className="spacing-h"
                                onClick={() => this._handlePlayRight()}/>
                        <Button icon={"step-forward"} className="spacing-h" onClick={() => this._handleNextFile()}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={ChartSpeed.List} value={this.state.chartSpeedTimes}
                            onChange={(value) => {
                                this.setState({chartSpeedTimes: value});
                            }}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={VoltDiv.List} value={this.state.voltDiv}
                            onChange={(value) => this._handleChangeVoltDiv(value)}/>
                        <Switch checkedChildren="显示格子" unCheckedChildren="隐藏格子" className={"spacing-h"}
                                checked={this.state.backgroundDisplay}
                                onChange={(value) => this._handleChangeBackgroundDisplay(value)}/>
                        <DatePicker className="spacing-h"
                                    value={this.state.recordDate ? moment(this.state.recordDate, FORMAT_DATE_HYPHEN) : null}
                                    locale={locale}
                                    disabled={true}
                        />
                        <Popover className="spacing-h"
                                 content={<DoubleCalendar visible={this.state.doubleCalendarVisible}
                                                          ecgDateIndexRecordMap={this.props.ecgDateIndexRecordMap}
                                                          onSelect={(date) => this._handleChangeRecordDate(date)}/>}
                                 visible={this.state.doubleCalendarVisible}
                                 trigger="click"
                                 placement="bottom"
                                 onVisibleChange={(value) => this.setState({doubleCalendarVisible: value})}>
                            <Icon className="ecg-history-double-calendar spacing-h"
                                  type="carry-out"
                                  theme="twoTone"/>
                        </Popover>
                        {/*<Switch className="history-events-switch pull-right spacing-h"*/}
                                {/*checkedChildren="设备详情"*/}
                                {/*unCheckedChildren="设备详情"*/}
                                {/*onChange={(value) => this._handleToggleDeviceDetailDrawer(value, this.state.deviceCode)}/>*/}
                        {/*<Switch className="anomalous-events-switch pull-right spacing-h"*/}
                                {/*checkedChildren="异常事件"*/}
                                {/*unCheckedChildren="异常事件"*/}
                                {/*onChange={(value) => this.setState({eventIndexTimeLineVisible: value})}/>*/}
                        {/*<Switch className="history-events-switch pull-right spacing-h"*/}
                                {/*checkedChildren="历史总览"*/}
                                {/*unCheckedChildren="历史总览"*/}
                                {/*onChange={(value) => this.setState({dateIndexOverviewVisible: value})}/>*/}
                        <span>{this.state.deviceCode}</span>
                    </div>
                </div>
                {/* 播放进度条 */}
                <TimeIndexSlider sliderValue={this.state.sliderValue}
                                 ecgTimeIndexData={timeFlag ? this.props.ecgTimeIndexData : ""}//
                                 ecgTimeSlideMarks={this.props.ecgTimeSlideMarks}
                                 onChange={(value) => {
                                     this.setState({sliderValue: value});
                                     this._handleStopPlay()
                                 }}
                                 onAfterChange={(value) => this.onPlaySliderChange(value, true)}/>
                {/*onClick={() => this._handleStopPlay()}*/}
                {/*onDrop={(value) => this.onPlaySliderChange(value, true)}*/}
                <div id="ecg_view_wrap">
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        ecgDateIndexData: store.EcgHistoryReducer.ecgDateIndexData,
        ecgDataIndexEffectiveRecords: store.EcgHistoryReducer.ecgDataIndexEffectiveRecords,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        ecgTimeIndexData: store.EcgHistoryReducer.ecgTimeIndexData,
        ecgTimeSlideMarks: store.EcgHistoryReducer.ecgTimeSlideMarks,
        timeRecordIndex: store.EcgHistoryReducer.timeRecordIndex,
        ecgEventIndexData: store.EcgHistoryReducer.ecgEventIndexData,
        annotationsMap: store.EcgDeviceFileAnnotationReducer.annotationsMap,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData
    }
};

export default connect(mapStateToProps)(injectIntl(Index));
