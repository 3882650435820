/**
 * Created by Ethan on 2017/5/12.
 */

import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import message from "../../../components/toast";

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshFile(file) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_REFRESH_FILE,
        file: file
    }
}

export function refreshFiles(files) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_REFRESH_FILES,
        files: files
    }
}



export function refreshContentFileMap(contentFileMap) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_REFRESH_CONTENT_FILE_MAP,
        contentFileMap: contentFileMap
    }
}
export function getContentFiles(contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        const requestUrl = WebService.getContentFiles.replace("{id}", contentId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshFiles(response.data));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function createContentFile(contentFile, contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        const requestUrl = WebService.createContentFile.replace("{id}", contentId);
        HttpUtils.post(requestUrl, contentFile, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getContentFiles(contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function updateContentFile(contentFile, contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        const requestURL = WebService.updateContentFile.replace("{id}", contentFile.id);
        HttpUtils.put(requestURL, contentFile, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getContentFiles(contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function deleteContentFile(id, contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});
        let requestURL = WebService.deleteContentFile.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(getContentFiles(contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (error) => {
            message.error(error);
        }, formatMessage);
    }
}

export function deleteDiagnosticReport(diagnosticReport, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});
        let requestURL = WebService.deleteDiagnosticReport;
        HttpUtils.post(requestURL, diagnosticReport, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (error) => {
            message.error(error);
        }, formatMessage);
    }
}