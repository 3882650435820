/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import {FORMAT_DATE_SIMPLE} from "../../../constants/DateTimeFormats";
import moment from "moment";
import {ECG_REALTIME_SCREENPOINTSUM} from "../ActionTypes";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_LOG_DEVICE_SERVER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshRealtimeScreenPointSum(screenPointSum) {
    return {
        type: ActionTypes.ECG_REALTIME_SCREENPOINTSUM,
        screenPointSum: screenPointSum
    }
}

export function initDeviceLogs(deviceCode) {
    return dispatch => {
        dispatch(refreshDeviceLogs([]));

        const searchCondition = {
            deviceCode: deviceCode,
            startDate: moment().subtract(5, 'days').format(FORMAT_DATE_SIMPLE),
            endDate: moment().format(FORMAT_DATE_SIMPLE)
        };
        dispatch(refreshSearchCondition(searchCondition));
    }
}

export function refreshDeviceLogs(deviceLogs) {
    return {
        type: ActionTypes.ECG_LOG_DEVICE_SERVER_SEARCH,
        deviceLogs: deviceLogs
    }
}

export function searchDeviceLogList(searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchDeviceLogs, searchCondition, (response) => {
            dispatch(refreshDeviceLogs(response.data));

            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function downloadDeviceLog(path, name, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.downloadDeviceLogs, "filePaths", path);
        HttpUtils.getFile(requestUrl, name, (response) => {

            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING, isLoading: false});
        }, formatMessage)
    }
}