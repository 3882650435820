import React, {Component} from 'react';
import {Descriptions, Slider} from 'antd';
import './TimeIndexSlider.css'
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../../constants/DateTimeFormats";

import moment from "moment";

let timeSlider;
// let marks;

export default class TimeIndexSlider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 0,
        }
    }

    componentDidMount() {

        const self = this
        timeSlider = document.getElementsByClassName("ecg-slider");
        if (timeSlider.addEventListener) {
            timeSlider.addEventListener('mousemove', mouseMove, false)
        }

        function mouseMove(event) {
            // console.log("timeSlider:", event);
            event.preventDefault();
            self.onChange(1)
        }
    }

    /** 子组件方法传递给父组件 */
    onPlaySliderChange = (value) => {
        // console.log("onPlaySliderChange", value);
        this.props.onAfterChange && this.props.onAfterChange(value);
    };

    onChange = (value) => {
        // console.log("onChange", value);
        this.props.onChange && this.props.onChange(value);
    };

    render() {
        // console.log("ecgTimeSlideMarks:", this.props.ecgTimeSlideMarks);
        let windImgBg;
        const ecgSliderNodes = document.getElementsByClassName("ecg-slider");
        const ecgSliderExhibitionNodes = document.getElementsByClassName("ecg-slider-exhibition");
        const ecgSliderTrackNodes = document.getElementsByClassName("ant-slider-track");
        // const ecgSliderTrack = <div className="ant-slider-track" style="left: 0%; right: auto; width: 100%;"></div>
        if (ecgSliderNodes && ecgSliderNodes.length > 0) {

            // console.log("ecgSliderTrackNodes:", ecgSliderTrackNodes)
            if (ecgSliderTrackNodes && ecgSliderTrackNodes.length > 0) {
                for (let i = 0; i < ecgSliderTrackNodes.length; i++) {
                    ecgSliderTrackNodes[i].parentNode.removeChild(ecgSliderTrackNodes[i]);
                }
            }


            let left = -1;
            let width = -1;

            let left2 = -1;
            let index = 0;
            let ecgSliderTimeSlideFlag = true;
            if (this.props.ecgTimeIndexData.timeSliceNum !== "" && this.props.ecgTimeSlideData) {
                // console.log("timeIndexSlider:", this.props.ecgTimeIndexData
                //     , this.props.historyFileIndex, this.props.deviceHistoryData, this.props.ecgTimeSlideMarks)
                for (let i = 0; i < this.props.ecgTimeIndexData.timeSliceNum; i++) {
                    const record = this.props.ecgTimeIndexData.records[i];

                    if (record && record.historyFileIndex > 0) {
                        while (this.props.ecgTimeSlideData.length - 1 > index &&
                        record.historyFileIndex >= this.props.ecgTimeSlideData[index].endIdx) {
                            index++;
                        }
                        if (this.props.ecgTimeSlideData.length > 0 && ecgSliderTimeSlideFlag) {
                            if (record.historyFileIndex >= this.props.ecgTimeSlideData[index].beginIdx &&
                                record.historyFileIndex <= this.props.ecgTimeSlideData[index].endIdx && left2 < 0) {
                                left2 = i;
                                width = 0;
                            }
                        }

                        if (left < 0 && left2 < 0) {
                            left = i;
                            width = 0;
                        }
                        width++;
                    }


                    if (this.props.ecgTimeSlideData.length > 0) {
                        if (!record || record.historyFileIndex >= this.props.ecgTimeSlideData[index].endId
                            || (i + 1) === this.props.ecgTimeIndexData.timeSliceNum || record.historyFileIndex <= 0) {
                            if (left2 >= 0 && width > 0) {
                                const ecgSliderTrackNode = document.createElement("div");
                                ecgSliderTrackNode.className = "ant-slider-track";
                                ecgSliderTrackNode.style.left = ((left2 / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                                ecgSliderTrackNode.style.right = "auto";
                                // ecgSliderTrackNode.style.height = "52px"
                                ecgSliderTrackNode.style.width = ((width / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                                ecgSliderTrackNode.style.backgroundColor = "#c6156a"
                                ecgSliderNodes[0].appendChild(ecgSliderTrackNode);
                                // console.log("片段2", ecgSliderTrackNode.style.left, ecgSliderTrackNode.style.width, ecgSliderNodes)
                                left2 = -1;
                                width = -1;
                            }
                        }
                    }


                    //切割每一次的数据 没数据就分开
                    if (!record || record.historyFileIndex <= 0 || (i + 1) === this.props.ecgTimeIndexData.timeSliceNum) {
                        if (width > 0 && left >= 0) {
                            const ecgSliderTrackNode = document.createElement("div");
                            ecgSliderTrackNode.className = "ant-slider-track";
                            ecgSliderTrackNode.style.left = ((left / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                            ecgSliderTrackNode.style.right = "auto";
                            // ecgSliderTrackNode.style.height = "52px"
                            ecgSliderTrackNode.style.width = ((width / this.props.ecgTimeIndexData.timeSliceNum) * 100).toString() + "%";
                            ecgSliderTrackNode.style.backgroundColor = "rgb(1, 131, 226)"
                            ecgSliderNodes[0].appendChild(ecgSliderTrackNode);
                            // console.log("片段1", ecgSliderTrackNode.style.left, ecgSliderTrackNode.style.width, ecgSliderNodes, index, this.props.ecgTimeSlideData)
                            left = -1;
                            width = -1;
                        }
                    }


                }
            }

        }


        // console.log("this.props.sliderValue:", this.props.sliderValue, this.props.ecgTimeIndexData, this.props.ecgTimeSlideData)
        windImgBg = this.props.ecgTimeIndexData ?
            'linear-gradient(90deg, rgba(204, 204, 204, 0.25) 0%, rgba(34, 131, 226, 0.25) 33%, rgba(34, 131, 226, 0.6) 33%, rgba(34, 226, 131, 0.6) 66%, rgba(34, 226, 131, 1) 66%,rgba(34, 131, 226, 1) 100%)' :
            'linear-gradient(90deg, rgba(204, 204, 204, 0.25) 0%, rgba(204, 204, 204, 0.25) 33%, rgba(204, 204, 204, 0.6) 33%, rgba(204, 204, 204, 0.6) 67%, rgba(204, 204, 204, 1) 67%,  rgba(204, 204, 204, 1) 100%)';
        //
        return (
            <div>
                <div id="time_index_slider" className="ecg-slider-container">

                    {/*<div className="ecg-slider-overlay" ref={this.containerRef}/>*/}
                    <Slider className="ecg-slider"
                            style={{width: "94%", height: "52px"}}
                        // tooltipVisible={true}
                            value={this.props.sliderValue}
                            marks={this.props.ecgTimeSlideMarks}
                        // marks={marks}
                        //     marks={this.props.ecgTimeSlideMarks}
                        // trackStyle={{
                        //     backgroundColor: 'rgb(222, 131, 226)',
                        //     height: '12px',
                        //     borderRadius: '6px',
                        // }}
                        // railStyle={{
                        //     // backgroundImage: windImgBg,
                        //     height: '52px',
                        //     borderRadius: '6px',
                        // }}
                            onChange={(value) => this.onChange(value)}
                            onAfterChange={(value) => this.onPlaySliderChange(value)}
                        // onDrop = {(value) => this.onPlaySliderChange(value)}
                            max={this.props.ecgTimeIndexData.timeSliceNum}
                            tipFormatter={() => {
                                const sliderValueMS = this.props.sliderValue * this.props.ecgTimeIndexData.miniSecPerFile
                                const dateMoment = moment(this.props.ecgTimeIndexData.date, FORMAT_DATE_SIMPLE).millisecond(sliderValueMS);
                                return dateMoment.format(FORMAT_TIME_COLON);
                            }}
                    />
                    {/*<Slider*/}
                    {/*    style={{width: "94%", height: "5px",paddingTop:"80px",paddingLeft:"20px"}}*/}
                    {/*    className="ecg-slider-exhibition"*/}
                    {/*    max={this.props.ecgTimeIndexData.timeSliceNum}*/}
                    {/*    marks={this.props.ecgTimeSlideMarks}*/}
                    {/*    onChange={(value) => this.onChange(value)}*/}
                    {/*    onAfterChange={(value) => this.onPlaySliderChange(value)}*/}
                    {/*    value={this.props.sliderValue}*/}
                    {/*    tipFormatter={() => {*/}
                    {/*        const sliderValueMS = this.props.sliderValue * this.props.ecgTimeIndexData.miniSecPerFile*/}
                    {/*        const dateMoment = moment(this.props.ecgTimeIndexData.date, FORMAT_DATE_SIMPLE).millisecond(sliderValueMS);*/}
                    {/*        return dateMoment.format(FORMAT_TIME_COLON);*/}
                    {/*    }}*/}
                    {/*    // disabled={true}*/}
                    {/*    // railStyle={{*/}
                    {/*    //     backgroundImage: "linear-gradient(90deg,rgba(255,255,255,1) 0%,rgba(255,255,255,1) 100%)",*/}
                    {/*    // }}*/}
                    {/*/>*/}
                    <div style={{paddingTop: "60px", paddingLeft: "33px"}}>
                        <b>文件参数：</b>
                    </div>
                    <Descriptions size="small" column={4} style={{paddingLeft: "33px"}}>
                        <Descriptions.Item
                            label="日期">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.date : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="文件编号">{this.props.historyFileIndex ? this.props.historyFileIndex : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="文件开始时间">{this.props.startTime ? this.props.startTime : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="文件时长">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.miniSecPerFile : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="记录条数">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.recordNum : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="非空记录条数">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.timeNum : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="记录总时长">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.workTime : ""}</Descriptions.Item>
                        <Descriptions.Item
                            label="时间隙数量">{this.props.ecgTimeIndexData ? this.props.ecgTimeIndexData.timeSliceNum : ""}</Descriptions.Item>
                    </Descriptions>
                    {/*<Slider*/}
                    {/*    marks={{0: "A", 20: "B", 40: "C", 60: "D", 80: "E", 100: "F"}}*/}
                    {/*/>*/}
                </div>
            </div>
        );
    };
}
