import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Row, Col, Input, Button, Select} from "antd";

import {
    refreshOrganization,
    refreshSearchCondition,
    searchOrganizations,
    toggleDetailModal
} from '../../../actions/OrganizationAction';
import {OrganizationTypes} from "../../../Enums";

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 18}, style: {marginBottom: "3px"}};

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
        this.props.dispatch(refreshSearchCondition({}));
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchOrganizations(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {

        this.props.dispatch(refreshOrganization({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ORG_ORGANIZATION_FIELD_NAME"})} {...formItemLayout}>
                                <Input onChange={(value) => this._handleChange("name", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ORG_ORGANIZATION_FIELD_TYPE"})} {...formItemLayout}>
                                <Select
                                    mode="multiple"
                                    onChange={(value) => this._handleChange("types", value)}>
                                    {
                                        OrganizationTypes.map((item, i) => {
                                            return (
                                                <Select.Option key={i} value={item.value}>
                                                    <FormattedMessage id={item.label}/>
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" icon="search" onClick={() => this._handleSearch(this.props.searchCondition)}
                                    className="spacing-h">
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button icon="plus" onClick={() => this._handleShowCreateModal()}>
                                <FormattedMessage id='COMMON_BUTTON_CREATE'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.OrgOrganizationReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));