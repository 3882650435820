class EcgSelectRectangle {
    constructor() {
        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;
    }

    toSVG() {
        const width = this.endX - this.startX;
        const height = this.endY - this.startY;
        const rect = "<rect id='ecg_view_select_rectangle' x='" + this.startX + "' y='" + this.startY + "' width='" + width + "' height='" + height + "' style='stroke:blue;fill: none;stroke-width:1;'/>";
        return rect;
    }
}

export default EcgSelectRectangle;