import React, {Component} from "react";
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {Form,DatePicker, Button, Select, Table, message, Row, Col,Input} from 'antd';
import {userModel} from "../../../Enums"
import {
    downloadDeviceFile,
    getUserMemoList, refreshMemoTimeFrom, refreshMemoTimeTo,
    refreshVersionIndex,
    searchEcgRecordSum
} from "../../../actions/DeviceAction";
import {getDateIndexFile, refreshVersionChange} from "../../../actions/HistoryAction";
import PaginationBar from "../../../../../components/PaginationBar";
import {position} from "plotly.js/src/plots/cartesian/layout_attributes";
import {Link} from 'react-router';
import BenzAMRRecorder from 'benz-amr-recorder';
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import moment from "moment";

// let xuanzeValue
let userMemoListParem = {};
let deviceCode
let deviceCodeInGuard

let phoneNumber

let deviceFlag = true;
let deviceInGuardFlag = true;
let allFlag = false;

let version;
let src;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            userModelValue: "",
            deviceCode: "",
            deviceCodeInGuard: "",
            version: "",
            isToggleOn: true,
            amr: new BenzAMRRecorder(),
            selectIdx: 0,
            columns: [
                {
                    key: 'createTime',
                    dataIndex: 'createTime',
                    title: "时间",
                    // width: 300,
                    render: (text, record, index) => this._renderTime(text)
                },
                {
                    key: "",
                    dataIndex: "",
                    title: "用户名",
                    render: (text, record, index) => text
                },
                {
                    key: "phoneNumber",
                    dataIndex: "phoneNumber",
                    title: "手机号",
                    render: (text, record, index) => text
                },
                {
                    key: "deviceCode",
                    dataIndex: "deviceCode",
                    title: "直连设备编号",
                    render: (text, record, index) => text
                },
                {
                    key: "deviceCodeInGuard",
                    dataIndex: "deviceCodeInGuard",
                    title: "监护他人设备编号",
                    render: (text, record, index) => text
                },
                {
                    key: "userMemo",
                    dataIndex: "userMemo",
                    title: "用户记录",
                    render: (text, record, index) => this._renderPlayAudio(text, record, index)
                },
                {
                    key: "phoneModel",
                    dataIndex: "phoneModel",
                    title: "手机品牌型号",
                    render: (text, record, index) => text
                },
                {
                    key: "appVersion",
                    dataIndex: "appVersion",
                    title: "APP版本",
                    render: (text, record, index) => text
                },
                {
                    key: "appMemory",
                    dataIndex: "appMemory",
                    title: "内存",
                    render: (text, record, index) => text
                },
                {
                    key: "activeTaskCount",
                    dataIndex: "activeTaskCount",
                    title: "活性任务数",
                    render: (text, record, index) => text
                },
                {
                    key: "corePoolSize",
                    dataIndex: "corePoolSize",
                    title: "线程池容量",
                    render: (text, record, index) => text
                }
            ]
        }
    }

    _renderTime(text) {
        var str = ""
        str += text.toString().substring(0, 4) + "/"
        str += text.toString().substring(4, 6) + "/"
        str += text.toString().substring(6, 8) + " "
        str += text.toString().substring(8, 10) + ":"
        str += text.toString().substring(10, 12) + ":"
        str += text.toString().substring(12, 14)
        return str
    }

    componentWillMount() {
        deviceCode = this.props.deviceCode;
        console.log("componentWillMount:", this.props.mapVersion, this.props.version)
        version = this.props.mapVersion[0]
        this.setState({version: version});
    }


    componentDidMount() {
    }

    _handleVersionChange(value) {

        if (Number(value) != 0) {
            version = this.props.mapVersion[this.props.mapVersion.length - Number(value)]
        } else {
            version = this.props.mapVersion[Number(value)]
        }
        this.setState({version: version});
        console.log("_handleVersionChange:", value, version)

        userMemoListParem.generationId = version;
    }

    _handleChangeUserModel(value) {
        this.empty();

        switch (value) {
            case 0:
                allFlag = true;
                deviceInGuardFlag = false
                deviceFlag = false
                break
            case 1:
                deviceFlag = true
                break
            case 2:
                deviceInGuardFlag = true;
                break
            case 3:
                break
        }
        if (!deviceInGuardFlag && !allFlag) {
            deviceCodeInGuard = this.props.deviceCode
            userMemoListParem.deviceCodeInGuard = deviceCodeInGuard
        }
        if (!deviceFlag) {
            deviceCode = this.props.deviceCode
            userMemoListParem.deviceCode = deviceCode
        }
        this.setState({"userModelValue": value});
    }

    _handleChange() {
        if (!deviceFlag | !deviceInGuardFlag | allFlag) {
            this.props.dispatch(getUserMemoList(userMemoListParem, this.state.formatMessage))
        } else {
            message.error("请选择监护模式,并且选择您需要监护的对象")
        }
    }

    _handleDownload() {
        console.log("userMemoList:" +this.props.userMemoList);
        if (this.props.userMemoList.length > 0) {
            let name = "UserMemo_";
            let myDate =new Date();
            name = name + myDate.toLocaleString().replaceAll("/","").replaceAll(":","").replaceAll(" ","");
            this.props.dispatch(downloadDeviceFile(userMemoListParem,name + ".xlsx", this.state.formatMessage))
        } else {
            message.error("请先检索数据。")
        }
    }

    _handleChangephoneNumber(value) {
        console.log("_handleChangephoneNumber:",value,value.target.value,value.target)
        phoneNumber = value.target.value
        userMemoListParem.phoneNumber = phoneNumber
    }

    _handleChangeDeviceCode(value, model) {
        if (model == 1) {
            deviceCode = value
            userMemoListParem.deviceCode = deviceCode
        } else {
            deviceCodeInGuard = value
            userMemoListParem.deviceCodeInGuard = deviceCodeInGuard
        }
        this.setState({deviceCode: "1"})
    }

    _handleChangeDate(value,name){
        let date;
        if(value){
            date = value.format(FORMAT_DATE_TIME_SIMPLE);
        }else{
            date = "";
        }

        if(name === "from"){
            userMemoListParem.memoTimeFrom = date;
            this.props.dispatch(refreshMemoTimeFrom(date));
        }else{
            userMemoListParem.memoTimeTo = date;
            this.props.dispatch(refreshMemoTimeTo(date));
        }
    }

    empty() {
        allFlag = false;
        deviceInGuardFlag = false
        deviceFlag = false
        userMemoListParem.deviceCode = "";
        userMemoListParem.deviceCodeInGuard = "";
        deviceCode = ""
        deviceCodeInGuard = ""
    }

    _renderPlayAudio(text, record, index){
        if(text === ""){
            let src = this.blobtoUrl(record.voice,"amr");
            return (

                <div style={{ padding: '0px 6px', width: 80, height: 28, backgroundColor: '#eee', borderRadius: "12px" }}>
                    {
                        this.state.selectIdx === index ?
                            this.state.isToggleOn
                                ? (
                                    <Row onClick={() => this.change(src,index)} style={{ cursor: "pointer" }}>
                                        <Col span={24} style={{ marginTop: 3, paddingLeft: 6 }}>开始播放</Col>
                                    </Row>
                                )
                                : (
                                    <Row onClick={() => this.consol()}>
                                        <Col span={24} style={{ marginTop: 3, paddingLeft: 6 }}>停止播放</Col>
                                    </Row>
                                )
                            :(
                                <Row onClick={() => this.change(src,index)} style={{ cursor: "pointer" }}>
                                    <Col span={24} style={{ marginTop: 3, paddingLeft: 6 }}>开始播放</Col>
                                </Row>
                            )
                    }
                </div>

            )
        }else{
            return (
                <div>
                    {text}
                </div>
            )
        }
    }

    // 点击开始播放
    change = (src,index) => {

        const { amr } = this.state;

        // amr = new BenzAMRRecorder();
        // amr请求
        amr.initWithUrl(src).then(() => {
            amr.play();
        });

        // 播放完成自动回到开始播放按钮
        amr.onEnded(() => {
            this.setState({
                amr: new BenzAMRRecorder(),
                isToggleOn: true,
            })
        })

        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn,
            selectIdx:index
        }))
    }

    // 点击停止播放
    consol = () => {
        const { amr } = this.state;
        this.setState(prevState => ({
            amr: new BenzAMRRecorder(),
            isToggleOn: !prevState.isToggleOn,
        }))
        amr.onPause(() => {
            console.log(1)
        })
        amr.pause();
    }

    blobtoUrl(base64,fileType){
        let typeHeader = 'data:application/' + fileType + ';base64,' // 定义base64 头部文件类型
        let converedBase64 = typeHeader + base64;  // 拼接最终的base64
        let blob = this.base64ToBlob(converedBase64, fileType)  // 转成blob对象

        return window.URL.createObjectURL(blob);
    }

    base64ToBlob(urlData, type) {

        let arr = urlData.split(',');
        let array = arr[0].match(/:(.*?);/);
        let mime = (array && array.length > 1 ? array[1] : type) || type;
        // 去掉url的头，并转化为byte
        let bytes = window.atob(arr[1]);
        // 处理异常,将ascii码小于0的转换为大于0
        let ab = new ArrayBuffer(bytes.length);
        // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
        let ia = new Uint8Array(ab);
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
        }
        return new Blob([ab], {
            type: 'audio/amr'
        });

    };

    render() {
        const formatMessage = this.state.formatMessage;

        let lstData = [];
        this.props.mapVersion.sort((curr, next) => {
            let a = curr;
            let b = next;

            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        });

        this.props.mapVersion.map((item, index) => {
            if (index === 0) {
                lstData.push({value: '00', label: item + "(当世)"});
            } else {
                lstData.push({value: item.substring(2, 4), label: item});
            }
        })
        return (
            <div>
                <Form>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="手机号" {...Label_6_Wrapper_18}>
                                <Input
                                    value={phoneNumber}
                                    style={{width: 200}}
                                    onChange={(value) => {
                                        this._handleChangephoneNumber(value)
                                    }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="世代编号" {...Label_6_Wrapper_18}>
                                <EnumItemSelect className="spacing-h"
                                                data={lstData}
                                                value={version}
                                                style={{width: 250}}
                                                onChange={(value) => {
                                                    this._handleVersionChange(value);
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="日期范围" {...Label_6_Wrapper_18}>
                                <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                            format={FORMAT_DATE_TIME_HYPHEN}
                                            allowClear={true}
                                            value={
                                                this.props.memoTimeFrom ?
                                                    moment(this.props.memoTimeFrom, FORMAT_DATE_TIME_SIMPLE)
                                                    :
                                                    null
                                            }
                                            placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                            onChange={(value) => {
                                                this._handleChangeDate(value,"from");
                                            }}/>
                                --
                                <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                            format={FORMAT_DATE_TIME_HYPHEN}
                                            allowClear={true}
                                            value={
                                                this.props.memoTimeTo ?
                                                    moment(this.props.memoTimeTo, FORMAT_DATE_TIME_SIMPLE)
                                                    :
                                                    null
                                            }
                                            placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                            onChange={(value) => {
                                                this._handleChangeDate(value,"to");
                                            }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="监护模式" {...Label_6_Wrapper_18}>
                                <EnumItemSelect className="spacing-h"
                                                data={userModel.List}
                                                value={this.state.userModelValue}
                                                style={{width: 200}}
                                                onChange={(value) => {
                                                    this._handleChangeUserModel(value);
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="直连设备编号" {...Label_6_Wrapper_18}>
                                <Select style={{width: 250}}
                                        optionFilterProp="children"
                                        showSearch
                                        placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}
                                        value={deviceCode}
                                        disabled={deviceFlag}
                                        onChange={(value) => {
                                            this._handleChangeDeviceCode(value, 1)
                                        }}>
                                    {
                                        this.props.allDevices.map((item, index) => {
                                            return <Select.Option key={index}
                                                                  value={item.code}>{item.code}</Select.Option>
                                            //device={this.state.deviceCode}
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="监护他人设备编号" {...Label_6_Wrapper_18}>
                                <Select style={{width: 200}}
                                        optionFilterProp="children"
                                        showSearch
                                        placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}
                                        value={deviceCodeInGuard}
                                        disabled={deviceInGuardFlag}
                                        onChange={(value) => {
                                            this._handleChangeDeviceCode(value, 2)
                                        }}>
                                    {
                                        this.props.allDevices.map((item, index) => {
                                            return <Select.Option key={index}
                                                                  value={item.code}>{item.code}</Select.Option>
                                            //device={this.state.deviceCode}
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                            <Button className="spacing-h"
                                    style={{marginLeft:145,width:120}}
                                    onClick={(value) => {
                                this._handleChange(value);
                            }}>检索</Button>
                            <Button className="spacing-h"
                                    style={{width:120}}
                                    onClick={(value) => {
                                        this._handleDownload(value);
                                    }}>下载Excel</Button>
                        </Col>
                    </Row>
                </Form>

                <Table rowKey="id"
                       size="small"
                       className="spacing-v"
                    // pagination={{position: [bottomRight, topRight], size: 10}}
                       pagination={true}
                       columns={this.state.columns}
                       scroll={{y: 638}}
                       dataSource={this.props.userMemoList}
                       style={{marginBottom: 10}}
                       onRow={record => {
                           return {
                               onClick: this._handleShowDetailModal.bind(this, record)
                           }
                       }}
                />
                {/*<Table rowKey="id"*/}
                {/*       size="small"*/}
                {/*       className="spacing-v"*/}
                {/*       showHeader={false}*/}
                {/*       columns={this.state.columns}*/}
                {/*       dataSource={latestContents}*/}
                {/*       pagination={{position: "both"}}*/}
                {/*/>*/}
            </div>)
    }

    _handleShowDetailModal(record) {
        if (record) {
            this.props.setDefaultActiveKey("5");
            this.props.setHistoryIndex(record.HistoryFileIndex)
            this.props.setHistoryFlag(false);
            this.props.setRecordValue(record);
        }
    }

}

function mapStateToProps(store) {
    return {
        allDevices: store.EcgDeviceReducer.allDevices,
        mapVersion: store.EcgDeviceReducer.mapVersion,
        version: store.EcgDeviceReducer.version,
        userMemoList: store.EcgDeviceReducer.userMemoList,
        memoTimeFrom:store.EcgDeviceReducer.memoTimeFrom,
        memoTimeTo:store.EcgDeviceReducer.memoTimeTo
    }
}

export default connect(mapStateToProps)(injectIntl(Index));