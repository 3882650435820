import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import PageTitle from "../../../../components/PageTitle";
import SearchResult from "./SearchResult";
import {CACHE_SITE_ID} from "../../../../constants/Profile";
import {getChannelContentTree} from "../../actions/ChannelAction";
import HistoryModal from "./HistoryModal";

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            height: "100%",
        }
    }

    componentWillMount() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        const contentFileMap = Object.assign({}, this.props.contentFileMap);
        this.props.dispatch(getChannelContentTree(siteId, contentFileMap, this.state.formatMessage));
    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    render() {
        return (
            <div className="page-content"  style={{maxHeight: height}}>
                <PageTitle title="CMS_SOFTWARE_ROUTE_LATEST_VERSION_DOWNLOAD"/>
                <HistoryModal {...this.props} />
                <SearchResult {...this.props}/>
                {/*<Table rowKey="id" size="small" className="spacing-v" pagination={false}
                       columns={columns} dataSource={this.props.devices}/>*/}
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        contentFileMap:store.ContentFileReducer.contentFileMap,
        latestContents:store.ContentReducer.latestContents
    }
}

export default connect(mapStateToProps)(injectIntl(Index));