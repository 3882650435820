import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    dictItems: [],
    dictItem: {},
    // Key: class code, value: item
    dictItemMap: {},
    showDetailModal: false,
};

const DictItemReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DICT_ITEM_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DICT_ITEM_REFRESH_ITEM:
            return Object.assign({}, state, {
                dictItem: action.dictItem
            });
        case ActionTypes.DICT_ITEM_REFRESH_ITEMS:
            return Object.assign({}, state, {
                dictItems: action.dictItems
            });
        case ActionTypes.DICT_ITEM_REFRESH_ITEM_MAP:
            return Object.assign({}, state, {
                dictItemMap: action.dictItemMap
            });
        case ActionTypes.DICT_ITEM_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default DictItemReducer;