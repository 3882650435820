/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import PageTitle from "../../../../../components/PageTitle";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import DetailModal from "./DetailModal";
import FieldSelectModal from "./FieldSelectModal";
import CheckUpdateVersion from "../../../../CMS/components/CheckUpdateVersion";

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            height: '100%'
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    //style={{overflowX: "auto",flex: "nowrap"}}

    render() {
        return (
            <div className="page-content position-relative" style={{maxHeight: height}}>
                <CheckUpdateVersion/>
                <PageTitle title="DAS_DEVICE_ROUTE_LIST"/>
                <DetailModal/>
                <FieldSelectModal/>
                <SearchCondition/>
                <SearchResult {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));