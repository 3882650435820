const EcgDrawUtils = {

    // SVG 线的背景颜色
    lineColors: ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E'],

    _renderEcgView: (numLeads, xCellCount, yCellCount, cellWidth) => {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        ecgViewWrapNode.innerHTML = "";
        for (let i = 0; i < numLeads; i++) {
            // 创建SVG容器标签
            const height = yCellCount * cellWidth;
            const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' class='svgplot' width='100%' height='" + height + "' preserveAspectRatio='xMidYMid meet'>";
            // 创建SVG的Group标签
            const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";

            const ecgViewText = EcgDrawUtils._getECGViewText(i, height);

            // 创建心电图背景图
            const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
            const ecgViewBackgroundEnd = "</g>";
            const ecgViewBackground = ecgViewBackgroundStart + EcgDrawUtils._getECGViewBackground(xCellCount, yCellCount, cellWidth) + ecgViewBackgroundEnd;

            // 创建心电图波形
            const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
            const ecgViewLightWaveEnd = "</g>";
            // 创建心电图波形
            const ecgViewLightWave = ecgViewLightWaveStart + /*EcgDrawUtils._getECGViewLightWave(i) + */ecgViewLightWaveEnd;

            const ecgViewGroupEnd = "</g>";
            const ecgViewEnd = "</svg>";

            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
        }
    },

    /** 画心电图的上的文本 */
    _getECGViewText: (index, winH) => {
        const centerY = winH / 2;
        // 画导联的名称
        const result = "<text x=0 y=" + centerY + ">CH-" + (index + 1) + "</text>";
        return result;
    },

    /** 画心电图的背景 */
    _getECGViewBackground: (winMarginX, winMarginY, xCellCount, yCellCount, cellWidth) => {
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPointY = (yCellCount - 3) * cellWidth + winMarginY;
        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        // 画原点
        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    },

    /** 画心电图的折线 */
    _getECGViewLightWave: (ecgPoints, index, pointSpace, winMarginX, winMarginY, zeroPointY, yCellCount, cellWidth, gridScale, gc, voltDiv, annotationMap) => {

        let result = "";
        if (!ecgPoints || ecgPoints.length <= 0) {
            return result;
        }
        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";

        let x = winMarginX;
        for (let i = 0; i < ecgPoints.length; i++) {

            // 计算前一个点
            let preY = 0;
            if (i !== 0) {
                if (ecgPoints[i - 1].newPoint !== null) {
                    preY = ecgPoints[i - 1].newPoint;
                } else {
                    preY = ecgPoints[i - 1].point;
                    if (preY !== 0) {
                        preY = preY / gc;// * 0.000049;
                        preY = preY / (1 / (voltDiv / gridScale));
                        preY = preY * cellWidth;
                    }
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (preY > 0) {
                        preY = zeroPointY - preY;
                    } else {
                        preY = Math.abs(preY) + zeroPointY;
                    }
                }
            }

            // 计算当前点
            let y = 0;
            // 超出了长度，则不处理
            if (ecgPoints[i] === null || ecgPoints[i] === undefined) {
                break;
                // drawBuffer.push(new ECGPoint());
            }

            if (ecgPoints[i].newPoint !== null) {
                y = ecgPoints[i].newPoint;
            } else {
                y = ecgPoints[i].point;
                y = y / gc;// * 0.000049;
                y = y / (1 / (voltDiv / gridScale));
                y = y * cellWidth;

                // 定位第一个点
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (y > 0) {
                    y = zeroPointY - y;
                } else {
                    y = Math.abs(y) + zeroPointY;
                }
                // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                ecgPoints[i].newPoint = y;
            }

            if (i === 0) {
                // 定位第一个点
                path = path + "M0" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }

            x = x + pointSpace;

            result = result + EcgDrawUtils._getECGViewLightWaveAnnotationLine(annotationMap, x, i, yCellCount, cellWidth, winMarginY);
        }

        // console.log(result);

        result = result + "<path stroke='" + EcgDrawUtils.lineColors[index] + "' fill='none' strokeWidth='1' d='" + path + "'></path>";
        return result;
    },

    _getECGViewLightWaveAnnotationLine(annotationMap, x, index, yCellCount, cellWidth, winMarginY) {
        let result = "";
        // 绘制心电图标注
        // 判断标注Map里是否包含当前历史文件索引
        if (annotationMap && annotationMap[index]) {
            // 取出标注对象
            const annotation = annotationMap[index];
            // 删除标识的标注不描画
            if (annotation.deleteFlag !== 1) {
                const y = yCellCount * cellWidth;
                const auxLine = "<line id='" + annotation.id + "_" + index + "' class='ecg-device-file-annotation' x1='" + x + "' y1='" + winMarginY + "' x2='" + x + "' y2='" + y + "' stroke-dasharray='5 2' stroke='grey' stroke-width='5'/>";
                const auxLineText = "<text y='" + (y + 10) + "' x='" + (x - 5) + "'>" + annotation.annotation + "</text>";
                result = result + auxLine + auxLineText;
            }
        }
        return result;
    }
};
export default EcgDrawUtils;