/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    countryTree: [],
    countryTreeExpandKeys: [],
    countries: [],
    country: {},
    showDetailModal: false
};

const CountryReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DISTRICT_COUNTRY_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DISTRICT_COUNTRY_TREE:
            return Object.assign({}, state, {
                countryTree: action.countryTree,
                countryTreeExpandKeys: action.countryTreeExpandKeys
            });
        case ActionTypes.DISTRICT_COUNTRY_SEARCH:
            return Object.assign({}, state, {
                countries: action.countries
            });
        case ActionTypes.DISTRICT_COUNTRY_REFRESH_COUNTRY:
            return Object.assign({}, state, {
                country: action.country
            });
        case ActionTypes.DISTRICT_COUNTRY_REFRESH_COUNTRY_TREE_EXPAND_KEYS:
            return Object.assign({}, state, {
                countryTreeExpandKeys: action.countryTreeExpandKeys
            });
        case ActionTypes.DISTRICT_COUNTRY_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default CountryReducer;