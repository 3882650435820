/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Collapse} from 'antd';

import {Avatar, Card, Divider, Icon, Menu, Popconfirm, Table, Button} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import SiderLayout from "../../../../../layout/SiderLayout";

import {
    deleteBinder,
    getBinderDevices,
    getBinderGuarders,
    refreshBinder,
    searchBinders, toggleDeviceBindModal,
    toggleDeviceListModal,
    toggleGuarderListModal
} from '../../../actions/BinderAction';

import {UserSex} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

import mqtt from "mqtt";
import {RoutePath} from "../../../../../constants/RoutePath";
import {Link} from "react-router";
import {CACHE_SESSION_USER} from "../../../../../constants/Profile";
import {searchDeviceBindHistoryList, toggleDeviceBindHistoryModal} from "../../../actions/DeviceBindHistoryAction";
import {searchGuarderBindHistoryList, toggleGuarderBindHistoryModal} from "../../../actions/GuarderBindHistoryAction";

// const mqttId = [];
let mqttA = {};
let client;

let ActiveKey = 2;

let messageIdMap = {};
let mobileMap = {};
let mqttIdMap = [];
let mqttData = [];

class SearchResult extends Component {

    constructor(props) {


        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            // Key: record.id, value: mqtt value
            mqttId: {},
            mobileCount: {},
            formatMessage: this.props.intl['formatMessage'],
            showDetail: false,
            ActiveKey: [],
            columns: [
                {
                    key: 'userName',
                    dataIndex: 'userName',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_NAME'})
                },
                {
                    key: 'userMobile',
                    dataIndex: 'userMobile',
                    title: formatMessage({id: 'ECG_BINDER_MOBILE'})
                },
                {
                    key: 'userMobiles',
                    dataIndex: 'userMobiles',
                    width: 250,
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_MOBILES'}),
                    render: (text, record, index) =>
                        this._renderMqttId(text, record, index)
                },
                {
                    key: 'userNickname',
                    dataIndex: 'userNickname',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_NICKNAME'})
                },
                // {
                //     key: 'userEmail',
                //     dataIndex: 'userEmail',
                //     title: formatMessage({id: 'ECG_BINDER_FIELD_USER_EMAIL'})
                // },
                {
                    key: 'userSex',
                    dataIndex: 'userSex',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_SEX'}),
                    render: (text, record, index) => <EnumItemLabel map={UserSex.Map} code={text}/>
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {

        client = mqtt.connect('ws://140.206.121.162:7768/mqtt');
        const self = this;

        // let mobileCount = 0;
        client.on('connect', function () {
            client.subscribe('D9K_MQTT/#', function (err) {
                if (!err) {
                    window.mobileData = [];
                }
            })
        })


        client.on('message', function (topic, message) {
            if (topic.toString().substr(9, 6) != "NOTICE" && topic.toString().substr(9, 3) != "SOS"
                || topic.toString().substr(9, 10) == "APPPERFORM") {
                console.log("topic:", topic + ":" + message.toString())
                // message is Buffer
                let mqttIdMap2 = [];
                var obj = JSON.parse(message);
                var messageId;
                var mqttId;
                if (topic.toString().substr(9, 10) == "APPPERFORM") {
                    messageId = topic.toString().substr(20, 32);
                    mqttId = topic.toString().substr(53, 36);
                } else {
                    messageId = topic.toString().substr(9, 32);
                    mqttId = topic.toString().substr(42, 36);
                }
                mqttA[mqttId] = obj;
                console.log("messageId:", messageId, "mqttId:", mqttId);
                self.setState({"mobileCount": mobileMap})
                for (var i = 0; i < mqttIdMap.length; i++) {
                    if (mqttIdMap[i] === mqttId) {
                        return;
                    }
                }
                // if (mqttId == mqttIdMap[messageId]) {
                //     return
                // }
                // if (mqttId != self.state.mqttId[messageId]) {
                //     mobileCount++
                // }

                // mqttIdMap[messageId] = mqttId;
                mqttIdMap.push(mqttId);
                if (messageIdMap[messageId]) {
                    messageIdMap[messageId].push(mqttId);
                } else {
                    mqttIdMap2.push(mqttId);
                    messageIdMap[messageId] = mqttIdMap2;
                }

                mobileMap[messageId] = messageIdMap[messageId].length;
                self.setState({"mobileCount": mobileMap, "mqttId": messageIdMap});
                // self.state.mqttId[messageId] = mqttId;
                // console.log(mqttA);
                // console.log(client);
            }
        })

        this.discardData = setInterval(() => {
                mqttIdMap = [];
                mqttA = [];
                mobileMap = {};
                messageIdMap = {};
                self.setState({"mqttId": messageIdMap, "mobileCount": mobileMap});
            },
            60000)
    }

    init(index) {
        mqttIdMap = [];
        mqttA = [];
        mobileMap = {};
        messageIdMap = {};
        switch (index) {
            case 1:
                this.setState({"mqttId": messageIdMap, "mobileCount": mobileMap, "ActiveKey": [1]});
                break
            case 2:
                this.setState({"mqttId": messageIdMap, "mobileCount": mobileMap, "ActiveKey": []});
                break
        }

    }

    componentWillUnmount() {
        this._handleStopSearch();
    }

    _handleStopSearch() {
        clearInterval(this.discardData)
        client.end();
    }

    _handleShowDeviceBindHistoryModal(record) {
        this.props.dispatch(searchDeviceBindHistoryList({binderId: record.id}, this.state.formatMessage));
        this.props.dispatch(toggleDeviceBindHistoryModal(true));
    }

    _handleShowGuarderBindHistoryModal(record) {
        this.props.dispatch(searchGuarderBindHistoryList({binderId: record.id}, this.state.formatMessage));
        this.props.dispatch(toggleGuarderBindHistoryModal(true));
    }

// <Link
// to={RoutePath.DAS_MQTT_LIST.path + "?userId=" + record.userId + "&userName=" + encodeURI(record.userName)}
// URIEncoding="utf-8">
// <FormattedMessage id="ECG_BINDER_MOBILE"/>
// </Link>
    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleGetDevices.bind(this, record)}>
                    <FormattedMessage id="ECG_BINDER_BUTTON_DEVICES"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleShowDeviceBindHistoryModal.bind(this, record)}>
                    <FormattedMessage id="ECG_BINDER_BUTTON_DEVICE_BIND_HISTORY"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleGetGuarders.bind(this, record)}>
                    <FormattedMessage id="ECG_BINDER_BUTTON_GUARDERS"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleShowGuarderBindHistoryModal.bind(this, record)}>
                    <FormattedMessage id="ECG_BINDER_BUTTON_GUARDER_BIND_HISTORY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a> <FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

// {(mqttA[this.state.mqttId[record.userId][i]].phoneBrand ? mqttA[this.state.mqttId[record.userId]].phoneBrand : "") + " "
// + (mqttA[this.state.mqttId[record.userId][i]].phoneModel ? mqttA[this.state.mqttId[record.userId]].phoneModel : "") + " "
// + (mqttA[this.state.mqttId[record.userId][i]].phoneNumber ? mqttA[this.state.mqttId[record.userId]].phoneNumber : "(未知)")}

// {this.state.mqttId[record.userId][i]}

// .map((item,index)=>{
//     item
// })

    _render(record, i) {
        let text = "";
        let phoneNumber = ""
        let guardMode = ""
        let appVersion = ""
        let deviceId = ""
        let deviceIdInGuard = ''
        if (this.state.mqttId[record.userId]) {
            if (mqttA[this.state.mqttId[record.userId][i]]) {
                text = (mqttA[this.state.mqttId[record.userId][i]].phoneBrand ? mqttA[this.state.mqttId[record.userId][i]].phoneBrand : "") + " "
                    + (mqttA[this.state.mqttId[record.userId][i]].phoneModel ? mqttA[this.state.mqttId[record.userId][i]].phoneModel : "")
                phoneNumber = "手机号码：" + (mqttA[this.state.mqttId[record.userId][i]].phoneNumber ? mqttA[this.state.mqttId[record.userId][i]].phoneNumber : "未知")
                guardMode = "监护模式：  " + (mqttA[this.state.mqttId[record.userId][i]].guardMode ? mqttA[this.state.mqttId[record.userId][i]].guardMode : "未知");
                appVersion = "app版本：  " + (mqttA[this.state.mqttId[record.userId][i]].appVersion ? mqttA[this.state.mqttId[record.userId][i]].appVersion : "未知");
                deviceId = "直连设备编号：" + (mqttA[this.state.mqttId[record.userId][i]].deviceId ? mqttA[this.state.mqttId[record.userId][i]].deviceId : "无");
                deviceIdInGuard = "远程监护设备编号：" + (mqttA[this.state.mqttId[record.userId][i]].deviceIdInGuard ? mqttA[this.state.mqttId[record.userId][i]].deviceIdInGuard : "无");
            }
        }
        console.log("_render:", this.state.mqttId, record.userId)
        return (
            <div>
                <div>
                    {text}
                </div>
                <div>
                    {phoneNumber}
                </div>
                <div>
                    {guardMode}
                </div>
                <div>
                    {appVersion}
                </div>
                <div>
                    {deviceId}
                </div>
                <div>
                    {deviceIdInGuard}
                </div>
            </div>

        )
    }

    _renderMqttId(text, record, index) {
        const {Panel} = Collapse;
        let res = [];
        let resV = [];
        for (let i = 0; i < this.state.mobileCount[record.userId]; i++) {
            res.push(<Link
                to={RoutePath.DAS_MQTT_LIST.path + "?userId=" + record.userId + "&userName=" + encodeURI(record.userName)}
                URIEncoding="utf-8">
                {this._render(record, i)}
                <br/>
            </Link>)
        }
        //[ActiveKey]
        if (res.length > 0) {
            resV.push(<Collapse defaultActiveKey={this.state.ActiveKey} title={this.state.mqttId[record.userId]}
                                placement="bottom">
                <Panel key={1} header={this.state.mobileCount[record.userId]}>
                    {res}
                </Panel>
            </Collapse>)
        }
        //输出出来是一个数组但是里面是没有内容的
        return (
            <div>
                {resV}
            </div>
        )
    }

// <Collapse title={this.state.mqttId[record.userId]} placement="bottom">
// <Panel key={1} header={this.state.mobileCount[record.userId]}>
//     {res}
// </Panel>
// </Collapse>

    _handleGetDevices(record) {
        this.props.dispatch(refreshBinder(record));
        this.props.dispatch(getBinderDevices(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleDeviceListModal(true));
    }

    _handleGetGuarders(record) {
        this.props.dispatch(refreshBinder(record));
        this.props.dispatch(getBinderGuarders(record.userId, this.state.formatMessage));
        this.props.dispatch(toggleGuarderListModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteBinder(record, searchCondition, formatMessage));
    }

    render() {
        return (

            <Card className="spacing-v page-width">
                <Button className="spacing-h"
                        onClick={(data) => this.init(1)}>全展开</Button>
                <Button className="spacing-h"
                        onClick={(data) => this.init(2)}>全折叠</Button>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, 30)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.binders} pagination={{position: "none", pageSize: 30}}/>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, 30)}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        binders: store.BinderReducer.binders,
        searchCondition: store.BinderReducer.searchCondition,
        pagination: store.BinderReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));