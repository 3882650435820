/**
 * Created by Ethan on 16/12/26.
 * 定义Action的类型
 */

// APP 全局的消息类型
export const APP_RESET_FLAG = "APP_RESET_FLAG";
export const APP_GET_DEFAULT_LANGUAGE = 'APP_GET_DEFAULT_LANGUAGE';
export const APP_CHANGE_LANGUAGE = 'APP_CHANGE_LANGUAGE';
export const APP_CLEAR_MESSAGE = "APP_CLEAR_MESSAGE";
export const APP_SHOW_MESSAGE = "APP_SHOW_MESSAGE";
export const APP_PAGINATION = "APP_PAGINATION";
export const APP_LOADING = "APP_LOADING";
export const APP_FORM_VALIDATE = "APP_FORM_VALIDATE";
export const APP_SET_CREATE_FLAG = "APP_SET_CREATE_FLAG";
export const APP_SET_SEARCH_FLAG = "APP_SET_SEARCH_FLAG";
export const APP_SET_UPDATE_FLAG = "APP_SET_UPDATE_FLAG";
export const APP_SET_DELETE_FLAG = "APP_SET_DELETE_FLAG";
export const APP_GET_MAIN_MENU = "APP_GET_MAIN_MENU";
export const APP_WARN_COUNT = 'APP_WARN_COUNT';
export const APP_REFRESH_WARN_FLAG = 'APP_REFRESH_WARN_FLAG';
export const APP_REFRESH_SHOW_WARN_RECORD_MODAL = 'APP_REFRESH_SHOW_WARN_RECORD_MODAL';
export const APP_REFRESH_WARN_RECORD = 'APP_REFRESH_WARN_RECORD';
export const APP_REFRESH_ACTIVITY_KEY = 'APP_REFRESH_ACTIVITY_KEY';
export const APP_REFRESH_SEARCH_CONDITION = 'APP_REFRESH_SEARCH_CONDITION';
export const APP_REFRESH_RECORD = 'APP_REFRESH_RECORD';
export const APP_WARN_LOADING = 'APP_WARN_LOADING';

export const APP_REFRESH_PATHNAME = "APP_REFRESH_PATHNAME";


export const LOGIN_RESET_STATE = 'LOGIN_RESET_STATE';
export const LOGIN_DOING = 'LOGIN_DOING';
export const LOGIN_DONE = 'LOGIN_DONE';
export const LOGIN_GET_USER_DOING = 'LOGIN_GET_USER_DOING';
export const LOGIN_GET_USER_DONE = 'LOGIN_GET_USER_DONE';

export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const ACCOUNT_TOGGLE_UPDATE_PROFILE_MODAL = "ACCOUNT_TOGGLE_UPDATE_PROFILE_MODAL";
export const ACCOUNT_TOGGLE_UPDATE_PASSPORT_MODAL = "ACCOUNT_TOGGLE_UPDATE_PASSPORT_MODAL";

export const SYS_USER_LIST_REFRESH_STATE_USER = "SYS_USER_LIST_REFRESH_STATE_USER";
export const SYS_USER_LIST_REFRESH_STATE_SEARCH_CONDITION = "SYS_USER_LIST_REFRESH_STATE_SEARCH_CONDITION";
export const SYS_USER_LIST_TOGGLE_CREATE_MODAL = "SYS_USER_LIST_TOGGLE_CREATE_MODAL";
export const SYS_USER_LIST_TOGGLE_UPDATE_MODAL = "SYS_USER_LIST_TOGGLE_UPDATE_MODAL";
export const SYS_USER_LIST_LOADING = "SYS_USER_LIST_LOADING";
export const SYS_USER_LIST_SEARCH = 'SYS_USER_LIST_SEARCH';

export const SYS_ROLE_LIST_RESET = "SYS_ROLE_LIST_RESET";
export const SYS_ROLE_LIST_LOADING = "SYS_ROLE_LIST_LOADING";
export const SYS_ROLE_LIST_SEARCH = "SYS_ROLE_LIST_SEARCH";

export const DEVICE_LOCATION_LIST_LOADING = 'DEVICE_LOCATION_LIST_LOADING';
export const DEVICE_LOCATION_LIST_SEARCH = 'DEVICE_LOCATION_LIST_SEARCH';

export const CLOTHES_LIST_LOADING = 'CLOTHES_LIST_LOADING';
export const CLOTHES_LIST_SEARCH = 'CLOTHES_LIST_SEARCH';
export const CLOTHES_LIST_REFRESH_STATE_SEARCH_CONDITION = 'CLOTHES_LIST_REFRESH_STATE_SEARCH_CONDITION';
export const CLOTHES_LIST_REFRESH_STATE_CLOTHES = 'CLOTHES_LIST_REFRESH_STATE_CLOTHES';
export const CLOTHES_LIST_TOGGLE_CREATE_MODAL = 'CLOTHES_LIST_TOGGLE_CREATE_MODAL';
export const CLOTHES_LIST_TOGGLE_UPDATE_MODAL = 'CLOTHES_LIST_TOGGLE_UPDATE_MODAL';
export const CLOTHES_LIST_TOGGLE_IMPORT_MODAL = "CLOTHES_LIST_TOGGLE_IMPORT_MODAL";


export const CONTENT_LIST_LOADING = 'CONTENT_LIST_LOADING';
export const CONTENT_LIST_SEARCH = "CONTENT_LIST_SEARCH";
export const CONTENT_LIST_CHANNELS = "CONTENT_LIST_CHANNELS";
export const CONTENT_LIST_REFRESH_STATE_SEARCH_CONDITION = 'CONTENT_LIST_REFRESH_STATE_SEARCH_CONDITION';
export const CONTENT_LIST_REFRESH_STATE_CONTENT = "CONTENT_LIST_REFRESH_STATE_CONTENT";
export const CONTENT_LIST_TOGGLE_CREATE_MODAL = "CONTENT_LIST_TOGGLE_CREATE_MODAL";
export const CONTENT_LIST_TOGGLE_UPDATE_MODAL = "CONTENT_LIST_TOGGLE_UPDATE_MODAL";

// 查询设备心电数据
export const ECG_LIST_SEARCH_DOING = 'ECG_LIST_SEARCH_DOING';
export const ECG_LIST_SEARCH_DONE = 'ECG_LIST_SEARCH_DONE';

export const  DEVICE_ECG_DATA_RESET_STATE = 'DEVICE_ECG_DATA_RESET_STATE';
// 获取全部导联数据列表
export const DEVICE_ECG_DATA_SEARCH_DOING = 'DEVICE_ECG_DATA_SEARCH_DOING';
export const DEVICE_ECG_DATA_SEARCH_DONE = 'DEVICE_ECG_DATA_SEARCH_DONE';

// 解析心电数据
export const DEVICE_ECG_DATA_ANALYZE_DOING = 'DEVICE_ECG_DATA_ANALYZE_DOING';
export const DEVICE_ECG_DATA_ANALYZE_DONE = 'DEVICE_ECG_DATA_ANALYZE_DONE';

export const USER_DETAIL_SEARCH_DOING = 'USER_DETAIL_SEARCH_DOING';
export const USER_DETAIL_SEARCH_DONE = 'USER_DETAIL_SEARCH_DONE';