/**
 * 字段    字节    范围|示例    说明
 *  PacketType    1B    0x33：文件请求响应    包类型
 *  FileType    4B        见《D9K协议文档 1.文件格式》
 *  FileOper    4B        0x01 Read
 *  FileIndex    4B    0-232-1    当前文件index
 *  FileNumber    4B    0-216-1    剩余的文件数量
 *  FilePoint    4B        文件访问的位置
 *  FileReqBytes    4B        文件访问的字节数
 *  FileReplyBytes    4B        文件实际访问的字节数
 *  FileTotalBytes    4B        文件的总字节数
 *  BlockID    4B    0-232-1    文件分割块的编号
 *  BlockLength    4B    0-232-1 Byte    此块中的FileData大小
 *  BlockNum    4B    0-232-1    此文件共包含几个块
 *  FileData        BlockLength长度
 */
class ProtocalHistoryData {
    constructor(dataArray, startPos) {
        let index = startPos;
        const fileTypeArray = dataArray.slice(index, index + 4);
        this.fileType = new Int32Array(fileTypeArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileOperArray = dataArray.slice(index, index + 4);
        this.fileOper = new Int32Array(fileOperArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileIndexArray = dataArray.slice(index, index + 4);
        this.fileIndex = new Int32Array(fileIndexArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileNumberArray = dataArray.slice(index, index + 4);
        this.fileNumber = new Int32Array(fileNumberArray.buffer.slice(-4))[0];
        index = index + 4;

        const filePointArray = dataArray.slice(index, index + 4);
        this.filePoint = new Int32Array(filePointArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileReqBytesArray = dataArray.slice(index, index + 4);
        this.fileReqBytes = new Int32Array(fileReqBytesArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileReplyBytesArray = dataArray.slice(index, index + 4);
        this.fileReplyBytes = new Int32Array(fileReplyBytesArray.buffer.slice(-4))[0];
        index = index + 4;

        const fileTotalBytesArray = dataArray.slice(index, index + 4);
        this.fileTotalBytes = new Int32Array(fileTotalBytesArray.buffer.slice(-4))[0];
        index = index + 4;

        const blockIDArray = dataArray.slice(index, index + 4);
        this.blockID = new Int32Array(blockIDArray.buffer.slice(-4))[0];
        index = index + 4;

        const blockLengthArray = dataArray.slice(index, index + 4);
        this.blockLength = new Int32Array(blockLengthArray.buffer.slice(-4))[0];
        index = index + 4;

        const blockNumArray = dataArray.slice(index, index + 4);
        this.blockNum = new Int32Array(blockNumArray.buffer.slice(-4))[0];
        index = index + 4;

        this.length = index - startPos;
    }
}

export default ProtocalHistoryData;