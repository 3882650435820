/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import QRCode from 'qrcode.react';

import {
    Modal, Icon,
    Card, Table, Popover
} from "antd";
import {refreshDevice, toggleDetailModal} from "../../../DAS/actions/DeviceAction";
import {toggleHistoryModal} from "../../actions/ContentAction";
import ConvertUtils from "../../../../utils/ConvertUtils";

class HistoryModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            showQrCode: false
        }
    }

    componentWillMount() {
    }

    _handleCancel() {
        this.props.dispatch(toggleHistoryModal(false, {}));
    }

    render() {
        const {formatMessage} = this.state;
        const {showHistoryModal, basicContent, contentFileMap} = this.props;

        const columns = [
            {
                title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_FILE_VERSION'}),
                dataIndex: 'version',
                key: 'version',
                width: "30%",
                render: (text, record) => {
                    let version = "";
                    const index = record.name && record.name.lastIndexOf(".");
                    if (index && index !== -1 && record.name) {
                        version = record.name.slice(0, index);
                    }
                    return version;
                }
            },
            {
                title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SIZE'}),
                dataIndex: 'size',
                key: 'size',
                width: 150,
                render: (text) => {
                    if (text) {
                        return ConvertUtils.byteToCapacity(text);
                    } else {
                        return null;
                    }
                }
            },
            {
                title: formatMessage({id: 'COMMON_BUTTON_DOWNLOAD'}),
                dataIndex: 'download',
                key: 'download',
                width: 100,
                render: (text, record) => {
                    if (record.path) {
                        return <a href={record.path}>{formatMessage({id: 'COMMON_BUTTON_DOWNLOAD'})}</a>
                    } else {
                        return null;
                    }
                }
            },
            {
                dataIndex: 'QRCode',
                key: 'QRCode',
                width: 100,
                render: (text, record, index) => {
                    console.log(record);
                    if (record.path) {
                        let qrCodeValue = record.path;
                        if (record.name && record.name.indexOf(".apk") > -1) {
                            qrCodeValue = "http://ecgv3.d9ktech.com/APPDownload.html?path=" + record.path;
                        }
                        return <Popover content={
                            <QRCode value={qrCodeValue}/>}
                                        placement={index <= 2 ? "bottom" : "top"}>
                            <a style={{fontSize: 40}}
                               onClick={() => this.setState({showQrCode: true})}>
                                <Icon type="qrcode"/>
                            </a>
                        </Popover>
                    } else {
                        return null;
                    }
                }
            },
            {
                title: formatMessage({id: 'COMMON_FIELD_PUBLISH_DATE'}),
                dataIndex: 'publishTime',
                key: 'publishTime',
                width: 150,
                render: (text, record) => {
                    if (record.createTime) {
                        return ConvertUtils.timeStrToDate(record.createTime);
                    } else {
                        return null;
                    }
                }
            },
            {
                title: formatMessage({id: 'COMMON_FIELD_EXPLANATION'}),
                dataIndex: 'explanation',
                key: 'explanation',
                render: (text, record) => {
                    if (record.remarks) {
                        return record.remarks;
                    } else {
                        return null;
                    }
                }
            },
        ];
        /*if (basicContent.code === "APP"){
            const index = columns.findIndex(item => item.key === "browser");
            if (index >= 0){
                columns.splice(index,1);
            }
        }*/

        return (
            <Modal
                title={basicContent.title}
                visible={showHistoryModal}
                footer={false}
                onCancel={() => this._handleCancel()}
                width={"80%"} style={{top: 20}}
            >
                <Card>
                    <Table rowKey="id"
                           size="small"
                           className="spacing-v"
                           columns={columns}
                           dataSource={contentFileMap[basicContent.id] && contentFileMap[basicContent.id]}
                           scroll={{y: 460}}
                           pagination={true}
                    />
                </Card>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showHistoryModal: store.ContentReducer.showHistoryModal,
        basicContent: store.ContentReducer.basicContent,
    }
}

export default connect(mapStateToProps)(injectIntl(HistoryModal));