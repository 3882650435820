/**
 * Created by Ethan on 2017/9/6.
 */

const en_US = {
    brand: "ECG SUIT",
    copyright: "Copyright ©2017 D9K",

    // 角色
    role: "Role",
    administrator: "Administrator",
    sysmanager: "System Manager",
    manager: "Manager",
    normal: "Normal",

    titleSysUserAdd: "System User Add",
    titleSysUserUpdate: "System User Update",

    // 路由的标题，菜单，路径导航上使用
    DAS_DASHBOARD_ROUTE_STATISTICS: "Home",
    routeDashboard: "Dashboard",
    DAS_DEVICE_ROUTE_MANAGEMENT: "Device",
    DAS_DEVICE_ROUTE_LIST: "Device List",
    routeDeviceDetail: "Device Detail",
    routeDeviceRegister: "Device Register",
    routeDeviceImport: "Device Import",
    routeClothesList: "衣服一览",

    routeEcg: "ECG",
    routeEcgList: "ECG List",
    routeEcgChart: "ECG Chart",

    routeUser: "User",
    routeUserList: "User List",
    routeDoctorList: "Doctor List",

    // 设备注册画面
    deviceCode: "Device Code",
    deviceName: "Device Name",
    deviceBinder: "Bind User",
    productionDate: "Production Date",
    cardNo: "Card No",

    // 设备上传画面
    deviceImport: "Device Import",
    deviceImportTemplate: "Device Import Template Download",

    // 心电图一览
    ecgChart: "ECG Chart",

    menuSysManage: "SYSTEM MANAGE",
    menuSysUserList: "User Role List",

    btnAdd: "Add",
    btnSubmit: "Submit",
    btnCancel: "Cancel",
    btnModify: "Modify",
    btnDelete: "Delete",
    btnOK: "OK",
    btnSearch: "Search",

    lblLoginId: "Login Id",
    lblUsername: "Name",
    lblFamilyName: "Last Name",
    lblGivenName: "Given Name",
    lblEmail: "Email",
    lblMobile: "Mobile",
    lblRole: "Role",
    lblOperation: "Operation",

    login: "Login",
    loginTime: "Login Time",
    forgetPassword: "Forget Password?",
    email: "email",
    username: "Name",
    birthday: "Birthday",
    mobile: "Mobile",
    password: "password",
    welcome: "welcome",
    profile: "My Profile",
    resetPassword: "Reset Password",
    logout: "Logout",
    import: "Import",
    export: "Export",
    downloadTemplate: "Download Template",

    search: "Search",
    advanceSearch: "Advance Search",
    register: "Register",
    registerDevice: "Device Register",
    registerDate: "Register Date",
    importDevice: "Device Import",

    activateDate: "Activate Date",

    deviceDataSum: "ECG Files",
    deviceMacAddress: "Mac Address",
    deviceSyncTime: "Sync Time",
    activateState: "Active State",
    bindState: "Bind State",
    unactivated: "Unactivated",
    activated: "Activated",
    unbound: "Unbound",
    bound: "Bound",
    userType: "User Type",
    user: "User",
    guardian: "Guardian",

    msgDeleteConfirmTitle: "Delete Confirm",
    msgDeleteConfirmContent: "Are you sure delete this item",

    msgPasswordRequired: "password is required.",
    msgAuthFailed: "Login information is wrong, auth failed.",


    // 标注工具
    ECG_ANNOTATION_ROUTE_TOOL: "标注工具",
    ECG_ANNOTATION_TITLE_FILE_SOURCE: "文件数据源",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE: "数据源",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_LOCAL: "D9K_本地",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_D9K_SERVER: "D9K_服务端",
    ECG_ANNOTATION_FIELD_SOURCE_TYPE_MIT: "MIT",
    ECG_ANNOTATION_FIELD_ROOT_FOLDER_SELECT: "选择根目录",
    ECG_ANNOTATION_FIELD_ANNOTATION_FILE: "标注文件",
    ECG_ANNOTATION_FIELD_ECG_FILE: "心电文件",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NORMAL: "normal beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LBBB: "left bundle branch block beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RBBB: "right bundle branch block beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BBB: "left or right bundle branch block",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_APC: "atrial premature contraction",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ABERR: "aberrated atrial premature beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NPC: "nodal (junctional) premature beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVPB: "premature or ectopic supraventricular beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PVC: "premature ventricular contraction",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RONT: "R-on-T premature ventricular contraction",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FUSION: "fusion of ventricular and normal beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AESC: "atrial escape beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NESC: "nodal (junctional) escape beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVESC: "supraventricular escape beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VESC: "ventricular escape beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACE: "paced beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PFUS: "fusion of paced and normal beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UNKNOWN: "unclassifiable beat",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LEARN: "learning",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFON: "start of ventricular flutter/fibrillation",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_FLWAV: "ventricular flutter wave",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFOFF: "end of ventricular flutter/fibrillation",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NAPC: "non-conducted P-wave (blocked APB)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFON: "waveform onset",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_WFOFF: "waveform end",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PWAVE: "P-wave peak",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TWAVE: "T-wave peak",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_UWAVE: "U-wave peak",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PQ: "PQ junction (beginning of QRS)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_JPT: "J point (end of QRS)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PACESP: "non-conducted pacer spike",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_ARFCT: "isolated QRS-like artifact",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOISE: "signal quality change",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_RHYTHM: "rhythm change",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_STCH: "ST change",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_TCH: "T-wave change",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SYSTOLE: "systole",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_DIASTOLE: "diastole",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_MEASURE: "measurement annotation",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOTE: "comment annotation",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_LINK: "link to external data (aux contains URL)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AB: "Atrial bigeminy",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFIB: "Atrial fibrillation",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_AFL: "Atrial flutter",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_B: "Ventricular bigeminy",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_BII: "2° heart block",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_IVR: "Idioventricular rhythm",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_N: "Normal sinus rhythm",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_NOD: "Nodal (A-V junctional) rhythm",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_P: "Paced rhythm",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_PREX: "Pre-excitation (WPW)",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SBR: "Sinus bradycardia",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_SVTA: "Supraventricular tachyarrhythmia",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_T: "Ventricular trigeminy",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VFL: "Ventricular flutter",
    ECG_ANNOTATION_FIELD_ANNOTATION_LABEL_VT: "Ventricular tachycardia",
};
export default en_US;