export const OrganizationTypes = [
    {value: "01", label: "ORG_ORGANIZATION_FIELD_TYPE_COMPANY"},
    {value: "02", label: "ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT"},
    {value: "03", label: "ORG_ORGANIZATION_FIELD_TYPE_TEAM"},
    // {value: "04", label: "ORG_ORGANIZATION_FIELD_TYPE_COMPANY"},
    // {value: "05", label: "ORG_ORGANIZATION_FIELD_TYPE_REPAIR_UNIT"},
    // {value: "06", label: "ORG_ORGANIZATION_FIELD_TYPE_EMPLOYEE"},
];

export const OrganizationTypesMap = {
    "01": "ORG_ORGANIZATION_FIELD_TYPE_COMPANY",
    "02": "ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT",
    "03": "ORG_ORGANIZATION_FIELD_TYPE_TEAM",
    // "04": "ORG_ORGANIZATION_FIELD_TYPE_COMPANY",
    // "05": "ORG_ORGANIZATION_FIELD_TYPE_REPAIR_UNIT",
    // "06": "ORG_ORGANIZATION_FIELD_TYPE_EMPLOYEE"
};
