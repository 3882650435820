import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber, TreeSelect, Upload, Button} from "antd";
import message from "../../../../components/toast";

import * as FTPWebService from "../../../../modules/FTP/WebService";

import {
    CACHE_ACCESS_TOKEN,
    CACHE_SITE_CODE, CACHE_SITE_ID
} from "../../../../constants/Profile";

import {HttpStatus} from "../../../../constants/Enums";

import {toggleDetailModal, refreshChannel, createChannel, updateChannel} from '../../actions/ChannelAction';

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(channel) {
        let messageMap = {};
        if (channel.code === undefined || channel.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'CMS_CHANNEL_MSG_CODE_REQUIRED'});
        }
        if (channel.title === undefined || channel.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'CMS_CHANNEL_MSG_TITLE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {channel, searchCondition} = this.props;
        if (this._validate(channel)) {
            const siteId = localStorage.getItem(CACHE_SITE_ID);
            if (channel.id === undefined || channel.id === "") {
                this.props.dispatch(createChannel(siteId, channel, searchCondition, this.state));
            } else {
                this.props.dispatch(updateChannel(siteId, channel, searchCondition, this.state));
            }

        }
    }

    _handleChange(name, value) {

        const channel = Object.assign({}, this.props.channel);
        channel[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshChannel(channel));
    }

    render() {
        const {channelTree, dispatch, channel} = this.props;
        const formatMessage = this.state.formatMessage;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const self = this;
        const uploadProps = {
            name: 'file',
            multiple: false,
            action: FTPWebService.uploadWebFile + "?folder=" + siteCode + "%2Fchannels",
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done' && info.file.response.status === HttpStatus.SUCCESS) {
                    const channel = Object.assign({}, self.props.channel);
                    channel["titleImage"] = info.file.response.data;
                    dispatch(refreshChannel(channel));
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        let title = formatMessage({id: 'CMS_CHANNEL_TITLE_CREATE'});
        if (channel.id !== undefined && channel.id !== "") {
            title = formatMessage({id: 'CMS_CHANNEL_TITLE_UPDATE'});
        }

        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_PARENT'})} {...formItemLayout}>
                        <TreeSelect
                            showSearch
                            value={this.props.channel.parentId}
                            allowClear
                            treeDefaultExpandAll
                            onChange={(data) => {
                                this._handleChange("parentId", data)
                            }}>
                            {
                                channelTree.map((item, i) => {
                                    return (
                                        <TreeSelect.TreeNode value={item.id} title={item.title} key={item.id}>
                                            {this._renderChannelTreeNode(item, i)}
                                        </TreeSelect.TreeNode>
                                    )
                                })
                            }
                        </TreeSelect>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_CODE'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={this.props.channel.code}
                               onChange={(data) => {
                                   this._handleChange("code", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_TITLE'})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input value={this.props.channel.title}
                               onChange={(data) => {
                                   this._handleChange("title", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_TITLE_IMAGE'})} {...formItemLayout}>
                        <Input value={this.props.channel.titleImage}
                               onChange={(data) => {
                                   this._handleChange("titleImage", data)
                               }} addonAfter={<Upload {...uploadProps} showUploadList={false}>
                            <Button size="small">
                                <FormattedMessage id="COMMON_BUTTON_UPLOAD"/>
                            </Button>
                        </Upload>}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'CMS_CHANNEL_FIELD_TITLE_IMAGE_PREVIEW'})}  {...formItemLayout}>
                        <img src={this.props.channel.titleImage} style={{height: '50px'}}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_SEQUENCE'})} {...formItemLayout}>
                        <InputNumber value={this.props.channel.sequence}
                                     onChange={(data) => {
                                         this._handleChange("sequence", data)
                                     }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CHANNEL_FIELD_REMARKS'})} {...formItemLayout}>
                        <Input.TextArea rows={4} value={this.props.channel.remarks}
                                        onChange={(data) => {
                                            this._handleChange("remarks", data)
                                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }

    _renderChannelTreeNode(channel, index) {
        if (channel.children && channel.children.length > 0) {
            return (
                channel.children.map((item, i) => {
                    return (
                        <TreeSelect.TreeNode value={item.id} title={item.title} key={item.id}>
                            {this._renderChannelTreeNode(item, index + "-" + i)}
                        </TreeSelect.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        channel: store.ChannelReducer.channel,
        channelTree: store.ChannelReducer.channelTree,
        showDetailModal: store.ChannelReducer.showDetailModal,
        searchCondition: store.ChannelReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));