// 2)	CompMethod==1/2情况下（即使用压缩）Data的排列方式为
// 序	名称	字节数	数据类型	说明
// 1	CodeLenInBits	4B	UInt32	Data的比特数
// 2	CodeLenInBytes	4B	UInt32	Data的字节数
// 3	CompRate	4B	UInt32	压缩率，单位0.1%，便于性能统计
// 4	Para1	4B	UInt32	参数1，有损压缩中的Pencent参数，目前为固定值50，即表示50%
// 5	Para2	4B	UInt32	参数2，有损压缩中的TargetBits参数，目前为固定值8，即表示8bit
// 6	Para3	4B	UInt32	其他，保留未用
// 7	Reserved1	4B	UInt32	其他，保留未用
// 8	Reserved2	4B	UInt32	其他，保留未用
// 9	Data	CodeLenInBytes	UInt8	CodeLenInBytes = DataLen – 32

class EcgFileCompressData {
    constructor(dataArray, startPos, dataLength) {
        let index = startPos;

        // Data的比特数
        const codeLenInBitsArray = dataArray.slice(index, index + 4);
        this.codeLenInBits = new Int32Array(codeLenInBitsArray.buffer.slice(-4))[0];
        // console.log("Data的比特数:" + this.codeLenInBits);
        index += 4;

        // Data的字节数
        const codeLenInBytesArray = dataArray.slice(index, index + 4);
        this.codeLenInBytes = new Int32Array(codeLenInBytesArray.buffer.slice(-4))[0];
        // console.log("Data的字节数:" + this.codeLenInBytes);
        index += 4;

        // 压缩率，单位0.1%，便于性能统计
        const compRateArray = dataArray.slice(index, index + 4);
        this.compRate = new Int32Array(compRateArray.buffer.slice(-4))[0];
        // console.log("压缩率，单位0.1%，便于性能统计:" + this.compRate);
        index += 4;

        // 参数1，有损压缩中的Pencent参数，目前为固定值50，即表示50%
        const para1Array = dataArray.slice(index, index + 4);
        this.para1 = new Int32Array(para1Array.buffer.slice(-4))[0];
        // console.log("有损压缩中的Pencent参数，目前为固定值50，即表示50%:" + this.para1);
        index += 4;

        // 参数2，有损压缩中的TargetBits参数，目前为固定值8，即表示8bit
        const para2Array = dataArray.slice(index, index + 4);
        this.para2 = new Int32Array(para2Array.buffer.slice(-4))[0];
        // console.log("有损压缩中的TargetBits参数，目前为固定值8，即表示8bit:" + this.para2);
        index += 4;

        // 其他，保留未用
        const para3Array = dataArray.slice(index, index + 4);
        this.para3 = new Int32Array(para3Array.buffer.slice(-4))[0];
        // console.log(this.para3);
        index += 4;

        // 其他，保留未用
        const reserved1Array = dataArray.slice(index, index + 4);
        this.reserved1 = new Int32Array(reserved1Array.buffer.slice(-4))[0];
        // console.log(this.reserved1);
        index += 4;

        // 其他，保留未用
        const reserved2Array = dataArray.slice(index, index + 4);
        this.reserved2 = new Int32Array(reserved2Array.buffer.slice(-4))[0];
        // console.log(this.reserved2);
        index += 4;

        // CodeLenInBytes = DataLen – 32
        this.data = dataArray.slice(index, index + dataLength - 32);
        // console.log(this.data);
    }
}

export default EcgFileCompressData;