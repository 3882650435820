/**
 * Created by Ethan on 17/1/13.
 */

import {ACCOUNT_SERVER_HOST} from '../../constants/Profile';

// 用户登录获取token
export const createToken = ACCOUNT_SERVER_HOST + "/openAPI/account/auth/token";
export const deleteToken = ACCOUNT_SERVER_HOST + "/account/auth/token";
export const searchUsers = ACCOUNT_SERVER_HOST + "/account/users/queries";
export const getUser = ACCOUNT_SERVER_HOST + "/account/users/{id}";
export const createUser = ACCOUNT_SERVER_HOST + "/account/users";
export const registerUser = ACCOUNT_SERVER_HOST + "/openAPI/account/users";
export const updateUser = ACCOUNT_SERVER_HOST + "/account/users/{id}";
export const deleteUser = ACCOUNT_SERVER_HOST + "/account/users/{id}";
export const getSessionUser = ACCOUNT_SERVER_HOST + "/account/auth/session";
export const updateSessionUser = ACCOUNT_SERVER_HOST + "/account/user/profile";
export const updateSessionUserPassword = ACCOUNT_SERVER_HOST + "/account/user/password";
export const resetPassword = ACCOUNT_SERVER_HOST + "/openAPI/account/user/password";

// 用户角色
export const getAllRoles = ACCOUNT_SERVER_HOST + "/account/roles";

// 测试环境
// export const ACCOUNT_SERVER_HOST = "http://106.14.160.126:8080";

// 共通
export const uploadFile = ACCOUNT_SERVER_HOST + "/common/oss/file";

