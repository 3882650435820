/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {getProvincialsByCountryId, refreshProvincialMap} from "./ProvincialAction";
import {refreshCityMap} from "./CityAction";
import {refreshCountyMap} from "./CountyAction";
import {refreshTownMap} from "./TownAction";
import {refreshVillageMap} from "./VillageAction";

export function refreshCountry(country) {
    return {
        type: ActionTypes.DISTRICT_COUNTRY_REFRESH_COUNTRY,
        country: country
    }
}

export function refreshCountryTreeExpandKeys(expandKeys) {
    return {
        type: ActionTypes.DISTRICT_COUNTRY_REFRESH_COUNTRY_TREE_EXPAND_KEYS,
        countryTreeExpandKeys: expandKeys
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_COUNTRY_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getCountryTree(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: true});

        HttpUtils.get(WebService.getAllCountries, (response) => {

            const countries = response.data;
            const expandKeys = [];
            const provincialMap = {};
            for (let i = 0; i < countries.length; i++) {
                const country = countries[i];
                expandKeys.push(country.id);
                const requestURL = WebService.getProvincialsByCountryId.replace("{id}", country.id);
                HttpUtils.get(requestURL, (response) => {
                    country.children = response.data;

                    for (let provincial of country.children) {
                        provincialMap[provincial.id] = provincial;
                    }

                    dispatch({
                        type: ActionTypes.DISTRICT_COUNTRY_TREE,
                        countryTree: countries,
                        countryTreeExpandKeys: expandKeys
                    });

                    dispatch(refreshProvincialMap(provincialMap));

                }, (response) => {
                }, formatMessage)
            }

            dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function refreshCountryTreeBySelectProvincial(countryTree, provincialId, countryTreeExpandKeys, cityMap, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.getCitiesByProvincialId.replace("{id}", provincialId);
        HttpUtils.get(requestURL, (response) => {

            for (let i = 0; i < countryTree.length; i++) {
                const country = countryTree[i];
                for (let provincial of country.children) {
                    if (provincial.id === provincialId) {
                        provincial.children = response.data;

                        for (let city of provincial.children) {
                            cityMap[city.id] = city;
                        }
                        break;
                    }
                }
            }

            countryTreeExpandKeys.push(provincialId);
            dispatch(refreshCityMap(cityMap));
            dispatch({
                type: ActionTypes.DISTRICT_COUNTRY_TREE,
                countryTree: countryTree,
                countryTreeExpandKeys: countryTreeExpandKeys
            });

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function refreshCountryTreeBySelectCity(countryTree, provincialId, cityId, countryTreeExpandKeys, countyMap, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.getCountiesByCityId.replace("{id}", cityId);
        HttpUtils.get(requestURL, (response) => {

            for (let i = 0; i < countryTree.length; i++) {
                const country = countryTree[i];
                for (let provincial of country.children) {
                    if (provincial.id === provincialId) {
                        for (let city of provincial.children) {
                            if (city.id === cityId) {
                                city.children = response.data;

                                for (let county of city.children) {
                                    countyMap[county.id] = county;
                                }
                                break;
                            }
                        }
                    }
                }
            }

            countryTreeExpandKeys.push(cityId);
            dispatch(refreshCountyMap(countyMap));

            dispatch({
                type: ActionTypes.DISTRICT_COUNTRY_TREE,
                countryTree: countryTree,
                countryTreeExpandKeys: countryTreeExpandKeys
            });

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function refreshCountryTreeBySelectCounty(countryTree, provincialId, cityId, countyId, countryTreeExpandKeys, townMap, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.getTownsByCountyId.replace("{id}", countyId);
        HttpUtils.get(requestURL, (response) => {

            for (let i = 0; i < countryTree.length; i++) {
                const country = countryTree[i];
                for (let provincial of country.children) {
                    if (provincial.id === provincialId) {
                        for (let city of provincial.children) {
                            if (city.id === cityId) {
                                for (let county of city.children) {
                                    if (county.id === countyId) {
                                        county.children = response.data;

                                        for (let town of county.children) {
                                            townMap[town.id] = town;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            countryTreeExpandKeys.push(countyId);
            dispatch(refreshTownMap(townMap));
            dispatch({
                type: ActionTypes.DISTRICT_COUNTRY_TREE,
                countryTree: countryTree,
                countryTreeExpandKeys: countryTreeExpandKeys
            });

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function refreshCountryTreeBySelectTown(countryTree, provincialId, cityId, countyId, townId, countryTreeExpandKeys, villageMap, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.getVillagesByTownId.replace("{id}", townId);
        HttpUtils.get(requestURL, (response) => {

            for (let i = 0; i < countryTree.length; i++) {
                const country = countryTree[i];
                for (let provincial of country.children) {
                    if (provincial.id === provincialId) {
                        for (let city of provincial.children) {
                            if (city.id === cityId) {
                                for (let county of city.children) {
                                    if (county.id === countyId) {
                                        for (let town of county.children) {
                                            if (town.id === townId) {
                                                town.children = response.data;

                                                for (let village of town.children) {
                                                    villageMap[village.id] = village;
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            countryTreeExpandKeys.push(townId);
            dispatch(refreshVillageMap(villageMap));

            dispatch({
                type: ActionTypes.DISTRICT_COUNTRY_TREE,
                countryTree: countryTree,
                countryTreeExpandKeys: countryTreeExpandKeys
            });

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getCountry(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: true});
        const requestURL = WebService.getCountryById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshCountry(response.data));
            dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createCountry(country, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: true});

        HttpUtils.post(WebService.createCountry, country, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCountry({}));
            dispatch(getCountryTree(formatMessage));
            dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateCountry(country, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: true});

        const requestURL = WebService.updateCountryById.replace("{id}", country.id);
        HttpUtils.put(requestURL, country, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCountry({}));
            dispatch(getCountryTree(formatMessage));

            dispatch({type: ActionTypes.DISTRICT_COUNTRY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteCountry(country, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByCountryId.replace("{id}", country.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getCountryTree(formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



