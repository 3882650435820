/**
 * Created by Ethan on 2017/9/8.
 */

import * as ActionTypes from '../constants/ActionTypes';
import {APP_WARN_COUNT} from "../constants/ActionTypes";

const initialState = {
    isCreateSuccess: false,
    isSearchSuccess: false,
    isUpdateSuccess: false,
    isDeleteSuccess: false,
    isValidateFailed: false,
    isLoading: false,

    messageMap: {},

    message: '',
    returnCode: '',
    pagination: {},

    currentLanguage: '',
    menuItems: [],
    menuItemMap: {},

    pathname: "",

    warnCnt: 0,
    isWarn: true,
    showWarnRecordModal: false,
    warnRecord:[],
    setDefaultActiveKey:'',
    searchCondition:{},
    record:[],
    isWarnLoading:false,
}

const AppReducer = (state = initialState, action = 'none')=> {

    switch (action.type) {
        case ActionTypes.APP_RESET_FLAG:
            return Object.assign({}, state, {
                isCreateSuccess: false,
                isSearchSuccess: false,
                isUpdateSuccess: false,
                isDeleteSuccess: false,
                isValidateFailed: false,
                isLoading : false,
            });
        case ActionTypes.APP_REFRESH_PATHNAME:
            return Object.assign({}, state, {
                pathname : action.pathname
            });
        case ActionTypes.APP_FORM_VALIDATE:
            return Object.assign({}, state, {
                isValidateFailed: action.isValidateFailed,
                messageMap : action.messageMap
            });
        case ActionTypes.APP_SET_CREATE_FLAG:
            return Object.assign({}, state, {
                isCreateSuccess: action.isCreateSuccess
            });
        case ActionTypes.APP_SET_SEARCH_FLAG:
            return Object.assign({}, state, {
                isSearchSuccess: action.isSearchSuccess
            });
        case ActionTypes.APP_SET_UPDATE_FLAG:
            return Object.assign({}, state, {
                isUpdateSuccess: action.isUpdateSuccess
            });
        case ActionTypes.APP_SET_DELETE_FLAG:
            return Object.assign({}, state, {
                isDeleteSuccess: action.isDeleteSuccess
            });
        case ActionTypes.APP_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.APP_CLEAR_MESSAGE:
            return Object.assign({}, state, {
                message: '',
                messageMap: {}
            });
        case ActionTypes.APP_SHOW_MESSAGE:
            return Object.assign({}, state, {
                message: action.message,
                returnCode: action.returnCode
            });
        case ActionTypes.APP_PAGINATION:
            return Object.assign({}, state, {
                pagination: action.pagination
            })
        case ActionTypes.APP_GET_DEFAULT_LANGUAGE:
            return Object.assign({}, state, {
                currentLanguage: action.currentLanguage
            });
        case ActionTypes.APP_CHANGE_LANGUAGE:
            return Object.assign({}, state, {
                currentLanguage: action.currentLanguage
            });
        case ActionTypes.APP_GET_MAIN_MENU:
            return Object.assign({}, state, {
                menuItems: action.menuItems,
                menuItemMap: action.menuItemMap
            });
        case ActionTypes.APP_WARN_COUNT:
            return Object.assign({}, state, {
                warnCnt: action.warnCnt
            });
        case ActionTypes.APP_REFRESH_WARN_FLAG:
            return Object.assign({}, state, {
                isWarn: action.isWarn
            });
        case ActionTypes.APP_REFRESH_SHOW_WARN_RECORD_MODAL:
            return Object.assign({}, state, {
                showWarnRecordModal: action.showWarnRecordModal
            });
        case ActionTypes.APP_REFRESH_WARN_RECORD:
            return Object.assign({}, state, {
                warnRecord: action.warnRecord
            });
        case ActionTypes.APP_REFRESH_ACTIVITY_KEY:
            return Object.assign({}, state, {
                setDefaultActiveKey: action.setDefaultActiveKey
            });
        case ActionTypes.APP_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.APP_REFRESH_RECORD:
            return Object.assign({}, state, {
                record: action.record
            });
        case ActionTypes.APP_WARN_LOADING:
            return Object.assign({}, state, {
                isWarnLoading: action.isWarnLoading
            });
        default:
            return state
    }
};

export default AppReducer;