// 字段	长度（字节）	例值（16进制）	说明
// ProtocalVersion	1	64	V1.00
// SourceType	1	02	00：设备
// 01：手机APP
// 02：服务器
// 03：WEB客户端
// DestType	1	03	同上
// SourceID	4	FF FF FF FF	除了设备以外统一ID为
// FF FF FF FF
// PacketLength	4	2F 00 00 00	47字节包
// PacketType	1	42	实时数据B包
// DownsamplingRatio	1	05	下采样速率，即抽样采样的速率，1-500Hz，2-250Hz，5-100Hz…
// N7	2	0E 00	ECGData中的每个导联的心电数据个数。
// LiveId	4		Live的计数，每次都从0开始递增，每个包+1
// Date	7		当前时间,同currenttime
// 0x3B,3B,17,1E,0B,D007(59秒，59分，23点，31日，12月，2000年)
// ECGData	8*4*N7		1组8通道实时数据（未压缩）

class ChartPoint {
    constructor() {
        //x值
        this.x = "";
        //y值
        this.y = "";
        //颜色
        this.color = "";
    }
}

export default ChartPoint;