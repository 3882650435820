/**
 * Created by Ethan on 2022/7/15.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import {getAllDevices, getDeviceById, toggleDetailModal} from "../../../../actions/DeviceAction";
import PageTitle from "../../../../../../components/PageTitle";
import DeviceModalLogTab from "./DeviceModalLogTab";
import {Select} from "antd";

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            height:"100%",
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllDevices(this.state.formatMessage));
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        this.setState({deviceCode: value.target ? value.target.value : value});
    }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <div>
                <PageTitle title="ECG_DATA_SERVER_DOWNLOAD"/>
                <div style={{marginLeft:"11px"}}>
                    设备编号：
                    <Select style={{width: '150px'}}
                            optionFilterProp="children"
                            showSearch
                            placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}
                            value={this.state.deviceCode}
                            onChange={(value) => {
                                this._handleChange("code", value)
                            }}>
                        {
                            this.props.allDevices.map((item, index) => {
                                return <Select.Option key={index}
                                                      value={item.code}>{item.code}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <DeviceModalLogTab deviceCode={this.state.deviceCode}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        allDevices: store.EcgDeviceReducer.allDevices
    }
}

export default connect(mapStateToProps)(injectIntl(Index));