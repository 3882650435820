import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    showCreateModal: false,
    showUpdateModal: false,
    sites: [],
    site: {},
};

const SiteReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.CMS_SITE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.CMS_SITE_TOGGLE_CREATE_MODAL:
            return Object.assign({}, state, {
                showCreateModal: action.showCreateModal
            });
        case ActionTypes.CMS_SITE_TOGGLE_UPDATE_MODAL:
            return Object.assign({}, state, {
                showUpdateModal: action.showUpdateModal
            });
        case ActionTypes.CMS_SITE_LIST:
            return Object.assign({}, state, {
                sites: action.sites
            });
        case ActionTypes.CMS_SITE_REFRESH_SITE:
            return Object.assign({}, state, {
                site: action.site
            });
        default:
            return state
    }
};

export default SiteReducer;