import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
// require('echarts/map/js/china.js');

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }
  timeTicket = null;
  getInitialState = () => ({option: this.getOption()});

  componentDidMount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
    this.timeTicket = setInterval(() => {
      const option = this.state.option;
      const r = new Date().getSeconds();
      // option.title.text = 'iphone销量' + r;
      // option.series[0].name = 'iphone销量' + r;
      // option.legend.data[0] = 'iphone销量' + r;
      this.setState({ option: option });
    }, 1000);
  };
  componentWillUnmount() {
    if (this.timeTicket) {
      clearInterval(this.timeTicket);
    }
  };
  randomData() {
    return Math.round(Math.random()*1000);
  };
  getOption = () => {
    return {
      title: {
        text: '心电衣设备使用分布',
        subtext: '',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      // legend: {
      //   orient: 'vertical',
      //   left: 'left',
      //   data:['iphone3','iphone4','iphone5']
      // },
      visualMap: {
        min: 0,
        max: 2500,
        left: 'left',
        top: 'bottom',
        text: ['高','低'],       // 文本，默认为数值文本
        calculable: true
      },
      // toolbox: {
      //   show: true,
      //   orient: 'vertical',
      //   left: 'right',
      //   top: 'center',
      //   feature: {
      //     dataView: {readOnly: false},
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      series: [
        {
          name: '心电衣',
          type: 'map',
          mapType: 'china',
          roam: false,
          label: {
            normal: {
              show: true
            },
            emphasis: {
              show: true
            }
          },
          data:[
            {name: '北京',value: this.randomData(), 'children':[
                    {'name':'北京', 'log':'116.46', 'lat':'39.92'},
                    {'name':'平谷', 'log':'117.1', 'lat':'40.13'},
                    {'name':'密云', 'log':'116.85', 'lat':'40.37'},
                    {'name':'顺义', 'log':'116.65', 'lat':'40.13'},
                    {'name':'通县', 'log':'116.67', 'lat':'39.92'},
                    {'name':'怀柔', 'log':'116.62', 'lat':'40.32'},
                    {'name':'大兴', 'log':'116.33', 'lat':'39.73'},
                    {'name':'房山', 'log':'115.98', 'lat':'39.72'},
                    {'name':'延庆', 'log':'115.97', 'lat':'40.47'},
                    {'name':'昌平', 'log':'116.2', 'lat':'40.22'}
                ] },
            {name: '天津',value: this.randomData() },
            {name: '上海',value: this.randomData() },
            {name: '重庆',value: this.randomData() },
            {name: '河北',value: this.randomData() },
            {name: '河南',value: this.randomData() },
            {name: '云南',value: this.randomData() },
            {name: '辽宁',value: this.randomData() },
            {name: '黑龙江',value: this.randomData() },
            {name: '湖南',value: this.randomData() },
            {name: '安徽',value: this.randomData() },
            {name: '山东',value: this.randomData() },
            {name: '新疆',value: this.randomData() },
            {name: '江苏',value: this.randomData() },
            {name: '浙江',value: this.randomData() },
            {name: '江西',value: this.randomData() },
            {name: '湖北',value: this.randomData() },
            {name: '广西',value: this.randomData() },
            {name: '甘肃',value: this.randomData() },
            {name: '山西',value: this.randomData() },
            {name: '内蒙古',value: this.randomData() },
            {name: '陕西',value: this.randomData() },
            {name: '吉林',value: this.randomData() },
            {name: '福建',value: this.randomData() },
            {name: '贵州',value: this.randomData() },
            {name: '广东',value: this.randomData() },
            {name: '青海',value: this.randomData() },
            {name: '西藏',value: this.randomData() },
            {name: '四川',value: this.randomData() },
            {name: '宁夏',value: this.randomData() },
            {name: '海南',value: this.randomData() },
            {name: '台湾',value: this.randomData() },
            {name: '香港',value: this.randomData() },
            {name: '澳门',value: this.randomData() }
          ]
        }
      ]
    };
  };
  render() {
    // const code = "require('echarts/map/js/china.js'); \n" +
    //       "<ReactEcharts \n" +
    //       "  option={this.state.option || {}} \n" +
    //       "  style={{height: '350px', width: '100%'}}  \n" +
    //       "  className='react_for_echarts' />";
    return (
      <div className='examples'>
        <div className='parent'>
          <ReactEcharts
            option={this.state.option}
            style={{height: '500px', width: '100%'}}
            className='react_for_echarts' />
        </div>
      </div>
    );
  };
}
