
export const FORMAT_DATE_TIME_SIMPLE = "YYYYMMDDHHmmss";
export const FORMAT_DATE_TIME_HYPHEN = "YYYY-MM-DD HH:mm:ss";
export const FORMAT_DATE_TIME_SLASH = "YYYY/MM/DD HH:mm:ss";
export const FORMAT_DATE_TIME_SLASH_NO_SECOND = "YYYY/MM/DD HH:mm";

export const FORMAT_DATE_TIME_FULL_SIMPLE = "YYYYMMDDHHmmssSSS";
export const FORMAT_DATE_TIME_FULL_HYPHEN = "YYYY-MM-DD HH:mm:ss:SSS";
export const FORMAT_DATE_TIME_FULL_SLASH = "YYYY/MM/DD HH:mm:ss:SSS";

export const FORMAT_DATE_SIMPLE = "YYYYMMDD";
export const FORMAT_DATE_HYPHEN = "YYYY-MM-DD";
export const FORMAT_DATE_SLASH = "YYYY/MM/DD";
export const FORMAT_DATE_SLASH_EN = "MM/DD/YYYY";

export const FORMAT_DATE_YEAR_MONTH_SIMPLE = "YYYYMM";
export const FORMAT_DATE_YEAR_MONTH_HYPHEN = "YYYY-MM";
export const FORMAT_DATE_YEAR_MONTH_SLASH = "YYYY/MM";
export const FORMAT_DATE_YEAR_MONTH_ZH = "YYYY年MM月";
export const FORMAT_DATE_MONTH_DAY_ZH = "MM月DD日";
export const FORMAT_TIME_SIMPLE = "HHmmss";
export const FORMAT_TIME_COLON = "HH:mm:ss";
export const FORMAT_MONTY_SLASH = "MM/DD";
