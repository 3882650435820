/**
 * Created by Ethan on 2017/11/7.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from "react-intl";

//canvas用到的全局变量
var context;
var canvas

var AXIS_ORIGIN
//坐标的顶部
var AXIS_TOP
//坐标的长度
var AXIS_RIGHT
//小标记的间隔
var HORIZONTAL_TICK_SPACING = 10
var VERTICAL_TICK_SPACING = 10;
//坐标标记的范围
var AXIS_WIDTH
var AXIS_HEIGHT
//横向标记数值
var NUM_HORIZONTAL_TICKS
var TICK_WIDTH = 10;
//标牌和坐标轴之间的距离
var SPACE_BETWEEN_ABELS_AND_AXIS = 20;
var winW
//水平标尺与canvas的距离
var HORIZONTAL_AXIS_MARGIN = 50;
//竖直标尺与canvas的距离
var VERTICAL_AXIS_MARGIN = 50;

//用户输入数值
var INPUT_NUM = 0;
var INPUT_NUM2 = 0;
var INPUT_NUM_TWO = INPUT_NUM;
var INPUT_NUM_TWO2 = INPUT_NUM2;
//最低值
var NUM_VERTICAL_MIN = [];
//
var NUM_VERTICAL_MIN_TWO = [];
//记录初始值
var NUM_VERTICAL_TICKS_TWO = [];
//纵向标记数值
var NUM_VERTICAL_TICKS = [];

//彩虹的RGB
var RGB = ['rgb(255,0,0)', 'rgb(255,165,0)', 'rgb(255,255,0)', 'rgb(0,255,0)', 'rgb(0,255,255)', 'rgb(0,0,255)', 'rgb(139,0,255)']


var count = [];
//数据源
var dataSource = [];
//数据名称
var dataSourceName = [];
//线的粗细
var lineThickness = [];
//最低值
var verticalMin;
//差值
var scale;
//线的颜色
var lineColor
//每条y的0点
var YStart
//y的点数总量
var yLength
//屏幕宽度
var winW

var eventTimeStamp;

//判断是否是第一次初始化
var one = true;

var arr = [];

//代表第几条 竖直线
var who = -1;
var whoFlag = false;
var vFlag = false;

//计时
var jishi = 0;


class HCanvas2 extends Component {


    constructor(props) {
        super(props);
        this.state = {
            who: -1,
            whoFlag: false
        }
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (this.discardData != null || this.discardData != undefined) {
            one = true
            clearInterval(this.discardData)
        }
        this.discardData = setInterval(() => {
            jishi++;
            _init(nextProps.data)
        }, 25)

        setInterval(() => {
            console.log("实际刷新频率为："+jishi)
            jishi = 0;
        }, 1000)
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.discardData)
    }


    render() {
        return (
            <div>
                <canvas id="myCanvas" width={this.props.data[7]} height={this.props.data[8]}></canvas>
            </div>
        );
    }
}

function _init(data) {
    // window.onload = function () {
    canvas = document.getElementById("myCanvas");
    context = canvas.getContext('2d');

    //标尺起点
    AXIS_ORIGIN = {
        x: HORIZONTAL_AXIS_MARGIN,
        y: canvas.height - VERTICAL_AXIS_MARGIN
    };
    //坐标的顶部
    AXIS_TOP = VERTICAL_AXIS_MARGIN;
    //坐标的长度
    AXIS_RIGHT = canvas.width - HORIZONTAL_AXIS_MARGIN;
    //坐标标记的范围
    AXIS_WIDTH = AXIS_RIGHT - AXIS_ORIGIN.x;
    AXIS_HEIGHT = AXIS_ORIGIN.y - AXIS_TOP;

    //横向标记数值
    NUM_HORIZONTAL_TICKS = AXIS_WIDTH / HORIZONTAL_TICK_SPACING;

    //所有一切初始化的地方
    function init(data) {
        //初始化数据源
        initDataSource(data);
        //初始化矩形
        initArr();
        draw()
    }

    function initArr() {
        //因为减去一部分方便捕捉
        var x = VERTICAL_AXIS_MARGIN - 25;
        var y = VERTICAL_AXIS_MARGIN;
        var width = VERTICAL_AXIS_MARGIN;
        var height = canvas.height - VERTICAL_AXIS_MARGIN * 2;
        arr = []

        for (var i = 0; i < dataSource.length; i++) {
            var Arr = {x, y, width, height};
            Arr.x = x + i * VERTICAL_AXIS_MARGIN;
            Arr.y = y;
            Arr.width = width;
            Arr.height = height;
            arr.push(Arr)
        }
    }

    function initDataSource(data) {
        //第一次初始化
        if (one) {
            dataSource = data[0];
            yLength = dataSource[0].length
            verticalMin = data[3];
            scale = data[4];
            NUM_VERTICAL_TICKS = []
            NUM_VERTICAL_TICKS_TWO = []
            count = []
            NUM_VERTICAL_MIN = []
            NUM_VERTICAL_MIN_TWO = []

            for (var i = scale.length; i < dataSource.length; i++) {
                scale.push(1)
            }
            for (var i = 0; i < dataSource.length; i++) {
                NUM_VERTICAL_TICKS.push(AXIS_HEIGHT / VERTICAL_TICK_SPACING * scale[i]);
                NUM_VERTICAL_TICKS_TWO.push(AXIS_HEIGHT / VERTICAL_TICK_SPACING * scale[i]);
                count.push(0);
                if (verticalMin[i] != undefined && verticalMin != null) {
                    NUM_VERTICAL_MIN.push(verticalMin[i]);
                    NUM_VERTICAL_MIN_TWO.push(verticalMin[i])
                } else {
                    NUM_VERTICAL_MIN.push(0);
                    NUM_VERTICAL_MIN_TWO.push(0)
                }
            }
            dataSourceName = data[1];
            for (var i = dataSourceName.length; i < dataSource.length; i++) {
                dataSourceName.push("dataName")
            }
            lineThickness = data[2];
            lineColor = data[5];
            YStart = data[6]
            one = false;
            for (var i = YStart.length; i < dataSource.length; i++) {
                YStart.push(YStart[0]);
            }
            //改变数据
        } else {
            dataSource = data[0];
            yLength = dataSource[0].length
            dataSourceName = data[1];
            lineThickness = data[2];
            // scale = data[4];

            lineColor = data[5];
            YStart = data[6]
            for (var i = scale.length; i <= dataSource.length; i++) {
                scale.push(1)
            }

            let max = 0;
            let min = 0;
            let piancha = 0;

            for (var x = 0; x < dataSource.length; x++) {
                max = 0;
                min = 0;
                for (var i = 0; i < dataSource[x].length; i++) {
                    max = max > dataSource[x][i] ? max : dataSource[x][i];
                    min = min > dataSource[x][i] ? dataSource[x][i] : min;
                }
                // piancha = max > 70 ? max - 70 : 0;
                // console.log((Math.floor((max - min) / 70)), (Math.ceil((max - min) % 70) * 0.1), max, min)
                // if ((Math.floor((max - min) / 70) + (Math.ceil((max - min) % 70) * 0.1)) > 1) {
                // scale[x] = Math.floor((max - min) / 70) + (Math.ceil((max - min) % 70) * 0.1);//ceil
                // } else {
                //     scale[x] = 1;
                // }

                if ((max - min) / (AXIS_HEIGHT / 10) > 1) {
                    scale[x] = (max - min) / (AXIS_HEIGHT / 10);
                } else {
                    scale[x] = 1
                }

                NUM_VERTICAL_TICKS_TWO[x] = (AXIS_HEIGHT / 10 * scale[x]);
            }
        }
        winW = canvas.width - dataSource.length * HORIZONTAL_AXIS_MARGIN
    }

    init(data)

    //canvas相应事件
    if (canvas.addEventListener) {
        canvas.addEventListener('mousemove', mouseMove, false);
        // IE9, Chrome, Safari, Opera
        canvas.addEventListener("mousewheel", scaleCanvas2, false);
        // Firefox
        canvas.addEventListener("DOMMouseScroll", scaleCanvas, false);
    } else {
        // IE 6/7/8
        canvas.attachEvent("onmousewheel", scaleCanvas);
    }

//鼠标滑动相应事件
    function mouseMove(event) {

        whoFlag = false;
        arr.forEach(function (v, i) {
            if ((event.clientX - arr[i].x - event.toElement.offsetLeft <= arr[i].width && event.clientX - arr[i].x - event.toElement.offsetLeft >= 0)
                && event.clientY - arr[i].y - event.toElement.offsetTop <= arr[i].height) {
                who = i;
                whoFlag = true;
                vFlag = true;
            }
        });
        if (!whoFlag && vFlag) {
            who = -1;
            vFlag = false;
        } else if (!whoFlag) {
            who = -1;
        }


    }

//火狐下滑轮滚动事件 暂时无效
    function scaleCanvas(event) {
        event.preventDefault();
        var e = window.event || event; // old IE support
        var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
        count += e.detail;
        if (count >= 0) {
            NUM_VERTICAL_TICKS = NUM_VERTICAL_TICKS + e.detail;
            INPUT_NUM = INPUT_NUM - e.detail;
            INPUT_NUM2 = INPUT_NUM2 - e.detail;
            NUM_VERTICAL_MIN = NUM_VERTICAL_MIN + e.detail;
        } else {
            count = 0;
            NUM_VERTICAL_TICKS = NUM_VERTICAL_TICKS_TWO;
            NUM_VERTICAL_MIN = count;
            INPUT_NUM = INPUT_NUM_TWO;
            INPUT_NUM2 = INPUT_NUM_TWO2;
        }
    }

//其他浏览器下滑轮滚动事件
    function scaleCanvas2(event) {
        event.preventDefault();
        var e = window.event || event; // old IE support
        var speed;
        if (eventTimeStamp == e.timeStamp) {
            return
        }
        if (e.deltaY > 0) {
            speed = 3;
        } else {
            speed = -3;
        }
        eventTimeStamp = e.timeStamp;
        whoFlag = false;
        arr.forEach(function (v, i) {
            if ((event.clientX - arr[i].x - event.toElement.offsetLeft <= arr[i].width && event.clientX - arr[i].x - event.toElement.offsetLeft >= 0)
                && event.clientY - arr[i].y - event.toElement.offsetTop <= arr[i].height) {
                who = i
                count[i] += speed;
                whoFlag = true;
            }
        });
        if (!whoFlag) {
            for (var i = 0; i < count.length; i++) {
                count[i] += speed;
            }
            who = -1;
        }
        for (var i = -1; i < dataSource.length; i++) {
            if (who == i && who >= 0) {
                // if (count[i] >= 0) {
                NUM_VERTICAL_TICKS[i] = NUM_VERTICAL_TICKS[i] + speed * scale[i];
                NUM_VERTICAL_MIN[i] = NUM_VERTICAL_MIN[i] + speed * scale[i];
                // } else {
                //     count[i] = 0;
                //     NUM_VERTICAL_TICKS[i] = NUM_VERTICAL_TICKS_TWO[i];
                //     NUM_VERTICAL_MIN[i] = NUM_VERTICAL_MIN_TWO[i];
                // }
            } else if (who == i && who < 0) {
                for (var i1 = 0; i1 < NUM_VERTICAL_TICKS.length; i1++) {
                    // if (count[i1] >= 0) {
                    NUM_VERTICAL_TICKS[i1] = NUM_VERTICAL_TICKS[i1] + speed * scale[i1];
                    NUM_VERTICAL_MIN[i1] = NUM_VERTICAL_MIN[i1] + speed * scale[i1];
                    // } else {
                    //     count[i1] = 0;
                    //     NUM_VERTICAL_TICKS[i1] = NUM_VERTICAL_TICKS_TWO[i1];
                    //     NUM_VERTICAL_MIN[i1] = NUM_VERTICAL_MIN_TWO[i1];
                    // }
                }
            }
        }


    }

    function draw() {

        function drawAxes() {
            context.save();
            context.lineWidth = 1.0;
            context.fillStyle = "rgba(100, 140, 230, 0.8)";
            context.strokeStyle = "navy";
            drawHorizontalAxis();
            for (var i = 0; i < dataSource.length; i++) {
                drawVerticalAxis(i * VERTICAL_AXIS_MARGIN);
            }
            context.lineWidth = 0.5;
            context.strokeStyle = "navy";
            context.strokeStyle = "darkred";
            for (var i = 0; i < dataSource.length; i++) {
                drawVerticalAxisTicks(i * VERTICAL_AXIS_MARGIN);
            }
            drawVerticalName(dataSourceName)
            // drawHorizontalAxisTicks();
            context.restore();
        }

        //绘制水平的小标
        // function drawHorizontalAxisTicks() {
        //     var deltaY;
        //     for (var i = canvas.width - dataSource.length * 50; i < canvas.width; i++) {
        //         context.beginPath();
        //         //判断画的是大坐标还是短坐标
        //         if (i % 5 == 0) {
        //             deltaY = TICK_WIDTH;
        //         } else {
        //             deltaY = TICK_WIDTH / 2
        //         }
        //         context.moveTo(AXIS_ORIGIN.x + i * HORIZONTAL_TICK_SPACING,
        //             AXIS_ORIGIN.y - deltaY);
        //         context.lineTo(AXIS_ORIGIN.x + i * HORIZONTAL_TICK_SPACING,
        //             AXIS_ORIGIN.y + deltaY);
        //         context.stroke();
        //     }
        // }

        //绘制数值的小标
        function drawVerticalAxisTicks(p) {
            var deltaX;
            for (var i = 1; i < AXIS_HEIGHT / VERTICAL_TICK_SPACING; i++) {
                context.beginPath();
                if (i % 5 === 0) {
                    deltaX = TICK_WIDTH;
                } else {
                    deltaX = TICK_WIDTH / 2;
                }
                if (AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING < AXIS_HEIGHT + VERTICAL_AXIS_MARGIN &&
                    AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING >= VERTICAL_AXIS_MARGIN) {
                    context.moveTo(AXIS_ORIGIN.x - deltaX + p,
                        AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING);
                    context.lineTo(AXIS_ORIGIN.x + deltaX + p,
                        AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING);
                }
                context.stroke();
            }
        }

        //画竖直线
        function drawVerticalAxis(p) {
            context.beginPath();
            if (lineColor[p / VERTICAL_AXIS_MARGIN] != null && lineColor[p / VERTICAL_AXIS_MARGIN] != undefined && lineColor[p / VERTICAL_AXIS_MARGIN] != 0) {
                context.strokeStyle = lineColor[p / VERTICAL_AXIS_MARGIN];
            } else {
                context.strokeStyle = RGB[p / VERTICAL_AXIS_MARGIN];
            }
            context.moveTo(AXIS_ORIGIN.x + p, AXIS_ORIGIN.y);
            context.lineTo(AXIS_ORIGIN.x + p, AXIS_TOP);
            context.stroke();
        }

        //画水平线
        function drawHorizontalAxis() {
            context.beginPath();
            context.moveTo(AXIS_ORIGIN.x, AXIS_ORIGIN.y);
            context.lineTo(canvas.width, AXIS_ORIGIN.y);
            context.stroke();
        }

        //绘制标注
        function drawAxisLabels() {
            context.fillStyle = "blue";
            //dataSouce
            for (var i = 0; i < dataSource.length; i++) {
                drawHorizontalAxisLabels(i * VERTICAL_AXIS_MARGIN, scale[i], NUM_VERTICAL_MIN[i], i + 1);
            }
            drawVerticalAxisLabels();
        }

        //绘制水平轴标注
        function drawVerticalAxisLabels() {
            context.textAlign = "center";
            context.fillStyle = 'blue';
            context.textBaseline = "top";
            HORIZONTAL_TICK_SPACING = winW / yLength
            var s = 1;
            for (var i = 0; HORIZONTAL_TICK_SPACING * s < 5; s = s * 2, i++) {
            }
            for (var i = 0, x = 0; i <= yLength; i++, x++) {
                if (x % (5 * s) === 0) {
                    context.fillText(i,
                        AXIS_ORIGIN.x + x * HORIZONTAL_TICK_SPACING + (dataSource.length - 1) * 50,//x * HORIZONTAL_TICK_SPACING
                        AXIS_ORIGIN.y + SPACE_BETWEEN_ABELS_AND_AXIS)
                }
            }
            context.save()
            context.restore()
        }

        //绘制每条线的名称
        function drawVerticalName(dataName) {
            context.textAlign = "center";
            context.textBaseline = "middle";
            for (var i = 0; i < dataName.length; i++) {
                if (lineColor[i] != null && lineColor[i] != undefined && lineColor[i] != 0) {
                    context.fillStyle = lineColor[i];
                } else {
                    context.fillStyle = RGB[i];
                }
                context.fillText(dataName[i],
                    canvas.width - VERTICAL_AXIS_MARGIN * 2,
                    i * 25 + VERTICAL_AXIS_MARGIN);
            }
            context.save()
            context.restore()
        }

        //绘制竖直轴标注
        function drawHorizontalAxisLabels(p, n, nm, pn) {
            context.textAlign = "center";
            context.textBaseline = "middle";
            for (var i = 0, x = nm; i <= AXIS_HEIGHT / VERTICAL_TICK_SPACING; i++, x += n) {
                if (i % 5 === 0 && i != 0 &&
                    AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING <= AXIS_HEIGHT + VERTICAL_AXIS_MARGIN &&
                    AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING >= VERTICAL_AXIS_MARGIN) {
                    if (pn == who + 1) {
                        context.fillStyle = 'red';
                        context.fillText(x.toFixed(1),
                            AXIS_ORIGIN.x - SPACE_BETWEEN_ABELS_AND_AXIS + p,
                            AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING);
                    } else {
                        context.fillStyle = 'blue';
                        context.fillText(x.toFixed(1),
                            AXIS_ORIGIN.x - SPACE_BETWEEN_ABELS_AND_AXIS + p,
                            AXIS_ORIGIN.y - i * VERTICAL_TICK_SPACING);
                    }

                }
            }
            context.save()
            context.restore()
        }

        function drawGrid(color, stepx, stepy) {
            context.save()
            context.strokeStyle = color;
            context.fillStyle = '#ffffff';
            context.lineWidth = 0.5;
            context.fillRect(0, 0, context.canvas.width, context.canvas.height);
            for (var i = stepx + 0.5; i < context.canvas.width; i += stepx) {
                context.beginPath();
                context.moveTo(i, 0);
                context.lineTo(i, context.canvas.height);
                context.stroke();
            }
            for (var i = stepy + 0.5; i < context.canvas.height; i += stepy) {
                context.beginPath();
                context.moveTo(0, i);
                context.lineTo(context.canvas.width, i);
                context.stroke();
            }
            context.restore();
        }

        context.font = "13px Arial";

        drawGrid("lightgray", 10, 10);

        context.shadowColor = "rgba(100, 140, 230, 0.8)";
        context.shadowOffsetX = 3;
        context.shadowOffsetY = 3;
        context.shadowBlur = 5;

        drawAxisLabels();
        drawAxes();
        //Math.random() *
        if (canvas.getContext) {
            for (var y = 0; y < dataSource.length; y++) {
                context.beginPath();
                if (lineColor[y] != null && lineColor[y] != undefined && lineColor[y] != 0) {
                    context.strokeStyle = lineColor[y];
                } else {
                    context.strokeStyle = RGB[y];
                }

                if (lineThickness[y] != null && lineThickness[y] != 0 && lineThickness[y] != undefined) {
                    context.lineWidth = lineThickness[y];
                } else {
                    context.lineWidth = 1;
                }
                for (let i = 0, x = dataSource.length * VERTICAL_AXIS_MARGIN;//dataSource.length * VERTICAL_AXIS_MARGIN
                     i < dataSource[y].length;
                     i++, x = x + (canvas.width - dataSource.length * VERTICAL_AXIS_MARGIN) / dataSource[y].length) {

                    // console.log(VERTICAL_AXIS_MARGIN + ((NUM_VERTICAL_TICKS_TWO[y] + NUM_VERTICAL_MIN[y]) - dataSource[y][i]) * VERTICAL_TICK_SPACING / scale[y])
                    if (VERTICAL_AXIS_MARGIN + ((NUM_VERTICAL_TICKS_TWO[y] + NUM_VERTICAL_MIN[y]) - dataSource[y][i]) / VERTICAL_TICK_SPACING / scale[y] < AXIS_HEIGHT + VERTICAL_AXIS_MARGIN &&
                        VERTICAL_AXIS_MARGIN + ((NUM_VERTICAL_TICKS_TWO[y] + NUM_VERTICAL_MIN[y]) - dataSource[y][i]) / VERTICAL_TICK_SPACING / scale[y] >= VERTICAL_AXIS_MARGIN) {
                        context.lineTo(x, VERTICAL_AXIS_MARGIN + ((NUM_VERTICAL_TICKS_TWO[y] + NUM_VERTICAL_MIN[y]) - dataSource[y][i]) * VERTICAL_TICK_SPACING / scale[y]);
                    }

                }
                context.stroke();
            }

            for (var y = 0; y < dataSource.length; y++) {
                context.beginPath();
                if (lineColor[y] != null && lineColor[y] != undefined && lineColor[y] != 0) {
                    context.strokeStyle = lineColor[y];
                } else {
                    context.strokeStyle = RGB[y];
                }
                // if (lineThickness[y] != null && lineThickness[y] != 0 && lineThickness[y] != undefined) {
                //     context.lineWidth = lineThickness[y];
                // } else {
                context.lineWidth = 0.5;
                // }
                for (let i = 0, x = dataSource.length * VERTICAL_AXIS_MARGIN;//dataSource.length * VERTICAL_AXIS_MARGIN
                     i < dataSource[y].length;
                     i++, x = x + (canvas.width - dataSource.length * VERTICAL_AXIS_MARGIN) / dataSource[y].length) {
                    if (YStart[y]) {
                        context.lineTo(x, VERTICAL_AXIS_MARGIN + ((NUM_VERTICAL_TICKS_TWO[y] + NUM_VERTICAL_MIN[y]) - 0) * VERTICAL_TICK_SPACING / scale[y]);
                    }

                }
                context.stroke();
            }

        }

    }
}





function mapStateToProps(store) {
    return {
        provinceStatistic: store.DASNcovReducer.provinceStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(HCanvas2));