import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Form, Input, Upload, Button, Icon, Table, Alert, Tag, Switch, Col, Radio} from "antd";
import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";
import {AnnotationFileSourceType} from "../../Enums";
import EnumItemRadio from "../../../../components/EnumItemRadio";
import DeviceSelectModal from "../../components/DeviceSelectModal";
import {
    getPastDynastiesVersion,
    getUserMemoList,
    refreshChannelType,
    refreshVersionIndex, searchEcgRecordSum,
    toggleDeviceSelectModal
} from "../../actions/DeviceAction";
import {getDateIndexFile, readDateIndexFile, refreshVersionChange} from "../../actions/HistoryAction";
import HistoryCalendar from "../ECG/History/Calendar/HistoryCalendar";
import FileUtils from "../../../../utils/FileUtils";
import {APP_SETTING_SOCKET_SERVER_HOST, TERMINAL_SOCKET_PATH} from "../../../../constants/Profile";
import EnumItemSelect from "../../../../components/EnumItemSelect";

class EcgFileSourceModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            fileSourceType: AnnotationFileSourceType.Enum.D9K_LOCAL,
            d9kEcgDatabaseRoot: "",
            deviceNumber: "",
            fileList: [],
            // 历史心电图的标注文件列表
            annotationMap: {},
            fileListColumns: [
                {
                    key: 'name',
                    dataIndex: 'name',
                    title: "文件名"
                }, {
                    key: 'size',
                    dataIndex: 'size',
                    title: "大小"
                }, {
                    key: 'status',
                    dataIndex: 'status',
                    title: "状态",
                    render: (text, record, index) => this.state.annotationMap[FileUtils.getFileName(record.name)] ?
                        <Tag color="green">已标注</Tag> : <Tag color="red">未标注</Tag>
                }
                // , {
                //     key: 'action',
                //     dataIndex: 'action',
                //     title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                //     render: (text, record, index) => this._renderButtonGroup(text, record, index)
                // }
            ],
            appSettingSocketStatus: false,
            version:"00"
        }
    }

    //获取APP方向
    _handleGetAppSetting() {
        const self = this;
        const appSettingWebSocket = new WebSocket(APP_SETTING_SOCKET_SERVER_HOST);
        appSettingWebSocket.onopen = function (evnt) {
            console.log("APP Setting 服务连接成功：");
            self.setState({appSettingSocketStatus: true});
            appSettingWebSocket.send(JSON.stringify({command: 'get', key: 'D9K_ECG_DATABASE_ROOT'}));
            appSettingWebSocket.send(JSON.stringify({command: 'get', key: 'D9K_DEVICE_NUMBER'}));
        };
        appSettingWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            console.log(data);
            switch (data.command) {
                case "get":
                    if (data.key === "D9K_ECG_DATABASE_ROOT") {
                        self.setState({d9kEcgDatabaseRoot: data.value});
                    } else if (data.key === "D9K_DEVICE_NUMBER") {
                        self.setState({deviceNumber: data.value});
                    }
                    break;
                case "save":
                    if (data.status === 1) {
                        console.log("配置信息写入成功");
                    } else {
                        console.log("配置信息写入失败");
                    }
                    break;
            }
        };
        appSettingWebSocket.onerror = function (evnt) {
            console.log("APP Setting 服务连接异常：" + evnt.target.url);
            self.setState({appSettingSocketStatus: false});
        };
        appSettingWebSocket.onclose = function (evnt) {
            console.log("APP Setting 服务连接断开：" + evnt.target.url);
            self.setState({appSettingSocketStatus: false});
        };
    }

    //存储APP设置
    _handleSaveAppSetting() {
        const self = this;
        const appSettingWebSocket = new WebSocket(APP_SETTING_SOCKET_SERVER_HOST);
        appSettingWebSocket.onopen = function (evnt) {
            console.log("APP Setting 服务连接成功：");
            self.setState({appSettingSocketStatus: true});
            appSettingWebSocket.send(JSON.stringify({
                command: 'save',
                key: 'D9K_ECG_DATABASE_ROOT',
                value: self.state.d9kEcgDatabaseRoot
            }));
            appSettingWebSocket.send(JSON.stringify({
                command: 'save',
                key: 'D9K_DEVICE_NUMBER',
                value: self.state.deviceNumber
            }));
        };
        appSettingWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            console.log(data);
            switch (data.command) {
                case "get":
                    if (data.key === "D9K_ECG_DATABASE_ROOT") {
                        self.setState({d9kEcgDatabaseRoot: data.value});
                    } else if (data.key === "D9K_DEVICE_NUMBER") {
                        self.setState({deviceNumber: data.value});
                    }
                    break;
                case "save":
                    if (data.status === 1) {
                        console.log("配置信息写入成功");
                    } else {
                        console.log("配置信息写入失败");
                    }
                    break;
            }
        };
        appSettingWebSocket.onerror = function (evnt) {
            console.log("APP Setting 服务连接异常：" + evnt.target.url);
            self.setState({appSettingSocketStatus: false});
        };
        appSettingWebSocket.onclose = function (evnt) {
            console.log("APP Setting 服务连接断开：" + evnt.target.url);
            self.setState({appSettingSocketStatus: false});
        };
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                {/*<a onClick={this._handleGetDevices.bind(this, record)}>*/}
                {/*<FormattedMessage id="ECG_BINDER_BUTTON_DEVICES"/>*/}
                {/*</a>*/}
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handleGetGuarders.bind(this, record)}>*/}
                {/*<FormattedMessage id="ECG_BINDER_BUTTON_GUARDERS"/>*/}
                {/*</a>*/}
            </div>
        )
    }

    componentDidMount() {
        this._handleGetAppSetting();
    }

    _handleOk() {
        if (this.state.fileList.length > 0) {
            const fileList = Object.assign([], this.state.fileList);
            const annotationMap = Object.assign([], this.state.annotationMap);
            this.setState({fileList: [], annotationMap: {}});
            this.props.onOk && this.props.onOk.call(this, fileList, annotationMap, this.state.fileSourceType);
        }
    }

    _handleCancel() {
        this.setState({fileList: [], annotationMap: {}});
        this.props.onCancel && this.props.onCancel.call(this);
    }

    _handleSelectDevice(value) {

        if (this.state.fileSourceType === AnnotationFileSourceType.Enum.D9K_LOCAL) {

            const formatMessage = this.state.formatMessage;
            let messageMap = {};
            if (this.state.d9kEcgDatabaseRoot === undefined || this.state.d9kEcgDatabaseRoot === "") {
                messageMap["d9kEcgDatabaseRoot"] = formatMessage({id: "ECG_ANNOTATION_MSG_DATABASE_ROOT_REQUIRED"});
            }
            if (this.state.deviceNumber === undefined || this.state.deviceNumber === "") {
                messageMap["deviceNumber"] = formatMessage({id: "ECG_ANNOTATION_MSG_DEVICE_NUMBER_REQUIRED"});
            }

            this.setState({messageMap: messageMap});

            if (Object.keys(messageMap).length <= 0) {
                const dataIndexFilePath = this._getD9KEcgDateIndexFilePath();
                this.props.dispatch(readDateIndexFile(dataIndexFilePath));
                this._handleSaveAppSetting();
            }
            // console.log("value", value, this.state.d9kEcgDatabaseRoot, this.state.deviceNumber,messageMap);
        } else {
            this.props.dispatch(getDateIndexFile(value.code, this.props.channelType));
            this.setState({"deviceNumber": value.code});
            this.props.onSelectDevice && this.props.onSelectDevice.call(this, value.code);

            //获取设备的所有世代信息
            this.props.dispatch(getPastDynastiesVersion(value.code, this.state.formatMessage));
        }
    }

    render() {
        const {formatMessage} = this.state;

        let fileSourceSelectComponent = this._renderD9KLocalItem();
        switch (this.state.fileSourceType) {
            case AnnotationFileSourceType.Enum.D9K_LOCAL:
                fileSourceSelectComponent = this._renderD9KLocalItem();
                break;
            case AnnotationFileSourceType.Enum.D9K_SERVER:
                fileSourceSelectComponent = this._renderD9KItems();
                break;
            case AnnotationFileSourceType.Enum.MIT:
                break;
        }

        return (
            <Modal
                width={1000}
                title={formatMessage({id: "ECG_ANNOTATION_TITLE_FILE_SOURCE"})}
                maskClosable={false}
                visible={this.props.showEcgFileSourceModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <DeviceSelectModal onSelect={(value) => this._handleSelectDevice(value)}/>
                <Form>
                    <Form.Item label={formatMessage({id: 'ECG_ANNOTATION_FIELD_SOURCE_TYPE'})} {...Label_6_Wrapper_18}>
                        <EnumItemRadio data={AnnotationFileSourceType.List} value={this.state.fileSourceType}
                                       onChange={(value) => {
                                           this.setState({fileSourceType: value})
                                       }}/>
                    </Form.Item>
                    {fileSourceSelectComponent}
                </Form>
                {this.state.deviceNumber ?
                    <HistoryCalendar {...this.props} deviceCode={this.state.deviceNumber}
                                     onSelect={(value) => this._handleSelectDate(value)}
                                     xuanzeValue={this.state.version}/>
                    : null}
            </Modal>
        );
    }

    _renderD9KLocalItem() {
        const {formatMessage} = this.state;

        return (
            <div>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_LOCAL_FILE_PERMISSION'})} {...Label_6_Wrapper_18}
                    required={true}>
                    <Switch checked={this.state.appSettingSocketStatus}
                            onChange={() => this._handleGetAppSetting()}/> <a href={TERMINAL_SOCKET_PATH}>Terminal
                    Socket <FormattedMessage id={"COMMON_BUTTON_DOWNLOAD"}/></a>
                    <Alert message={formatMessage({id: "ECG_ANNOTATION_FIELD_LOCAL_FILE_PERMISSION_HELP"})}
                           type="info"/>
                </Form.Item>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_DATABASE_ROOT'})} {...Label_6_Wrapper_18}
                    required={true}
                    validateStatus={this.state.messageMap["d9kEcgDatabaseRoot"] ? "error" : ""}
                    help={this.state.messageMap["d9kEcgDatabaseRoot"]}>
                    <Input value={this.state.d9kEcgDatabaseRoot}
                           onChange={(value) => this.setState({d9kEcgDatabaseRoot: value.target.value})}/>
                    <Alert message={formatMessage({id: "ECG_ANNOTATION_FIELD_DATABASE_ROOT_HELP"})} type="info"/>
                </Form.Item>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_DEVICE_CODE'})} {...Label_6_Wrapper_18}
                    required={true}
                    validateStatus={this.state.messageMap["deviceNumber"] ? "error" : ""}
                    help={this.state.messageMap["deviceNumber"]}>
                    <Input value={this.state.deviceNumber}
                           onChange={(value) => this.setState({deviceNumber: value.target.value})}/>
                    <Alert message={formatMessage({id: "ECG_ANNOTATION_FIELD_DEVICE_CODE_HELP"})} type="info"/>
                </Form.Item>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_DATE_INDEX_FILE'})} {...Label_6_Wrapper_18}>
                    <Input disabled
                           value={this._getD9KEcgDateIndexFilePath()}
                           addonAfter={<a onClick={() => this._handleSelectDevice(this.state.deviceNumber)}>
                               <FormattedMessage id='ECG_ANNOTATION_TITLE_BUTTON_READ_DATE_FILE'/>
                           </a>}/>
                </Form.Item>
            </div>
        )
    }

    _getD9KEcgDateIndexFilePath() {
        return this.state.d9kEcgDatabaseRoot + "\\" + this.state.deviceNumber + "\\0000\\idx\\" + this.state.deviceNumber + "_date.idx";
    }

    _renderD9KFileUpload() {
        const {formatMessage} = this.state;
        const props = {
            name: 'file',
            showUploadList: false,
            beforeUpload: file => {

                // 比如一些隐藏文件，需要被过滤掉
                if (file.name.indexOf(".") <= 0) {
                    return false;
                }

                // 需要区分是标注文件还是心电文件
                if (file.name.lastIndexOf(".anno") > 0) {
                    const reader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = () => {
                        const fileName = FileUtils.getFileName(file.name);
                        const annotationMap = Object.assign({}, this.state.annotationMap);
                        const annotationPointMap = JSON.parse(reader.result);
                        console.log(annotationPointMap);
                        const newAnnotationPointMap = {};
                        for (let key in annotationPointMap) {
                            newAnnotationPointMap[key] = {
                                pointIndex: key,
                                annotation: annotationPointMap[key]
                            };
                        }
                        annotationMap[fileName] = newAnnotationPointMap;
                        this.setState({
                            annotationMap: annotationMap,
                        });
                    };
                } else {
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                }
                return false;
            },
        };
        return (
            <div>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_ROOT_FOLDER_SELECT'})} {...Label_6_Wrapper_18}>
                    <Upload directory  {...props}>
                        <Button>
                            <Icon type="upload"/> 选择数据目录
                        </Button>
                    </Upload>
                    <Alert message="受浏览器限制，网页程序无法主动读取本地文件，必须手动选择指定目录进行文件读取" type="info"/>
                </Form.Item>
                <Form.Item
                    label={formatMessage({id: 'ECG_ANNOTATION_FIELD_ECG_FILE'})} {...Label_6_Wrapper_18}>
                    <Table rowKey="uid" size="small" columns={this.state.fileListColumns}
                           dataSource={this.state.fileList}/>
                </Form.Item>
            </div>
        )
    }

    _renderD9KItems() {
        const {formatMessage} = this.state;

        //设置世代数据
        let lstData = [];
        let mapVersion = this.props.mapVersion;
        mapVersion.sort((curr, next) => {
            let a = curr;
            let b = next;

            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        });

        mapVersion.map((item, index) => {
            if (index === 0) {
                lstData.push({value: '00', label: item + "(当世)"});
            } else {
                lstData.push({value: item.substring(2, 4), label: item});
            }
        })
        console.log("lstData:" + mapVersion);
        return (
            <div>
                <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_DEVICE_NUMBER'})} {...Label_6_Wrapper_18}>
                    <Input disabled value={this.state.deviceNumber}
                           addonAfter={<a onClick={() => this.props.dispatch(toggleDeviceSelectModal(true))}>
                               <FormattedMessage id='ECG_DEVICE_BUTTON_SELECT_DEVICE'/>
                           </a>}/>
                    <Radio.Group onChange={(event) => {
                        this._handleChannelTypeChange(event.target.value);
                    }} value={this.props.channelType}>
                        <Radio value={'10'}>设备</Radio>
                        <Radio value={'20'}>手机</Radio>

                        世代编号：
                        <EnumItemSelect data={lstData}
                                        value={this.state.version}
                                        style={{width: 240}}
                                        onChange={(value) => {
                                            this._handleVersionChange(value);
                                        }}/>

                    </Radio.Group>
                </Form.Item>
            </div>
        )
    }

    _handleChannelTypeChange(value) {
        this.props.dispatch(refreshChannelType(value));
        this.props.dispatch(getDateIndexFile(this.state.deviceNumber, value,this.state.version));
    }

    _handleSelectDate(ecgDateIndexRecord) {
        this.props.onSelectDate && this.props.onSelectDate.call(this, ecgDateIndexRecord, this.state.fileSourceType, this.state.d9kEcgDatabaseRoot, this.state.deviceNumber, this.props.channelType);
    }
    _handleVersionChange(value) {
        this.setState({version: value});
        this.props.dispatch(getDateIndexFile(this.state.deviceNumber, this.props.channelType, value));
    }
}

function mapStateToProps(store) {
    return {
        // showEcgFileSourceModal: store.EcgAnnotationToolReducer.showEcgFileSourceModal,
        // binder: store.EcgAnnotationToolReducer.binder
        channelType: store.EcgDeviceReducer.channelType,
        version: store.EcgDeviceReducer.version,
        mapVersion: store.EcgDeviceReducer.mapVersion,
    }
}

export default connect(mapStateToProps)(injectIntl(EcgFileSourceModal));