/**
 * 名称    字节数    范围    说明
 *  Date    4B        年/月/日     8B时间类型的高4字节
 *  RecordNum    4B        记录条的数量，0表示没有记录条
 *  TimeNum    4B    0-224-1    非空记录条的数量
 *  WorkTime    4B        当天记录总时长，以毫秒为单位
 *  TimeSliceNum    4B        时间隙数量，目前情况下为5444
 *  MiniSecPerFile    4B        每个历史文件的时间长度，单位毫秒。当前默认
 *  15.872秒=256*31/500
 *  Record1    8B
 *  名称    字节数    范围    说明
 *  HistoryFileIndex    4B    0-232-1    历史数据索引编号
 *  EventRecordIndex    4B    0-232-1    对应的事件记录编号，指向此时刻对应的第1条事件索引记录
 */
import EcgFileDate from "./EcgFileDate";
import EcgFileTimeIndexRecord from "./EcgFileTimeIndexRecord";

class EcgFileTimeIndex {
    constructor(dataArray, startPos) {
        let index = startPos;

        // 索引文件的起始日期
        const ecgFileDate = new EcgFileDate(dataArray, index);
        console.log("索引文件的起始日期:", dataArray, ecgFileDate)
        this.date = ecgFileDate.getDate();
        index = index + ecgFileDate.length;

        // 记录条的数量，0表示没有记录条
        const recordNumArray = dataArray.slice(index, index + 4);
        this.recordNum = new Int32Array(recordNumArray.buffer.slice(-4))[0];
        index = index + 4;

        // 非空记录条的数量
        const timeNumArray = dataArray.slice(index, index + 4);
        this.timeNum = new Int32Array(timeNumArray.buffer.slice(-4))[0];
        index = index + 4;

        // 当天记录总时长，以毫秒为单位
        const workTimeArray = dataArray.slice(index, index + 4);
        this.workTime = new Int32Array(workTimeArray.buffer.slice(-4))[0];
        index = index + 4;

        // 时间隙数量，目前情况下为5444
        const timeSliceNumArray = dataArray.slice(index, index + 4);
        this.timeSliceNum = new Int32Array(timeSliceNumArray.buffer.slice(-4))[0];
        index = index + 4;

        // 每个历史文件的时间长度，单位毫秒。
        // 当前默认 15.872秒=256*31/500
        const miniSecPerFileArray = dataArray.slice(index, index + 4);
        this.miniSecPerFile = new Int32Array(miniSecPerFileArray.buffer.slice(-4))[0];
        index = index + 4;

        // 时间记录
        this.records = [];
        for (let i = 0; i < this.recordNum; i++) {
            const ecgFileTimeIndexRecord = new EcgFileTimeIndexRecord(dataArray, index);
            this.records.push(ecgFileTimeIndexRecord);
            index = index + ecgFileTimeIndexRecord.length;
        }

        this.length = index - startPos;
    }
}

export default EcgFileTimeIndex;