import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Table} from "antd";
import moment from 'moment';
import {
    FORMAT_DATE_TIME_FULL_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import {
    getContentFiles
} from "../../../../CMS/actions/ContentFileAction";
import {
    toggleDetailModal,
    refreshReport,
    refreshIsCheck,
    getDiagnosticReportsDetail
} from "../../../actions/DeviceDoctorAction";
import DeviceDoctorModal from "./DeviceDoctorModal";
import HttpUtils from "../../../../../utils/HttpUtils";

let paths = {};
let showReports = [];
let selectedDeviceId;

class DeviceDoctorTab extends Component {
    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            aa: 0,
            valueFiles: [],
            columns:[
                {
                    dataIndex: 'path', key: 'path',
                    render: (text, record, index) => {
                        paths[index] = text;
                    }
                },
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SEQUENCE'}),
                    dataIndex: 'sequence',
                    key: 'sequence',
                    sorter:(a,b) =>a.sequence - b.sequence,
                    render: (text, record, index) =>
                        <div style={{textAlign: 'right'}}
                        >{text}</div>
                },
                {
                    title: formatMessage({id: 'ECG_CASE_ROUTE_PHYSICIANS_REPORT'}), dataIndex: 'fileName', key: 'fileName',
                    sorter:(a,b) =>a.fileName.localeCompare(b.fileName),
                    render: (text, record, index) =>
                        <a target="_blank" href={paths[index]}
                        >{text}
                        </a>
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_UPLOAD_TIME'}),
                    dataIndex: 'uploadTime',
                    key: 'uploadTime',
                    sorter:(a,b) =>a.uploadTime.localeCompare(b.uploadTime),
                    render: (text, record, index) => {
                        let timeOne = new Date();
                        let year = timeOne.getFullYear();
                        let month = timeOne.getMonth() + 1;
                        let day = timeOne.getDate();
                        let str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN)
                        if (year == text.slice(0, 4) && month == text.slice(4, 6) && day == text.slice(6, 8)) {
                            str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_TIME_COLON)
                        }

                        return (
                            <div>
                                {str}
                            </div>
                        )

                    }


                },
                {
                    title: formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_STATE'}),
                    dataIndex: 'state',
                    key: 'state',
                    sorter:(a,b) =>a.state.localeCompare(b.state),
                    render: (text, record, index) =>
                    {
                        if(text === "已诊断"){
                            return <div style={{textAlign: 'left'}}><font color="green">{text}</font></div>
                        }else{
                            return <div style={{textAlign: 'left'}}><font color="red">{text}</font></div>
                        }

                    }

                },
                {
                    title: formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_NAME'}),
                    dataIndex: 'doctorName',
                    key: 'doctorName',
                    // sorter:(a,b) =>a.doctorName.localeCompare(b.doctorName),
                    render: (text, record, index) =>
                        <div style={{textAlign: 'left'}}
                        >{text}</div>
                },
                {
                    title: formatMessage({id: 'ECG_DEVICE_DOCTOR_HOSPITAL'}),
                    dataIndex: 'hospitalName',
                    key: 'hospitalName',
                    // sorter:(a,b) =>a.hospitalName.localeCompare(b.hospitalName),
                    render: (text, record, index) =>
                        <div style={{textAlign: 'left'}}
                        >{text}</div>
                },
                {
                    title: formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_CONTENT'}),
                    dataIndex: 'content',
                    key: 'content',
                    // sorter:(a,b) =>a.content.localeCompare(b.content),
                    render: (text, record, index) =>
                        this._renderButtonCheck(text, record, index)
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_REMARKS'}),
                    dataIndex: 'remarks',
                    key: 'remarks',
                    sorter:(a,b) =>a.remarks.localeCompare(b.remarks),
                    render: (text, record, index) =>
                        <div style={{textAlign: 'left'}}
                        >{text}</div>
                },
                {
                    title: formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_TIME'}),
                    dataIndex: 'diagnosticTime',
                    key: 'diagnosticTime',
                    sorter:(a,b) =>a.diagnosticTime.localeCompare(b.diagnosticTime),
                    render: (text, record, index) => {
                        let timeOne = new Date();
                        let year = timeOne.getFullYear();
                        let month = timeOne.getMonth() + 1;
                        let day = timeOne.getDate();
                        let str = "";
                        if(text && text !== ""){
                            console.log("text:" + text);
                            str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN);
                        }
                        // if (year === text.slice(0, 4) && month === text.slice(4, 6) && day === text.slice(6, 8)) {
                        //     str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_TIME_COLON)
                        // }

                        return (
                            <div>
                                {str}
                            </div>
                        )

                    }


                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) =>
                        this._renderButtonGroup(text, record, index)

                },

            ],
            showQrCode: false,
            selectedRecord: {}
        }
    }

    componentWillMount() {
        const { dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        selectedDeviceId = this.props.selectedDeviceId;
        dispatch(getDiagnosticReportsDetail(selectedDeviceId, formatMessage));

    }

    componentWillUnmount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        if(selectedDeviceId !== nextProps.selectedDeviceId){
            dispatch(getDiagnosticReportsDetail(nextProps.selectedDeviceId, formatMessage));
            selectedDeviceId = nextProps.selectedDeviceId;
        }

    }

    _renderButtonCheck(text, record, index){
        return(
            <div>
                {record.comment ?
                    <a onClick={this._handleShowContent.bind(this, record, index)}>
                        <FormattedMessage id="COMMON_BUTTON_CHECK"/>
                    </a> :
                    null}
            </div>
            )

    }

    _handleShowContent(record, index){
        this.props.dispatch(refreshIsCheck(true));
        this.props.dispatch(refreshReport(record));
        this.props.dispatch(toggleDetailModal(true));
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                {record.comment ?
                    <a onClick={this._handleShowUpdateModal.bind(this, record, index)}>
                        <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                    </a> :
                    <a onClick={this._handleShowUpdateModal.bind(this, record, index)}>
                        <FormattedMessage id="COMMON_BUTTON_DIAGNOSIS"/>
                    </a>}
            </div>
        )
    }
    _handleShowUpdateModal(record, index) {
        this.props.dispatch(refreshIsCheck(false));
        this.props.dispatch(refreshReport(record));
        this.props.dispatch(toggleDetailModal(true));
    }

    _getData(){
        let {reports} = this.props;
        let comments;
        let doctor;
        let hospital;
        let idx = 0;
        let showReport = {};
        showReports = [];

        reports.sort((a, b) => (a.createTime.localeCompare(b.createTime)) * -1);

        for (let report of reports){
            showReport = {};
            comments = report.comments;
            showReport.sequence = idx + 1;
            showReport.path = report.filePath;
            showReport.fileName =report.fileName;
            showReport.uploadTime =report.createTime;
            showReport.deviceCode =report.deviceCode;
            showReport.deviceId =report.deviceId;
            showReport.reportId =report.id;

            if(comments.length > 0){
                doctor = comments[0].doctor;
                if(doctor){
                    hospital = doctor.hospital;
                    showReport.doctorName = doctor.name;
                    showReport.hospitalName = hospital.name;
                }
                showReport.keyId = comments[0].keyId;
                showReport.id = comments[0].id;
                showReport.doctorId = comments[0].doctorId;
                showReport.comment = comments[0].comment;
                if(showReport.comment !== ""){
                    showReport.state = "已诊断";
                }else{
                    showReport.state = "未诊断";
                }
                showReport.remarks = comments[0].remarks;
                showReport.diagnosticTime = comments[0].updateTime?comments[0].updateTime:comments[0].createTime;
            }else{
                showReport.comment = "";
                showReport.state = "未诊断";
                showReport.remarks = "";
                showReport.diagnosticTime = "";
                showReport.doctorName = " ";
                showReport.hospitalName = "";
            }

            showReports.push(showReport);
            idx += 1;
        }

    }

    render() {
        this._getData();

        return(
            <div>
                <DeviceDoctorModal/>
                <Table rowKey="id" columns={this.state.columns}
                       dataSource={showReports}
                       scroll={{x: false}}
                       pagination={{
                           position: "both", pageSize: 10
                       }}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        files: store.ContentFileReducer.files,
        content: store.ContentReducer.content,
        reports:store.DeviceDoctorReducer.reports,
        selectedDeviceId:store.EcgDeviceReducer.selectedDeviceId,
        allDevices: store.EcgDeviceReducer.allDevices,
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceDoctorTab));