import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input,Button} from "antd";
import {
    updateDiagnosticReport,
    toggleDetailModal,
    refreshReport,
    createDiagnosticReport
} from "../../../actions/DeviceDoctorAction";


class DeviceDoctorModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(report) {
        let messageMap = {};
        if (report.comment === undefined || report.comment === "") {
            messageMap["comment"] = "诊断结论不能为空！";
        }
        // if (report.remarks === undefined || report.remarks === "") {
        //     messageMap["remarks"] = this.state.formatMessage({id: 'ECG_DEVICE_DOCTOR_REMARKS'});
        // }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {report} = this.props;
        const {changeBtnClick} = this.props
        if (this._validate(report)) {
            let updateData = {};
            if(report.id){
                updateData.keyId = report.keyId;
                updateData.id = report.id;
                updateData.reportId = report.reportId;
                updateData.doctorId = report.doctorId;
                updateData.comment = report.comment;
                updateData.remarks = report.remarks;
                this.props.dispatch(updateDiagnosticReport(updateData, report.deviceId, this.state.formatMessage));
            }else{
                updateData.reportId = report.reportId;
                updateData.doctorId = this.props.user.id;
                updateData.comment = report.comment;
                updateData.remarks = report.remarks;
                this.props.dispatch(createDiagnosticReport(updateData, report.deviceId, this.state.formatMessage));
            }

        }
        // setTimeout(
        //     () => {
        //         changeBtnClick()
        //     },
        //     500
        // );

    }

    _handleChange(name, value) {
        const report = Object.assign({}, this.props.report);
        report[name] = value && value.target ? value.target.value : value;
        this.props.dispatch(refreshReport(report));

    }

    render() {
        const {report} = this.props;
        const formatMessage = this.state.formatMessage;
        let title = report.fileName;
        // if(!title && title !== ""){
        //     title = title.replace('.pdf','');
        // }
// console.log("user:" + JSON.stringify(this.props.user));
        return (
            <div>
                {this.props.check ?
                    <Modal
                        maskClosable={false}
                        title={title + "-诊断查看"}
                        visible={this.props.showDetailModal}
                        onCancel={() => this._handleCancel()}
                        footer={[<Button key="back" onClick={() => this._handleCancel()}>关闭</Button>]}
                    >
                        <Form>
                            <Form.Item label={formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_RESULT'})}
                                       required={true}
                                       validateStatus={this.state.messageMap["comment"] ? "error" : ""}
                                       help={this.state.messageMap["comment"]}>
                                <Input.TextArea
                                    value={report.comment}
                                    allowClear={false}
                                    disabled={true}
                                    rows={12}
                                    onChange={(data) => {
                                        this._handleChange("comment", data)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={formatMessage({id: 'ECG_DEVICE_DOCTOR_REMARKS'})}
                                required={false}
                                validateStatus={this.state.messageMap["remarks"] ? "error" : ""}
                                help={this.state.messageMap["remarks"]}>
                                <Input.TextArea
                                    value={report.remarks}
                                    allowClear={false}
                                    disabled={true}
                                    rows={12}
                                    onChange={(data) => {
                                        this._handleChange("remarks", data)
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>
                    :
                    <Modal
                        maskClosable={false}
                        title={title + "-诊断查看"}
                        visible={this.props.showDetailModal}
                        onOk={() => this._handleOk()}
                        onCancel={() => this._handleCancel()}
                        okText={formatMessage({id: "COMMON_BUTTON_SUBMIT"})}
                        cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
                    >
                        <Form>
                            <Form.Item label={formatMessage({id: 'ECG_DEVICE_DOCTOR_DIAGNOSIS_RESULT'})}
                                       required={true}
                                       validateStatus={this.state.messageMap["comment"] ? "error" : ""}
                                       help={this.state.messageMap["comment"]}>
                                <Input.TextArea
                                    value={report.comment}
                                    allowClear={true}
                                    rows={12}
                                    onChange={(data) => {
                                        this._handleChange("comment", data)
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={formatMessage({id: 'ECG_DEVICE_DOCTOR_REMARKS'})}
                                required={false}
                                validateStatus={this.state.messageMap["remarks"] ? "error" : ""}
                                help={this.state.messageMap["remarks"]}>
                                <Input.TextArea
                                    value={report.remarks}
                                    allowClear={true}
                                    rows={12}
                                    onChange={(data) => {
                                        this._handleChange("remarks", data)
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Modal>}
            </div>

        );
    }
}

function mapStateToProps(store) {
    return {
        report: store.DeviceDoctorReducer.report,
        showDetailModal: store.DeviceDoctorReducer.showDetailModal,
        check:store.DeviceDoctorReducer.check,
        user: store.AccountUserReducer.user,
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceDoctorModal));