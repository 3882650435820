/**
 * Created by Ethan on 2018/4/21.设备详情第三列
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    InputNumber,
    Button,
    Descriptions,
    Popover,
    Tag,
} from "antd";

import moment from 'moment';

import {EcgFileSource} from "../../../Enums";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";
import EcgTransferStatus from "../../../components/EcgTransferStatus";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class DeviceDetailUsage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    render() {
        const {device, deviceHistoryData} = this.props;

        const formatMessage = this.state.formatMessage;
        console.log("device132:", device, deviceHistoryData)
        return (
            <Descriptions column={1}>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_ACTIVE_FLAG"})}>
                    {device.activeFlag === 1 ?
                        <FormattedMessage id="DAS_DEVICE_FIELD_ACTIVE_FLAG_TRUE"/> :
                        <FormattedMessage id="DAS_DEVICE_FIELD_ACTIVE_FLAG_FALSE"/>}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_ACTIVE_TIME"})}>
                    {device.activeTime !== '' ?
                        moment(device.activeTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}
                </Descriptions.Item>
                <Descriptions.Item label="设备实时传输状态">
                    <EcgTransferStatus status={device.devLiveOnlineStatus}/>
                </Descriptions.Item>
                <Descriptions.Item label="设备历史传输状态">
                    <EcgTransferStatus status={device.devHisOnlineStatus}/>
                </Descriptions.Item>
                <Descriptions.Item label="手机实时传输状态">
                    <EcgTransferStatus status={device.appLiveOnlineStatus}/>
                </Descriptions.Item>
                <Descriptions.Item label="手机历史传输状态">
                    <EcgTransferStatus status={device.appHisOnlineStatus}/>
                </Descriptions.Item>
                {/*<Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_LOCATION"})}>*/}
                {/*<Button type="link" size="small"><FormattedMessage*/}
                {/*id="DAS_DEVICE_FIELD_LOCATION_CURRENT"/></Button>*/}
                {/*<Button type="link" size="small"><FormattedMessage*/}
                {/*id="DAS_DEVICE_FIELD_LOCATION_TRAIL"/></Button>*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_WEB_SUBSCRIBER_NUM"})}>
                    {device.webSubscriberNum}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_UPLOAD_PERCENT"})}>
                    <Popover content={
                        // <div>
                        //     {}
                        //     {/*<Descriptions column={1} size={"small"} bordered={true}>*/}
                        //     {/*    <Descriptions.Item*/}
                        //     {/*        label="最大历史数据文件编号">{deviceHistoryData ? deviceHistoryData[deviceHistoryData.length - 1].endIdx : 0}</Descriptions.Item>*/}
                        //     {/*    <Descriptions.Item*/}
                        //     {/*        label="未删除最小历史数据文件编号">{device.notEasedFileIndex}</Descriptions.Item>*/}
                        //     {/*    <Descriptions.Item*/}
                        //     {/*        label="已经读取最小历史数据文件编号">*/}
                        //     {/*        {*/}
                        //     {/*            deviceHistoryData ? deviceHistoryData[0].beginIdx : 0*/}
                        //     {/*        }*/}
                        //     {/*    </Descriptions.Item>*/}
                        //     {/*</Descriptions>*/}
                        // </div>
                        this._renderHistory(deviceHistoryData)
                    } title="详细">
                        <Button type="link"
                                size="small">{this._historyDataPercentage(deviceHistoryData)}%</Button>
                    </Popover>
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_WIFI_NUM"})}>
                    {device.wifiNum}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LEAD_STATE"})}>
                    {device.leadOff}
                    <Popover content={
                        <Descriptions column={2} size={"small"} bordered={true}>
                            <Descriptions.Item
                                label="RA导联状态">{device.leadAState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V2导联状态">{device.leadEState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LA导联状态">{device.leadBState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V3导联状态">{device.leadFState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LL导联状态">{device.leadCState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V4导联状态">{device.leadGState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V1导联状态">{device.leadDState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V5导联状态">{device.leadHState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                        </Descriptions>
                    } title="详细">
                        <Button type="link" size="small">
                            {(device.leadAState && device.leadBState && device.leadCState && device.leadDState && device.leadEState && device.leadFState && device.leadGState && device.leadHState) ?
                                <Tag color="green">正常</Tag> :
                                <Tag color="red">脱落</Tag>}
                        </Button>
                    </Popover>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_TIME"})}>
                    {(device.lastHisDataReceivedTime && device.lastHisDataReceivedTime !== '') ?
                        moment(device.lastHisDataReceivedTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_SOURCE"})}>
                    <EnumItemLabel map={EcgFileSource.Map} code={device.lastHisDataSource}/>
                </Descriptions.Item>
            </Descriptions>
        );
    }

    _renderHistory(deviceHistoryData) {
        let text = " ";
        this.testRef = React.createRef();
        if (deviceHistoryData.length > 0) {
            for (var i = 0; i < deviceHistoryData.length; i++) {
                text += deviceHistoryData[i].beginIdx + "--" + deviceHistoryData[i].endIdx + "\n "
            }
        }
        if (text == " ") {
            text = "服务器上未有数据"
        }
        console.log("text:", text)
        return (
            <div style={{whiteSpace: 'pre-wrap', overflow: "auto", height: "200px"}} ref={this.testRef}>
                {text}
            </div>
        )
    }

    _historyDataPercentage(deviceHistoryData) {
        var str = 0;
        console.log("deviceHistoryData:", deviceHistoryData)
        if (deviceHistoryData.length > 0) {
            for (var i = 0; i < deviceHistoryData.length; i++) {
                str += deviceHistoryData[i].endIdx - deviceHistoryData[i].beginIdx;
            }
            str = (str + 1) / deviceHistoryData[deviceHistoryData.length - 1].endIdx;
            str = (str * 100).toFixed(2);
        }
        //parseInt((device.readFileIndex / device.writeFileIndex) * 100)
        //    \ ? ((device.readFileIndex / device.writeFileIndex) * 100) : 0
        return str ? str : 0
    }

}


// const mapStateToProps = (store) => {
//     return {
//
//     }
// };


function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(DeviceDetailUsage));