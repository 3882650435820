const ByteUtils = {

    /**
     * int 转 byte
     * @param i
     */
    intToByte: (i) => {
        const b = i & 0xFF;
        let c = 0;
        if (b >= 128) {
            c = b % 128;
            c = -1 * (128 - c);
        } else {
            c = b;
        }
        // console.log(c)
    },
    bytesToInt: (b1, b2, b3) => {
        return b3 << 16 | (b2 & 255) << 8 | b1 & 255;
    }

};

export default ByteUtils;
