import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Form, Row, DatePicker, Input, Upload, Icon} from "antd";
import message from "../../../components/toast";
import moment from 'moment';
import ChannelSelect from "./ChannelSelect";

import {refreshContent} from '../actions/ContentAction';
import {getChannelTree} from "../actions/ChannelAction";
import {
    CACHE_ACCESS_TOKEN,
    CACHE_SITE_CODE,
    CACHE_SITE_ID
} from "../../../constants/Profile";
import {Label_8_Wrapper_16} from "../../../constants/FormItemLayout";
import ContentTypeSelector from "./ContentTypeSelector";
import ContentTitleLayoutSelector from "./ContentTitleLayoutSelector";
import * as FTPWebService from "../../FTP/WebService";
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import {HttpStatus} from "../../../constants/Enums";

class ContentBasicForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const content = Object.assign({}, this.props.content);
        content[name] = value.target ? value.target.value : value;
        console.log(refreshContent);
        this.props.dispatch(refreshContent(content));
    }

    render() {
        const {content, messageMap, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;

        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const yearMonth = moment().format(DateTimeFormats.FORMAT_DATE_SIMPLE);
        const self = this;
        const uploadProps = {
            name: 'file',
            multiple: true,
            action: FTPWebService.uploadWebFile + "?folder=" + siteCode + "%2Fcontents%2F" + yearMonth,
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done' && info.file.response.status === HttpStatus.SUCCESS) {
                    if (!content.titleImage || content.titleImage === "") {
                        self._handleChange("titleImage", info.file.response.data);
                    } else {
                        self._handleChange("titleImage", content.titleImage + ";" + info.file.response.data);
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <Form style={{width: 600}}>
                <Form.Item  {...Label_8_Wrapper_16}
                            label={formatMessage({id: 'CMS_CONTENT_FIELD_CHANNEL'})}
                            required={true}
                            validateStatus={messageMap["channelId"] ? "error" : ""}
                            help={messageMap["channelId"]}>
                    <ChannelSelect channelTree={this.props.channelTree}
                                   value={content.channelId}
                                   onChange={(value) => this._handleChange("channelId", value)}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_CODE'})}
                           required={true}
                           validateStatus={messageMap["code"] ? "error" : ""}
                           help={messageMap["code"]}>
                    <Input
                        value={content.code}
                        onChange={(data) => {
                            this._handleChange("code", data)
                        }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_TITLE'})}
                           required={true}
                           validateStatus={messageMap["title"] ? "error" : ""}
                           help={messageMap["title"]}>
                    <Input value={content.title}
                           onChange={(data) => {
                               this._handleChange("title", data)
                           }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_TYPE'})}
                           required={true}
                           validateStatus={messageMap["type"] ? "error" : ""}
                           help={messageMap["type"]}>
                    <ContentTypeSelector value={content.type} onChange={(data) => {
                        this._handleChange("type", data)
                    }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_TITLE_LAYOUT'})}
                           required={true}
                           validateStatus={messageMap["titleLayout"] ? "error" : ""}
                           help={messageMap["titleLayout"]}>
                    <ContentTitleLayoutSelector value={content.titleLayout} onChange={(data) => {
                        this._handleChange("titleLayout", data)
                    }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_SUB_TITLE'})}>
                    <Input value={content.subTitle}
                           onChange={(data) => {
                               this._handleChange("subTitle", data)
                           }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_SOURCE'})}>
                    <Input value={content.source}
                           onChange={(data) => {
                               this._handleChange("source", data)
                           }}/>
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_PUBLISH_TIME'})}>
                    <DatePicker
                        showTime
                        format={DateTimeFormats.FORMAT_DATE_TIME_HYPHEN}
                        value={(content.publishTime && content.publishTime !== "") ?
                            moment(content.publishTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE) : null}
                        onChange={(value, dateString)=>this._handleChange("publishTime", value.format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE))}
                    />
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_TITLE_IMAGE'})}>
                    <Input.TextArea value={content.titleImage} rows={6}
                                    onChange={(data) => {
                                        this._handleChange("titleImage", data)
                                    }}/>
                    {this._renderTitleImagePreview(content.titleImage)}
                </Form.Item>
                <Form.Item {...Label_8_Wrapper_16}
                           label={formatMessage({id: "CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD"})}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT"}/></p>
                        <p className="ant-upload-hint"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT"}/></p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        );
    }

    _renderTitleImagePreview(titleImage){
        if (!titleImage || titleImage === ""){
            return null;
        }
        const images = titleImage.split(";");
        return(
            <div className="spacing-v">
                {images.map((item, index)=>{
                    return(<img key={index} src={item} style={{width: 120, height: 100}} className="spacing-h"/>)
                })}
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.ChannelReducer.channelTree,
        content: store.ContentReducer.content
    }
}

export default connect(mapStateToProps)(injectIntl(ContentBasicForm));