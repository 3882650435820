/**
 * 名称    字节数    数据类型    数值范围    说明
 * PolymNum    4B    UInt32    0-232-1    模态数量，或者信号类型的数量
 * 例如目前是有心电和MPU6050，即PolymNum=2
 * StartTime    8B    UInt32    0x20,1B,05,00,00,00,D007(27秒，05分，0点，1日，1月，2000年)
 * 0x3B,3B,17,1E,0B,D007(320毫秒，59秒，59分，23点，31日，12月，2000年)    开始采集的时间
 * 此次将时间变更为了8个字节，以便对齐。
 * 高4字节表示：YYYY年，MM月，DD日
 * 低4字节表示：HH时，MM分，SS秒，XX毫秒
 * 目前默认为00毫秒，即实际精度只到秒。
 * Duration    4B    UInt32    0-232-1    持续时间，以ms计量，例如15500表示15.5秒
 * EventNum    4B    UInt32        事件的个数
 * LabelNum    4B    UInt32        标记点的个数
 * Polym0Region
 * Polym1Region
 * …
 * …
 * …
 * EventRecord1                事件记录
 * EventRecord2                事件记录
 * …
 * LabelRecord1                标记记录
 * LabelRecord2                标记记录
 */
import EcgFileDateTime from "./EcgFileDateTime";
import {FORMAT_DATE_TIME_SIMPLE} from "../../../constants/DateTimeFormats";
import EcgFileMixDataPolymRegion from "./EcgFileMixDataPolymRegion";
import EcgFileMixDataEventRecord from "./EcgFileMixDataEventRecord";
import EcgFileMixDataLabelRecord from "./EcgFileMixDataLabelRecord";

class EcgFileMixData {
    constructor(dataArray, startPos, timeRecordIndex, historyFileIndex, eventRecordIndex, drawBufferMap, ecgFilePackage, preTimeIndex, medfiltDeWanderEnable, huffmanDecode) {

        let index = startPos;

        // 模态数量，或者信号类型的数量
        // 例如目前是有心电和MPU6050，即PolymNum=2
        const polymNumArray = dataArray.slice(index, index + 4);
        this.polymNum = new Int32Array(polymNumArray.buffer.slice(-4))[0];
        index += 4;

        // 开始采集的时间
        // 此次将时间变更为了8个字节，以便对齐。
        // 高4字节表示：YYYY年，MM月，DD日
        // 低4字节表示：HH时，MM分，SS秒，XX毫秒
        // 目前默认为00毫秒，即实际精度只到秒。
        // const ecgFileDateTime = new EcgFileDateTime(dataArray, index);
        this.ecgFileDateTime = new EcgFileDateTime(dataArray, index);
        this.startTime = this.ecgFileDateTime.getDateTime(FORMAT_DATE_TIME_SIMPLE);
        console.log("ecgFileDateTime:", this.ecgFileDateTime, this.startTime)
        index = index + this.ecgFileDateTime.length;

        // 持续时间，以ms计量，例如15500表示15.5秒
        const durationArray = dataArray.slice(index, index + 4);
        this.duration = new Int32Array(durationArray.buffer.slice(-4))[0];
        index += 4;

        // 呼吸频率
        const respirationArray = dataArray.slice(index, index + 4);
        this.respiration = new Int32Array(respirationArray.buffer.slice(-4))[0];
        index += 4;

        // 导联脱落状态
        const leadOffArray = dataArray.slice(index, index + 4);
        this.leadOff = new Int32Array(leadOffArray.buffer.slice(-4))[0];
        index += 4;

        // 起搏信号状态
        const paceArray = dataArray.slice(index, index + 4);
        this.pace = new Int32Array(paceArray.buffer.slice(-4))[0];
        index += 4;

        // 对本文件的说明：
        // 0x01：只采集未计算的文件
        // 0x02：来不及计算的文件
        // 0x03：经过计算的文件
        const noteArray = dataArray.slice(index, index + 4);
        this.note = new Int32Array(noteArray.buffer.slice(-4))[0];
        index += 4;

        // 记录此文件时，设备所处的工作状态
        const workModeArray = dataArray.slice(index, index + 4);
        this.workMode = new Int32Array(workModeArray.buffer.slice(-4))[0];
        index += 4;

        // 事件的个数
        const eventNumArray = dataArray.slice(index, index + 4);
        this.eventNum = new Int32Array(eventNumArray.buffer.slice(-4))[0];
        index += 4;

        // 标记点的个数
        const labelNumArray = dataArray.slice(index, index + 4);
        this.labelNum = new Int32Array(labelNumArray.buffer.slice(-4))[0];
        index += 4;

        // PolymRegion 是一种模态的信号的数据区域
        this.polymRegions = [];
        // 实时心电图用固定值1，历史心电图还是要用原来的数据
        const polymCount = drawBufferMap ? 1 : this.polymNum;
        for (let i = 0; i < polymCount; i++) {
            const ecgFileMixDataPolymRegion = new EcgFileMixDataPolymRegion(dataArray, index, timeRecordIndex, historyFileIndex, eventRecordIndex, drawBufferMap, ecgFilePackage, i, preTimeIndex, medfiltDeWanderEnable, huffmanDecode);
            console.log("ecgFileMixDataPolymRegion:",ecgFileMixDataPolymRegion);
            this.polymRegions.push(ecgFileMixDataPolymRegion);
            index = index + ecgFileMixDataPolymRegion.length;
        }

        // 事件记录
        this.eventRecords = [];
        for (let i = 0; i < this.eventNum; i++) {
            const ecgFileMixDataEventRecord = new EcgFileMixDataEventRecord(dataArray, index);
            this.eventRecords.push(ecgFileMixDataEventRecord);
            index = index + ecgFileMixDataEventRecord.length;
        }

        // 标记记录
        this.labelRecords = [];
        for (let i = 0; i < this.labelNum; i++) {
            const ecgFileMixDataLabelRecord = new EcgFileMixDataLabelRecord(dataArray, index);
            this.labelRecords.push(ecgFileMixDataLabelRecord);
            index = index + ecgFileMixDataLabelRecord.length;
        }
    }
}

export default EcgFileMixData;