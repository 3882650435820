/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';
import {VIEWPORT_FLAG} from "../Enums";

const initialState = {
    devices: [],
    allDevices: [],
    devicesSum: 0,
    devicesActiveSum: 0,
    mapVersion: [],
    device: {},
    searchCondition: {},
    searchConditionAll: {},
    screenPointSum: 0,
    pagination: {},
    showDetailModal: false,
    showCreateModal: false,
    showFieldSelectModal: false,
    showCacheModal: false,
    cacheDevice: {}, // Redis中的设备数据
    isLoading: false,
    deviceMonitorColumns: [],
    filterMapViewportFlag: VIEWPORT_FLAG.CHANGE,
    selectedDeviceId: "",
    mapScreenVisible: false,
    monitorSearchCondition: {},
    allAllDevices: [],
    deviceHistoryData: "",
    deviceEventData: "",
    deviceTimeData: "",
    appHistoryData: "",
    appEventData: "",
    appTimeData: "",
    deviceCode: "",
    ecgDateIndexRecord: "",
    topQ: [],
    showResultModal: false,
    mapSearchCondition: {},
    mapDevices: [],
    mapPagination: [],
    deviceListScreenVisible: false,
    regionResearchCondition: {},
    regions: [],
    screenMap: {},
    calendarSearchCondition: {},
    showDeviceSelectModal: false,

    ecgEventRecordMap: {},
    userMemoList: [],
    // 历史文件区分设备和移动端，如果从设备端过来，则为10，如果从移动端过来，则为20
    channelType: '10',
    version: '',
    failFilesMapApp: [],
    failFilesMapDev: [],
    memoTimeFrom:'',
    memoTimeTo:''
};

const DeviceReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_DEVICE_REFRESH_CALENDAR_SEARCH_CONDITION:
            return Object.assign({}, state, {
                calendarSearchCondition: action.calendarSearchCondition,
            });
        case ActionTypes.ECG_DEVICE_DATE_INDEX_RECORD:
            return Object.assign({}, state, {
                ecgDateIndexRecord: action.ecgDateIndexRecord,
            });
        case ActionTypes.ECG_DEVICE_DATE_TOP_POINT:
            return Object.assign({}, state, {
                topQ: action.topQ,
            });
        case ActionTypes.ECG_DEVICE_HISTORY_DATA:
            return Object.assign({}, state, {
                deviceHistoryData: action.deviceHistoryData,
            });
        case ActionTypes.ECG_DEVICE_EVENT_DATA:
            return Object.assign({}, state, {
                deviceEventData: action.deviceEventData,
            });
        case ActionTypes.ECG_EVENT_RECORD_MAP:
            return Object.assign({}, state, {
                ecgEventRecordMap: action.ecgEventRecordMap,
            });
        case ActionTypes.ECG_FILE_STATISTIC_USER_MEMO_LIST:
            return Object.assign({}, state, {
                userMemoList: action.userMemoList
            })
        case ActionTypes.ECG_DEVICE_TIME_DATA:
            return Object.assign({}, state, {
                deviceTimeData: action.deviceTimeData,
            });
        case ActionTypes.ECG_APP_HISTORY_DATA:
            return Object.assign({}, state, {
                appHistoryData: action.appHistoryData,
            });
        case ActionTypes.ECG_APP_EVENT_DATA:
            return Object.assign({}, state, {
                appEventData: action.appEventData,
            });
        case ActionTypes.ECG_APP_TIME_DATA:
            return Object.assign({}, state, {
                appTimeData: action.appTimeData,
            });
        case ActionTypes.ECG_DEVICE_CODE:
            return Object.assign({}, state, {
                deviceCode: action.deviceCode,
            });
        case ActionTypes.ECG_DEVICE_REFRESH_REGIONS:
            return Object.assign({}, state, {
                regions: action.regions,
            });
        case ActionTypes.ECG_DEVICE_REFRESH_REGION_SEARCH_CONDITION:
            return Object.assign({}, state, {
                regionResearchCondition: action.regionResearchCondition,
            });
        case ActionTypes.ECG_DEVICE_REFRESH_SCREEN_MAP:
            return Object.assign({}, state, {
                screenMap: action.screenMap,
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_DEVICE_LIST_SCREEN:
            return Object.assign({}, state, {
                deviceListScreenVisible: action.deviceListScreenVisible,
            });
        case ActionTypes.ECG_MAP_DEVICE_SEARCH:
            return Object.assign({}, state, {
                mapDevices: action.mapDevices,
                mapPagination: action.mapPagination
            });
        case ActionTypes.ECG_DEVICE_REFRESH_MAP_SEARCH_CONDITION:
            return Object.assign({}, state, {
                mapSearchCondition: action.mapSearchCondition
            });
        case ActionTypes.ECG_DASHBOARD_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                monitorSearchCondition: action.monitorSearchCondition
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_RESULT_MODAL:
            return Object.assign({}, state, {
                showResultModal: action.showResultModal
            });
        case ActionTypes.ECG_ALL_DEVICE_SEARCH:
            return Object.assign({}, state, {
                allAllDevices: action.allAllDevices
            });
        case ActionTypes.ECG_DASHBOARD_TOGGLE_MAP_SCREEN:
            return Object.assign({}, state, {
                mapScreenVisible: action.mapScreenVisible
            });
        case ActionTypes.ECG_DEVICE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_DEVICE_SEARCH:
            return Object.assign({}, state, {
                devices: action.devices,
                pagination: action.pagination
            });
        case ActionTypes.ECG_DEVICE_SEARCH_ALL:
            return Object.assign({}, state, {
                allDevices: action.allDevices
            });
        case ActionTypes.ECG_DEVICE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_DEVICE_ALL_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchConditionAll: action.searchConditionAll
            });
        case ActionTypes.ECG_REALTIME_SCREENPOINTSUM:
            return Object.assign({}, state, {
                screenPointSum: action.screenPointSum
            });
        case ActionTypes.ECG_DEVICE_REFRESH_DEVICE:
            return Object.assign({}, state, {
                device: action.device
            });
        case ActionTypes.ECG_DEVICE_REFRESH_CACHE_DEVICE:
            return Object.assign({}, state, {
                cacheDevice: action.cacheDevice
            });
        case ActionTypes.ECG_DEVICE_REFRESH_DEVICES:
            return Object.assign({}, state, {
                devices: action.devices
            });
        case ActionTypes.ECG_DEVICE_REFRESH_DEVICE_MONITOR_COLUMNS:
            return Object.assign({}, state, {
                deviceMonitorColumns: action.deviceMonitorColumns
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_FIELD_SELECT_MODAL:
            return Object.assign({}, state, {
                showFieldSelectModal: action.showFieldSelectModal
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_CACHE_MODAL:
            return Object.assign({}, state, {
                showCacheModal: action.showCacheModal
            });
        case ActionTypes.ECG_DEVICE_SEARCH_DEVICES_SUM:
            return Object.assign({}, state, {
                devicesSum: action.devicesSum
            });
        case ActionTypes.ECG_DEVICE_SEARCH_DEVICES_ACTIVE_SUM:
            return Object.assign({}, state, {
                devicesActiveSum: action.devicesActiveSum
            });
        case ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING:
            return Object.assign({}, state, {
                mapVersion: action.mapVersion
            });
        case ActionTypes.ECG_DEVICE_REFRESH_FILTER_VIEWPORT_FLAG:
            return Object.assign({}, state, {
                filterMapViewportFlag: action.filterMapViewportFlag
            });
        case ActionTypes.ECG_DEVICE_REFRESH_SELECT_DEVICE_ID:
            return Object.assign({}, state, {
                selectedDeviceId: action.selectedDeviceId
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_CREATE_MODAL:
            return Object.assign({}, state, {
                showCreateModal: action.showCreateModal
            });
        case ActionTypes.ECG_DEVICE_TOGGLE_SELECT_MODAL:
            return Object.assign({}, state, {
                showDeviceSelectModal: action.showDeviceSelectModal
            });
        case ActionTypes.ECG_DEVICE_REFRESH_CHANNEL_TYPE:
            return Object.assign({}, state, {
                channelType: action.channelType
            });
        case ActionTypes.ECG_DEVICE_REFRESH_VERSION_INDEX:
            return Object.assign({}, state, {
                version: action.version
            });
        case ActionTypes.ECG_LOG_DEVICE_READ_FAIL_FILES_APP:
            return Object.assign({}, state, {
                failFilesMapApp: action.failFilesMapApp
            });
        case ActionTypes.ECG_LOG_DEVICE_READ_FAIL_FILES_DEV:
            return Object.assign({}, state, {
                failFilesMapDev: action.failFilesMapDev
            });
        case ActionTypes.ECG_DEVICE_REFRESH_MEMO_TIME_FROM:
            return Object.assign({}, state, {
                memoTimeFrom: action.memoTimeFrom
            });
        case ActionTypes.ECG_DEVICE_REFRESH_MEMO_TIME_TO:
            return Object.assign({}, state, {
                memoTimeTo: action.memoTimeTo
            });
        default:
            return state
    }
};

export default DeviceReducer;