import HttpUtils from "../../../utils/HttpUtils";
import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_COORDINATE_REFRESH_CONDITION,
        searchCondition: searchCondition
    }
}



export function searchDeviceCoordinates(deviceId, startTime, endTime, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_COORDINATE_LOADING, isLoading: true});

        let requestUrl = WebService.getDeviceCoordinates.replace("{id}", deviceId);
        requestUrl = requestUrl.replace("{startTime}", startTime);
        requestUrl = requestUrl.replace("{endTime}", endTime);

        console.log(requestUrl);
        dispatch(refreshSearchCondition({startTime: startTime, endTime: endTime}));

        HttpUtils.get(requestUrl, (response) => {
            console.log(response);
            dispatch({
                type: ActionTypes.ECG_DEVICE_COORDINATE_SEARCH,
                coordinates: response.data
            });
            dispatch({type: ActionTypes.ECG_DEVICE_COORDINATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_COORDINATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}