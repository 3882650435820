/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    coordinates: [],
    searchCondition: {}
};

const DeviceCoordinateReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_DEVICE_COORDINATE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_DEVICE_COORDINATE_SEARCH:
            return Object.assign({}, state, {
                coordinates: action.coordinates
            });
        case ActionTypes.ECG_DEVICE_COORDINATE_REFRESH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        default:
            return state
    }
};

export default DeviceCoordinateReducer;