/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Input, Form} from "antd";
import {bindGuarder, toggleGuarderBindModal} from "../../../actions/BinderAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class GuarderBindModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            guarderMobile: ""
        }
    }

    componentDidMount() {
    }

    _handleOk() {
        if (this.state.guarderMobile !== "") {
            this.props.dispatch(bindGuarder(this.props.binder.userId, this.state.guarderMobile, this.state.formatMessage));
        }
    }

    _handleCancel() {
        this.props.dispatch(toggleGuarderBindModal(false));
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                title={formatMessage({id: "ECG_BINDER_TITLE_GUARDER_BIND"})}
                maskClosable={false}
                visible={this.props.showGuarderBindModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'ECG_GUARDER_FIELD_USER_MOBILE'})} {...Label_6_Wrapper_18}>
                        <Input value={this.state.guarderMobile} onChange={(data) => {
                            this.setState({guarderMobile: data.target.value})
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showGuarderBindModal: store.BinderReducer.showGuarderBindModal,
        binder: store.BinderReducer.binder
    }
}

export default connect(mapStateToProps)(injectIntl(GuarderBindModal));