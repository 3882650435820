/**
 * Created by Ethan on 17/3/7.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

// 引入多语言Message
import { FormattedMessage, injectIntl } from 'react-intl';

import PageTitle from "../../../../../components/PageTitle";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        return (
            <div className="page-content">
                <PageTitle title="ECG_BINDER_ROUTE_BINDERS_DATA"/>
                <SearchCondition/>
                <SearchResult {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}
export default connect(mapStateToProps)(injectIntl(Index));