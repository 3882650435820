import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Table, Divider, Popconfirm, Upload, Icon, Popover, Modal, Form, DatePicker, Tooltip} from "antd";
import message from "../../../components/toast";
import moment from 'moment';
import QRCode from 'qrcode.react';
// import ScrollView from 'react-free-scrollbar';

import {
    toggleDetailModal,
    refreshFile,
    deleteContentFile,
    createContentFile, deleteDiagnosticReport
} from '../actions/ContentFileAction';
import * as FTPWebService from "../../FTP/WebService";
import {CACHE_ACCESS_TOKEN, CACHE_SITE_CODE} from "../../../constants/Profile";
import {HttpStatus} from "../../../constants/Enums";
import ContentFileModal from "./ContentFileModal";
import {
    FORMAT_DATE_TIME_FULL_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN, FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_TIME_SLASH,
    FORMAT_TIME_COLON
} from "../../../constants/DateTimeFormats";
import ReactTooltip from "react-tooltip";
import {refreshSearchCondition} from "../../DAS/actions/BinderAction";
import PaginationBar from "../../../components/PaginationBar";
import {getDeviceById, searchDeviceList} from "../../DAS/actions/DeviceAction";

let paths = {};
let remarks = {};
let view = [];
let views = {};
// let fileVersions = [];
let filess = [];
let files2 = [];
let timeFlag = false;
let isFirst = true;
let isDelete = false;
let pageSize = 20;

let minData = 0;
let maxData = Date.now();
let jilu = maxData;
let hFlag = {}

class ContentFileList extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            aa: 0,
            valueFiles: [],
            columns: [
                {
                    dataIndex: 'path', key: 'path',
                    render: (text, record, index) => {
                        paths[index] = text;
                    }
                },
                {
                    dataIndex: 'remarks', key: 'remarks',
                    render: (text, record, index) => {
                        remarks[index] = text;
                        view = [];
                        remarks[index].split("\n").forEach((v, i) => {
                            view.push(v);
                            console.log("v1:", v)
                            view.push(<br key={i}/>)
                        });
                        views[index] = view;
                        console.log("views:", views)
                    }
                },
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SEQUENCE'}),
                    dataIndex: 'sequence',
                    key: 'sequence',
                    render: (text, record, index) =>
                        <div style={{textAlign: 'right'}}
                        >{text}</div>
                },
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_NAME'}), dataIndex: 'name', key: 'name',
                    render: (text, record, index) =>
                        <a target="_blank" href={paths[index]}
                        >{text}
                        </a>
                },

                // {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_TYPE'}), dataIndex: 'type', key: 'type'},
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SIZE'}), dataIndex: 'size', key: 'size',
                    render: (text, record, index) =>
                        <div

                            target="_blank" style={{width: "100px", textAlign: 'right'}}>{this.diskSize(text)}
                        </div>


                },
                // onMouseEnter={() => this._showCartHandler(text, record, index)}
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_FILE_VERSION'}),
                    dataIndex: 'fileVersion',
                    key: 'fileVersion',
                    render: (text, record, index) => {
                        return (
                            <Tooltip title={views[index]} visible={hFlag[index]} autoAdjustOverflow={true}>
                                <div
                                    target="_blank">
                                    {text}
                                </div>
                            </Tooltip>
                        )

                    }


                    // fileVersions.push(text);
                    // <Tooltip title={views[index]}>
                    //     <div
                    //         target="_blank">
                    //         {text}
                    //     </div>
                    // </Tooltip>


                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_CREATE_TIME'}),
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (text, record, index) => {
                        let timeOne = new Date();
                        let year = timeOne.getFullYear();
                        let month = timeOne.getMonth() + 1;
                        let day = timeOne.getDate();
                        let str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN)
                        if (year == text.slice(0, 4) && month == text.slice(4, 6) && day == text.slice(6, 8)) {
                            str = moment(text, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_TIME_COLON)
                        }

                        return (
                            <div>
                                {str}
                            </div>
                        )

                    }


                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) =>
                        this._renderButtonGroup(text, record, index)

                },

            ],
            showQrCode: false,
            selectedRecord: {}
        }
    }

    componentWillMount() {
        let searchCondition = {};
        searchCondition.pageSize = 20;
        searchCondition.deviceCodeFilter = 2;
        searchCondition.paginationFlag = false;
        this.props.dispatch(searchDeviceList(searchCondition, this.state.formatMessage));
    }

    _renderGroup(text, record, index, view) {

        if (view == 1) {
            hFlag[index] = true;
        } else {
            hFlag[index] = false;
        }
        this.setState({showQrCode: this.state.showQrCode})
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record, index)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a> <FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                <Divider type="vertical"/>
                <Popover content={<QRCode value={"http://ecgv3.d9ktech.com/APPDownload.html?path=" + record.path}/>}>
                    <a onClick={() => this.setState({showQrCode: true, selectedRecord: record})}> <Icon type="qrcode"/></a>
                </Popover>
            </div>
        )
    }

    // toFormattedByteSize(sizeL){
    //     var sizeF = 0;
    //     const oneKB = 1024;
    //     const oneMB = oneKB * 1014;
    //     const oneGB = oneMB * 1024;
    //     const unit = "";
    //
    //     if(sizeL >= oneGB){
    //         sizeF = 1f * sizeL / oneGB;
    //         unit = "G";
    //
    //     }else if(sizeL >= oneMB){
    //         sizeF = 1f * sizeL / oneMB;
    //         unit = "M";
    //
    //     }else if(sizeL >= oneKB){
    //         sizeF = 1f * sizeL / oneKB;
    //         unit = "K";
    //     }
    //
    //     return String.format("%.3f " + unit + "B", sizeF);
    // }

    diskSize(num) {
        if (num == 0) return '0 B';
        var k = 1024; //设定基础容量大小
        var sizeStr = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; //容量单位
        var i = 0; //单位下标和次幂
        for (var l = 0; l < 8; l++) {   //因为只有8个单位所以循环八次
            if (num / Math.pow(k, l) < 1) { //判断传入数值 除以 基础大小的次幂 是否小于1，这里小于1 就代表已经当前下标的单位已经不合适了所以跳出循环
                break; //小于1跳出循环
            }
            i = l; //不小于1的话这个单位就合适或者还要大于这个单位 接着循环
        }
        // 例： 900 / Math.pow(1024, 0)  1024的0 次幂 是1 所以只要输入的不小于1 这个最小单位就成立了；
        //     900 / Math.pow(1024, 1)  1024的1次幂 是1024  900/1024 < 1 所以跳出循环 下边的 i = l；就不会执行  所以 i = 0； sizeStr[0] = 'B';
        //     以此类推 直到循环结束 或 条件成立
        return (num / Math.pow(k, i)).toFixed(3) + ' ' + sizeStr[i];  //循环结束 或 条件成立 返回字符
    }


    _handleDelete(record, index) {
        const {content, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;

        let deviceCode;
        let deviceId;
        let fileName = record.name;
        let fileInfo = fileName.replace('.pdf','').split('_');
        deviceCode = fileInfo[fileInfo.length - 1];
        console.log("deviceCode:" + deviceCode);
        for(let device of this.props.devices){
            if(deviceCode === device.code){
                console.log("deviceId:" + device.id);
                deviceId = device.id;
            }
        }

        dispatch(deleteContentFile(record.id, content.id, formatMessage));

        if(deviceId){
            let delRecord = {};
            delRecord.fileId = record.id;
            delRecord.deviceId = deviceId;
            dispatch(deleteDiagnosticReport(delRecord, formatMessage));
        }

        setTimeout(
            () => {
                isFirst = true
                isDelete = true;
                this.setState({showQrCode: this.state.showQrCode})
            },
            500
        );

    }

    _handleShowUpdateModal(record, index) {
        // this.setState({"valueFiles": this.state.valueFiles});
        isFirst = true;
        this.props.dispatch(refreshFile(record));
        this.props.dispatch(toggleDetailModal(true));
        // filess = this.props.files;
        // this.setState({showQrCode: this.state.showQrCode})
    }

    _handleChange(name, value) {

        filess = []
        if (timeFlag) {
            for (let file of files2) {

                if (file.fileVersion.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1 ||
                    file.name.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1 ||
                    file.remarks.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1) {
                    filess.push(file);
                }
            }
        } else {
            for (let file of this.props.files) {

                if (file.fileVersion.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1 ||
                    file.name.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1 ||
                    file.remarks.toLowerCase().indexOf((value.target.value).toLowerCase()) != -1) {
                    filess.push(file);
                }
            }
        }

        this.setState({"valueFiles": filess});
    }

    _onChange(value, dateString, num) {
        console.log("_onChange:", dateString, value, num);
        timeFlag = true;
        switch (num) {
            case 1:
                if (dateString) {
                    minData = (new Date(dateString)).getTime()
                } else {
                    if (maxData == jilu) {
                        timeFlag = false;
                    }
                    timeFlag = false;
                    minData = 0;
                }
                files2 = []
                filess = null;
                for (let file of this.props.files) {
                    const time = moment(file.createTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_SLASH)
                    const timeV = (new Date(time)).getTime();

                    if (timeV > minData && timeV < maxData) {
                        console.log("minData:", minData, "timeV:", timeV, "maxData:", maxData);
                        files2.push(file);
                    }
                }

                this.setState({"valueFiles": files2});
                break

            case 2:
                if (dateString) {
                    maxData = (new Date(dateString)).getTime()
                } else {
                    if (minData == 0) {
                        timeFlag = false;
                    }
                    maxData = Date.now();
                    jilu = maxData;
                }
                files2 = []
                filess = null;
                for (let file of this.props.files) {
                    const time = moment(file.createTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_SLASH)
                    const timeV = (new Date(time)).getTime();
                    if (timeV > minData && timeV < maxData) {
                        files2.push(file);
                    }
                }
                console.log("file2:", files2);
                this.setState({"valueFiles": files2});
                break
        }
    }

    //父组件事件
    btnClick() {
        isFirst = true
        // filess = this.props.files;
        this.setState({showQrCode: this.state.showQrCode})
    }

    componentWillUnmount() {
        this.initFile();
    }

    initFile() {
        isFirst = true
        timeFlag = false;
        filess = []
        files2 = []
    }

    render() {
        const {content} = this.props;
        const {formatMessage} = this.state;
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const yearMonth = moment().format("YYYYMM");
        const self = this;
        const uploadProps = {
            name: 'file',
            multiple: true,
            action: FTPWebService.uploadWebFile + "?renameFlag=false&folder=" + siteCode + "%2Fcontents%2F" + yearMonth,
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            onChange(info) {
                isFirst = true
                console.log(info);
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done' && info.file.response.status === HttpStatus.SUCCESS) {
                    const files = Object.assign([], self.props.files);
                    const file = {
                        id: info.file.uid,
                        name: info.file.name,
                        path: info.file.response.data,
                        size: info.file.size,
                        type: info.file.type
                    };
                    files.push(file);

                    self.props.dispatch(createContentFile(file, content.id, formatMessage));

                    // filess = this.props.files;
                    // self.setState({showQrCode: this.start.showQrCode})
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };
        if ((isFirst && this.props.files.length > 0) || isDelete && this.props.files.length == 0) {
            console.log("数据改变")
            isFirst = false;
            isDelete = false;
            files2 = null;
            filess = this.props.files;
        }

        // console.log("devices:" + JSON.stringify(this.props.devices));
        return (
            <div>
                <ContentFileModal changeBtnClick={() => this.btnClick()}/>
                {this._renderQRCodeModal()}
                <div className={"spacing-v"}>
                    关键字：<input onChange={(data) => {
                    this._handleChange("version", data)
                }}/>
                    <Divider type="vertical"/>
                    {/*<Form.Item*/}
                    {/*    style={{paddingLeft: 29}}*/}
                    {/*    label={formatMessage({id: 'DAS_DEVICE_FIELD_LATEST_USAGE_TIME'})}>*/}
                    {/*    <Form.Item>*/}
                    <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                format={FORMAT_DATE_TIME_SLASH}
                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                onChange={(value, dateString) => {
                                    this._onChange(value, dateString, 1);
                                }}/>
                    --
                    <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                format={FORMAT_DATE_TIME_SLASH}
                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                onChange={(value, dateString) => {
                                    this._onChange(value, dateString, 2);
                                }}/>
                    <Upload.Dragger style={{marginTop: 10}} {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT"}/></p>
                        <p className="ant-upload-hint"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT"}/></p>
                    </Upload.Dragger>
                </div>
                <div>
                    <Table rowKey="id" columns={this.state.columns}
                           dataSource={filess ? filess : files2}
                           scroll={{x: false}}
                           onRow={(record, index) => {
                               return {
                                   onMouseEnter: event => {
                                       this._renderGroup(record.fileVersion, record, index, 1);
                                   }, // 鼠标移入行
                                   onMouseLeave: event => {
                                       this._renderGroup(record.fileVersion, record, index, 2);
                                       // this.setState({hFlags:hFlag})
                                   },
                               };
                           }
                           }
                           pagination={{
                               position: "both", pageSize: 20
                           }}/>

                </div>
            </div>
        );
    }

    _renderQRCodeModal() {
        return (
            <Modal visible={this.state.showQrCode}
                   closable={false}
                   onOk={() => this.setState({showQrCode: false})}
                   onCancel={() => this.setState({showQrCode: false})}
                   footer={null} style={{width: '300px', height: '300px'}}>
                <div className="text-center">
                    <QRCode style={{width: '100%', height: '100%'}}
                            value={"http://ecgv3.d9ktech.com/APPDownload.html?path=" + this.state.selectedRecord.path}/>
                </div>
            </Modal>
        )
    }

}

function mapStateToProps(store) {
    return {
        files: store.ContentFileReducer.files,
        content: store.ContentReducer.content,
        device: store.EcgDeviceReducer.device,
        devices: store.EcgDeviceReducer.devices,
    }
}

export default connect(mapStateToProps)(injectIntl(ContentFileList));