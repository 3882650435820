/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {AnnotationLabel} from "../Enums";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../constants/DateTimeFormats";
import moment from "moment";

export function toggleAnnotationModal(isShow) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_TOGGLE_ANNOTATION_MODAL,
        showAnnotationModal: isShow
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshAnnotation(annotation) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATION,
        annotation: annotation
    }
}

export function refreshAnnotationsMap(annotationsMap) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATIONS_MAP,
        annotationsMap: annotationsMap
    }
}

export function refreshEcgRuler(ecgRuler) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULER,
        ecgRuler: ecgRuler
    }
}

export function refreshEcgRulersMap(ecgRulersMap) {
    return {
        type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULERS_MAP,
        ecgRulersMap: ecgRulersMap
    }
}

export function searchAnnotations(deviceCode, recordDate, historyFileIndex, fileName, annotationsMap, labelRecords, formatMessage, successCallback, medfiltDeWander) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: true});

        const searchCondition = {
            deviceCode: deviceCode,
            recordDate: recordDate,
            historyFileIndex: historyFileIndex
        };
        HttpUtils.post(WebService.searchDeviceFileAnnotation, searchCondition, (response) => {
            // Key: HistoryFileIndex, Value: {Key: PointIndex,Value:Annotation}
            // const annotationsMap = {};
            // 初始化标注存储结构
            let annotationPointMap = annotationsMap[fileName];
            if (!annotationPointMap) {
                annotationPointMap = {};
                annotationsMap[fileName] = annotationPointMap;
            }

            if (response.data.length > 0) {
                for (let annotation of response.data) {
                    annotationPointMap[annotation.pointIndex] = annotation;
                }
            } else {
                console.log(labelRecords);
                // 如果标注文件读取失败，则以当前心电文件的labelRecords数据作为标注数据
                // labelRecords 是设备算法获得的高点位置，自动设定上Normal标记
                for (let i = 0; i < labelRecords.length; i++) {
                    const labelRecord = labelRecords[i];

                    // 如果是已经存在的，则已存在的优先级高，所以需要判断不存在的话，取默认值
                    if (!annotationPointMap[labelRecord.topPoint]) {
                        const annotation = {
                            id: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE) + labelRecord.topPoint,
                            // deviceCode: this.state.deviceCode,
                            // recordDate: this.state.recordDate,
                            // fileName: this._getEcgFileName(),
                            pointIndex: labelRecord.topPoint,
                            annotation: AnnotationLabel.Enum.NORMAL
                        };

                        // 中值滤波有中值滤波后波形有N/2的延时，N是中值滤波窗口大小，
                        // N是奇数，准确地说，延时是(N-1)/2，所以相应的高点位置，也要做同样的延迟处理
                        console.log(medfiltDeWander);
                        if (medfiltDeWander) {
                            console.log(medfiltDeWander.nFilterSize2);
                            annotation.pointIndex = annotation.pointIndex + medfiltDeWander.nPrevWinLen
                        }

                        annotationPointMap[annotation.pointIndex] = annotation;
                    }
                }
            }
            console.log(annotationsMap);
            dispatch(refreshAnnotationsMap(annotationsMap));

            successCallback && successCallback.call(this, annotationPointMap);

            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getAnnotation(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getDeviceFileAnnotation, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshAnnotation(response.data));
            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function saveAnnotations(annotations, formatMessage, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: true});

        HttpUtils.post(WebService.saveDeviceFileAnnotations, annotations, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_SAVE_SUCCESS"}));
            successCallback && successCallback.call(this, response.data);
            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}