/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Icon} from "antd";

import {searchBindersSum} from "../../../actions/BinderAction";
import {RoutePath} from "../../../../../constants/RoutePath";

class DevicesSum extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(searchBindersSum(this.state.formatMessage));
    }

    _handleShowDetailType(showDetailType) {
        console.log(showDetailType);
        // this.props.dispatch(changeShowDetailType(showDetailType));
    }

    render() {
        return (
            <div className="small-box yellow">
                <div className="inner">
                    <h3>{this.props.bindersSum}</h3>
                    <p><FormattedMessage id="DAS_BINDER_FIELD_BINDERS_SUM"/></p>
                </div>
                <div className="icon">
                    <Icon type="user" theme="outlined"/>
                </div>
                <Link to={RoutePath.DAS_BINDER_LIST.path} className="small-box-footer"
                      onClick={() => this._handleShowDetailType()}>
                    <FormattedMessage id="COMMON_BUTTON_DETAIL"/> <Icon type="right-circle"/>
                </Link>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        bindersSum: store.BinderReducer.bindersSum
    }
}

export default connect(mapStateToProps)(injectIntl(DevicesSum));