import React, {Component} from 'react';
import {browserHistory, hashHistory, Router, Route, IndexRedirect} from 'react-router';
//import { createHashHistory } from 'history';

import App from './App.js';

//import SignInContainer from './containers/SignInContainer';
import SiderLayout from './layout/SiderLayout';


import ORGOrganizationList from "./modules/ORG/views/Organization/List/Index";
import ORGEmployeeList from './modules/ORG/views/Employee/List/Index';

// 站点管理
import CmsSites from './modules/CMS/views/Sites/Index';
// 栏目管理
import CmsChannels from './modules/CMS/views/Channels/Index';
// 内容管理
import CmsContentList from './modules/CMS/views/Contents/List/Index';
import CmsLatestVersionDownload from './modules/CMS/views/Download/Index';
import CmsContentDrafts from './modules/CMS/views/Contents/Drafts/Index';
import CmsContentCreate from "./modules/CMS/views/Contents/Create/Index";
import CmsContentUpdate from "./modules/CMS/views/Contents/Update/Index";
import CmsContentUpdate2 from "./modules/CMS/views/Contents/Update/Index2";
import CmsContentUpdate3 from "./modules/CMS/views/Contents/Update/Index3";

// 监控
import MonitorRegion from './modules/DAS/views/Dashboard/Monitor/Index';

import Dashboard from './views/Dashboard/IndexView';
import DasDashboardStatistics from './modules/DAS/views/Dashboard/Statistics/Index';
// import DasDashboardMonitor from './modules/DAS/views/Dashboard/Monitor/Index';
import DasDashboardMonitorNCOV from "./modules/DAS/views/Dashboard/NCoV/Publish/Index";
import DasDashboardMonitorNCOVImport from "./modules/DAS/views/Dashboard/NCoV/Import/Index";
import DasDashboardDistrictMap from "./modules/District/views/Map/Index";

import DasDeviceMonitor from './modules/DAS/views/Device/List/Index';
import DasDeviceList from './modules/DAS/views/Device/Monitor/Index';
import DasDeviceDetailList from './modules/DAS/views/Device/Detail/Index';
import DasDeviceDownload from './modules/DAS/views/Device/Download/Index';
import DasDeviceDataDownload from './modules/DAS/views/Device/Download/Data/Index';

// 独立的实时心电图和历史心电图画面
import EcgRealTimePage from './modules/DAS/views/ECG/RealTimePage/Index';
import EcgHistoryCalendarPage from './modules/DAS/views/ECG/HistoryPage/Calendar/Index';
import EcgHistoryDayPage from './modules/DAS/views/ECG/HistoryPage/Day/Index';
import EcgLorenzPage from './modules/DAS/views/ECG/History/Lorenz/Index';
import EcgLorenzSamplePage from './modules/DAS/views/ECG/History/Lorenz/Index2'

import DasBinderList from './modules/DAS/views/Binder/Binders/Index';
import DasBinderTerminalList from './modules/DAS/views/Binder/Terminal/Index';
import DasOneselfDeviceList from './modules/DAS/views/Oneself/Index';
import EcgGuarderList from './modules/DAS/views/Guarder/List/Index';
import DasMobileList from './modules/DAS/views/MOBILE/Index';
import DasBinderData from './modules/DAS/views/Binder/Data/Index';
import DasBinderDataPersonal from './modules/DAS/views/ECG/MyData/Index';
import DasECGRealTime from "./modules/DAS/views/ECG/RealTime/Index";
import DasECGEcgFiles from "./modules/DAS/views/ECG/History/List/Index";
import EcgFileCalendar from "./modules/DAS/views/ECG/History/Calendar/Index";
import EcgHistoryDay from './modules/DAS/views/ECG/History/Day/Index';
import DasHistoryLightWave from "./modules/DAS/views/ECG/History/LightWave/Index";
import EcgUserList from './modules/DAS/views/User/Index';
import EcgCaseList from './modules/DAS/views/Case/List/Index';

import AccountLogin from './modules/Account/views/User/Login/Index';
import SystemRedis from './modules/DAS/views/System/Redis/Index';

import DoctorRedis from './modules/DAS/views/Doc/Diagnostic/Index'
import ImageRedis from './modules/DAS/views/Doc/Text/index'
import ImageRedis2 from './modules/DAS/views/Doc/Text2/EChartsModel2'
import ImageRedis3 from './modules/DAS/views/Doc/Text2/index'

// 标注工具
import EcgAnnotationTool from "./modules/DAS/views/Annotation/Index";

import {RoutePath, RoutePathKey} from './constants/RoutePath';
import {ECG_HISTORY_CALENDAR, ECG_HISTORY_DAY, ECG_REAL_TIME} from "./modules/DAS/RoutePath";
import {WECHAT_AUTH_BIND_LOGIN} from "./modules/WeChat/RoutePath";

//const appHistory = useRouterHistory(createHashHistory)({queryKey: false});

// 微信
import WeChatAuthBindLogin from "./modules/WeChat/views/Auth/BindLogin/Index";

export default class Root extends Component {
    render() {
        return (
            <Router history={hashHistory}>
                <Route path="/" component={App}>
                    <Route path={RoutePath.REST_NCOV.path} component={DasDashboardMonitorNCOV}/>
                    <IndexRedirect to={RoutePath.DAS_DEVICE_LIST.path}/>
                    <Route path={WECHAT_AUTH_BIND_LOGIN} component={WeChatAuthBindLogin}/>
                    <Route component={SiderLayout}>
                        <Route path={RoutePath.ORG_ORGANIZATION_LIST.path} component={ORGOrganizationList}/>
                        <Route path={RoutePath.ORG_EMPLOYEE_LIST.path} component={ORGEmployeeList}/>

                        <Route path={RoutePath.CMS_SITE_LIST.path} component={CmsSites}/>
                        <Route path={RoutePath.CMS_CHANNEL_LIST.path} component={CmsChannels}/>
                        <Route path={RoutePath.CMS_SOFTWARE_VERSION_MANAGEMENT.path} component={CmsContentList}/>
                        <Route path={RoutePath.CMS_SOFTWARE_LATEST_VERSION_DOWNLOAD.path}
                               component={CmsLatestVersionDownload}/>
                        <Route path={RoutePath.CMS_CONTENT_DRAFTS.path} component={CmsContentDrafts}/>
                        <Route path={RoutePath.CMS_CONTENT_DRAFTS.path} component={CmsContentDrafts}/>
                        <Route path={RoutePath.CMS_CONTENT_CREATE.path} component={CmsContentCreate}/>
                        <Route path={RoutePath.CMS_CONTENT_UPDATE.path} component={CmsContentUpdate}/>

                        <Route path={RoutePath.CMS_CONTENT_UPDATE2.path} component={CmsContentUpdate2}/>
                        <Route path={RoutePath.CMS_CONTENT_UPDATE3.path} component={CmsContentUpdate3}/>
                        {/*<Route path={RoutePath.CMS_CONTENT_DOWNLOAD.path} component={CmsContentUpdate2}/>*/}

                        <Route path={RoutePath.MONITOR_REGION.path} component={MonitorRegion}/>

                        <Route path={RoutePath.DAS_DASHBOARD.url} component={Dashboard}/>
                        <Route path={RoutePath.DAS_DASHBOARD_STATISTICS.path} component={DasDashboardStatistics}/>
                        {/*<Route path={RoutePath.DAS_DASHBOARD_MONITOR.path} component={DasDashboardMonitor}/>*/}
                        <Route path={RoutePath.REST_NCOV_IMPORT.path}
                               component={DasDashboardMonitorNCOVImport}/>
                        <Route path={RoutePath.DAS_DASHBOARD_DISTRICT_MAP.path} component={DasDashboardDistrictMap}/>

                        <Route path={RoutePath.DAS_DEVICE_LIST.path} component={DasDeviceList}/>
                        <Route path={RoutePath.DAS_DEVICE_DETAIL.path} component={DasDeviceDetailList}/>
                        <Route path={RoutePath.DAS_DEVICE_MONITOR.path} component={DasDeviceMonitor}/>
                        <Route path={RoutePath.DAS_DEVICE_DOWNLOAD.path} component={DasDeviceDownload}/>
                        <Route path={RoutePath.DAS_DEVICE_DATA_SERVER_DOWNLOAD.path} component={DasDeviceDataDownload}/>
                        {/*<Route path={RoutePath.ECG_HISTORY_QUERY.path} component={EcgHistoryQuery}/>*/}
                        <Route path={RoutePath.ECG_HISTORY_CALENDAR.path} component={EcgFileCalendar}/>
                        <Route path={RoutePath.ECG_HISTORY_DAY.path} component={EcgHistoryDay}/>

                        <Route path={RoutePath.DAS_BINDER_LIST.path} component={DasBinderList}/>
                        <Route path={RoutePath.DAS_BINDER_TERMINAL_LIST.path} component={DasBinderTerminalList}/>
                        <Route path={RoutePath.DAS_BINDER_GUARDIAN.path} component={EcgGuarderList}/>
                        <Route path={RoutePath.DAS_MQTT_LIST.path} component={DasMobileList}/>
                        <Route path={RoutePath.DAS_BINDER_DATA.path} component={DasBinderData}/>
                        <Route path={RoutePath.DAS_ECG_MINE.path} component={DasBinderDataPersonal}/>

                        <Route path={RoutePath.DAS_BINDER_ONESELF.path} component={DasOneselfDeviceList}/>

                        <Route path={RoutePath.DAS_BINDER_USER.path} component={EcgUserList}/>

                        <Route path={RoutePath.DAS_ECG_LORENZ.path} component={EcgLorenzPage}/>
                        <Route path={RoutePath.DAS_ECG_LORENZ_SAMPLE.path} component={EcgLorenzSamplePage}/>
                        <Route path={RoutePath.DAS_ECG_REAL_TIME.path} component={DasECGRealTime}/>
                        <Route path={RoutePath.DAS_ECG_HISTORY.path} component={DasECGEcgFiles}/>
                        <Route path={RoutePath.ECG_FILE_CALENDAR.path} component={EcgFileCalendar}/>
                        <Route path={RoutePath.DAS_ECG_HISTORY_LIGHTWAVE.path} component={DasHistoryLightWave}/>

                        <Route path={RoutePath.ECG_SYSTEM_REDIS.path} component={SystemRedis}/>

                        {/*医生端*/}
                        <Route path={RoutePath.DOCTOR_DIAGNOSTIC_RECORD.path} component={DoctorRedis}/>
                        <Route path={RoutePath.T_P_Y_S.path} component={ImageRedis}/>
                        <Route path={RoutePath.T_P_Y_S2.path} component={ImageRedis2}/>
                        <Route path={RoutePath.T_P_Y_S3.path} component={ImageRedis3}/>

                        {/*病例管理*/}
                        <Route path={RoutePath.ECG_CASE_LIST.path} component={EcgCaseList}/>

                        {/*标注工具*/}
                        <Route path={RoutePath.ECG_ANNOTATION_TOOL.path} component={EcgAnnotationTool}/>
                    </Route>

                    <Route path={RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path} component={AccountLogin}/>

                    {/*外部网页调用实时心电图和历史心电图*/}
                    <Route path={ECG_REAL_TIME} component={EcgRealTimePage}/>
                    <Route path={ECG_HISTORY_CALENDAR} component={EcgHistoryCalendarPage}/>
                    <Route path={ECG_HISTORY_DAY} component={EcgHistoryDayPage}/>
                </Route>
            </Router>
        )
    }
};
