/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    deviceLogs: [],
    searchCondition: {},
    isLoading: false
};

const LogDataServerReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_LOG_DEVICE_SERVER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_LOG_DEVICE_SERVER_SEARCH:
            return Object.assign({}, state, {
                deviceLogs: action.deviceLogs
            });
        case ActionTypes.ECG_LOG_DEVICE_SERVER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        default:
            return state
    }
};

export default LogDataServerReducer;