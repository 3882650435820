/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Button, Slider, Popover,  Icon, Descriptions, Switch} from 'antd';

import * as WebService from "../../../../WebService";
import HttpUtils from "../../../../../../utils/HttpUtils";

import {ChartSpeed, VoltDiv} from "../../../../Enums";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";

let ecgDataSegment = {};
let ecgFile = {};

let pointIndex = 0;
let ecgTextY = [];

// 一个导联的svg图高度
let winH;
// 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
let winW;

// 采样率
// 标准值：500samples/s
// 可选值：250samples/s，1000samples/s
const sampRate = 500;

// 下采样率，默认值1，从设备中获取后改变
let downRatio = 1;

// 通过采样率和下采样率的关系，算出每秒实际多少个点
let dataRate = sampRate / downRatio;

// 画布高度
// const height = 1200;
// 采集频率，每秒500次
const frequency = 500;
// 显示的时间（秒）
let duration = 10;

// 格子尺度【GridScale】
// 	5个小格构成一个大格
// 标准值：1mm/小格
// 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
// 	例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
// 此处默认以大格子作为显示单位
const gridScale = 5;

// 格子的缩放
const gridRatio = 1;

// 走纸速度, 标准值：25mm/s
// 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
const chartSpeed = 25;

// 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
const gridPointNum = dataRate / (chartSpeed / gridScale);
// console.log("gridPointNum:" + gridPointNum);

// 播放的走点数, 每次更新的数据点的数量
// 对应的文档上的参数名是RfsNum
let playStep = 5;

// 转换成一次刷新走多少个点，按500的采样率，则100个点代表0.2秒=是5mm，就是一格的描画点数
// 10ms刷新一次，则刷新100次是1s，那么刷100次则需要行走5个格子，则刷新一次走0.05个格子，也就是说是5个点
let playSpeed = 1000 / (dataRate / playStep);

let gc;
// // CH1（呼吸通路）
// // Gain=2^24*4*1/5000
// const gain1 = 13421.7728;
//
// // CH2~CH8
// // Gain=2^24*6*1/5000=20132.6592 1/mV
// const gain = 20132.6592;

// 计算出横向的格子数
// 一个格子是0.2S(0.04S的小格子不显示)
// 如果是10S是50个格子
const unit = 0.2;


// 算出横向格子数
let xCellCount;
// 每个导联占据纵向格子数
const yCellCount = 6;

// 算出每个格子的宽度
let cellWidth;

// 绘图的缓冲区
// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
let drawBufferMap = {};

// 8个导联的数据
let numLeads = 8;

// 窗口刷新率，每40ms刷新一次走纸
const winFps = 40;
//每次更新的数据点的数量
// const baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
let rfsNum = (sampRate / downRatio) / (1000 / winFps);

// 定标一个div为100mm宽度，算出相对应的pixel
const unitWidthMill = 1;

// 绘图窗口的Y轴Margin
const winMarginX = 40;
const winMarginY = 20;

// SVG 线的背景颜色
const lineColors = ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E'];

// 一屏幕能绘画的点数量
let screenPointSum;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            sliderMax: 100,
            sliderValue: 0,
            showControl: true,
            diagnosisDate: "",
            sliderMarks: {},
            backgroundDisplay: true,
            // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // 一个格子是5mm，所以一个格子代表0.5mv
            voltDiv: 10,
            // 走纸倍数
            chartSpeedTimes: 1,
            combinePaint: false
        }
    }

    componentWillMount() {
    }

    _handleResize = () => {
        this._calcParams();
        this._handleChangeBackgroundDisplay(this.state.backgroundDisplay);
    };

    _calcParams(combinePaintFlag) {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        // 定标mm和px的换算
        const unitWidth = document.getElementById("unit_width");
        console.log("Width:" + unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        console.log("Height" + unitWidth.style.height, unitWidth.clientHeight, unitWidth.offsetHeight, unitWidth.scrollHeight);

        winW = ecgViewWrapNode.clientWidth / (unitWidth.clientWidth / unitWidthMill);
        console.log("winW " + winW + " mm");

        // 每个格子的宽度
        cellWidth = gridScale * (unitWidth.clientWidth / unitWidthMill);
        console.log("cellWidth " + cellWidth + " px");

        // 格子的数量，向下取整
        xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        console.log("xCellCount " + xCellCount);

        // 计算出一屏的点总数
        screenPointSum = gridPointNum * xCellCount;
        console.log("一屏的描画点数：", screenPointSum);

        if (combinePaintFlag) {
            // 合并在一张画板上，则需要按导联数计算高度
            winH = yCellCount * numLeads * cellWidth + 50;
        } else {
            winH = yCellCount * cellWidth + 50;
        }

        // // 纵轴总共多少mv
        // const winDataH = (gridScale / this.state.voltDiv) * yCellCount;
        // const scrH = unitWidth.clientHeight;
        // const scrSizeH = unitWidthMill;
        //
        // gf_ch1 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch1) / winH);
        // gf_ch2_8 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch2_8) / winH);
        //
        // // console.log("gf_ch1 " + gf_ch1);
        // // console.log("gf_ch2_8 " + gf_ch2_8);
        //
        // dg_ch1 = gc_ch1 * gf_ch1;
        // dg_che2_8 = gc_ch2_8 * gc_ch2_8;

        ecgTextY = [];
        pointIndex = 0;
    }

    componentDidMount() {

        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        this._calcParams();

        let requestUrl = WebService.getHistoryEcgFile.replace("{id}", this.props.params.id);
        const diagnosisTime = HttpUtils.getQueryString("diagnosisTime");
        if (diagnosisTime) {
            requestUrl = HttpUtils.addQueryString(requestUrl, "diagnosisTime", diagnosisTime);
        }
        this._getEcgFile(requestUrl);
    }

    _getEcgFile(requestUrl) {
        HttpUtils.get(requestUrl, (response) => {
            console.log(response.data);
            // signals = response.data.signals;
            ecgDataSegment = response.data.historyEcgFile;
            ecgFile = response.data;
            numLeads = response.data.historyEcgFile.polymRegions[0].channelNum;
            drawBufferMap = response.data.historyEcgFile.polymRegions[0].ecgData;
            gc = response.data.historyEcgFile.polymRegions[0].gc;
            // 计算时间轴的最大点数
            this.setState({sliderMax: drawBufferMap[0].length});
            // for (let i = 0; i < numLeads; i++) {
            //
            //     // 初始化固定长度的绘画缓冲区
            //     drawBufferMap[i] = new Array(screenPointSum);
            //     for (let j = 0; j < drawBufferMap[i].length; j++) {
            //         drawBufferMap[i][j] = new ECGPoint();
            //     }
            // }

            // for (let i = 0; i < signals.length; i++) {
            // let ecgViewGroups = "";
            // for (let i = 0; i < ecgDataSegment.fileData.leadCount; i++) {
            //
            //     if (i === 0) {
            //         // 计算时间轴的最大点数
            //         this.setState({sliderMax: ecgDataSegment.fileData.ecgData[0].length})
            //     }
            //
            //     // 创建SVG容器标签
            //     const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
            //     // 创建SVG的Group标签
            //     const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";
            //
            //     const ecgViewText = this._getECGViewText(i);
            //
            //     // 创建心电图背景图
            //     const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
            //     const ecgViewBackgroundEnd = "</g>";
            //     const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
            //
            //     // 创建心电图波形
            //     const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
            //     const ecgViewLightWaveEnd = "</g>";
            //     // 创建心电图波形
            //     const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;
            //
            //     const ecgViewGroupEnd = "</g>";
            //     const ecgViewEnd = "</svg>";
            //
            //     ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
            // }
            // const auxLineGraphic = "<g id='auxLine'><path id='auxLinePath' stroke='rgb(0,0,255)' fill='blue' strokeWidth='0.5' d=''></path></g>";
            //
            // ecgViewWrapNode.innerHTML = auxLineGraphic + ecgViewGroups;

            // // 添加鼠标悬浮事件监听，以获取辅助蓝线的描画坐标
            // const ecg_svg = document.getElementById("ecg_svg");
            // ecg_svg.onclick = function (e) {
            //     const auxLinePath = document.getElementById("auxLinePath");
            //     if (e.offsetX < transformX) {
            //         auxLinePath.setAttribute("d", "");
            //     } else {
            //         auxLinePath.setAttribute("d", "M" + e.offsetX + ",50 L" + e.offsetX + "," + heightPerLead * ecgDataSegment.leadCount); // = "<path stroke='rgb(0,0,255)' fill='blue' strokeWidth='0.5' d='M" + e.screenX + ",50 L" + e.screenX + "," + heightPerLead * ecgDataSegment.leadCount + "'></path>";
            //     }
            // };
            this._drawECG(this.state.combinePaint);
            this._handlePlayRight();
        }, (response) => {
            console.log(response);
        }, this.state.formatMessage)
    }

    _drawECG(combinePaintFlag) {
        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        ecgViewWrapNode.innerHTML = "";
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);
        if (combinePaintFlag) {
            // 创建SVG容器标签
            const ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";

            let ecgViewContent = "";
            for (let i = 0; i < numLeads; i++) {

                const offsetY = i * yCellCount * cellWidth;

                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

                const ecgViewText = this._getECGViewText(i);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                ecgViewContent = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;
            }

            const ecgViewEnd = "</svg>";
            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
        } else {
            for (let i = 0; i < numLeads; i++) {

                // 初始化固定长度的绘画缓冲区
                // drawBufferMap[i] = new Array(screenPointSum);
                // for (let j = 0; j < drawBufferMap[i].length; j++) {
                //     drawBufferMap[i][j] = new ECGPoint();
                // }

                // 创建SVG容器标签
                const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // 创建SVG的Group标签
                const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";

                const ecgViewText = this._getECGViewText(i);

                // 创建心电图背景图
                const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                const ecgViewBackgroundEnd = "</g>";
                const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

                // 创建心电图波形
                const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                const ecgViewLightWaveEnd = "</g>";
                // 创建心电图波形
                const ecgViewLightWave = ecgViewLightWaveStart + this._getECGViewLightWave(i) + ecgViewLightWaveEnd;

                const ecgViewGroupEnd = "</g>";
                const ecgViewEnd = "</svg>";

                ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalPlay);
        window.removeEventListener('resize', this._handleResize);
    }

    onPlaySliderChange(value) {
        this.setState({playFlag: false, playDirection: "", sliderValue: value});
        clearInterval(this.intervalPlay);
        pointIndex = value;
        console.log(pointIndex);
        this._refreshECGView();
    }

    onDurationSliderChange(value) {
        // duration = value;
        // // 算出横向格子数
        // xCellCount = duration / unit;
        // // 算出每个格子的宽度
        // cellWidth = width / xCellCount;
        // // 每个导联占据的高度
        // heightPerLead = yCellCount * cellWidth;
        // this._refreshECGView();
    }

    onSpeedSliderChange(value) {
        rfsNum = value;
    }

    _handleEcgSelectChange(value) {

    }


    _handleChangeBackgroundDisplay(value) {
        this.setState({backgroundDisplay: value});
        for (let i = 0; i < numLeads; i++) {
            if (value) {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = this._getECGViewBackground();

            } else {

                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = "";
            }
        }
    }

    render() {
        return (
            <div style={{padding: '5px', width: '100%', height: '100%'}}>
                <div id="unit_width" style={{
                    width: unitWidthMill + 'mm',
                    height: unitWidthMill + 'mm',
                    position: 'absolute',
                    zIndex: -1
                }}>

                </div>

                <div>
                    <div>
                        {/*<Button icon={"fast-backward"} className="spacing-h"*/}
                        {/*onClick={() => this._handlePlayToStart()}/>*/}
                        <Button icon={"step-backward"} className="spacing-h"
                                onClick={() => this._handlePlayToPrePage()}/>
                        <Button
                            icon={(this.state.playDirection === "backward") ? "pause" : "caret-left"}
                            className="spacing-h" onClick={() => this._handlePlayLeft()}/>
                        <Button
                            icon={(this.state.playDirection === "forward") ? "pause" : "caret-right"}
                            className="spacing-h" onClick={() => this._handlePlayRight()}/>
                        <Button icon={"step-forward"} className="spacing-h"
                                onClick={() => this._handlePlayToNextPage()}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={ChartSpeed.List} value={this.state.chartSpeedTimes}
                            onChange={(value) => {
                                this.setState({chartSpeedTimes: value});
                            }}/>
                        <EnumItemSelect
                            style={{width: '100px', marginRight: '10px'}} allowClear={false}
                            data={VoltDiv.List} value={this.state.voltDiv}
                            onChange={(value) => {
                                this.setState({voltDiv: value});
                            }}/>
                        <Switch checkedChildren="显示格子" unCheckedChildren="隐藏格子" className={"spacing-h"}
                                checked={this.state.backgroundDisplay}
                                onChange={(value) => this._handleChangeBackgroundDisplay(value)}/>

                        <Popover content={this._renderInfo()} title="心电数据包信息">
                            <Icon type="question-circle"/>
                        </Popover>
                        {/*<Button icon={"fast-forward"} className="spacing-h"*/}
                        {/*onClick={() => this._handlePlayToEnd()}/>*/}
                    </div>
                    <Slider value={this.state.sliderValue}
                            onChange={(value) => this.onPlaySliderChange(value)}
                            max={this.state.sliderMax}/>
                </div>
                <div id="ecg_view_wrap" style={{width: '100%', height: '100%', paddingTop: '10px'}}>
                </div>
            </div>
        );
    }

    _renderInfo() {
        return (
            <Descriptions title={ecgFile.filePath}>
                <Descriptions.Item label="用户姓名">{ecgFile.binder && ecgFile.binder.userName}</Descriptions.Item>
                <Descriptions.Item label="用户手机号">{ecgFile.binder && ecgFile.binder.userMobile}</Descriptions.Item>
                <Descriptions.Item label="设备编号">{ecgFile.device && ecgFile.device.code}</Descriptions.Item>
                <Descriptions.Item
                    label="网络协议版本号">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.protocolVersion}</Descriptions.Item>
                <Descriptions.Item
                    label="数据来源类型">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.sourceType}</Descriptions.Item>
                <Descriptions.Item
                    label="目标类型">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.destType}</Descriptions.Item>
                <Descriptions.Item
                    label="源设备号">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.deviceID}</Descriptions.Item>
                <Descriptions.Item
                    label="包长度">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.packetLength}</Descriptions.Item>
                <Descriptions.Item
                    label="包类型">{ecgDataSegment.tcpHeader && ecgDataSegment.tcpHeader.packetType}</Descriptions.Item>
                <Descriptions.Item
                    label="总文件长度">{ecgDataSegment.fileData && ecgDataSegment.fileData.totalSize}</Descriptions.Item>
                <Descriptions.Item
                    label="心电数据长度">{ecgDataSegment.fileData && ecgDataSegment.fileData.dataSize}</Descriptions.Item>
                <Descriptions.Item
                    label="文件编号">{ecgDataSegment.packetHeader && ecgDataSegment.packetHeader.fileIndex}</Descriptions.Item>
                <Descriptions.Item
                    label="文件长度">{ecgDataSegment.packetHeader && ecgDataSegment.packetHeader.fileLength}</Descriptions.Item>
                <Descriptions.Item
                    label="qrs位置信息的长度">{ecgDataSegment.fileData && ecgDataSegment.fileData.qrsSize}</Descriptions.Item>
                <Descriptions.Item
                    label="导联个数">{ecgDataSegment.fileData && ecgDataSegment.fileData.leadCount}</Descriptions.Item>
                <Descriptions.Item
                    label="心电样本个数">{ecgDataSegment.fileData && ecgDataSegment.fileData.sampleCount}</Descriptions.Item>
                <Descriptions.Item
                    label="数据精度">{ecgDataSegment.fileData && ecgDataSegment.fileData.resolution}</Descriptions.Item>
                <Descriptions.Item
                    label="诊断">{ecgDataSegment.fileData && ecgDataSegment.fileData.diagnosis}</Descriptions.Item>
            </Descriptions>
        )
    }

    _handlePlayToStart() {
        this.setState({playFlag: false, playDirection: ""});
        clearInterval(this.intervalPlay);

        pointIndex = 0;

        this.setState({sliderValue: pointIndex});
        this._refreshECGView();
    }

    _handlePlayToEnd() {
        this.setState({playFlag: false, playDirection: ""});
        clearInterval(this.intervalPlay);

        pointIndex = drawBufferMap[0].length;

        this.setState({sliderValue: pointIndex});
        this._refreshECGView();

        // const calcWidthNodes = document.getElementsByClassName("calc_width");
        // for (let calcWidthNode of calcWidthNodes) {
        //     console.log(calcWidthNode.style.width, calcWidthNode.clientWidth + 'px');
        // }
    }

    _handlePlayToPrePage() {
        this.setState({playFlag: false, playDirection: ""});
        clearInterval(this.intervalPlay);

        pointIndex = pointIndex - frequency * duration;
        // 控制最小index不能小于0
        if (pointIndex < 0) {
            pointIndex = 0;
        }

        this.setState({sliderValue: pointIndex});
        this._refreshECGView();
    }

    _handlePlayToNextPage() {
        this.setState({playFlag: false, playDirection: ""});
        clearInterval(this.intervalPlay);

        pointIndex = pointIndex + frequency * duration;

        // 控制最大index不能超过心电图总数据量
        if (pointIndex + frequency * duration > drawBufferMap[0].length) {
            pointIndex = drawBufferMap[0].length - frequency * duration;
        }

        this.setState({sliderValue: pointIndex});
        this._refreshECGView();
    }

    _handlePlayLeft() {
        const newPlayFlag = !this.state.playFlag;

        if (newPlayFlag) {
            this.setState({playFlag: newPlayFlag, playDirection: "backward"});
            this.intervalPlay = setInterval(() => {
                // 控制最小index不能小于0
                pointIndex = pointIndex - rfsNum * this.state.chartSpeedTimes;
                if (pointIndex < 0) {
                    pointIndex = 0;
                    this.setState({playFlag: false, playDirection: ""});
                    clearInterval(this.intervalPlay);
                } else {
                    this.setState({sliderValue: pointIndex});

                    this._refreshECGView();
                }
            }, winFps);
        } else {
            this.setState({playFlag: newPlayFlag, playDirection: ""});
            clearInterval(this.intervalPlay);
        }
    }

    _handlePlayRight() {

        const newPlayFlag = !this.state.playFlag;

        if (newPlayFlag) {
            this.setState({playFlag: newPlayFlag, playDirection: "forward"});
            this.intervalPlay = setInterval(() => {
                pointIndex = pointIndex + rfsNum * this.state.chartSpeedTimes;
                // 控制最大index不能超过心电图总数据量
                if (pointIndex >= drawBufferMap[0].length) {
                    pointIndex = 0;
                    this.setState({playFlag: false, playDirection: ""});
                    clearInterval(this.intervalPlay);

                    // 播放完毕，则获取下一个心电图，继续播放
                    let requestUrl = WebService.getHistoryEcgFile.replace("{id}", ecgFile.id);
                    const diagnosisTime = HttpUtils.getQueryString("diagnosisTime");
                    if (diagnosisTime) {
                        requestUrl = HttpUtils.addQueryString(requestUrl, "diagnosisTime", diagnosisTime);
                    }
                    requestUrl = HttpUtils.addQueryString(requestUrl, "nextFlag", 'true');
                    this._getEcgFile(requestUrl);
                    // HttpUtils.get(requestUrl, (response) => {
                    //     console.log(response);
                    //     ecgDataSegment = response.data.historyEcgFiles && response.data.historyEcgFiles[0];
                    //     ecgFile = response.data;
                    //     this._handlePlayRight();
                    // }, (response) => {
                    //     console.log(response);
                    // }, this.state.formatMessage)
                } else {
                    this.setState({sliderValue: pointIndex});
                    this._refreshECGView();
                }

            }, winFps);
        } else {
            this.setState({playFlag: newPlayFlag, playDirection: ""});
            clearInterval(this.intervalPlay);
        }
    }

    _refreshECGView() {
        for (let i = 0; i < numLeads; i++) {
            const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);

            // 创建心电图波形
            const ecgViewLightWave = this._getECGViewLightWave(i);

            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }
    }

    _getECGViewText(index) {
        const centerY = winH / 2;
        // 画导联的名称
        const result = "<text x=0 y=" + centerY + ">CH-" + (index + 1) + "</text>";
        return result;
    }

    _getECGViewBackground() {

        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        // 画原点
        const zeroPointY = (yCellCount - 3) * cellWidth + winMarginY;
        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";


        return result + zeroRect;
    }

    _getECGViewLightWave(index) {
        // // 计算出横向的格子数
        // // 一个格子是0.2S(0.04S的小格子不显示)
        // // 如果是10S是50个格子
        // const unit = 0.2;
        // // 算出横向格子数
        // const xCellCount = duration / unit;
        // // 算出每个格子的宽度
        // const cellWidth = width / xCellCount;
        const cellHeight = cellWidth;
        // console.log("Cell Width:" + cellWidth);
        //
        // // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        const pointSpace = cellWidth / gridPointNum;

        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";

        let length = gridPointNum * xCellCount;

        if (length + pointIndex > drawBufferMap[index].length) {
            length = drawBufferMap[index].length;
        }

        const points = drawBufferMap[index].slice(pointIndex, length);

        // if (index === 0) {
        //
        //     console.log("pointIndex:" + pointIndex);
        //
        //     console.log("length:" + length);
        //     console.log(points);
        // }

        let x = 0;

        for (let i = 0; i < points.length; i++) {

            // // 取出诊断时间
            // if (Number.isInteger(i / ecgDataSegment.sampleCount)) {
            //     const time = ecgDataSegment.time[i / ecgDataSegment.sampleCount];
            //     if (time) {
            //         const dateTime = moment(time, FORMAT_DATE_TIME_SIMPLE);
            //         // 获得当前的X坐标
            //         timeText.push({
            //             x: (i - pointIndex) * pointSpace,
            //             date: dateTime.format(FORMAT_DATE_SLASH),
            //             time: dateTime.format(FORMAT_TIME_COLON)
            //         });
            //     }
            // }

            // // 超出了长度，则不处理
            // if (i + pointIndex >= ecgDataSegment.data[index].length) {
            //     break;
            // }

            let preY = 0;
            if (i > 0) {
                preY = points[i - 1];
            }
            let y = points[i];

            // if (index === 0 && i < 10) {
            //     console.log("Original point:" + preY, y);
            // }

            preY = preY / gc;
            y = y / gc;
            // if (i < 10) {
            //     console.log("Original point / GC:" + preY, y);
            // }

            preY = preY / (1 / (this.state.voltDiv / gridScale));
            y = y / (1 / (this.state.voltDiv / gridScale));

            preY = preY * cellHeight;
            y = y * cellHeight;

            // if (i < 10) {
            //     console.log("Original point / GC to mv:" + preY, y);
            // }

            // 定位第一个点
            // 如果是正值，则需要以zeroPoint - 该值，
            // 如果是负值，则需要以zeroPoint + 该值的绝对值
            if (y > 0) {
                y = zeroPoint - y;
            } else {
                y = Math.abs(y) + zeroPoint;
            }

            if (i === 0) {
                // // 第一个点必须是从0位开始的，所以需要算出一个偏移量，以供后续的点调整
                // offsetY[index] = y - zeroPoint;
                // // console.log("offset " + offset);
                // ecgTextY[index] = zeroPoint;
            }

            // y = y - offsetY[index];
            // console.log("Final Y:" + y);

            if (i === 0) {
                // 定位第一个点
                path = path + "M" + winMarginX + "," + y;
            } else {
                // // 记录前一个点，计算相对距离
                // let preY = ecgDataSegment.data[index][i - 1] / ecgDataSegment.gc;
                // // preY = preY - offset;
                // preY = (preY / cellUnit) * cellHeight;
                // 如果是正值，则需要以zeroPoint - 该值，
                // 如果是负值，则需要以zeroPoint + 该值的绝对值
                if (preY > 0) {
                    preY = zeroPoint - preY;
                } else {
                    preY = Math.abs(preY) + zeroPoint;
                }

                // preY = preY - offsetY[index];
                // console.log("Final preY:" + preY);
                path = path + " l" + pointSpace + "," + (y - preY)
            }

            x = x + pointSpace;
        }

        // let diagnosisDate = "";
        // if (index === 0 && timeText.length > 0) {
        //     diagnosisDate = "<g><text y='-35' x='-50'>诊断日期：" + timeText[0].date + "</text></g>";
        // }

        let result = "";//<g id=''>" + diagnosisDate + "<text x=-50 y=" + ecgTextY[index] + ">ECG-" + (index + 1) + "</text>";

        // for (let i = 0; i < timeText.length; i++) {
        // result = result + "<text y='" + (heightPerLead - 10) + "' x='" + timeText[i].x + "'>" + timeText[i].time + "</text>";
        // result = result + "<polygon points='" + Math.round(timeText[i].x) + "," + (heightPerLead) + " " + Math.round(timeText[i].x - 5) + "," + (heightPerLead + 10) + " " + Math.round(timeText[i].x + 5) + "," + (heightPerLead + 10) + "' fill='blue' stroke='rgb(0,0,255)' stroke-width=0.5></polygon>"
        // result = result + "<path stroke='rgb(0,0,255)' fill='blue' strokeWidth=0.5 d='M" + timeText[i].x + ",0 l0," + yCellCount * cellHeight + "'></path>";
        // }

        result = result + "<path stroke='rgb(0,0,0)' fill='none' strokeWidth=1 d='" + path + "'></path>";
        return result;
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));