/**
 * Created by Ethan on 17/3/16.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import moment from "moment";
import * as DateTimeFormats from "../../../constants/DateTimeFormats";

import {searchBinderData} from "./BinderDataAction"
import message from "../../../components/toast";
import {getDeviceById, refreshDevices, searchDeviceList} from "./DeviceAction";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_BINDER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshBinder(binder) {
    return {
        type: ActionTypes.ECG_BINDER_REFRESH_BINDER,
        binder: binder
    }
}

export function toggleDeviceListModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_DEVICE_LIST_MODAL,
        showDeviceListModal: isShow
    }
}

export function toggleDeviceBindModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_DEVICE_BIND_MODAL,
        showDeviceBindModal: isShow
    }
}

export function toggleGuarderListModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL,
        showGuarderListModal: isShow
    }
}

export function toggleGuarderBindModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL,
        showGuarderBindModal: isShow
    }
}

export function toggleBinderSelectModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_BINDER_SELECT_MODAL,
        showBinderSelectModal: isShow
    }
}

export function getBinderDevices(binderUserId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.getBinderDevices.replace("{userId}", binderUserId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_BINDER_REFRESH_DEVICES,
                binderDevices: response.data
            });
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function bindDevice(binderUserId, deviceCode, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.bindDevice.replace("{userId}", binderUserId);
        HttpUtils.post(requestUrl, {code: deviceCode}, (response) => {
            dispatch(toggleDeviceBindModal(false));
            dispatch(getBinderDevices(binderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_BIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function unbindDevice(binderUserId, deviceId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.unbindDevice.replace("{userId}", binderUserId);
        HttpUtils.delete(requestUrl, {id: deviceId}, (response) => {
            dispatch(toggleDeviceBindModal(false));
            dispatch(getBinderDevices(binderUserId, formatMessage));
            dispatch(getDeviceById(deviceId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_UNBIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getBinderGuarders(binderUserId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.getBinderGuarders.replace("{userId}", binderUserId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_BINDER_REFRESH_GUARDERS,
                binderGuarders: response.data
            });
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function bindGuarder(binderUserId, guarderMobile, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        let requestUrl = WebService.bindGuarder.replace("{userId}", binderUserId);
        requestUrl = requestUrl.replace("{guarderMobile}", guarderMobile)
        HttpUtils.post(requestUrl, {}, (response) => {
            dispatch(toggleGuarderBindModal(false));
            dispatch(getBinderGuarders(binderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_BIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function unbindGuarder(binderUserId, guarderId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        let requestUrl = WebService.unbindGuarder.replace("{userId}", binderUserId);
        requestUrl = requestUrl.replace("{guarderId}", guarderId);
        HttpUtils.delete(requestUrl, requestUrl, (response) => {
            dispatch(toggleGuarderBindModal(false));
            dispatch(getBinderGuarders(binderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_UNBIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchBinders(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchBinderList, searchCondition, (response) => {
            dispatch({
                type: ActionTypes.ECG_BINDER_SEARCH,
                binders: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchBindersSum(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        HttpUtils.get(WebService.searchBinderCount, (response) => {
            dispatch({
                type: ActionTypes.ECG_BINDER_SEARCH_BINDERS_SUM,
                bindersSum: response.data
            });

            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getBinderById(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.getBinderById.replace("{id}", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshBinder(response.data));

            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getBinderByMobile(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.getBinder.replace("{userId}", searchCondition.mobile);
        HttpUtils.get(requestUrl, (response) => {
            // const searchCondition = {
            //     binderId: response.data.id,
            //     startTime: moment().startOf('month').format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
            //     endTime: moment().endOf('month').format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE)
            // };
            // console.log(searchCondition);
            searchCondition.binderId = response.data.id;
            console.log(searchCondition);
            dispatch(searchBinderData(searchCondition, formatMessage));

            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteBinder(binder, searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteBinder, binder, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(searchBinders(searchCondition, formatMessage));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}
