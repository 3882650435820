/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {
    Button,
    Descriptions,
} from "antd";

import {DeviceColor} from "../../../Enums";
import {connect} from "react-redux";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class DeviceDetailBasic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        const {device} = this.props;

        const formatMessage = this.state.formatMessage;

        return (

            <Descriptions column={1}>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_CODE"})}>{device.code}</Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_NAME"})}>
                    <div>
                        <span>{device.name}</span>
                        {/*<Button type="link" size="small">*/}
                        {/*    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>*/}
                        {/*</Button>*/}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_COLOR"})}>
                    <EnumItemLabel map={DeviceColor.Map} code={device.color}/>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_DSP_VERSION"})}>
                    {device.dspVersion}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_ESP_VERSION"})}>
                    {device.espVersion}
                </Descriptions.Item>
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_MAC"})}>
                    {device.mac}
                </Descriptions.Item>
            </Descriptions>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}
export default connect(mapStateToProps)(injectIntl(DeviceDetailBasic));