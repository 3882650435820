/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    cacheSystemProperties: {},
};

const EcgSystemReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_SYSTEM_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_SYSTEM_REFRESH_PROPERTIES:
            return Object.assign({}, state, {
                cacheSystemProperties: action.cacheSystemProperties
            });
        default:
            return state
    }
};

export default EcgSystemReducer;