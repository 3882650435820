/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import moment from "moment"
import "./Index.css"

import {Calendar, Row, Col, Badge} from 'antd';
import {FORMAT_DATE_SIMPLE, FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../../../../../../constants/DateTimeFormats";

let previousDate;
let currentDate;

export default class DateIndexCalendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previousDate: moment(moment(new Date()).subtract(1, 'month')),
            currentDate: moment(moment(new Date()))
        }
        previousDate = this.state.previousDate;
        currentDate = this.state.currentDate;
    }

    dateCellRender = (value, index) => {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[value.format(FORMAT_DATE_SIMPLE)];
        if (!dateIndexRecord) {
            return;
        }

        if(index === 1){
            if(value.format(FORMAT_DATE_YEAR_MONTH_SIMPLE) !== previousDate.format(FORMAT_DATE_YEAR_MONTH_SIMPLE)){
                return;
            }
        }else{
            if(value.format(FORMAT_DATE_YEAR_MONTH_SIMPLE) !== currentDate.format(FORMAT_DATE_YEAR_MONTH_SIMPLE)){
                return;
            }
        }

        return (
            <h3 className="text-center">
                {
                    // 优先显示事件数 => 修改为显示时间索引数
                    // dateIndexRecord.eventNum >= dateIndexRecord.timeNum ?
                    //     <Badge count={dateIndexRecord.eventNum} overflowCount={9999}/> :
                    //     <Badge count={dateIndexRecord.timeNum} overflowCount={9999}
                    //            style={{backgroundColor: '#52c41a'}}/>
                    <Badge count={dateIndexRecord.timeNum} overflowCount={9999}
                    style={{backgroundColor: '#52c41a'}}/>
                }
            </h3>
        );
    };

    /** 返回日历日期的禁止点击 */
    _handleDisabled = (currentDate) => {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[currentDate.format(FORMAT_DATE_SIMPLE)];
        // 时间索引文件编号，0表示没采集数据
        return dateIndexRecord === undefined || dateIndexRecord === null || dateIndexRecord.eventFileIndex <= 0;
    };

    /** 日历日期点击事件 */
    _handleSelectDate = (date) => {
        console.log("data:", date)
        this.props.onSelect && this.props.onSelect.call(this, date);
    };

    onPanelChange = (value, index) => {

        if (index == 1) {
            previousDate = moment(moment(value._d))//new Date(value._d)
            currentDate = moment(value._d).subtract(-1, 'month')
            console.log(previousDate, currentDate)
        } else {
            currentDate = moment(moment(value._d))
            previousDate = moment(value._d).subtract(1, 'month')
        }
        this.setState({currentDate: moment(moment(new Date()))})
    }

    render() {
        return (
            <Row className="double-calendar">
                <Col className="double-calendar-divider double-calendar-left" span={12}>
                    <Calendar mode="month"
                              value={previousDate}
                              disabledDate={currentDate => this._handleDisabled(currentDate)}
                        // onPanelChange={(value, mode) => this.onPanelChange(value, mode, 1)}
                              onChange={value => this.onPanelChange(value, 1)}
                              dateCellRender={(value) => this.dateCellRender(value,1)}
                              onSelect={(date) => this._handleSelectDate(date)}/>
                </Col>
                <Col className="double-calendar-right" span={12}>
                    <Calendar value={currentDate} mode="month"
                              disabledDate={currentDate => this._handleDisabled(currentDate)}
                        // onPanelChange={(value, mode) => this.onPanelChange(value, mode)}
                              onChange={value => this.onPanelChange(value, 2)}
                              dateCellRender={(value) => this.dateCellRender(value,2)}
                              onSelect={(date) => this._handleSelectDate(date)}/>
                </Col>
            </Row>
        );
    }
}
