/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';
import {Year, Month} from "../../../../Enums";
import {Badge, Switch, Button} from 'antd';
import * as echarts from 'echarts';
import {
    FORMAT_DATE_HYPHEN,
    FORMAT_DATE_SIMPLE
} from "../../../../../../constants/DateTimeFormats";
import moment from "moment";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";
import {
    refreshEcgDateIndexFlag, refreshTopQ
} from "../../../../actions/HistoryAction";
import message from "../../../../../../components/toast";
import {
    getUserMemoList,
    refreshDate,
    refreshEcgEventRecordMap, refreshMemoTimeFrom, refreshMemoTimeTo,
    searchEcgRecordSum
} from "../../../../actions/DeviceAction";

let myChart;
let main;
let option;
let app = {};

let channelType;
let version
let ecgEventRecordMap;

let year
let month

let yearMonth;

let list;
let isStatistics;
let versionTemp;

let webSocket;
// 事件索引文件对象
let ecgFileEventIndex;
// 事件索引文件对象列表
let ecgFileEventIndexList;

class HistoryCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            timeFileIndex: 0,
            flag: true,
            // year: 2022,
            // month: 7
        }
    }

    componentWillUnmount() {
        // clearInterval(this.timer2);
        clearInterval(this.timer)
    }

    componentDidMount() {
        // list = this.props.ecgEventRecordMap;
        list = this.props.ecgEventRecordMap

        // this.timer = setInterval(() => {
        //     if (list != this.props.ecgEventRecordMap || this.props.channelType != channelType) {
        //         list = this.props.ecgEventRecordMap
        //         channelType = this.props.channelType;
        //         this._init()
        //         // clearInterval(this.timer)
        //     }
        // }, 500);


    }


    componentWillReceiveProps(nextProps, nextContext) {
        // 只有需要刷新的时候，才调用init方法，比如设备号更改了
        if (nextProps.ecgDataIndexDataRefreshFlag) {//|| channelType != nextProps.channelType || version != nextProps.version ||ecgEventRecordMap != nextProps.ecgEventRecordMap
            //跳转到有数据的那一天
            if (versionTemp !== this.props.version) {
                let lastDate = 0;
                for (let item in this.props.ecgDateIndexRecordMap) {
                    lastDate = item;
                }
                if (lastDate == 0)
                    message.error("未获取到日期文件")


                if (lastDate !== 0) {
                    year = parseInt(lastDate.toString().substring(0, 4));
                    month = parseInt(lastDate.toString().substring(4, 6));
                }

                versionTemp = this.props.version;
            }

            this._init(1);
            // channelType = nextProps.channelType;
            // version = nextProps.version;
            // ecgEventRecordMap = nextProps.ecgEventRecordMap;

            this.props.dispatch(refreshEcgDateIndexFlag(false));
        }

        // if (channelType != nextProps.channelType) {
        //     this._init(2);
        //     channelType = nextProps.channelType;
        // }
        //
        // if (version != nextProps.version) {
        //     this._init(3);
        //     version = nextProps.version;
        // }

        // if (ecgEventRecordMap != nextProps.ecgEventRecordMap) {
        //     this._init(4);
        //     ecgEventRecordMap = nextProps.ecgEventRecordMap;
        // }

    }

    _init(index) {
        let self = this;
        if (myChart) {
            myChart.clear();
        }
        main = document.getElementById('main');
        // main.innerHTML = "";
        // main._echarts_instance_ = "";
        // main.setAttribute("_echarts_instance_", "");
        myChart = echarts.init(main);

        const cellSize = [120, 80];
        const pieRadius = 30;
        // const pieRadius = ['4%', '7%']
        let monthV;
        console.log("_init:", index, this.props.deviceCode, self.props.ecgEventRecordMap)

        function getVirtulData() {

            let month2;
            if (month >= 10) {
                monthV = month
                month2 = month + 1
            } else {
                monthV = "0" + month
                if (month + 1 >= 10) {
                    month2 = month + 1
                } else {
                    month2 = "0" + (month + 1)
                }

            }
            let date = +echarts.number.parseDate(year + '-' + monthV + '-01');
            let end = +echarts.number.parseDate(year + '-' + month2 + '-01');
            let dayTime = 3600 * 24 * 1000;
            let data = [];
            for (let time = date; time < end; time += dayTime) {
                data.push([

                    echarts.format.formatTime('yyyy-MM-dd', time), 1

                ]);
            }
            return data;
        }

        function getMemoRecord(scatterData) {
            var data = []
            scatterData.map(function (item, index) {
                let memoValue = 0;
                for (let x = 0; x < self.props.userMemoList.length; x++) {
                    if ((moment(item[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE)).toString() ===
                        self.props.userMemoList[x].createTime.substring(0, 8)) {
                        memoValue++;
                    }
                }
                data.push([
                    item[0],
                    "用户记录",
                    memoValue,
                ]);
            });
            return data;
        }

        function getPieSeries(scatterData, chart) {
            return scatterData.map(function (item, index) {
                //ecgEventRecordMap
                let value;
                let memoValue = 0;
                for (let i = 0; i < self.props.ecgEventRecordMap.length; i++) {
                    if ((moment(item[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE)).toString()
                        === self.props.ecgEventRecordMap[i].eventDate.toString()) {
                        value = self.props.ecgEventRecordMap[i];
                    } else {
                    }

                }
                for (let x = 0; x < self.props.userMemoList.length; x++) {

                    if ((moment(item[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE)).toString() ===
                        self.props.userMemoList[x].createTime.substring(0, 8)) {
                        memoValue++;
                    }
                }

                const dateIndexRecord = self.props.ecgDateIndexRecordMap[moment(item[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE)];

                var center = chart.convertToPixel('calendar', item);
                if (value) {
                    return {
                        id: index + 'pie',
                        type: 'pie',
                        center: center,
                        color: ["#d71b38", "#f87703", "#f5c74d", "#2956b1", "#2dab6d", "#aaaaaa"],
                        label: {
                            formatter: function (name) {
                                if (name.value > 0) {
                                    return name.value;
                                } else {
                                    return ""
                                }
                            },
                            position: 'inside',
                            color: "white"
                        },
                        radius: pieRadius,
                        data: [
                            {
                                name: '诊断紧急事件',
                                value: value ? (value.eventLevel0 == 0 ? "" : value.eventLevel0) : "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '跌倒紧急事件',
                                value: value ? (value.eventLevel1 == 0 ? "" : value.eventLevel1) : "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '导联脱落事件',
                                value: value ? (value.eventLevel2 == 0 ? "" : value.eventLevel2) : "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '诊断结果事件',
                                value: value ? (value.eventLevel3 == 0 ? "" : value.eventLevel3) : "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '设备状态事件',
                                value: value ? (value.eventLevel4 == 0 ? "" : value.eventLevel4) : "",
                                dateIndexRecord: dateIndexRecord
                            },
                            // {
                            //     name: "记录",
                            //     value: memoValue == 0 ? "" : memoValue,
                            //     dateIndexRecord: dateIndexRecord,
                            //     record: true
                            // },
                            {
                                name: '无数据',
                                value: 0,//length > 0 ? "" :
                                dateIndexRecord: dateIndexRecord
                            },
                        ]
                    }
                        // , {
                        //     type: 'pie',
                        //     // radius: '25%',
                        //     center: ['50%', '50%'],
                        //     data: {
                        //         name: "记录",
                        //         value: memoValue == 0 ? "" : memoValue,
                        //         dateIndexRecord: dateIndexRecord,
                        //         record: true
                        //     },
                        //     label: {
                        //         formatter: '{c}',
                        //         position: 'inside'
                        //     },
                        // },]
                        ;
                } else {
                    return {
                        id: index + 'pie',
                        type: 'pie',
                        center: center,
                        color: ["#d71b38", "#f87703", "#f5c74d", "#2956b1", "#2dab6d", "#aaaaaa"],
                        label: {
                            formatter: '{c}',
                            position: 'inside'
                        },
                        radius: pieRadius,
                        data: [
                            {
                                name: '诊断紧急事件',
                                value: "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '跌倒紧急事件',
                                value: "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '导联脱落事件',
                                value: "",
                                dateIndexRecord: dateIndexRecord
                            },
                            {
                                name: '诊断结果事件',
                                value: "",
                                dateIndexRecord: dateIndexRecord
                            },

                            {
                                name: '设备状态事件',
                                value: "",
                                dateIndexRecord: dateIndexRecord
                            },
                            // {
                            //     name: "记录",
                            //     value: memoValue == 0 ? "" : memoValue,
                            //     dateIndexRecord: dateIndexRecord,
                            //     record: true
                            // },

                        ]
                    }
                        // , {
                        //     type: 'pie',
                        //     // radius: '25%',
                        //     center: ['50%', '50%'],
                        //     data: {
                        //         name: "记录",
                        //         value: memoValue == 0 ? "" : memoValue,
                        //         dateIndexRecord: dateIndexRecord,
                        //         record: true
                        //     },
                        //     label: {
                        //         formatter: '{c}',
                        //         position: 'inside'
                        //     },
                        //     // left: 0,
                        //     // right: '66.6667%',
                        //     // top: 0,
                        //     // bottom: 0
                        // },]
                        ;
                }


            });
        }

        function getPieSeriesUpdate(scatterData, chart) {
            return scatterData.map(function (item, index) {
                var center = chart.convertToPixel('calendar', item);
                return {
                    id: index + 'pie',
                    center: center
                };
            });
        }

        option = null
        var scatterData = getVirtulData();
        var recordData = getMemoRecord(scatterData);
        option = {
            tooltip: {},
            legend: {
                data: ['诊断紧急事件', '跌倒紧急事件', '导联脱落事件', '诊断结果事件', '设备状态事件', '记录'],
                bottom: 20
            },
            calendar: {
                top: 'middle',
                left: 'center',
                orient: 'vertical',
                cellSize: cellSize,
                yearLabel: {
                    show: false,
                    fontSize: 30
                },
                dayLabel: {
                    margin: 20,
                    firstDay: 1,
                    nameMap: ['日', '一', '二', '三', '四', '五', '六', '日']
                },
                monthLabel: {
                    show: false
                },
                range: [year + '-' + monthV]
            },
            series: [
                {
                    id: 'label',
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: 1,
                    color: "#ffffff",
                    label: {
                        show: true,
                        formatter: function (params) {
                            return echarts.format.formatTime('dd', params.value[0]);
                        },
                        color: "#000000",
                        offset: [-cellSize[0] / 2 + 15, -cellSize[1] / 2 + 15],
                        fontSize: 17
                    },
                    data: scatterData
                }, {
                    id: 'label2',
                    type: 'scatter',
                    coordinateSystem: 'calendar',
                    symbolSize: 1,
                    color: "#ffffff",
                    label: {
                        formatter: function (params) {
                            return params.value[2] == 0 ? "" : params.value[2]
                        },
                        show: true,
                        color: "red",
                        // borderColor: "red",
                        // padding: 5,
                        borderWidth: "1",
                        offset: [-cellSize[0] / 2 + 108, -cellSize[1] / 2 + 15],
                        fontWeight: "bold",
                        fontSize: 17
                    },
                    data: recordData
                }
            ]
        };
        let pieInitialized;
        setTimeout(function () {
            pieInitialized = true;
            myChart.setOption({
                series: getPieSeries(scatterData, myChart)
            });

        }, 10);

        // app.onresize = function () {
        if (pieInitialized) {
            myChart.setOption({
                series: getPieSeriesUpdate(scatterData, myChart)
            });
        }
        // };
        myChart.on('click', function (event) {
            if (isStatistics) {
                // option.series[0].data[event.componentIndex-1]
                // 绘制图表
                option && myChart.setOption(option);
            } else {
                if (event.data[2] > 0) {
                    let date = moment(event.data[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE).toString();
                    let parem = {
                        "deviceCode": self.props.deviceCode,
                        "createDate": date
                    }
                    console.log("event.data:" + JSON.stringify(event.data));
                    self.props.dispatch(refreshMemoTimeFrom(date + "000000"));
                    self.props.dispatch(refreshMemoTimeTo(date + "235959"));
                    self.props.dispatch(getUserMemoList(parem, self.state.formatMessage));
                    self.props.setDefaultActiveKey("10");
                    return;
                }

                if (!event.data.dateIndexRecord) {
                    return;
                }

                if (event.data.dateIndexRecord) {
                    // console.log("event.data.dateIndexRecord:", event.data.dateIndexRecord)
                    self.props.onSelect && self.props.onSelect.call(self, event.data.dateIndexRecord);
                    const getUserMemoListParem = {
                        "deviceCode": self.props.deviceCode,//zhi
                        "generationId": self.props.mapVersion[0],
                        "memoTimeFrom": event.data.dateIndexRecord.recordDate + "000001",
                        "memoTimeTo": event.data.dateIndexRecord.recordDate + "235959",
                    }
                    self.props.dispatch(getUserMemoList(getUserMemoListParem, self.state.formatMessage))
                    self.props.dispatch(refreshTopQ([]));
                    console.log("event.data.dateIndexRecord:", event.data.dateIndexRecord,self.props.topQ)
                    return
                }


                const record = self.props.ecgDateIndexRecordMap[moment(event.data[0], FORMAT_DATE_HYPHEN).format(FORMAT_DATE_SIMPLE)]

                if (record.eventNum > 0) {
                    console.log("record.eventNum:", record)
                    self.props.onSelect && self.props.onSelect.call(self, record);

                }
            }


        })
        if (myChart === "null" || myChart === undefined) {
            return
        }
        // 绘制图表
        option && myChart.setOption(option);
        this.setState({timeFileIndex: this.state.timeFileIndex})
    }

    componentWillMount() {
        let date = new Date();
        month = date.getMonth() + 1;
        year = date.getFullYear();
        channelType = this.props.channelType;
        version = this.props.version
        ecgEventRecordMap = this.props.ecgEventRecordMap;
        versionTemp = this.props.version;
        let lastDate = 0;
        for (let item in this.props.ecgDateIndexRecordMap) {
            lastDate = item;
        }
        if (lastDate == 0)
            message.error("未获取到日期文件")


        if (lastDate !== 0) {
            year = parseInt(lastDate.toString().substring(0, 4));
            month = parseInt(lastDate.toString().substring(4, 6));
        }

        versionTemp = this.props.version;
    }


    /** 日历日期点击事件 */
    _handleSelectDate(date) {

        const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
        this.props.onSelect && this.props.onSelect.call(this, ecgDateIndexRecord);
    };

    /** 日历返回的事件Badge */
    dateCellRender(value) {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[value.format(FORMAT_DATE_SIMPLE)];
        if (!dateIndexRecord) {
            return;
        }

        return (
            <h3 className="text-center">
                <Badge count={dateIndexRecord.eventNum} overflowCount={9999}/>
                <Badge count={dateIndexRecord.timeNum} overflowCount={9999}
                       style={{backgroundColor: '#52c41a'}}/>
            </h3>
        );
    }

    /** 返回日历日期的禁止点击 */
    _handleDisabled(currentDate) {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[currentDate.format(FORMAT_DATE_SIMPLE)];
        // 时间索引文件编号，0表示没采集数据
        return dateIndexRecord === undefined || dateIndexRecord === null || dateIndexRecord.eventFileIndex <= 0;
    }

    render() {

        return (
            <div>
                {/*<Calendar className="calendar-mode-invisible" mode="month"*/}
                {/*          dateCellRender={(value) => this.dateCellRender(value)}*/}
                {/*          disabledDate={currentDate => this._handleDisabled(currentDate)}*/}
                {/*    // onPanelChange={(value, mode) => this.onPanelChange(value, mode)}*/}
                {/*          onSelect={(date) => this._handleSelectDate(date)}*/}
                {/*          style={{marginTop: 20}}*/}
                {/*/>*/}

                年：
                <Button icon={"step-backward"} className="spacing-h" onClick={() => this._handlePreFile(1)}/>
                <EnumItemSelect
                    style={{width: '100px', marginRight: '10px'}} allowClear={false}
                    data={Year.List} value={year}
                    onChange={(value) => {
                        this._handleChangeYear(value)
                    }}/>
                <Button icon={"step-forward"} className="spacing-h" onClick={() => this._handleNextFile(1)}/>

                月：
                <Button icon={"step-backward"} className="spacing-h" onClick={() => this._handlePreFile(2)}/>
                <EnumItemSelect
                    style={{width: '100px', marginRight: '10px'}} allowClear={false}
                    data={Month.List} value={month}
                    onChange={(value) => {
                        this._handleChangeMonth(value)
                    }}/>
                <Button icon={"step-forward"} className="spacing-h" onClick={() => this._handleNextFile(2)}/>
                {/*<Switch*/}
                {/*    checkedChildren={"统计功能"}*/}
                {/*    unCheckedChildren={"统计功能"}*/}
                {/*    onChange={(value) => isStatistics = value}/>*/}
                {/*<Button>开始统计</Button>*/}
                <div id="main" style={{height: '600px', width: '100%',}}>

                </div>
            </div>
        );
    }

    _handlePreFile(style) {
        switch (style) {
            case 1:
                // this.setState({year: this.state.year - 1})
                year--
                break
            case 2:
                // this.setState({month: this.state.month - 1})
                if (month > 1) {
                    month--
                } else {
                    year--
                    month = 12
                }
                break
        }
        yearMonth = year.toString() + (month > 9 ? month : "0" + month);
        this._init();
    }

    _handleNextFile(style) {
        switch (style) {
            case 1:
                // this.setState({year: this.state.year + 1})
                year++
                break
            case 2:
                // this.setState({month: this.state.month + 1})
                if (month < 12) {
                    month++
                }else{
                    year++
                    month = 1
                }

                break
        }
        yearMonth = year.toString() + (month > 9 ? month : "0" + month);
        this._init();
    }

    _handleChangeYear(value) {
        year = value
        yearMonth = year.toString() + (month > 9 ? month : "0" + month);
        // const parem = {
        //     "deviceCode": this.props.deviceCode,
        //     "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(this.props.xuanzeValue)],
        //     "fromApp": this.props.channelType == "10" ? "0" : "1",
        //     // "eventMonth": yearMonth
        // }
        // this.props.dispatch(refreshEcgEventRecordMap(""))
        // this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
        this._init();
    }

    _handleChangeMonth(value) {
        month = value
        yearMonth = year.toString() + (month > 9 ? month : "0" + month);
        // const parem = {
        //     "deviceCode": this.props.deviceCode,
        //     "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(this.props.xuanzeValue)],
        //     "fromApp": this.props.channelType == "10" ? "0" : "1",
        //     // "eventMonth": yearMonth
        // }
        // this.props.dispatch(refreshEcgEventRecordMap(""))
        // this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
        this._init();
    }
}

const mapStateToProps = (store) => {
    return {
        ecgDataIndexDataRefreshFlag: store.EcgHistoryReducer.ecgDataIndexDataRefreshFlag,
        channelType: store.EcgDeviceReducer.channelType,
        version: store.EcgDeviceReducer.version,
        ecgEventRecordMap: store.EcgDeviceReducer.ecgEventRecordMap,
        userMemoList: store.EcgDeviceReducer.userMemoList,
        topQ:store.EcgDeviceReducer.topQ
    }
};

export default connect(mapStateToProps)(injectIntl(HistoryCalendar));
