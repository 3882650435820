/**
 * Created by Ethan on 17/3/11.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    // Key: HistoryFileIndex, Value: {Key: PointIndex,Value:Annotation}
    annotationsMap: {},
    annotation: {},

    // 标注工具的标尺对象
    // Key: HistoryFileIndex, Value: {Key: PointIndex, Value: EcgRuler}
    ecgRulersMap: {},
    ecgRuler: {},

    isLoading: false,
    searchCondition: {},
    showAnnotationModal: false
};

const DeviceFileAnnotationReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_TOGGLE_ANNOTATION_MODAL:
            return Object.assign({}, state, {
                showAnnotationModal: action.showAnnotationModal,
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition,
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATIONS_MAP:
            return Object.assign({}, state, {
                annotationsMap: action.annotationsMap
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATION:
            return Object.assign({}, state, {
                annotation: action.annotation
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULERS_MAP:
            return Object.assign({}, state, {
                ecgRulersMap: action.ecgRulersMap
            });
        case ActionTypes.ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULER:
            return Object.assign({}, state, {
                ecgRuler: action.ecgRuler
            });
        default:
            return state
    }
};

export default DeviceFileAnnotationReducer;