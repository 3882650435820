/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Switch, Radio, Button} from 'antd';

import HistoryCalendar from "./HistoryCalendar";
import {
    getDateIndexFile,
    refreshCalendarEcgDateIndexRecord,
    refreshCalendarSearchCondition, refreshEcgDateIndexRecordMap, refreshTopQ, refreshVersionChange
} from "../../../../actions/HistoryAction";
import HttpUtils from "../../../../../../utils/HttpUtils";
import {
    getDeviceById,
    refreshChannelType,
    getPastDynastiesVersion, refreshVersionIndex, searchEcgRecordSum, getUserMemoList, refreshEcgEventRecordMap
} from "../../../../actions/DeviceAction";
import DeviceDetailDrawer from "../../RealTime/DeviceDetailDrawer";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {FORMAT_DATE_SIMPLE} from "../../../../../../constants/DateTimeFormats";
import HistoryDataDrawer from "./HistoryDataDrawer";
import {DeviceFileTypeTemp, Year} from "../../../../Enums";
import EnumItemSelect from "../../../../../../components/EnumItemSelect";
import Toast from "../../../../../../components/toast/index";

let lstDate = [];
let mapVersion;
let xuanzeValue;
let yearMonth;

let channelType;
let version
let deviceCode

let isOne = true;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            toggleDeviceDetail: false,
            toggleVersionDetail: false
        }
    }

    componentWillMount() {
        channelType = this.props.channelType
        version = this.props.version
        mapVersion = this.props.mapVersion
        deviceCode = this.props.deviceCode;
        xuanzeValue = this.props.mapVersion.length
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (channelType != nextProps.channelType) {
            channelType = nextProps.channelType
            this._handleChannelTypeChange(channelType);
        }

        if (version != nextProps.version) {
            version = nextProps.version
            this._handleVersionChange(version);
        }
        if ((mapVersion != nextProps.mapVersion && deviceCode != nextProps.deviceCode)||isOne ) {
            if (nextProps.mapVersion.length > 0 && nextProps.deviceCode.length > 0) {
                // if (nextProps.mapVersion && nextProps.deviceCode) {
                const getUserMemoListParem = {
                    "deviceCode": nextProps.deviceCode,//zhi
                    "generationId": nextProps.mapVersion[0],
                }
                console.log("getUserMemoListParem:", getUserMemoListParem, nextProps.mapVersion, nextProps.deviceCode
                    , nextProps, mapVersion, deviceCode)
                this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))

                const parem = {
                    "deviceCode": nextProps.deviceCode,
                    "generationId": nextProps.mapVersion[0],
                    "fromApp": nextProps.channelType == "10" ? "0" : "1"
                }
                // this.props.dispatch(refreshEcgEventRecordMap(""));
                this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
                mapVersion = nextProps.mapVersion
                deviceCode = nextProps.deviceCode
                xuanzeValue = this.props.mapVersion.length
                isOne = false;
                this.props.dispatch(getDateIndexFile(this.props.deviceCode, this.props.channelType, this.props.version, "detailIndex"));
                // }
            }

        }
    }


    componentDidMount() {

    }


    _handleToggleVersionDetailDrawer(value, deviceCode) {
        this.setState({toggleVersionDetail: value});
        if (value) {
            this.props.dispatch(getPastDynastiesVersion(deviceCode, this.state.formatMessage));
        }
    }

    _handleChannelTypeChange(value) {
        this.props.dispatch(refreshEcgDateIndexRecordMap({}));
        this.props.dispatch(refreshChannelType(value));
        const parem = {
            "deviceCode": this.props.deviceCode,
            "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(xuanzeValue)],
            "fromApp": value == "10" ? "0" : "1"
        }
        // console.log("_handleChannelTypeChange:",xuanzeValue)
        // const getUserMemoListParem = {
        //     "deviceCode": this.props.deviceCode,//zhi
        //     "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(xuanzeValue)],
        // }
        // this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))
        this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
    }

    _handleVersionChange(value) {
        this.props.dispatch(refreshVersionIndex(value));
        this.props.dispatch(refreshVersionChange(true));
        if (value == "00") {
            xuanzeValue = this.props.mapVersion.length;
        } else {
            xuanzeValue = value;
        }
        const getUserMemoListParem = {
            "deviceCode": this.props.deviceCode,//zhi
            "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(xuanzeValue)],
        }
        console.log("getUserMemoListParem:", getUserMemoListParem)
        this.props.dispatch(getUserMemoList(getUserMemoListParem, this.state.formatMessage))
        const parem = {
            "deviceCode": this.props.deviceCode,
            "generationId": this.props.mapVersion[this.props.mapVersion.length - Number(xuanzeValue)],
            "fromApp": this.props.channelType == "10" ? "0" : "1"
        }
        this.props.dispatch(searchEcgRecordSum(parem, this.state.formatMessage))
    }

    render() {
        const deviceCode = this.props.deviceCode;

        //设置世代数据
        let lstData = [];
        mapVersion = this.props.mapVersion;
        console.log("mapVersion", mapVersion)
        mapVersion.sort((curr, next) => {
            let a = curr;
            let b = next;

            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        });

        mapVersion.map((item, index) => {
            if (index === 0) {
                lstData.push({value: '00', label: item + "(当世)"});
            } else {
                lstData.push({value: item.substring(2, 4), label: item});
            }
            lstDate.push(item);
        })
        console.log("lstData:" + JSON.stringify(lstData));
        // Toast.error('普通提示');
        return (
            <div className="position-relative">
                {/*<SearchCondition {...this.props} />*/}
                {/*<div>*/}

                {/*<Radio.Group onChange={(event) => {*/}
                {/*    this._handleChannelTypeChange(event.target.value);*/}
                {/*}} value={this.props.channelType}>*/}
                {/*    <Radio value={'10'}>设备</Radio>*/}
                {/*    <Radio value={'20'}>手机</Radio>*/}
                {/*</Radio.Group>*/}

                {/*世代编号：*/}
                {/*<EnumItemSelect data={lstData}*/}
                {/*                value={this.props.version}*/}
                {/*                style={{width: 240}}*/}
                {/*                onChange={(value) => {*/}
                {/*                    this._handleVersionChange(value);*/}
                {/*                }}/>*/}


                <div style={{height: '10px'}}>

                </div>

                <DeviceDetailDrawer visible={this.state.toggleDeviceDetail} setVis={this._setVis}/>
                {/*<HistoryDataDrawer visible={this.state.toggleVersionDetail} setVis={this._setVisH}/>*/}
                <HistoryCalendar {...this.props} deviceCode={this.props.deviceCode}
                                 channelType={this.props.channelType}
                                 onSelect={(value) => this._handleSelectDate(value)}
                                 ecgDateIndexRecordMap={this.props.ecgDateIndexRecordMap}
                                 xuanzeValue={xuanzeValue}
                                 setDefaultActiveKey={this._setDefaultActiveKey}
                />
            </div>
        );
    }

    _setDefaultActiveKey = value => {
        this.props.setDefaultActiveKey("10");
    };

    _setVis = value => {
        this.setState({
            toggleDeviceDetail: value,
        });

    };

    _setVisH = value => {
        this.setState({
            toggleVersionDetail: value,
        });

    };


    _handleSelectDate(ecgDateIndexRecord) {
        // const deviceCode = this.props.deviceCode;
        // // 当前选中的日期的索引信息写入
        // let requestUrl = RoutePath.ECG_HISTORY_DAY.path;
        // requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", deviceCode);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);
        //
        // this.props.router.push(requestUrl);
        this.props.dispatch(refreshTopQ([]))
        this.props.dispatch(refreshCalendarEcgDateIndexRecord(ecgDateIndexRecord));
        this.props.setDefaultActiveKey("5");
    }
}

function mapStateToProps(store) {
    return {
        deviceCode: store.EcgDeviceReducer.deviceCode,
        mapVersion: store.EcgDeviceReducer.mapVersion,
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
        channelType: store.EcgDeviceReducer.channelType,
        version: store.EcgDeviceReducer.version
    }
}

export default connect(mapStateToProps)(injectIntl(Index));