/**
 * Created by Ethan on 2017/11/15.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import Map from "./Map";
import {Col, Row} from "antd";
import Sum from "./Sum";
import Colors from "../../../../../../constants/Colors";
import {getNcovData} from "../../../../actions/NcovAction";
import {injectIntl} from "react-intl";
import ProvinceChart from "./ProvinceChart";
import CountryChart from "./CountryChart";
import moment from "moment";
import {FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_TIME_SLASH_NO_SECOND} from "../../../../../../constants/DateTimeFormats";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getNcovData(this.state.formatMessage))
    }

    render() {

        // 累计确诊
        let countryConfirmed = 0;
        let countryConfirmedChange = 0;

        // 累计疑似病例
        let countrySuspected = 0;
        let countrySuspectedChange = 0;

        // 累计治愈人数
        let countryCure = 0;
        let countryCureChange = 0;

        // 累计死亡人数
        let countryDead = 0;
        let countryDeadChange = 0;

        // 现有确诊
        let confirmedNow = 0;
        let confirmedNowChange = 0;

        let publishTime = "";

        const countryStatisticKeys = Object.keys(this.props.countryStatistic);
        if (countryStatisticKeys && countryStatisticKeys.length > 0) {
            const lastItem = this.props.countryStatistic[countryStatisticKeys[countryStatisticKeys.length - 1]];
            const preLastItem = this.props.countryStatistic[countryStatisticKeys[countryStatisticKeys.length - 2]];

            publishTime = moment(lastItem.publishTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_SLASH_NO_SECOND);

            // 累计确诊
            countryConfirmed = lastItem.confirmed;
            countryConfirmedChange = lastItem.confirmed - preLastItem.confirmed;

            // 累计疑似病例
            countrySuspected = lastItem.suspected;
            countrySuspectedChange = lastItem.suspected - preLastItem.suspected;

            // 累计治愈人数
            countryCure = lastItem.cure;
            countryCureChange = lastItem.cure - preLastItem.cure;

            // 累计死亡人数
            countryDead = lastItem.dead;
            countryDeadChange = lastItem.dead - preLastItem.dead;

            // 现有确诊
            confirmedNow = countryConfirmed - countryCure - countryDead;
            confirmedNowChange = countryConfirmedChange - countryCureChange - countryDeadChange;
        }

        return (
            <div style={{padding: '10px'}}>
                <p className="page-title">
                    全国疫情 <span className="text-muted" style={{fontSize: '12px'}}>更新时间 {publishTime}</span>
                </p>
                <Row gutter={16}>
                    <Col span={8}>
                        <Sum color={Colors.Brown}
                             total={countryConfirmed}
                             title="累计确诊"
                             incremental={countryConfirmedChange}/>
                    </Col>
                    <Col span={8}>
                        <Sum color={Colors.SandyBrown}
                             total={confirmedNow}
                             title="现有确诊"
                             incremental={confirmedNowChange}/>
                    </Col>
                    <Col span={8}>
                        <Sum color={Colors.GoldEnrod}
                             total={countrySuspected}
                             title="疑似病例"
                             incremental={countrySuspectedChange}/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={8}>
                        <Sum color={Colors.Turquoise}
                             total={countryCure}
                             title="治愈人数"
                             incremental={countryCureChange}/>
                    </Col>
                    <Col span={8}>
                        <Sum color={Colors.Black}
                             total={countryDead}
                             title="死亡人数"
                             incremental={countryDeadChange}/>
                    </Col>
                    <Col span={8}>

                    </Col>
                </Row>

                <Row gutter={16}>
                    <Map/>
                </Row>


                <div className="spacing-v">
                    <p className="page-title">全国疫情趋势</p>
                    <CountryChart/>
                </div>

                <div className="spacing-v">
                    <p className="page-title">全国省市疫情</p>
                    <ProvinceChart/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        countryStatistic: store.DASNcovReducer.countryStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(Index));