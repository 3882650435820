/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    binderData: [],
    binderDataCount: 0,
    binderDataItem: {},
    ecgChainList: [],
    ecgChainPartList: [],
    ecgChainPartStartIndex: 0,
    ecgChainPartEndIndex: 5000, // 心电数据采集频率是501.3，所以默认值以显示10s为基准
    ecgChainInterval: 100,
    // key:day, value: data list
    binderDataMap: {},
    isLoading: false
};

const BinderDataReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.DAS_BINDER_DATA_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.DAS_BINDER_DATA_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.DAS_BINDER_DATA_SEARCH:
            return Object.assign({}, state, {
                binderData: action.binderData,
                binderDataMap: action.binderDataMap
            });
        case ActionTypes.DAS_BINDER_DATA_SEARCH_COUNT:
            return Object.assign({}, state, {
                binderDataCount: action.binderDataCount
            });
        case ActionTypes.DAS_BINDER_DATA_ANALYZE:
            return Object.assign({}, state, {
                ecgChainList: action.ecgChainList
            });
        case ActionTypes.DAS_BINDER_DATA_SPLIT:
            return Object.assign({}, state, {
                ecgChainPartList: action.ecgChainPartList,
                ecgChainPartStartIndex: action.ecgChainPartStartIndex,
                ecgChainPartEndIndex: action.ecgChainPartEndIndex,
                ecgChainInterval: action.ecgChainInterval
            });
        default:
            return state
    }
};

export default BinderDataReducer;