import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {CACHE_ACCESS_TOKEN, CACHE_SESSION_USER} from '../../../constants/Profile';
import HttpUtils from '../../../utils/HttpUtils';
import message from "../../../components/toast";
import {RoutePath} from "../../../constants/RoutePath";

export function refreshUser(user) {
    return {
        type: ActionTypes.USER_REFRESH_USER,
        user: user
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.USER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.USER_TOGGLE_DETAIL_MODAL,
        showDetailModal: showDetailModal
    }
}

export function togglePasswordChangeModal(show) {
    return {
        type: ActionTypes.USER_TOGGLE_PASSWORD_CHANGE_MODAL,
        showPasswordChangeModal: show
    }
}

export function getUser(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        let requestURL = WebService.getUser.replace("{id}", id);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshUser(response.data));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getSessionUser(formatMessage, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});
        HttpUtils.get(WebService.getSessionUser, (response) => {
            localStorage.setItem(CACHE_SESSION_USER, JSON.stringify(response.data));
            dispatch(refreshUser(response.data));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});

            successCallback && successCallback.call(this, response.data);
        }, (response) => {
        }, formatMessage)
    }
}

export function searchUsers(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchUsers, condition, (response) => {
            dispatch({
                type: ActionTypes.USER_SEARCH,
                users: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.createUser, user, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshUser({}));
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function registerUser(user, router, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.registerUser, user, (response) => {
            dispatch(refreshUser({}));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_CREATE_SUCCESS"}));
            router.push(RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path);
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function resetPassword(user, router, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.put(WebService.resetPassword, user, (response) => {
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS"}));
            router.push(RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path);
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        let requestURL = WebService.updateUser.replace("{id}", user.id);

        HttpUtils.put(requestURL, user, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshUser({}));
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}


export function updateSessionUser(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateSessionUser, user, (response) => {
            dispatch(refreshUser(response.data));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateSessionUserPassword(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateSessionUserPassword, user, (response) => {
            dispatch(refreshUser(response.data));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_CHANGE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});
        let requestURL = WebService.deleteUser.replace("{id}", user.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}

export function login(user, formatMessage, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.createToken, user, (response) => {
            console.log(response);
            localStorage.setItem(CACHE_ACCESS_TOKEN, response.data.accessToken);
            dispatch(getSessionUser(formatMessage, successCallback));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function logout(router, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.delete(WebService.deleteToken, {}, (response) => {
            localStorage.removeItem(CACHE_ACCESS_TOKEN);
            router.push(RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path);
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}
