/**
 * LabelType	2B		标记类型
 * StartPoint	2B		起点
 * EndPoint	2B		终点
 * TopPoint	2B		高点
 * Duration	2B		持续时间
 */
class EcgFileMixDataLabelRecord {
    constructor(dataArray, startPos) {

        let index = startPos;

        // 标记类型
        const labelTypeArray = dataArray.slice(index, index + 2);
        this.labelType = new Int16Array(labelTypeArray.buffer.slice(-2))[0];
        index += 2;

        // 起点
        const startPointArray = dataArray.slice(index, index + 2);
        this.startPoint = new Int16Array(startPointArray.buffer.slice(-2))[0];
        index += 2;

        // 终点
        const endPointArray = dataArray.slice(index, index + 2);
        this.endPoint = new Int16Array(endPointArray.buffer.slice(-2))[0];
        index += 2;

        // 高点
        const topPointArray = dataArray.slice(index, index + 2);
        this.topPoint = new Int16Array(topPointArray.buffer.slice(-2))[0];
        index += 2;

        // 持续时间
        const durationArray = dataArray.slice(index, index + 2);
        this.duration = new Int16Array(durationArray.buffer.slice(-2))[0];
        index += 2;

        this.length = index - startPos;
    }
}

export default EcgFileMixDataLabelRecord;