/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {refreshCountry} from "./CountryAction";

export function refreshProvincial(provincial) {
    return {
        type: ActionTypes.DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL,
        provincial: provincial
    }
}

export function refreshProvincialMap(provincialMap) {
    return {
        type: ActionTypes.DISTRICT_PROVINCIAL_REFRESH_PROVINCIAL_MAP,
        provincialMap: provincialMap
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_PROVINCIAL_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getProvincialsByCountryId(countryId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.getProvincialsByCountryId.replace("{id}", countryId);
        HttpUtils.get(requestURL, (response) => {

            dispatch({
                type: ActionTypes.DISTRICT_PROVINCIAL_SEARCH,
                provincials: response.data
            });

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getProvincial(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});
        const requestURL = WebService.getProvincialById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshProvincial(response.data));
            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createProvincial(provincial, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        HttpUtils.post(WebService.createProvincial, provincial, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshProvincial({}));
            dispatch(getProvincialsByCountryId(response.data.countryId, formatMessage));
            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateProvincial(provincial, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: true});

        const requestURL = WebService.updateProvincialById.replace("{id}", provincial.id);
        HttpUtils.put(requestURL, provincial, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshProvincial({}));
            dispatch(getProvincialsByCountryId(response.data.countryId, formatMessage));

            dispatch({type: ActionTypes.DISTRICT_PROVINCIAL_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteProvincial(provincial, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByProvincialId.replace("{id}", provincial.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getProvincialsByCountryId(response.data.countryId, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



