/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
    Card, Form, Row, Col, Input, Button
} from "antd";

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import PageTitle from "../../../../../components/PageTitle";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {getSystemRedis, refreshSystemProperties, updateSystemRedis} from "../../../actions/EcgSystemAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getSystemRedis(this.state.formatMessage));
    }

    _handleChange(name, value) {
        let cacheSystemProperties = Object.assign({}, this.props.cacheSystemProperties);
        cacheSystemProperties[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSystemProperties(cacheSystemProperties));
    }

    _handleSave(cacheSystemProperties) {
        this.props.dispatch(updateSystemRedis(cacheSystemProperties, this.state.formatMessage));
    }

    render() {
        const {cacheSystemProperties} = this.props;
        const {formatMessage} = this.state;
        return (
            <div className="page-content position-relative ">
                <PageTitle title="ECG_SYSTEM_ROUTE_REDIS"/>
                <Card>
                    <Form>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={"最新DSP版本号"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestDspFirmwareVersionFull}
                                           onChange={(data) => {
                                               this._handleChange("newestDspFirmwareVersionFull", data)
                                           }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"最新DSP更新时间"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestDspFirmwareCreateTime}
                                           onChange={(data) => {
                                               this._handleChange("newestDspFirmwareCreateTime", data)
                                           }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={"最新ESP32版本号"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestEspFirmwareVersionFull}
                                           onChange={(data) => {
                                               this._handleChange("newestEspFirmwareVersionFull", data)
                                           }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={"最新ESP32更新时间"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestEspFirmwareCreateTime}
                                           onChange={(data) => {
                                               this._handleChange("newestEspFirmwareCreateTime", data)
                                           }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label={"最新DSP文件路径"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestDspFilePath} onChange={(data) => {
                                        this._handleChange("newestDspFilePath", data)
                                    }}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={"最新ESP文件路径"} {...Label_6_Wrapper_18}>
                                    <Input value={cacheSystemProperties.newestEspFilePath} onChange={(data) => {
                                        this._handleChange("newestEspFilePath", data)
                                    }}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Item className="text-center">
                                <Button type="primary" icon="search"
                                        onClick={() => this._handleSave(this.props.cacheSystemProperties)}>
                                    <FormattedMessage id='COMMON_BUTTON_UPDATE'/>
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        cacheSystemProperties: store.EcgSystemReducer.cacheSystemProperties,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));