class LorenzData {
    constructor() {
        this.fs = 0;
        //时间RR
        this.hr = [];
        //时间RR
        this.trr = [];
        //时间RR位置所有
        this.trrSiteIndexM = [];
        //二维RR间期散点图数据x
        this.rrX = [];
        //二维RR间期散点图数据y
        this.rrY = [];
        //二维RR间期散点图数据xy原始位置
        this.rrXySiteIndexM = [];
        //二维RR间期差分散点图数据x
        this.drrX = [];
        //二维RR间期差分散点图数据Y
        this.drrY = [];
        //二维RR间期差分值散点图数据xy原始位置
        this.drrXySiteIndexM = [];
        //二维RR间期差分值的半径
        this.drrRadius = [];
        //二维RR间期差分值的与【0,0】位置的角度，使用了0-180度计算
        this.drrAngle = [];
        this.rrDensity = [];
        this.drrDensity = [];
        //多条辅助线x
        this.lineX = [];
        //多条辅助线y
        this.lineY = [];
        //框图的半径的一半
        this.xlimD = 0.0;
    }
}
export default LorenzData;