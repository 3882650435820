/**
 * Created by Ethan on 17/3/7.
 */

class MenuItem {
    constructor(title, path, icon) {
        this.title = title;

        // 在路由的控制中起决定性作用
        this.path = path;

        // 在path存在参数的情况下，url就需要把参数替换掉，成为菜单上准确路径
        this.url = path;
        this.icon = icon;
        this.isSelected = false;
        this.children = [];
        this.target = "";
    }
}

export default MenuItem;