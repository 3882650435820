/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Form, Input, Table, Button} from "antd";

import ExportJsonExcel from "js-export-excel";

class LogListModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'time',
                    dataIndex: 'time',
                    title: "Time"
                },
                {
                    key: 'content',
                    dataIndex: 'content',
                    title: "Content",
                    render: (text, record, index) => <div
                        style={{
                            width: '600px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}>{text}</div>
                }
            ],
        }
    }

    componentDidMount() {
    }


    _handleCancel() {
        this.props.onClose && this.props.onClose.call(this);
    }

    _handleExport() {
        let option = {
            fileName: 'Logs',
            datas: [
                {
                    sheetData: this.props.logs,
                    sheetName: 'sheet',
                    sheetFilter: ["time", "content"],
                    sheetHeader: ["Time", "Content"],
                }
            ],
        };

        const toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                title={formatMessage({id: 'ECG_FILE_TITLE_WARN_LOG'})}
                maskClosable={false}
                width={800}
                visible={this.props.visible}
                onCancel={() => this._handleCancel()}
                footer={null}>
                <div className="text-right">
                    <Button icon="file-excel" size="small" onClick={() => this._handleExport()}>
                        <FormattedMessage id='COMMON_BUTTON_EXPORT'/>
                    </Button>
                </div>
                <Table rowKey="time" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.logs}/>
            </Modal>
        );
    }
}


function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(LogListModal));