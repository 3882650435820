/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Input, Form} from "antd";
import {bindDevice, toggleDeviceBindModal} from "../../../actions/BinderAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class DeviceBindModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: ""
        }
    }

    componentDidMount() {
    }

    _handleOk() {
        if (this.state.deviceCode !== "") {
            this.props.dispatch(bindDevice(this.props.binder.userId, this.state.deviceCode, this.state.formatMessage));
        }
    }

    _handleCancel() {
        this.props.dispatch(toggleDeviceBindModal(false));
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                title={formatMessage({id: "ECG_BINDER_TITLE_DEVICE_BIND"})}
                maskClosable={false}
                visible={this.props.showDeviceBindModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_6_Wrapper_18}>
                        <Input value={this.state.deviceCode} onChange={(data) => {
                            this.setState({deviceCode: data.target.value})
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showDeviceBindModal: store.BinderReducer.showDeviceBindModal,
        binder: store.BinderReducer.binder
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceBindModal));