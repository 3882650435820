/**
 * Created by Ethan on 2017/11/7.
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from "react-intl";
import {Col, Row, Collapse, Icon} from "antd";
import ReactEcharts from 'echarts-for-react';

import {FORMAT_DATE_SIMPLE, FORMAT_MONTY_SLASH} from "../../../../../../constants/DateTimeFormats";

import moment from "moment";

class ProvinceChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeKey: "上海市"
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <Row gutter={16} style={{padding: '12px'}}>
                    <Col span={8}>地区</Col>
                    <Col span={4} className="text-danger">新增确诊</Col>
                    <Col span={4}>累计确诊</Col>
                    <Col span={4}>死亡</Col>
                    <Col span={4}>治愈</Col>
                </Row>
                <Collapse
                    activeKey={this.state.activeKey}
                    onChange={(key) => {
                        this.setState({activeKey: key})
                    }}
                    expandIconPosition={'right'}>
                    {
                        this._renderProvince("上海市")
                    }
                    {
                        this._renderProvince("湖北省")
                    }
                    {
                        Object.keys(this.props.provinceStatistic).map((item, index) => {
                            if (item === "上海市" || item === "湖北省") {
                                return null;
                            }
                            return this._renderProvince(item);
                        })
                    }
                </Collapse>
            </div>
        );
    }

    _renderProvince(province) {
        const provinceDataMap = this.props.provinceStatistic[province];
        if (provinceDataMap) {

            const provinceDataMapKeys = Object.keys(provinceDataMap);
            const confirmedValues = [];
            const deadValues = [];
            const cureValues = [];

            for (let key of provinceDataMapKeys) {
                const item = provinceDataMap[key];
                confirmedValues.push(item.confirmed);
                deadValues.push(item.dead);
                cureValues.push(item.cure);
            }

            const option = {
                title: {
                    text: ''
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['确诊', '死亡', '治愈']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: provinceDataMapKeys.map((item, index) => {
                        return moment(item, FORMAT_DATE_SIMPLE).format(FORMAT_MONTY_SLASH)
                    })
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '确诊',
                        type: 'line',
                        data: confirmedValues
                    },
                    {
                        name: '死亡',
                        type: 'line',
                        data: deadValues
                    },
                    {
                        name: '治愈',
                        type: 'line',
                        data: cureValues
                    },
                ]
            };

            const lastItem = provinceDataMap[provinceDataMapKeys[provinceDataMapKeys.length - 1]];
            const preLastItem = provinceDataMap[provinceDataMapKeys[provinceDataMapKeys.length - 2]];

            const increase = lastItem.confirmed - preLastItem.confirmed;
            return (
                <Collapse.Panel key={province} header={
                    <Row gutter={16}>
                        <Col span={8}>{province}</Col>
                        <Col span={4} className="text-danger">{(increase > 0) ? increase : ""}</Col>
                        <Col span={4}>{lastItem.confirmed}</Col>
                        <Col span={4}>{lastItem.dead}</Col>
                        <Col span={4}>{lastItem.cure}</Col>
                    </Row>
                } extra={<Icon
                    type="setting"
                    onClick={event => {
                        // If you don't want click extra trigger collapse, you can prevent this:
                        event.stopPropagation();
                    }}
                />} showArrow={false}>
                    <ReactEcharts
                        option={option}
                        style={{height: '350px', width: '100%'}}
                        className='react_for_echarts'/>
                    {
                        Object.keys(lastItem.city).map((city, index) => {
                            const increase = lastItem.city[city].confirmed - (preLastItem.city[city] ? preLastItem.city[city].confirmed : 0);
                            return (
                                <Row gutter={16} key={index} style={{padding: "12px", borderBottom: '1px solid #d9d9d9'}}>
                                    <Col span={8}>{city}</Col>
                                    <Col span={4} className="text-danger">{(increase > 0) ? increase : ""}</Col>
                                    <Col span={4}>{lastItem.city[city].confirmed}</Col>
                                    <Col span={4}>{lastItem.city[city].dead}</Col>
                                    <Col span={4}>{lastItem.city[city].cure}</Col>
                                </Row>
                            )
                        })
                    }
                </Collapse.Panel>
            )
        }
    }
}

function mapStateToProps(store) {
    return {
        provinceStatistic: store.DASNcovReducer.provinceStatistic
    }
}

export default connect(mapStateToProps)(injectIntl(ProvinceChart));