import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Input, Form, Button, Icon, InputNumber} from "antd";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {createCase, refreshCase, toggleDetailModal, updateCase} from "../../../actions/EcgCaseAction";

import BinderSelectModal from "../../../components/BinderSelectModal";
import DeviceSelectModal from "../../../components/DeviceSelectModal";
import {toggleBinderSelectModal} from "../../../actions/BinderAction";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {UserSex} from "../../../Enums";
import {toggleDeviceSelectModal} from "../../../actions/DeviceAction";

class DeviceBindModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    componentDidMount() {
    }

    _handleOk() {
        const {dispatch, searchCondition, ecgCase} = this.props;
        if (this._validate(ecgCase)) {
            if (ecgCase.id !== undefined && ecgCase.id !== "") {
                dispatch(updateCase(ecgCase, searchCondition, this.state.formatMessage));
            } else {
                dispatch(createCase(ecgCase, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(ecgCase) {
        let messageMap = {};

        const formatMessage = this.state.formatMessage;
        if (ecgCase.number === undefined || ecgCase.number === "") {
            messageMap["number"] = formatMessage({id: "ECG_CASE_MSG_NUMBER_REQUIRED"});
        }
        if (ecgCase.name === undefined || ecgCase.name === "") {
            messageMap["name"] = formatMessage({id: "ECG_CASE_MSG_NAME_REQUIRED"});
        }
        if (ecgCase.deviceNumber === undefined || ecgCase.deviceNumber === "") {
            messageMap["deviceNumber"] = formatMessage({id: "ECG_CASE_MSG_DEVICE_NAME_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleChange(name, value) {
        const ecgCase = Object.assign({}, this.props.ecgCase);
        ecgCase[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshCase(ecgCase));
    }

    _handleSelectBinder(value) {
        const ecgCase = Object.assign({}, this.props.ecgCase);
        ecgCase.binderId = value.id;
        ecgCase.name = value.userName;
        ecgCase.gender = value.userSex;
        this.props.dispatch(refreshCase(ecgCase));
    }

    _handleSelectDevice(value) {
        const ecgCase = Object.assign({}, this.props.ecgCase);
        ecgCase.deviceId = value.id;
        ecgCase.deviceNumber = value.code;
        this.props.dispatch(refreshCase(ecgCase));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {ecgCase} = this.props;

        let title = formatMessage({id: 'ECG_CASE_TITLE_CREATE'});
        if (ecgCase.id !== undefined && ecgCase.id !== "") {
            title = formatMessage({id: "ECG_CASE_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                maskClosable={false}
                visible={this.props.showDetailModal}
                onCancel={() => this._handleCancel()}
                onOk={() => this._handleOk()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <BinderSelectModal onSelect={(value) => this._handleSelectBinder(value)}/>
                <DeviceSelectModal onSelect={(value) => this._handleSelectDevice(value)}/>
                <Form>
                    <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_NUMBER'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["number"] ? "error" : ""}
                               help={this.state.messageMap["number"]}>
                        <Input value={ecgCase.number} onChange={(data) => this._handleChange("number", data)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_NAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input disabled value={ecgCase.name} onChange={(data) => this._handleChange("name", data)}
                               addonAfter={<a onClick={() => this.props.dispatch(toggleBinderSelectModal(true))}>
                                   <FormattedMessage id='ECG_CASE_BUTTON_SELECT_BINDER'/>
                               </a>}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_GENDER'})} {...Label_6_Wrapper_18}>
                        <EnumItemSelect data={UserSex.List} value={ecgCase.gender}
                                        onChange={(data) => this._handleChange("gender", data)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_AGE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={ecgCase.age} onChange={(data) => this._handleChange("age", data)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ECG_CASE_FIELD_DEVICE_NUMBER'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["deviceNumber"] ? "error" : ""}
                               help={this.state.messageMap["deviceNumber"]}>
                        <Input disabled value={ecgCase.deviceNumber}
                               onChange={(data) => this._handleChange("deviceNumber", data)}
                               addonAfter={<a onClick={() => this.props.dispatch(toggleDeviceSelectModal(true))}>
                                   <FormattedMessage id='ECG_DEVICE_BUTTON_SELECT_DEVICE'/>
                               </a>}/>
                    </Form.Item>

                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showDetailModal: store.EcgCaseReducer.showDetailModal,
        searchCondition: store.EcgCaseReducer.searchCondition,
        ecgCase: store.EcgCaseReducer.ecgCase
    }
}

export default connect(mapStateToProps)(injectIntl(DeviceBindModal));