/**
 * Created by Ethan on 16/12/26.
 * 定义Action的类型
 */

// 心电设备
export const ECG_DEVICE_LOADING = 'ECG_DEVICE_LOADING';
export const ECG_MAP_DEVICE_SEARCH = 'ECG_MAP_DEVICE_SEARCH';
export const ECG_DEVICE_SEARCH = 'ECG_DEVICE_SEARCH';
export const ECG_ALL_DEVICE_SEARCH = 'ECG_ALL_DEVICE_SEARCH';
export const ECG_DEVICE_SEARCH_ALL = 'ECG_DEVICE_SEARCH_ALL';
export const ECG_DEVICE_SEARCH_DEVICES_SUM = 'ECG_DEVICE_SEARCH_DEVICES_SUM';
export const ECG_DEVICE_SEARCH_DEVICES_ACTIVE_SUM = 'ECG_DEVICE_SEARCH_DEVICES_ACTIVE_SUM';
export const ECG_FILE_STATISTIC_USER_MEMO_LIST = "ECG_FILE_STATISTIC_USER_MEMO_LIST"
export const ECG_DEVICE_REFRESH_SEARCH_CONDITION = 'ECG_DEVICE_REFRESH_SEARCH_CONDITION';
export const ECG_DEVICE_ALL_SEARCH_CONDITION = 'ECG_DEVICE_ALL_SEARCH_CONDITION';
export const ECG_DEVICE_REFRESH_DEVICE = 'ECG_DEVICE_REFRESH_DEVICE';
export const ECG_DEVICE_REFRESH_CACHE_DEVICE = 'ECG_DEVICE_REFRESH_CACHE_DEVICE';
export const ECG_DEVICE_REFRESH_DEVICES = 'ECG_DEVICE_REFRESH_DEVICES';
export const ECG_DEVICE_REFRESH_DEVICE_MONITOR_COLUMNS = 'ECG_DEVICE_REFRESH_DEVICE_MONITOR_COLUMNS';
export const ECG_DEVICE_REFRESH_MEMO_TIME_FROM = 'ECG_DEVICE_REFRESH_MEMO_TIME_FROM';
export const ECG_DEVICE_REFRESH_MEMO_TIME_TO = 'ECG_DEVICE_REFRESH_MEMO_TIME_TO';
export const ECG_DEVICE_TOGGLE_DETAIL_MODAL = 'ECG_DEVICE_TOGGLE_DETAIL_MODAL';
export const ECG_DEVICE_TOGGLE_CREATE_MODAL = 'ECG_DEVICE_TOGGLE_CREATE_MODAL';
export const ECG_DEVICE_TOGGLE_FIELD_SELECT_MODAL = 'ECG_DEVICE_TOGGLE_FIELD_SELECT_MODAL';
export const ECG_DEVICE_TOGGLE_CACHE_MODAL = 'ECG_DEVICE_TOGGLE_CACHE_MODAL';
export const ECG_DEVICE_TOGGLE_SELECT_MODAL = "ECG_DEVICE_TOGGLE_SELECT_MODAL";
export const ECG_DEVICE_REFRESH_FILTER_VIEWPORT_FLAG = "ECG_DEVICE_REFRESH_FILTER_VIEWPORT_FLAG";
export const ECG_DEVICE_REFRESH_SELECT_DEVICE_ID = "ECG_DEVICE_REFRESH_SELECT_DEVICE_ID";
export const ECG_DEVICE_TOGGLE_RESULT_MODAL = 'ECG_DEVICE_TOGGLE_RESULT_MODAL';
export const ECG_DEVICE_HISTORY_DATA = "ECG_DEVICE_HISTORY_DATA"
export const ECG_DEVICE_EVENT_DATA = "ECG_DEVICE_EVENT_DATA"
export const ECG_DEVICE_TIME_DATA = "ECG_DEVICE_TIME_DATA"
export const ECG_APP_HISTORY_DATA = "ECG_APP_HISTORY_DATA"
export const ECG_APP_EVENT_DATA = "ECG_APP_EVENT_DATA"
export const ECG_APP_TIME_DATA = "ECG_APP_TIME_DATA"

export const ECG_EVENT_RECORD_MAP = "ECG_EVENT_RECORD_MAP"

export const ECG_DASHBOARD_TOGGLE_MAP_SCREEN = "ECG_DASHBOARD_TOGGLE_MAP_SCREEN";
export const ECG_DASHBOARD_REFRESH_SEARCH_CONDITION = "ECG_DASHBOARD_REFRESH_SEARCH_CONDITION";
export const ECG_DEVICE_REFRESH_MAP_SEARCH_CONDITION = "ECG_DEVICE_REFRESH_MAP_SEARCH_CONDITION";
export const ECG_DEVICE_TOGGLE_DEVICE_LIST_SCREEN = "ECG_DEVICE_TOGGLE_DEVICE_LIST_SCREEN";
export const ECG_DEVICE_REFRESH_SCREEN_MAP = "ECG_DEVICE_REFRESH_SCREEN_MAP";
export const ECG_DEVICE_REFRESH_REGION_SEARCH_CONDITION = "ECG_DEVICE_REFRESH_REGION_SEARCH_CONDITION";
export const ECG_DEVICE_REFRESH_REGIONS = "ECG_DEVICE_REFRESH_REGIONS";
export const ECG_DEVICE_REFRESH_CALENDAR_SEARCH_CONDITION = "ECG_DEVICE_REFRESH_CALENDAR_SEARCH_CONDITION";
export const ECG_DEVICE_DATE_INDEX_RECORD = "ECG_DEVICE_DATE_INDEX_RECORD";
export const ECG_DEVICE_DATE_TOP_POINT = "ECG_DEVICE_DATE_TOP_POINT";
export const ECG_DEVICE_REFRESH_CHANNEL_TYPE = "ECG_DEVICE_REFRESH_CHANNEL_TYPE";
export const ECG_DEVICE_REFRESH_VERSION_INDEX = "ECG_DEVICE_REFRESH_VERSION_INDEX";

export const ECG_DEVICE_COORDINATE_LOADING = 'ECG_DEVICE_COORDINATE_LOADING';
export const ECG_DEVICE_COORDINATE_SEARCH = 'ECG_DEVICE_COORDINATE_SEARCH';
export const ECG_DEVICE_COORDINATE_REFRESH_CONDITION = "ECG_DEVICE_COORDINATE_REFRESH_CONDITION";
//deviceCode
export const ECG_DEVICE_CODE = "ECG_DEVICE_CODE";

// 设备文件标注
export const ECG_DEVICE_FILE_ANNOTATION_LOADING = "ECG_DEVICE_FILE_ANNOTATION_LOADING";
export const ECG_DEVICE_FILE_ANNOTATION_TOGGLE_ANNOTATION_MODAL = "ECG_DEVICE_FILE_ANNOTATION_TOGGLE_ANNOTATION_MODAL";
export const ECG_DEVICE_FILE_ANNOTATION_REFRESH_SEARCH_CONDITION = "ECG_DEVICE_FILE_ANNOTATION_REFRESH_SEARCH_CONDITION";
export const ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATIONS_MAP = "ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATIONS_MAP";
export const ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATION = "ECG_DEVICE_FILE_ANNOTATION_REFRESH_ANNOTATION";
export const ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULERS_MAP = "ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULERS_MAP";
export const ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULER = "ECG_DEVICE_FILE_ANNOTATION_REFRESH_ECG_RULER";

export const ECG_BINDER_LOADING = 'ECG_BINDER_LOADING';
export const ECG_BINDER_SEARCH = 'ECG_BINDER_SEARCH';
export const ECG_BINDER_SEARCH_BINDERS_SUM = 'ECG_BINDER_SEARCH_BINDERS_SUM';
export const ECG_BINDER_REFRESH_BINDER = 'ECG_BINDER_REFRESH_BINDER';
export const ECG_BINDER_REFRESH_SEARCH_CONDITION = 'ECG_BINDER_REFRESH_SEARCH_CONDITION';
export const ECG_BINDER_REFRESH_DEVICES = "ECG_BINDER_REFRESH_DEVICES";
export const ECG_BINDER_REFRESH_GUARDERS = "ECG_BINDER_REFRESH_GUARDERS";
export const ECG_BINDER_REFRESH_SELECTED_BINDERS = 'ECG_BINDER_REFRESH_SELECTED_BINDERS';
export const ECG_BINDER_TOGGLE_DEVICE_LIST_MODAL = 'ECG_BINDER_TOGGLE_DEVICE_LIST_MODAL';
export const ECG_BINDER_TOGGLE_DEVICE_BIND_MODAL = 'ECG_BINDER_TOGGLE_DEVICE_BIND_MODAL';
export const ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL = 'ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL';
export const ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL = 'ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL';
export const ECG_BINDER_TOGGLE_BINDER_SELECT_MODAL = 'ECG_BINDER_TOGGLE_BINDER_SELECT_MODAL';

export const ECG_GUARDER_LOADING = "ECG_GUARDER_LOADING";
export const ECG_GUARDER_SEARCH = "ECG_GUARDER_SEARCH";
export const ECG_GUARDER_REFRESH_GUARDER = 'ECG_GUARDER_REFRESH_GUARDER';
export const ECG_GUARDER_REFRESH_BINDERS = 'ECG_GUARDER_REFRESH_BINDERS';
export const ECG_GUARDER_REFRESH_SEARCH_CONDITION = 'ECG_GUARDER_REFRESH_SEARCH_CONDITION';
export const ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL = 'ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL';

export const DAS_BINDER_DATA_LOADING = 'DAS_BINDER_DATA_LOADING';
export const DAS_BINDER_DATA_SEARCH = 'DAS_BINDER_DATA_SEARCH';
export const DAS_BINDER_DATA_SEARCH_COUNT = 'DAS_BINDER_DATA_SEARCH_COUNT';
export const DAS_BINDER_DATA_ANALYZE = 'DAS_BINDER_DATA_ANALYZE';
export const DAS_BINDER_DATA_SPLIT = 'DAS_BINDER_DATA_SPLIT';
export const DAS_BINDER_DATA_REFRESH_BINDER_DATA_ITEM = 'DAS_BINDER_DATA_REFRESH_BINDER_DATA_ITEM';
export const DAS_BINDER_DATA_REFRESH_SEARCH_CONDITION = 'DAS_BINDER_DATA_REFRESH_SEARCH_CONDITION';

export const DAS_ECG_LOADING = "DAS_ECG_LOADING";
export const DAS_ECG_REFRESH_SEARCH_CONDITION = "DAS_ECG_REFRESH_SEARCH_CONDITION";
export const DAS_ECG_INIT_SIGNAL = "DAS_ECG_INIT_SIGNAL";
export const DAS_ECG_SIGNAL_SEARCH = "DAS_ECG_SIGNAL_SEARCH";
export const DAS_ECG_FILES_COUNT = "DAS_ECG_FILES_COUNT";
export const DAS_ECG_FILES_SEARCH = "DAS_ECG_FILES_SEARCH";

export const DAS_ECG_FILE_STATISTIC_LOADING = "DAS_ECG_FILE_STATISTIC_LOADING";
export const DAS_ECG_FILE_STATISTIC_SEARCH = "DAS_ECG_FILE_STATISTIC_SEARCH";
export const DAS_ECG_DATE_INDEX_DATA_SEARCH = "DAS_ECG_DATE_INDEX_DATA_SEARCH";
export const DAS_ECG_DATE_INDEX_DATA_REFRESH_FLAG = "DAS_ECG_DATE_INDEX_DATA_REFRESH_FLAG";
export const ECG_HISTORY_REFRESH_DATE_INDEX_EFFECTIVE_RECORDS = "ECG_HISTORY_REFRESH_DATE_INDEX_EFFECTIVE_RECORDS";
export const ECG_HISTORY_REFRESH_DATE_INDEX_RECORD_MAP = "ECG_HISTORY_REFRESH_DATE_INDEX_RECORD_MAP";
export const DAS_ECG_EVENT_INDEX_DATA_SEARCH = "DAS_ECG_EVENT_INDEX_DATA_SEARCH";
export const DAS_ECG_REFRESH_TIME_INDEX_DATA = "DAS_ECG_REFRESH_TIME_INDEX_DATA";
export const DAS_ECG_HISTORY_LOADING = "DAS_ECG_HISTORY_LOADING";
export const DAS_ECG_REFRESH_ANCHOR_MAP = "DAS_ECG_REFRESH_ANCHOR_MAP";
export const ECG_HISTORY_REFRESH_TIME_RECORD_INDEX = "ECG_HISTORY_REFRESH_TIME_RECORD_INDEX";
export const DAS_ECG_DATE_INDEX_DATA_SEARCH_LEFT = "DAS_ECG_DATE_INDEX_DATA_SEARCH_LEFT";
export const DAS_ECG_DATE_INDEX_DATA_SEARCH_RIGHT = "DAS_ECG_DATE_INDEX_DATA_SEARCH_RIGHT";
export const DAS_USER_MOBILE_DATA = "DAS_USER_MOBILE_DATA";
export const DAS_ECG_HISTORY_VERSION_CHANGE = "DAS_ECG_HISTORY_VERSION_CHANGE";

export const DAS_NCOV_LOADING = "DAS_NCOV_LOADING";
export const DAS_NCOV_GET_DATA = "DAS_NCOV_GET_DATA";
export const DAS_NCOV_STATISTIC = "DAS_NCOV_STATISTIC";

export const ECG_LOG_DEVICE_SERVER_LOADING = "ECG_LOG_DEVICE_SERVER_LOADING";
export const ECG_LOG_DEVICE_READ_FAIL_FILES_APP = "ECG_LOG_DEVICE_READ_FAIL_FILES_APP";
export const ECG_LOG_DEVICE_READ_FAIL_FILES_DEV = "ECG_LOG_DEVICE_READ_FAIL_FILES_DEV";
export const ECG_LOG_DEVICE_SERVER_SEARCH = "ECG_LOG_DEVICE_SERVER_SEARCH";
export const ECG_LOG_DEVICE_SERVER_REFRESH_SEARCH_CONDITION = "ECG_LOG_DEVICE_SERVER_REFRESH_SEARCH_CONDITION";
export const ECG_REALTIME_SCREENPOINTSUM = "ECG_REALTIME_SCREENPOINTSUM";
export const ECG_LOG_DEVICE_DOWNLOAD_CONFIRM = "ECG_LOG_DEVICE_DOWNLOAD_CONFIRM";
export const ECG_LOG_DEVICE_DOWNLOAD_FINISH = "ECG_LOG_DEVICE_DOWNLOAD_FINISH";
export const ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS = "ECG_LOG_DEVICE_DOWNLOAD_READ_STATUS";
export const ECG_LOG_DEVICE_DOWNLOAD_VERSION = "ECG_LOG_DEVICE_DOWNLOAD_VERSION";

export const ECG_SYSTEM_LOADING = "ECG_SYSTEM_LOADING";
export const ECG_SYSTEM_REFRESH_PROPERTIES = "ECG_SYSTEM_REFRESH_PROPERTIES";

// 病例
export const ECG_CASE_LOADING = 'ECG_CASE_LOADING';
export const ECG_CASE_SEARCH = 'ECG_CASE_SEARCH';
export const ECG_CASE_REFRESH_CASE = 'ECG_CASE_REFRESH_CASE';
export const ECG_CASE_REFRESH_SEARCH_CONDITION = 'ECG_CASE_REFRESH_SEARCH_CONDITION';
export const ECG_CASE_REFRESH_CASES = "ECG_CASE_REFRESH_CASES";
export const ECG_CASE_TOGGLE_DETAIL_MODAL = 'ECG_CASE_TOGGLE_DETAIL_MODAL';

// ECG Application
export const ECG_APPLICATION_LOADING = "ECG_APPLICATION_LOADING";
export const ECG_APPLICATION_REFRESH_APPLICATION = "ECG_APPLICATION_REFRESH_APPLICATION";

// 设备绑定历史
export const ECG_DEVICE_BIND_HISTORY_LOADING = "ECG_DEVICE_BIND_HISTORY_LOADING";
export const ECG_DEVICE_BIND_HISTORY_REFRESH_SEARCH_CONDITION = "ECG_DEVICE_BIND_HISTORY_REFRESH_SEARCH_CONDITION";
export const ECG_DEVICE_BIND_HISTORY_SEARCH = "ECG_DEVICE_BIND_HISTORY_SEARCH";
export const ECG_DEVICE_BIND_HISTORY_TOGGLE_LIST_MODAL = "ECG_DEVICE_BIND_HISTORY_TOGGLE_LIST_MODAL";

// 监护人绑定历史
export const ECG_GUARDER_BIND_HISTORY_LOADING = "ECG_GUARDER_BIND_HISTORY_LOADING";
export const ECG_GUARDER_BIND_HISTORY_REFRESH_SEARCH_CONDITION = "ECG_GUARDER_BIND_HISTORY_REFRESH_SEARCH_CONDITION";
export const ECG_GUARDER_BIND_HISTORY_SEARCH = "ECG_GUARDER_BIND_HISTORY_SEARCH";
export const ECG_GUARDER_BIND_HISTORY_TOGGLE_LIST_MODAL = "ECG_GUARDER_BIND_HISTORY_TOGGLE_LIST_MODAL";