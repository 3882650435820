export const Colors = {

    // 纯蓝
    Blue: "#0000FF",

    // 午夜的蓝色
    MidnightBlue: "#191970",

    // 深蓝色
    DarkBlue: "#00008B",

    // 道奇蓝
    DoderBlue: "#1E90FF",

    // 绿宝石
    Turquoise: "#40E0D0",

    // 浅黄色
    LightYellow: "#FFFFE0",

    // 金
    Gold: "#FFD700",

    // 秋麒麟
    GoldEnrod: "#DAA520",

    // 沙棕色
    SandyBrown: "#F4A460",

    // 棕色
    Brown: "#A52A2A",

    // 纯白
    White: "#FFFFFF",

    // 白烟
    WhiteSmoke: "#F5F5F5",

    // 亮灰色
    Gainsboro: "#DCDCDC",

    // 	浅灰色
    LightGrey: "#D3D3D3",

    // 银白色
    Silver: "#C0C0C0",

    // 深灰色
    DarkGray: "#A9A9A9",

    // 灰色
    Gray: "#808080",

    // 暗淡的灰色
    DimGray: "#696969",

    // 纯黑
    Black: "#000000",

    SpringGreen: "#3CB371",//春天的绿色
    SeaGreen: "#2E8B57",//海洋绿
    Honeydew: "#F0FFF0",//蜂蜜
    LightGreen: "#90EE90",//淡绿色
    PaleGreen: "#98FB98",//苍白的绿色
    DarkSeaGreen: "#8FBC8F",//深海洋绿
    LimeGreen: "#32CD32",//酸橙绿
    Lime: "#00FF00",//酸橙色
    ForestGreen: "#228B22",//森林绿
    Green: "#008000",//纯绿
    DarkGreen: "#006400",//深绿色
    Chartreuse: "#7FFF00",//查特酒绿
    LawnGreen: "#7CFC00",//草坪绿
    GreenYellow: "#ADFF2F",//绿黄色
};
export default Colors;