import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Card, Table} from 'antd';
import ConvertUtils from "../../../../utils/ConvertUtils";
import {toggleHistoryModal} from "../../actions/ContentAction"
import QRCode from "qrcode.react";
import {
    FORMAT_DATE_TIME_FULL_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN
} from "../../../../constants/DateTimeFormats";

import moment from "moment";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    dataIndex: 'titleImage',
                    key: 'titleImage',
                    width: 70,
                    render: text => {
                        if (text) {
                            return <img style={{width: 50, height: 50}} src={text} alt=""/>;
                        } else {
                            return <img style={{width: 50, height: 50}} src={require("../../../../images/empty.png")}
                                        alt=""/>;
                        }
                    }
                },
                {
                    dataIndex: 'title',
                    key: 'title',
                    render: (text, record) => {
                        return <div>
                            <div><strong>{record.title}</strong></div>
                            <div className="text-muted">{record.subTitle}</div>
                        </div>
                    }
                },
                {
                    dataIndex: 'latestVersionNumber',
                    key: 'latestVersionNumber',
                    width: 250,
                    render: (text, record) => {
                        const contentFileMap = this.props.contentFileMap;
                        return contentFileMap[record.id] &&
                            contentFileMap[record.id].length !== 0 &&
                            contentFileMap[record.id][0].fileVersion;
                    }
                },
                // {
                //     dataIndex: 'remarks',
                //     key: 'remarks',
                //     width: 250,
                //     render: (text, record) => {
                //         const contentFileMap = this.props.contentFileMap;
                //         return contentFileMap[record.id] &&
                //             contentFileMap[record.id].length !== 0 &&
                //             contentFileMap[record.id][0].remarks;
                //     }
                // },
                {
                    dataIndex: 'size',
                    key: 'size',
                    width: 120,
                    render: (text, record) => {
                        const contentFileMap = this.props.contentFileMap;
                        let size = contentFileMap[record.id] &&
                            contentFileMap[record.id].length !== 0 &&
                            contentFileMap[record.id][0].size;
                        if (size) {
                            return ConvertUtils.byteToCapacity(size);
                        } else {
                            return null;
                        }
                    }
                },
                {
                    dataIndex: 'publishTime',
                    key: 'publishTime',
                    width: 135,
                    render: (text, record) => {

                        const contentFileMap = this.props.contentFileMap;
                        const publishTime = contentFileMap[record.id] &&
                            contentFileMap[record.id].length !== 0 &&
                            contentFileMap[record.id][0].createTime;
                        return moment(publishTime, FORMAT_DATE_TIME_FULL_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN);
                        // if (record.createTime){
                        //     return ConvertUtils.timeStrToDate(record.createTime);
                        // }else {
                        //     return null;
                        // }
                    }
                },
                {
                    dataIndex: 'download',
                    key: 'download',
                    render: (text, record) => {
                        const contentFileMap = this.props.contentFileMap;
                        let path = contentFileMap[record.id] &&
                            contentFileMap[record.id].length !== 0 &&
                            contentFileMap[record.id][0].path;
                        if (path) {
                            return <a href={path}>{formatMessage({id: 'COMMON_BUTTON_DOWNLOAD'})}</a>;
                        } else {
                            return null;
                        }
                    }
                },
                {
                    dataIndex: 'hisDownload',
                    key: 'hisDownload',
                    render: (text, record) => {
                        const contentFileMap = this.props.contentFileMap;
                        let contentFileList = contentFileMap[record.id];
                        if (contentFileList && contentFileList.length !== 0) {
                            const basicContent = {};
                            basicContent["id"] = record.id;
                            basicContent["title"] = record.title;
                            basicContent["code"] = record.code;
                            return <a onClick={() => this.openHisVersionModal(basicContent)}>
                                {formatMessage({id: 'COMMON_BUTTON_ELDER_VERSIONS_DOWNLOAD'})}
                            </a>;
                        } else {
                            return null;
                        }
                    }
                },
                {
                    dataIndex: 'QRCode',
                    key: 'QRCode',
                    render: (text, record) => {
                        const contentFileMap = this.props.contentFileMap;
                        let path = contentFileMap[record.id] &&
                            contentFileMap[record.id].length !== 0 &&
                            contentFileMap[record.id][0].path;
                        if (path && record.code.indexOf("APP") > -1) {
                            return <QRCode value={"http://ecgv3.d9ktech.com/APPDownload.html?path=" + path}/>;
                        } else {
                            return null;
                        }
                    }
                },
            ]
        }
    }

    openHisVersionModal(basicContent) {
        this.props.dispatch(toggleHistoryModal(true, basicContent));
    }

    render() {
        const {latestContents} = this.props;
        latestContents.sort((a, b) => a.title.localeCompare(b.title))
        return (
            <Card className="spacing-v" style={{height: 810}}>

                {/*<PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>*/}

                <Table rowKey="id"
                       size="small"
                       className="spacing-v"
                       showHeader={false}
                       columns={this.state.columns}
                       dataSource={latestContents}
                       pagination={{position: "both"}}
                />
                {/*{
                    contents && contents.map((item,index) => {
                        const latestVersionNumber = Object.keys(contentFileMap).length !== 0 && contentFileMap[item.id] &&
                            contentFileMap[item.id].length !== 0 &&
                            contentFileMap[item.id][contentFileMap[item.id].length-1].name
                        return latestVersionNumber;
                    })
                }*/}

            </Card>
        )
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(SearchResult));