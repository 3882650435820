/**
 * Created by Ethan on 2018/1/12.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Pagination} from "antd";

class PaginationBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {

    }

    componentDidUpdate() {

    }

    _handleChange(page, pageSize) {
        this.props.onChange && this.props.onChange.call(this, page, pageSize);
    }

    _handleShowSizeChange(current, pageSize) {
        this.props.onShowSizeChange && this.props.onShowSizeChange.call(this, current, pageSize);
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const pageSizeOptions = ['10', '20', '30', '50', '100', '200'];
        // if (this.props.pagination.recordSum > 100) {
        //     pageSizeOptions.push(this.props.pagination.recordSum.toString());
        // }
        return (
            <div className="text-right">
                <Pagination size="small"
                            pageSize={this.props.pagination.pageSize ? this.props.pagination.pageSize : 0}
                            defaultCurrent={this.props.pagination.currentPage}
                            current={this.props.pagination.currentPage ? this.props.pagination.currentPage : 0}
                            onChange={(page, pageSize) => this._handleChange(page, pageSize)}
                            total={this.props.pagination.recordSum ? this.props.pagination.recordSum : 0}
                            showTotal={(total) => formatMessage({id: "COMMON_FIELD_PAGINATION_TOTAL"}, {sum: total})}
                            pageSizeOptions={pageSizeOptions}
                            showSizeChanger={this.props.showSizeChanger}
                            onShowSizeChange={(current, pageSize) => this._handleShowSizeChange(current, pageSize)}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}


export default connect(mapStateToProps)(injectIntl(PaginationBar));