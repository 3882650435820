/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Layout} from "antd";
import DistrictTree from "../../components/DistrictTree";
import Map from "./Map";
import {
    getCountryTree,
    refreshCountryTreeBySelectCity,
    refreshCountryTreeBySelectCounty, refreshCountryTreeBySelectProvincial, refreshCountryTreeBySelectTown
} from "../../actions/CountryAction";

let defaultZoom = 5;
let bmap;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentDidMount() {
        bmap = new window.BMap.Map("allmap");
        bmap.centerAndZoom(new window.BMap.Point(121.50, 31.33), defaultZoom);  // 初始化地图,设置中心点坐标和地图级别
        bmap.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    }

    _handleTreeNodeSelect(selectedKey, info) {
        console.log(selectedKey);
        console.log(info);

        const {provincialMap, cityMap, countyMap, townMap,villageMap } = this.props;

        let value = "";
        if (info.node.props.pos.split("-").length === 3) {
            // 选择了省份节点
            value = info.node.props.title;
        } else if (info.node.props.pos.split("-").length === 4) {
            // 选择了城市节点
            const city = cityMap[info.node.props.eventKey];
            const provincial = provincialMap[city.provincialId];
            value = provincial.name + city.name;
        } else if (info.node.props.pos.split("-").length === 5) {
            // 选择了区县节点
            const county = countyMap[info.node.props.eventKey];
            const city = cityMap[county.cityId];
            const provincial = provincialMap[city.provincialId];
            value = provincial.name + city.name + county.name;
        } else if (info.node.props.pos.split("-").length === 6) {
            // 选择了镇节点
            const town = townMap[info.node.props.eventKey];
            const county = countyMap[town.countyId];
            const city = cityMap[county.cityId];
            const provincial = provincialMap[city.provincialId];
            value = provincial.name + city.name + county.name + town.name;
        }else if (info.node.props.pos.split("-").length === 7) {
            // 选择了村节点
            const village = villageMap[info.node.props.eventKey];
            const town = townMap[village.townId];
            const county = countyMap[town.countyId];
            const city = cityMap[county.cityId];
            const provincial = provincialMap[city.provincialId];
            value = provincial.name + city.name + county.name + town.name + village.name;
        }

        const self = this;
        const options = {
            onSearchComplete: function (results) {
                if (local.getStatus() === window.BMAP_STATUS_SUCCESS) {

                    bmap.clearOverlays();
                    // 判断状态是否正确
                    const s = [];
                    console.log(results);
                    for (let i = 0; i < results.getCurrentNumPois(); i++) {
                        // if (i === 0) {
                        //     bmap.centerAndZoom(new window.BMapGL.Point(results.getPoi(i).point.lng, results.getPoi(i).point.lat), 19);
                        // }
                        s.push(results.getPoi(i));

                        // let myIcon = new window.BMapGL.Icon(url,
                        //     new window.BMapGL.Size(25, 25), {
                        //         imageSize: new window.BMapGL.Size(25, 25),
                        //         // offset: new window.BMapGL.Size(10, 21),
                        //         // imageOffset: new window.BMapGL.Size(0 , 34)
                        //     });
                        //
                        // // 创建标注
                        const point = new window.BMap.Point(results.getPoi(i).point.lng, results.getPoi(i).point.lat);
                        const marker = new window.BMap.Marker(point, {title: value});
                        // markerList.push(marker);
                        bmap.addOverlay(marker);
                        bmap.centerAndZoom(point, 12);
                    }
                    // bmap.setViewport(point);
                    // self.setState({searchResult: s});
                    // self.props.dispatch(refreshBMapSearchResultList(s));
                }
            }
        };
        const local = new window.BMap.LocalSearch(bmap, options);
        local.search(value);
    }

    render() {
        return (
            <div className="page-content">
                <Layout>
                    <Layout.Sider width={300}
                                  style={{background: '#fff', borderRight: '1px solid #e8e8e8', height: '100%'}}>
                        <DistrictTree onSelect={(selectedKey, info) => this._handleTreeNodeSelect(selectedKey, info)}/>
                    </Layout.Sider>
                    <Layout.Content>
                        <Map bmap={bmap}/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        provincialMap: store.DistrictProvincialReducer.provincialMap,
        cityMap: store.DistrictCityReducer.cityMap,
        countyMap: store.DistrictCountyReducer.countyMap,
        townMap: store.DistrictTownReducer.townMap,
        villageMap: store.DistrictVillageReducer.villageMap
    }
}

export default connect(mapStateToProps)(injectIntl(Index));