/**
 * 名称	字节数	范围	说明
 * HistoryFileIndex	4B	0-232-1	历史数据索引编号
 * EventRecordIndex	4B	0-232-1	对应的事件记录编号，指向此时刻对应的第1条事件索引记录
 */

class EcgFileTimeIndexRecord {
    constructor(dataArray, startPos) {
        let index = startPos;
        const historyFileIndexArray = dataArray.slice(index, index + 4);
        this.historyFileIndex = new Int32Array(historyFileIndexArray.buffer.slice(-4))[0];;
        index = index + 4;

        const eventRecordIndexArray = dataArray.slice(index, index + 4);
        this.eventRecordIndex = new Int32Array(eventRecordIndexArray.buffer.slice(-4))[0];;
        index = index + 4;

        this.length = index - startPos;
    }
}

export default EcgFileTimeIndexRecord;