/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Row, Col} from "antd";

import PageTitle from "../../../../../components/PageTitle";
import DevicesSum from "./DevicesSum";
import DevicesActiveSum from "./DevicesActiveSum";
import BindersSum from "./BindersSum";
import BinderDataSum from "./BinderDataSum";

import './Monitor.css';

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    render() {
        return (
            <div className="page-content">
                <PageTitle title="DAS_DASHBOARD_ROUTE_STATISTICS"/>
                <Row gutter={16}>
                    <Col span={6}>
                        <DevicesSum/>
                    </Col>
                    <Col span={6}>
                        <DevicesActiveSum/>
                    </Col>
                    <Col span={6}>
                        <BindersSum/>
                    </Col>
                    <Col span={6}>
                        <BinderDataSum/>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));