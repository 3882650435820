/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';
import {Row, Col, Form, Button, Select, Checkbox, Badge, Icon, Switch, Modal, Divider} from 'antd';

import message from "../../../../../components/toast";
import {LiveDataChannel, ChartSpeed, EcgFileType, ProtocolPackageType, VoltDiv, leadName} from "../../../Enums";
import {getAllDevices, getDeviceById, toggleDetailModal} from "../../../actions/DeviceAction";
import {
    ECG_SOCKET_SERVER_HOST,
    LOG_SOCKET_SERVER_HOST,
    MQTT_HOST, PERFORMANCE_SOCKET_SERVER_HOST,
    SYSTEM_INFO_SOCKET_SERVER_HOST, TERMINAL_SOCKET_PATH
} from "../../../../../constants/Profile";
import ECGPoint from "../../../entities/ECGPoint";
import LogListModal from "./LogListModal";
import SearchCondition from "./SearchCondition";

import moment from "moment";
import {
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_FULL_SLASH, FORMAT_DATE_TIME_SIMPLE,
    FORMAT_DATE_TIME_SLASH
} from "../../../../../constants/DateTimeFormats";
import HttpUtils from "../../../../../utils/HttpUtils";
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import DetailModal from "../../Device/Monitor/DetailModal";
import {initDeviceLogs} from "../../../actions/LogDataServerAction";
import DeviceDetailDrawer from "./DeviceDetailDrawer";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import WebSocketUtils from "../../../utils/WebSocketUtils";
import ProtocolCommonHeader from "../../../entities/ProtocolCommonHeader";
import EcgFileLiveData from "../../../entities/EcgFileLiveData";
import mqtt from "mqtt";
import RandomUtils from "../../../../../utils/RandomUtils";
import EcgRouteEventFile from "../../../entities/EcgRouteEventFile";
import HuffmanDecode from "../../../utils/HuffmanDecode";

// MQTT的日志汇报对象
let mqttLogReporter;

// 一个导联的svg图高度
let winH;
// 一个导联的svg图宽度，svg的图片宽度是100%，但在绘制网格底图纵线的时候，无法知道绘制多少条的情况下，设定一个屏幕够大的宽度
let winW;

let lastX = [];
let timeV = 0;

// 采样率
// 标准值：500samples/s
// 可选值：250samples/s，1000samples/s
const sampRate = 500;

// 下采样率，默认值5，从设备中获取后改变
let downRatio = 5;

// 通过采样率和下采样率的关系，算出每秒实际多少个点
let dataRate = sampRate / downRatio;
let preTimeVs = [1, 1, 1, 1, 1, 1, 1, 1]

let iii = 0
let preTime;
let YearMonth = new Date().toJSON().substr(0, 11);

let preTimeIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
let preTimeIndexApp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

// 格子尺度【GridScale】
// 	5个小格构成一个大格
// 标准值：1mm/小格
// 可选值：根据具体的显示设备的屏幕尺寸来设置，APP/Web中，该值是可变参数。
// 	例如：1.2mm/小格，1.5mm/小格，2.0mm/小格子。
// 此处默认以大格子作为显示单位
const gridScale = 5;

let time = 0
let maxLiveId = 0;
let minLiveId = 10000000000000;


let liveIdFlag = [];
let liveIdFlagApp = [];
let lastLiveId = [];
let lastLiveIdApp = [];

// 格子的缩放
const gridRatio = 1;

// 走纸速度, 标准值：25mm/s
// 可选值：以标准走纸速度的x0.5倍速，x2倍速，x4倍速，x8倍速即可。用不着无极调速，也用不着x3、x5倍速。即12.5mm/s，50mm/s，100mm/s和200mm/s
const chartSpeed = 25;

// 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
let gridPointNum = dataRate / (chartSpeed / gridScale);

// // 一个格子代表0.5mV
// const gridVolt = 1 / (voltDiv / gridScale);

// Gain=2^23*6/2500=20132.6592 1/mV
// const gain = 20132.6592;
// const gain = 20132.6592 / 4;

// 其中Ga是ADS1298的增益
const ga_ch1 = 4;
const ga_ch2_8 = 6;

// Gb为王博除基算法lib的增益，目前为1
const gb = 1;

// 增益系数【Gc】
// 增益系数Gc的计算公式为：
//         5000/Ga*Gc=(2^24)*Gb；
//         所以Gc=(2^24)*Ga*Gb/5000
// 为了在APP、Web上进行缩放显示，显示前，APP/Web可能对数据进行缩放，该缩放系数为Gf。
// 	 定义Gd=Gc*Gf。
//
//         其中Ga为ADS1298的配置增益，
//                Gb为王博除基算法lib的增益，目前为1   @王博
// N表示表示数据的bit数。
const gc_ch1 = (Math.pow(2, 24) * ga_ch1 * gb) / 5000;
const gc_ch2_8 = (Math.pow(2, 24) * ga_ch2_8 * gb) / 5000;

let gf_ch1;
let gf_ch2_8;

let heartFlag = false;
let heartFlagApp = false;

//  为了在APP、Web上进行缩放显示，显示前，APP/Web可能对数据进行缩放，该缩放系数为Gf。
// 	定义Gd=Gc*Gf。
let dg_ch1;
let dg_che2_8;

// 算出横向格子数
let xCellCount;
// 每个导联占据纵向格子数
const yCellCount = 6;

// 算出每个格子的宽度
let cellWidth;
// 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
// 计算得出点之间的x间距
let pointSpace;

// 算出0点，以下是负值，以上是正直，需要变换数据
let zeroPointY;

let webSocket;
let websocketApp;
let logWebSocket;
let performanceWebSocket;
let systemInfoWebSocket;

// 绘图的缓冲区
// 导联和该导联的点ECGPoint所组成的一个二维数组，其长度会在画面初始化时，根据xCellCount进行实力化
// 1、	开一个总长为A4的FIFO/Buffer，4个参数A4\A3\A2\A1。数据从右侧进来
const drawBufferMapDevice = {};
const drawBufferMapApp = {}; // APP通道

const drawBufferMapStore = [];

// 为了浮动计算rfsNum，需要计算每秒的刷新效率是否达到预期
// let winFpsDeviceCount = 1;
// let winFpsDeviceCountResult = 25;
// let winFpsAppCount = 1;
// let winFpsAppCountResult = 25;

// 8个导联的数据
const numLeads = 12;

// 窗口刷新率，每40ms刷新一次走纸
const winFps = 50;
let preTimeVList = [dataRate, dataRate,
    dataRate, dataRate, dataRate, dataRate, dataRate, dataRate]

let jiange = 0;
let jiange2 = 0;

//每次更新的数据点的数量
let baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
let baseRfsNumApp = (sampRate / downRatio) / (1000 / winFps);
let rfsNum = baseRfsNum;
let rfsNumApp = baseRfsNumApp;

// 定标一个div为100mm宽度，算出相对应的pixel
const unitWidthMill = 1;
let preTimeV = 0;

// 绘图窗口的Y轴Margin
const winMarginX = 75;
const winMarginY = 20;

// 缓冲容器的尺寸，比如缓冲一屏
// 3、	A2是缓冲长度，就和看电影一开始要缓冲一段一样。
// 开始采集实时数据后，当数据点数量>=A2时，开始波形描绘。
let bufferSizeA2;
// 缓存阈值
// 	两个因素造成当前FIFO中数据数量N的波动：
// 1）通信造成的数据波动。【主要因素】
// 2）随着时间流逝，异步系统时钟误差造成数据+1或-1。【异步时钟问题】
// 所以N会变化。
// 处理方式很简单：
// 1）A1≤N≤A3   （即在蓝色框范围内）      每次取RfsNum=4个点描绘
// 2）A1>N       （即在蓝色框右侧）         每次取RfsNum-1=3个点描绘
// 3）A3<N       （即在蓝色框左侧）       每次取RfsNum+1=5个点描绘
let bufferThresholdA1;
let bufferThresholdA3;

let manualStopSearch = false;
let manualStopSearchApp = false;
// 是否手动关闭了log记录操作
let manualStopWriteLog = false;

// SVG 线的背景颜色
const lineColors = ['#000000', '#00CED1', '#0000FF', '#D2691E', '#000000', '#00CED1', '#0000FF', '#D2691E', '#0000FF', '#D2691E', '#000000', '#00CED1'];

// 一屏幕能绘画的点数量
let screenPointSum;

// 缓冲区大小过低的标识，只有达到一定的缓冲值，才开始描画
let bufferLowFlag = false;
let bufferLowFlagApp = false;

let firstFlag = true;

// 无损压缩对象
let huffmanDecode;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            deviceCode: "",
            searchFlag: false,
            channelType: "0",
            channelTypeDevice: false,
            channelTypeAPP: true,
            backgroundDisplay: true,
            // 定标电压, 标准值：10mm/mV, 可选值： 1/4缩放，1/2缩放，2倍缩放，4倍缩放；对应2.5mm/mV、5mm/mV、20mm/mV、40mm/mV
            // 一个格子是5mm，所以一个格子代表0.5mv
            voltDiv: 10,
            // 走纸倍数
            chartSpeedTimes: 1,
            // 警告数据，如果live id，tcp id不连续，需要写入警告日志
            logs: [],
            // Web和APP端数据传输的警告信息
            logsWeb: [],
            logsApp: [],
            showLogModal: false,
            receiveDataWrite: false,
            combinePaint: false,
            // 记录绘制掉的点，以供Debug比较
            writeLogFlag: false,
            writeLogSocketStatus: "",
            // 记录数据时间
            receiveDrawTime: false,
            // 记录描绘动态参数
            drawParamsWrite: false,
            // 本地时钟的测试
            localClockWrite: false,
            toggleDeviceDetail: false,
            bufferLengthWeb: 0,
            bufferLengthApp: 0,
            rfsNum: 0,
            rfsNumApp: 0,
            performanceReportFlag: false,
            systemInfo: {}
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllDevices(this.state.formatMessage));
        this.setState({deviceCode: this.props.deviceCode});
        console.log("this.props.deviceCode:", this.props.deviceCode);
        for (let i = 0; i < 100; i++) {
            drawBufferMapStore.push(new ECGPoint());
        }

        let start = HttpUtils.getQueryString("start");
        console.log("start:" + start);
        if (start !== undefined) {
            this._handleSearch();
        }

        // this._performanceMqttConnect();

        // 读取本地的无损压缩字典文件
        huffmanDecode = new HuffmanDecode();
        huffmanDecode.initByLoadDictFile();
    }

    _performanceMqttConnect(mac) {

        // EMQX发送参数：
        //
        // URI=  tcp://140.206.121.162:7766
        // userName=  mgg.shao
        // password= mochan.shao
        // QoS= 2
        // topic=  D9K_MQTT/uuid1/uuid2
        // 其中
        // D9K_MQTT和斜杠都是固定显示文字
        // uuid1=当前PC网卡的mac地址的base64后的字符串，作为PC唯一识别
        // uuid2=每次C#程序连上MQTT服务器后采集一个UUID设置在这里
        // mqtt 连接"receive"
        mqttLogReporter = mqtt.connect(MQTT_HOST);
        mqttLogReporter.on('connect', function (err) {
            // mqttLogReporter.subscribe(topic, function (err) {
            //     console.log(err);
            //     if (!err) {
            //         console.log("subscribe successed");
            //     } else {
            //         console.log("subscribe failed");
            //     }
            // });
        });

        //mqtt 接收信息
        mqttLogReporter.on('message', function (topic, message) {
            // message is Buffer
            let obj = JSON.parse(message.toString());
            console.log("message:" + message);
            console.log("obj:" + obj);
        });
    }

    _performanceMQTTReport(topic, performance, browser) {

        let processorTime = 0;
        let workingSet = 0;

        for (let item of performance.processorTimes) {
            processorTime += item;
        }

        for (let item of performance.workingSets) {
            workingSet += item;
        }

        const params = {
            // 当前working set的个数
            "activeTaskCount": performance.workingSets.length,
            // 空
            "appVersion": "",
            // 0
            "corePoolSize": "",
            // 当前时间
            "datetime": moment().format(FORMAT_DATE_TIME_SLASH),
            // 空
            "guardMode": "",
            // 当前working set 内存占用总和，MB
            "memory": (workingSet / 1024) / 1024,
            // 空
            "phoneBrand": "",
            // 空
            "phoneModel": "",
            // 空
            "phoneNumber": "",
            // 当前PC的IP地址
            "ip": this.state.systemInfo.ip,
            // 当前PC的Windows设定ComputerName
            "computerName": this.state.systemInfo.computerName,
            // working set name
            "browser": browser,
            // 当前working set CPU占用总和，%
            "cpu": processorTime
        };

        mqttLogReporter && mqttLogReporter.publish(topic, JSON.stringify(params), function (err) {
            console.log(err);
        });
        this._handleWriteLog(JSON.stringify(params));
    }

    _handleResize = () => {
        this._calcParams(this.state.combinePaint);

        this._handleChangeBackgroundDisplay(this.state.backgroundDisplay);
    };

    _calcParams(combinePaintFlag) {

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        console.log(ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);

        // 定标mm和px的换算
        const unitWidth = document.getElementById("unit_width");
        console.log("Width:" + unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        console.log("Height" + unitWidth.style.height, unitWidth.clientHeight, unitWidth.offsetHeight, unitWidth.scrollHeight);

        winW = ecgViewWrapNode.clientWidth / (unitWidth.clientWidth / unitWidthMill);
        console.log("winW " + winW + " mm");

        // 每个格子的宽度
        cellWidth = gridScale * (unitWidth.clientWidth / unitWidthMill);
        console.log("cellWidth " + cellWidth + " px");

        // 格子的数量，向下取整
        xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        console.log("xCellCount " + xCellCount);

        // 通过采样率和下采样率的关系，算出每秒实际多少个点
        dataRate = sampRate / downRatio;
        console.log("downRatio " + downRatio);
        console.log("dataRate " + dataRate);

        // 通过走纸速度和格子的实际宽度，算出每个格子包含多少个点
        gridPointNum = dataRate / (chartSpeed / gridScale);
        console.log("gridPointNum " + gridPointNum);

        // 按500点/秒的频率采集，一个格子0.2S，则每个格子描画100个点
        // 计算得出点之间的x间距
        pointSpace = cellWidth / gridPointNum;
        console.log("pointSpace " + pointSpace);

        // 算出0点，以下是负值，以上是正直，需要变换数据
        zeroPointY = (yCellCount - 3) * cellWidth + winMarginY;
        console.log("zeroPointY " + zeroPointY);

        //每次更新的数据点的数量
        baseRfsNum = (sampRate / downRatio) / (1000 / winFps);
        baseRfsNumApp = (sampRate / downRatio) / (1000 / winFps);
        rfsNum = baseRfsNum;
        rfsNumApp = baseRfsNumApp;
        console.log("rfsNum " + rfsNum);

        // 计算出一屏的点总数
        screenPointSum = gridPointNum * xCellCount + this.props.screenPointSum;
        console.log("一屏的描画点数：", screenPointSum);
        // if (!firstFlag)
        //     screenPointSum = screenPointSum + 180;

        bufferSizeA2 = 3 * dataRate;
        bufferThresholdA1 = 2 * dataRate;
        bufferThresholdA3 = 6 * dataRate;

        console.log("缓存阈值A1：", bufferThresholdA1);
        console.log("缓冲长度A2：", bufferSizeA2);
        console.log("缓存阈值A3：", bufferThresholdA3);

        if (combinePaintFlag) {
            // 合并在一张画板上，则需要按导联数计算高度
            winH = yCellCount * numLeads * cellWidth + 50;
        } else {
            winH = yCellCount * cellWidth + 50;
        }

        // 纵轴总共多少mv
        const winDataH = (gridScale / this.state.voltDiv) * yCellCount;
        const scrH = unitWidth.clientHeight;
        const scrSizeH = unitWidthMill;

        gf_ch1 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch1) / winH);
        gf_ch2_8 = (((this.state.voltDiv * gridRatio * scrH * winDataH / scrSizeH) / gc_ch2_8) / winH);

        // console.log("gf_ch1 " + gf_ch1);
        // console.log("gf_ch2_8 " + gf_ch2_8);

        dg_ch1 = gc_ch1 * gf_ch1;
        dg_che2_8 = gc_ch2_8 * gc_ch2_8;

        // firstFlag = false
        // window.firstFlag
    }

    componentDidMount() {

        // 如果路径中带了deviceCode参数，则自动填入
        const deviceCode = HttpUtils.getQueryString("deviceCode");
        console.log("deviceCode:", deviceCode)
        if (deviceCode !== undefined && deviceCode !== "") {
            this.setState({deviceCode: deviceCode});
        }

        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);

        //
        // // 定标mm和px的换算
        // const unitWidth = document.getElementById("unit_width");
        // console.log(unitWidth.style.width, unitWidth.clientWidth, unitWidth.offsetWidth, unitWidth.scrollWidth);
        //
        // winW = ecgViewWrapNode.clientWidth / unitWidth.clientWidth;
        // console.log("winW " + winW + " mm");
        //
        // // 每个格子的宽度
        // cellWidth = gridScale * unitWidth.clientWidth;
        // console.log("cellWidth " + cellWidth + " px");
        //
        // // 格子的数量，向下取整
        // xCellCount = Math.floor(ecgViewWrapNode.clientWidth / cellWidth);
        // console.log("xCellCount " + xCellCount);
        //
        // winH = yCellCount * cellWidth + 50;

        this._calcParams(this.state.combinePaint);
        this._initDrawBuffer();
        // this._initDrawBufferApp();
        // for (let i = 0; i < numLeads; i++) {
        //
        //     // 初始化固定长度的绘画缓冲区
        //     drawBufferMap[i] = new Array(screenPointSum);
        //     for (let j = 0; j < drawBufferMap[i].length; j++) {
        //         drawBufferMap[i][j] = new ECGPoint();
        //     }
        // }
        //
        this._drawECG(this.state.combinePaint, this.state.channelTypeDevice, this.state.channelTypeAPP);
        // this._drawECGApp(this.state.combinePaint, this.state.channelTypeAPP);
    }

    _drawEcgChainCombine(i, channelTypeDevice, ecgViewContent) {
        let result = "";
        let ecgViewTextPrefix = "";
        if (channelTypeDevice) {
            ecgViewTextPrefix = "D";
        } else {
            ecgViewTextPrefix = "A";
        }
        const offsetY = i * yCellCount * cellWidth;

        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "'" + " transform='translate(0 " + offsetY + ")' " + "'>";

        const ecgViewText = this._getECGViewText(i, ecgViewTextPrefix);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(i) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        result = ecgViewContent + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd;

        return result;
    }

    _drawEcgChain(i, channelTypeDevice, channelTypeAPP, ecgViewWrapNode) {
        let index = i;
        let ecgViewTextPrefix = "";
        if (channelTypeDevice && channelTypeAPP) {
            if (i % 2 === 0) {
                ecgViewTextPrefix = "D";
            } else {
                ecgViewTextPrefix = "A";
            }

            index = Math.floor(i / 2);
        } else if (channelTypeDevice) {
            ecgViewTextPrefix = "D";
        } else {
            ecgViewTextPrefix = "A";
        }

        // // 初始化固定长度的绘画缓冲区
        // drawBufferMap[i] = new Array(screenPointSum);
        // for (let j = 0; j < drawBufferMap[i].length; j++) {
        //     drawBufferMap[i][j] = new ECGPoint();
        // }

        // 创建SVG容器标签
        const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
        // 创建SVG的Group标签
        const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";

        const ecgViewText = this._getECGViewText(index, ecgViewTextPrefix);

        // 创建心电图背景图
        const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
        const ecgViewBackgroundEnd = "</g>";
        const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;

        // 创建心电图波形
        const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
        const ecgViewLightWaveEnd = "</g>";
        // 创建心电图波形
        const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(index) + ecgViewLightWaveEnd;

        const ecgViewGroupEnd = "</g>";
        const ecgViewEnd = "</svg>";

        ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
    }

    _drawECG(combinePaintFlag, channelTypeDevice, channelTypeAPP) {
        console.log("实时心电")
        let numLeadsSum = numLeads;
        let numLeadsStartIndex = 1;
        let ecgViewTextPrefix = "";
        if (channelTypeDevice && channelTypeAPP) {
            numLeadsSum += numLeads;
            numLeadsStartIndex++;
        }

        // 得到EcgView的容器标签
        const ecgViewWrapNode = document.getElementById("ecg_view_wrap");
        ecgViewWrapNode.innerHTML = "";
        // console.log("ECG View(with, clientWidth, offsetWidth, scrollWidth):", ecgViewWrapNode.style.width, ecgViewWrapNode.clientWidth, ecgViewWrapNode.offsetWidth, ecgViewWrapNode.scrollWidth);
        if (combinePaintFlag) {
            // 创建SVG容器标签
            const ecgViewStart = "<svg id='ecg_view_0' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";

            let ecgViewContent = "";
            for (let i = 1; i < numLeads; i++) {

                ecgViewContent = this._drawEcgChainCombine(i, channelTypeDevice, ecgViewContent);
            }
            ecgViewContent = this._drawEcgChainCombine(0, channelTypeDevice, ecgViewContent);

            const ecgViewEnd = "</svg>";
            ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewContent + ecgViewEnd;
        } else {
            for (let i = numLeadsStartIndex; i < numLeadsSum; i++) {

                // let index = i;
                // if (channelTypeDevice && channelTypeAPP) {
                //     if (i % 2 === 0) {
                //         ecgViewTextPrefix = "D";
                //     } else {
                //         ecgViewTextPrefix = "A";
                //     }
                //
                //     index = Math.floor(i / 2);
                // } else if (channelTypeDevice) {
                //     ecgViewTextPrefix = "D";
                // } else {
                //     ecgViewTextPrefix = "A";
                // }
                //
                // // // 初始化固定长度的绘画缓冲区
                // // drawBufferMap[i] = new Array(screenPointSum);
                // // for (let j = 0; j < drawBufferMap[i].length; j++) {
                // //     drawBufferMap[i][j] = new ECGPoint();
                // // }
                //
                // // 创建SVG容器标签
                // const ecgViewStart = "<svg id='ecg_view_" + i + "' xmlns='http://www.w3.org/2000/svg  xmlns:xlink=http://www.w3.org/1999/xlink' className='svgplot' width='100%' height='" + winH + "' preserveAspectRatio='xMidYMid meet'>";
                // // 创建SVG的Group标签
                // const ecgViewGroupStart = "<g id='ecg_view_group_" + i + "' >";
                //
                // const ecgViewText = this._getECGViewText(index, ecgViewTextPrefix);
                //
                // // 创建心电图背景图
                // const ecgViewBackgroundStart = "<g id='ecg_view_background_group_" + i + "'>";
                // const ecgViewBackgroundEnd = "</g>";
                // const ecgViewBackground = ecgViewBackgroundStart + this._getECGViewBackground() + ecgViewBackgroundEnd;
                //
                // // 创建心电图波形
                // const ecgViewLightWaveStart = "<g id='ecg_view_lightwave_group_" + i + "'>";
                // const ecgViewLightWaveEnd = "</g>";
                // // 创建心电图波形
                // const ecgViewLightWave = ecgViewLightWaveStart + this._initEcgViewLightWave(index) + ecgViewLightWaveEnd;
                //
                // const ecgViewGroupEnd = "</g>";
                // const ecgViewEnd = "</svg>";
                //
                // ecgViewWrapNode.innerHTML += ecgViewStart + ecgViewGroupStart + ecgViewText + ecgViewBackground + ecgViewLightWave + ecgViewGroupEnd + ecgViewEnd;
                this._drawEcgChain(i, channelTypeDevice, channelTypeAPP, ecgViewWrapNode);
            }

            // 把设备端和手机端的第一导联数据移到最后
            this._drawEcgChain(0, channelTypeDevice, channelTypeAPP, ecgViewWrapNode);
            if (numLeadsStartIndex === 2) {
                this._drawEcgChain(1, channelTypeDevice, channelTypeAPP, ecgViewWrapNode);
            }
        }
    }

    componentWillUnmount() {
        mqttLogReporter && mqttLogReporter.end();
        this._handleStopSearch();
        window.removeEventListener('resize', this._handleResize);
    }

    render() {
        const formatMessage = this.state.formatMessage;

        return (
            <div style={{padding: 5, width: '100%', height: '90%'}}>
                <DetailModal/>
                {/*<SearchCondition/>*/}
                <div id="unit_width" style={{
                    width: unitWidthMill + 'mm',
                    height: unitWidthMill + 'mm',
                    position: 'absolute',
                    zIndex: -1
                }}>
                </div>
                <LogListModal visible={this.state.showLogModal} logs={this.state.logs}
                              onClose={() => this.setState({showLogModal: false})}/>
                <Row style={{width: '100%', height: '30px', backgroundColor: "#FFF"}}>
                    <Col span={2} style={{
                        width: '70%', height: '30px', backgroundColor: "#FFF",
                    }}>
                        <Form layout="inline">
                            <Form.Item>
                                {/*<Select style={{width: '150px'}}*/}
                                {/*        placeholder={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}*/}
                                {/*        value={this.props.deviceCode}*/}
                                {/*        onChange={(value) => {*/}
                                {/*            this._handleChange("code", value)*/}
                                {/*        }}>*/}
                                {/*    {*/}
                                {/*        this.props.allDevices.map((item, index) => {*/}
                                {/*            return <Select.Option key={index}*/}
                                {/*                                  value={item.code}>{item.code}</Select.Option>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</Select>*/}

                            </Form.Item>
                            <Form.Item>
                                {/*<span className="spacing-h"*/}
                                {/*      onClick={() => this._handleShowDeviceModal()}>*/}
                                {/*    <Icon type="question-circle" style={{fontSize: '20px'}}/>*/}
                                {/*</span>*/}
                                <b>播放功能：</b>
                                <Button type="primary" loading={this.state.searchFlag} icon="search"
                                        className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH2'/>
                                </Button>
                                <Button icon="stop" className="spacing-h"
                                        onClick={() => this._handleStopSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_STOP'/>
                                </Button>
                                {/*<DictItemRadio data={ChannelType.List} defaultValue={ChannelType.Enum.Device}*/}
                                {/*onChange={(value) => this.setState({channelType: value.target.value})}/>*/}
                                <Checkbox checked={this.state.channelTypeDevice}
                                          onChange={(value) => this._handleChangeChannelTypeDevice(value.target.checked)}>
                                    设备
                                </Checkbox>
                                <Checkbox checked={this.state.channelTypeAPP}
                                          onChange={(value) => this._handleChangeChannelTypeApp(value.target.checked)}>
                                    手机APP
                                </Checkbox>
                                <Checkbox checked={this.state.backgroundDisplay}
                                          onChange={(value) => this._handleChangeBackgroundDisplay(value.target.checked)}>显示底图格子</Checkbox>

                                {/*<EnumItemSelect*/}
                                {/*style={{width: '100px', marginRight: '10px'}} allowClear={false}*/}
                                {/*data={ChartSpeed.List} value={this.state.chartSpeedTimes}*/}
                                {/*onChange={(value) => {*/}
                                {/*this.setState({chartSpeedTimes: value});*/}
                                {/*}}/>*/}
                                <EnumItemSelect
                                    style={{width: '100px', marginRight: '10px'}} allowClear={false}
                                    data={VoltDiv.List} value={this.state.voltDiv}
                                    onChange={(value) => this._handleChangeVoltDiv(value)}/>
                                <Checkbox checked={this.state.combinePaint}
                                          onChange={(value) => this._handleSwitchCombinePaint(value)}>合并画布</Checkbox>


                            </Form.Item>
                        </Form>
                    </Col>
                    {this._renderDebugPart()}
                </Row>
                {/*<div>*/}
                {/*<Checkbox checked={this.state.writeLogFlag}*/}
                {/*onChange={(value) => this._handleWriteLogFlagChange(value.target.checked)}>记录日志{this.state.writeLogSocketStatus}</Checkbox>*/}
                {/*<Checkbox checked={this.state.receiveDrawTime}*/}
                {/*onChange={(value) => this._handleReceiveDrawTimeChange(value.target.checked)}>接收和描绘的时间记录</Checkbox>*/}
                {/*<Checkbox checked={this.state.drawParamsWrite}*/}
                {/*onChange={(value) => this._handleDrawParamsWriteChange(value.target.checked)}>描绘刷新参数记录</Checkbox>*/}
                {/*<Checkbox checked={this.state.receiveDataWrite}*/}
                {/*onChange={(value) => this._handleReceiveDataWriteChange(value.target.checked)}>接收数据完整记录</Checkbox>*/}
                {/*<Checkbox checked={this.state.localClockWrite}*/}
                {/*onChange={(value) => this._handleLocalClockWrite(value.target.checked)}>本地时钟值记录</Checkbox>*/}

                {/*</div>*/}
                {this._renderEcgDebugInfo()}

                <Divider
                    style={{
                        top: 85,
                        position: "absolute",
                        cursor: "pointer",
                        paddingLeft: 5,
                        fontSize: 12,
                        fontWeight: "bold"
                    }}
                    dashed={true} plain/>
                <DeviceDetailDrawer visible={this.state.toggleDeviceDetail} setVis={this._setVis}/>
                <div id="ecg_view_wrap" style={{width: '100%', height: '100%', paddingTop: '10px'}}>
                </div>
            </div>
        );
    }

    _setVis = value => {
        this.setState({
            toggleDeviceDetail: value,
        });

    };

    _renderDebugPart() {
        const isExternalUser = (this.props.user && this.props.user.roles && this.props.user.roles[0] && this.props.user.roles[0].code === 'ROLE_EXTERNAL') || !this.props.showRoles;
        if (isExternalUser) {
            return null;
        } else {
            return (
                <Col span={2} style={{
                    width: '350px', height: '30px', backgroundColor: "#ffffcc", float: "right"
                }}>
                    {/*<div style={{float: "right", marginLeft: "400px", backgroundColor: "#ffffcc"}}>*/}
                    <b>调试功能：</b>
                    <span className="spacing-h"
                          onClick={() => this.setState({logs: this.state.logsWeb, showLogModal: true})}>
                        <Badge count={this.state.logsWeb.length} overflowCount={9999}>
                        <Icon type="wifi" style={{fontSize: '20px'}}/>
                        </Badge>
                        </span>
                    <span className="spacing-h"
                          onClick={() => this.setState({logs: this.state.logsApp, showLogModal: true})}>
                        <Badge count={this.state.logsApp.length} overflowCount={9999}>
                            <Icon type="android" style={{fontSize: '20px'}}/>
                        </Badge>
                        </span>
                    <Switch
                        checkedChildren="性能监控"
                        unCheckedChildren="性能监控"
                        checked={this.state.performanceReportFlag}
                        onChange={(value) => this._handleTogglePerformanceReport(value)}/>
                    <a
                        href={TERMINAL_SOCKET_PATH}>Terminal
                        Socket <FormattedMessage id={"COMMON_BUTTON_DOWNLOAD"}/></a>
                    {/*</div>*/}
                </Col>
            )
        }
    }

    _renderEcgDebugInfo() {
        const isExternalUser = (this.props.user && this.props.user.roles && this.props.user.roles[0] && this.props.user.roles[0].code === 'ROLE_EXTERNAL') || !this.props.showRoles;
        if (isExternalUser) {
            return null;
        } else {
            return (
                <div style={{paddingTop: "20px"}}>
                    <b>数据参数：</b>
                    <span className="spacing-h">数据长度(Device)：{this.state.bufferLengthWeb}</span>
                    <span className="spacing-h">数据长度(App)：{this.state.bufferLengthApp}</span>
                    <span className="spacing-h">刷新点数(Device)：{this.state.rfsNum}</span>
                    <span className="spacing-h">刷新点数(APP)：{this.state.rfsNumApp}</span>
                </div>
            )
        }
    }

    _handleTogglePerformanceReport(value) {
        this._handleConnectLogSocket();
        this._handleConnectPerformanceSocket();
        this._handleConnectSystemInfoSocket();
    }

    _handleConnectPerformanceSocket() {
        const self = this;
        const processName = this._getNavigatorProcessName();
        const topic = "D9K_MQTT/" + window.btoa(this.state.systemInfo.mac) + "/" + this.props.deviceCode + "/" + RandomUtils.getUUID() + "/" + moment().format(FORMAT_DATE_TIME_SIMPLE);
        performanceWebSocket = new WebSocket(PERFORMANCE_SOCKET_SERVER_HOST);
        performanceWebSocket.onopen = function (evnt) {
            self.setState({performanceReportFlag: true});
            performanceWebSocket.send(JSON.stringify({command: 'start', interval: 5000, processName: processName}));
        };
        performanceWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            self._performanceMQTTReport(topic, data, processName);
        };
        performanceWebSocket.onerror = function (evnt) {
            self.setState({performanceReportFlag: false});
        };
        performanceWebSocket.onclose = function (evnt) {
            self.setState({performanceReportFlag: false});
        };
    }

    _handleConnectSystemInfoSocket() {
        const self = this;
        systemInfoWebSocket = new WebSocket(SYSTEM_INFO_SOCKET_SERVER_HOST);
        systemInfoWebSocket.onopen = function (evnt) {
            systemInfoWebSocket.send(JSON.stringify({command: 'get'}));
        };
        systemInfoWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            console.log(data);
            self.setState({systemInfo: data});

            self._performanceMqttConnect(data.mac);
        };
        systemInfoWebSocket.onerror = function (evnt) {
        };
        systemInfoWebSocket.onclose = function (evnt) {
        };
    }

    _getNavigatorProcessName() {

        const userAgent = window.navigator.userAgent;
        if (userAgent.toLowerCase().indexOf("edge") >= 0) {
            return "msedge";
        } else if (userAgent.toLowerCase().indexOf("chrome") >= 0) {
            return "chrome";
        } else if (userAgent.toLowerCase().indexOf("firefox") >= 0) {
            return "firefox";
        }
    }

    _handleChangeVoltDiv(value) {
        this.setState({voltDiv: value});
        // 重新计算点
        for (let i = 0; i < numLeads; i++) {

            if (drawBufferMapDevice[i]) {
                const drawBuffer = drawBufferMapDevice[i];
                if (!drawBuffer) {
                    return;
                }

                for (let i = 0; i < screenPointSum; i++) {
                    drawBuffer[i].newPoint = null;
                }
            }

            if (drawBufferMapApp[i]) {
                const drawBuffer = drawBufferMapApp[i];
                if (!drawBuffer) {
                    return;
                }

                for (let i = 0; i < screenPointSum; i++) {
                    drawBuffer[i].newPoint = null;
                }
            }
        }
    }

    _handleChangeChannelTypeDevice(checked) {
        if (!checked && !this.state.channelTypeAPP) {
            message.warn("请至少保持一个通道打开！");
            return;
        }

        if (this.state.combinePaint) {
            message.warn("合并画布的状态下，无法操作通道变更");
            return;
        }

        this.setState({channelTypeDevice: checked});
        if (checked && this.state.searchFlag) {
            this._handleSearchDevice(checked);
        } else {
            // manualStopSearch = true;
            this._handleStopSearchDevice(true);
        }
        this._drawECG(this.state.combinePaint, checked, this.state.channelTypeAPP);
    }

    _handleChangeChannelTypeApp(checked) {
        if (!checked && !this.state.channelTypeDevice) {
            message.warn("请至少保持一个通道打开！");
            return;
        }

        if (this.state.combinePaint) {
            message.warn("合并画布的状态下，无法操作通道变更");
            return;
        }
        this.setState({channelTypeAPP: checked});
        if (checked && this.state.searchFlag) {
            this._handleSearchApp(checked);
        } else {
            // manualStopSearch = true;
            this._handleStopSearchApp(true);
        }
        this._drawECG(this.state.combinePaint, this.state.channelTypeDevice, checked);
    }

    _handleLocalClockWrite(checked) {
        this.setState({localClockWrite: checked});
        if (checked) {
            if (!this.state.writeLogFlag) {
                // 勾选Debug开关，必须要记录日志
                this._handleWriteLogFlagChange(checked);
            }
            this.intervalClockLog = setInterval(() => {
                const time = Date.now();
                this._handleWriteLog("每秒记录一次计算机时间(精确到毫秒), 时间戳，日期时间" + "," + time + "," + moment(time).format(FORMAT_DATE_TIME_SLASH));
            }, 1000);
        } else {
            clearInterval(this.intervalClockLog);
        }
    }

    _handleDrawParamsWriteChange(checked) {
        this.setState({drawParamsWrite: checked});
        if (checked && !this.state.writeLogFlag) {
            // 勾选Debug开关，必须要记录日志
            this._handleWriteLogFlagChange(checked);
        }
    }

    _handleReceiveDrawTimeChange(checked) {
        this.setState({receiveDrawTime: checked});
        if (checked && !this.state.writeLogFlag) {
            // 勾选Debug开关，必须要记录日志
            this._handleWriteLogFlagChange(checked);
        }
    }

    _handleReceiveDataWriteChange(checked) {
        this.setState({receiveDataWrite: checked});
        if (checked && !this.state.writeLogFlag) {
            // 勾选Debug开关，必须要记录日志
            this._handleWriteLogFlagChange(checked);
        }
    }

    _handleWriteLogFlagChange(checked) {
        this.setState({writeLogFlag: checked});
        if (checked) {
            manualStopWriteLog = false;
            this._handleConnectLogSocket();
        } else {
            manualStopWriteLog = true;
            logWebSocket.close();
            this.setState({receiveDataWrite: false, drawParamsWrite: false, receiveDrawTime: false});
        }
    }

    _handleWriteLog(content) {
        if (logWebSocket && logWebSocket.readyState === 1) {
            logWebSocket.send(content);
        } else if (logWebSocket && logWebSocket.readyState === 0) {
            // message.warn("Log 服务连接中");
            console.log("Log 服务连接中")
        } else if (logWebSocket && logWebSocket.readyState === 2) {
            // message.warn("Log 服务链接关闭中");
            console.log("Log 服务链接关闭中")
        } else if (logWebSocket && logWebSocket.readyState === 3) {
            // message.warn("Log 服务已断开链接");
            console.log("Log 服务已断开链接")
        }
    }

    _handleConnectLogSocket() {
        const self = this;
        logWebSocket = new WebSocket(LOG_SOCKET_SERVER_HOST);
        logWebSocket.onopen = function (evnt) {
            message.success("Log 服务连接成功：");
        };
        logWebSocket.onmessage = function (evnt) {
            const data = JSON.parse(evnt.data);
            console.log(data);
        };
        logWebSocket.onerror = function (evnt) {
            self.setState({writeLogSocketStatus: "（连接异常）"});
            // message.error("Log 服务连接异常：" + evnt.target.url);
            console.log("Log 服务连接异常：" + evnt.target.url)
        };
        logWebSocket.onclose = function (evnt) {
            self.setState({writeLogSocketStatus: "（连接断开）"});
            // message.warn("Log 服务连接断开：" + evnt.target.url);
            console.log("Log 服务连接断开：" + evnt.target.url)
            if (!manualStopWriteLog) {
                self.setState({writeLogSocketStatus: "（重新连接）"});
                // message.info("Log 服务尝试重新连接：" + evnt.target.url);
                console.log("Log 服务尝试重新连接：" + evnt.target.url)
                self._handleConnectLogSocket();
            }
        };
    }

    _handleShowDeviceModal() {
        if (!this.props.deviceCode) {
            return;
        }
        for (let device of this.props.allDevices) {
            if (device.code === this.props.deviceCode) {
                this.props.dispatch(getDeviceById(device.id, this.state.formatMessage));
                this.props.dispatch(toggleDetailModal(true));

                const startTime = moment().subtract(10, 'days').format(FORMAT_DATE_SIMPLE + "000000");
                const endTime = moment().format(FORMAT_DATE_SIMPLE + "235959");
                this.props.dispatch(searchDeviceCoordinates(device.id, startTime, endTime, this.state.formatMessage));

                this.props.dispatch(initDeviceLogs(device.code));
                break;
            }
        }
    }


    _handleSwitchCombinePaint(value) {
        if (this.state.channelTypeAPP && this.state.channelTypeDevice) {
            message.warn("多通道打开，无法合并画布");
            return;
        }
        this.setState({combinePaint: value.target.checked});
        this._calcParams(value.target.checked);
        this._drawECG(value.target.checked, this.state.channelTypeDevice, this.state.channelTypeAPP);
    }

    _handleChangeBackgroundDisplay(value) {
        this.setState({backgroundDisplay: value});
        for (let i = 0; i < numLeads; i++) {
            if (value) {
                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = this._getECGViewBackground();

            } else {

                const ecgViewBackgroundGroup = document.getElementById("ecg_view_background_group_" + i);
                ecgViewBackgroundGroup.innerHTML = "";
            }
        }
    }

    _handleDrawBufferEcg() {
        if (this.intervalPlay === undefined || this.intervalPlay === null) {
            // console.log("启动描画线程");
            bufferLowFlag = true;
            this.intervalPlay = setInterval(() => {

                const bufferDataLength = drawBufferMapDevice[0].length;
                if (bufferLowFlag) {
                    // 缓冲区大小过低，需要等待
                    if (bufferDataLength >= screenPointSum + bufferSizeA2) {
                        bufferLowFlag = false;
                        const time = Date.now();
                        // message.success("缓冲区已经填满，开始绘制" + "," + time + "," + moment(time).format(FORMAT_DATE_TIME_SLASH));
                    }
                } else {
                    // 如果启动缓冲区
                    // 缓存一定的量再启动描画
                    // const pointNum = gridPointNum * xCellCount;
                    // 3、	A2是缓冲长度，就和看电影一开始要缓冲一段一样。
                    // 开始采集实时数据后，当数据点数量>=A2时，开始波形描绘。
                    // if (bufferDataLength >= bufferSizeA2) {
                    // 如果点的长度减去本次要刷新的点，依然超过缓存的最长度，则增加刷新的点数
                    // 1）A1≤N≤A3   （即在蓝色框范围内）      每次取RfsNum=4个点描绘
                    // 2）A1>N       （即在蓝色框右侧）         每次取RfsNum-1=3个点描绘
                    // 3）A3<N       （即在蓝色框左侧）       每次取RfsNum+1=5个点描绘
                    // baseRfsNum = (sampRate / downRatio) / winFpsDeviceCountResult;
                    if (bufferDataLength < screenPointSum + bufferThresholdA1) {
                        rfsNum = baseRfsNum - 1;
                    } else if (bufferDataLength > screenPointSum + bufferThresholdA3) {
                        rfsNum = baseRfsNum + 1;
                    } else {
                        rfsNum = baseRfsNum;
                    }

                    // if (this.state.drawParamsWrite) {
                    // console.log("ScreenPointSum, BaseRfsNum, BufferDataLength, RfsNum:" + screenPointSum + "," + baseRfsNum + "," + bufferDataLength + "," + rfsNum);
                    // }

                    this._refreshECGView();
                }
                // if (drawBufferMapDevice[0][0].point != 0) {
                //
                //     for (var i = 0; i < 8; i++) {
                //         if (preTimeVList[i] <= 0) {
                //             preTimeVList[i] = 1000 / winFps
                //         }
                //         preTimeVs[i] = preTimeVList[i]--
                //     }
                //
                // }
                // winFpsDeviceCount++;

                this.setState({bufferLengthWeb: bufferDataLength, rfsNum: rfsNum});
            }, winFps);

            // 无极计算刷新点数，会导致切换浏览器tab，加速播放的问题，该现象不可接受，所以注释掉这段代码
            // this.intervalRecalculate = setInterval(() => {
            //     winFpsDeviceCountResult = winFpsDeviceCount;
            //     winFpsDeviceCount = 0;
            //     // console.log("y,x" + winFpsDeviceCountResult, winFpsDeviceCount)
            // }, 1000);
        }
    }

    _handleDrawBufferEcgApp() {
        if (this.intervalPlayApp === undefined || this.intervalPlayApp === null) {
            // console.log("启动描画线程");
            bufferLowFlagApp = true;
            this.intervalPlayApp = setInterval(() => {

                const bufferDataLength = drawBufferMapApp[0].length;
                if (bufferLowFlagApp) {
                    // 缓冲区大小过低，需要等待
                    if (bufferDataLength >= screenPointSum + bufferSizeA2) {
                        bufferLowFlagApp = false;
                        const time = Date.now();
                        this._handleWriteLog("缓冲区已经填满，开始绘制" + "," + time + "," + moment(time).format(FORMAT_DATE_TIME_SLASH));
                    }
                } else {
                    // 如果启动缓冲区
                    // 缓存一定的量再启动描画
                    // const pointNum = gridPointNum * xCellCount;
                    // 3、	A2是缓冲长度，就和看电影一开始要缓冲一段一样。
                    // 开始采集实时数据后，当数据点数量>=A2时，开始波形描绘。
                    // if (bufferDataLength >= bufferSizeA2) {
                    // 如果点的长度减去本次要刷新的点，依然超过缓存的最长度，则增加刷新的点数
                    // 1）A1≤N≤A3   （即在蓝色框范围内）      每次取RfsNum=4个点描绘
                    // 2）A1>N       （即在蓝色框右侧）         每次取RfsNum-1=3个点描绘
                    // 3）A3<N       （即在蓝色框左侧）       每次取RfsNum+1=5个点描绘
                    // baseRfsNumApp = (sampRate / downRatio) / winFpsAppCountResult;
                    if (bufferDataLength < screenPointSum + bufferThresholdA1) {
                        rfsNumApp = baseRfsNumApp - 1;
                    } else if (bufferDataLength > screenPointSum + bufferThresholdA3) {
                        rfsNumApp = baseRfsNumApp + 1;
                    } else {
                        rfsNumApp = baseRfsNumApp;
                    }

                    if (this.state.drawParamsWrite) {
                        this._handleWriteLog("ScreenPointSum, BaseRfsNum, BufferDataLength, RfsNum:" + screenPointSum + "," + baseRfsNumApp + "," + bufferDataLength + "," + rfsNumApp);
                    }


                    this._refreshECGViewApp(rfsNumApp);
                }
                // if (this.state.channelTypeAPP && !this.state.channelTypeDevice) {
                //     if (drawBufferMapApp[0][0].point != 0) {
                //
                //         for (var i = 0; i < 8; i++) {
                //             if (preTimeVList[i] <= 1) {
                //                 preTimeVList[i] = 1000 / winFps
                //             }
                //             preTimeVs[i] = preTimeVList[i]--
                //         }
                //
                //     }
                // }
                // winFpsAppCount++;
                this.setState({bufferLengthApp: bufferDataLength, rfsNumApp: rfsNumApp});
            }, winFps);

            // this.intervalRecalculateApp = setInterval(() => {
            //     winFpsAppCountResult = winFpsAppCount;
            //     winFpsAppCount = 0;
            // }, 1000);
        }
    }

    _handleSearch() {
        this._handleSearchDevice(this.state.channelTypeDevice);
        this._handleSearchApp(this.state.channelTypeAPP);
        //TODO:如果出现信号中断又继续连上的情况下有可能会出错
        // this._handleData = setInterval(() => {
        //     if (!heartFlag && this.state.channelTypeDevice) {
        //         liveIdFlag = [];
        //         lastLiveId = [];
        //         preTimeIndex = [0, 0, 0, 0, 0, 0, 0, 0]
        //         for (let i = 0; i < 8; i++) {
        //             for (let j = 0; j < 50; j++) {
        //                 drawBufferMapDevice[i].push(new ECGPoint());
        //             }
        //         }
        //     }
        //     if (!heartFlagApp && this.state.channelTypeAPP) {
        //         liveIdFlagApp = [];
        //         lastLiveIdApp = [];
        //         preTimeIndexApp = [0, 0, 0, 0, 0, 0, 0, 0];
        //         for (let i = 0; i < 8; i++) {
        //             for (let j = 0; j < 50; j++) {
        //                 drawBufferMapApp[i].push(new ECGPoint());
        //             }
        //         }
        //     }
        //     heartFlag = false;
        //     heartFlagApp = false;
        // }, 5000);//5000
    }

    _handleSearchApp(channelTypeAPP) {
        if (this.props.deviceCode === "" || !channelTypeAPP) {
            return;
        }

        const searchStartTime = Date.now();
        this._handleWriteLog("查询开始, 时间戳，日期时间" + "," + searchStartTime + "," + moment(searchStartTime).format(FORMAT_DATE_TIME_SLASH));

        // 手动停止的话，再查询需要清空残留的点重画
        if (manualStopSearchApp) {
            // this._initDrawBuffer();
            // this._drawECG(this.state.combinePaint, this.state.channelTypeDevice, channelTypeAPP);
            this._initDrawBufferApp();
        }

        manualStopSearchApp = false;
        this.setState({searchFlag: true});

        const self = this;
        // if (!this.state.searchFlag) {
        websocketApp = new WebSocket(ECG_SOCKET_SERVER_HOST);
        websocketApp.binaryType = 'arraybuffer';
        // console.log(webSocket);
        // webSocket.send('64 03 02 FF FF FF FF 15 00 00 00 81 68 16 00 00');
        websocketApp.onopen = function (evnt) {
            // 打开一个连接
            WebSocketUtils._sendHello(websocketApp, self.state.deviceCode);
        };
        websocketApp.onmessage = function (e) {

            const dataArray = new Int8Array(e.data);
            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            // console.log("protocolCommonHeader:", protocolCommonHeader, dataArray, e);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                // message.success("连接成功，开始发送获取实时文件文件的指令");
                WebSocketUtils._sendGetLiveEcg(websocketApp, LiveDataChannel.Enum.Mobile);
                return;
            }

            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.Event) {
                self.props.setEventNumAPP(self.props.eventNumAPP + 1);
                let startPos = 0;
                startPos = startPos + protocolCommonHeader.length;
                self.props.EventFile[self.props.EventFile.length] = new EcgRouteEventFile(dataArray, startPos, 20)
                self.props.setEventFile(self.props.EventFile);
                console.log("ecgRouteEventFile:", self.props.EventFile, e)
            }

            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.Live) {
                console.log("包类型非实时数据，跳过不处理，PackageType：" + protocolCommonHeader.packetType);
                return;
            }
            let startPos = 0;
            // 解析实时
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const ecgFileLiveData = new EcgFileLiveData(preTimeIndexApp, liveIdFlagApp, lastLiveIdApp, dataArray, startPos, drawBufferMapApp, huffmanDecode);
            heartFlagApp = true;
            // 如果下采样率改变，则重新计算各描绘参数
            if (ecgFileLiveData.downSamplingRatio !== downRatio) {
                console.log("下采样率计算：" + ecgFileLiveData.downSamplingRatio);
                downRatio = ecgFileLiveData.downSamplingRatio;
                self._calcParams(self.state.combinePaint);

                self._initDrawBuffer();
            }

            // console.log("缓冲区长度：" + drawBufferMap[0].length);
            const bufferDataLength = drawBufferMapApp[0].length;
            console.log("drawBufferMapApp:", drawBufferMapApp);
            if (bufferDataLength >= screenPointSum + bufferThresholdA3 * 2) {
                // 缓存的数据太多，则截取到最后一屏的数据
                message.warn("数据丢弃，缓存的数据长度超过阈值的设定值：" + (screenPointSum + bufferThresholdA3 * 2));
                console.log("数据丢弃，缓存的数据长度超过阈值的设定值：" + (screenPointSum + bufferThresholdA3 * 2));
                for (let i = 0; i < numLeads; i++) {
                    drawBufferMapApp[i].splice(0, bufferDataLength - (screenPointSum + bufferSizeA2));
                }
            }
        };
        websocketApp.onerror = function (evnt) {
            console.log("链接服务器异常：" + evnt.target.url);
            message.success(self.state.formatMessage({id: "DAS_ECG_MSG_STOP_REAL_TIME"}));

            const logs = self.state.logsApp;
            logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "链接服务器异常：" + evnt.target.url});
            self.setState({logsApp: logs});

            self._handleWriteLog("链接服务器异常：" + evnt.target.url);
        };
        websocketApp.onclose = function (evnt) {
            message.warn("与服务器断开了链接：" + evnt.target.url);

            const logs = self.state.logsApp;
            logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "与服务器断开了链接：" + evnt.target.url});
            self._handleWriteLog("与服务器断开了链接：" + evnt.target.url);

            console.log(evnt);
            if (!manualStopSearchApp) {
                message.info("尝试重新连接：" + evnt.target.url);
                logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "尝试重新连接：" + evnt.target.url});
                self._handleWriteLog("尝试重新连接：" + evnt.target.url);
                // 继续尝试连接
                self._handleSearchApp(channelTypeAPP);
            }
            self.setState({logsApp: logs});
        };

        this._handleDrawBufferEcgApp();
    }

    _handleSearchDevice(channelTypeDevice) {
        if (this.props.deviceCode === "" || !channelTypeDevice) {
            return;
        }

        // const searchStartTime = Date.now();
        // this._handleWriteLog("查询开始, 时间戳，日期时间" + "," + searchStartTime + "," + moment(searchStartTime).format(FORMAT_DATE_TIME_SLASH));

        // 手动停止的话，再查询需要清空残留的点重画
        if (manualStopSearch) {
            // this._initDrawBuffer();
            // this._drawECG(this.state.combinePaint, channelTypeDevice, this.state.channelTypeAPP);
            this._initDrawBufferDevice();
        }

        manualStopSearch = false;
        this.setState({searchFlag: true});

        const self = this;
        // if (!this.state.searchFlag) {
        webSocket = new WebSocket(ECG_SOCKET_SERVER_HOST);
        webSocket.binaryType = 'arraybuffer';
        webSocket.onopen = function (evnt) {
            // 打开一个连接
            WebSocketUtils._sendHello(webSocket, self.state.deviceCode);
        };
        webSocket.onmessage = function (e) {
            const dataArray = new Int8Array(e.data);
            // 解析通用协议头
            const protocolCommonHeader = new ProtocolCommonHeader(dataArray);
            // console.log(protocolCommonHeader);
            // console.log("protocolCommonHeader:", protocolCommonHeader, dataArray, e);

            // 客户端连接到服务器后，进行一次主动的数据发送，将相关的设备id等信息提供给服务器，服务器收到后返回0x82，设备正常运行；若没有返回，则断开连接；
            // 收到的二进制数据应该是 64 02 03 FF FF FF FF 0C 00 00 00 82
            // 但由于转换成了有符号的证书，82编程了-126
            if (protocolCommonHeader.packetType === ProtocolPackageType.EnumInt.MessageConfirmationResponse) {
                // message.success("连接成功，开始发送获取实时文件文件的指令");
                WebSocketUtils._sendGetLiveEcg(webSocket, LiveDataChannel.Enum.Device);
                return;
            }
            if (protocolCommonHeader.packetType == ProtocolPackageType.EnumInt.Event) {
                self.props.setEventNumDevice(self.props.eventNumDevice + 1);
                let startPos = 0;
                startPos = startPos + protocolCommonHeader.length;
                self.props.EventFile[self.props.EventFile.length] = new EcgRouteEventFile(dataArray, startPos, 10)
                self.props.setEventFile(self.props.EventFile);
                console.log("ecgRouteEventFile:", self.props.EventFile, e)
            }

            if (protocolCommonHeader.packetType !== ProtocolPackageType.EnumInt.Live) {
                // message.warn("包类型非实时数据，跳过不处理，PackageType：" + protocolCommonHeader.packetType);
                return;
            }
            let startPos = 0;
            // 解析实时
            // 参考：《客户端与WebSocketServer间网络通信协议》
            startPos = startPos + protocolCommonHeader.length;
            const ecgFileLiveData = new EcgFileLiveData(preTimeIndex, liveIdFlag, lastLiveId, dataArray, startPos, drawBufferMapDevice, huffmanDecode);

            heartFlag = true;
            // // 如果下采样率改变，则重新计算各描绘参数
            // if (ecgFileLiveData.downSamplingRatio !== downRatio) {
            //     console.log("下采样率计算：" + ecgFileLiveData.downSamplingRatio);
            //     downRatio = ecgFileLiveData.downSamplingRatio;
            //     self._calcParams(self.state.combinePaint);
            //
            //     self._initDrawBuffer();
            // }

            // console.log("缓冲区长度：" + drawBufferMap[0].length);
            const bufferDataLength = drawBufferMapDevice[0].length;
            if (bufferDataLength >= screenPointSum + bufferThresholdA3 * 2) {
                // 缓存的数据太多，则截取到最后一屏的数据
                message.warn("数据丢弃，缓存的数据长度超过阈值的设定值：" + (screenPointSum + bufferThresholdA3 * 2));
                console.log("数据丢弃，缓存的数据长度超过阈值的设定值：" + (screenPointSum + bufferThresholdA3 * 2));
                for (let i = 0; i < numLeads; i++) {
                    drawBufferMapDevice[i].splice(0, bufferDataLength - (screenPointSum + bufferSizeA2));
                }

            }
        };
        webSocket.onerror = function (evnt) {
            console.log("链接服务器异常：" + evnt.target.url);
            message.success(self.state.formatMessage({id: "DAS_ECG_MSG_STOP_REAL_TIME"}));

            const logs = self.state.logsWeb;
            logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "链接服务器异常：" + evnt.target.url});
            self.setState({logsWeb: logs});

            self._handleWriteLog("链接服务器异常：" + evnt.target.url);
        };
        webSocket.onclose = function (evnt) {
            message.warn("与服务器断开了链接：" + evnt.target.url);

            const logs = self.state.logsWeb;
            logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "与服务器断开了链接：" + evnt.target.url});
            self._handleWriteLog("与服务器断开了链接：" + evnt.target.url);

            console.log(evnt);
            if (!manualStopSearch) {
                // message.info("尝试重新连接：" + evnt.target.url);
                // logs.push({time: moment().format(FORMAT_DATE_TIME_FULL_SLASH), content: "尝试重新连接：" + evnt.target.url});
                // self._handleWriteLog("尝试重新连接：" + evnt.target.url);
                // // 继续尝试连接
                // self._handleSearchDevice(channelTypeDevice);
            }
            self.setState({logsWeb: logs});
        };

        this._handleDrawBufferEcg();
    }

    _handleStopSearch() {
        this.setState({searchFlag: false});
        this._handleStopSearchDevice(true);
        this._handleStopSearchApp(true);
        message.warn("实时心电和异常事件接收停止");
        // clearInterval(this._handleData)
    }

    _handleStopSearchDevice(isManualStop) {
        preTime = null;
        liveIdFlag = [];
        lastLiveId = [];
        preTimeIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        manualStopSearch = isManualStop;
        clearInterval(this.intervalPlay);
        this.intervalPlay = undefined;
        // clearInterval(this.intervalRecalculate);
        // this.intervalRecalculate = undefined;
        webSocket && webSocket.close();
    }

    _handleStopSearchApp(isManualStop) {
        preTime = null;
        liveIdFlagApp = [];
        lastLiveIdApp = [];
        preTimeIndexApp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        manualStopSearchApp = isManualStop;
        clearInterval(this.intervalPlayApp);
        this.intervalPlayApp = undefined;
        // clearInterval(this.intervalRecalculateApp);
        // this.intervalRecalculateApp = undefined;
        websocketApp && websocketApp.close();
    }

    _handleChange(name, value) {
        this.setState({deviceCode: value.target ? value.target.value : value});
        this._initDrawBuffer();
        // this._initDrawBufferApp();
        this._drawECG(this.state.combinePaint, this.state.channelTypeDevice, this.state.channelTypeAPP);
        this._handleStopSearch();
    }

// 设备切换，停止重新开始的时候，需要清空画板
    _initDrawBuffer() {
        // 改变设备，清空数据重新绘制，否则不同的设备数据混在一起
        for (let i = 0; i < numLeads; i++) {
            // 初始化固定长度的绘画缓冲区
            drawBufferMapDevice[i] = [];
            drawBufferMapApp[i] = [];
            for (let j = 0; j < screenPointSum; j++) {
                drawBufferMapDevice[i][j] = new ECGPoint();
                drawBufferMapApp[i][j] = new ECGPoint();
            }
        }
    }

    _initDrawBufferDevice() {
        // 改变设备，清空数据重新绘制，否则不同的设备数据混在一起
        for (let i = 0; i < numLeads; i++) {
            // 初始化固定长度的绘画缓冲区
            drawBufferMapDevice[i] = [];
            for (let j = 0; j < screenPointSum; j++) {
                drawBufferMapDevice[i][j] = new ECGPoint();
            }
        }
    }

    _initDrawBufferApp() {
        for (let i = 0; i < numLeads; i++) {
            // 初始化固定长度的绘画缓冲区
            drawBufferMapApp[i] = [];
            for (let j = 0; j < screenPointSum; j++) {
                drawBufferMapApp[i][j] = new ECGPoint();
            }
        }
    }

    _refreshEcgChainView(i) {
        let index = i;
        if (this.state.channelTypeDevice && this.state.channelTypeAPP) {
            if (i % 2 !== 0) {
                return;
            }

            index = Math.floor(i / 2);
        }

        // 把rfsNum这个索引之前的数据删掉
        drawBufferMapDevice[index].splice(0, rfsNum);

        const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);

        // 创建心电图波形
        const ecgViewLightWave = this._getECGViewLightWave(index, drawBufferMapDevice, 1);

        ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
    }

    _refreshECGView() {

        if (!this.state.channelTypeDevice) {
            return;
        }

        if (this.state.receiveDrawTime) {
            for (let j = screenPointSum - rfsNum; j < screenPointSum; j++) {
                if (drawBufferMapDevice[0][j] && drawBufferMapDevice[0][j].package && drawBufferMapDevice[0][j].package.receiveTime &&
                    drawBufferMapDevice[0][j].package.drawTime === undefined) {
                    drawBufferMapDevice[0][j].package.drawTime = Date.now();
                    this._handleWriteLog("LiveId, ReceiveTime, DrawTime:" + drawBufferMapDevice[0][j].package.liveId + "," + drawBufferMapDevice[0][j].package.receiveTime + "," + drawBufferMapDevice[0][j].package.drawTime);
                }
            }
        }

        let numLeadsSum = numLeads;
        let numLeadsIndex = 1;
        if (this.state.channelTypeDevice && this.state.channelTypeAPP) {
            numLeadsSum += numLeads;
            numLeadsIndex++;
        }

        for (let i = numLeadsIndex; i < numLeadsSum; i++) {

            // let index = i;
            // if (this.state.channelTypeDevice && this.state.channelTypeAPP) {
            //     if (i % 2 !== 0) {
            //         continue;
            //     }
            //
            //     index = Math.floor(i / 2);
            // }
            //
            // // 把rfsNum这个索引之前的数据删掉
            // drawBufferMapDevice[index].splice(0, rfsNum);
            //
            // const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);
            //
            // // 创建心电图波形
            // const ecgViewLightWave = this._getECGViewLightWave(index, drawBufferMapDevice, 1);
            //
            // ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;

            this._refreshEcgChainView(i);
        }

        this._refreshEcgChainView(0);
        if (numLeadsIndex === 2) {
            this._refreshEcgChainView(1);
        }
    }

    _refreshECGViewApp(rfsNum) {
        if (!this.state.channelTypeAPP) {
            return;
        }

        if (this.state.receiveDrawTime) {
            for (let j = screenPointSum - rfsNum; j < screenPointSum; j++) {
                if (drawBufferMapApp[0][j] && drawBufferMapApp[0][j].package && drawBufferMapApp[0][j].package.receiveTime &&
                    drawBufferMapApp[0][j].package.drawTime === undefined) {
                    drawBufferMapApp[0][j].package.drawTime = Date.now();
                    this._handleWriteLog("LiveId, ReceiveTime, DrawTime:" + drawBufferMapApp[0][j].package.liveId + "," + drawBufferMapApp[0][j].package.receiveTime + "," + drawBufferMapApp[0][j].package.drawTime);
                }
            }
        }

        let numLeadsSum = numLeads;
        if (this.state.channelTypeDevice && this.state.channelTypeAPP) {
            numLeadsSum += numLeads;
        }

        // console.log(drawBufferMapApp);

        for (let i = 0; i < numLeadsSum; i++) {
            let index = i;
            if (this.state.channelTypeDevice && this.state.channelTypeAPP) {
                if (i % 2 === 0) {
                    continue;
                }
                index = Math.floor(i / 2);
            }

            // 把rfsNum这个索引之前的数据删掉
            drawBufferMapApp[index].splice(0, rfsNum);

            const ecgViewLightwaveGroup = document.getElementById("ecg_view_lightwave_group_" + i);

            // 创建心电图波形
            const ecgViewLightWave = this._getECGViewLightWave(index, drawBufferMapApp, 2);

            ecgViewLightwaveGroup.innerHTML = ecgViewLightWave;
        }
    }

    _getECGViewText(index, prefix) {
        // const centerY = (yCellCount * cellWidth + winMarginY) / 2;
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;
        // 画导联的名称
        const chainName = "<text fill='" + lineColors[index] + "' x=-10 y=" + zeroPoint + ">" + prefix + "CH" + (index + 1) + ":" + leadName.List[index].label + "</text>";
        let result = chainName;
        return result;
    }

    _getECGViewTextApp(index) {
        // const centerY = (yCellCount * cellWidth + winMarginY) / 2;
        // 算出0点，以下是负值，以上是正直，需要变换数据
        const zeroPoint = (yCellCount - 3) * cellWidth + winMarginY;
        // 画导联的名称
        const chainName = "<text fill='" + lineColors[index] + "' x=0 y=" + zeroPoint + ">ACH" + (index + 1) + "</text>";
        let result = chainName;
        return result;
    }

    _getECGViewBackground() {
        // 算出纵向的格子数
        // const yCellCount = Math.floor(height / cellWidth);

        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "M" + winMarginX + "," + winMarginY;

        // 画竖线
        const y = yCellCount * cellWidth;
        for (let i = 0; i <= xCellCount; i++) {
            path = path + " l0," + y;
            // 移到下一个位置
            path = path + " m" + cellWidth + ",-" + y;
        }

        // 画横线
        path = path + " M" + winMarginX + "," + winMarginY;
        const x = xCellCount * cellWidth;
        for (let i = 0; i <= yCellCount; i++) {
            path = path + " l" + x + ",0";
            // 移到下一个位置
            path = path + " m-" + x + "," + cellWidth;
        }

        const result = "<path stroke='rgb(255,192,203)' fill='red' strokeWidth=0.5 d='" + path + "'></path>";

        const zeroRect = "<rect name='原点' x='" + (winMarginX - 2) + "' y='" + (zeroPointY - 2) + "' fill='rgb(255,192,203)' stroke='rgb(255,192,203)' stroke-width='0.5' width='5' height='5'/>";

        return result + zeroRect;
    }

    /**
     * 初始化一条执行作为心电图的初始状态
     * @private
     */
    _initEcgViewLightWave(index) {
        let path = "";
        let x = 0;
        for (let i = 0; i < screenPointSum; i++) {
            // 计算前一个点
            let preY = zeroPointY;

            // 计算当前点
            let y = zeroPointY;

            if (i === 0) {
                // 定位第一个点
                path = path + "M" + winMarginX + "," + y;
            } else {
                path = path + " l" + pointSpace + "," + (y - preY)
            }
            x = x + pointSpace;
        }
        let result = "<g><path stroke='" + lineColors[index] + "' fill='none' stroke-dasharray='2 2' d='" + path + "'></path></g>";
        return result;
    }

    _getECGViewLightWave(index, drawBufferMap, value) {
        // 计算出path
        // M = moveto
        // L = lineto
        // H = horizontal lineto
        // V = vertical lineto
        // C = curveto
        // S = smooth curveto
        // Q = quadratic Belzier curve
        // T = smooth quadratic Belzier curveto
        // A = elliptical Arc
        // Z = closepath
        let path = "";
        const paths = [];

        let timeSvg = "";
        const drawBuffer = drawBufferMap[index];
        // if (index > 8) {
        // console.log("drawBuffer:", index, drawBuffer, value);
        // }


        let x = winMarginX;

        // console.log("drawBuffer:", drawBuffer);
        // const timeTextArray = [];
        // const timeSvgArray = [];
        // const timeStampSvgArray = [];
        // 需要区分path是实线和虚线，有心电数据的实线，无心电数据的虚线，
        // 所以需要分段描绘
        const virtualPaths = [];
        let virtualPath = "";
        let line = "";
        let svgDate
        for (let i = 0; i < screenPointSum; i++) {
            // preTimeV = i;
            // 计算当前点
            let y = 0;
            if (drawBuffer[i] === null || drawBuffer[i] === undefined) {
                drawBuffer.push(new ECGPoint());
            }

            if (i > 0) {
                x = x + pointSpace;
            }

            // 没有package数据对象，说明是无数据的点
            if (!drawBuffer[i].package || !drawBuffer[i].package.ecgFileCommonHeader) {
                // console.log("无数据")
                if (virtualPath === "") {
                    virtualPath = virtualPath + "M" + x + "," + zeroPointY;
                } else {
                    virtualPath = virtualPath + " l" + pointSpace + ",0";
                }

                if (path !== "") {
                    // 如果path有数据，则加到paths列表，并初始化
                    paths.push(path);
                    path = "";
                }
            } else {

                if (virtualPath !== "") {
                    virtualPaths.push(virtualPath);
                    virtualPath = "";
                }

                // 计算前一个点
                let preY = 0;
                if (i !== 0) {
                    if (drawBuffer[i - 1].newPoint !== null) {
                        preY = drawBuffer[i - 1].newPoint;
                        preTimeV++;
                    } else {
                        preY = drawBuffer[i - 1].point;
                        if (preY !== 0) {
                            preY = preY / gc_ch2_8;
                            preY = preY / (1 / (this.state.voltDiv / gridScale));
                            preY = preY * cellWidth;
                        }
                        // 如果是正值，则需要以zeroPoint - 该值，
                        // 如果是负值，则需要以zeroPoint + 该值的绝对值
                        if (preY > 0) {
                            preY = zeroPointY - preY;
                        } else {
                            preY = Math.abs(preY) + zeroPointY;
                        }
                    }
                }

                if (drawBuffer[i].newPoint !== null) {
                    y = drawBuffer[i].newPoint;
                } else {
                    y = drawBuffer[i].point;
                    y = y / gc_ch2_8;// * 0.000049;
                    y = y / (1 / (this.state.voltDiv / gridScale));
                    y = y * cellWidth;

                    // 定位第一个点
                    // 如果是正值，则需要以zeroPoint - 该值，
                    // 如果是负值，则需要以zeroPoint + 该值的绝对值
                    if (y > 0) {
                        y = zeroPointY - y;
                    } else {
                        y = Math.abs(y) + zeroPointY;
                    }
                    // 记录计算后的新点，在下次循环的时候，就不用重复计算，提高性能
                    drawBuffer[i].newPoint = y;
                }

                if (drawBuffer[i].startPoint) {
                    line += "<line x1='" + (x - 5) + "' y1=20 x2='" + (x - 5) + "' y2=140 stroke='blue' stroke-dasharray='2 2'></line>"
                    line += "<line x1='" + (x + 5) + "' y1=20 x2='" + (x + 5) + "' y2=140 stroke='blue' stroke-dasharray='2 2'></line>"
                }

                if (path === "") {
                    // 定位第一个点
                    path = path + "M" + x + "," + y;
                } else {
                    path = path + " l" + pointSpace + "," + (y - preY)
                }
                // 绘制心电图上的时间标识
                if (drawBuffer[i].devTime && drawBuffer[i].package) {//&& ((((drawBuffer[i].package.liveId - minLiveId) * 255) + drawBuffer[i].index) % (dataRate * 5) == 0)) {//(((time + drawBuffer[i].index) % 500 == 0) && (drawBuffer[i].point != 0 && drawBuffer[i].newPoint != 0)))
                    svgDate = "<text y='15' x='" + (x + 5) + "'>" + drawBuffer[i].devTime + "</text>";

                    // maxLiveId = drawBuffer[i].package.liveId > maxLiveId ? drawBuffer[i].package.liveId : maxLiveId;
                    preTime = drawBuffer[i].devTime;
                    const svgStamp = "<polygon points='" + x + ",20 " + (x - 5) + ",30 " + (x + 5) + ",30' fill='blue' stroke='rgb(0,0,255)' stroke-dasharray='2 2'></polygon>";
                    timeSvg = timeSvg + svgDate + svgStamp;
                }

            }
            // if (drawBuffer[i] && drawBuffer[i].package.time && !timeTextArray.includes(drawBuffer[i].package.time)) {
            //     timeTextArray.push(drawBuffer[i].package.time);
            //     const svgDate = "<text y='10' x='" + x + "'>" + drawBuffer[i].package.time + "</text>";
            //     timeSvgArray.push(svgDate);
            //     const svgStamp = "<polygon points='" + x + ",20 " + (x - 5) + ",30 " + (x + 5) + ",30' fill='blue' stroke='rgb(0,0,255)' stroke-width=0.5></polygon>";
            //     timeStampSvgArray.push(svgStamp);
            // result = result + "<path stroke='rgb(0,0,255)' fill='blue' strokeWidth=0.5 d='M" + timeText[i].x + ",0 l0," + yCellCount * cellHeight + "'></path>";
            // }
        }


        // if (preTimeVs[index] >= screenPointSum) {
        //     preTimeVs[index]--;
        // }

        // if (preTimeV >= screenPointSum) {
        //     preTimeV--;
        // }


        // 最后一条path要加入列表中描绘
        if (path !== "") {
            paths.push(path);
        }

        const gStart = "<g>";
        const gEnd = "</g>";
        let result = "";
        if (paths.length > 0) {
            for (let path of paths) {
                result = result + "<path stroke='" + lineColors[index] + "' fill='none' strokeWidth=1 d='" + path + "'></path>";
            }
        }

        // 最后一条虚线，一直等不到有实际数据过来，会漏画，
        // 增加该逻辑补充上去
        if (virtualPath !== "") {
            virtualPaths.push(virtualPath);
        }

        if (virtualPaths.length > 0) {
            for (let virtualPath of virtualPaths) {
                result = result + "<path stroke='" + lineColors[index] + "' fill='none' stroke-dasharray='2 2'  d='" + virtualPath + "'></path>";
            }
        }
        result = gStart + result + gEnd;
        if (timeSvg !== "") {
            result = result + timeSvg;
        }
        if (line !== "") {
            result = result + line;
        }
// for (let i = 0; i < timeSvgArray.length; i++) {
//     result = result + timeSvgArray[i] + timeStampSvgArray[i];
// }
        return result;
    }
}

function mapStateToProps(store) {
    return {
        allDevices: store.EcgDeviceReducer.allDevices,
        screenPointSum: store.EcgDeviceReducer.screenPointSum,
        deviceCode: store.EcgDeviceReducer.deviceCode,
        user: store.AccountUserReducer.user,
        showRoles: store.AccountRoleReducer.showRoles,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));
