import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

// 导入React 百度地图组件
import {Map} from 'react-bmap'
import {Icon} from 'antd'
import {searchDeviceList, toggleResultModal} from '../../../actions/DeviceAction';
// import {BMapDarkJson, YangPuCoordsJsonData, YangPuData} from "./TaskMapMonitorStyleJson";
// import {MONITOR_TYPE} from "../../../Device/Enums";
import monitorMapStyleJson from '../../../config/monitorMapStyle';
import {RoutePath} from "../../../../../constants/RoutePath";
import Button from "@material-ui/core/Button";
import {func} from "prop-types";
import {Link} from "react-router";

const defaultCenter = {lng: 121.50, lat: 31.33};
let opts = {
    width: 250,     // 信息窗口宽度
    height: 80,     // 信息窗口高度
    title: "<h4>设备信息</h4>", // 信息窗口标题
    enableMessage: true,//设置允许信息窗发送短息
    enableAutoPan: true//自动平移
};
let height
let width
let defaultZoom = 13;
let dataCondition = [];

let moonFlag = false;
let moonStr = "黑夜"

class TaskMonitorMap extends Component {

    constructor(props) {
        super(props);


        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            isFullScreen: false
        }
    }

    componentWillMount() {
        this._handleSearch();
        opts = {
            width: 250,     // 信息窗口宽度
            height: 80,     // 信息窗口高度
            title: "<h4>设备信息</h4>", // 信息窗口标题
            enableMessage: true,//设置允许信息窗发送短息
            enableAutoPan: true//自动平移
        };
        height = opts.height
        width = opts.width
    }

    componentWillUnmount() {
    }

    _handleSearch() {
        // const devices = Object.assign([],this.props.devices);
        // console.log("devices:" + JSON.stringify(devices));
        const monitorSearchCondition = Object.assign({}, this.props.monitorSearchCondition);
        monitorSearchCondition["paginationFlag"] = false;
        this.props.dispatch(searchDeviceList(monitorSearchCondition, this.state.formatMessage, false, false));
    }

    componentDidMount() {
        // this.map && this.map.setMapStyleV2({styleJson: monitorMapStyleJson}/*{style: 'midnight'}*/);
        const self = this;
        window.onresize = function () {
            if (!self.checkFull()) {
                // 要执行的动作
                self.setState({isFullScreen: false});
            }
        }

        //每5秒刷新一次坐标
        this._startRefreshData();

        //拖动地图时停止实时刷新数据
        this.map && this.map.addEventListener("dragstart", function () {
            self._stopRefreshData();
        });
        this.map && this.map.addEventListener("dragend", function () {
            self._startRefreshData();
        });

        //缩放地图时停止实时刷新数据
        this.map && this.map.addEventListener("zoomstart", function () {
            self._stopRefreshData();
        });
        this.map && this.map.addEventListener("zoomend", function () {
            self._startRefreshData();
        });

        this.props.parent.getChildrenMsg(this, this.map);
    }

    componentWillUnmount() {
        const self = this;
        clearInterval(self.timer);
        this.map && this.map.removeEventListener("dragstart", function () {
            self._stopRefreshData();
        });
        this.map && this.map.removeEventListener("dragend", function () {
            self._startRefreshData();
        });

        this.map && this.map.removeEventListener("zoomstart", function () {
            self._stopRefreshData();
        });
        this.map && this.map.removeEventListener("zoomend", function () {
            self._startRefreshData();
        });
    }

    _stopRefreshData() {
        clearInterval(this.timer);
    }

    _startRefreshData() {
        this.timer = setInterval(() => {
            this._handleSearch();
        }, 5000);
    }

    checkFull() {
        let isFull =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement
        if (isFull === undefined) isFull = false
        return isFull
    }

    _openInfo(content, e, all, value) {
        let str = ""
        let index = 0
        let res = [];
        for (let i = 0; i < all.length; i++) {
            if (all[i] != null && this.map) {
                if (Math.abs(value - (all[i].longitude + all[i].latitude)) < 0.001) {
                    // str += "设备编号：" + all[i].code + "<br/>";
                    // res.push(
                    //     <Link
                    //         to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + all[i].code}
                    //         URIEncoding="utf-8">
                    //         设备编号：{all[i].code}
                    //         <br/>
                    //     </Link>
                    // )
                    // str += '<Link to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" ' + all[i].code + '} URIEncoding="utf-8">' +
                    //     '设备编号：' + all[i].code + '<br/></Link>'
                    str += '<p>设备编号：' + all[i].code + '</p>'//onClick={() => ' + this.clickInfo(1, all[i].code) + '}
                    index++;
                }
            }
        }

        opts.width = width + 20 * index
        opts.height = height + 20 * index
        // str = '<div>'
        // for (let i = 0; i < res.length; i++) {
        //     str += res[i];
        // }
        // str += '</div>'

        let p = e.target;
        let point = new window.BMap.Point(p.getPosition().lng, p.getPosition().lat);
        // let point = new window.BMap.Point
        // (content.length * (p.getPosition().lng), content.length * (p.getPosition().lat));
        let infoWindow = new window.BMap.InfoWindow(str, opts);  // 创建信息窗口对象
        console.log("_openInfo:", content, e, str, opts, infoWindow)
        this.map && this.map.openInfoWindow(infoWindow, point); //开启信息窗口
    }

    _fullScreen() {
        let docElm = document.documentElement;
        //W3C
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        }
        //FireFox
        else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        }
        //Chrome等
        else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        }
        //IE11
        else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
        let exitFullScreen = document.exitFullScreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen
        if (exitFullScreen) {
            exitFullScreen.call(document)
        }
        this.setState({isFullScreen: !this.state.isFullScreen});

    }

    _openSearchResult() {
        this.props.dispatch(toggleResultModal(true));
    }

    _selectDevices() {
        const allAllDevices = Object.assign([], this.props.devices);
        const monitorSearchCondition = Object.assign({}, this.props.monitorSearchCondition);
        dataCondition = [];
// debugger;
        for (let item of allAllDevices) {

            //传输状态
            if (monitorSearchCondition.deviceOnlineStatesTemp !== undefined &&
                monitorSearchCondition.deviceOnlineStatesTemp.length > 0) {
                if (!monitorSearchCondition.deviceOnlineStatesTemp.includes(item.devLiveOnlineStatus)) {
                    continue;
                }
            }

            //导联状态
            if (monitorSearchCondition.deviceLeadStatesTemp !== undefined) {
                // debugger;
                if (monitorSearchCondition.deviceLeadStatesTemp[0] === "0") {
                    if (item.leadAState === 0 || item.leadBState === 0 || item.leadCState === 0 || item.leadDState === 0 ||
                        item.leadEState === 0 || item.leadFState === 0 || item.leadGState === 0 || item.leadHState === 0) {
                        dataCondition.push(JSON.parse(JSON.stringify(item)));
                    }
                } else {
                    if (item.leadAState === 1 && item.leadBState === 1 && item.leadCState === 1 && item.leadDState === 1 &&
                        item.leadEState === 1 && item.leadFState === 1 && item.leadGState === 1 && item.leadHState === 1) {
                        dataCondition.push(JSON.parse(JSON.stringify(item)));
                    }
                }

                continue;
            }

            // if(monitorSearchCondition.deviceSysStatesTemp !== undefined && !monitorSearchCondition.deviceSysStatesTemp.includes(item.sysState)){
            //     continue;
            // }
            dataCondition.push(JSON.parse(JSON.stringify(item)));
        }
        // console.log("monitorSearchCondition:" + JSON.stringify(monitorSearchCondition));
        console.log("dataCondition:" + dataCondition.length);
    }

    mapMoon() {
        if (!moonFlag) {
            this.map && this.map.setMapStyleV2({styleJson: monitorMapStyleJson}/*{style: 'midnight'}*/);
            moonFlag = !moonFlag
        } else {
            moonFlag = !moonFlag
            this.map && this.map.setMapStyleV2({styleJson: ""}/*{style: 'midnight'}*/);
        }
        this.setState({isFullScreen: this.state.isFullScreen})
    }

    render() {
        this._selectDevices();
        // const allAllDevices = Object.assign([],this.props.allAllDevices);
        const allAllDevices = dataCondition;
        console.log("dataCondition:" + dataCondition.length);
        // let docElm = document.documentElement;
        // let content = ""
        let res = []
        if (window.NONITOR_DATA_REFRESH === 1) {
            // debugger;
            this.map && this.map.clearOverlays();
            for (let i = 0; i < allAllDevices.length; i++) {
                if (allAllDevices[i] != null && this.map) {
                    let longitude = allAllDevices[i].longitude;
                    let latitude = allAllDevices[i].latitude;
                    // let content = "设备编号：" + allAllDevices[i].code + "<br/>设备名称：" + allAllDevices[i].name;
                    let content = "设备编号：" + allAllDevices[i].code + "<br/>";
                    // res.push(
                    //     <Button onClick={() => this.clickInfo()}>设备编号:{allAllDevices[i].code}</Button>
                    // )
                    // res.push(<br/>)
                    // content += "<Button onClick={() => this.clickInfo()}>设备编号:" + allAllDevices[i].code + "</Button><br/>"
                    const icon = new window.BMap.Icon(require("../../../../../images/icon.png")/*"http://api.map.baidu.com/lbsapi/createmap/images/icon.png"*/
                        , new window.BMap.Size(20, 21),
                        {
                            imageOffset: new window.BMap.Size(-45, 0)  // 图片相对视窗的偏移
                        }
                    );
                    const point = new window.BMap.Point(longitude, latitude);
                    const marker = new window.BMap.Marker(point, {icon: icon});
                    marker.setAnimation(window.BMAP_ANIMATION_DROP);
                    // this.map && this.map.setMapStyle({styleJson:styleJson}/*{style: 'midnight'}*/);
                    this.map && this.map.addOverlay(marker);
                    // marker.setLabel(label);//显示标注点的内容
                    marker.addEventListener('mouseover', (e) => this._openInfo(content, e, allAllDevices, longitude + latitude));
                    marker.addEventListener('onclick', (e) => this.clickInfo(e, allAllDevices[i].code))
                }
            }
        }
        return (
            <div style={{height: '100%', position: "relative"}}>
                <Map ref={ref => this.map = ref && ref.map} enableScrollWheelZoom
                     center={defaultCenter} zoom={defaultZoom} minZoom={5}
                    // mapStyle={{style: 'midnight'}}
                     style={this.state.isFullScreen ?
                         {height: '100%', width: "100%"} :
                         {height: '100%', width: "100%"}
                     }>
                    {/*<Button onClick={() => this.mapMoon()}>夜间模式</Button>*/}
                </Map>

                <div style={{
                    right: 50,
                    top: 50,
                    width: 35,
                    height: 35,
                    borderRadius: 7,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    cursor: "pointer"
                }}
                     onClick={() => this._fullScreen()}>

                    <Icon style={{fontSize: 30, margin: "2px 0 0 2px"}}
                          type={this.state.isFullScreen ? "fullscreen-exit" : "fullscreen"}/>
                </div>
                <div style={{
                    right: 45,
                    bottom: 50,
                    width: 45,
                    height: 40,
                    borderRadius: 7,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    cursor: "pointer",
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontSize: 12,
                    fontWeight: "bold"
                }}
                     onClick={() => this._openSearchResult()}>
                    Info
                </div>
                <div style={{
                    right: 50,
                    top: 100,
                    width: 35,
                    height: 35,
                    borderRadius: 7,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    cursor: "pointer",
                    paddingTop: 7,
                    paddingLeft: 5,
                    fontSize: 12,
                    fontWeight: "bold"
                }}
                     onClick={() => this.mapMoon()}>
                    {moonFlag ? "白天" : "黑夜"}
                </div>

                <div style={{
                    right: 50,
                    top: 150,
                    width: 35,
                    height: 35,
                    borderRadius: 7,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    cursor: "pointer",
                    paddingLeft: 5,
                    fontSize: 12,
                    fontWeight: "bold"
                }}
                     onClick={() => this.handleDeviceList()}>
                    设备列表
                </div>
            </div>
        );
    }

    clickInfo(value, code) {
        console.log("clickInfo:", value)
        this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + code);// + "?deviceCode=" + record.code
    }

    handleDeviceList() {
        this.props.router.push(RoutePath.DAS_DEVICE_LIST.path);// + "?deviceCode=" + record.code
    }
}

function mapStateToProps(store) {
    return {
        allAllDevices: store.EcgDeviceReducer.allAllDevices,
        monitorSearchCondition: store.EcgDeviceReducer.monitorSearchCondition,
        redisData: store.EcgDeviceReducer.redisData,
        devices: store.EcgDeviceReducer.devices
    }
}

export default connect(mapStateToProps)(injectIntl(TaskMonitorMap));
