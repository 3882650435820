import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Modal, Input, Form, Table, Row, Col, Button} from "antd";
import {
    refreshBinder,
    refreshSearchCondition, searchBinders,
    toggleBinderSelectModal
} from "../actions/BinderAction";
import PaginationBar from "../../../components/PaginationBar";
import {Label_6_Wrapper_18} from "../../../constants/FormItemLayout";

class BinderSelectModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            columns: [
                {
                    key: 'userName',
                    dataIndex: 'userName',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_NAME'})
                },
                {
                    key: 'userMobile',
                    dataIndex: 'userMobile',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_MOBILE'})
                },
                {
                    key: 'userNickname',
                    dataIndex: 'userNickname',
                    title: formatMessage({id: 'ECG_BINDER_FIELD_USER_NICKNAME'})
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        this._handleSearch({});
    }


    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleOk() {
        const {dispatch, searchCondition, ecgCase} = this.props;
        // if (this._validate(ecgCase)) {
        //     if (ecgCase.id !== undefined && ecgCase.id !== "") {
        //         dispatch(updateCase(ecgCase, searchCondition, this.state.formatMessage));
        //     } else {
        //         dispatch(createCase(ecgCase, searchCondition, this.state.formatMessage));
        //     }
        // }
    }

    _handleCancel() {
        this.props.dispatch(toggleBinderSelectModal(false));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleSelect.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_SELECT"/>
                </a>
            </div>
        )
    }

    _handleSelect(record) {
        this.props.onSelect && this.props.onSelect.call(this, record);
        this.props.dispatch(toggleBinderSelectModal(false));
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.currentPage = page;
        this.props.dispatch(searchBinders(searchCondition, this.state.formatMessage));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {searchCondition} = this.props;

        return (
            <Modal
                width={800}
                title={formatMessage({id: 'ECG_BINDER_TITLE_LIST'})}
                maskClosable={false}
                visible={this.props.showBinderSelectModal}
                onCancel={() => this._handleCancel()}
                cancelText={formatMessage({id: "COMMON_BUTTON_CLOSE"})}
                footer={[
                    <Button key="back" onClick={() => this._handleCancel()}>
                        <FormattedMessage id="COMMON_BUTTON_CLOSE"/>
                    </Button>
                ]}>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_BINDER_FIELD_USER_NAME'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.binderName} onChange={(value) => {
                                    this._handleChange("binderName", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'ECG_BINDER_FIELD_USER_MOBILE'})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.binderMobile} onChange={(value) => {
                                    this._handleChange("binderMobile", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.binders} pagination={false}/>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showBinderSelectModal: store.BinderReducer.showBinderSelectModal,
        binders: store.BinderReducer.binders,
        searchCondition: store.BinderReducer.searchCondition,
        pagination: store.BinderReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(BinderSelectModal));