/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Table, Divider, Switch, Modal} from 'antd';
import PaginationBar from "../../../../../components/PaginationBar";
import {
    searchEmployees,
    toggleDetailModal,
    getEmployeeDetailById,
    deleteEmployee,
    updateEmployee
} from '../../../actions/EmployeeAction';

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'code',
                    dataIndex: 'code',
                    title: formatMessage({id: 'ORG_EMPLOYEE_FIELD_CODE'}),
                    width: 150,
                },
                {
                    key: 'fullName',
                    dataIndex: 'fullName',
                    title: formatMessage({id: 'ORG_EMPLOYEE_FIELD_FULL_NAME'})
                },
                {
                    key: 'role', dataIndex: 'role',
                    title: formatMessage({id: 'ACCOUNT_ROLE_FIELD_NAME'}),
                    width: 150,
                    render: (text, record, index) => this._renderRoles(text, record, index)
                },
                {
                    key: 'mobile',
                    dataIndex: 'mobile',
                    title: formatMessage({id: 'ORG_EMPLOYEE_FIELD_MOBILE'}),
                    width: 150,
                },
                {
                    key: 'organization.name',
                    dataIndex: 'organization.name',
                    title: formatMessage({id: 'ORG_EMPLOYEE_FIELD_ORGANIZATION'}),
                    width: 150,
                },
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    width: 150,
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderRoles(text, record, index) {
        let roleStr = "";
        if (record.user !== null && record.user.roles !== null && record.user.roles.length > 0) {
            for (let i = 0; i < record.user.roles.length; i++) {
                if (i === 0) {
                    roleStr = roleStr + record.user.roles[i].name
                } else {
                    roleStr = "," + roleStr + record.user.roles[i].name
                }
            }
        }
        return (
            <span>{roleStr}</span>
        )
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
            </div>
        )
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getEmployeeDetailById(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "ORG_EMPLOYEE_TITLE_DELETE"}),
            content: formatMessage({id: "ORG_EMPLOYEE_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteEmployee(record, searchCondition, formatMessage));
            }
        })
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.employees} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchEmployees(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        employees: store.OrgEmployeeReducer.employees,
        searchCondition: store.OrgEmployeeReducer.searchCondition,
        pagination: store.OrgEmployeeReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));