/**
 * Created by Ethan on 2018/4/21.设备详情第三列
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {
    InputNumber,
    Button,
    Descriptions,
    Popover,
    Tag,
} from "antd";

import moment from 'moment';

import {EcgFileSource} from "../../../Enums";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";
import EcgTransferStatus from "../../../components/EcgTransferStatus";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class DeviceDetailUsage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    render() {
        const {device, deviceHistoryData, appHistoryData} = this.props;
        console.log("this.props:", deviceHistoryData, appHistoryData)
        const formatMessage = this.state.formatMessage;
        return (
            <Descriptions column={1}>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_ACTIVE_FLAG"})}>
                    {device.activeFlag === 1 ?
                        <FormattedMessage id="DAS_DEVICE_FIELD_ACTIVE_FLAG_TRUE"/> :
                        <FormattedMessage id="DAS_DEVICE_FIELD_ACTIVE_FLAG_FALSE"/>}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_ACTIVE_TIME"})}>
                    {device.activeTime !== '' ?
                        moment(device.activeTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}
                </Descriptions.Item>
                <Descriptions.Item label="设备实时传输状态">
                    <EcgTransferStatus status={device.devLiveOnlineStatus}/>
                </Descriptions.Item>
                <Descriptions.Item label="设备历史传输状态">
                    {device.generationInvalid === 1 ? <Tag color="blue">未重置</Tag> :
                        <EcgTransferStatus status={device.devHisOnlineStatus}/>}
                </Descriptions.Item>
                <Descriptions.Item label="手机实时传输状态">
                    <EcgTransferStatus status={device.appLiveOnlineStatus}/>
                </Descriptions.Item>
                <Descriptions.Item label="手机历史传输状态">
                    {device.generationInvalid === 1 ? <Tag color="blue">未重置</Tag> :
                        <EcgTransferStatus status={device.appHisOnlineStatus}/>}
                </Descriptions.Item>
                {/*<Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_LOCATION"})}>*/}
                {/*<Button type="link" size="small"><FormattedMessage*/}
                {/*id="DAS_DEVICE_FIELD_LOCATION_CURRENT"/></Button>*/}
                {/*<Button type="link" size="small"><FormattedMessage*/}
                {/*id="DAS_DEVICE_FIELD_LOCATION_TRAIL"/></Button>*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_WEB_SUBSCRIBER_NUM"})}>
                    {device.webSubscriberNum}
                </Descriptions.Item>

                {/*<Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_UPLOAD_PERCENT"})}>*/}
                {/*    <Popover content={*/}
                {/*        // <div>*/}
                {/*        //     {}*/}
                {/*        //     /!*<Descriptions column={1} size={"small"} bordered={true}>*!/*/}
                {/*        //     /!*    <Descriptions.Item*!/*/}
                {/*        //     /!*        label="最大历史数据文件编号">{deviceHistoryData ? deviceHistoryData[deviceHistoryData.length - 1].endIdx : 0}</Descriptions.Item>*!/*/}
                {/*        //     /!*    <Descriptions.Item*!/*/}
                {/*        //     /!*        label="未删除最小历史数据文件编号">{device.notEasedFileIndex}</Descriptions.Item>*!/*/}
                {/*        //     /!*    <Descriptions.Item*!/*/}
                {/*        //     /!*        label="已经读取最小历史数据文件编号">*!/*/}
                {/*        //     /!*        {*!/*/}
                {/*        //     /!*            deviceHistoryData ? deviceHistoryData[0].beginIdx : 0*!/*/}
                {/*        //     /!*        }*!/*/}
                {/*        //     /!*    </Descriptions.Item>*!/*/}
                {/*        //     /!*</Descriptions>*!/*/}
                {/*        // </div>*/}
                {/*        this._renderHistory(deviceHistoryData)*/}
                {/*    } title="设备详细">*/}
                {/*        <Button type="link"*/}
                {/*                size="small">{this._historyDataPercentage(deviceHistoryData)}%</Button>*/}
                {/*    </Popover>*/}
                {/*    <Popover content={*/}
                {/*        this._renderHistory(appHistoryData)*/}
                {/*    } title="APP详细">*/}
                {/*        <Button type="link"*/}
                {/*                size="small">{this._appHistoryDataPercentage(appHistoryData)}%</Button>*/}
                {/*    </Popover>*/}
                {/*</Descriptions.Item>*/}

                {/*<Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_WIFI_NUM"})}>*/}
                {/*    {device.wifiNum}*/}
                {/*</Descriptions.Item>*/}
                <Descriptions.Item label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LEAD_STATE"})}>
                    {device.leadOff}
                    <Popover content={
                        <Descriptions column={3} size={"small"} bordered={true}>
                            <Descriptions.Item span={2} label="正面">
                                <div style={{src: require("../../../../../images/front.png")}}>
                                    <img style={{width: 150, height: 150}}
                                         src={require("../../../../../images/front.png")} alt=""/>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 80,
                                        left: 165,
                                    }}
                                         src={require((device.leadDState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 90,
                                        left: 155,
                                    }}>V1</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 80,
                                        left: 200,
                                    }}
                                         src={require((device.leadEState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 90,
                                        left: 205,
                                    }}>V2</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 130,
                                        left: 165,
                                    }}
                                         src={require((device.leadGState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 140,
                                        left: 155,
                                    }}>V4</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 130,
                                        left: 200,
                                    }}
                                         src={require((device.leadHState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 140,
                                        left: 205,
                                    }}>V5</p>

                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 95,
                                        left: 130,
                                    }}
                                         src={require((device.leadAState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 85,
                                        left: 110,
                                    }}>RA</p>
                                    <img style={{
                                        width: 10, height: 10,
                                        position: "absolute",
                                        top: 95,
                                        left: 235,
                                    }}
                                         src={require((device.leadBState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                         alt=""/>
                                    <p style={{
                                        width: 10,
                                        height: 10,
                                        position: "absolute",
                                        top: 85,
                                        left: 245,
                                    }}>LA</p>
                                </div>
                            </Descriptions.Item>
                            <Descriptions.Item label="背面">
                                <img style={{width: 150, height: 150}}
                                     src={require("../../../../../images/back_front.png")}
                                     alt="">
                                </img>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 80,
                                    left: 520,
                                }}
                                     src={require((device.leadFState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 90,
                                    left: 525,
                                }}>V3</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 480,
                                }}
                                     src={require((device.leadCState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 485,
                                }}>LL</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 520,
                                }}
                                     src={require(((!device.leadBState && !device.leadCState &&
                                                    !device.leadDState && !device.leadEState &&
                                                    !device.leadFState && !device.leadGState &&
                                                    !device.leadHState) ? "../../../../../images/red.png" : "../../../../../images/blue.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 525,
                                }}>RL</p>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label="RA电极">{device.leadAState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V2电极">{device.leadEState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V3电极">{device.leadFState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LA电极">{device.leadBState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V4电极">{device.leadGState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="LL电极">{device.leadCState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>

                            <Descriptions.Item
                                label="V1电极">{device.leadDState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="V5电极">{device.leadHState ? <Tag color="green">正常 </Tag> :
                                <Tag color="red">脱落
                                </Tag>}</Descriptions.Item>
                            <Descriptions.Item
                                label="RL电极">{(!device.leadBState && !device.leadCState &&
                                                !device.leadDState && !device.leadEState &&
                                                !device.leadFState && !device.leadGState &&
                                                !device.leadHState) ? <Tag color="red">脱落 </Tag> :
                                <Tag color="green">正常 </Tag>}
                            </Descriptions.Item>
                        </Descriptions>
                    } title="详细">
                        <Button type="link" size="small">
                            {(device.leadAState && device.leadBState && device.leadCState && device.leadDState && device.leadEState && device.leadFState && device.leadGState && device.leadHState) ?
                                <Tag color="green">正常</Tag> :
                                (device.leadAState || device.leadBState || device.leadCState || device.leadDState || device.leadEState || device.leadFState || device.leadGState || device.leadHState) ?
                                    <Tag color="blue">部分脱落</Tag> : <Tag color="red">脱落</Tag>}
                        </Button>
                    </Popover>
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_TIME"})}>
                    {(device.lastHisDataReceivedTime && device.lastHisDataReceivedTime !== '') ?
                        moment(device.lastHisDataReceivedTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}
                </Descriptions.Item>
                <Descriptions.Item
                    label={formatMessage({id: "DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_SOURCE"})}>
                    <EnumItemLabel map={EcgFileSource.Map} code={device.lastHisDataSource}/>
                </Descriptions.Item>
            </Descriptions>
        );
    }

    _renderHistory(deviceHistoryData) {
        let text = " ";
        this.testRef = React.createRef();
        if (deviceHistoryData.length > 0) {
            for (var i = 0; i < deviceHistoryData.length; i++) {
                text += deviceHistoryData[i].beginIdx + "--" + deviceHistoryData[i].endIdx + "\n "
            }
        }
        if (text == " ") {
            text = "服务器上未有数据"
        }
        return (
            <div style={{whiteSpace: 'pre-wrap', overflow: "auto", height: "200px"}} ref={this.testRef}>
                {text}
            </div>
        )
    }

    _historyDataPercentage(deviceHistoryData) {
        var str = 0;
        if (deviceHistoryData.length > 0) {
            for (var i = 0; i < deviceHistoryData.length; i++) {
                str += deviceHistoryData[i].endIdx - deviceHistoryData[i].beginIdx;
            }
            str = (str + 1) / deviceHistoryData[deviceHistoryData.length - 1].endIdx;
            str = (str * 100).toFixed(2);
        }
        //parseInt((device.readFileIndex / device.writeFileIndex) * 100)
        //    \ ? ((device.readFileIndex / device.writeFileIndex) * 100) : 0
        return str ? str : 0
    }

    _appHistoryDataPercentage(appHistoryData) {
        var str = 0;
        if (appHistoryData.length > 0) {
            for (var i = 0; i < appHistoryData.length; i++) {
                str += appHistoryData[i].endIdx - appHistoryData[i].beginIdx;
            }
            str = (str + 1) / appHistoryData[appHistoryData.length - 1].endIdx;
            str = (str * 100).toFixed(2);
        }
        //parseInt((device.readFileIndex / device.writeFileIndex) * 100)
        //    \ ? ((device.readFileIndex / device.writeFileIndex) * 100) : 0
        return str ? str : 0
    }

}


// const mapStateToProps = (store) => {
//     return {
//
//     }
// };


function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(DeviceDetailUsage));