import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";

export function refreshSelectedRoles(selectedRoles) {
    return {
        type: ActionTypes.ROLE_REFRESH_SELECTED_ROLES,
        selectedRoles: selectedRoles
    }
}

export function refreshShowRoles(showRoles) {
    return {
        type: ActionTypes.ROLE_REFRESH_SHOW_ROLES,
        showRoles: showRoles
    }
}

export function getAllRoles(defaultChecked, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ROLE_LOADING, isLoading: true});

        HttpUtils.get(WebService.getAllRoles, (response) => {
            dispatch({
                type: ActionTypes.ROLE_SEARCH,
                roles: response.data
            });

            // 默认选中全部
            if (defaultChecked){
                const selectedRoles = [];
                response.data.map((item, i) => {
                    return (
                        selectedRoles.push(item.id)
                    )
                });
                dispatch(refreshSelectedRoles(selectedRoles));
            }

            dispatch({type: ActionTypes.ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}