/**
 * Created by Ethan on 17/1/13.
 */

const Validator = {

    /*
     * 验证电话号码
     * */
    isMobileNumber: (mobile) => {

        if (mobile.length <= 0 || mobile.length !== 11) {
            return false;
        }

        const regex = /^(((13[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
        if (!regex.test(mobile)) {
            return false;
        }
        return true;
    },

    /**
     * 验证邮箱格式
     * @param email 邮箱地址
     * @returns {boolean} 是否是邮箱格式
     */
    isEmail: (email) => {
        const regex = /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.|-]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (!regex.test(email)) {
            return false;
        }
        return true;
    },

    isNumber: (number) => {
        const regex = /^[0-9]*$/;
        if (!regex.test(number)) {
            return false;
        }
        return true;
    },

    isMacAddress: (mac) => {
        const regex = /^[A-Fa-f0-9]{2}(:[A-Fa-f0-9]{2}){5}$/;
        if (!regex.test(mac)) {
            return false;
        }
        return true;
    },

    /**
     * 数字、字母大/小写、以及特殊字符四者至少取其三
     */
    isPasswordStrength: (password) => {
        const regex = /^(?:(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*?\(\)])).*$/;
        if (regex.test(password)) {
            return true;
        }
        return false;
    }
}
export default Validator;