import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {TreeSelect} from 'antd';

import {getOrganizationTree, selectListItem} from '../actions/OrganizationAction';
import OrgOrganizationReducer from "../reducers/OrganizationReducer";

class OrganizationTreeSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectItemKey: "",
        }
    }

    componentWillMount() {
        this.props.dispatch(getOrganizationTree(this.state.formatMessage));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== undefined && nextProps.value !== null) {
            this.setState({selectItemKey: nextProps.value});
        }
    }

    onChange = (value) => {
        this.setState({selectItemKey: value});
        // this.props.dispatch(selectListItem(value));
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <div>
                <TreeSelect
                    value={this.state.selectItemKey}
                    treeDefaultExpandAll
                    onChange={this.onChange}>
                    {
                        this.props.organizationsTree.map((item, i) => {
                            return (
                                <TreeSelect.TreeNode key={item.id} value={item.id} title={item.name}>
                                    {this._renderTreeNode(item)}
                                </TreeSelect.TreeNode>
                            )
                        })
                    }
                </TreeSelect>
            </div>
        );
    }

    _renderTreeNode(item) {
        if (item.children && item.children.length > 0) {
            return (
                item.children.map((item, i) => {
                    return (
                        <TreeSelect.TreeNode key={item.id} value={item.id} title={item.name}>
                            {this._renderTreeNode(item)}
                        </TreeSelect.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        organizationsTree: store.OrgOrganizationReducer.organizationsTree,
        selectItemKey: store.OrgOrganizationReducer.selectItemKey
    }
}

export default connect(mapStateToProps)(injectIntl(OrganizationTreeSelect));