export default class Medfilt {
    constructor(filterSize) {
        let N = filterSize;
        this.N = N;
        this.data = new Array(N + 2);
        this.pos = new Array(N);
        this.data.fill(0, 0, N + 2);
        this.heap = new Array(N + 2);
        this.midpos = (N >> 1) + 1;
        this.idx = 0;
        this.data[N] = Number.MIN_SAFE_INTEGER;
        this.data[N + 1] = Number.MAX_SAFE_INTEGER;
        this.heap[0] = N;
        this.heap[N + 1] = N + 1;
        let pos = this.pos;
        let heap = this.heap;
        while ((filterSize--) > 0) {
            pos[filterSize] = ((filterSize + 1) >> 1) * ((filterSize & 1) == 1 ? -1 : 1);
            heap[pos[filterSize] + this.midpos] = filterSize;
        }
        this.minCt = (N - 1) >> 1;
        this.maxCt = N >> 1;
    }

    minSortUp(v, p) {
        let heap = this.heap,
            data = this.data,
            pos = this.pos,
            midpos = this.midpos;
        let ti, tj, hi;
        tj = p;
        while (tj > 0) {
            ti = tj >> 1;
            hi = heap[ti + midpos];
            if (data[hi] > v) {
                heap[tj + midpos] = hi;
                pos[hi] = tj;
                tj = ti;
            } else
                break;
        }
        return tj;
    }

    maxSortUp(v, p) {
        let heap = this.heap,
            data = this.data,
            pos = this.pos,
            midpos = this.midpos;
        let ti, tj, hi;
        tj = p;
        while (tj < 0) {
            ti = Math.trunc(tj / 2); //tj >> 1;
            hi = heap[ti + midpos];
            if (data[hi] < v) {
                heap[tj + midpos] = hi;
                pos[hi] = tj;
                tj = ti;
            } else
                break;
        }
        return tj;
    }

    minSinkDown(v, p) {
        let pos = this.pos,
            data = this.data,
            heap = this.heap,
            idx = this.idx,
            midpos = this.midpos,
            minCt = this.maxCt;
        let ti, tj, h1, h2, ch1, ch2;
        tj = p;
        for (ti = p << 1; ti <= minCt; ti <<= 1) {
            h1 = heap[ti + midpos];
            ch1 = data[h1];
            h2 = heap[ti + 1 + midpos];
            ch2 = data[h2];
            if (ch1 > ch2) {
                ++ti;
                h1 = h2;
                ch1 = ch2;
            }
            if (ch1 < v) {
                heap[tj + midpos] = h1;
                pos[h1] = tj;
                tj = ti;
            } else {
                break;
            }
        }
        heap[tj + midpos] = idx;
        pos[idx] = tj;
    }

    maxSinkDown(v, p) {
        let pos = this.pos,
            data = this.data,
            heap = this.heap,
            idx = this.idx,
            midpos = this.midpos,
            maxCt = this.maxCt;
        let ti, tj, h1, h2, ch1, ch2;
        tj = p;
        for (ti = p << 1; ti >= -maxCt; ti <<= 1) {
            h1 = heap[ti + midpos];
            ch1 = data[h1];
            h2 = heap[midpos + ti - 1];
            ch2 = data[h2];
            if (ch1 < ch2) {
                --ti;
                h1 = h2;
                ch1 = ch2;
            }
            if (ch1 > v) {
                heap[tj + midpos] = h1;
                pos[h1] = tj;
                tj = ti;
            } else {
                break;
            }
        }
        heap[tj + midpos] = idx;
        pos[idx] = tj;
    }


    insert0(v) {
        let pos = this.pos,
            data = this.data,
            heap = this.heap,
            idx = this.idx,
            midpos = this.midpos;
        let p = pos[idx];
        let old = data[idx];
        let hi, tj, h1;

        data[idx] = v;

        if (p > 0) {
            if (v > old) {
                this.minSinkDown(v, p);
                return;
            }

            if (v < old) {
                tj = this.minSortUp(v, p);
                if (tj > 0) {
                    heap[tj + midpos] = idx;
                    pos[idx] = tj;
                } else {
                    hi = heap[midpos - 1];
                    if (data[hi] > v) {
                        heap[midpos] = hi;
                        pos[hi] = 0;
                        this.maxSinkDown(v, -1);

                    } else {
                        heap[midpos] = idx;
                        pos[idx] = 0;
                    }
                }
            }
        } else if (p < 0) {
            if (v < old) {
                this.maxSinkDown(v, p);
                return;
            }

            if (v > old) {
                tj = this.maxSortUp(v, p);
                if (tj < 0) {
                    heap[tj + midpos] = idx;
                    pos[idx] = tj;
                } else {
                    hi = heap[midpos + 1];
                    if (data[hi] < v) {
                        heap[midpos] = hi;
                        pos[hi] = 0;
                        this.minSinkDown(v, 1);
                    } else {
                        heap[midpos] = idx;
                        pos[idx] = 0;
                    }
                }
            }
        } else {
            h1 = heap[midpos - 1];
            if (data[h1] > v) {
                heap[midpos] = h1;
                pos[h1] = 0;
                this.maxSinkDown(v, -1);
                return;
            }

            h1 = heap[midpos + 1];
            if (data[h1] < v) {
                heap[midpos] = h1;
                pos[h1] = 0;
                this.minSinkDown(v, 1);
            }
        }
    }
    insert(v) {
        this.insert0(v);
        this.idx = (this.idx + 1) % this.N;
    }
    stream0(input, out) {
        this.stream(input, 0, input.length, out, 0);
    }
    stream(input, inOffset, len, output, outOffset) {
        let data = this.data,
            heap = this.heap,
            midpos = this.midpos;
        if ((this.N & 0x1) == 1) {
            for (let i = 0; i < len; i++) {
                this.insert(input[i + inOffset]);
                output[i + outOffset] = data[heap[midpos]];
            }
        } else {
            for (let i = 0; i < len; i++) {
                this.insert(input[i + inOffset]);
                output[i + outOffset] = (data[heap[midpos]] + data[heap[midpos - 1]]) / 2;
            }
        }
    }
}