import React, {Component} from 'react';
import Colors from "../../../../../../constants/Colors";

export default class Sum extends Component {

    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <div style={{color: this.props.color, fontWeight: '800', fontSize: '24px'}}>{this.props.total}</div>
                <div><span style={{backgroundColor: Colors.WhiteSmoke, padding: '5px'}}>{this.props.title}</span></div>
                <div><span style={{color: Colors.DarkGray}}>昨日</span><span
                    style={{color: this.props.color}}>{this.props.incremental}</span></div>
            </div>
        );
    }
};

