/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, Switch, Checkbox} from "antd";

import RoleSelector from "../../../../Account/components/RoleSelector";
import OrganizationTreeSelect from "../../../components/OrganizationTreeSelect";
import {toggleDetailModal, createEmployee, updateEmployee, refreshEmployee} from "../../../actions/EmployeeAction";

import Validator from "../../../../../utils/Validator";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        const employee = Object.assign({}, this.props.employee);
        const user = employee.user ? employee.user : {};
        if (name === "roles") {
            const roles = [];
            for (let i = 0; i < value.length; i++) {
                roles.push({"id": value[i]});
            }
            user[name] = roles;
            employee["user"] = user;
        }
        if (name === "password" || name === "enabled") {
            user[name] = value.target ? value.target.value : value;
            employee["user"] = user;
        } else {
            employee[name] = value.target ? value.target.value : value;
        }
        this.props.dispatch(refreshEmployee(employee));
    }

    _handleOk() {
        const {dispatch, searchCondition, employee} = this.props;
        if (this._validate(employee)) {
            if (employee.id === undefined || employee.id === "") {
                dispatch(createEmployee(employee, searchCondition, this.state.formatMessage));
            } else {
                dispatch(updateEmployee(employee, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(employee) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};
        if (employee.code === undefined || employee.code === "") {
            messageMap["code"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        // 创建用户的时候需要验证密码必填项
        if (employee.id === undefined || employee.id === "") {
            if (!employee.user || !employee.user.password || employee.user.password === "") {
                messageMap["password"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
            }
        }

        if (employee.mobile === undefined || employee.mobile === "") {
            messageMap["mobile"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        } else if (!Validator.isMobileNumber(employee.mobile)) {
            messageMap["mobile"] = formatMessage({id: "ORG_EMPLOYEE_MSG_MOBILE_FORMAT_WRONG"});
        }
        if (employee.email === undefined || employee.email === "") {
            messageMap["email"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        } else if (!Validator.isEmail(employee.email)) {
            messageMap["email"] = formatMessage({id: "ORG_EMPLOYEE_MSG_EMAIL_FORMAT_WRONG"});
        }
        if (employee.lastName === undefined || employee.lastName === "") {
            messageMap["lastName"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (employee.firstName === undefined || employee.firstName === "") {
            messageMap["firstName"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (!employee.user || !employee.user.roles || employee.user.roles.length <= 0) {
            messageMap["roles"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }
        if (employee.organizationId === undefined || employee.organizationId === "") {
            messageMap["organizationId"] = formatMessage({id: "COMMON_MSG_REQUIRED"});
        }

        console.log(messageMap);

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleCancel() {
        this.props.dispatch(refreshEmployee({}));
        this.props.dispatch(toggleDetailModal(false));
    }

    render() {
        const {employee} = this.props;
        const formatMessage = this.state.formatMessage;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        let title = "";
        if (employee.id === undefined || employee.id === "") {
            title = formatMessage({id: "ORG_EMPLOYEE_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ORG_EMPLOYEE_TITLE_UPDATE"});
        }

        const roleIds = [];
        if (employee.user && employee.user.roles) {
            for (let i = 0; i < employee.user.roles.length; i++) {
                roleIds.push(employee.user.roles[i].id);
            }
        }

        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: "ORG_EMPLOYEE_FIELD_CODE"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={employee.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    {
                        (employee.id && employee.id !== "") ? null :
                            <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})} {...formItemLayout}
                                       required={true}
                                       validateStatus={this.state.messageMap["password"] ? "error" : ""}
                                       help={this.state.messageMap["password"]}>
                                <Input type="password" value={employee.user && employee.user.password}
                                       onChange={(value) => {
                                           this._handleChange("password", value)
                                       }}/>
                            </Form.Item>
                    }

                    <Form.Item label={formatMessage({id: "ORG_EMPLOYEE_FIELD_MOBILE"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["mobile"] ? "error" : ""}
                               help={this.state.messageMap["mobile"]}>
                        <Input value={employee.mobile}
                               onChange={(value) => {
                                   this._handleChange("mobile", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ORG_EMPLOYEE_FIELD_EMAIL"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["email"] ? "error" : ""}
                               help={this.state.messageMap["email"]}>
                        <Input value={employee.email}
                               onChange={(value) => {
                                   this._handleChange("email", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ORG_EMPLOYEE_FIELD_LAST_NAME"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["lastName"] ? "error" : ""}
                               help={this.state.messageMap["lastName"]}>
                        <Input value={employee.lastName}
                               onChange={(value) => {
                                   this._handleChange("lastName", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ORG_EMPLOYEE_FIELD_FIRST_NAME"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["firstName"] ? "error" : ""}
                               help={this.state.messageMap["firstName"]}>
                        <Input value={employee.firstName}
                               onChange={(value) => {
                                   this._handleChange("firstName", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_ROLE_FIELD_NAME"})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["roles"] ? "error" : ""}
                               help={this.state.messageMap["roles"]}>
                        <RoleSelector values={roleIds} onChange={(values) => {
                            this._handleChange("roles", values)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ORG_EMPLOYEE_FIELD_ORGANIZATION'})} {...formItemLayout}
                               required={true}
                               validateStatus={this.state.messageMap["organizationId"] ? "error" : ""}
                               help={this.state.messageMap["organizationId"]}>
                        <OrganizationTreeSelect value={employee.organizationId} onChange={(value) => {
                            this._handleChange("organizationId", value)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_ENABLE"})} {...formItemLayout}
                               required={true}>
                        <Switch checked={employee.user && employee.user.enabled === 1} onChange={(value) => {
                            this._handleChange("enabled", value ? 1 : 0)
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        employee: store.OrgEmployeeReducer.employee,
        searchCondition: store.OrgEmployeeReducer.searchCondition,
        showDetailModal: store.OrgEmployeeReducer.showDetailModal,
        roles: store.AccountRoleReducer.roles
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));