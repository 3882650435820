/**
 * Created by Ethan on 17/3/7.
 */

import React from 'react';
import {connect} from 'react-redux';

// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import NavigationBar from "../../../../../components/NavigationBar";
import HttpUtils from "../../../../../utils/HttpUtils";

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {WECHAT_APP_ID} from "../../../constants/WeChatConstants";
import {loginByOpenId} from "../../../actions/AuthAction";
import MessageDialog from "../../../../../components/MessageDialog";

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            code: '',
            email: '',
            mobile: '',
            bindSuccessVisible:false
        }
    }

    componentWillMount() {

        // 微信网页授权会携带code，然后通过后台获取openID，提交的时候把该openID跟访客绑定
        const code = HttpUtils.getQueryString("code");
        if (code) {
            this.setState({code: code})
        }
    }

    _handBindLogin() {
        if (this.state.mobile) {
            this.props.dispatch(loginByOpenId(this.state.code, WECHAT_APP_ID, {mobile: this.state.mobile}, this.state.formatMessage, (data) => {
                this.setState({bindSuccessVisible: true});
            }, (data) => {
                console.log(data);
            }));
        }
    }

    render() {
        const {formatMessage, messageMap} = this.state;
        return (
            <div>
                <MessageDialog visible={this.state.bindSuccessVisible}
                               title={this.state.formatMessage({id: 'WECHAT_AUTH_TITLE_USER_BIND'})}
                               content={this.state.formatMessage({id: 'WECHAT_AUTH_MSG_USER_BIND_SUCCESS'})}
                               onClose={() => this.setState({bindSuccessVisible: false})}/>
                <NavigationBar title={formatMessage({id: "WECHAT_AUTH_TITLE_USER_BIND"})}/>
                <Container>
                    <TextField
                        label={formatMessage({id: "WECHAT_AUTH_FIELD_USER_BIND_MOBILE"})}
                        placeholder={formatMessage({id: "WECHAT_AUTH_FIELD_USER_BIND_MOBILE_PLACEHOLDER"})}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required={true}
                        error={Object.keys(messageMap).includes("mobile")}
                        helperText={messageMap["mobile"]}
                        value={this.state.mobile}
                        onChange={(event) => {
                            this.setState({"mobile": event.target.value})
                        }}
                    />

                    <div className={"text-center"}>
                        <Button variant="contained" color="primary" onClick={() => this._handBindLogin()}>
                            <FormattedMessage id={'WECHAT_AUTH_BUTTON_USER_BIND'}/>
                        </Button>
                    </div>
                </Container>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));