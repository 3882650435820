/**
 * Created by Ethan on 17/3/12.
 */

export const HttpStatus = {
    "SUCCESS": 200,
    'UNAUTHORIZED': 401,
    "EXCEPTION": 417,
};

export const Language = {
    List: [
        {value: "zh-CN", label: "APP_MULTI_LANGUAGE_ZH"},
        {value: "en-US", label: "APP_MULTI_LANGUAGE_EN"},
        // {value: "ja-JP", label: "APP_LANGUAGE_JP"},
    ], Map: {
        "zh-CN": "APP_MULTI_LANGUAGE_ZH",
        "en-US": "APP_MULTI_LANGUAGE_EN",
        // "ja-JP": "APP_LANGUAGE_JP",
    }, Enum: {
        ZH: "zh-CN",
        EN: "en-US",
    }
};

export const ActiveStatusMap = {
    0: '未激活',
    1: '已激活'
};

export const BindStatusMap = {
    0: '未绑定',
    1: '已绑定'
};

export const RoleTypes = {
    'ADMINISTRATOR': 'Administrator',
    'SYS_MANAGER': 'SysManager',
    'NORMAL': 'Normal',
    'MANAGER': 'Manager'
};

// 访问WebService的返回值定义
export const ReturnCodes = {
    'SUCCESS': '200',
    'UNAUTHORIZED': '401',
    'NOT_ACCEPTABLE': '406',
    'EXPECTATION_FAILED': '417',
    "FETCH_FAILED": '500',
};

export const DeviceStates = {
    '1': 'stateInLibrary',
    '2': 'stateSold',
    '3': 'stateRework',
    '4': 'stateDiscarded'
};

export const BinderTypeMap = {
    '1': '用户',
    '2': '监护人'
};

export const ClothesSizeMap = {
    '1': 'S',
    '2': 'M',
    '3': 'L',
    '4': 'XL'
};
export const ClothesSizeList = [
    {key: "1", value: 'S'},
    {key: "2", value: 'M'},
    {key: "3", value: 'L'},
    {key: "4", value: 'XL'}
];

export const ClothesColorMap = {
    '1': '黑色',
    '2': '红色'
};
export const ClothesColorList = [
    {key: "1", value: '黑色'},
    {key: "2", value: '红色'}
];

export const ClothesClassifyMap = {
    '1': '男款',
    '2': '女款'
};
export const ClothesClassifyList = [
    {key: "1", value: '男款'},
    {key: "2", value: '女款'}
];
