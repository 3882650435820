import * as ActionTypes from '../ActionTypes';

const initialState = {
    application: {},
    isLoading: false
};

const EcgApplicationReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_APPLICATION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_APPLICATION_REFRESH_APPLICATION:
            return Object.assign({}, state, {
                application: action.application
            });
        default:
            return state
    }
};

export default EcgApplicationReducer;