/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Row, Col, Form, Button, Input, DatePicker} from 'antd';

import {
    refreshDevice, refreshDeviceMonitorColumns, refreshScreenMap,
    refreshSearchCondition,
    searchDeviceList,
    toggleDetailModal,
    toggleFieldSelectModal
} from '../../../actions/DeviceAction';
import {CACHE_DEVICE_MONITOR_COLUMNS} from "../../../../../constants/Profile";
import {DeviceCodeFilter, LeadState, SysState, OnlineState} from "../../../Enums";
import {
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE,
    FORMAT_TIME_COLON
} from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import SearchDrawer from "../../../../../components/SearchDrawer";
import EnumItemSelect from "../../../../../components/EnumItemSelect";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    componentWillMount() {
        // let deviceMonitorColumns = JSON.parse(localStorage.getItem(CACHE_DEVICE_MONITOR_COLUMNS));
        // if (!deviceMonitorColumns || deviceMonitorColumns.length <= 0) {
        this._resetFieldSelect();
        // }

        // 默认一页显示20条记录
        this.props.dispatch(refreshSearchCondition({pageSize: 20, deviceCodeFilter: "2"}));

        this.intervalRefresh = setInterval(() => {
            const screenMap = this.props.screenMap;
            const pathname = "/das/devices";

            if ((screenMap && !screenMap[pathname]) && this._validate(this.props.searchCondition)) {
                console.log("intervalRefresh");
                this._handleSearch(this.props.searchCondition, false);
            }
        }, 2000);

    }

    componentWillUnmount() {
        clearInterval(this.intervalRefresh);
    }

    componentDidMount() {
    }

    _validate(searchCondition) {
        const {formatMessage} = this.state;
        let messageMap = {};
        if (searchCondition.deviceCodeFilter &&
            searchCondition.deviceCodeFilter === "3") {
            if (searchCondition.deviceCodeStart && searchCondition.deviceCodeStart.length !== 8 &&
                searchCondition.deviceCodeStart !== "" && searchCondition.deviceCodeStart !== undefined) {
                messageMap["deviceCodeStart"] = formatMessage({id: "COMMON_MSG_LENGTH_EIGHT"});
            }
            if (searchCondition.deviceCodeEnd && searchCondition.deviceCodeEnd.length !== 8 &&
                searchCondition.deviceCodeEnd !== "" && searchCondition.deviceCodeEnd !== undefined) {
                messageMap["deviceCodeEnd"] = formatMessage({id: "COMMON_MSG_LENGTH_EIGHT"});
            }
            if (searchCondition.deviceCodeStart &&
                searchCondition.deviceCodeEnd &&
                searchCondition.deviceCodeStart !== "" &&
                searchCondition.deviceCodeStart !== undefined &&
                searchCondition.deviceCodeEnd !== "" &&
                searchCondition.deviceCodeEnd !== undefined &&
                searchCondition.deviceCodeStart.length === 8 &&
                searchCondition.deviceCodeEnd.length === 8 &&
                searchCondition.deviceCodeEnd <= searchCondition.deviceCodeStart) {
                messageMap["deviceCodeEnd"] = formatMessage({id: "COMMON_MSG_GREATER_START"});
            }
        }
        // console.log(searchCondition);
        // console.log(messageMap);
        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _resetFieldSelect() {
        const deviceMonitorColumns = [
            {
                key: 'code',
                dataIndex: 'code',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_CODE'}),
                visible: true
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_NAME'}),
                visible: false
            },
            {
                key: 'mac',
                dataIndex: 'mac',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_MAC'}),
                visible: false
            },
            {
                key: 'color',
                dataIndex: 'color',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_COLOR'}),
                visible: false
            },
            {
                key: 'dspVersion',
                dataIndex: 'dspVersion',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_DSP_VERSION'}),
                visible: true
            },
            {
                key: 'espVersion',
                dataIndex: 'espVersion',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_ESP_VERSION'}),
                visible: true
            },
            {
                key: 'workMode',
                dataIndex: 'workMode',
                title: "工作模式",
                visible: true
            },
            {
                key: 'appPowerSaveMode',
                dataIndex: 'appPowerSaveMode',
                title: "状态",
                width: 120,
                visible: true
            },
            // {
            //     key:'serverStatus',
            //     dataIndex: 'serverStatus',
            //     title: "wifi状态",
            //     visible: true
            // },
            // {
            //     key:'bleStatus',
            //     dataIndex: 'bleStatus',
            //     title: "蓝牙状态",
            //     visible: true
            // },
            // {
            //     key:'chargeState',
            //     dataIndex: 'chargeState',
            //     title: "充电状态",
            //     visible: true
            // },
            {
                key: 'timeDiff',
                dataIndex: 'timeDiff',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_TIME_DIFF'}),
                visible: false
            },
            {
                key: 'sampleRate',
                dataIndex: 'sampleRate',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_SAMPLE_RATE'}),
                visible: false
            },
            {
                key: 'sampleResolution',
                dataIndex: 'sampleResolution',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_SAMPLE_RESOLUTION'}),
                visible: false
            },
            {
                key: 'battVolume',
                dataIndex: 'battVolume',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_BATT_VOLUME'}),
                visible: false
            },
            // {
            //     key: 'chargeState',
            //     dataIndex: 'chargeState',
            //     title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_CHARGE_STATE'}),
            //     visible: false
            // },
            // {
            //     key: 'sysState',
            //     dataIndex: 'sysState',
            //     title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'}),
            //     visible: false
            // },
            {
                key: 'batteryVoltage',
                dataIndex: 'batteryVoltage',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_BATTERY_VOLTAGE'}),
                visible: false
            },
            {
                key: 'tempBq27441',
                dataIndex: 'tempBq27441',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BQ27441'}),
                visible: false
            },
            {
                key: 'tempBle',
                dataIndex: 'tempBle',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BLE'}),
                visible: false
            },
            {
                key: 'tempBat',
                dataIndex: 'tempBat',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_TEMP_BAT'}),
                visible: false
            },
            {
                key: 'devLiveOnlineStatus',
                dataIndex: 'devLiveOnlineStatus',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_DEV_LIVE_ONLINE_STATUS'}),
                visible: false
            },
            {
                key: 'devHisOnlineStatus',
                dataIndex: 'devHisOnlineStatus',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_DEV_HIS_ONLINE_STATUS'}),
                visible: false
            },
            {
                key: 'appLiveOnlineStatus',
                dataIndex: 'appLiveOnlineStatus',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_APP_LIVE_ONLINE_STATUS'}),
                visible: true
            },
            {
                key: 'appHisOnlineStatus',
                dataIndex: 'appHisOnlineStatus',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_APP_HIS_ONLINE_STATUS'}),
                visible: true
            },
            {
                key: 'webSubscriberNum',
                dataIndex: 'webSubscriberNum',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_WEB_SUBSCRIBER_NUM'}),
                visible: false
            },
            {
                key: 'appSubscriberNum',
                dataIndex: 'appSubscriberNum',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_APP_SUBSCRIBER_NUM'}),
                visible: false
            },
            {
                key: 'fileUploadPercent',
                dataIndex: 'fileUploadPercent',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_UPLOAD_PERCENT'}),
                visible: false
            },
            {
                key: 'wifiNum',
                dataIndex: 'wifiNum',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_WIFI_NUM'}),
                visible: false
            },
            {
                key: 'leadOff',
                dataIndex: 'leadOff',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'}),
                visible: true
            },
            {
                key: 'lastUpdateTime',
                dataIndex: 'lastUpdateTime',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LAST_REDIS_UPDATE_TIME'}),
                visible: false
            },
            {
                key: 'lastHisDataReceivedTime',
                dataIndex: 'lastHisDataReceivedTime',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_TIME'}),
                visible: false
            },
            {
                key: 'lastHisDataSource',
                dataIndex: 'lastHisDataSource',
                title: this.state.formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LAST_ECG_RECEIVE_SOURCE'}),
                visible: false
            },

        ];

        localStorage.setItem(CACHE_DEVICE_MONITOR_COLUMNS, JSON.stringify(deviceMonitorColumns));
        this.props.dispatch(refreshDeviceMonitorColumns(deviceMonitorColumns));
    }

    _handleSearch(searchCondition) {

    }

    //查询方法
    _handleSearch(searchCondition, flag) {
        console.log("searchCondition:", searchCondition)
        searchCondition.paginationFlag = false;
        this.props.dispatch(searchDeviceList(searchCondition, this.state.formatMessage));
        const screenMap = this.props.screenMap;
        const pathname = "/das/devices";
        if (flag) {
            screenMap[pathname] = true;
            if (screenMap && screenMap[pathname]) {
                screenMap[pathname] = !screenMap[pathname];
            } else {
                screenMap[pathname] = true;
            }
        }
        const searchMapTemp = Object.assign({}, screenMap);
        if (searchMapTemp){
            this.props.dispatch(refreshScreenMap(searchMapTemp));
        }
    }

    //查询条件
    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        console.log("_handleChange:", searchCondition, this.props.searchCondition)
        if (value || value === 0) {
            // console.log("searchCondition[name]:", searchCondition[name], searchCondition);
            if (name === "deviceLeadStatesTemp") {
                searchCondition[name] = value.target ? [String(value.target.value)] : [String(value)];
                // console.log("value.target ? [String(value.target.value)] : [String(value)]:",
                //     value.target ? [String(value.target.value)] : [String(value)]);
            } else if (searchCondition.deviceCodeFilter === "0" && name === "deviceCode") {
                searchCondition[name] = value.target ? value.target.value + "%" : value + "%";
            } else if (searchCondition.deviceCodeFilter === "1" && name === "deviceCode") {
                searchCondition[name] = value.target ? "%" + value.target.value : "%" + value;
            } else if (searchCondition.deviceCodeFilter === "2" && name === "deviceCode") {
                searchCondition[name] = value.target ? "%" + value.target.value + "%" : "%" + value + "%";
            } else {
                searchCondition[name] = value.target ? value.target.value : value;
            }
            // console.log(searchCondition[name], searchCondition);
        } else {

            delete searchCondition[name];

        }
        if (name === "deviceCodeFilter" &&
            (searchCondition.deviceCodeFilter === "" || searchCondition.deviceCodeFilter === undefined)) {
            if (searchCondition.deviceCode || searchCondition.deviceCode === 0) {
                delete searchCondition["deviceCode"];
            }
            if (searchCondition.deviceCodeStart || searchCondition.deviceCodeStart === 0) {
                delete searchCondition["deviceCodeStart"];
            }
            if (searchCondition.deviceCodeEnd || searchCondition.deviceCodeEnd === 0) {
                delete searchCondition["deviceCodeEnd"];
            }
        }
        if (name === "deviceCodeFilter" &&
            searchCondition.deviceCodeFilter !== "" && searchCondition.deviceCodeFilter !== undefined) {
            if (searchCondition.deviceCode || searchCondition.deviceCode === 0) {
                delete searchCondition["deviceCode"];
            }
            if (searchCondition.deviceCodeStart || searchCondition.deviceCodeStart === 0) {
                delete searchCondition["deviceCodeStart"];
            }
            if (searchCondition.deviceCodeEnd || searchCondition.deviceCodeEnd === 0) {
                delete searchCondition["deviceCodeEnd"];
            }
        }
        // searchCondition[name] = value.target ? value.target.value : value;
        // console.log(searchCondition)
        console.log(name, value, searchCondition);
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshDevice({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleShowImportModal() {
        // this.props.dispatch(toggleImportModal(true));
    }

    _handleEmpty() {
        this.props.dispatch(refreshSearchCondition({}));
    }

    _returnValue(searchCondition) {
        if (searchCondition.deviceCodeFilter === "3") {
            return searchCondition.deviceCodeStart;
        } else {
            let deviceCode = searchCondition.deviceCode;
            if (searchCondition.deviceCodeFilter === "0") {
                deviceCode = deviceCode && deviceCode.substring(0, deviceCode.lastIndexOf('%'));
            } else if (searchCondition.deviceCodeFilter === "1") {
                deviceCode = deviceCode && deviceCode.substring(1);
            } else {
                deviceCode = deviceCode && deviceCode.substring(1, deviceCode.lastIndexOf('%'));
            }
            return deviceCode;
        }
    }

    render() {
        const {searchCondition} = this.props;
        const {formatMessage, messageMap} = this.state;

        return (
            <SearchDrawer placement={"top"}>
                <Form layout="inline" style={{backgroundColor: "#ffffcc", paddingLeft: "5px"}}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                style={{paddingLeft: 57}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})}>
                                <EnumItemSelect data={DeviceCodeFilter.List}
                                                value={searchCondition.deviceCodeFilter}
                                                style={{width: 120}}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    this._handleChange("deviceCodeFilter", value)
                                                }}/>
                            </Form.Item>
                            {
                                searchCondition.deviceCodeFilter ?
                                    <Form.Item
                                        validateStatus={messageMap["deviceCodeStart"] ? "error" : ""}
                                        help={messageMap["deviceCodeStart"]}
                                    >
                                        <Input style={{width: 127}}
                                               maxLength={8}
                                               value={this._returnValue(searchCondition)}
                                               onChange={(value) => {
                                                   if (searchCondition.deviceCodeFilter === "3") {
                                                       this._handleChange("deviceCodeStart", value)
                                                   } else {
                                                       this._handleChange("deviceCode", value)
                                                   }
                                               }}/>
                                    </Form.Item> : null
                            }
                            {
                                searchCondition.deviceCodeFilter === "3" ?
                                    <Form.Item>
                                        <Form.Item>--</Form.Item>
                                        <Form.Item
                                            validateStatus={messageMap["deviceCodeEnd"] ? "error" : ""}
                                            help={messageMap["deviceCodeEnd"]}
                                        >
                                            <Input style={{width: 127}}
                                                   maxLength={8}
                                                   value={searchCondition.deviceCodeEnd}
                                                   onChange={(value) => {
                                                       this._handleChange("deviceCodeEnd", value)
                                                   }}/>
                                        </Form.Item>
                                    </Form.Item> : null
                            }
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{paddingLeft: 29}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_DEV_LIVE_ONLINE_STATUS'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={searchCondition.devLiveOnlineStatusTemp}
                                                style={{width: 120}}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    this._handleChange("devLiveOnlineStatusTemp", value)
                                                }}/>

                            </Form.Item>
                            <Form.Item
                                style={{paddingLeft: 80}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_APP_LIVE_ONLINE_STATUS'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={searchCondition.appLiveOnlineStatusTemp}
                                                style={{width: 120}}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    this._handleChange("appLiveOnlineStatusTemp", value)
                                                }}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                style={{paddingLeft: 29}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_LATEST_USAGE_TIME'})}>
                                <Form.Item>
                                    <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                                format={FORMAT_DATE_TIME_HYPHEN}
                                                allowClear={true}
                                                value={
                                                    searchCondition.lastUpdateStartTimeTemp ?
                                                        moment(searchCondition.lastUpdateStartTimeTemp, FORMAT_DATE_TIME_SIMPLE)
                                                        :
                                                        null
                                                }
                                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                                onChange={(value) => {
                                                    this._handleChange("lastUpdateStartTimeTemp", value && value.format(FORMAT_DATE_TIME_SIMPLE))
                                                }}/>
                                </Form.Item>
                                <Form.Item>--</Form.Item>
                                <Form.Item>
                                    <DatePicker showTime={{format: FORMAT_TIME_COLON}}
                                                format={FORMAT_DATE_TIME_HYPHEN}
                                                allowClear={true}
                                                value={
                                                    searchCondition.lastUpdateEndTimeTemp ?
                                                        moment(searchCondition.lastUpdateEndTimeTemp, FORMAT_DATE_TIME_SIMPLE)
                                                        :
                                                        null
                                                }
                                                placeholder={formatMessage({id: "COMMON_PLACEHOLDER_SELECT_DATE"})}
                                                onChange={(value) => {
                                                    this._handleChange("lastUpdateEndTimeTemp", value && value.format(FORMAT_DATE_TIME_SIMPLE))
                                                }}/>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{paddingLeft: 29}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_DEV_HIS_ONLINE_STATUS'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={searchCondition.devHisOnlineStatusTemp}
                                                style={{width: 120}}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    this._handleChange("devHisOnlineStatusTemp", value)
                                                }}/>
                            </Form.Item>
                            <Form.Item
                                style={{paddingLeft: 80}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_APP_HIS_ONLINE_STATUS'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={searchCondition.appHisOnlineStatusTemp}
                                                style={{width: 120}}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    this._handleChange("appHisOnlineStatusTemp", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>
                            <Form.Item
                                style={{paddingLeft:55}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_ACTIVE_FLAG'})}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={searchCondition.deviceOnlineStates}
                                                mode="multiple"
                                                style={{width: 177}}
                                                onChange={(value) => {
                                                    this._handleChange("deviceOnlineStates", value)
                                                }}/>
                            </Form.Item>
                        </Col>*/}
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item*/}
                        {/*        style={{paddingLeft: 55}}*/}
                        {/*        label={formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'})}>*/}
                        {/*        <EnumItemSelect data={SysState.List}*/}
                        {/*                        value={searchCondition.deviceSysStates}*/}
                        {/*                        mode="multiple"*/}
                        {/*                        style={{width: 120}}*/}
                        {/*                        onChange={(value) => {*/}
                        {/*                            this._handleChange("deviceSysStates", value)*/}
                        {/*                        }}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                style={{paddingLeft: 21}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})}>
                                <Input
                                    value={searchCondition.deviceMac}
                                    style={{width: 120}}
                                    onChange={(value) => {
                                        this._handleChange("deviceMac", value)
                                    }}/>
                            </Form.Item>
                            <Form.Item
                                style={{paddingLeft: 110}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})}>
                                <EnumItemSelect data={LeadState.List}
                                                style={{width: 120}}
                                                allowClear={true}
                                                value={searchCondition.deviceLeadStatesTemp && Number(searchCondition.deviceLeadStatesTemp[0])}
                                                onChange={(value) => {
                                                    this._handleChange("deviceLeadStatesTemp", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>
                            <Form.Item
                                style={{paddingLeft:34}}
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_APP_LIVE_ONLINE_STATUS'})}>
                                <EnumItemSelect data={OnlineStatus.List}
                                                value={searchCondition.appLiveOnlineStatus}
                                                style={{width: 120}}
                                                onChange={(value) => {
                                                    this._handleChange("appLiveOnlineStatus", value)
                                                }}/>
                            </Form.Item>
                        </Col>*/}

                    </Row>
                    {/*<Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_CODE'})} {...Label_6_Wrapper_18}>
                                <Input onChange={(value) => {
                                    this._handleChange("deviceCode", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_NAME'})} {...Label_6_Wrapper_18}>
                                <Input onChange={(value) => {
                                    this._handleChange("deviceName", value)
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_MAC'})} {...Label_6_Wrapper_18}>
                                <Input onChange={(value) => {
                                    this._handleChange("deviceMac", value)
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_SYS_STATE'})} {...Label_6_Wrapper_18}>
                                <EnumItemSelect data={SysState.List} value={this.props.searchCondition.deviceSysStates}
                                                mode="multiple"
                                                onChange={(value) => {
                                                    this._handleChange("deviceSysStates", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_ONLINE_STATUS'})} {...Label_6_Wrapper_18}>
                                <EnumItemSelect data={OnlineState.List}
                                                value={this.props.searchCondition.deviceOnlineStates}
                                                mode="multiple"
                                                onChange={(value) => {
                                                    this._handleChange("deviceOnlineStates", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: 'DAS_DEVICE_FIELD_FILE_LEAD_STATE'})} {...Label_6_Wrapper_18}>
                                <EnumItemSelect data={LeadState.List}
                                                value={this.props.searchCondition.deviceLeadStates}
                                                mode="multiple"
                                                onChange={(value) => {
                                                    this._handleChange("deviceLeadStates", value)
                                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>*/}
                    <div className="text-center">
                        <br/>
                        <Button type="primary" icon="delete" className="spacing-h"
                                onClick={() => this._handleEmpty()}>
                            <FormattedMessage id='COMMON_BUTTON_EMPTY'/>
                        </Button>
                        <Button type="primary" icon="search" className="spacing-h" loading={this.props.isLoading}
                                onClick={() => this._handleSearch(this.props.searchCondition, true)}>
                            <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                        </Button>
                        <Button icon="filter" className="spacing-h"
                                onClick={() => this.props.dispatch(toggleFieldSelectModal(true))}>
                            <FormattedMessage id='DAS_DEVICE_BUTTON_FIELD_SELECT'/>
                        </Button>
                        <Button icon="filter"
                                onClick={() => this._resetFieldSelect()}>
                            <FormattedMessage id='DAS_DEVICE_BUTTON_FIELD_SELECT_RESET'/>
                        </Button>
                    </div>
                </Form>
            </SearchDrawer>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.EcgDeviceReducer.isLoading,
        searchCondition: store.EcgDeviceReducer.searchCondition,
        deviceListScreenVisible: store.EcgDeviceReducer.deviceListScreenVisible,
        screenMap: store.EcgDeviceReducer.screenMap
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));