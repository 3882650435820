import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {Radio} from "antd";

class EnumItemRadio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {

        const options = [];
        this.props.data && this.props.data.map((item, index) => {
            options.push({label: this.state.formatMessage({id: item.label}), value: item.value})
        });

        return (
            <Radio.Group options={options} value={this.props.value} onChange={(value) => {
                this.onChange(value.target.value)
            }}/>
        );
    }
}

export default (injectIntl(EnumItemRadio));