/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage,injectIntl} from 'react-intl';

import TaskMonitorMap from "./TaskMonitorMap";
import SearchCondition from "./SearchCondition";

import './InfoWindow.css';
import SearchResult from "./SearchResult";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            map: new Object()
        }
    }

    componentDidMount() {
        /*let txt = document.getElementsByClassName("BMap_pop")[0];
        alert(txt);*/
    }

    getChildrenMsg = (result, msg) => {
        // console.log(result, msg)
        // 很奇怪这里的result就是子组件那bind的第一个参数this，msg是第二个参数
        this.setState({
            map: msg
        })
    }

    render() {
        return (
            <div className=" position-relative" style={{height: '100%',overflow: "hidden"}}>
                {/*<PageTitle title="DAS_DASHBOARD_ROUTE_MONITOR"/>*/}
                <TaskMonitorMap parent={ this } {...this.props}/>
                <SearchCondition {...this.state} />
                {/*<TaskMonitorMap />*/}
                {/*<SearchCondition />*/}
                <SearchResult />
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}

export default connect(mapStateToProps)(injectIntl(Index));