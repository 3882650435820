import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";


export function getNcovData(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DAS_ECG_LOADING, isLoading: true});

        HttpUtils.get(WebService.getNCOV, (response) => {
            dispatch({type: ActionTypes.DAS_NCOV_LOADING, isLoading: false});

            // key：省份
            // value：每日数据Map=>key：日期，value：确诊人数，疑似病例，治愈人数，死亡人数
            const provinceStatistic = {};

            for (let item of response.data) {
                if (!item.province) {
                    continue;
                }

                let provinceDataMap = provinceStatistic[item.province];
                // 不包含这个日期的数据，则增加这个数据对象
                if (!provinceDataMap) {
                    provinceDataMap = {};
                    provinceStatistic[item.province] = provinceDataMap;
                }

                let provinceData = provinceDataMap[item.monitorDate];
                if (!provinceData) {
                    // 添加省份数据
                    provinceData = {};
                    provinceData.publishTime = item.publishTime;
                    provinceData.confirmed = item.provinceConfirmed;
                    provinceData.suspected = item.provinceSuspected;
                    provinceData.cure = item.provinceCure;
                    provinceData.dead = item.provinceDead;
                    provinceData.city = {};

                    provinceDataMap[item.monitorDate] = provinceData;
                }

                // 添加城市数据
                provinceData.city[item.city] = {
                    confirmed: item.cityConfirmed,
                    suspected: item.citySuspected,
                    cure: item.cityCure,
                    dead: item.cityDead
                };
            }

            let countryConfirmed = 0;
            let countryConfirmedChange = 0;
            let countrySuspected = 0;
            let countrySuspectedChange = 0;
            let countryCure = 0;
            let countryCureChange = 0;
            let countryDead = 0;
            let countryDeadChange = 0;


            // key：日期
            // value：确诊人数，疑似病例，治愈人数，死亡人数
            const countryStatistic = {};

            for (let key in provinceStatistic) {
                const provinceDataMap = provinceStatistic[key];
                const provinceDataMapKeys = Object.keys(provinceDataMap);

                for (let monitorDate of provinceDataMapKeys) {
                    let countryData = countryStatistic[monitorDate];
                    if (!countryData) {
                        // 如果该日没有国家数据，则初始化
                        countryData = {};
                        countryData.publishTime = provinceDataMap[monitorDate].publishTime;
                        countryData.confirmed = 0;
                        countryData.suspected = 0;
                        countryData.cure = 0;
                        countryData.dead = 0;
                        countryStatistic[monitorDate] = countryData;
                    }
                    countryData.confirmed = countryData.confirmed + provinceDataMap[monitorDate].confirmed;
                    countryData.suspected = countryData.suspected + provinceDataMap[monitorDate].suspected;
                    countryData.cure = countryData.cure + provinceDataMap[monitorDate].cure;
                    countryData.dead = countryData.dead + provinceDataMap[monitorDate].dead;
                }
            }

            // console.log(countryStatistic);

            dispatch({
                type: ActionTypes.DAS_NCOV_STATISTIC,
                countryStatistic: countryStatistic,
                provinceStatistic: provinceStatistic
            });
        }, (response) => {
            dispatch({type: ActionTypes.DAS_NCOV_LOADING, isLoading: false});
        }, formatMessage)
    }
}