/**
 * Created by Ethan on 2017/5/1.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

class DateRangeDivider extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    render() {
        return (
            <span style={{
                display: 'inline-block',
                margin: 'auto 5px',
                textAlign: 'center'
            }}>~</span>
        )
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(DateRangeDivider);
