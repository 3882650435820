/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Table, Divider, Tag, Popover, Descriptions, Tooltip} from 'antd';

import {
    getAllDevices,
    getDeviceById, getDeviceHistoryData, refreshSearchCondition,
    searchDeviceList,
    toggleDetailModal,
} from '../../../actions/DeviceAction';

import PaginationBar from "../../../../../components/PaginationBar";
import {RoutePath} from "../../../../../constants/RoutePath";
import {CACHE_DEVICE_MONITOR_COLUMNS} from "../../../../../constants/Profile";
import {ChargeState, EcgFileSource, SysState} from "../../../Enums";

import {
    FORMAT_DATE_SIMPLE,
    FORMAT_DATE_TIME_HYPHEN,
    FORMAT_DATE_TIME_SIMPLE
} from "../../../../../constants/DateTimeFormats";

import moment from 'moment';
import {searchDeviceCoordinates} from "../../../actions/DeviceCoordinateAction";
import EcgTransferStatus from "../../../components/EcgTransferStatus";
import {
    initDeviceLogs, refreshRealtimeScreenPointSum
} from "../../../actions/LogDataServerAction";
import EnumItemLabel from "../../../../../components/EnumItemLabel";
import Button from "@material-ui/core/Button";

let dataAll = [];
let dataPage = [];

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    // key: 'appPowerSaveMode',
                    // dataIndex: 'appPowerSaveMode',
                    // title: "状态",
                    // // width: 300,
                    // render: (text, record, index) =>
                }
            ]
        }
    }

    componentWillMount() {
    }

    _renderWorkMode(text, record, index) {
        let flag = record.appHisOnlineStatus != 0 || record.appLiveOnlineStatus != 0
            || record.devLiveOnlineStatus != 0 || record.devHisOnlineStatus != 0;
        var str = '';
        var color = 'red'
        switch (text) {
            case 0:
                str = '初始'
                break
            case 1:
                str = '开机'
                break
            case 2:
                str = '配对'
                break
            case 3:
                str = '待机'
                break
            case 4:
                str = '采集'
                color = 'blue'
                break
            case 5:
                str = 'U盘'
                break
            case 6:
                str = '全工'
                color = "green"
                break
            case 7:
                str = '关机'
                break
            default:
                flag = false;
                break
        }
        var res = []
        if (flag) {
            res.push(
                <Tag color={color}>{str}</Tag>
            )
        }
        return (
            <div>
                {res}
            </div>
        )
    }

    _renderLeadOff(text, record, index) {
        let flag = record.appHisOnlineStatus != 0 || record.appLiveOnlineStatus != 0
            || record.devLiveOnlineStatus != 0 || record.devHisOnlineStatus != 0;
        return (
            <div>
                {flag ? <Popover content={
                    <Descriptions column={3} size={"small"} bordered={true}>
                        <Descriptions.Item span={2} label="正面">
                            <div style={{src: require("../../../../../images/front.png")}}>
                                <img style={{width: 150, height: 150}}
                                     src={require("../../../../../images/front.png")} alt=""/>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 80,
                                    left: 165,
                                }}
                                     src={require((record.leadDState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 90,
                                    left: 155,
                                }}>V1</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 80,
                                    left: 200,
                                }}
                                     src={require((record.leadEState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 90,
                                    left: 205,
                                }}>V2</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 165,
                                }}
                                     src={require((record.leadGState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 155,
                                }}>V4</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 130,
                                    left: 200,
                                }}
                                     src={require((record.leadHState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 140,
                                    left: 205,
                                }}>V5</p>

                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 95,
                                    left: 130,
                                }}
                                     src={require((record.leadAState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 85,
                                    left: 110,
                                }}>RA</p>
                                <img style={{
                                    width: 10, height: 10,
                                    position: "absolute",
                                    top: 95,
                                    left: 235,
                                }}
                                     src={require((record.leadBState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                     alt=""/>
                                <p style={{
                                    width: 10,
                                    height: 10,
                                    position: "absolute",
                                    top: 85,
                                    left: 245,
                                }}>LA</p>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="背面">
                            <img style={{width: 150, height: 150}}
                                 src={require("../../../../../images/back_front.png")}
                                 alt="">
                            </img>
                            <img style={{
                                width: 10, height: 10,
                                position: "absolute",
                                top: 80,
                                left: 520,
                            }}
                                 src={require((record.leadFState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                 alt=""/>
                            <p style={{
                                width: 10,
                                height: 10,
                                position: "absolute",
                                top: 90,
                                left: 525,
                            }}>V3</p>
                            <img style={{
                                width: 10, height: 10,
                                position: "absolute",
                                top: 130,
                                left: 480,
                            }}
                                 src={require((record.leadCState ? "../../../../../images/blue.png" : "../../../../../images/red.png"))}
                                 alt=""/>
                            <p style={{
                                width: 10,
                                height: 10,
                                position: "absolute",
                                top: 140,
                                left: 485,
                            }}>LL</p>
                            <img style={{
                                width: 10, height: 10,
                                position: "absolute",
                                top: 130,
                                left: 520,
                            }}
                                 src={require(((!record.leadBState && !record.leadCState &&
                                                !record.leadDState && !record.leadEState &&
                                                !record.leadFState && !record.leadGState &&
                                                !record.leadHState) ? "../../../../../images/red.png" : "../../../../../images/blue.png"))}
                                 alt=""/>
                            <p style={{
                                width: 10,
                                height: 10,
                                position: "absolute",
                                top: 140,
                                left: 525,
                            }}>RL</p>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="RA电极">{record.leadAState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="V2电极">{record.leadEState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="V3电极">{record.leadFState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="LA电极">{record.leadBState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="V4电极">{record.leadGState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="LL电极">{record.leadCState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>

                        <Descriptions.Item
                            label="V1电极">{record.leadDState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="V5电极">{record.leadHState ? <Tag color="green">正常 </Tag> :
                            <Tag color="red">脱落
                            </Tag>}</Descriptions.Item>
                        <Descriptions.Item
                            label="RL电极">{(!record.leadBState && !record.leadCState &&
                                            !record.leadDState && !record.leadEState &&
                                            !record.leadFState && !record.leadGState &&
                                            !record.leadHState) ? <Tag color="red">脱落 </Tag> :
                            <Tag color="green">正常 </Tag>}</Descriptions.Item>
                    </Descriptions>
                } title="详细">
                    {(record.leadAState && record.leadBState && record.leadCState && record.leadDState && record.leadEState && record.leadFState && record.leadGState && record.leadHState) ?
                        <Tag color="green">正常</Tag> :
                        (record.leadAState || record.leadBState || record.leadCState || record.leadDState || record.leadEState || record.leadFState || record.leadGState || record.leadHState) ?
                            <Tag color="blue">部分脱落</Tag> : <Tag color="red">脱落</Tag>}
                </Popover> : ""}
            </div>
        )

    }

    _renderState(text, record, index) {
        let flag = record.appHisOnlineStatus != 0 || record.appLiveOnlineStatus != 0
            || record.devLiveOnlineStatus != 0 || record.devHisOnlineStatus != 0;
        let appFlag = record.appHisOnlineStatus != 0 || record.appLiveOnlineStatus != 0
        let devFlag = record.devLiveOnlineStatus != 0 || record.devHisOnlineStatus != 0;

        var strBle;
        switch (record.bleStatus) {
            case "0":
                strBle = "设备蓝牙未打开"
                break
            case "1":
                strBle = "设备蓝牙已打开"
                break
            case "2":
                strBle = "设备蓝牙已连接"
                break
            case "3":
                strBle = "设备蓝牙开始配对"
                break
        }
        var strWifi = [];
        strWifi.push("心电设备连接状态：")
        strWifi.push(<br/>)
        switch (record.serverConnected) {
            case 0:
                strWifi.push("服务器    未连接")
                strWifi.push(<br/>)
                break
            case 1:
                strWifi.push("服务器    已连接")
                strWifi.push(<br/>)
                break
        }

        switch (record.mobileConnected) {
            case 0:
                strWifi.push("手机APP   未连接")
                break
            case 1:
                strWifi.push("手机APP   已连接")
                break
        }

        var strCharge;
        switch (record.chargeState) {
            case "0":
                strCharge = "初始态"
                break
            case "1":
                strCharge = "充电态"
                break
            case "2":
                strCharge = "放电态"
                break
            case "3":
                strCharge = "插着电源，但既没有充电也没有放电"
                break
        }

        var res = []
        if (flag) {
            res.push(
                <Tooltip title={strCharge} autoAdjustOverflow={true}>
                    <img style={{width: 20, height: 20}}
                         src={require((record.chargeState == 1 || record.chargeState == 3) ? '../../../../../images/icon_on_charge.png' : '../../../../../images/icon_off_charge.png')}
                         alt=""/>
                </Tooltip>
            )
            res.push(
                <Tooltip title={strWifi} autoAdjustOverflow={true}>
                    <img style={{width: 25, height: 20, paddingLeft: 5}}
                         src={require((record.mobileConnected != 0 || record.mobileConnected != 0)
                             ? '../../../../../images/icon_on_wifi.png' : '../../../../../images/icon_off_wifi.png')}
                         alt=""/>
                </Tooltip>
            )
            res.push(
                <Tooltip title={strBle} autoAdjustOverflow={true}>
                    <img style={{width: 25, height: 20, paddingLeft: 5}}
                         src={require(record.bleStatus == 2 ? '../../../../../images/icon_on_blue.png' : '../../../../../images/icon_off_blue.png')}
                         alt=""/>
                </Tooltip>
            )
            if (appFlag) {
                res.push(
                    <Tooltip title={text == 1 ? "APP节能模式" : "APP非节能模式"} autoAdjustOverflow={true}>
                        <img style={{width: 25, height: 20, paddingLeft: 5}}
                             src={require(text == 1 ? '../../../../../images/icon_on.png' : '../../../../../images/icon_off.png')}
                             alt=""/>
                    </Tooltip>
                )
            }
        }

        return (
            <div>
                {res}
            </div>
        )
    }


    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowDetailModal.bind(this, record, 1)}>
                    <FormattedMessage id="COMMON_BUTTON_DETAIL"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleShowDetailModalRealtime.bind(this, record)}>
                    <FormattedMessage id="DAS_ECG_ROUTE_REAL_TIME"/>
                </a>
                {/*<Link to={RoutePath.DAS_ECG_REAL_TIME.path + "?deviceCode=" + record.code}>*/}
                {/*    <FormattedMessage id="DAS_ECG_ROUTE_REAL_TIME"/>*/}
                {/*</Link>*/}
                <Divider type="vertical"/>
                <a onClick={this._handleShowDetailModalHistory.bind(this, record)}>
                    <FormattedMessage id="DAS_ECG_ROUTE_HISTORY"/>
                </a>
                {/*<Link to={RoutePath.ECG_FILE_CALENDAR.path + "?deviceCode=" + record.code}>*/}
                {/*    <FormattedMessage id="DAS_ECG_ROUTE_HISTORY"/>*/}
                {/*</Link>*/}
                {/*
                <Divider type="vertical"/>
                <a>
                    <FormattedMessage id="DAS_ECG_ROUTE_EVENTS"/>
                </a>
                <Divider type="vertical"/>
                <a>
                    <FormattedMessage id="DAS_ECG_ROUTE_REPORT"/>
                </a>
                */}
            </div>
        )
    }

    _selectDevices() {
        const allAllDevices = Object.assign([], this.props.devices);
        const monitorSearchCondition = Object.assign({}, this.props.searchCondition);
        dataAll = [];
// debugger;
        for (let item of allAllDevices) {

            //设备实时传输状态
            if (monitorSearchCondition.devLiveOnlineStatusTemp !== undefined) {
                if (monitorSearchCondition.devLiveOnlineStatusTemp !== item.devLiveOnlineStatus) {
                    continue;
                }
            }

            //设备历史传输状态
            if (monitorSearchCondition.devHisOnlineStatusTemp !== undefined) {
                if (monitorSearchCondition.devHisOnlineStatusTemp !== item.devHisOnlineStatus) {
                    continue;
                }
            }

            //手机实时传输状态
            if (monitorSearchCondition.appLiveOnlineStatusTemp !== undefined) {
                if (monitorSearchCondition.appLiveOnlineStatusTemp !== item.appLiveOnlineStatus) {
                    continue;
                }
            }

            //手机历史传输状态
            if (monitorSearchCondition.appHisOnlineStatusTemp !== undefined) {
                if (monitorSearchCondition.appHisOnlineStatusTemp !== item.appHisOnlineStatus) {
                    continue;
                }
            }

            //电极状态
            if (monitorSearchCondition.deviceLeadStatesTemp !== undefined) {
                // debugger;
                if (monitorSearchCondition.deviceLeadStatesTemp[0] === "0") {
                    if (item.leadAState === 0 || item.leadBState === 0 || item.leadCState === 0 || item.leadDState === 0 ||
                        item.leadEState === 0 || item.leadFState === 0 || item.leadGState === 0 || item.leadHState === 0) {
                        dataAll.push(JSON.parse(JSON.stringify(item)));
                    }
                } else {
                    if (item.leadAState === 1 && item.leadBState === 1 && item.leadCState === 1 && item.leadDState === 1 &&
                        item.leadEState === 1 && item.leadFState === 1 && item.leadGState === 1 && item.leadHState === 1) {
                        dataAll.push(JSON.parse(JSON.stringify(item)));
                    }
                }

                continue;
            }

            //最近使用时间
            if (monitorSearchCondition.lastUpdateStartTimeTemp !== undefined &&
                monitorSearchCondition.lastUpdateStartTimeTemp > item.lastHisDataReceivedTime) {
                continue;
            }
            if (monitorSearchCondition.lastUpdateEndTimeTemp !== undefined &&
                monitorSearchCondition.lastUpdateEndTimeTemp < item.lastHisDataReceivedTime) {
                continue;
            }
            // if(monitorSearchCondition.deviceSysStatesTemp !== undefined && !monitorSearchCondition.deviceSysStatesTemp.includes(item.sysState)){
            //     continue;
            // }
            dataAll.push(JSON.parse(JSON.stringify(item)));
        }
        // console.log("monitorSearchCondition:" + JSON.stringify(monitorSearchCondition));
        dataAll.sort((a, b) => a.code.localeCompare(b.code, 'en') * -1);
        console.log("dataAll:" + dataAll.length, dataAll);
    }

    _handlePaginationData() {
        // debugger;
        const searchCondition = Object.assign({}, this.props.searchCondition);
        let page = searchCondition.currentPage;
        let pageSize = searchCondition.pageSize;

        //organizations.sort((a, b) => a.name.localeCompare(b.name, 'en'));
        dataPage = [];
        if (page === undefined || page === 0) {
            dataPage = dataAll.slice(0, pageSize).concat();
        } else {
            dataPage = dataAll.slice((page - 1) * pageSize, page * pageSize).concat();
        }
    }

    render() {
        this._selectDevices();
        this._handlePaginationData();

        const deviceMonitorColumns = JSON.parse(localStorage.getItem(CACHE_DEVICE_MONITOR_COLUMNS));
        const columns = Object.assign([], this.state.columns);

        for (let deviceMonitorColumn of deviceMonitorColumns) {
            let flag = false;
            if (deviceMonitorColumn.visible) {
                if (deviceMonitorColumn.key === 'code') {
                    deviceMonitorColumn.filters = dataPage.map((item, index) => {
                        return {
                            text: item.code,
                            value: item.code,
                        }
                    });
                    deviceMonitorColumn.render = (text, record, index) =>
                        <a onClick={this._handleShowDetailModal.bind(this, record, 2)}>{text}</a>
                    // <Link to={RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + text}>
                    //     {text}
                    // </Link>
                    deviceMonitorColumn.sorter = (a, b) => a.code.localeCompare(b.code);
                    deviceMonitorColumn.onFilter = (value, record) => record.code.indexOf(value) === 0;
                } else if (deviceMonitorColumn.key === 'name') {
                    deviceMonitorColumn.sorter = (a, b) => a.name.localeCompare(b.name);
                } else if (deviceMonitorColumn.key === 'mac') {
                    deviceMonitorColumn.sorter = (a, b) => a.mac.localeCompare(b.mac);
                } else if (deviceMonitorColumn.key === 'color') {
                    deviceMonitorColumn.sorter = (a, b) => a.color.localeCompare(b.color);
                } else if (deviceMonitorColumn.key === 'dspVersion') {
                    deviceMonitorColumn.sorter = (a, b) => a.dspVersion - b.dspVersion;
                } else if (deviceMonitorColumn.key === 'espVersion') {
                    deviceMonitorColumn.sorter = (a, b) => a.espVersion - b.espVersion;
                } else if (deviceMonitorColumn.key === 'timeDiff') {
                    deviceMonitorColumn.sorter = (a, b) => a.timeDiff - b.timeDiff;
                    deviceMonitorColumn.render = (text, record, index) => (record.timeDiff < -10 || record.timeDiff > 10) ?
                        <strong className="text-danger">{record.timeDiff}秒</strong> :
                        <span>{record.timeDiff} 秒</span>
                } else if (deviceMonitorColumn.key === 'sampleRate') {
                    deviceMonitorColumn.sorter = (a, b) => a.sampleRate - b.sampleRate;
                } else if (deviceMonitorColumn.key === 'sampleResolution') {
                    deviceMonitorColumn.sorter = (a, b) => a.sampleResolution - b.sampleResolution;
                } else if (deviceMonitorColumn.key === 'battVolume') {
                    deviceMonitorColumn.sorter = (a, b) => a.battVolume - b.battVolume;
                    deviceMonitorColumn.render = (text, record, index) => <span>{text}%</span>
                } else if (deviceMonitorColumn.key === 'chargeState') {
                    deviceMonitorColumn.sorter = (a, b) => a.chargeState.localeCompare(b.chargeState);
                    // <EnumItemLabel map={ChargeState.Map} code={text}/>
                } else if (deviceMonitorColumn.key === 'sysState') {
                    deviceMonitorColumn.sorter = (a, b) => a.sysState.localeCompare(b.sysState);
                    deviceMonitorColumn.render = (text, record, index) => <EnumItemLabel map={SysState.Map}
                                                                                         code={text}/>
                } else if (deviceMonitorColumn.key === 'batteryVoltage') {
                    deviceMonitorColumn.sorter = (a, b) => a.batteryVoltage - b.batteryVoltage;
                    deviceMonitorColumn.render = (text, record, index) => <span>{text}mV</span>
                } else if (deviceMonitorColumn.key === 'tempBq27441') {
                    deviceMonitorColumn.sorter = (a, b) => a.tempBq27441 - b.tempBq27441;
                    deviceMonitorColumn.render = (text, record, index) => <span>{text / 10}°C</span>
                } else if (deviceMonitorColumn.key === 'tempBle') {
                    deviceMonitorColumn.sorter = (a, b) => a.tempBle - b.tempBle;
                    deviceMonitorColumn.render = (text, record, index) => <span>{text / 10}°C</span>
                } else if (deviceMonitorColumn.key === 'tempBat') {
                    deviceMonitorColumn.sorter = (a, b) => a.tempBat - b.tempBat;
                    deviceMonitorColumn.render = (text, record, index) => <span>{text / 10}°C</span>
                } else if (deviceMonitorColumn.key === 'devLiveOnlineStatus') {
                    deviceMonitorColumn.sorter = (a, b) => a.devLiveOnlineStatus - b.devLiveOnlineStatus;
                    deviceMonitorColumn.render = (text, record, index) => text ? flag = true : "";
                    deviceMonitorColumn.render = (text, record, index) => <EcgTransferStatus status={text}/>
                } else if (deviceMonitorColumn.key === 'devHisOnlineStatus') {
                    deviceMonitorColumn.sorter = (a, b) => a.devHisOnlineStatus - b.devHisOnlineStatus;
                    deviceMonitorColumn.render = (text, record, index) => text ? flag = true : "";
                    deviceMonitorColumn.render = (text, record, index) => record.generationInvalid === 1 ?
                        <Tag color="blue">未重置</Tag> :
                        <EcgTransferStatus status={text}/>
                } else if (deviceMonitorColumn.key === 'appLiveOnlineStatus') {
                    deviceMonitorColumn.sorter = (a, b) => a.appLiveOnlineStatus - b.appLiveOnlineStatus;
                    deviceMonitorColumn.render = (text, record, index) => text ? flag = true : "";
                    deviceMonitorColumn.render = (text, record, index) => <EcgTransferStatus status={text}/>
                } else if (deviceMonitorColumn.key === 'appHisOnlineStatus') {
                    deviceMonitorColumn.sorter = (a, b) => a.appHisOnlineStatus - b.appHisOnlineStatus;
                    deviceMonitorColumn.render = (text, record, index) => text ? flag = true : "";
                    deviceMonitorColumn.render = (text, record, index) => record.generationInvalid === 1 ?
                        <Tag color="blue">未重置</Tag> :
                        <EcgTransferStatus status={text}/>
                } else if (deviceMonitorColumn.key === 'bleStatus') {
                    // deviceMonitorColumn.sorter = (a, b) => a.chargeState.localeCompare(b.chargeState);
                } else if (deviceMonitorColumn.key === 'serverStatus') {
                } else if (deviceMonitorColumn.key === 'webSubscriberNum') {
                    deviceMonitorColumn.sorter = (a, b) => a.webSubscriberNum - b.webSubscriberNum;
                } else if (deviceMonitorColumn.key === 'appSubscriberNum') {
                    deviceMonitorColumn.sorter = (a, b) => a.appSubscriberNum - b.appSubscriberNum;
                } else if (deviceMonitorColumn.key === 'fileUploadPercent') {
                    deviceMonitorColumn.sorter = (a, b) => a.readFileIndex / a.writeFileIndex - b.readFileIndex / b.writeFileIndex;
                    deviceMonitorColumn.render = (text, record, index) =>
                        <span>{parseInt((record.readFileIndex / record.writeFileIndex) * 100)}%</span>
                } else if (deviceMonitorColumn.key === 'wifiNum') {
                    deviceMonitorColumn.sorter = (a, b) => a.wifiNum - b.wifiNum;
                } else if (deviceMonitorColumn.key === 'leadOff') {
                    deviceMonitorColumn.sorter = (a, b) => (a.leadAState && a.leadBState && a.leadCState && a.leadDState && a.leadEState && a.leadFState && a.leadGState && a.leadHState).toString().localeCompare((b.leadAState && b.leadBState && b.leadCState && b.leadDState && b.leadEState && b.leadFState && b.leadGState && b.leadHState).toString());
                    deviceMonitorColumn.render = (text, record, index) => this._renderLeadOff(text, record, index)

                } else if (deviceMonitorColumn.key === 'lastHisDataReceivedTime') {
                    deviceMonitorColumn.sorter = (a, b) => a.lastHisDataReceivedTime.localeCompare(b.lastHisDataReceivedTime);
                    deviceMonitorColumn.render = (text, record, index) => <span>{(text && text !== '') ?
                        moment(text, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}</span>
                } else if (deviceMonitorColumn.key === 'lastUpdateTime') {
                    deviceMonitorColumn.sorter = (a, b) => a.lastUpdateTime.localeCompare(b.lastUpdateTime);
                    deviceMonitorColumn.render = (text, record, index) => <span>{(text && text !== '') ?
                        moment(text, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_HYPHEN) : ""}</span>
                } else if (deviceMonitorColumn.key === 'appPowerSaveMode') {
                    deviceMonitorColumn.render = (text, record, index) => this._renderState(text, record, index)
                } else if (deviceMonitorColumn.key === 'lastHisDataSource') {
                    deviceMonitorColumn.sorter = (a, b) => a.lastHisDataSource - b.lastHisDataSource;
                    deviceMonitorColumn.render = (text, record, index) => <EnumItemLabel map={EcgFileSource.Map}
                                                                                         code={text}/>
                } else if (deviceMonitorColumn.key === "workMode") {
                    deviceMonitorColumn.render = (text, record, index) => this._renderWorkMode(text, record, index)
                }
                columns.splice(columns.length - 1, 0, deviceMonitorColumn);
            }
        }

        const pagination = this.props.pagination;
        console.log("pagination:", pagination)
        pagination.recordSum = dataAll.length;
        return (
            <Card className="spacing-v page-width">
                <Button style={{background: "#e4e4e4"}} onClick={() => this.handleMonitorRegion()}>区域监控</Button>
                <PaginationBar pagination={pagination}
                               showSizeChanger={true}
                               onShowSizeChange={(page, pageSize) => this._handlePagination(page, pageSize)}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id"
                       size="small"
                       className="spacing-v"
                       pagination={false}
                       columns={columns}
                       scroll={{y: 638}}
                       dataSource={dataPage}
                       style={{marginBottom: 10}}
                />
                <PaginationBar pagination={pagination}
                               showSizeChanger={true}
                               onShowSizeChange={(page, pageSize) => this._handlePagination(page, pageSize)}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchDeviceList(searchCondition, this.state.formatMessage, false, false, false));
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowDetailModal(record, index) {
        const startTime = moment().format(FORMAT_DATE_SIMPLE + "000000");
        const endTime = moment().format(FORMAT_DATE_SIMPLE + "235959");
        this.props.dispatch(searchDeviceCoordinates(record.id, startTime, endTime, this.state.formatMessage));
        this.props.dispatch(getDeviceHistoryData(record.code, "dev", "03", this.state.formatMessage));
        this.props.dispatch(getDeviceById(record.id, this.state.formatMessage));

        this.props.dispatch(initDeviceLogs(record.code));
        console.log("_handleShowDetailModal:", this)
        if (index == 2) {
            this.props.router.push(RoutePath.DAS_DEVICE_DETAIL.path + "?deviceCode=" + record.code);// + "?deviceCode=" + record.code
        } else {
            this.props.dispatch(toggleDetailModal(true));
        }

    }

    _handleShowDetailModalRealtime(record, index) {
        this.props.dispatch(refreshRealtimeScreenPointSum(180));
        this.props.router.push(RoutePath.DAS_ECG_REAL_TIME.path + "?deviceCode=" + record.code);// + "?deviceCode=" + record.code
    }

    _handleShowDetailModalHistory(record, index) {
        this.props.dispatch(refreshRealtimeScreenPointSum(180));
        this.props.router.push(RoutePath.ECG_FILE_CALENDAR.path + "?deviceCode=" + record.code);// + "?deviceCode=" + record.code
    }

    handleMonitorRegion() {
        this.props.router.push(RoutePath.MONITOR_REGION.path);
    }
}

function mapStateToProps(store) {
    return {
        devices: store.EcgDeviceReducer.devices,
        deviceHistoryData: store.EcgDeviceReducer.deviceHistoryData,
        appHistoryData: store.EcgDeviceReducer.appHistoryData,
        pagination: store.EcgDeviceReducer.pagination,
        searchCondition: store.EcgDeviceReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));