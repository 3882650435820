import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

// import {
//
//     refreshFilterMapViewportFlag,
//     refreshSelectZoneId
// } from "../../../actions/ZoneAction";
import {VIEWPORT_FLAG} from "../../../Enums";
// import {BMapUtils} from "../../../../../utils/BMapUtils";
// import "../../../../../utils/InfoBox.js"

const noImageUrl = require("../../../../../images/noImage.svg");
let viewPoints;
let infoBox;
let defaultZoom = 12;
let bmap;

class FilterBMap extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],

        }
    }

    componentDidMount() {
        bmap = new window.BMap.Map("allmap");    // 创建Map实例

        //map自定义加组件
        const down_left_control = new window.BMap.ScaleControl({anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT});// 右下角，添加比例尺
        const down_left_navigation = new window.BMap.NavigationControl({anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT});  //右下角，添加默认缩放平移控件
        // const down_right_navigation = new window.BMap.NavigationControl({
        //     anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
        //     type: window.BMAP_NAVIGATION_CONTROL_SMALL
        // }); //右下角，仅包含平移和缩放按钮
        /*缩放控件type有四种类型:
        BMAP_NAVIGATION_CONTROL_SMALL：仅包含平移和缩放按钮；
        BMAP_NAVIGATION_CONTROL_PAN:仅包含平移按钮；
        BMAP_NAVIGATION_CONTROL_ZOOM：仅包含缩放按钮*/
        if (bmap) {
            bmap.addControl(down_left_control);
            bmap.addControl(down_left_navigation);
            // bmap.addControl(down_right_navigation);
        }
        bmap.centerAndZoom(new window.BMap.Point(121.50, 31.33), defaultZoom);  // 初始化地图,设置中心点坐标和地图级别
        bmap.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        this.forceUpdate();
    }


    scrollToAnchor(id) {
        document.getElementById(id).scrollIntoView(false);//根据ID的位置进行滚动
        console.log("id:", id)
    }

    _renderMapMarker(item, index) {
        const self = this;
        const blueUrl = require("../../../../../images/bmap/location-blue.svg");
        const blueCUrl = require("../../../../../images/bmap/location-blue-change.svg");
        const selectedUrl = require("../../../../../images/bmap/location-red.svg");
        const point = new window.BMap.Point(item.longitude, item.latitude);
        const label = new window.BMap.Label;
        label.setContent(index + 1);
        //Label的中心定位，个位数的时候和2位数的时候分开处理。
        //标注在图片上显示的偏移量
        if (index >= 9) {
            label.setOffset(new window.BMap.Size(7, 5));
        } else if (index >= 99) {
            label.setOffset(new window.BMap.Size(3, 5));
        } else {
            label.setOffset(new window.BMap.Size(11, 5));
        }

        label.setStyle({
            width: "26px", height: "28px",
            fontSize: "12px", backgroundColor: "transparent",
            border: "none", fontWeight: "900", color: "white"
        });

        // 创建标注
        //黄色被选中
        let yellowIcon = new window.BMap.Icon(selectedUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });
        //蓝色默认
        let blueIcon = new window.BMap.Icon(blueUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });
        let blueChangeIcon = new window.BMap.Icon(blueCUrl,
            new window.BMap.Size(32, 32), {
                imageSize: new window.BMap.Size(32, 32),
                offset: new window.BMap.Size(10, 24),
            });
        let marker;
        //点击标注修改图标
        if (this.props.selectedDeviceId === item.id) {

            marker = new window.BMap.Marker(point, {icon: yellowIcon});
            marker.setTop(true);//将标注置于其他标注之上。
        } else {
            //判断是否进过详情框，有的话对应的标注修改图标
            // if (this.props.readZoneIdList.indexOf(item.id) >= 0) {
            //     marker = new window.BMap.Marker(point, {icon: blueChangeIcon});
            // }else{
            marker = new window.BMap.Marker(point, {icon: blueIcon});
            // }
            marker.addEventListener("click", () => this._handleBlueMarkerClick(item));

            marker.setTop(false);
        }
        marker.setLabel(label);

        if (bmap) {
            // marker.addEventListener("mouseover", () => ZoneMapInfoBoxUtils.drawOpen(marker, bmap, {
            //         name: item.name,
            //         number: item.number,
            //         photos: item.photos
            //     },
            //     {
            //         markerWidth: 5,
            //         markerHeight: 5
            //     }));
            // marker.addEventListener("mouseout", () => ZoneMapInfoBoxUtils.drawClose());
            bmap.addOverlay(marker);
            //console.log(marker);
        }
        if (viewPoints) {
            viewPoints.push(point);
        }
    }

    _handleBlueMarkerClick(item) {
        // this.props.dispatch(refreshSelectZoneId(item.id));
        // this.props.dispatch(refreshFilterMapViewportFlag(VIEWPORT_FLAG.NO_CHANGE));
        this.scrollToAnchor(item.id);
        // this._handleYellowMarkerClick(item);
    }

    _renderViewPoint() {
        //isLoading判断后台数据是否传递完
        if (bmap && this.props.filterMapViewportFlag === VIEWPORT_FLAG.CHANGE && !this.props.isLoading) {
            bmap.setViewport(viewPoints);
        }
    }

    render() {

        if (bmap) {
            bmap.clearOverlays();
            //标注点在地图可视范围内
            if (this.props.filterMapViewportFlag === VIEWPORT_FLAG.CHANGE) {
                viewPoints = [];
            }
        }
        return (
            <div style={{height: '100%', position: 'relative'}}>
                <div id="allmap" style={{height: '100%'}}></div>
                {
                    this.props.mapDevices.map((item, index) => {
                        //把查询到的所需数据进行描绘
                        this._renderMapMarker(item, index);
                    })
                }
                {this._renderViewPoint()}
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        mapDevices: store.EcgDeviceReducer.mapDevices,
        // rectangleShowValue: store.WMSZoneReducer.rectangleShowValue,
        // rectangleCenterPoint: store.WMSZoneReducer.rectangleCenterPoint,
        //
        selectedDeviceId: store.EcgDeviceReducer.selectedDeviceId,
        filterMapViewportFlag: store.EcgDeviceReducer.filterMapViewportFlag,
        isLoading: store.EcgDeviceReducer.isLoading,
        // readZoneIdList: store.WMSZoneReducer.readZoneIdList
    }
}

export default connect(mapStateToProps)(injectIntl(FilterBMap));