/**
 * Created by HGB on 21/10/15.
 */


import React, { Component } from 'react';
import { connect } from 'react-redux';

// 引入多语言Message
import { injectIntl } from 'react-intl';

import OneselfBindModal from "./OneselfBindModal";
import PageTitle from "../../../../components/PageTitle";
import OneselfListModal from "./OneselfListModal";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import HttpUtils from "../../../../utils/HttpUtils";
import {refreshSearchDeviceCode} from "../../actions/DeviceAction";

let height;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            height: "100%",
        }
    }

    componentDidMount() {
        this._handleResize();
        // 注册窗口大小变化事件
        window.addEventListener('resize', this._handleResize);
    }

    _handleResize = () => {
        height = (document.body.clientHeight - 65) + "px";
        this.setState({height: height});
    };

    render() {
        return (
            <div className="page-content position-relative" style={{maxHeight: height}}>
                <PageTitle title="ECG_BINDER_ROUTE_ONESELF"/>
                <OneselfBindModal/>
                <OneselfListModal/>
                <SearchCondition/>
                <SearchResult/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}
export default connect(mapStateToProps)(injectIntl(Index));