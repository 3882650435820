/**
 * Created by Ethan on 17/3/16.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";
import {searchDeviceList} from "./DeviceAction";
import {getBinderDevices, toggleDeviceBindModal} from "./BinderAction";
import {DAS_USER_MOBILE_DATA} from "../ActionTypes";

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_GUARDER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleBinderListModal(isShow) {
    return {
        type: ActionTypes.ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL,
        showBinderListModal: isShow
    }
}

export function refreshGuarder(guarder) {
    return {
        type: ActionTypes.ECG_GUARDER_REFRESH_GUARDER,
        guarder: guarder
    }
}

export function searchGuardians(searchCondition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchGuarderList, searchCondition, (response) => {
            dispatch({
                type: ActionTypes.ECG_GUARDER_SEARCH,
                guardians: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getGuarderBinders(guarderUserId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: true});

        const requestUrl = WebService.getGuarderBinders.replace("{userId}", guarderUserId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_GUARDER_REFRESH_BINDERS,
                guarderBinders: response.data
            });

            dispatch({type: ActionTypes.ECG_GUARDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function refreshBinder(binder) {
    return {
        type: ActionTypes.ECG_BINDER_REFRESH_BINDER,
        binder: binder
    }
}

export function toggleGuarderListModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_GUARDER_LIST_MODAL,
        showGuarderListModal: isShow
    }
}

export function toggleGuarderBindModal(isShow) {
    return {
        type: ActionTypes.ECG_BINDER_TOGGLE_GUARDER_BIND_MODAL,
        showGuarderBindModal: isShow
    }
}

export function getMobileData(mobileData) {
    return {
        type:ActionTypes.DAS_USER_MOBILE_DATA,
        mobileData:mobileData
    }
}

export function getBinderGuarders(binderUserId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        const requestUrl = WebService.getBinderGuarders.replace("{userId}", binderUserId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ECG_BINDER_REFRESH_GUARDERS,
                binderGuarders: response.data
            });
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function unbindGuarder(binderUserId, guarderId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        let requestUrl = WebService.unbindGuarder.replace("{userId}", binderUserId);
        requestUrl = requestUrl.replace("{guarderId}", guarderId);
        HttpUtils.delete(requestUrl, requestUrl, (response) => {
            dispatch(toggleGuarderBindModal(false));
            dispatch(getBinderGuarders(binderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_UNBIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function bindGuarder(binderUserId, guarderMobile, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: true});

        let requestUrl = WebService.bindGuarder.replace("{userId}", binderUserId);
        requestUrl = requestUrl.replace("{guarderMobile}", guarderMobile)
        HttpUtils.post(requestUrl, {}, (response) => {
            dispatch(toggleGuarderBindModal(false));
            dispatch(getBinderGuarders(binderUserId, formatMessage));
            message.success(formatMessage({id: "ECG_BINDER_MSG_BIND_SUCCESS"}));
            dispatch({type: ActionTypes.ECG_BINDER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

