import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Select} from "antd";
import {ContentTitleLayout} from "../Enums";

class ContentTitleLayoutSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <Select value={this.props.value}
                    onChange={this.onChange}>
                {
                    ContentTitleLayout.List.map((item, i) => {
                        return (
                            <Select.Option key={i} value={item.value}>
                                <FormattedMessage id={item.label}/>
                            </Select.Option>
                        )
                    })
                }
            </Select>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(ContentTitleLayoutSelector));