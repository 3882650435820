/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils'
import message from "../../../components/toast";

export function refreshCity(city) {
    return {
        type: ActionTypes.DISTRICT_CITY_REFRESH_CITY,
        city: city
    }
}

export function refreshCityMap(cityMap) {
    return {
        type: ActionTypes.DISTRICT_CITY_REFRESH_CITY_MAP,
        cityMap: cityMap
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.DISTRICT_CITY_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function getCitiesByProvincialId(countryId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: true});

        const requestURL = WebService.getCitiesByProvincialId.replace("{id}", countryId);
        HttpUtils.get(requestURL, (response) => {

            dispatch({
                type: ActionTypes.DISTRICT_CITY_SEARCH,
                cities: response.data
            });

            dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function getCity(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: true});
        const requestURL = WebService.getCityById.replace("{id}", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshCity(response.data));
            dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createCity(city, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: true});

        HttpUtils.post(WebService.createCity, city, (response) => {

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCity({}));
            dispatch(getCitiesByProvincialId(response.data.provincialId, formatMessage));
            dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateCity(city, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: true});

        const requestURL = WebService.updateCityById.replace("{id}", city.id);
        HttpUtils.put(requestURL, city, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch(toggleDetailModal(false));
            dispatch(refreshCity({}));
            dispatch(getCitiesByProvincialId(response.data.provincialId, formatMessage));

            dispatch({type: ActionTypes.DISTRICT_CITY_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteCity(city, formatMessage) {
    return dispatch => {
        const requestURL = WebService.deleteByCityId.replace("{id}", city.id);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getCitiesByProvincialId(response.data.provincialId, formatMessage));
        }, (response) => {
        }, formatMessage);
    }
}



