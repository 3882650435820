import EcgFileDateTime from "./EcgFileDateTime";
import {FORMAT_DATE_SLASH, FORMAT_DATE_TIME_FULL_HYPHEN, FORMAT_TIME_COLON} from "../../../constants/DateTimeFormats";
import EcgFileMixData from "./EcgFileMixData";
import EcgFileCommonHeader from "./EcgFileCommonHeader";
import moment from "moment";
import EcgFileDate from "./EcgFileDate";


class EcgRouteEventFile {
    constructor(dataArray, startPos, model) {
        let index = startPos;

        const EventDate = dataArray.slice(index, index + 8);
        this.EventDate = "";
        this.EventDateV = "";
        for (let i = 0; i < 8; i++) {
            if (i == 4) {
                this.EventDate += "/"
            } else if (i == 6) {
                this.EventDate += "/"
            }
            this.EventDate += String.fromCharCode(EventDate[i]);
            this.EventDateV += String.fromCharCode(EventDate[i]);
        }

        index += 8;

        const EventFileIdx = dataArray.slice(index, index + 4);
        this.EventFileIdx = new Int32Array(EventFileIdx.buffer.slice(-4))[0]

        index += 4;

        const EventRecordNo = dataArray.slice(index, index + 4);
        this.EventRecordNo = new Int32Array(EventRecordNo.buffer.slice(-4))[0];

        index += 4;

        const TimeRecordNo = dataArray.slice(index, index + 4);
        this.TimeRecordNo = new Int32Array(TimeRecordNo.buffer.slice(-4))[0];

        index += 4;

        const EventTime = dataArray.slice(index, index + 4);
        this.EventTime = ""
        this.EventTimeV = ""
        for (let i = 0; i < 3; i++) {
            if (i == 1) {
                this.EventTime += ":"
            } else if (i == 2) {
                this.EventTime += ":"
            }
            this.EventTime += EventTime[i] < 10 ? "0" + EventTime[i] : EventTime[i];
            this.EventTimeV += EventTime[i] < 10 ? "0" + EventTime[i] : EventTime[i]
        }

        index += 4;

        const HistoryFileIndex = dataArray.slice(index, index + 4);
        this.HistoryFileIndex = new Int32Array(HistoryFileIndex.buffer.slice(-4))[0];

        index += 4;

        const TimeRecordIndex = dataArray.slice(index, index + 4);
        this.TimeRecordIndex = new Int32Array(TimeRecordIndex.buffer.slice(-4))[0];

        index += 4;

        const EventLevel = dataArray.slice(index, index + 1);
        this.EventLevel = EventLevel[0] >= 0 ? EventLevel[0] : EventLevel[0] + 256;

        index += 1;

        const EventCode = dataArray.slice(index, index + 1);
        this.EventCode = EventCode[0] >= 0 ? EventCode[0] : EventCode[0] + 256;

        index += 1;

        const Reserved = dataArray.slice(index, index + 1);
        this.Reserved = Reserved[0] >= 0 ? Reserved[0] : Reserved[0] + 256;

        index += 1;

        const AckRecord = dataArray.slice(index, index + 1);
        this.AckRecord = AckRecord[0] >= 0 ? AckRecord[0] : AckRecord[0] + 256;

        index += 1;

        this.model = model;
    }
}

export default EcgRouteEventFile;