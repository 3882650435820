/**
 * Created by Ethan on 17/3/11.
 */
import * as ActionTypes from '../ActionTypes'
import * as WebService from '../WebService'
import HttpUtils from '../../../utils/HttpUtils';

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ECG_GUARDER_BIND_HISTORY_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleGuarderBindHistoryModal(isShow) {
    return {
        type: ActionTypes.ECG_GUARDER_BIND_HISTORY_TOGGLE_LIST_MODAL,
        showBindHistoryModal: isShow
    }
}

export function searchGuarderBindHistoryList(searchCondition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ECG_GUARDER_BIND_HISTORY_LOADING, isLoading: true});

        dispatch(refreshSearchCondition(searchCondition));

        HttpUtils.post(WebService.searchGuarderBindHistory, searchCondition, (response) => {

            dispatch({
                type: ActionTypes.ECG_GUARDER_BIND_HISTORY_SEARCH,
                guarderBindHistoryList: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.ECG_GUARDER_BIND_HISTORY_LOADING, isLoading: false});

        }, (response) => {
            dispatch({type: ActionTypes.ECG_GUARDER_BIND_HISTORY_LOADING, isLoading: false});
        }, formatMessage)
    }
}