/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Select} from 'antd';

import {getAllRoles} from '../actions/RoleAction';

class RoleSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedValues: [],
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllRoles(this.state.formatMessage));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.values !== undefined && nextProps.values !== null) {
            this.setState({selectedValues: nextProps.values});
        }
    }

    _handleChange(values) {
        this.setState({selectedValues: values});
        this.props.onChange && this.props.onChange.call(this, values);
    }

    render() {
        return (
            <Select mode="multiple"
                    value={this.state.selectedValues}
                    onChange={(values) => this._handleChange(values)}>
                {
                    this.props.roles.map((item, i) => {
                        return (
                            <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                        )
                    })
                }
            </Select>
        );
    }
}

function mapStateToProps(store) {
    return {
        roles: store.AccountRoleReducer.roles
    }
}

export default connect(mapStateToProps)(injectIntl(RoleSelector));