/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Layout} from 'antd';

import PageTitle from "../../../../../components/PageTitle";
import OrganizationTree from '../../../components/OrganizationTree';
import DetailModal from "./DetailModal";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";


import {searchEmployees} from '../../../actions/EmployeeAction';

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    _handleTreeNodeSelect(selectedKey) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition.organizationIds = [];
        searchCondition.organizationIds.push(selectedKey);
        this.props.dispatch(searchEmployees(searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <div className="page-content">
                <DetailModal/>
                <PageTitle title="ORG_EMPLOYEE_ROUTE_LIST"/>
                <Layout>
                    <Layout.Sider width={200}
                                  style={{background: '#fff', borderRight: '1px solid #e8e8e8', height: '100%'}}>
                        <OrganizationTree onSelect={(selectedKey) => this._handleTreeNodeSelect(selectedKey)}/>
                    </Layout.Sider>
                    <Layout.Content style={{paddingLeft: '10px', minHeight: 280}}>
                        <SearchCondition/>
                        <SearchResult/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.OrgEmployeeReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));