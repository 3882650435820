/**
 * Created by Ethan on 17/3/16.
 */

import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    guardians: [],
    guarderBinders: [],
    guarder: {},
    pagination: {},
    isLoading: false,
    showBinderListModal: false
};

const GuardianReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ECG_GUARDER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ECG_GUARDER_TOGGLE_BINDER_LIST_MODAL:
            return Object.assign({}, state, {
                showBinderListModal: action.showBinderListModal
            });
        case ActionTypes.ECG_GUARDER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ECG_GUARDER_REFRESH_GUARDER:
            return Object.assign({}, state, {
                guarder: action.guarder
            });
        case ActionTypes.ECG_GUARDER_SEARCH:
            return Object.assign({}, state, {
                guardians: action.guardians,
                pagination: action.pagination
            });
        case ActionTypes.ECG_GUARDER_REFRESH_BINDERS:
            return Object.assign({}, state, {
                guarderBinders: action.guarderBinders
            });
        default:
            return state
    }
};

export default GuardianReducer;