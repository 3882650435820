import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import message from "../../../components/toast";

export function refreshDictItem(dictItem) {
    return {
        type: ActionTypes.DICT_ITEM_REFRESH_ITEM,
        dictItem: dictItem
    }
}

export function toggleDetailModal(showModal) {
    return {
        type: ActionTypes.DICT_ITEM_TOGGLE_DETAIL_MODAL,
        showDetailModal: showModal
    }
}

export function getDictItemsByClassId(classId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        const requestUrl = WebService.getDictItems + "?classId=" + classId;
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.DICT_ITEM_REFRESH_ITEMS,
                dictItems: response.data
            });
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getDictItemsByClassCode(classCode, dictItemMap, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        const dictItemMapTemp = Object.assign({}, dictItemMap);
        if (dictItemMapTemp[classCode]) {
            dispatch({
                type: ActionTypes.DICT_ITEM_REFRESH_ITEM_MAP,
                dictItemMap: dictItemMapTemp
            });
        } else {
            const requestUrl = WebService.getDictItems + "?classCode=" + classCode;
            console.log(requestUrl);
            HttpUtils.get(requestUrl, (response) => {
                dictItemMapTemp[classCode] = response.data;
                dispatch({
                    type: ActionTypes.DICT_ITEM_REFRESH_ITEM_MAP,
                    dictItemMap: dictItemMapTemp
                });
                dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
            }, (response) => {
                dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
            }, formatMessage)
        }
    }
}

export function getDictItem(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        HttpUtils.get(WebService.getDictItem.replace("{id}", id), (response) => {
            dispatch(refreshDictItem(response.data));
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createDictItem(dictItem, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDictItem, dictItem, (response) => {
            dispatch(getDictItemsByClassId(dictItem.classId, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDictItem(dictItem, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateDictItem.replace("{id}", dictItem.id), dictItem, (response) => {
            dispatch(getDictItemsByClassId(dictItem.classId, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteDictItem(dictItem, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: true});

        HttpUtils.delete(WebService.deleteDictItem.replace("{id}", dictItem.id), {}, (response) => {
            dispatch(getDictItemsByClassId(dictItem.classId, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_ITEM_LOADING, isLoading: false});
        }, formatMessage)
    }
}