/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Calendar, Badge, Switch} from 'antd';

import {RoutePath} from "../../../../../../constants/RoutePath";
import HttpUtils from "../../../../../../utils/HttpUtils";
import {FORMAT_DATE_SIMPLE} from "../../../../../../constants/DateTimeFormats";

class HistoryCalendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        // const deviceCode = this.props.location.query.deviceCode;
        // this.setState({deviceCode: deviceCode})
        // this.props.dispatch(getDateIndexFile(deviceCode));
    }

    componentWillUnmount() {
    }

    /** 日历日期点击事件 */
    _handleSelectDate(date) {

        // const deviceCode = HttpUtils.getQueryString("deviceCode");
        const ecgDateIndexRecord = this.props.ecgDateIndexRecordMap[date.format(FORMAT_DATE_SIMPLE)];
        // 当前选中的日期的索引信息写入
        // let requestUrl = this.props.routePath;
        // requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", this.props.deviceCode);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
        // requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);
        //
        // this.props.router.push(requestUrl);

        this.props.onSelect && this.props.onSelect.call(this, ecgDateIndexRecord);
    };

    /** 日历返回的事件Badge */
    dateCellRender(value) {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[value.format(FORMAT_DATE_SIMPLE)];
        if (!dateIndexRecord) {
            return;
        }

        return (
            <h3 className="text-center">
                <Badge count={dateIndexRecord.eventNum} overflowCount={9999}/>
                <Badge count={dateIndexRecord.timeNum} overflowCount={9999}
                       style={{backgroundColor: '#52c41a'}}/>
            </h3>
        );
    }

    /** 返回日历日期的禁止点击 */
    _handleDisabled(currentDate) {
        const dateIndexRecord = this.props.ecgDateIndexRecordMap[currentDate.format(FORMAT_DATE_SIMPLE)];
        // 时间索引文件编号，0表示没采集数据
        return dateIndexRecord === undefined || dateIndexRecord === null || dateIndexRecord.eventFileIndex <= 0;
    }

    render() {
        return (
            <Calendar className="calendar-mode-invisible" mode="month"
                      dateCellRender={(value) => this.dateCellRender(value)}
                      disabledDate={currentDate => this._handleDisabled(currentDate)}
                // onPanelChange={(value, mode) => this.onPanelChange(value, mode)}
                      onSelect={(date) => this._handleSelectDate(date)}
                      style={{marginTop: 20}}
            />
        );
    }
}

const mapStateToProps = (store) => {
    return {
        ecgDateIndexRecordMap: store.EcgHistoryReducer.ecgDateIndexRecordMap,
    }
};

export default connect(mapStateToProps)(injectIntl(HistoryCalendar));
