/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Switch} from 'antd';

import HistoryCalendar from "./HistoryCalendar";
import {getDateIndexFile} from "../../../../actions/HistoryAction";
import HttpUtils from "../../../../../../utils/HttpUtils";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {ECG_HISTORY_DAY} from "../../../../RoutePath";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            toggleDeviceDetail: false,
        }
    }

    componentWillMount() {
        const deviceCode = this.props.location.query.deviceCode;
        this.props.dispatch(getDateIndexFile(deviceCode));
    }

    _handleToggleDeviceDetailDrawer(value, deviceCode) {
        this.setState({toggleDeviceDetail: value});
        if (value) {
            // this.props.dispatch(getDeviceById(deviceCode, this.state.formatMessage));
        }
    }

    render() {
        const deviceCode = this.props.location.query.deviceCode;
        return (
            <div className="position-relative">
                {/*<SearchCondition {...this.props} />*/}
                <div>
                    <Switch className="history-events-switch pull-right spacing-h"
                            disabled={true}
                            checkedChildren={deviceCode + " 详情"}
                            unCheckedChildren={deviceCode + " 详情"}
                            onChange={(value) => this._handleToggleDeviceDetailDrawer(value, deviceCode)}/>
                </div>

                {/*<DeviceDetailDrawer visible={this.state.toggleDeviceDetail}/>*/}
                <HistoryCalendar {...this.props} deviceCode={deviceCode}
                                 onSelect={(value) => this._handleSelectDate(value)}/>
            </div>
        );
    }

    _handleSelectDate(ecgDateIndexRecord) {
        const deviceCode = this.props.location.query.deviceCode;
        // 当前选中的日期的索引信息写入
        let requestUrl = ECG_HISTORY_DAY;
        requestUrl = HttpUtils.addQueryString(requestUrl, "deviceCode", deviceCode);
        requestUrl = HttpUtils.addQueryString(requestUrl, "eventFileIndex", ecgDateIndexRecord.eventFileIndex);
        requestUrl = HttpUtils.addQueryString(requestUrl, "eventNum", ecgDateIndexRecord.eventNum);
        requestUrl = HttpUtils.addQueryString(requestUrl, "recordDate", ecgDateIndexRecord.recordDate);
        requestUrl = HttpUtils.addQueryString(requestUrl, "timeFileIndex", ecgDateIndexRecord.timeFileIndex);
        requestUrl = HttpUtils.addQueryString(requestUrl, "timeNum", ecgDateIndexRecord.timeNum);

        this.props.router.push(requestUrl);
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));